import React from 'react';

import DropdownSelect from '../../../dropdowns/dropdown-select/DropdownSelect';
import BoxButton from '../../../layout/box/BoxButton';

export const columnsResizeStrategies = [
  { value: 'text', label: 'Resize to fit text' },
  { value: 'grid', label: 'Resize to fit grid' },
];

type Props = {
  strategy: 'text' | 'grid';
  onChange: (value: string) => void;
};

const ColumnResizeDropdown: React.FC<Props> = ({ strategy, onChange }) => {
  const item = columnsResizeStrategies.find(constant => constant.value === strategy);

  return (
    <DropdownSelect
      label={<BoxButton>Resize to fit {item && item.value}</BoxButton>}
      items={columnsResizeStrategies}
      onChange={(values: string[]) => onChange(values[0])}
      values={strategy ? [strategy] : undefined}
    />
  );
};

export default ColumnResizeDropdown;
