import type { Theme } from '@mui/material';
import type MUILink from '@mui/material/Link';
import type { SxProps } from '@mui/material/styles';
import type { TabProps as MUITabProps } from '@mui/material/Tab';
import MUITab from '@mui/material/Tab';
import React from 'react';

import type { TestProps } from '../../../types';

export type DomainNavTabProps<TComponent extends React.ElementType> = TestProps &
  Omit<MUITabProps<TComponent>, 'sx'> & { component?: TComponent };

const tabStyle: SxProps<Theme> = theme => ({
  '.MuiSvgIcon-root': {
    fontSize: theme.typography.body1.fontSize,
  },
  '&:link, &:hover': {
    textDecoration: 'none',
  },
});

export function DomainNavTab<TComponent extends React.ElementType = typeof MUILink>(
  props: DomainNavTabProps<TComponent>
): JSX.Element {
  return <MUITab {...props} sx={tabStyle} />;
}
