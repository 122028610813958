import { ColDef, ColumnState } from 'ag-grid-community';
import React from 'react';

import { Group } from '../../../..';
import DropdownSelect from '../../../dropdowns/dropdown-select/DropdownSelect';
import { DropdownSelectItem } from '../../../dropdowns/dropdown-select/DropdownSelectItem';
import Icon from '../../../graphics/icon/Icon';
import BoxButton from '../../../layout/box/BoxButton';

export type CategoriesConfig = {
  [key: string]: Partial<ColDef>[];
};

export type Props = {
  columns: ColDef[];
  columnsModel: ColumnState[];
  categorizedColumns?: CategoriesConfig;
  onChange: (values: string[]) => void;
};

export const getItems = (columns: ColDef[]): DropdownSelectItem<string>[] => {
  return columns
    .filter(c => !c.suppressColumnsToolPanel && c.field && c.headerName)
    .map(c => ({ value: c.colId || c.field || '', label: c.headerName || '' }));
};

export const getCategorizedItems = (categories: Partial<CategoriesConfig>): Group[] => {
  return Object.entries(categories).map(([category, columns]) => ({
    label: category,
    options: getItems(columns as ColDef[]),
  }));
};

const ColumnSelectorDropdown: React.FC<Props> = ({
  columns,
  categorizedColumns,
  columnsModel,
  onChange,
}) => {
  const items = React.useMemo(() => {
    return getItems(columns);
  }, [columns]);
  const categorizedItems = React.useMemo(() => {
    return categorizedColumns ? getCategorizedItems(categorizedColumns) : undefined;
  }, [categorizedColumns]);
  const values = columnsModel.filter(c => !c.hide).map(c => c.colId) as string[];

  return (
    <DropdownSelect
      label={
        <BoxButton aria-label="column setup" testId="grid-column-selector">
          <Icon name="cog" />
        </BoxButton>
      }
      items={items}
      categorizedItems={categorizedItems}
      onChange={onChange}
      values={values}
      multiple
      searchable
    />
  );
};

export default ColumnSelectorDropdown;
