import type { IIdleTimer } from 'react-idle-timer';
import { getCookie } from 'react-use-cookie';

/**
 * Return time stamp when the idle timer expires
 */
export const getIdleTimerEndStamp = (idleTimer: IIdleTimer) => {
  return Date.now() + idleTimer.getRemainingTime();
};

/**
 * Check if the user is active on different tab or window by comparing
 * cookie value with the local idle timer expiration time stamp.
 * @param idleTimer
 * @param cookieName
 */
export const isUserActiveOnOtherTab = (idleTimer: IIdleTimer, cookieName: string) => {
  return parseInt(getCookie(cookieName)) > getIdleTimerEndStamp(idleTimer);
};

/**
 * Computes the prompt before idle timeout in minutes as half of the total idle timeout.
 * The returned value will never exceed 5 minutes.
 * @param idleTimeoutMins
 */
export const getPromptBeforeIdle = (idleTimeoutMins: number) => {
  const promptBeforeIdleMins = idleTimeoutMins / 2;
  const promptBeforeIdleRounded = Math.floor(promptBeforeIdleMins);

  if (promptBeforeIdleRounded === 0) {
    return promptBeforeIdleMins;
  }

  return promptBeforeIdleRounded > 5 ? 5 : promptBeforeIdleRounded;
};

/**
 * Returns idle user warning message.
 * @param idleTimeoutMins
 */
export const getIdleMessage = (idleTimeoutMins: number) => {
  const promptBeforeIdle = getPromptBeforeIdle(idleTimeoutMins);

  if (promptBeforeIdle < 1) {
    return `Due to inactivity, you will be logged out in ${
      promptBeforeIdle * 60
    } seconds unless you become active again.`;
  }

  return `Due to inactivity, you will be logged out in ${promptBeforeIdle} ${
    promptBeforeIdle === 1 ? 'minute' : 'minutes'
  } unless you become active again.`;
};
