import { getOperationName } from '@apollo/client/utilities';
import { datalabApi } from '@cmg/api';
import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { Alert, apiUtil, DataGrid, loggerUtil, SecondaryButton, ToastManager } from '@cmg/common';
import { GridReadyEvent } from 'ag-grid-community';
import saveAs from 'file-saver';
import React, { useState } from 'react';

import { PaginationQueryParams } from '../../../../types/api/pagination';
import { useSectorsGrid_CustomSectorListQuery } from '../../sectors/sectors-grid/graphql/__generated__/SectorsGrid_CustomSectors';
import {
  getColumns,
  getDownloadArgs,
  getIssuerListQuerySort,
  useIssuerListQueryFilter,
} from './CompaniesGrid.model';
import CompaniesFilters from './components/CompaniesFilters';
import SectorsModal from './components/SectorsModal';
import {
  CompaniesGrid_IssuerListDocument,
  CompaniesGrid_IssuerPartsFragment,
  useCompaniesGrid_IssuerListQuery,
  useCompaniesGrid_UpsertCustomSectorIssuersMutation,
} from './graphql/__generated__/CompaniesGrid_Issuers';

const CompaniesGrid = () => {
  const {
    data: sectorListData,
    loading: sectorListLoading,
    error: sectorListError,
  } = useSectorsGrid_CustomSectorListQuery();
  const sectors = sectorListData?.customSectors?.items ?? [];

  const [queryParams, setQueryParams] = useState<PaginationQueryParams>({
    orderDirection: 'asc',
    orderField: 'name',
    page: 1,
    perPage: DataGrid.DEFAULT_PER_PAGE,
  });

  const { issuerListQueryFilter, setFilterValues } = useIssuerListQueryFilter();

  const {
    data: issuerListData,
    loading: issuerListLoading,
    error: issuerListError,
  } = useCompaniesGrid_IssuerListQuery({
    variables: {
      skip: (queryParams.page - 1) * queryParams.perPage,
      take: queryParams.perPage,
      where: issuerListQueryFilter,
      order: getIssuerListQuerySort(queryParams),
    },
  });
  const issuers = issuerListData?.issuers?.items ?? [];

  const [selected, setSelected] = useState<CompaniesGrid_IssuerPartsFragment[]>([]);
  const [showModal, setShowModal] = useState(false);

  const handleSelectionChange = (rows: CompaniesGrid_IssuerPartsFragment[]) => {
    setSelected(rows);
  };

  const handleUpdateIds = (issuers: CompaniesGrid_IssuerPartsFragment[]) => {
    setSelected(issuers);
    setShowModal(true);
  };

  const canWriteCustomSectors = useCheckPermissions([permissionsByEntity.CustomSectors.FULL]);

  const [upsertCustomSectors, { loading: upsertLoading }] =
    useCompaniesGrid_UpsertCustomSectorIssuersMutation({
      refetchQueries: [getOperationName(CompaniesGrid_IssuerListDocument)!],
      onError: error => {
        const messages = error.graphQLErrors
          .map(({ extensions, message }) =>
            extensions ? extensions.errors.map(e => e.message) : message
          )
          .flat();
        loggerUtil.error(error);
        ToastManager.error(
          `Sector assignment failed: ${messages.length ? messages.join('; ') : error.message}`
        );
      },
    });

  const onAssignSectorToCompanies = (customSectorId: string) => {
    const issuerIds = selected.map(({ id }) => id).filter((id): id is string => !!id);
    if (!issuerIds.length) {
      return;
    }

    upsertCustomSectors({
      variables: {
        input: {
          customSectorId,
          issuerIds,
        },
      },
    });
  };

  const gridRef = React.useRef<GridReadyEvent>();

  const onDownload = async () => {
    const displayedColumns = gridRef?.current?.columnApi.getAllDisplayedColumns();
    const downloadArgs = getDownloadArgs({
      displayedColumns,
      filter: issuerListQueryFilter,
      sort: getIssuerListQuerySort(queryParams),
    });

    const result = await datalabApi.downloadIssuers(downloadArgs, {});

    if (result.ok) {
      saveAs(
        result.data,
        apiUtil.getFilenameFromContentDisposition(
          result.headers['content-disposition'] ?? '',
          'companies-export.xlsx'
        )
      );
      ToastManager.success('Download successfully completed.');
    } else {
      ToastManager.error('Download failed.');
    }
  };

  return (
    <React.Fragment>
      <DataGrid<CompaniesGrid_IssuerPartsFragment>
        onGridReady={event => {
          if (gridRef) {
            gridRef.current = event;
          }
          event.columnApi.applyColumnState({ state: [{ colId: 'name', sort: 'asc' }] });
        }}
        extended={{ title: 'Manage Companies', withMargin: true, onDownload }}
        columns={getColumns({ canWriteCustomSectors, handleUpdateIds })}
        rows={issuers}
        resizeBy="grid"
        renderFilters={() => (
          <React.Fragment>
            {sectorListError && <Alert severity="error">{sectorListError.message}</Alert>}
            {issuerListError && <Alert severity="error">{issuerListError.message}</Alert>}
            <CompaniesFilters sectors={sectors} onChange={setFilterValues} />
          </React.Fragment>
        )}
        renderPanel={
          canWriteCustomSectors
            ? ({ selectedRows }) => (
                <SecondaryButton
                  disabled={selectedRows.length < 2}
                  onClick={() => setShowModal(true)}
                >
                  Bulk update
                </SecondaryButton>
              )
            : undefined
        }
        totalPages={Math.ceil((issuerListData?.issuers?.totalCount ?? 0) / queryParams.perPage)}
        pagination={queryParams}
        onPaginationChange={setQueryParams}
        onSelectionChange={handleSelectionChange}
        loading={issuerListLoading || sectorListLoading}
      />
      <SectorsModal
        onSubmit={onAssignSectorToCompanies}
        isSubmitting={upsertLoading}
        companyCount={selected.length}
        sectors={sectors || undefined}
        onHide={() => setShowModal(false)}
        show={showModal}
      />
    </React.Fragment>
  );
};

export default CompaniesGrid;
