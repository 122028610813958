import React from 'react';

import DropdownSelect from '../../../dropdowns/dropdown-select/DropdownSelect';
import BoxButton from '../../../layout/box/BoxButton';

const paginationItemsPerPage = [
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
];

type Props = {
  itemsPerPage: number;
  onChange: (value: number) => void;
};

export default class PaginationDropdown extends React.Component<Props> {
  handleChange = (values: number[]) => {
    this.props.onChange(values[0]);
  };

  render() {
    const { itemsPerPage } = this.props;
    const item = paginationItemsPerPage.find(constant => constant.value === itemsPerPage);

    return (
      <DropdownSelect<number>
        label={<BoxButton>Show {item ? item.label : itemsPerPage}</BoxButton>}
        items={paginationItemsPerPage}
        onChange={this.handleChange}
        values={[itemsPerPage]}
      />
    );
  }
}
