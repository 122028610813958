import { datalabApi } from '@cmg/api';
import { apiUtil } from '@cmg/common';
import { addWeeks, addYears, previousSaturday, subWeeks, subYears } from 'date-fns';
import saveAs from 'file-saver';
import uniq from 'lodash/uniq';

import {
  type OfferingFilterInput,
  OfferingSortInput,
  OfferingStatus,
} from '../../../graphql/__generated__/index';
import { type NestedSortInput } from '../../../graphql/types';
import { CalendarCategory } from '../../../types/domain/calendar/constants';
import { getGraphqlWhere } from '../hooks/useCalendarQuery.model';
import { tabConfig as filedTabConfig } from '../tabs/FiledOfferingsCalendar';
import { tabConfig as liveTabConfig } from '../tabs/LiveOfferingsCalendar';
import { tabConfig as lockupTabConfig } from '../tabs/LockupExpirationsOfferingsCalendar';
import { tabConfig as myOfferingsTabConfig } from '../tabs/MyOfferingsCalendar';
import { tabConfig as myOfferingsWithAllocationTabConfig } from '../tabs/MyOfferingsWithAllocationsCalendar';
import { tabConfig as postponedTabConfig } from '../tabs/PostponedOfferingsCalendar';
import { tabConfig as pricedTabConfig } from '../tabs/PricedOfferingsCalendar';
import { FilterValues } from './calendar-filters';

type SortModelItem = {
  orderBy: string;
  orderByType: string;
};

export function getSortingModel({ orderBy, orderByType }: SortModelItem): OfferingSortInput {
  const direction = orderByType.toUpperCase();

  return orderBy
    .split('.')
    .reverse()
    .reduce((sort, key, index) => ({ [key]: index === 0 ? direction : sort }), {});
}

// Recursively extract field name into Json object, preserving gql schema structure
export const fieldNameToJson = (acc, input, index) => {
  if (index >= input.length) {
    return '';
  }
  const item = input[index];
  acc[item] = fieldNameToJson(acc[item] ?? {}, input, index + 1);
  return acc;
};

// We need to query these fields for calculated fields to work
export const defaultBaseGqlFields = {
  status: '',
  attributes: {
    publicFilingDate: '',
    firstTradeDate: '',
    postponedDate: '',
    pricingDate: '',
    lockUpExpirationDate: '',
  },
};

export const excludeFromGql = [
  'priceRangeLivePricedColumn', // composed from multiple fields
  'sellingRestrictionColumn', // composed from multiple fields
] as string[];

type ColIdName = { colId: string; colName: string };
export const rangeColumns: Record<string, [ColIdName, ColIdName]> = {
  'attributes.latestIpoRangeLowUsd': [
    {
      colId: 'attributes.latestIpoRangeLowUsd',
      colName: 'Price Range - Low',
    },
    {
      colId: 'attributes.latestIpoRangeHighUsd',
      colName: 'Price Range - High',
    },
  ],
  priceRangeLivePricedColumn: [
    {
      colId: 'attributes.latestIpoRangeLowUsd',
      colName: 'Price Range - Low',
    },
    {
      colId: 'attributes.latestIpoRangeHighUsd',
      colName: 'Price Range - High',
    },
  ],
  sellingRestrictionColumn: [
    {
      colId: 'attributes.isRegS',
      colName: 'Reg S',
    },
    {
      colId: 'attributes.isRule144A',
      colName: 'Rule 144a',
    },
  ],
};

export const additionalOptions: Record<string, { numberPrecision?: number }> = {};

const tabList = [
  CalendarCategory.LIVE,
  CalendarCategory.PRICED,
  CalendarCategory.FILED,
  CalendarCategory.POSTPONED,
  CalendarCategory.LOCK_UP_EXPIRATION,
  CalendarCategory.MY_OFFERINGS,
  CalendarCategory.MY_OFFERINGS_WITH_ALLOCATIONS,
];

type ColDef = { field: string; label: string };

const getTabColumns = ({
  columnsConfig,
  defaultVisibleColumns,
}: {
  columnsConfig: { field: string; label: string }[];
  defaultVisibleColumns: string[];
}): ColDef[] =>
  columnsConfig
    .filter(({ field }) => defaultVisibleColumns.includes(field))
    .map(({ field, label }) => ({ field, label }));

const getSheetConfig = (): Record<string, { filter?: string; columns: ColDef[] }> => {
  const now = new Date();
  const ago20y = subYears(now, 20).toISOString().split('T')[0];
  const ago2w = subWeeks(now, 2).toISOString().split('T')[0];
  const future1y = addYears(now, 1).toISOString().split('T')[0];
  const future20y = addYears(now, 20).toISOString().split('T')[0];
  const future2w = addWeeks(now, 2).toISOString().split('T')[0];
  const yearStart = `${now.getUTCFullYear()}-01-01`;
  const lastSaturday = previousSaturday(now).toISOString().split('T')[0];

  return {
    [CalendarCategory.LIVE]: {
      filter: `$[?(@.status == '${OfferingStatus.Live}' && @.attributes.publicFilingDate >= '${ago20y}' && @.attributes.publicFilingDate <= '${future1y}')]`,
      columns: getTabColumns(liveTabConfig),
    },
    [CalendarCategory.PRICED]: {
      filter: `$[?(@.status == '${OfferingStatus.Priced}' && @.attributes.firstTradeDate >= '${ago2w}' && @.attributes.firstTradeDate <= '${future1y}')]`,
      columns: getTabColumns(pricedTabConfig),
    },
    [CalendarCategory.FILED]: {
      filter: `$[?(@.status == '${OfferingStatus.Filed}' && @.attributes.publicFilingDate >= '${ago20y}' && @.attributes.publicFilingDate <= '${future1y}')]`,
      columns: getTabColumns(filedTabConfig),
    },
    [CalendarCategory.POSTPONED]: {
      filter: `$[?((@.status == '${OfferingStatus.Postponed}' || @.status == '${OfferingStatus.Withdrawn}') && @.attributes.publicFilingDate >= '${ago20y}' && @.attributes.publicFilingDate <= '${future20y}' && @.attributes.postponedDate >= '${yearStart}' && @.attributes.postponedDate <= '${future20y}')]`,
      columns: getTabColumns(postponedTabConfig),
    },
    [CalendarCategory.LOCK_UP_EXPIRATION]: {
      filter: `$[?(@.status == '${OfferingStatus.Priced}' && @.attributes.pricingDate >= '${ago20y}' && @.attributes.pricingDate <= '${future1y}' && @.attributes.lockUpExpirationDate >= '${lastSaturday}' && @.attributes.lockUpExpirationDate <= '${future2w}')]`,
      columns: getTabColumns(lockupTabConfig),
    },
    [CalendarCategory.MY_OFFERINGS]: {
      filter: `$[?(@.attributes.publicFilingDate >= '${ago20y}' && @.attributes.publicFilingDate <= '${future1y}')]`,
      columns: getTabColumns(myOfferingsTabConfig),
    },
    [CalendarCategory.MY_OFFERINGS_WITH_ALLOCATIONS]: {
      filter: `$[?(@.attributes.publicFilingDate >= '${ago20y}' && @.attributes.publicFilingDate <= '${future1y}')]`,
      columns: getTabColumns(myOfferingsWithAllocationTabConfig),
    },
  };
};

export const getColumnOptions = (columns: ColDef[]) => {
  let index = 0;

  return columns.reduce((acc, item) => {
    const colId = item.field;
    const colName = item.label;

    // add multiple columns to excel if it is a (low - high) range
    if (rangeColumns[colId]) {
      rangeColumns[colId].forEach(rangeItem => {
        const rangeColId = rangeItem.colId.replaceAll('_', '.');
        acc[rangeColId] = {
          columnName: rangeItem.colName,
          displayOrder: index++,
          ...additionalOptions[rangeItem.colId],
        };
      });
    } else {
      acc[colId.replaceAll('_', '.')] = {
        columnName: colName,
        displayOrder: index++,
        ...additionalOptions[colId],
      };
    }
    return acc;
  }, {});
};

type GetExcelDownloadSheetSetupArgs = {
  screen: string | undefined;
  columns: ColDef[] | undefined;
};

type SheetDef = {
  sheetName: string;
  filter?: string;
  columnOptions: Record<string, { columnName: string; displayOrder: number }>;
};

/**
 * Since we need selected (visible) columns for all the sheets but have
 * only these for the active tab, we need to get the default setup of the
 * inactive tabs and merge it with actual setup of the active tab
 */
export const getExcelDownloadSheetSetup = ({
  screen,
  columns,
}: GetExcelDownloadSheetSetupArgs): SheetDef[] => {
  const sheetConfig = getSheetConfig();

  return tabList.map(tabId => ({
    sheetName: tabId,
    filter: sheetConfig[tabId].filter,
    columnOptions: getColumnOptions(
      tabId === screen && columns ? columns : sheetConfig[tabId].columns
    ),
  }));
};

type GetExcelDownloadArgs = {
  gqlFilterInput?: OfferingFilterInput;
  sheetSetup: SheetDef[];
  sortModel?: SortModelItem[];
  defaultSortModel?: NestedSortInput;
  baseGqlFields?: Object;
};

/**
 * Turn selected fields, filter, and sort model into excel download args
 *
 * Based on `dlgw/components/offerings-report-table` modified for the calendar
 * - defines `sheets` (each calendar tab is exported as a sheet)
 * - expects VirtualizedTable columns (instead of AgGrid)
 */
export const getExcelDownloadArgs = ({
  gqlFilterInput,
  sortModel = [],
  defaultSortModel = {},
  sheetSetup,
  baseGqlFields = defaultBaseGqlFields,
}: GetExcelDownloadArgs): datalabApi.CalendarOfferingsRequestDto => {
  const allColumnKeys = uniq(
    sheetSetup.map(({ columnOptions }) => Object.keys(columnOptions)).flat()
  );
  // Recursively turn selected fields into json object, following gql schema structure
  const selectionJson = allColumnKeys.reduce((acc, colId) => {
    const fieldId = colId.replaceAll('.', '_');
    return excludeFromGql.includes(fieldId) ? acc : fieldNameToJson(acc, colId.split('.'), 0);
  }, baseGqlFields);

  // Stringify json object and replace non-gql characters
  const selectionString = JSON.stringify(selectionJson)
    .replaceAll(/"|'|:/gi, '') // remove single ('), double quotes ("), and colon (:)
    .replaceAll(/,/gi, ' '); // replace comma (,) with space
  const selection = selectionString
    .substring(1, selectionString.length - 1) // remove first and last braces ({})
    .replaceAll(/{|}/gi, ' $& '); // add space around braces ({})

  const downloadArg = {
    selection,
    arguments: {
      order: [
        sortModel.length > 0 && sortModel[0] ? getSortingModel(sortModel[0]) : defaultSortModel,
      ],
      where: gqlFilterInput ?? {},
    },
    sheets: sheetSetup,
  };

  return downloadArg;
};

export type DownloadPayload = {
  order?: { orderBy: string; orderByType: 'asc' | 'desc' | 'descWithNullFirst' };
  filters: FilterValues;
  /*
  includeOfferingNotes?: boolean;
  includeIoiNotes?: boolean;
  includeFundIoi?: boolean;
  */
  screen?: string;
  columns?: ColDef[]; // TODO: unset the optional flag after the tests are updated
};

// VirtualizedTableWidget.downloadExport params
export type DownloadExportProps = Omit<DownloadPayload, 'order' | 'filter'>;

export const downloadCalendarExport = async ({
  order,
  filters,
  // includeOfferingNotes,
  // includeIoiNotes,
  // includeFundIoi
  screen,
  columns,
}: DownloadPayload) => {
  const downloadArgs = getExcelDownloadArgs({
    gqlFilterInput: getGraphqlWhere(undefined, filters),
    sortModel: order && [order],
    sheetSetup: getExcelDownloadSheetSetup({ screen, columns }),
  });

  const resp: datalabApi.DownloadCalendarOfferingsResponse =
    await datalabApi.downloadCalendarOfferings(downloadArgs, {});

  if (resp.ok && resp.data) {
    saveAs(
      resp.data,
      apiUtil.getFilenameFromContentDisposition(
        resp.headers['content-disposition'],
        'calendar-download.xlsx'
      )
    );
  } else if (resp.ok && !resp.data) {
    throw new Error('Empty data returned!');
  } else {
    throw new Error('Download failed!');
  }
};
