import { Icon } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledIcon = styled(Icon)`
  margin: 0 7px;
`;

export const SectorFilter = styled.div`
  margin: 10px 10px 0 auto;
  float: right; /* for IE */
  width: 200px;

  input {
    padding: 6px 26px !important;
  }
`;

export const SectorsUl = styled.ul`
  margin: 0 0 40px 40px;
  list-style: none;

  ul {
    list-style: none;
    margin: 0 0 0 30px;
  }

  li {
    padding: 2px;
  }
`;

/**
 * The button that toggles a row collapsed state. It sits out to the left of the sector name.
 */
export const SSectorParent = styled.div`
  display: flex;
  align-items: center;
`;

export const SSectorRowWrapper = styled.div`
  margin-left: 20px;
`;
