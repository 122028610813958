/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OfferingsFilterForm_SectorsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type OfferingsFilterForm_SectorsQuery = {
  __typename?: 'Query';
  sectors?: {
    __typename?: 'SectorsCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'EnumLabelOfSector';
      id: Types.Sector;
      value: number;
      displayName: string;
    }> | null;
  } | null;
  subSectors?: {
    __typename?: 'SubSectorsCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'EnumLabelOfSubSector';
      id: Types.SubSector;
      value: number;
      displayName: string;
    }> | null;
  } | null;
};

export const OfferingsFilterForm_SectorsDocument = gql`
  query OfferingsFilterForm_Sectors {
    sectors(take: 500) {
      items {
        id
        value
        displayName
      }
      totalCount
    }
    subSectors(take: 500) {
      items {
        id
        value
        displayName
      }
      totalCount
    }
  }
`;

/**
 * __useOfferingsFilterForm_SectorsQuery__
 *
 * To run a query within a React component, call `useOfferingsFilterForm_SectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingsFilterForm_SectorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingsFilterForm_SectorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOfferingsFilterForm_SectorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OfferingsFilterForm_SectorsQuery,
    OfferingsFilterForm_SectorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingsFilterForm_SectorsQuery,
    OfferingsFilterForm_SectorsQueryVariables
  >(OfferingsFilterForm_SectorsDocument, options);
}
export function useOfferingsFilterForm_SectorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingsFilterForm_SectorsQuery,
    OfferingsFilterForm_SectorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingsFilterForm_SectorsQuery,
    OfferingsFilterForm_SectorsQueryVariables
  >(OfferingsFilterForm_SectorsDocument, options);
}
export type OfferingsFilterForm_SectorsQueryHookResult = ReturnType<
  typeof useOfferingsFilterForm_SectorsQuery
>;
export type OfferingsFilterForm_SectorsLazyQueryHookResult = ReturnType<
  typeof useOfferingsFilterForm_SectorsLazyQuery
>;
export type OfferingsFilterForm_SectorsQueryResult = Apollo.QueryResult<
  OfferingsFilterForm_SectorsQuery,
  OfferingsFilterForm_SectorsQueryVariables
>;
