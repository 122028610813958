import ChevronLeft from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useState } from 'react';

export type SidePanelProps = Readonly<{
  readonly title: string;
}>;

const SidePanel: FC<SidePanelProps> = ({ title, children }) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <Stack bgcolor="background.lightGrey">
      {!isExpanded && (
        <Box alignSelf="center" py={1}>
          <IconButton title="Expand side panel" onClick={() => setIsExpanded(true)}>
            <MenuIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
      {isExpanded && (
        <Stack direction="row" spacing="between" alignItems="center" px={2} py={1}>
          <Typography variant="subtitle1" flexGrow={1}>
            {title}
          </Typography>
          <IconButton title="Collapse side panel" onClick={() => setIsExpanded(false)}>
            <ChevronLeft fontSize="small" />
          </IconButton>
        </Stack>
      )}
      <Collapse in={isExpanded} collapsedSize={theme.spacing(6)} orientation="horizontal">
        <Box width={theme.spacing(32)}>{isExpanded && children}</Box>
      </Collapse>
    </Stack>
  );
};

export default SidePanel;
