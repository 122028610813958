import { Box, Column, numericUtil, Popover, Row } from '@cmg/common';
import { Component } from 'react';
import styled from 'styled-components/macro';

import morningStarImg from '../../../../assets/morning_star_logo.svg';
import { formatUrl } from '../../../../common/helpers/helpers';
import { CompanyLegacy } from '../../../../types/domain/company/company';
import { SDlHorizontal } from './CompanyInfo.styles';

const SMorningStarPopoverContent = styled.div`
  font-size: ${({ theme }) => theme.text.size.small};
  width: 200px;
`;

const companyTabs = [
  {
    value: 'PRICE_PERFORMANCE',
    label: 'Price & Performance',
  },
  { value: 'COMPANY_PROFILE', label: 'Company Profile' },
];

const getTabs = (companyDelisted: boolean) => {
  return companyDelisted ? [companyTabs[1]] : companyTabs;
};

export type Props = {
  companyDelisted: boolean;
  stockProfile: Pick<CompanyLegacy, 'stockProfile'>['stockProfile'];
  companyProfile: Pick<CompanyLegacy, 'companyProfile'>['companyProfile'];
  getCurrencyFormat: (value: number | null) => JSX.Element | null;
  getCurrencyFormatInMillions: (value: number | null) => string | null;
};

type State = {
  activeTab: { value: string; label: string };
};

export default class CompanyInfo extends Component<Props, State> {
  state = {
    activeTab: getTabs(this.props.companyDelisted)[0],
  };

  componentDidUpdate(prevProps) {
    const nextProps = this.props;

    if (prevProps.companyProfile.ticker !== nextProps.companyProfile.ticker) {
      this.setState({
        activeTab: getTabs(nextProps.companyDelisted)[0],
      });
    }
  }

  handleTabChanged = activeTab => {
    this.setState({ activeTab });
  };

  renderMorningstarLogo() {
    return (
      <Popover
        trigger="hover"
        variant="TOOLTIP"
        content={
          <SMorningStarPopoverContent>
            ©{new Date().getFullYear()} Morningstar. All Rights Reserved. The information contained
            herein: (1) is proprietary to Morningstar and/or its content providers; (2) may not be
            copied or distributed; and (3) is not warranted to be accurate, complete or timely.
            Neither Morningstar nor its content providers are responsible for any damages or losses
            arising from any use of this information. Past performance is no guarantee of future
            results.
          </SMorningStarPopoverContent>
        }
        placement="left"
      >
        <img
          src={morningStarImg}
          alt="Morningstar"
          style={{ marginTop: 2, marginRight: 2, height: 36, width: 97 }}
        />
      </Popover>
    );
  }

  renderPricePerformance() {
    const { stockProfile, getCurrencyFormat, getCurrencyFormatInMillions } = this.props;
    const { marketCapitalization, enterpriseValue } = stockProfile;

    return (
      <Row>
        <Column xs={12}>
          <SDlHorizontal>
            <dt>Last Close:</dt>
            <dd>{getCurrencyFormat(stockProfile.lastClose)}</dd>
            <dt>52-Week High/Low:</dt>
            <dd>
              {getCurrencyFormat(stockProfile.highPrice52Weeks)} /{' '}
              {getCurrencyFormat(stockProfile.lowPrice52Weeks)}
            </dd>
            <dt>% Below 52-Week High:</dt>
            <dd>{numericUtil.formatPercents(stockProfile.lastCloseAsPctOfHigh, 1, 0)}</dd>
            <dt>% Above 52-Week Low:</dt>
            <dd>{numericUtil.formatPercents(stockProfile.lastCloseAsPctOfLow, 1, 0)}</dd>
            <dt>Market Capitalization:</dt>
            <dd>{getCurrencyFormatInMillions(marketCapitalization)}</dd>
            <dt>Enterprise Value:</dt>
            <dd>{getCurrencyFormatInMillions(enterpriseValue)}</dd>
          </SDlHorizontal>
        </Column>

        <Column xs={12}>
          <SDlHorizontal>
            <dt>4 Week Average Volume:</dt>
            <dd>{numericUtil.formatInteger(stockProfile.averageDailyVolume4Weeks)}</dd>
            <dt>Shares O/S:</dt>
            <dd>{numericUtil.formatNumber(stockProfile.sharesOutstanding, 1, -6)}</dd>
            <dt>% Change 1-Week:</dt>
            <dd>{numericUtil.formatPercents(stockProfile.percentPriceChange1Week, 1, 0)}</dd>
            <dt>% Change MTD:</dt>
            <dd>{numericUtil.formatPercents(stockProfile.percentPriceChangeMtd, 1, 0)}</dd>
            <dt>% Change YTD:</dt>
            <dd>{numericUtil.formatPercents(stockProfile.percentPriceChangeYtd, 1, 0)}</dd>
            <dt>% Change 52-Weeks:</dt>
            <dd>{numericUtil.formatPercents(stockProfile.percentPriceChange52Weeks, 1, 0)}</dd>
          </SDlHorizontal>
        </Column>
      </Row>
    );
  }

  renderCompanyProfile() {
    const { companyProfile } = this.props;

    return (
      <Row>
        <Column xs={24} sm={10}>
          <p dangerouslySetInnerHTML={{ __html: companyProfile.profileText }} />
        </Column>
        <Column xs={24} sm={12} offset={2}>
          <Row>
            <Column xs={12} sm={24}>
              <SDlHorizontal>
                <dt>Company Name:</dt>
                <dd>{companyProfile.companyName}</dd>
                <dt>Exchange:</dt>
                <dd>{companyProfile.market}</dd>
                <dt>CIK:</dt>
                <dd>{companyProfile.cikCode}</dd>
                <dt>CUSIP:</dt>
                <dd>{companyProfile.cusip}</dd>
                <dt>ISIN:</dt>
                <dd>{companyProfile.isin}</dd>
                <dt>MorningStar&copy; Sector:</dt>
                <dd>{companyProfile.sector}</dd>
                <dt>MorningStar&copy; Group:</dt>
                <dd>{companyProfile.industryGroup}</dd>
                <dt>MorningStar&copy; Industry:</dt>
                <dd>{companyProfile.industry}</dd>
                <dt>SIC:</dt>
                <Popover
                  trigger="hover"
                  variant="TOOLTIP"
                  content={<span>SIC Code: {companyProfile.sicCode}</span>}
                  placement="top"
                >
                  <dd>{companyProfile.sicCodeName}</dd>
                </Popover>
                <dt>NAICS:</dt>
                <Popover
                  trigger="hover"
                  variant="TOOLTIP"
                  content={<span>NAICS Code: {companyProfile.naicsCode}</span>}
                  placement="top"
                >
                  <dd>{companyProfile.naicsCodeName}</dd>
                </Popover>
              </SDlHorizontal>
            </Column>
            <Column xs={12} sm={24}>
              <SDlHorizontal topDelimiter>
                <dt>Company Website:</dt>
                <dd>
                  {companyProfile.website && (
                    <a
                      href={formatUrl(companyProfile.website)}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {companyProfile.website}
                    </a>
                  )}
                </dd>
                <dt>Email:</dt>
                <dd>{companyProfile.companyContactEmail}</dd>
                <dt>Phone Number:</dt>
                <dd>{companyProfile.companyContactPhone}</dd>
                <dt>Headquarters:</dt>
                <dd>{companyProfile.headquarters}</dd>
                <dt>Year Founded:</dt>
                <dd>{companyProfile.yearFounded}</dd>
                <dt>Fiscal Year End:</dt>
                <dd>{companyProfile.fiscalYearEnd}</dd>
                <dt>Auditor:</dt>
                <dd>{companyProfile.auditor}</dd>
              </SDlHorizontal>
            </Column>
          </Row>
        </Column>
      </Row>
    );
  }

  render() {
    const { companyDelisted } = this.props;
    const { activeTab } = this.state;

    return (
      <Box>
        <div>
          <Box.Header
            tabs={getTabs(companyDelisted)}
            activeTab={activeTab}
            onChangeTab={this.handleTabChanged}
          >
            {this.renderMorningstarLogo()}
          </Box.Header>

          <Box.Content>
            {activeTab.value === companyTabs[0].value && this.renderPricePerformance()}
            {activeTab.value === companyTabs[1].value && this.renderCompanyProfile()}
          </Box.Content>
        </div>
      </Box>
    );
  }
}
