import {
  ButtonGroup,
  Modal,
  ModalContent,
  ModalFooter,
  PrimaryButton,
  SecondaryButton,
  TextAreaField,
} from '@cmg/common';
import { Form, FormikBag, FormikProps, withFormik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { connectModal, hide, InjectedProps, show } from 'redux-modal';
import * as yup from 'yup';

import { getAppSettings } from '../../../../../config/appSettings';
import { supportRequest } from '../../../ducks';
import HubspotForm from './components/hubspot-form/HubspotForm';

const SupportRequestFormSchema = yup.object().shape({
  feedback: yup.string().required('Required'),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ supportRequest }, dispatch),
});

type Values = {
  feedback: string;
};

type OwnProps = {
  /**
   * User auth token to be used when sending request to server
   */
  token: string;
  /**
   * Modal window title
   */
  title?: string;
  /**
   * Text area description text
   */
  description?: string;
  /**
   * Custom submit button text
   */
  submitButtonCaption?: string;
};

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type OuterProps = InjectedProps & DispatchProps & OwnProps;
export type Props = OuterProps & FormikProps<Values>;

/**
 * Modal window component to send feedback message to CMG support.
 */
export const SupportRequestModalComponent: React.FC<Props> = ({
  show,
  isValid,
  handleHide,
  description,
  title = 'Please let us know of any questions or comments',
  submitButtonCaption = 'Submit',
}) => {
  const { isHubspotSupportFormOn } = getAppSettings().features;

  return (
    <Modal show={show} title={title} closeButton onHide={handleHide}>
      {isHubspotSupportFormOn ? (
        <ModalContent>
          <HubspotForm />
        </ModalContent>
      ) : (
        <Form>
          <ModalContent>
            {description && <div>{description}</div>}
            <TextAreaField name="feedback" fullWidth />
          </ModalContent>
          <ModalFooter>
            <ButtonGroup>
              <SecondaryButton onClick={handleHide}>Close</SecondaryButton>
              <PrimaryButton disabled={!isValid} type="submit">
                {submitButtonCaption}
              </PrimaryButton>
            </ButtonGroup>
          </ModalFooter>
        </Form>
      )}
    </Modal>
  );
};

const name = 'SUPPORT/SUPPORT_REQUEST_MODAL';
export const openSupportRequestModal = (props: OwnProps) => show(name, props);
export const closeSupportRequestModal = () => hide(name);

export const SupportRequestForm = withFormik({
  handleSubmit: (values: Values, formikBag: FormikBag<OuterProps, Values>) => {
    const { token, actions } = formikBag.props;

    if (token) {
      actions.supportRequest({ feedback: values.feedback, token });
    }
  },
  mapPropsToValues: () => {
    return { feedback: '' };
  },
  validationSchema: SupportRequestFormSchema,
})(SupportRequestModalComponent);

const ConnectedModal = connect(undefined, mapDispatchToProps)(SupportRequestForm);
export default connectModal({ name })(ConnectedModal);
