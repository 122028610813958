import History from 'history';

// React-router needs a string literal in `route` instead of a generic string.
// It can comprehend the url and extract the correct parameters for `match` in RouteProps.
// See ExtractRouteParams type in React-router.
// This is why we need the `RoutePathLiteral extends string` line
export type RouteConfig<
  RoutePathLiteral extends string,
  GetUrlPath extends ((...params: any) => string) | undefined,
  GetRouteState extends
    | ((params: { location: History.Location }) => Record<string, any>)
    | undefined,
  GetDocumentTitle extends ((...params: any) => string) | undefined
> = {
  routePath: RoutePathLiteral;
} & (GetUrlPath extends undefined ? {} : { getUrlPath: GetUrlPath }) &
  (GetUrlPath extends undefined ? {} : { getAbsoluteUrlPath: GetUrlPath }) &
  (GetRouteState extends undefined ? {} : { getRouteState: GetRouteState }) &
  (GetDocumentTitle extends undefined ? {} : { getDocumentTitle: GetDocumentTitle });

// React-router needs a string literal in `route` instead of a generic string.
// It can comprehend the url and extract the correct parameters for `match` in RouteProps.
// See ExtractRouteParams type in React-router.
// This function provides correct setting of the types.
export function createRoute<
  RoutePathLiteral extends string,
  GetUrlPath extends ((...params: any) => string) | undefined,
  GetRouteState extends
    | ((params: { location: History.Location }) => Record<string, any>)
    | undefined,
  GetDocumentTitle extends ((...params: any) => string) | undefined
>({
  routePath,
  getUrlPath,
  getAbsoluteUrlPath,
  getRouteState,
  getDocumentTitle,
}: {
  routePath: RoutePathLiteral;
  getUrlPath?: GetUrlPath;
  getAbsoluteUrlPath?: GetUrlPath;
  getRouteState?: GetRouteState;
  getDocumentTitle?: GetDocumentTitle;
}): RouteConfig<RoutePathLiteral, GetUrlPath, GetRouteState, GetDocumentTitle> {
  // @ts-expect-error: TS cannot grok this unfortunately
  return {
    routePath,
    getUrlPath,
    getAbsoluteUrlPath,
    getRouteState,
    getDocumentTitle,
  };
}
