import type { ChipProps } from '@mui/material/Chip';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import type { Theme } from '@mui/material/styles';
import React from 'react';

import type { HighlightsItemProps } from './HighlightsItem';
import { HighlightsItem } from './HighlightsItem';

export type HighlightsChipItemListProps = Readonly<
  Pick<HighlightsItemProps, 'name' | 'data-test-id'> & {
    chips: readonly Pick<ChipProps, 'label' | 'color'>[];
  }
>;

const chipStyle = (theme: Theme) => theme.typography.highlight2;

export function HighlightsChipItemList({
  chips,
  ...itemProps
}: HighlightsChipItemListProps): JSX.Element {
  return (
    <HighlightsItem
      {...itemProps}
      value={
        <Stack flexWrap="wrap" alignItems="baseline" direction="row" gap={1}>
          {chips.map((chip, idx) => (
            <Chip key={idx} color={chip.color} label={chip.label} size="small" sx={chipStyle} />
          ))}
        </Stack>
      }
    />
  );
}
