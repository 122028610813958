import type { AutocompleteInputChangeReason } from '@mui/base/useAutocomplete/useAutocomplete';
import Box from '@mui/material/Box';
import type { ButtonProps } from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import type { PopperProps } from '@mui/material/Popper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import type { ReactElement } from 'react';
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';

import type { AutocompleteProps } from '../autocomplete/Autocomplete';
import { Autocomplete } from '../autocomplete/Autocomplete';

const StyledPopper = styled(Popper)({
  '& .MuiAutocomplete-noOptions': {
    display: 'none',
  },
});
export const StyledPopperComponent = React.forwardRef<HTMLDivElement, PopperProps>((props, ref) => {
  return <StyledPopper {...props} ref={ref} />;
});

export type AsyncAutocompleteFetchOptionsFunction<T> = (
  searchedText: string,
  paginationPage?: number
) => Promise<{ data: T[]; pagination?: { hasNext: boolean } }>;

export type AsyncAutocompleteProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined
> = Omit<
  AutocompleteProps<T, Multiple, DisableClearable, boolean>,
  'options' | 'filterOptions' | 'loading' | 'PaperComponent' | 'ref'
> & {
  innerRef?: React.ForwardedRef<HTMLUListElement>;
  isLoadingOptions: boolean;
  onFetchOptions: AsyncAutocompleteFetchOptionsFunction<T>;
  footerButton?: ReactElement<ButtonProps>;
  fetchOptionsDebounceTimeout?: number;
};

const AsyncAutocompleteComponent = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
>(
  props: AsyncAutocompleteProps<T, Multiple, DisableClearable>
) => {
  const {
    onFetchOptions,
    onInputChange,
    onOpen,
    isLoadingOptions,
    footerButton,
    noOptionsText = 'No results matching your search',
    ListboxProps,
    fetchOptionsDebounceTimeout = 500,
    innerRef,
    ...rest
  } = props;

  const [options, setOptions] = React.useState<T[]>([]);
  const [inputText, setInputText] = React.useState<string>('');
  const [activePage, setActivePage] = React.useState<number>(1);
  const [hasNextPage, setHasNextPage] = React.useState<boolean>(true);

  // because rerendering of options when fetching additional data causes reset of scroll, we have to set it back manually
  // https://github.com/mui/material-ui/issues/30249#issuecomment-1255850128
  const [scrollPosition, setScrollPosition] = React.useState<number>(0);
  const listElement = React.useRef<HTMLElement | null>(null);
  const mounted = React.useRef<boolean>();

  React.useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }
    if (scrollPosition && listElement.current && listElement.current.offsetHeight) {
      listElement.current.scrollTop = scrollPosition - listElement.current.offsetHeight;
    }
  });

  const fetchOptions = React.useCallback(
    async (searchedString: string, loadedPage: number) => {
      const { data, pagination } = await onFetchOptions(searchedString, loadedPage);
      setActivePage(loadedPage);
      setHasNextPage(pagination?.hasNext ?? false);
      return data;
    },
    [onFetchOptions]
  );

  const debouncedFetchOptions = useDebouncedCallback(
    async (searchedString: string, loadedPage: number) => {
      const nextOptions = await fetchOptions(searchedString, loadedPage);
      setOptions(nextOptions);
    },
    fetchOptionsDebounceTimeout
  );

  const handleInputChange = React.useCallback(
    async (
      event: React.SyntheticEvent,
      searchedString: string,
      reason: AutocompleteInputChangeReason
    ) => {
      onInputChange?.(event, searchedString, reason);

      setInputText(searchedString);
      await debouncedFetchOptions(searchedString, 1);
    },
    [debouncedFetchOptions, onInputChange]
  );

  const handleOpen = React.useCallback(
    async (event: React.SyntheticEvent) => {
      onOpen?.(event);

      if (isLoadingOptions) {
        return;
      }
      if (options.length > 0) {
        return;
      }

      const nextOptions = await fetchOptions(inputText, 1);
      setOptions(nextOptions);
    },
    [isLoadingOptions, fetchOptions, inputText, onOpen, options]
  );

  const handleListboxScroll = React.useCallback(
    async ({ currentTarget }: React.UIEvent<HTMLUListElement>) => {
      if (!hasNextPage) {
        return;
      }
      if (isLoadingOptions) {
        return;
      }

      const { scrollTop, clientHeight, scrollHeight } = currentTarget;
      const scrollPosition = scrollTop + clientHeight;
      if (scrollHeight - scrollPosition <= 1) {
        setScrollPosition(scrollPosition);
        const nextOptions = await fetchOptions(inputText, activePage + 1);
        setOptions([...options, ...nextOptions]);
      }
    },
    [activePage, fetchOptions, inputText, hasNextPage, options, isLoadingOptions]
  );

  const handleFilterOptions = React.useCallback((x: T[]) => x, []);

  const listboxProps = React.useMemo(
    () => ({
      ...ListboxProps,
      ref: listElement,
      onScroll: handleListboxScroll,
    }),
    [ListboxProps, handleListboxScroll]
  );

  return (
    <Autocomplete<T, Multiple, DisableClearable, boolean>
      ref={() => innerRef}
      // we need to disable the built-in filtering
      // https://mui.com/material-ui/react-autocomplete/#search-as-you-type
      filterOptions={handleFilterOptions}
      // the input change function is synchronous, but we are loading data in it
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onInputChange={handleInputChange}
      // the open function is synchronous, but we are loading data in it
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onOpen={handleOpen}
      options={options}
      ListboxProps={listboxProps}
      noOptionsText={null}
      PopperComponent={StyledPopperComponent}
      PaperComponent={({ children, ...props }) => (
        <Paper
          {...props}
          // preventing closing of paper on mouse down (we would not be able to use footer button)
          onMouseDown={e => e.preventDefault()}
        >
          {children}

          {!isLoadingOptions && options.length === 0 && (
            <Box px={2} py={1}>
              <Typography variant="body1" color="text.secondary">
                {noOptionsText}
              </Typography>
            </Box>
          )}
          {isLoadingOptions && (
            <Box px={2} py={1}>
              <Typography variant="body1" color="text.secondary">
                Loading...
              </Typography>
            </Box>
          )}
          {footerButton && (
            <Stack>
              <Divider />
              <Box px={2} py={1}>
                {footerButton}
              </Box>
            </Stack>
          )}
        </Paper>
      )}
      forcePopupIcon
      {...rest}
    />
  );
};

export const AsyncAutocomplete = React.forwardRef((props, ref) => {
  return (
    <AsyncAutocompleteComponent
      {...props}
      // @ts-expect-error unknown is not assignable to HTMLUListElement
      innerRef={ref}
    />
  );
}) as <T, Multiple extends boolean | undefined, DisableClearable extends boolean | undefined>(
  props: AsyncAutocompleteProps<T, Multiple, DisableClearable>
) => React.JSX.Element;
