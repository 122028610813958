import { createTestId } from '../../../common/selectors';

const create = name => createTestId(`rolodex-crm-match-list-${name}`);

export const crmMatchListScreen = create('screen');
export const crmMatchListHeader = create('header');
export const crmMatchListLinkOrganizationModalLinkButton = create(
  'link-organization-modal-link-button'
);
export const crmMatchListLinkOrganizationModalLinkAndReturnToInvestorFirmsListButton = create(
  'link-organization-modal-link-and-return-to-investor-firms-list-button'
);
