import React from 'react';
import styled from 'styled-components/macro';

import { ToastMessage } from '../types';

type DefaultToastContentTemplateProps = {
  content: Array<ToastMessage>;
};

const SToastContentListItem = styled.li`
  list-style-type: none;
`;

const SToastContentList = styled.ul`
  padding: 0;
  margin: 0;

  ${SToastContentListItem} {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const DefaultToastContentTemplate = ({ content }: DefaultToastContentTemplateProps) => (
  <SToastContentList>
    {content.map((message, index) => (
      <SToastContentListItem key={`${content.length}-${index}`}>{message}</SToastContentListItem>
    ))}
  </SToastContentList>
);

export default DefaultToastContentTemplate;
