import { numericUtil, Popover } from '@cmg/common';
import React from 'react';

import { CompanyOfferingIoiInfo } from '../../../../../types/domain/company/companyOfferingIoiInfo';
import { IoiType } from '../../../../../types/domain/offering/ioi/constants';
import PopoverContentTable from '../../../../shared/components/PopoverContentTable';
import { SRightAlignTd } from './FundAllocationsCell.styled';

export type Props = {
  ioiInfo: CompanyOfferingIoiInfo;
};

/**
 * A single table cell that shows the Ioi info for a particular offering.
 * If the account hasn't passed or isn't reg-m conflict then it will display the allocation in shares.
 */
const FundAllocationsCell: React.FC<Props> = ({ ioiInfo }) => {
  // we check for the legacy values since this component is still used in a location where we haven't transitioned to the datalab gateway api
  if (ioiInfo.ioiType === IoiType.REG_M || ioiInfo.ioiType === IoiType.LEGACY_REG_M) {
    return <SRightAlignTd>Reg-M</SRightAlignTd>;
  }

  if (ioiInfo.ioiType === IoiType.PASS || ioiInfo.ioiType === IoiType.LEGACY_PASS) {
    return <SRightAlignTd>Pass</SRightAlignTd>;
  }

  if (ioiInfo.fundAllocations.length <= 0) {
    return (
      <SRightAlignTd capitalize>{numericUtil.formatInteger(ioiInfo.allocation)}</SRightAlignTd>
    );
  }

  const mappedFundAllocations = ioiInfo.fundAllocations
    .sort((a, b) => a.shares - b.shares)
    .map(f => (
      <tr key={f.id}>
        <td>{f.fundName}</td>
        <td>{numericUtil.formatInteger(f.shares)}</td>
        <td>{numericUtil.formatPercents(f.pctOfFirmAllocation)}</td>
      </tr>
    ));

  const tooltip = (
    <Popover
      trigger="hover"
      variant="TOOLTIP"
      content={
        <PopoverContentTable alignAll="right" alignFirstColumn="left">
          <thead>
            <tr>
              <th>Participating Fund</th>
              <th>Shares</th>
              <th>% Of Firm Allocation</th>
            </tr>
          </thead>
          <tbody>{mappedFundAllocations}</tbody>
        </PopoverContentTable>
      }
      placement="left"
    >
      <span>{numericUtil.formatInteger(ioiInfo.allocation)}</span>
    </Popover>
  );

  return <SRightAlignTd capitalize>{tooltip}</SRightAlignTd>;
};

export default FundAllocationsCell;
