import React from 'react';

import Popover, { Props as PopoverProps } from '../../../../../overlays/popover/Popover';
import type { CellRendererProps, FieldValidationProps } from '../../BaseAgGrid.model';
import WarningIcon from '../WarningIcon';
import { SLayout, SLayoutProps } from './ValidationRenderer.styles';

type Props = CellRendererProps<number> &
  FieldValidationProps &
  SLayoutProps & {
    readonly showWarningIcon?: boolean;
    readonly popoverProps?: PopoverProps;
    readonly 'data-test-id'?: string;
  };

/**
 * Ag Grid Cell Renderer to display field validation feedback.
 * You pass params for hAlign and validation
 * @param props
 * @returns
 */
export const ValidationRenderer: React.FC<Props> = props => {
  const {
    value,
    valueFormatted,
    node,
    validation,
    justifyContent,
    showWarningIcon = true,
    popoverProps = { disabled: true },
    ...rest
  } = props;

  return (
    <SLayout
      justifyContent={justifyContent}
      validation={node.isRowPinned() ? undefined : validation}
      node={node}
      {...rest}
    >
      {validation && showWarningIcon && (
        <Popover {...popoverProps}>
          <span>
            <WarningIcon validation={validation} />
          </span>
        </Popover>
      )}

      <span>{valueFormatted ?? value}</span>
    </SLayout>
  );
};

export default ValidationRenderer;
