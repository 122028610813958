import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import React from 'react';

import Table from '../table/Table';
import TreeGridGenericCell from './TreeGridGenericCell';
import TreeGridHierarchyCell from './TreeGridHierarchyCell';

export type TreeGridRowData = { [key: string]: any };
export type TreeGridColDef = {
  field: string;
  headerName: string;
  cellRendererFramework?: (row: TreeGridRowData) => React.ReactNode;
};
export type Props = {
  isLoading?: boolean;
  columns: TreeGridColDef[];
  rows: TreeGridRowData[];
  getDataPath: (row: TreeGridRowData) => (string | number)[];
  striped?: boolean;
  responsive?: boolean;
  groupDefaultExpanded?: boolean;
  expandedGroups?: (string | number)[];
  isCollapsible?: boolean;
};

/**
 * Displays a table of data with parent/child relationships
 *
 * @param columns - the column definition, very similar to ag-grid's column definition object
 * @param rows - the row definition, very similar to ag-grid's row definition object
 * @param striped - whether or not the <Table /> is striped
 * @param responsive - whether or not the <Table /> is responsive
 * @param getDataPath - a callback function that returns the path to the hierarchy array within a row object
 * @param groupDefaultExpanded - whether or not to expand all rows
 * @param expandedGroups - an array of groups that should be expanded on mount
 * @param isCollapsible - a boolean that controls whether rows can be collapsed or not - defaults to true.
 */
export const TreeGrid: React.FC<Props> = ({
  columns,
  rows,
  striped,
  responsive,
  getDataPath,
  groupDefaultExpanded,
  expandedGroups = [],
  isCollapsible = true,
}) => {
  const rowData = sortBy(rows, row => getDataPath(row));
  const rowsWithChildren = rowData
    .map(row => getDataPath(row))
    .reduce((acc, dataPath) => uniq([...acc, ...dataPath.slice(0, -1)]), []);
  const [expandedRows, setExpandedRows] = React.useState<(string | number)[]>(
    groupDefaultExpanded ? rowsWithChildren : expandedGroups
  );

  return (
    <Table striped={striped} responsive={responsive}>
      <thead>
        <tr>
          {columns.map(column => (
            <th key={column.field}>{column.headerName}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rowData
          .map((row, rowIndex) => {
            const path = [...getDataPath(row)].slice(-1).pop()!;
            const ancestors = getDataPath(row).slice(0, getDataPath(row).length - 1);
            const isVisible = isEqual(
              intersection(expandedRows, ancestors).sort((a, b) =>
                a.toString().localeCompare(b.toString())
              ),
              [...ancestors].sort((a, b) => a.toString().localeCompare(b.toString()))
            );
            const isExpanded = expandedRows.includes(path);
            const hasChildren = rowsWithChildren.includes(path);

            return (
              isVisible && (
                <tr key={`tree-grid-${rowIndex}`}>
                  {columns.map((column, columnIndex) => {
                    return columnIndex === 0 ? (
                      <TreeGridHierarchyCell
                        key={`tree-grid-${rowIndex}-${column.field}`}
                        column={column}
                        row={row}
                        path={path}
                        ancestors={ancestors}
                        hasChildren={hasChildren}
                        isExpanded={isExpanded}
                        isCollapsible={isCollapsible}
                        onToggleRowCollapse={newHierarchyTarget => {
                          if (isExpanded) {
                            setExpandedRows(
                              expandedRows.filter(expandedRow => expandedRow !== newHierarchyTarget)
                            );
                          } else {
                            setExpandedRows([...expandedRows, newHierarchyTarget]);
                          }
                        }}
                      />
                    ) : (
                      <TreeGridGenericCell
                        key={`tree-grid-${rowIndex}-${column.field}`}
                        column={column}
                        row={row}
                      />
                    );
                  })}
                </tr>
              )
            );
          })
          .filter(rowComponent => !!rowComponent)}
      </tbody>
    </Table>
  );
};

export default TreeGrid;
