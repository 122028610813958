import { Box, numericUtil } from '@cmg/common';
import isNil from 'lodash/isNil';
import React from 'react';

import PerformancePercents from '../../../../../common/components/format/performance-percents/PerformancePercents';
import { offeringPriceVsRangeLabels } from '../../../../../types/domain/offering/constants';
import { Performance, Pricing } from '../../model/offering';
import ValueWithTooltip from '../value-with-tooltip/ValueWithTooltip';
import ValuesGrid from '../values-grid/ValuesGrid';
import { SColumn, SRow } from './WidgetPricingAndPerformanceIPO.styles';

const { Label, Value } = ValuesGrid;

export type Props = {
  pricing: Pricing;
  performance: Performance;
  showInternational: boolean;
  getCurrencyFormat: (value: number | null) => JSX.Element | string;
  getCurrencyFormatInMillions: (value: number | null) => string;
};

const WidgetPricingAndPerformanceIPO: React.FC<Props> = ({
  pricing,
  performance,
  showInternational,
  getCurrencyFormat,
  getCurrencyFormatInMillions,
}) => (
  <Box>
    <div>
      <Box.Header title="Pricing &amp; Performance" />
      <Box.Content>
        <ValuesGrid>
          <SRow>
            <SColumn>
              <ValueWithTooltip
                value={
                  showInternational
                    ? getCurrencyFormatInMillions(pricing.marketCapLocalCurr)
                    : getCurrencyFormatInMillions(pricing.marketCap)
                }
                valueTooltip={numericUtil.formatInteger(pricing.postOfferingShares)}
                label="Market Cap at Pricing"
                labelTooltip="Shares Outstanding"
              />
            </SColumn>
            <SColumn>
              <Value>
                {!isNil(pricing.pctPreOfferingMarketCap)
                  ? numericUtil.formatPercents(pricing.pctPreOfferingMarketCap, 1)
                  : 'N/A'}
                {' | '}
                {!isNil(pricing.pctPostOfferingMarketCap)
                  ? numericUtil.formatPercents(pricing.pctPostOfferingMarketCap, 1)
                  : 'N/A'}
              </Value>
              <Label>% Pre & Post Market Cap</Label>
            </SColumn>
            <SColumn>
              <Value>
                <span>
                  {pricing.priceVsRange ? offeringPriceVsRangeLabels[pricing.priceVsRange] : 'N/A'}
                </span>
              </Value>
              <Label>Price vs Range</Label>
            </SColumn>
            <SColumn>
              <Value>
                {!isNil(pricing.priceVsMidpoint)
                  ? numericUtil.formatPercents(pricing.priceVsMidpoint, 1)
                  : 'N/A'}
              </Value>
              <Label>% to Midpoint</Label>
            </SColumn>
            <SColumn>
              <Value>
                {!isNil(pricing.pctChangeInSize)
                  ? numericUtil.formatPercents(pricing.pctChangeInSize, 1)
                  : 'N/A'}
              </Value>
              <Label>% Change in Size</Label>
            </SColumn>
          </SRow>

          <SRow>
            <SColumn>
              <ValueWithTooltip
                value={
                  !isNil(performance.firstDayTurnover)
                    ? numericUtil.formatMultipleFactor(performance.firstDayTurnover)
                    : 'N/A'
                }
                valueTooltip={numericUtil.formatInteger(pricing.firstDayVolume)}
                label="First Day Turnover"
                labelTooltip="First Day Volume"
              />
            </SColumn>
            <SColumn>
              <ValueWithTooltip
                value={<PerformancePercents value={performance.openingPrice} nullValue="N/A" />}
                valueTooltip={getCurrencyFormat(performance.openingSharePrice)}
                label="Opening Price"
                labelTooltip="Opening Price"
              />
            </SColumn>
            <SColumn>
              <ValueWithTooltip
                value={<PerformancePercents value={performance.day1} nullValue="N/A" />}
                valueTooltip={getCurrencyFormat(performance.day1SharePrice)}
                label="1 Day"
                labelTooltip="1 Day Close Price"
              />
            </SColumn>
            <SColumn>
              <ValueWithTooltip
                value={<PerformancePercents value={performance.day3} nullValue="N/A" />}
                valueTooltip={getCurrencyFormat(performance.day3SharePrice)}
                label="3 Day"
                labelTooltip="3 Day Close Price"
              />
            </SColumn>
            <SColumn>
              <ValueWithTooltip
                value={<PerformancePercents value={performance.day7} nullValue="N/A" />}
                valueTooltip={getCurrencyFormat(performance.day7SharePrice)}
                label="7 Day"
                labelTooltip="7 Day Close Price"
              />
            </SColumn>
          </SRow>

          <SRow>
            <SColumn>
              <ValueWithTooltip
                value={<PerformancePercents value={performance.day14} nullValue="N/A" />}
                valueTooltip={getCurrencyFormat(performance.day14SharePrice)}
                label="14 Day"
                labelTooltip="14 Day Close Price"
              />
            </SColumn>
            <SColumn>
              <ValueWithTooltip
                value={<PerformancePercents value={performance.day30} nullValue="N/A" />}
                valueTooltip={getCurrencyFormat(performance.day30SharePrice)}
                label="30 Day"
                labelTooltip="30 Day Close Price"
              />
            </SColumn>
            <SColumn>
              <ValueWithTooltip
                value={<PerformancePercents value={performance.day90} nullValue="N/A" />}
                valueTooltip={getCurrencyFormat(performance.day90SharePrice)}
                label="90 Day"
                labelTooltip="90 Day Close Price"
              />
            </SColumn>
            <SColumn>
              <ValueWithTooltip
                value={<PerformancePercents value={performance.day180} nullValue="N/A" />}
                valueTooltip={getCurrencyFormat(performance.day180SharePrice)}
                label="180 Day"
                labelTooltip="180 Day Close Price"
              />
            </SColumn>
            <SColumn>
              <ValueWithTooltip
                value={<PerformancePercents value={performance.current} nullValue="N/A" />}
                valueTooltip={getCurrencyFormat(performance.currentSharePrice)}
                label="Current"
                labelTooltip="Last Close Price"
              />
            </SColumn>
          </SRow>
        </ValuesGrid>
      </Box.Content>
    </div>
  </Box>
);

export default WidgetPricingAndPerformanceIPO;
