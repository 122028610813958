import type { OptionsWithExtraProps, SnackbarKey } from 'notistack';
import { closeSnackbar, enqueueSnackbar } from 'notistack';

import type { SnackbarVariants } from '../../components/feedback/snackbar/SnackbarProvider.model';

export type SnackbarOptions<V extends SnackbarVariants> = {
  readonly message: string;
  readonly options?: OptionsWithExtraProps<V>;
};

export type SnackbarMessage<V extends SnackbarVariants> = string | SnackbarOptions<V>;

function* enqueueSnackbarVariant<V extends SnackbarVariants>(
  variant: V,
  ...messages: readonly SnackbarMessage<V>[]
) {
  for (const msg of messages) {
    const message = typeof msg === 'string' ? msg : msg.message;
    const options =
      typeof msg === 'string' ? ({ variant } as OptionsWithExtraProps<V>) : msg.options;
    const snackbarKey = enqueueSnackbar(message, {
      ...((options ?? {}) as object),
      onClose:
        options?.persist === true && typeof options.onClose === 'undefined'
          ? () => undefined
          : options?.onClose,
    });

    yield snackbarKey;
  }
}

/**
 * Displays an _info_ snackbar.
 * @param message The message to display.
 * @returns Returns an array of snackbar keys.
 */
export function info(...messages: readonly SnackbarMessage<'info'>[]): readonly SnackbarKey[] {
  return [...enqueueSnackbarVariant('info', ...messages)];
}

/**
 * Displays an _error_ snackbar.
 * @param message The message to display.
 * @returns Returns an array of snackbar keys.
 */
export function error(...messages: readonly SnackbarMessage<'error'>[]): readonly SnackbarKey[] {
  return [...enqueueSnackbarVariant('error', ...messages)];
}

/**
 * Displays a _success_ snackbar.
 * @param message The message to display.
 * @returns Returns an array of snackbar keys.
 */
export function success(
  ...messages: readonly SnackbarMessage<'success'>[]
): readonly SnackbarKey[] {
  return [...enqueueSnackbarVariant('success', ...messages)];
}

/**
 * Closes a snackbar. If no keys are passed, then All snackbars are closed.
 * @param keys The key(s) of a snackbar to close, if no keys are passed, then All snackbars are closed.
 */
export function close(...keys: readonly SnackbarKey[]): void {
  if (keys.length === 0) {
    closeSnackbar();
    return;
  }

  for (const key of keys) {
    closeSnackbar(key);
  }
}
