import { loggerUtil } from '../util';

// Since some handling of currency symbols in the app are hardcoded to a string `$` and others are using the `Intl.NumberFormat` API,
// we are keeping the locale hardcoded to `en-US` so there is consistency in the currency symbols displayed across the app.
// Down the road, when we have a more robust solution for handling currency display + broader localization support, we will revisit this.
const locale = 'en-US';

export const getCurrencySymbol = (currency: string | undefined | null) => {
  try {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency ?? undefined,
    })
      .formatToParts(0)
      .find(part => part.type === 'currency')?.value;
  } catch (error) {
    loggerUtil.error(error as Error, {
      message: `Failed to get currency symbol for currency code: ${currency}`,
    });

    return '';
  }
};
