import { datalabApi } from '@cmg/api';
import { Popover, Tag } from '@cmg/common';
import React from 'react';
import styled, { ThemeConsumer } from 'styled-components/macro';

import { getFeatureToggles } from '../../../config/appSettings';
import { OfferingStatus } from '../../../types/domain/offering/constants';
import { getFormattedCurrency } from '../../shared/model/utils';

const SPriceRangeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const STagWrapper = styled.div`
  margin-top: -3px;
  margin-right: 5px;
`;

export type Props = {
  offering: datalabApi.CalendarOffering;
};

const PriceRangeRenderer: React.FC<Props> = ({ offering }) => {
  const {
    isIpoPriceRangeRevised,
    initialIpoRangeLow,
    initialIpoRangeHigh,
    priceRangeLow,
    priceRangeHigh,
    pricingCurrencyCode,
  } = offering;

  const hideRevisedTag =
    getFeatureToggles().isRestrictFiledUSOfferingsInDLOn &&
    offering.countryCode === 'US' &&
    offering.status === OfferingStatus.FILED;

  return (
    <SPriceRangeWrapper>
      {isIpoPriceRangeRevised && initialIpoRangeLow && initialIpoRangeHigh && !hideRevisedTag && (
        <Popover
          placement="top"
          variant="TOOLTIP"
          content={
            <span>
              Initial Range: {getFormattedCurrency(pricingCurrencyCode, initialIpoRangeLow)}
              {' \u2013 '}
              {getFormattedCurrency(pricingCurrencyCode, initialIpoRangeHigh)}
            </span>
          }
        >
          <STagWrapper>
            <ThemeConsumer>{theme => <Tag color={theme.color.blue2}>R</Tag>}</ThemeConsumer>
          </STagWrapper>
        </Popover>
      )}
      <span>
        {priceRangeLow && priceRangeHigh
          ? `${getFormattedCurrency(pricingCurrencyCode, priceRangeLow)} \u2013
         ${getFormattedCurrency(pricingCurrencyCode, priceRangeHigh)}`
          : '-'}
      </span>
    </SPriceRangeWrapper>
  );
};

export default PriceRangeRenderer;
