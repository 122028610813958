import type { ButtonProps } from '@mui/material/Button';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { styled, useTheme } from '@mui/material/styles';
import type { FC } from 'react';
import React from 'react';

const StyledContent = styled(Stack, {
  name: 'LoadingButton',
  slot: 'Content',
  overridesResolver: (_props, styles) => [styles.content],
})({});

const StyledCircularProgress = styled(CircularProgress, {
  name: 'LoadingButton',
  slot: 'CircularProgress',
  overridesResolver: (_props, styles) => [styles.circularProgress],
})({});

export type LoadingButtonProps = Readonly<
  ButtonProps & {
    /**
     * True when loading otherwise false
     * @default false
     */
    readonly loading?: boolean;
  }
>;

export const LoadingButton: FC<LoadingButtonProps> = ({
  disabled,
  loading,
  children,
  ...restBtnProps
}) => {
  const theme = useTheme();
  const { contentProps, circularProgressProps } =
    theme.components?.LoadingButton?.defaultProps ?? {};

  return (
    <Button {...restBtnProps} disabled={disabled ?? loading}>
      <StyledContent {...contentProps}>
        {children}
        {loading && <StyledCircularProgress {...circularProgressProps} />}
      </StyledContent>
    </Button>
  );
};
