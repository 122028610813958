import React from 'react';
import styled from 'styled-components/macro';

const SBody = styled.div`
  padding-bottom: 15px;
  position: relative;
`;

export type Props = {};

const ModalContent: React.FC<Props> = ({ children }) => {
  return <SBody>{children}</SBody>;
};

export default ModalContent;
