import * as docs from './docs';
import * as ecm from './ecm';
import * as identity from './identity';
import * as xc from './xc';

export const identitySelectors = identity;
export const ecmSelectors = ecm;
export const docsSelectors = docs;
export const xcSelectors = xc;

/**
 * @deprecated
 * will be replaced by a grouped import ecmSelectors
 */
export * from './ecm';

/**
 * @deprecated
 * will be replaced by a grouped import identitySelectors
 */
export * from './identity';
