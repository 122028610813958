import type { ListItemProps } from '@mui/material/ListItem';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';

export type ListStatusItemProps = ListItemProps;

const ListStatusItem = styled(ListItem, {
  name: 'ListStatusItem',
  slot: 'ListItem',
  overridesResolver: (_props, styles) => [styles.root],
})(() => ({
  alignItems: 'flex-start',
}));

export default ListStatusItem;
