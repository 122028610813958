import { Icon } from '@cmg/common';
import { ecmSelectors } from '@cmg/e2e-selectors';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { isComposite } from '../shared/table-helpers';
import { SGrouping, SHeaderItem, SOrderArrow, SOrdering } from './HeaderItem.styles';

export default class HeaderItem extends Component {
  static propTypes = {
    style: PropTypes.object,
    isVisible: PropTypes.bool,
    className: PropTypes.string,
    column: PropTypes.object.isRequired,
    orderBy: PropTypes.string.isRequired,
    orderByType: PropTypes.string.isRequired,
    handleOrderBy: PropTypes.func.isRequired,
    groupBy: PropTypes.string.isRequired,
    handleGroupBy: PropTypes.func.isRequired,
  };

  render() {
    const {
      style,
      isVisible,
      className,
      column,
      orderBy,
      handleOrderBy,
      orderByType,
      groupBy,
      handleGroupBy,
    } = this.props;

    return (
      <SHeaderItem
        data-test-id={ecmSelectors.oldGrid.headerItem.testId}
        className={className}
        hidden={isVisible === false}
        style={style}
      >
        {column.canGroup && (
          <SGrouping
            isActive={groupBy === column.field}
            highlighted={orderBy === column.field}
            onClick={() => handleGroupBy(column.field)}
          >
            <Icon name="th-large" />
          </SGrouping>
        )}

        {!column.headerNoSorting && (
          <SOrdering
            highlighted={orderBy === column.field}
            onClick={() => !isComposite(column) && handleOrderBy(column.field)}
          >
            {column.headerRenderer != null ? (
              <span>{column.headerRenderer()}</span>
            ) : (
              <span>{column.label}</span>
            )}

            <SOrderArrow hidden={orderBy !== column.field}>
              <Icon name={`sort-amount-${orderByType === 'asc' ? 'down' : 'up'}`} />
            </SOrderArrow>
          </SOrdering>
        )}

        {column.headerNoSorting &&
          (column.headerRenderer != null ? (
            <span>{column.headerRenderer()}</span>
          ) : (
            <span>{column.label}</span>
          ))}
      </SHeaderItem>
    );
  }
}
