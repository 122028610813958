import { datalabApi } from '@cmg/api';
import { numericUtil, Popover } from '@cmg/common';
import React from 'react';

import { IoiType } from '../../../types/domain/offering/ioi/constants';
import IoiSummary from './IoiSummary';

export type Props = {
  offering: datalabApi.CalendarOffering;
  indicationType?: IoiType; // todo find the proper type / enum
};

/**
 * @description a. If there is no IOI, but allocation exists - show blank IOI (with no tool tip)
 *              b. Tool tip for IOIs - e.g. 1,000 @ Market, 10% @ $10.00 or $25.0M @ $11.00
 *              c. Display Pass or Reg-M, if available
 */
const IndicationTooltip: React.FC<Props> = ({ offering, indicationType }) => {
  if (indicationType === IoiType.PASS) {
    return <span>Pass</span>;
  }

  if (indicationType === IoiType.REG_M) {
    return <span>Reg-M</span>;
  }

  return (
    <Popover
      placement="left"
      content={<IoiSummary iois={offering.indicationOfInterest} />}
      variant="TOOLTIP"
    >
      <span>{numericUtil.formatInteger(offering.ioiShares)}</span>
    </Popover>
  );
};

export default IndicationTooltip;
