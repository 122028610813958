import Orderable from './orderable';
import { Row } from './Row';
import { SBodyCell, SBodyHeaderCell, SBodySubHeaderCell, SHeaderCell, Table } from './Table.styles';

export default Object.assign({}, Table, {
  TableHeaderCell: SHeaderCell,
  GroupHeaderCell: SBodyHeaderCell,
  GroupSubHeaderCell: SBodySubHeaderCell,
  Cell: SBodyCell,
  Row,
  Orderable,
});
