import { createTestId } from '../../../common/selectors';

const create = (name: string) =>
  createTestId(`xc-release-final-institutional-allocations-modal-${name}`);

export const releaseFinalInstitutionalAllocationsModalWindow = create('window');

export const releaseFinalInstitutionalAllocationsModalValidationTextInput =
  create('validation-text-input');
export const releaseFinalInstitutionalAllocationsModalCancelButton = create('cancel-button');
export const releaseFinalInstitutionalAllocationsModalReleaseButton = create('release-button');
