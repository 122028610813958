import Stack from '@mui/material/Stack';
import React from 'react';

export type HighlightsActionListProps = Readonly<{
  actions: Readonly<React.ReactNode>;
}>;

export const HighlightsActionList: React.FC<HighlightsActionListProps> = ({ actions }) => {
  return (
    <Stack direction="row" gap={1}>
      {actions}
    </Stack>
  );
};
