import { urlUtil } from '@cmg/common';
import { AxiosInstance } from 'axios';
import { compile } from 'path-to-regexp';

import { ApiResponse } from '../../types/api/ApiResponse';
import { UUID } from '../../types/common';

/**
 * UTILS
 */
export function makeFetchListRequest<
  Dto,
  Query extends { [key: string]: any },
  Parameters extends {} = {}
>(apiClient: AxiosInstance, route: string) {
  // extracts route parameters into an url string factory based on parameters
  const toUrl = compile<Parameters>(route);

  return (query: Query, parameters: Parameters = {} as Parameters) => {
    // creating a concrete url based on provided parameters
    const url = toUrl(parameters);
    const queryString = urlUtil.queryStringify(query);
    return apiClient.get<Dto, ApiResponse<Dto>>(`${url}${queryString}`);
  };
}

export function makeFetchEntityRequest<Dto, Parameters extends { id: UUID } = { id: UUID }>(
  apiClient: AxiosInstance,
  route: string
) {
  // extracts route parameters into an url string factory based on parameters
  const toUrl = compile<Parameters>(route);

  return (parameters: Parameters) => {
    // creating a concrete url based on provided parameters
    const url = toUrl(parameters);
    return apiClient.get<Dto, ApiResponse<Dto>>(url);
  };
}

export function makeCreateEntityRequest<RequestDto, ResponseDto, Parameters extends {} = {}>(
  apiClient: AxiosInstance,
  route: string
) {
  // extracts route parameters into an url string factory based on parameters
  const toUrl = compile<Parameters>(route);

  return (parameters: Parameters, data: RequestDto) => {
    // creating a concrete url based on provided parameters
    const url = toUrl(parameters);
    return apiClient.post<RequestDto, ApiResponse<ResponseDto>>(url, data);
  };
}

export function makeUpdateEntityRequest<
  RequestDto,
  ResponseDto,
  Parameters extends { id: UUID } = { id: UUID }
>(apiClient: AxiosInstance, route: string) {
  // extracts route parameters into an url string factory based on parameters
  const toUrl = compile<Parameters>(route);

  return (parameters: Parameters, data: RequestDto) => {
    // creating a concrete url based on provided parameters
    const url = toUrl(parameters);
    return apiClient.put<RequestDto, ApiResponse<ResponseDto>>(url, data);
  };
}

export function makeDeleteEntityRequest<Parameters extends { id: UUID } = { id: UUID }>(
  apiClient: AxiosInstance,
  route: string
) {
  // extracts route parameters into an url string factory based on parameters
  const toUrl = compile<Parameters>(route);

  return (parameters: Parameters) => {
    // creating a concrete url based on provided parameters
    const url = toUrl(parameters);
    return apiClient.delete<undefined, ApiResponse<undefined>>(url);
  };
}
