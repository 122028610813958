import styled from 'styled-components/macro';

import IconButton from '../../buttons/button/IconButton';

export const STreeGridCellContent = styled.div<{ tabCount?: number }>`
  display: flex;
  align-items: center;
  margin-left: ${({ tabCount }) => (tabCount ? 25 * tabCount : 0)}px;
`;

export const SCollapseButtonWrapper = styled.div`
  width: 25px;
  height: 16px;
  margin-right: 3px;
`;

export const StyledCollapseIconButton = styled(IconButton)`
  &:active,
  &:focus {
    box-shadow: none;
    color: ${({ theme }) => theme.text.color.link};
  }
`;

export const StyledCollapseIconWrapper = styled.div`
  display: flex;
  padding-left: 3px;
`;
