import { createTestId } from '../../../common/selectors';

const create = (name: string) => createTestId(`xc-roadshow-meeting-details-form-${name}`);

export const roadshowMeetingDetailsFormLocationField = create('location-field');
export const roadshowMeetingDetailsFormDeleteMeetingDraftButton = create(
  'delete-meeting-draft-button'
);
export const roadshowMeetingDetailsFormCreateMeetingDraftButton = create(
  'create-meeting-draft-button'
);
export const roadshowMeetingDetailsFormReleaseMeetingDraftButton = create(
  'release-meeting-draft-button'
);
export const roadshowMeetingDetailsFormSaveMeetingButton = create('save-meeting-button'); // TODO: remove after changed to meeting details and deal teams
export const roadshowMeetingDetailsFormOpenToSalesButton = create('open-to-sales-button');
export const roadshowMeetingDetailsFormConfirmButton = create('confirm-button');
export const roadshowMeetingDetailsFormCancelButton = create('cancel-button');
export const roadshowMeetingDetailsFormDiscardChangesButton = create('discard-changes-button');
export const roadshowMeetingDetailsFormSaveChangesButton = create('save-changes-button');
