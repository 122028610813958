import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Spinner from '../../shared/spinner/Spinner';
import CompanyScreen from '../components/CompanyScreen';
import { fetchCompanyActions, resetState, selectCompany, selectError } from '../ducks';

type RouterProps = RouteComponentProps<{ companyId: string }>;

const mapStateToProps = (state, { match }: RouterProps) => ({
  company: selectCompany(state),
  companyId: match.params.companyId,
  error: selectError(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchCompany: fetchCompanyActions.request,
      resetState: resetState,
    },
    dispatch
  ),
});

export type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export class CompanyContainerLegacy extends Component<Props> {
  componentDidMount() {
    this.props.actions.fetchCompany(this.props.companyId);
  }

  componentWillUnmount() {
    this.props.actions.resetState();
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props;

    if (prevProps.companyId !== nextProps.companyId) {
      nextProps.actions.fetchCompany(nextProps.companyId);
    }
  }

  render() {
    const { company, error } = this.props;
    const isLoading = !company && !error;

    return (
      <Spinner show={isLoading}>
        <CompanyScreen companyLegacy={company} error={error} />
      </Spinner>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyContainerLegacy);
