import React from 'react';
import styled from 'styled-components/macro';

const SFooter = styled.div`
  padding-top: 15px;
  text-align: right;
  border-top: ${({ theme }) => theme.border.width.small} solid
    ${({ theme }) => theme.border.color.dark};
`;

export type Props = {};

const ModalFooter: React.FC<Props> = ({ children }) => {
  return <SFooter>{children}</SFooter>;
};

export default ModalFooter;
