import Box from '@mui/material/Box';
import type { MenuProps } from '@mui/material/Menu';
import Menu from '@mui/material/Menu';
import React, { useCallback, useEffect, useMemo } from 'react';

import { AppBarIconButton } from './AppBarIconButton';

export type RenderTriggerProps = Readonly<{
  readonly menuId: string;
  readonly openMenu: (anchorElement: HTMLElement) => void;
}>;

export type RenderItemsProps = Readonly<{
  readonly closeMenu: () => void;
}>;

export type AppBarMenuProps = Pick<MenuProps, 'MenuListProps'> &
  Readonly<{
    /**
     * Menu id
     */
    readonly id: string;
    /**
     * Menu label
     */
    readonly label: string;
    /**
     * Icon representing the menu
     */
    readonly icon: React.ReactNode;
    /**
     * Callback returning items, that are supposed to be rendered inside the menu.
     *
     * @param props
     * @returns Menu items. If there is supposed to be rendered more then one menu item, then array should be returned
     */
    readonly renderItems: (props: RenderItemsProps) => React.ReactNode;

    /**
     * Expose a way for the parent component to know when the menu is opened.
     */
    onOpen?: () => void;

    /**
     * If `true`, the menu button is disabled.
     * @default false
     */
    disabled?: boolean;
  }>;

export const AppBarMenu: React.VFC<AppBarMenuProps> = ({
  id,
  label,
  icon,
  renderItems,
  onOpen,
  disabled,
  ...restMenuProps
}) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorElNav);

  useEffect(() => {
    if (open && onOpen) {
      onOpen();
    }
  }, [onOpen, open]);

  const closeMenu = useCallback(() => {
    setAnchorElNav(null);
  }, []);

  const renderItemsProps = useMemo(
    (): RenderItemsProps => ({
      closeMenu,
    }),
    [closeMenu]
  );

  return (
    <Box>
      <AppBarIconButton
        aria-controls={id}
        tooltipTitle={label}
        onClick={e => setAnchorElNav(e.currentTarget)}
        disabled={disabled}
      >
        {icon}
      </AppBarIconButton>
      <Menu
        {...restMenuProps}
        id={id}
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={open}
        onClose={closeMenu}
      >
        {renderItems(renderItemsProps)}
      </Menu>
    </Box>
  );
};
