import ReactTimePicker from 'react-time-picker';
import styled from 'styled-components/macro';

import Popover from '../../../../components/overlays/popover/Popover';

type Focusable = {
  onFocus?: (event: React.FocusEvent<Element>) => void;
  onBlur?: (event: React.FocusEvent<Element>) => void;
};

type DisplayFocus = {
  hasFocus?: boolean;
  hasError?: boolean;
};

export const StyledReactTimePicker = styled(ReactTimePicker)`
  color: ${({ theme }) => theme.designSystem.colors.darkBlue[200]};
  height: 100%;

  .react-time-picker__clear-button {
    display: none;
  }

  .react-time-picker__wrapper {
    border: none;
  }

  .react-time-picker__inputGroup {
    padding: 0;
    min-width: auto;
  }

  .react-time-picker__inputGroup > input {
    color: ${({ theme }) => theme.designSystem.colors.darkBlue[200]};

    &:focus {
      outline: none;
    }

    ::placeholder {
      color: ${({ theme }) => theme.foundation.colors.secondary[300]};
    }
  }
`;

export const SMeridiem = styled.div`
  color: ${({ theme }) => theme.designSystem.colors.darkBlue[200]};
  flex-grow: 1;
`;

export const SInputWrapper = styled.div<DisplayFocus & Focusable>`
  display: flex;
  align-items: center;
  min-width: 128px;
  height: 34px;
  font-size: ${({ theme }) => theme.text.size.medium};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  border-radius: ${({ hasFocus }) => (hasFocus ? '2px 2px 0 0' : '2px')};

  border: ${({ theme, hasFocus, hasError }) => {
    if (hasError) {
      return `${theme.border.width.small} solid ${theme.foundation.colors.error[300]}`;
    }
    if (hasFocus) {
      return `${theme.border.width.small} solid ${theme.foundation.colors.primary[300]}`;
    }
    return `${theme.border.width.small} solid ${theme.foundation.colors.secondary[100]}`;
  }};

  padding: 0 4px 0 8px;
`;

export const SDivider = styled.hr`
  border: none;
  border-right: ${({ theme }) =>
    `${theme.border.width.small} solid ${theme.foundation.colors.secondary[100]}`};
  margin: 0 8px 0 0;
  height: 28px;
`;

export const StyledPopover = styled(Popover)`
  width: 250px;
  padding-top: 0;

  .ant-popover-inner {
    margin-top: -5px;
    box-shadow: none;
    border-radius: 0 0 2px 2px;
    border: ${({ theme }) =>
      `${theme.border.width.small} solid ${theme.foundation.colors.primary[300]}`};
  }

  .ant-popover-inner-content {
    padding: 0;
  }
`;
