/**
 * Currency Code Options
 */

export const currencyCodes = {
  XUA: {
    currencyName: 'ADB Unit of Account',
    alphabeticCode: 'XUA',
    numericCode: '965',
  },
  AFN: {
    currencyName: 'Afghani',
    alphabeticCode: 'AFN',
    numericCode: '971',
  },
  DZD: {
    currencyName: 'Algerian Dinar',
    alphabeticCode: 'DZD',
    numericCode: '012',
  },
  ARS: {
    currencyName: 'Argentine Peso',
    alphabeticCode: 'ARS',
    numericCode: '032',
  },
  AMD: {
    currencyName: 'Armenian Dram',
    alphabeticCode: 'AMD',
    numericCode: '051',
  },
  AWG: {
    currencyName: 'Aruban Florin',
    alphabeticCode: 'AWG',
    numericCode: '533',
  },
  AUD: {
    currencyName: 'Australian Dollar',
    alphabeticCode: 'AUD',
    numericCode: '036',
  },
  AZN: {
    currencyName: 'Azerbaijan Manat',
    alphabeticCode: 'AZN',
    numericCode: '944',
  },
  BSD: {
    currencyName: 'Bahamian Dollar',
    alphabeticCode: 'BSD',
    numericCode: '044',
  },
  BHD: {
    currencyName: 'Bahraini Dinar',
    alphabeticCode: 'BHD',
    numericCode: '048',
  },
  THB: {
    currencyName: 'Baht',
    alphabeticCode: 'THB',
    numericCode: '764',
  },
  PAB: {
    currencyName: 'Balboa',
    alphabeticCode: 'PAB',
    numericCode: '590',
  },
  BBD: {
    currencyName: 'Barbados Dollar',
    alphabeticCode: 'BBD',
    numericCode: '052',
  },
  BYN: {
    currencyName: 'Belarusian Ruble',
    alphabeticCode: 'BYN',
    numericCode: '933',
  },
  BZD: {
    currencyName: 'Belize Dollar',
    alphabeticCode: 'BZD',
    numericCode: '084',
  },
  BMD: {
    currencyName: 'Bermudian Dollar',
    alphabeticCode: 'BMD',
    numericCode: '060',
  },
  VED: {
    currencyName: 'Bolívar Soberano',
    alphabeticCode: 'VED',
    numericCode: '926',
  },
  VES: {
    currencyName: 'Bolívar Soberano',
    alphabeticCode: 'VES',
    numericCode: '928',
  },
  BOB: {
    currencyName: 'Boliviano',
    alphabeticCode: 'BOB',
    numericCode: '068',
  },
  BRL: {
    currencyName: 'Brazilian Real',
    alphabeticCode: 'BRL',
    numericCode: '986',
  },
  BND: {
    currencyName: 'Brunei Dollar',
    alphabeticCode: 'BND',
    numericCode: '096',
  },
  BGN: {
    currencyName: 'Bulgarian Lev',
    alphabeticCode: 'BGN',
    numericCode: '975',
  },
  BIF: {
    currencyName: 'Burundi Franc',
    alphabeticCode: 'BIF',
    numericCode: '108',
  },
  CVE: {
    currencyName: 'Cabo Verde Escudo',
    alphabeticCode: 'CVE',
    numericCode: '132',
  },
  CAD: {
    currencyName: 'Canadian Dollar',
    alphabeticCode: 'CAD',
    numericCode: '124',
  },
  KYD: {
    currencyName: 'Cayman Islands Dollar',
    alphabeticCode: 'KYD',
    numericCode: '136',
  },
  XOF: {
    currencyName: 'CFA Franc BCEAO',
    alphabeticCode: 'XOF',
    numericCode: '952',
  },
  XAF: {
    currencyName: 'CFA Franc BEAC',
    alphabeticCode: 'XAF',
    numericCode: '950',
  },
  XPF: {
    currencyName: 'CFP Franc',
    alphabeticCode: 'XPF',
    numericCode: '953',
  },
  CLP: {
    currencyName: 'Chilean Peso',
    alphabeticCode: 'CLP',
    numericCode: '152',
  },
  COP: {
    currencyName: 'Colombian Peso',
    alphabeticCode: 'COP',
    numericCode: '170',
  },
  KMF: {
    currencyName: 'Comorian Franc ',
    alphabeticCode: 'KMF',
    numericCode: '174',
  },
  CDF: {
    currencyName: 'Congolese Franc',
    alphabeticCode: 'CDF',
    numericCode: '976',
  },
  BAM: {
    currencyName: 'Convertible Mark',
    alphabeticCode: 'BAM',
    numericCode: '977',
  },
  NIO: {
    currencyName: 'Cordoba Oro',
    alphabeticCode: 'NIO',
    numericCode: '558',
  },
  CRC: {
    currencyName: 'Costa Rican Colon',
    alphabeticCode: 'CRC',
    numericCode: '188',
  },
  CUP: {
    currencyName: 'Cuban Peso',
    alphabeticCode: 'CUP',
    numericCode: '192',
  },
  CZK: {
    currencyName: 'Czech Koruna',
    alphabeticCode: 'CZK',
    numericCode: '203',
  },
  GMD: {
    currencyName: 'Dalasi',
    alphabeticCode: 'GMD',
    numericCode: '270',
  },
  DKK: {
    currencyName: 'Danish Krone',
    alphabeticCode: 'DKK',
    numericCode: '208',
  },
  MKD: {
    currencyName: 'Denar',
    alphabeticCode: 'MKD',
    numericCode: '807',
  },
  DJF: {
    currencyName: 'Djibouti Franc',
    alphabeticCode: 'DJF',
    numericCode: '262',
  },
  STN: {
    currencyName: 'Dobra',
    alphabeticCode: 'STN',
    numericCode: '930',
  },
  DOP: {
    currencyName: 'Dominican Peso',
    alphabeticCode: 'DOP',
    numericCode: '214',
  },
  VND: {
    currencyName: 'Dong',
    alphabeticCode: 'VND',
    numericCode: '704',
  },
  XCD: {
    currencyName: 'East Caribbean Dollar',
    alphabeticCode: 'XCD',
    numericCode: '951',
  },
  EGP: {
    currencyName: 'Egyptian Pound',
    alphabeticCode: 'EGP',
    numericCode: '818',
  },
  SVC: {
    currencyName: 'El Salvador Colon',
    alphabeticCode: 'SVC',
    numericCode: '222',
  },
  ETB: {
    currencyName: 'Ethiopian Birr',
    alphabeticCode: 'ETB',
    numericCode: '230',
  },
  EUR: {
    currencyName: 'Euro',
    alphabeticCode: 'EUR',
    numericCode: '978',
  },
  FKP: {
    currencyName: 'Falkland Islands Pound',
    alphabeticCode: 'FKP',
    numericCode: '238',
  },
  FJD: {
    currencyName: 'Fiji Dollar',
    alphabeticCode: 'FJD',
    numericCode: '242',
  },
  HUF: {
    currencyName: 'Forint',
    alphabeticCode: 'HUF',
    numericCode: '348',
  },
  GHS: {
    currencyName: 'Ghana Cedi',
    alphabeticCode: 'GHS',
    numericCode: '936',
  },
  GIP: {
    currencyName: 'Gibraltar Pound',
    alphabeticCode: 'GIP',
    numericCode: '292',
  },
  HTG: {
    currencyName: 'Gourde',
    alphabeticCode: 'HTG',
    numericCode: '332',
  },
  PYG: {
    currencyName: 'Guarani',
    alphabeticCode: 'PYG',
    numericCode: '600',
  },
  GNF: {
    currencyName: 'Guinean Franc',
    alphabeticCode: 'GNF',
    numericCode: '324',
  },
  GYD: {
    currencyName: 'Guyana Dollar',
    alphabeticCode: 'GYD',
    numericCode: '328',
  },
  HKD: {
    currencyName: 'Hong Kong Dollar',
    alphabeticCode: 'HKD',
    numericCode: '344',
  },
  UAH: {
    currencyName: 'Hryvnia',
    alphabeticCode: 'UAH',
    numericCode: '980',
  },
  ISK: {
    currencyName: 'Iceland Krona',
    alphabeticCode: 'ISK',
    numericCode: '352',
  },
  INR: {
    currencyName: 'Indian Rupee',
    alphabeticCode: 'INR',
    numericCode: '356',
  },
  IRR: {
    currencyName: 'Iranian Rial',
    alphabeticCode: 'IRR',
    numericCode: '364',
  },
  IQD: {
    currencyName: 'Iraqi Dinar',
    alphabeticCode: 'IQD',
    numericCode: '368',
  },
  JMD: {
    currencyName: 'Jamaican Dollar',
    alphabeticCode: 'JMD',
    numericCode: '388',
  },
  JOD: {
    currencyName: 'Jordanian Dinar',
    alphabeticCode: 'JOD',
    numericCode: '400',
  },
  KES: {
    currencyName: 'Kenyan Shilling',
    alphabeticCode: 'KES',
    numericCode: '404',
  },
  PGK: {
    currencyName: 'Kina',
    alphabeticCode: 'PGK',
    numericCode: '598',
  },
  HRK: {
    currencyName: 'Kuna',
    alphabeticCode: 'HRK',
    numericCode: '191',
  },
  KWD: {
    currencyName: 'Kuwaiti Dinar',
    alphabeticCode: 'KWD',
    numericCode: '414',
  },
  AOA: {
    currencyName: 'Kwanza',
    alphabeticCode: 'AOA',
    numericCode: '973',
  },
  MMK: {
    currencyName: 'Kyat',
    alphabeticCode: 'MMK',
    numericCode: '104',
  },
  LAK: {
    currencyName: 'Lao Kip',
    alphabeticCode: 'LAK',
    numericCode: '418',
  },
  GEL: {
    currencyName: 'Lari',
    alphabeticCode: 'GEL',
    numericCode: '981',
  },
  LBP: {
    currencyName: 'Lebanese Pound',
    alphabeticCode: 'LBP',
    numericCode: '422',
  },
  ALL: {
    currencyName: 'Lek',
    alphabeticCode: 'ALL',
    numericCode: '008',
  },
  HNL: {
    currencyName: 'Lempira',
    alphabeticCode: 'HNL',
    numericCode: '340',
  },
  SLE: {
    currencyName: 'Leone',
    alphabeticCode: 'SLE',
    numericCode: '925',
  },
  SLL: {
    currencyName: 'Leone',
    alphabeticCode: 'SLL',
    numericCode: '694',
  },
  LRD: {
    currencyName: 'Liberian Dollar',
    alphabeticCode: 'LRD',
    numericCode: '430',
  },
  LYD: {
    currencyName: 'Libyan Dinar',
    alphabeticCode: 'LYD',
    numericCode: '434',
  },
  SZL: {
    currencyName: 'Lilangeni',
    alphabeticCode: 'SZL',
    numericCode: '748',
  },
  LSL: {
    currencyName: 'Loti',
    alphabeticCode: 'LSL',
    numericCode: '426',
  },
  MGA: {
    currencyName: 'Malagasy Ariary',
    alphabeticCode: 'MGA',
    numericCode: '969',
  },
  MWK: {
    currencyName: 'Malawi Kwacha',
    alphabeticCode: 'MWK',
    numericCode: '454',
  },
  MYR: {
    currencyName: 'Malaysian Ringgit',
    alphabeticCode: 'MYR',
    numericCode: '458',
  },
  MUR: {
    currencyName: 'Mauritius Rupee',
    alphabeticCode: 'MUR',
    numericCode: '480',
  },
  MXN: {
    currencyName: 'Mexican Peso',
    alphabeticCode: 'MXN',
    numericCode: '484',
  },
  MXV: {
    currencyName: 'Mexican Unidad de Inversion (UDI)',
    alphabeticCode: 'MXV',
    numericCode: '979',
  },
  MDL: {
    currencyName: 'Moldovan Leu',
    alphabeticCode: 'MDL',
    numericCode: '498',
  },
  MAD: {
    currencyName: 'Moroccan Dirham',
    alphabeticCode: 'MAD',
    numericCode: '504',
  },
  MZN: {
    currencyName: 'Mozambique Metical',
    alphabeticCode: 'MZN',
    numericCode: '943',
  },
  BOV: {
    currencyName: 'Mvdol',
    alphabeticCode: 'BOV',
    numericCode: '984',
  },
  NGN: {
    currencyName: 'Naira',
    alphabeticCode: 'NGN',
    numericCode: '566',
  },
  ERN: {
    currencyName: 'Nakfa',
    alphabeticCode: 'ERN',
    numericCode: '232',
  },
  NAD: {
    currencyName: 'Namibia Dollar',
    alphabeticCode: 'NAD',
    numericCode: '516',
  },
  NPR: {
    currencyName: 'Nepalese Rupee',
    alphabeticCode: 'NPR',
    numericCode: '524',
  },
  ANG: {
    currencyName: 'Netherlands Antillean Guilder',
    alphabeticCode: 'ANG',
    numericCode: '532',
  },
  ILS: {
    currencyName: 'New Israeli Sheqel',
    alphabeticCode: 'ILS',
    numericCode: '376',
  },
  TWD: {
    currencyName: 'New Taiwan Dollar',
    alphabeticCode: 'TWD',
    numericCode: '901',
  },
  NZD: {
    currencyName: 'New Zealand Dollar',
    alphabeticCode: 'NZD',
    numericCode: '554',
  },
  BTN: {
    currencyName: 'Ngultrum',
    alphabeticCode: 'BTN',
    numericCode: '064',
  },
  KPW: {
    currencyName: 'North Korean Won',
    alphabeticCode: 'KPW',
    numericCode: '408',
  },
  NOK: {
    currencyName: 'Norwegian Krone',
    alphabeticCode: 'NOK',
    numericCode: '578',
  },
  MRU: {
    currencyName: 'Ouguiya',
    alphabeticCode: 'MRU',
    numericCode: '929',
  },
  TOP: {
    currencyName: 'Pa’anga',
    alphabeticCode: 'TOP',
    numericCode: '776',
  },
  PKR: {
    currencyName: 'Pakistan Rupee',
    alphabeticCode: 'PKR',
    numericCode: '586',
  },
  MOP: {
    currencyName: 'Pataca',
    alphabeticCode: 'MOP',
    numericCode: '446',
  },
  CUC: {
    currencyName: 'Peso Convertible',
    alphabeticCode: 'CUC',
    numericCode: '931',
  },
  UYU: {
    currencyName: 'Peso Uruguayo',
    alphabeticCode: 'UYU',
    numericCode: '858',
  },
  PHP: {
    currencyName: 'Philippine Peso',
    alphabeticCode: 'PHP',
    numericCode: '608',
  },
  GBP: {
    currencyName: 'Pound Sterling',
    alphabeticCode: 'GBP',
    numericCode: '826',
  },
  BWP: {
    currencyName: 'Pula',
    alphabeticCode: 'BWP',
    numericCode: '072',
  },
  QAR: {
    currencyName: 'Qatari Rial',
    alphabeticCode: 'QAR',
    numericCode: '634',
  },
  GTQ: {
    currencyName: 'Quetzal',
    alphabeticCode: 'GTQ',
    numericCode: '320',
  },
  ZAR: {
    currencyName: 'Rand',
    alphabeticCode: 'ZAR',
    numericCode: '710',
  },
  OMR: {
    currencyName: 'Rial Omani',
    alphabeticCode: 'OMR',
    numericCode: '512',
  },
  KHR: {
    currencyName: 'Riel',
    alphabeticCode: 'KHR',
    numericCode: '116',
  },
  RON: {
    currencyName: 'Romanian Leu',
    alphabeticCode: 'RON',
    numericCode: '946',
  },
  MVR: {
    currencyName: 'Rufiyaa',
    alphabeticCode: 'MVR',
    numericCode: '462',
  },
  IDR: {
    currencyName: 'Rupiah',
    alphabeticCode: 'IDR',
    numericCode: '360',
  },
  RUB: {
    currencyName: 'Russian Ruble',
    alphabeticCode: 'RUB',
    numericCode: '643',
  },
  RWF: {
    currencyName: 'Rwanda Franc',
    alphabeticCode: 'RWF',
    numericCode: '646',
  },
  SHP: {
    currencyName: 'Saint Helena Pound',
    alphabeticCode: 'SHP',
    numericCode: '654',
  },
  SAR: {
    currencyName: 'Saudi Riyal',
    alphabeticCode: 'SAR',
    numericCode: '682',
  },
  XDR: {
    currencyName: 'SDR (Special Drawing Right)',
    alphabeticCode: 'XDR',
    numericCode: '960',
  },
  RSD: {
    currencyName: 'Serbian Dinar',
    alphabeticCode: 'RSD',
    numericCode: '941',
  },
  SCR: {
    currencyName: 'Seychelles Rupee',
    alphabeticCode: 'SCR',
    numericCode: '690',
  },
  SGD: {
    currencyName: 'Singapore Dollar',
    alphabeticCode: 'SGD',
    numericCode: '702',
  },
  PEN: {
    currencyName: 'Sol',
    alphabeticCode: 'PEN',
    numericCode: '604',
  },
  SBD: {
    currencyName: 'Solomon Islands Dollar',
    alphabeticCode: 'SBD',
    numericCode: '090',
  },
  KGS: {
    currencyName: 'Som',
    alphabeticCode: 'KGS',
    numericCode: '417',
  },
  SOS: {
    currencyName: 'Somali Shilling',
    alphabeticCode: 'SOS',
    numericCode: '706',
  },
  TJS: {
    currencyName: 'Somoni',
    alphabeticCode: 'TJS',
    numericCode: '972',
  },
  SSP: {
    currencyName: 'South Sudanese Pound',
    alphabeticCode: 'SSP',
    numericCode: '728',
  },
  LKR: {
    currencyName: 'Sri Lanka Rupee',
    alphabeticCode: 'LKR',
    numericCode: '144',
  },
  XSU: {
    currencyName: 'Sucre',
    alphabeticCode: 'XSU',
    numericCode: '994',
  },
  SDG: {
    currencyName: 'Sudanese Pound',
    alphabeticCode: 'SDG',
    numericCode: '938',
  },
  SRD: {
    currencyName: 'Surinam Dollar',
    alphabeticCode: 'SRD',
    numericCode: '968',
  },
  SEK: {
    currencyName: 'Swedish Krona',
    alphabeticCode: 'SEK',
    numericCode: '752',
  },
  CHF: {
    currencyName: 'Swiss Franc',
    alphabeticCode: 'CHF',
    numericCode: '756',
  },
  SYP: {
    currencyName: 'Syrian Pound',
    alphabeticCode: 'SYP',
    numericCode: '760',
  },
  BDT: {
    currencyName: 'Taka',
    alphabeticCode: 'BDT',
    numericCode: '050',
  },
  WST: {
    currencyName: 'Tala',
    alphabeticCode: 'WST',
    numericCode: '882',
  },
  TZS: {
    currencyName: 'Tanzanian Shilling',
    alphabeticCode: 'TZS',
    numericCode: '834',
  },
  KZT: {
    currencyName: 'Tenge',
    alphabeticCode: 'KZT',
    numericCode: '398',
  },
  TTD: {
    currencyName: 'Trinidad and Tobago Dollar',
    alphabeticCode: 'TTD',
    numericCode: '780',
  },
  MNT: {
    currencyName: 'Tugrik',
    alphabeticCode: 'MNT',
    numericCode: '496',
  },
  TND: {
    currencyName: 'Tunisian Dinar',
    alphabeticCode: 'TND',
    numericCode: '788',
  },
  TRY: {
    currencyName: 'Turkish Lira',
    alphabeticCode: 'TRY',
    numericCode: '949',
  },
  TMT: {
    currencyName: 'Turkmenistan New Manat',
    alphabeticCode: 'TMT',
    numericCode: '934',
  },
  AED: {
    currencyName: 'UAE Dirham',
    alphabeticCode: 'AED',
    numericCode: '784',
  },
  UGX: {
    currencyName: 'Uganda Shilling',
    alphabeticCode: 'UGX',
    numericCode: '800',
  },
  CLF: {
    currencyName: 'Unidad de Fomento',
    alphabeticCode: 'CLF',
    numericCode: '990',
  },
  COU: {
    currencyName: 'Unidad de Valor Real',
    alphabeticCode: 'COU',
    numericCode: '970',
  },
  UYW: {
    currencyName: 'Unidad Previsional',
    alphabeticCode: 'UYW',
    numericCode: '927',
  },
  UYI: {
    currencyName: 'Uruguay Peso en Unidades Indexadas (UI)',
    alphabeticCode: 'UYI',
    numericCode: '940',
  },
  USD: {
    currencyName: 'US Dollar',
    alphabeticCode: 'USD',
    numericCode: '840',
  },
  USN: {
    currencyName: 'US Dollar (Next day)',
    alphabeticCode: 'USN',
    numericCode: '997',
  },
  UZS: {
    currencyName: 'Uzbekistan Sum',
    alphabeticCode: 'UZS',
    numericCode: '860',
  },
  VUV: {
    currencyName: 'Vatu',
    alphabeticCode: 'VUV',
    numericCode: '548',
  },
  CHE: {
    currencyName: 'WIR Euro',
    alphabeticCode: 'CHE',
    numericCode: '947',
  },
  CHW: {
    currencyName: 'WIR Franc',
    alphabeticCode: 'CHW',
    numericCode: '948',
  },
  KRW: {
    currencyName: 'Won',
    alphabeticCode: 'KRW',
    numericCode: '410',
  },
  YER: {
    currencyName: 'Yemeni Rial',
    alphabeticCode: 'YER',
    numericCode: '886',
  },
  JPY: {
    currencyName: 'Yen',
    alphabeticCode: 'JPY',
    numericCode: '392',
  },
  CNY: {
    currencyName: 'Yuan Renminbi',
    alphabeticCode: 'CNY',
    numericCode: '156',
  },
  ZMW: {
    currencyName: 'Zambian Kwacha',
    alphabeticCode: 'ZMW',
    numericCode: '967',
  },
  ZWL: {
    currencyName: 'Zimbabwe Dollar',
    alphabeticCode: 'ZWL',
    numericCode: '932',
  },
  PLN: {
    currencyName: 'Zloty',
    alphabeticCode: 'PLN',
    numericCode: '985',
  },
  GBX: {
    currencyName: 'Pence Sterling',
    alphabeticCode: 'GBX',
    numericCode: '',
  },
};
