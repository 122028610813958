/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OfferingsFilterForm_ShareholderPartsFragment = {
  __typename?: 'Shareholder';
  id: string;
  name?: string | null;
};

export type OfferingsFilterForm_ShareholdersQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ShareholderFilterInput>;
}>;

export type OfferingsFilterForm_ShareholdersQuery = {
  __typename?: 'Query';
  shareholders?: {
    __typename?: 'ShareholderCollectionSegment';
    totalCount: number;
    items?: Array<{ __typename?: 'Shareholder'; id: string; name?: string | null }> | null;
  } | null;
};

export const OfferingsFilterForm_ShareholderPartsFragmentDoc = gql`
  fragment OfferingsFilterForm_ShareholderParts on Shareholder {
    id
    name
  }
`;
export const OfferingsFilterForm_ShareholdersDocument = gql`
  query OfferingsFilterForm_Shareholders($filter: ShareholderFilterInput) {
    shareholders(where: $filter) {
      items {
        ...OfferingsFilterForm_ShareholderParts
      }
      totalCount
    }
  }
  ${OfferingsFilterForm_ShareholderPartsFragmentDoc}
`;

/**
 * __useOfferingsFilterForm_ShareholdersQuery__
 *
 * To run a query within a React component, call `useOfferingsFilterForm_ShareholdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingsFilterForm_ShareholdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingsFilterForm_ShareholdersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOfferingsFilterForm_ShareholdersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OfferingsFilterForm_ShareholdersQuery,
    OfferingsFilterForm_ShareholdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingsFilterForm_ShareholdersQuery,
    OfferingsFilterForm_ShareholdersQueryVariables
  >(OfferingsFilterForm_ShareholdersDocument, options);
}
export function useOfferingsFilterForm_ShareholdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingsFilterForm_ShareholdersQuery,
    OfferingsFilterForm_ShareholdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingsFilterForm_ShareholdersQuery,
    OfferingsFilterForm_ShareholdersQueryVariables
  >(OfferingsFilterForm_ShareholdersDocument, options);
}
export type OfferingsFilterForm_ShareholdersQueryHookResult = ReturnType<
  typeof useOfferingsFilterForm_ShareholdersQuery
>;
export type OfferingsFilterForm_ShareholdersLazyQueryHookResult = ReturnType<
  typeof useOfferingsFilterForm_ShareholdersLazyQuery
>;
export type OfferingsFilterForm_ShareholdersQueryResult = Apollo.QueryResult<
  OfferingsFilterForm_ShareholdersQuery,
  OfferingsFilterForm_ShareholdersQueryVariables
>;
