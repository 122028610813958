import { createTestId } from '../../../../common/selectors';

const create = (name: string) => createTestId(`xc-offering-side-panel-communicated-demand-${name}`);

export const offeringSidePanelCommunicatedDemandFinalAllocationShares =
  create('final-allocation-shares');
export const offeringSidePanelCommunicatedDemandFinalAllocationCurrency = create(
  'final-allocation-currency'
);
export const offeringSidePanelCommunicatedDemandFinalAllocationOfferPrice = create(
  'final-allocation-offer-price'
);
