import { createTestId } from '../../../common/selectors';

const create = name => createTestId(`rolodex-crm-integration-detail-${name}`);

export const crmInvestorFirmListSearchInput = create('firm-list-search-input');
export const crmInvestorFirmListLinkStatusSelect = create('firm-list-link-status-select');
export const crmInvestorFirmListInvestorCategorySelect = create(
  'firm-list-investor-category-select'
);

export const crmInvestorFirmListInvestorStatusSelect = create('firm-list-investor-status-select');
