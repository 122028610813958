import { datalabApi } from '@cmg/api';
import { checkPermissions, permissionsByEntity, useAuth } from '@cmg/auth';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTheme } from 'styled-components/macro';

import { CMGTheme } from '../../../types/vendor/styled-components/styled-components';
import SectorsModal, {
  OnSubmit,
} from '../../settings/companies/companies-grid-legacy/SectorsModal';
import { selectUserSettings } from '../ducks';
import { fetchSectors, selectSectors } from './ducks';
import SectorPickerComponent from './SectorPicker';
import SectorToggleComponent from './SectorToggle';

const mapStateToProps = state => ({
  userSettings: selectUserSettings(state),
  sectors: selectSectors(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchSectors,
    },
    dispatch
  ),
});

type OwnProps = { onSubmit: OnSubmit; theme: CMGTheme };
type SectorToggleType = React.FC<{}>;
type SectorPickerType = React.FC<{ sector?: string | null; customSector?: string | null }>;
type RenderProps = {
  SectorToggle: SectorToggleType;
  SectorPicker: SectorPickerType;
  sectors: datalabApi.CustomSectorDto[] | null;
  userPermissions: string[];
};
type ChildProps = OwnProps & { children: (renderProps: RenderProps) => React.ReactNode };
export type Props = ChildProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

/**
 * RenderProp Component giving prepared components, props and methods for switching and updating custom-sectors
 */
export const SectorProviderComponent: React.FC<Props> = ({
  children,
  actions,
  onSubmit,
  sectors,
  userSettings,
  theme,
}) => {
  const { userPermissions } = useAuth();
  const [useCustomSectors, setUseCustomSectors] = useState(!!userSettings.useCustomSectors);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    !sectors && actions.fetchSectors();
  }, [sectors, actions]);

  const handleToggle = (useCustomSectors: boolean) => setUseCustomSectors(useCustomSectors);

  const pickerProps = { useCustomSectors, userPermissions, onPick: () => setShowModal(true) };
  const SectorPicker: SectorPickerType = ({ sector, customSector }) => {
    return <SectorPickerComponent {...pickerProps} sector={sector} customSector={customSector} />;
  };

  const SectorToggle: SectorToggleType = () =>
    !checkPermissions(userPermissions, [permissionsByEntity.CustomSectors.READ]) ? (
      <React.Fragment>Sector</React.Fragment>
    ) : (
      <SectorToggleComponent
        value={useCustomSectors}
        onChange={handleToggle}
        color={theme.color.blue2}
      />
    );

  return (
    <React.Fragment>
      {children({ SectorToggle, SectorPicker, sectors, userPermissions })}
      <SectorsModal
        onSubmit={onSubmit}
        sectors={sectors || undefined}
        show={showModal}
        onHide={() => setShowModal(false)} // todo
      />
    </React.Fragment>
  );
};

const ConnectedSectorProvider = connect(
  mapStateToProps,
  mapDispatchToProps
)(SectorProviderComponent);

export default withTheme(ConnectedSectorProvider);
