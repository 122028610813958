import styled from 'styled-components/macro';

export const SRow = styled.div`
  display: flex;
  justify-content: space-between;

  .select-filter,
  .range-filter {
    width: 275px;
  }
`;
