import { createTestId } from '../../../../common/selectors';

const create = (name: string) =>
  createTestId(`order-allocation-communicated-demand-ioi-tracker-bar-${name}`);

export const editMyIoiTrackerIndicationAllocation = create('edit');

export const passMyIoiTrackerIndicationAllocation = create('pass');

export const reActivateMyIoiTrackerIndicationAllocation = create('re-activate');
