import { GridApi, GridReadyEvent, RowNode } from 'ag-grid-community';
import React, { useCallback } from 'react';

import DataGrid, { PaginationQueryParams, Props as GridProps } from './DataGrid';
import useGrouping, { GroupRow, UseGroupingParams } from './hooks/useGrouping';
import FullWidthCellRenderer from './renderers/FullWidthCellRenderer';

const defaultPagination = {
  page: 1,
  perPage: DataGrid.DEFAULT_PER_PAGE,
};
type GroupingProps<ClientTRow> = UseGroupingParams<ClientTRow>;
export type Props<ClientTRow> = GroupingProps<ClientTRow> &
  Omit<GridProps<any>, 'pagination' | 'onPaginationChange' | 'totalPages'>;

/**
 * DataGridGroupingClient is a wrapper around AgGrid that renders the provided data grouped by a certain field and handles
 * sorting, client-side
 */
const DataGridGroupingClient = <ClientTRow extends any>(props: Props<ClientTRow>) => {
  const {
    rows,
    groupByField,
    groupHeaderRenderer,
    groupHeaderStyles,
    groupHeaderComparator,
    groupHeaderOrderDirection,
    orderComparator,
    orderField: defaultOrderField,
    orderDirection: defaultOrderDirection,
    onGridReady,
  } = props;
  const [gridApi, setGridApi] = React.useState<GridApi>();
  const [pagination, setPagination] = React.useState<PaginationQueryParams>({
    ...defaultPagination,
    orderField: defaultOrderField,
    orderDirection: defaultOrderDirection,
  });
  const { orderDirection, orderField } = pagination;
  const groupedData = useGrouping<ClientTRow>({
    sourceRows: rows,
    groupByField: groupByField,
    groupHeaderRenderer: groupHeaderRenderer,
    groupHeaderStyles: groupHeaderStyles,
    orderField: orderField ?? defaultOrderField,
    orderDirection: orderDirection ?? defaultOrderDirection,
    groupHeaderComparator,
    groupHeaderOrderDirection,
    orderComparator,
    gridApi,
  });

  const onGridReadyCallback = useCallback(
    (event: GridReadyEvent) => {
      setGridApi(event.api);
      // we want to trigger client side sort client-side onload
      event.api.onSortChanged();
      onGridReady && onGridReady(event);
    },
    [onGridReady]
  );

  // resets page & perPage on every change in rows - during filtering for example
  React.useEffect(() => {
    setPagination(pagination => ({
      ...pagination,
      ...defaultPagination,
    }));
  }, [props.rows]);

  return (
    <DataGrid<ClientTRow | GroupRow>
      {...props}
      extended={{
        ...props.extended,
        // we don't use pagination UI with grouping
        hidePagination: true,
      }}
      onGridReady={onGridReadyCallback}
      domLayout={
        props.domLayout === 'normal' || props.extended?.fillViewport ? 'normal' : 'autoHeight'
      }
      gridOptions={{
        // assumption: out of the box the data grid client - full width rows are ONLY used to render group headers and will only render
        // when isFullWidthCell is true.
        // note: the consumer has the ability override all of the full width properties
        fullWidthCellRenderer: 'fullWidthCellRenderer',
        frameworkComponents: {
          ...props.gridOptions?.frameworkComponents,
          fullWidthCellRenderer: FullWidthCellRenderer,
        },
        isFullWidthCell: (rowNode: RowNode) => !!rowNode.data?.__groupHeader || !!rowNode.footer,
        ...props.gridOptions,
      }}
      rows={groupedData}
      pagination={pagination}
      onPaginationChange={setPagination}
    />
  );
};

export default DataGridGroupingClient;
