import { Box } from '@cmg/common';
import { Form } from 'antd';
import styled from 'styled-components/macro';

export const StyledBox = styled(Box)`
  height: calc(100% - 14px);
  margin-bottom: 14px;

  > div {
    height: 100%;
  }
`;

export const StyledForm = styled(Form)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .ant-col {
    flex-grow: 1;
  }
`;
