import { datalabApi } from '@cmg/api';
import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../common/util/routeFactory';

type Props = {
  label: string;
  offering: datalabApi.CalendarOffering;
};

const OfferingProfileLinkRenderer: React.FC<Props> = ({ label, offering }) => {
  const url = routeFactory.offerings.getUrlPath({ id: offering.id });

  return (
    <Link to={url} style={{ flex: '1 1 auto', paddingRight: 10 }}>
      {label}
    </Link>
  );
};

export default OfferingProfileLinkRenderer;
