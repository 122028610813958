/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ManagerFragment = { __typename?: 'Manager'; name?: string | null; id: string };

export type OfferingFragment = {
  __typename: 'Offering';
  id: string;
  managers: Array<{
    __typename?: 'OfferingManager';
    manager?: { __typename?: 'Manager'; name?: string | null; id: string } | null;
  }>;
};

export type GetUnderwritersQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.OfferingFilterInput>;
}>;

export type GetUnderwritersQuery = {
  __typename?: 'Query';
  offerings?: {
    __typename: 'OfferingCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename: 'Offering';
      id: string;
      managers: Array<{
        __typename?: 'OfferingManager';
        manager?: { __typename?: 'Manager'; name?: string | null; id: string } | null;
      }>;
    }> | null;
  } | null;
};

export const ManagerFragmentDoc = gql`
  fragment Manager on Manager {
    name
    id
  }
`;
export const OfferingFragmentDoc = gql`
  fragment Offering on Offering {
    id
    managers {
      manager {
        ...Manager
      }
    }
    __typename
  }
  ${ManagerFragmentDoc}
`;
export const GetUnderwritersDocument = gql`
  query GetUnderwriters($filter: OfferingFilterInput) {
    offerings(where: $filter) {
      items {
        ...Offering
      }
      totalCount
      __typename
    }
  }
  ${OfferingFragmentDoc}
`;

/**
 * __useGetUnderwritersQuery__
 *
 * To run a query within a React component, call `useGetUnderwritersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnderwritersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnderwritersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetUnderwritersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUnderwritersQuery, GetUnderwritersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUnderwritersQuery, GetUnderwritersQueryVariables>(
    GetUnderwritersDocument,
    options
  );
}
export function useGetUnderwritersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUnderwritersQuery, GetUnderwritersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUnderwritersQuery, GetUnderwritersQueryVariables>(
    GetUnderwritersDocument,
    options
  );
}
export type GetUnderwritersQueryHookResult = ReturnType<typeof useGetUnderwritersQuery>;
export type GetUnderwritersLazyQueryHookResult = ReturnType<typeof useGetUnderwritersLazyQuery>;
export type GetUnderwritersQueryResult = Apollo.QueryResult<
  GetUnderwritersQuery,
  GetUnderwritersQueryVariables
>;
