import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import React from 'react';

import { ExpandableGroup } from '../../form/select/ExpandableGroup';
import { Group, Option } from '../../form/select/types';
import Icon from '../../graphics/icon/Icon';
import {
  SGroupLabelWrapper,
  SGroupLeafList,
  SGroupLeafWrapper,
  STicker,
  StyledCheckbox,
} from './CheckboxTreeGroup.styles';
import CheckboxTreeLeaf from './CheckboxTreeLeaf';

export type Props<TOptionValue> = {
  /**
   * List of selected children
   */
  values?: TOptionValue[];
  /**
   * List of disabled children
   */
  disabled?: TOptionValue[];
  /**
   * Expand/collapse group
   */
  expanded?: boolean;
  /**
   * Tree group
   */
  group: Group<TOptionValue>;
  /**
   * Enables style tweaks
   */
  className?: string;
  /**
   * Event callback to fire on selection change
   */
  onChange: (values: TOptionValue[]) => void;
  /**
   * Event callback to file on toggle expand/collapse
   */
  onToggle: (group: Group<TOptionValue>) => void;
};

/**
 * Tree node with children inside it.
 */
const CheckboxTreeGroup = <TOptionValue,>({
  className,
  values = [],
  disabled = [],
  expanded = false,
  group,
  onChange,
  onToggle,
}: Props<TOptionValue>) => {
  const handleLeafSelectionChange = (option: Option<TOptionValue>, isSelected: boolean) => {
    const nextSelected = isSelected
      ? [...values, option.value]
      : values.filter(value => value !== option.value);

    onChange(nextSelected);
  };

  const handleGroupSelectionChange = (checked: boolean) => {
    const groupOptionValues = group.options.map(option => option.value);
    const groupSelection = values.filter(
      value => groupOptionValues.includes(value) && !disabled.includes(value)
    );

    let nextValues;

    if (checked || !groupSelection.length) {
      nextValues = [...values, ...groupOptionValues];
    } else {
      nextValues = values.filter(value => !groupSelection.includes(value));
    }

    // Remove possible duplicated values
    nextValues = uniqWith(nextValues, isEqual);
    onChange(nextValues);
  };

  const isDisabled = group.options.every(option => disabled.includes(option.value));
  const allSelected = group.options.every(option => values.includes(option.value));
  const someSelected = group.options.some(option => values.includes(option.value));
  const intermediate = someSelected && !allSelected;

  const data = {
    ...group,
    expanded,
  };

  return (
    <div className={className}>
      <ExpandableGroup
        data={data}
        onToggle={onToggle}
        label={
          <SGroupLabelWrapper>
            <Icon
              fixedWidth
              name={data.expanded ? 'caret-down' : 'caret-right'}
              variant="solid"
              size="lg"
            />
            <StyledCheckbox
              indeterminate={intermediate}
              value={allSelected || someSelected}
              onChange={handleGroupSelectionChange}
              disabled={isDisabled}
            />
            {group.label}
          </SGroupLabelWrapper>
        }
      >
        <SGroupLeafList>
          {group.options.map(option => (
            <SGroupLeafWrapper key={`${option.value}`}>
              <STicker />
              <CheckboxTreeLeaf
                isDisabled={disabled.includes(option.value)}
                isSelected={values.includes(option.value)}
                option={option}
                onChange={handleLeafSelectionChange}
              />
            </SGroupLeafWrapper>
          ))}
        </SGroupLeafList>
      </ExpandableGroup>
    </div>
  );
};

export default CheckboxTreeGroup;
