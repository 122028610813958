import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from '@microsoft/signalr';

import { createReconnectStategy, defaultIntervalStrategy } from './SignalRReconnectStategy';

// How this works:
// A connection to each hub is created, but not started on startup.
// Once the first listener to a specific hub is registered, the connection to the hub is started.
// Once the last listener to a specific hub is unregistered, the connection to the hub is closed.

// Edit the following section when adding a new event:

// --------- section start ---------
/**
 * An event sent from the hub to the client.
 */
export enum SignalREvent {
  CALENDAR_REFRESH = 'RefreshCalendar',
}

/**
 * Urls to specific hubs
 */
export enum SignalRHub {
  CALENDAR_REFRESH = '/os/signalr/calendarRefresh',
}

/**
 * Assigns events to hubs
 */
export const signalRHubByEvent: { [eventType in SignalREvent]: SignalRHub } = {
  [SignalREvent.CALENDAR_REFRESH]: SignalRHub.CALENDAR_REFRESH,
};

/**
 * Additional data sent with each event
 */
export type SignalRCallbackByEvent = {
  [SignalREvent.CALENDAR_REFRESH]: (offeringId: string) => void;
};

// The following ensures that SignalRCallbackByEvent contains definitions for every SignalREvent.

/* eslint-disable @typescript-eslint/no-unused-vars */
type Guard<T extends { [eventType in SignalREvent]: Function }> = null;
const guard: Guard<SignalRCallbackByEvent> = null;
/* eslint-enable @typescript-eslint/no-unused-vars */

// --------- section end ---------

const hubConnectionTransitionalStates: HubConnectionState[] = [
  HubConnectionState.Connecting,
  HubConnectionState.Disconnecting,
  HubConnectionState.Reconnecting,
];

export type SignalRContextInterface = {
  connectionsStore: SignalRConnectionStore | null;
};

export class SignalRConnectionStore {
  constructor(token: string, logLevel?: LogLevel) {
    this.hubs = Object.values(SignalRHub).reduce((acc, hubRoute) => {
      let connection = new HubConnectionBuilder()
        .withUrl(`${window.location.origin}${hubRoute}`, {
          accessTokenFactory: () => token,
        })
        .withAutomaticReconnect(createReconnectStategy(defaultIntervalStrategy));

      if (logLevel) {
        connection = connection.configureLogging(logLevel);
      }

      const builtConnection = connection.build();
      const connectionInfo = { connection: builtConnection, desiredState: undefined };
      builtConnection.onreconnected(() => {
        void this.goToDesiredStateIfNotTransitioning(connectionInfo);
      });

      return {
        ...acc,
        [hubRoute]: {
          connectionInfo,
          numListeners: 0,
        },
      };
    }, {} as typeof this.hubs);
  }

  private readonly hubs: {
    [hub in SignalRHub]: {
      connectionInfo: {
        connection: HubConnection;
        desiredState: HubConnectionState.Disconnected | HubConnectionState.Connected | undefined;
      };
      numListeners: number;
    };
  };

  private goToDesiredStateIfNotTransitioning = async (
    connectionInfo: (typeof this.hubs)[SignalRHub]['connectionInfo']
  ) => {
    const currentState = connectionInfo.connection.state;
    const desiredState = connectionInfo.desiredState;

    if (currentState === desiredState || hubConnectionTransitionalStates.includes(currentState)) {
      return;
    }

    if (desiredState === HubConnectionState.Connected) {
      await connectionInfo.connection.start();
    }
    if (desiredState === HubConnectionState.Disconnected) {
      await connectionInfo.connection.stop();
    }

    // Something else could have changed the desired state while we were starting / stopping
    await this.goToDesiredStateIfNotTransitioning(connectionInfo);
  };

  addListener: <TEventType extends SignalREvent>(
    eventType: TEventType,
    callback: SignalRCallbackByEvent[TEventType]
  ) => Promise<void> = async (eventType, callback) => {
    const hubInfo = this.hubs[signalRHubByEvent[eventType]];

    hubInfo.connectionInfo.connection.on(eventType, callback);

    hubInfo.numListeners += 1;
    hubInfo.connectionInfo.desiredState = HubConnectionState.Connected;

    await this.goToDesiredStateIfNotTransitioning(hubInfo.connectionInfo);
  };

  removeListener: <TEventType extends SignalREvent>(
    eventType: TEventType,
    callback: SignalRCallbackByEvent[TEventType]
  ) => Promise<void> = async (eventType, callback) => {
    const hubInfo = this.hubs[signalRHubByEvent[eventType]];

    hubInfo.numListeners = Math.max(0, hubInfo.numListeners - 1);
    hubInfo.connectionInfo.connection.off(eventType, callback);

    if (hubInfo.numListeners === 0) {
      hubInfo.connectionInfo.desiredState = HubConnectionState.Disconnected;
    }

    await this.goToDesiredStateIfNotTransitioning(hubInfo.connectionInfo);
  };

  removeAllListeners: () => Promise<void[]> = () => {
    return Promise.all(
      Object.values(this.hubs).map(async hubInfo => {
        hubInfo.numListeners = 0;
        hubInfo.connectionInfo.desiredState = HubConnectionState.Disconnected;
        await this.goToDesiredStateIfNotTransitioning(hubInfo.connectionInfo);
      })
    );
  };
}
