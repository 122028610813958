import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import type { TableCellProps } from '@mui/material/TableCell';
import TableCell from '@mui/material/TableCell';
import type { TableContainerProps } from '@mui/material/TableContainer';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import React, { useMemo } from 'react';

import { TableRow } from './TableRow';

export type TableSkeletonProps = Readonly<{
  readonly 'aria-label'?: TableContainerProps['aria-label'];
  /**
   * Size of the table cells
   */
  readonly cellSize?: TableCellProps['size'];
  /**
   * Number of header rows
   * @default 1
   */
  readonly numOfHeaderRows?: number;
  /**
   * Number of rows
   * @default 5
   */
  readonly numOfRows?: number;
  /**
   * Number of columns
   * @default 5
   */
  readonly numOfColumns?: number;
}>;

export const TableSkeleton: React.VFC<TableSkeletonProps> = ({
  cellSize,
  'aria-label': ariaLabel,
  numOfHeaderRows = 1,
  numOfRows = 5,
  numOfColumns = 5,
}) => {
  const headerRows = useMemo(() => Array.from(Array(numOfHeaderRows).keys()), [numOfHeaderRows]);
  const rows = useMemo(() => Array.from(Array(numOfRows).keys()), [numOfRows]);
  const columns = useMemo(() => Array.from(Array(numOfColumns).keys()), [numOfColumns]);

  return (
    <TableContainer tabIndex={0} role="progressbar" aria-label={ariaLabel}>
      <Table>
        {numOfHeaderRows > 0 && (
          <TableHead>
            {headerRows.map(headerRowId => (
              <TableRow key={headerRowId}>
                {columns.map(columnId => (
                  <TableCell key={columnId} size={cellSize}>
                    <Skeleton />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
        )}
        <TableBody>
          {rows.map(rowId => (
            <TableRow key={rowId}>
              {columns.map(columnId => (
                <TableCell key={columnId} size={cellSize}>
                  <Skeleton />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
