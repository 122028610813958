import styled from 'styled-components/macro';

import Icon from '../../graphics/icon/Icon';

export const SBreadcrumb = styled.div`
  display: inline-block;
`;

export const StyledBreadcrumbIcon = styled(Icon)`
  color: ${({ theme }) => theme.color.black};
  margin: 0 10px;
`;
