import { RegularBreakpoints } from '@cmg/design-system';

import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import OfferingProfileCardItem from '../../shared/OfferingProfileCardItem';
import { useUnderwritingTerms } from '../AtmProfile.model';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
};

const UnderwritingTermsWidget: React.FC<Props> = ({ offeringProfile, cardSizes }) => {
  const advisers = useUnderwritingTerms(offeringProfile);

  return (
    <OfferingProfileCardItem title="Underwriting Terms" cardSizes={cardSizes} rowData={advisers} />
  );
};

export default UnderwritingTermsWidget;
