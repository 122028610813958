import { createTestId } from '../../../common/selectors';

const create = (name: string) => createTestId(`xc-wires-termination-${name}`);

export const wiresTerminationEditButton = create('edit-button');
export const wiresTerminationDeleteButton = create('delete-button');
export const wiresTerminationSendButton = create('send-button');

export const wiresTerminationModalCancelButton = create('modal-cancel-button');
export const wiresTerminationModalSaveButton = create('modal-save-button');

export const wiresTerminationFormTerminationDateInput = create('form-termination-date-input');
