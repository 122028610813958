import { createTestId } from '../../../common/selectors';

const create = (name: string) => createTestId(`xc-roadshow-participants-form-${name}`);

export const roadshowParticipantsFormSaveButton = create('save-button');
export const roadshowParticipantsFormDeleteParticipantButton = create('delete-participant-button');
export const roadshowParticipantsFormAddParticipantButton = create('add-participant-button');

export const roadshowParticipantsCreateModalCancelButton = create('new-participants-cancel-button');
export const roadshowParticipantsCreateModalSubmitButton = create('new-participants-submit-button');
