import mixpanel from 'mixpanel-browser';
import React from 'react';

import * as thisModule from './mixpanelLogger';

type MixpanelAppContextValue = {
  appName: string;
  userId: string | null;
  accountType: 'SYSTEM' | 'SELL_SIDE' | 'BUY_SIDE' | null;
};
type MixpanelModuleContextValue = { moduleName: string };
type MixpanelContextValue = MixpanelAppContextValue & Partial<MixpanelModuleContextValue>;
type MixpanelMessage = MixpanelContextValue & {
  subdomain: string;
};

export const MixpanelAppContext = React.createContext<MixpanelAppContextValue | undefined>(
  undefined
);
export const MixpanelModuleContext = React.createContext<MixpanelModuleContextValue | undefined>(
  undefined
);

export const useMixpanelContext: () => MixpanelContextValue = () => {
  const appContext = React.useContext(MixpanelAppContext);
  const moduleContext = React.useContext(MixpanelModuleContext);

  if (appContext === undefined) {
    throw new Error('No Provider found for MixpanelAppContext');
  }

  return { ...appContext, ...moduleContext };
};

const globalInitializedStatus = { isInitialized: false };

export const getInitializedStatus = () => {
  return globalInitializedStatus;
};

export const initMixpanel = ({
  token,
  apiHost,
  isDevelopment = false,
}: {
  /**
   * Mixpanel API token
   */
  token: string;
  /**
   * URL to use for the tracking API calls
   */
  apiHost?: string;
  /**
   * Is SPA running in development mode
   */
  isDevelopment?: boolean;
}) => {
  if (thisModule.getInitializedStatus().isInitialized) {
    throw new Error('Mixpanel is already initialized.');
  }

  mixpanel.init(token, { api_host: apiHost, debug: isDevelopment });
  thisModule.getInitializedStatus().isInitialized = true;
};

export const useMixpanel = () => {
  const mixpanelContext = useMixpanelContext();
  const subdomain = window.location.hostname.split('.')[0];

  if (!thisModule.getInitializedStatus().isInitialized) {
    throw new Error('Mixpanel is not initialized.');
  }

  const log = React.useCallback(
    (message: string, extraData: Record<string, any> = {}) => {
      const data: MixpanelMessage = {
        subdomain,
        ...mixpanelContext,
      };

      mixpanel.track(message, { ...data, ...extraData });
    },
    [subdomain, mixpanelContext]
  );

  return React.useMemo(() => ({ log }), [log]);
};
