import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import type { RadioProps as MUIRadioProps } from '@mui/material/Radio';
import MUIRadio from '@mui/material/Radio';
import React from 'react';

export type RadioProps = Omit<MUIRadioProps, 'icon'>;

export const Radio: React.FC<RadioProps> = (props: RadioProps) => {
  const iconColor = props.color === 'error' ? 'error' : undefined;

  return <MUIRadio {...props} icon={<RadioButtonUncheckedOutlinedIcon color={iconColor} />} />;
};
