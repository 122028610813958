import datadog from './datadog/datadogLogger';

/**
 * Initializes Datadog Real User Monitoring
 * and Logging.
 */
export const initDatadog = (config: {
  /**
   * Datadog Application ID
   */
  datadogApplicationId?: string;
  /**
   * Datadog Client Token
   */
  datadogClientToken?: string;
  /**
   * An identifier for the environment
   * the application is running in.
   */
  clusterEnvironment?: string;
  /**
   * Datadog 'Service' - unique per SPA
   */
  serviceName: string;
  /**
   * Datadog regex or string of origins that will be traced
   * e.g. /http:\/\/.*\.local\.oncmg\.io/
   */
  allowedTracingOrigin: RegExp;
  /**
   * Is SPA running in development mode
   */
  isDevelopment?: boolean;
  /**
   * SPA Version
   */
  version?: string;
}): void => {
  datadog.init(config);
};

/**
 * Sends error information to our application monitoring providers.
 *
 * @error - an Error object
 * @context - extra attributes to be collected with the logged event.
 */
export const error = (error: Error, context?: Object): void => {
  datadog.logError({
    message: error.message,
    context: {
      ...context,
      error: {
        message: error.message,
        stack: error.stack,
      },
    },
  });
};

/**
 * Logs warning to our application monitoring providers.
 *
 * @message - The log event message
 * @context - extra attributes to be collected with the logged event.
 */
export const warning = (message: string, context: Object = {}): void => {
  datadog.logWarning({ message, context });
};

/**
 * Logs information to our application monitoring providers.
 *
 * @message - The log event message
 * @context - extra attributes to be collected with the logged event.
 */
export const info = (message: string, context: Object = {}): void => {
  datadog.logInfo({ message, context });
};

export {
  initMixpanel,
  MixpanelAppContext,
  MixpanelModuleContext,
  useMixpanel,
} from './mixpanel/mixpanelLogger';
