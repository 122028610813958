import isNil from 'lodash/isNil';
import React from 'react';

import CurrencyFormatter from '../../../../../common/components/currency-formatter/CurrencyFormatter';
import { getCurrencyFormatInMillions } from '../../../../shared/model/utils';
import { Filing, Pricing } from '../../model/offering';

const getPriceRangeString = (
  pricingLow: number | null,
  pricingHigh: number | null,
  pricingCurrencyCode: string
) => {
  return !isNil(pricingLow) && !isNil(pricingHigh) ? (
    <React.Fragment>
      <CurrencyFormatter
        value={pricingLow}
        currency={pricingCurrencyCode}
        displayVariant="symbol"
      />
      {' - '}
      <CurrencyFormatter
        value={pricingHigh}
        currency={pricingCurrencyCode}
        displayVariant="symbol"
      />
    </React.Fragment>
  ) : (
    '-'
  );
};
export const getFormattedPriceColumnValue = ({
  isFollowOn,
  filing,
  pricing,
  pricingCurrencyCode = 'USD',
  showInternational,
}: {
  isFollowOn: boolean;
  filing: Filing;
  pricing?: Pricing;
  pricingCurrencyCode?: string;
  showInternational?: boolean;
}) => {
  const localCurrFieldName = showInternational ? 'LocalCurr' : '';
  const value = filing[`offeringPrice${localCurrFieldName}`];
  if (value) {
    return (
      <CurrencyFormatter value={value} currency={pricingCurrencyCode} displayVariant="symbol" />
    );
  } else if (isFollowOn && pricing) {
    return getPriceRangeString(
      pricing[`reOfferLow${localCurrFieldName}`],
      pricing[`reOfferHigh${localCurrFieldName}`],
      pricingCurrencyCode
    );
  }

  return getPriceRangeString(
    filing[`ipoRangeLow${localCurrFieldName}`],
    filing[`ipoRangeHigh${localCurrFieldName}`],
    pricingCurrencyCode
  );
};

export const getFormattedSizeColumnValue = ({
  row,
  pricingCurrencyCode = 'USD',
  showInternational,
}: {
  row: Filing;
  pricingCurrencyCode?: string;
  showInternational: boolean;
}): string => {
  const value = showInternational ? row.sizeInLocalCurr : row.sizeInDollars;
  return (
    getCurrencyFormatInMillions({
      value,
      pricingCurrencyCode,
      showInternational,
      keepSmallValues: true,
    }) ?? '-'
  );
};
