import type {
  ColDef as AgGridColDef,
  Column,
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowNode,
  SelectionChangedEvent,
  ValueFormatterParams as AgGridValueFormatterParams,
  ValueGetterParams as AgGridValueGetterParams,
} from 'ag-grid-community';
import deepMerge from 'deepmerge';
import type { CSSProperties } from 'react';

import type { Maybe } from '../../../../types';
import FullWidthCellRenderer from '../renderers/FullWidthCellRenderer';
import LongStringCellRenderer from '../renderers/LongStringCellRenderer';
import IconLabelRenderer from './columns/IconLabelRenderer';
import LinkRenderer from './columns/LinkRenderer';
import { NumericInputForm } from './columns/NumericInputForm';
import RowSelectorRenderer from './columns/RowSelectorRenderer';
import ValidationRenderer from './columns/ValidationRenderer';

export interface ResizeProps {
  /**
   * resize grid by cell content or resize so text fits
   */
  readonly resize?: Maybe<'column' | 'grid' | boolean>;
}

export const defaultRowHeight = 32;

const defaultCellStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'normal',
  lineHeight: 1.2,
  height: '100%',
  fontSize: '13px',
  color: 'black',
  border: 0,
};

export const defaultColDef: Partial<AgGridColDef> = {
  suppressColumnsToolPanel: true,
  resizable: true,
  flex: 1,
  sortable: true,
  editable: params => !params.node.isRowPinned(),
  cellStyle: params =>
    params.node.isRowPinned()
      ? { ...defaultCellStyle, color: 'white', fontSize: '14px' }
      : { ...defaultCellStyle, backgroundColor: 'transparent' },
};

export const defaultRowStyle: CSSProperties = { cursor: 'default' };

export const defaultGridOptions: GridOptions = {
  suppressCellSelection: true,
  suppressRowClickSelection: true,
  suppressRowHoverHighlight: true,
  suppressPaginationPanel: true,
};

export const getAllColumnIds = (
  columnApi: ColumnApi,
  options: { autoSizedOnly?: boolean } = { autoSizedOnly: false }
) => {
  const allColumns = columnApi.getAllColumns() ?? [];
  return allColumns
    .filter(column => (options.autoSizedOnly ? !column.getColDef().suppressAutoSize : true))
    .map(column => column.getColId());
};

export const resizeGrid = (event: GridReadyEvent, config: ResizeProps) => {
  const { resize = true } = config;
  if (resize != null && resize) {
    const gridApi = event.api;
    const gridColumnApi = event.columnApi;

    gridApi.sizeColumnsToFit();

    if (resize === 'column') {
      gridColumnApi.autoSizeColumns(getAllColumnIds(event.columnApi, { autoSizedOnly: true }));
    }
  }
};

export const defaultFrameworkComponents = {
  numericalEditor: NumericInputForm,
  validationRenderer: ValidationRenderer,
  rowSelectorRenderer: RowSelectorRenderer,
  iconLabelRenderer: IconLabelRenderer,
  linkRenderer: LinkRenderer,
  fullWidthCellRenderer: FullWidthCellRenderer,
  longStringCellRenderer: LongStringCellRenderer,
};

export type OnGridReadyEventFn = (event: GridReadyEvent) => void;

export type ValueFormatterParams<Data = unknown, Value = unknown> = Omit<
  AgGridValueFormatterParams,
  'value' | 'data'
> & { value: Value; data: Data };

export type CellStyleParams<Data = unknown, Value = unknown> = ValueFormatterParams<Data, Value>;

export type CreateCellStyleFn<Data = unknown, Value = unknown> = (
  params: CellStyleParams<Data, Value>
) => CSSProperties;

export type ValueGetterParams<Data = unknown> = Omit<AgGridValueGetterParams, 'value' | 'data'> & {
  data: Data;
};

export type ValueFormatterFn<Data = unknown, Value = unknown> = (
  params: ValueFormatterParams<Data, Value>
) => string;

export type ValueGetterFn<Data = unknown> = (params: ValueGetterParams<Data>) => string;

export type HAlign = 'left' | 'right';

export type HAlignProps = {
  readonly hAlign?: Maybe<HAlign>;
};
export type CellRendererFieldValidation = 'error' | 'warning';

export type FieldValidationProps = {
  readonly validation?: Maybe<CellRendererFieldValidation>;
};

export type CellRendererProps<Value = unknown> = {
  readonly value?: Maybe<Value>;
  readonly valueFormatted?: string;
  readonly node: RowNode;
  readonly api: GridApi;
  readonly column: Column;
  readonly colDef: AgGridColDef;
};

export type SelectionChangedEventFn = (event: SelectionChangedEvent) => void;

export type ColDef<Data = unknown, Value = unknown> = AgGridColDef & {
  valueFormatter?: ValueFormatterFn<Data, Value>;
  valueGetter?: ValueGetterFn<Data>;
  comparator?: (
    valueA: Value,
    valueB: Value,
    nodeA: RowNode,
    nodeB: RowNode,
    isInverted: boolean
  ) => number;
  cellStyle?: CreateCellStyleFn<Data, Value>;
};

export type GridColumnDefMap<
  T extends string | number | symbol,
  Data = unknown,
  Value = unknown
> = {
  readonly [K in T]?: Partial<ColDef<Data, Value>>;
};

export const createColDef = <Data = unknown, Value = unknown>(
  colDef: Partial<ColDef<Data, Value>>
): ColDef<Data, Value> => deepMerge(defaultColDef as ColDef<Data, Value>, colDef);

export const createCellStyle =
  <Data = unknown, Value = unknown>(cellStyleFn: CreateCellStyleFn<Data, Value>) =>
  params =>
    params.node.isRowPinned()
      ? deepMerge({ ...defaultCellStyle, color: 'white', fontSize: '14px' }, cellStyleFn(params))
      : deepMerge({ ...defaultCellStyle, backgroundColor: 'transparent' }, cellStyleFn(params));
