import { useEffect } from 'react';

/**
 * Simple factory for a hook, with ability to set default document title for the instance.
 * Each SPA should only need to call this factory function once.
 * @param defaultTitle - when component unmounts, this is what document title is set too.
 * @returns
 */
export const createUseDocumentTitleHook = (defaultTitle: string) => {
  /**
   * A React Hook that sets the browser title. When unmounted the title is reset to a generic title.
   * @param title Browser title is set to this string
   */
  return function useDocumentTitle(title: string) {
    useEffect(() => {
      document.title = title;
      return () => {
        document.title = defaultTitle;
      };
    }, [title]);
  };
};
