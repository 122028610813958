import { datalabApi } from '@cmg/api';
import { TextInput } from '@cmg/common';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components/macro';

import { KEYS } from '../../../../shared/constants/constants';
import { selectSectors } from '../../../../shared/sectors/ducks';
import { submitSector, submitSubSector } from '../../ducks';
import { SectorPrimaryButton } from './styled';

// for validation purposes
const getSectorNames = (
  sectors: datalabApi.CustomSectorDto[] | null,
  parentId?: string,
  sector?: datalabApi.CustomSectorDto | datalabApi.CustomSubSectorDto
) => {
  let sectorList: datalabApi.CustomSectorDto[] | datalabApi.CustomSubSectorDto[] | null = sectors;

  if (parentId) {
    const parent = sectors && sectors.find(s => s.sectorId === parentId);
    sectorList = parent ? parent.subSectors : [];
  }

  if (sector) {
    sectorList = (sectorList as datalabApi.CustomSectorDto[]).filter(
      s => s.sectorId !== sector.sectorId
    );
  }

  return sectorList ? (sectorList as datalabApi.CustomSectorDto[]).map(s => s.sectorName) : [];
};

export const checkIsDuplicate = (sectorName: string, sectorNames: string[] = []): boolean =>
  sectorNames.some(s => s.toLowerCase() === sectorName.toLowerCase());

const mapStateToProps = state => ({
  sectors: selectSectors(state),
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ submitSector, submitSubSector }, dispatch),
});

export const SInvalidInput = styled.span`
  padding: 5px;
  color: ${({ theme }) => theme.text.color.error};
`;
export const SSectorInputWrapper = styled.div`
  display: inline-block;
`;
export const StyledSectorInput = styled(TextInput)`
  width: 250px;
  display: inline-block;
`;

type OwnProps = {
  parentId?: string;
  sector?: datalabApi.CustomSectorDto | datalabApi.CustomSubSectorDto;
  onSaved?: Function;
};
type Props = OwnProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export const SectorEdit = ({ sector, parentId, actions, onSaved, sectors }: Props) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    sector && setValue(sector.sectorName);
  }, [sector]);

  const handleSave = () => {
    const { sectorId } = sector || {};

    if (parentId) {
      actions.submitSubSector({ sectorId, parentId, data: { sectorName: value } });
    } else {
      actions.submitSector({ sectorId, data: { sectorName: value } });
    }

    onSaved && onSaved();
  };

  const handleChange = (value: string | null) => {
    const sectorName = value || '';
    setValue(sectorName);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.key === KEYS.ENTER && handleSave();
  };

  const isDuplicate = checkIsDuplicate(value, getSectorNames(sectors, parentId, sector));

  return (
    <React.Fragment>
      <SSectorInputWrapper>
        <StyledSectorInput
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          hasError={isDuplicate}
        />
        {isDuplicate && <SInvalidInput>Cannot add/edit a duplicate sector.</SInvalidInput>}
      </SSectorInputWrapper>
      <SectorPrimaryButton disabled={isDuplicate} onClick={handleSave}>
        Save
      </SectorPrimaryButton>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SectorEdit);
