import { Box, numericUtil, Popover, Table } from '@cmg/common';
import React from 'react';

import { UUID } from '../../../../../types/common';
import { ShareHolder } from '../../../../../types/domain/datalab-dlgw/shared/shareHolder';
import { AdvisorRole, advisorRoleLabels } from '../../../../../types/domain/offering/constants';
import { StyledTable } from './WidgetShareholdersAdvisors.styles';

// TODO: move to dlgw domain types
type Advisor = {
  firmId: UUID;
  firmName: string;
  role: AdvisorRole;
};

export type Props = {
  advisors: Advisor[];
  sponsors: ShareHolder[];
};

const WidgetShareholdersAdvisors: React.FC<Props> = ({ advisors, sponsors }) => {
  return (
    <Box>
      <div>
        <Box.Header title="Key Shareholders &amp; Advisers" />
        <Box.Content>
          <Table striped>
            <thead>
              <tr>
                <th>Adviser</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              {advisors.map(row => (
                <tr key={row.firmId}>
                  <td>{row.firmName}</td>
                  <td>{advisorRoleLabels[row.role]}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {sponsors.length > 0 && (
            <StyledTable striped>
              <thead>
                <tr>
                  <th>Shareholder</th>
                  <Table.RightHeader>Pre-offering Shares</Table.RightHeader>
                  <Table.RightHeader>Post-offering Shares</Table.RightHeader>
                </tr>
              </thead>
              <tbody>
                {sponsors.map(row => (
                  <tr key={row.firmId}>
                    <td>{row.firmName}</td>
                    <Table.RightCell>
                      <Popover
                        trigger="hover"
                        variant="TOOLTIP"
                        content={
                          <span>
                            Ownership: {numericUtil.formatPercents(row.pctPreOfferingOwnership, 1)}
                          </span>
                        }
                      >
                        <span>{numericUtil.formatInteger(row.preOfferingOwnership)}</span>
                      </Popover>
                    </Table.RightCell>
                    <Table.RightCell>
                      <Popover
                        trigger="hover"
                        variant="TOOLTIP"
                        content={
                          <span>
                            Ownership: {numericUtil.formatPercents(row.pctPostOfferingOwnership, 1)}
                          </span>
                        }
                      >
                        <span>{numericUtil.formatInteger(row.postOfferingOwnership)}</span>
                      </Popover>
                    </Table.RightCell>
                  </tr>
                ))}
              </tbody>
            </StyledTable>
          )}
        </Box.Content>
      </div>
    </Box>
  );
};

export default WidgetShareholdersAdvisors;
