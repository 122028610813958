import type { AlertProps } from '@mui/material/Alert';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import type { SxProps, Theme } from '@mui/material/styles';
import { styled, useTheme } from '@mui/material/styles';
import type { CustomContentProps, SharedProps } from 'notistack';
import { closeSnackbar } from 'notistack';
import type { FC, SyntheticEvent } from 'react';
import React, { useCallback, useMemo } from 'react';

type BaseAlertProps = Pick<CustomContentProps, 'id'> &
  Omit<AlertProps, 'id' | 'onClose'> &
  SharedProps;

const StyledPaper = styled(Paper, {
  name: 'BaseAlert',
  slot: 'Root',
  overridesResolver: (_props, styles) => [styles.root],
})({});

export const BaseAlert: FC<BaseAlertProps> = props => {
  const { children, id, severity, onClose, ...rest } = props;
  const theme = useTheme();
  const onCloseAlert = useCallback(
    (event: SyntheticEvent) => {
      if (onClose != null) {
        closeSnackbar(id);
        onClose(event, 'instructed');
      }
    },
    [id, onClose]
  );

  const sx = useMemo<SxProps<Theme>>(
    () => ({
      backgroundColor: theme.palette[severity ?? 'info'].main,
      color: theme.palette.primary.contrastText,
      '.MuiAlert-icon': {
        color: theme.palette.primary.contrastText,
      },
    }),
    [severity, theme.palette]
  );

  return (
    <StyledPaper elevation={8}>
      <Alert sx={sx} severity={severity} onClose={onClose && onCloseAlert} {...rest}>
        {children}
      </Alert>
    </StyledPaper>
  );
};
