import { FlagComponent } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

const SContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SFlag = styled(FlagComponent)`
  margin-right: 8px;
  margin-top: -3px;
`;

export type Props = {
  countryCode: string | null;
  countryDisplayName: string | null;
};

const CountryRenderer: React.FC<Props> = ({ countryCode, countryDisplayName }) => {
  if (!countryCode || !countryDisplayName) {
    return null;
  }

  return (
    <SContainer>
      <SFlag countryCode={countryCode} countryDisplayName={countryDisplayName} />
      {countryDisplayName}
    </SContainer>
  );
};

export default CountryRenderer;
