import type { ChipProps } from '@mui/material/Chip';
import React from 'react';

import { HighlightsChipItemList } from './HighlightsChipItemList';
import type { HighlightsItemProps } from './HighlightsItem';

export type HighlightsChipItemColors = ChipProps['color'];

export type HighlightsChipItemProps = Readonly<
  Pick<HighlightsItemProps, 'name' | 'data-test-id'> & Pick<ChipProps, 'label' | 'color'>
>;

export function HighlightsChipItem({
  label,
  color,
  ...restProps
}: HighlightsChipItemProps): JSX.Element {
  return <HighlightsChipItemList {...restProps} chips={[{ label, color }]} />;
}
