import { createTestId } from '../../../common/selectors';

const create = (name: string) => createTestId(`xc-order-book-header-${name}`);

export const orderBookHeaderInstitutionalDemand = create('institutional-demand');
export const orderBookHeaderRetailDemand = create('retail-demand');
export const orderBookHeaderCloseOrderBookButton = create('close-order-book-button');
export const orderBookHeaderReleaseFinalAllocationsButton = create(
  'release-final-allocations-button'
);
export const orderBookHeaderAllocationsReleasedStatus = create('allocations-released-status');
