/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OfferingsFilterForm_CustomSectorFieldsFragment = {
  __typename?: 'CustomSector';
  id: string;
  name?: string | null;
};

export type OfferingsFilterForm_CustomSectorPartsFragment = {
  __typename?: 'CustomSector';
  id: string;
  name?: string | null;
  children: Array<{ __typename?: 'CustomSector'; id: string; name?: string | null }>;
};

export type OfferingsFilterForm_CustomSectorsQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  where?: Types.InputMaybe<Types.CustomSectorFilterInput>;
  order?: Types.InputMaybe<Array<Types.CustomSectorSortInput> | Types.CustomSectorSortInput>;
}>;

export type OfferingsFilterForm_CustomSectorsQuery = {
  __typename?: 'Query';
  customSectors?: {
    __typename?: 'CustomSectorCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'CustomSector';
      id: string;
      name?: string | null;
      children: Array<{ __typename?: 'CustomSector'; id: string; name?: string | null }>;
    }> | null;
    pageInfo: {
      __typename?: 'CollectionSegmentInfo';
      hasNextPage: boolean;
      offset: number;
      pageSize: number;
    };
  } | null;
};

export const OfferingsFilterForm_CustomSectorFieldsFragmentDoc = gql`
  fragment OfferingsFilterForm_CustomSectorFields on CustomSector {
    id
    name
  }
`;
export const OfferingsFilterForm_CustomSectorPartsFragmentDoc = gql`
  fragment OfferingsFilterForm_CustomSectorParts on CustomSector {
    ...OfferingsFilterForm_CustomSectorFields
    children {
      ...OfferingsFilterForm_CustomSectorFields
    }
  }
  ${OfferingsFilterForm_CustomSectorFieldsFragmentDoc}
`;
export const OfferingsFilterForm_CustomSectorsDocument = gql`
  query OfferingsFilterForm_CustomSectors(
    $skip: Int = 0
    $take: Int = 100
    $where: CustomSectorFilterInput = {}
    $order: [CustomSectorSortInput!]
  ) {
    customSectors(
      skip: $skip
      take: $take
      where: { and: [{ type: { eq: SECTOR } }, $where] }
      order: $order
    ) {
      items {
        ...OfferingsFilterForm_CustomSectorParts
      }
      totalCount
      pageInfo {
        hasNextPage
        offset
        pageSize
      }
    }
  }
  ${OfferingsFilterForm_CustomSectorPartsFragmentDoc}
`;

/**
 * __useOfferingsFilterForm_CustomSectorsQuery__
 *
 * To run a query within a React component, call `useOfferingsFilterForm_CustomSectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingsFilterForm_CustomSectorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingsFilterForm_CustomSectorsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useOfferingsFilterForm_CustomSectorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OfferingsFilterForm_CustomSectorsQuery,
    OfferingsFilterForm_CustomSectorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingsFilterForm_CustomSectorsQuery,
    OfferingsFilterForm_CustomSectorsQueryVariables
  >(OfferingsFilterForm_CustomSectorsDocument, options);
}
export function useOfferingsFilterForm_CustomSectorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingsFilterForm_CustomSectorsQuery,
    OfferingsFilterForm_CustomSectorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingsFilterForm_CustomSectorsQuery,
    OfferingsFilterForm_CustomSectorsQueryVariables
  >(OfferingsFilterForm_CustomSectorsDocument, options);
}
export type OfferingsFilterForm_CustomSectorsQueryHookResult = ReturnType<
  typeof useOfferingsFilterForm_CustomSectorsQuery
>;
export type OfferingsFilterForm_CustomSectorsLazyQueryHookResult = ReturnType<
  typeof useOfferingsFilterForm_CustomSectorsLazyQuery
>;
export type OfferingsFilterForm_CustomSectorsQueryResult = Apollo.QueryResult<
  OfferingsFilterForm_CustomSectorsQuery,
  OfferingsFilterForm_CustomSectorsQueryVariables
>;
