import { useMemo, useRef } from 'react';

import { Maybe } from '../../types';
import { listCompare, ListCompareOptions, ListCompareResults } from '../../util/list/compare';

type UseListCompareOptions<T> = ListCompareOptions<T> & { isEnabled?: boolean };

export const useListCompare = <T extends Record<string, unknown>>(
  a: Maybe<readonly T[]>,
  b: Maybe<readonly T[]>,
  options?: UseListCompareOptions<T>
): ListCompareResults<T> => {
  const _options = useRef<UseListCompareOptions<T>>({
    key: item => item,
    equals: (a, b) => a === b,
    isEnabled: true,
    ...options,
  });

  const result = useMemo(
    () =>
      _options.current.isEnabled
        ? listCompare({ a: a || [], b: b || [], options: _options.current })
        : { addedItems: [], deletedItems: [], mutatedItems: [] },
    [a, b, _options]
  );

  return result;
};
