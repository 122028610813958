import orderBy from 'lodash/orderBy';
import React from 'react';

import DataGrid, { PaginationQueryParams, Props as GridProps } from './DataGrid';

export const applyPagination = <ClientTRow extends unknown>(
  rows: ClientTRow[],
  page: number,
  perPage: number
) => {
  const startIndex = (page - 1) * perPage;
  return rows.slice(startIndex, startIndex + perPage);
};

export const getTotalPages = (rowCount: number, perPage: number) => {
  const pagesNeeded = rowCount / perPage;
  const pagesNeededFloored = Math.floor(pagesNeeded);
  return pagesNeededFloored + (pagesNeeded === pagesNeededFloored ? 0 : 1);
};

const defaultPagination = {
  page: 1,
  perPage: DataGrid.DEFAULT_PER_PAGE,
};

export type Props<ClientTRow> = Omit<
  GridProps<ClientTRow>,
  'pagination' | 'onPaginationChange' | 'totalPages'
> & { defaultPageSize?: number };

/**
 * DataGridClient is a wrapper around AgGrid that renders the provided data and handles
 * sorting, and pagination client side.
 */
const DataGridClient = <ClientTRow extends any>(props: Props<ClientTRow>) => {
  const { rows, extended } = props;
  const withPagination = extended && !extended.hidePagination;

  const [pagination, setPagination] = React.useState<PaginationQueryParams>(defaultPagination);
  // resets page & perPage on every change in rows - during filtering for example
  React.useEffect(() => {
    if (!withPagination) {
      return;
    }

    setPagination(pagination => ({
      ...pagination,
      ...defaultPagination,
      ...(props.defaultPageSize && { perPage: props.defaultPageSize }),
    }));
  }, [props.rows, props.defaultPageSize, withPagination]);

  const { orderDirection, orderField, page, perPage } = pagination;

  const sortedRows = orderField ? orderBy(rows, orderField, orderDirection) : rows;
  const pageRows =
    withPagination && sortedRows ? applyPagination(sortedRows, page, perPage) : sortedRows;

  const totalPages = React.useMemo(
    () => (withPagination ? getTotalPages(rows ? rows.length : 0, perPage) : undefined),
    [perPage, rows, withPagination]
  );

  return (
    <DataGrid
      {...props}
      rows={pageRows}
      pagination={pagination}
      onPaginationChange={setPagination}
      totalPages={totalPages}
    />
  );
};

export default DataGridClient;
