import React from 'react';

import Checkbox from '../../../form/checkbox/Checkbox';
import Icon from '../../../graphics/icon/Icon';
import BoxButton from '../../../layout/box/BoxButton';
import Popover from '../../../overlays/popover/Popover';
import {
  SDownloadHeader,
  SDownloadItem,
  SDownloadItemLabel,
  SDownloadMenu,
  SDownloadSubHeader,
  StyledDownloadButton,
} from './DownloadDropdown.styles';

type Props = {
  onDownload: (selectedOptions: string[]) => void;
  downloadOptions?: {
    value: string;
    label: string;
  }[];
  downloadTitle?: string;
};

const DownloadDropdown: React.FC<Props> = ({ onDownload, downloadOptions = [], downloadTitle }) => {
  const [selected, setSelected] = React.useState({});
  const [visible, setVisible] = React.useState(false);
  const onDownloadClick = () => {
    const checked = downloadOptions
      .filter(item => selected[item.value] === true)
      .map(item => item.value);
    setVisible(false);
    setSelected({});
    onDownload(checked);
  };

  // Simple case - no dropdown, just a button
  if (downloadOptions.length === 0) {
    return (
      <BoxButton aria-label="download excel" onClick={onDownloadClick}>
        <Icon name="file-excel" />
      </BoxButton>
    );
  }

  // When the user can choose options for the download, we display a dropdown with this options
  return (
    <Popover
      variant="DARK"
      placement="bottomLeft"
      visible={visible}
      onVisibleChange={visible => setVisible(visible)}
      content={
        <SDownloadMenu>
          {downloadTitle && <SDownloadHeader>{downloadTitle}</SDownloadHeader>}
          {downloadOptions.length > 0 && (
            <React.Fragment>
              <SDownloadSubHeader>Also Include:</SDownloadSubHeader>
              {downloadOptions.map(({ value, label }) => (
                <SDownloadItem key={value}>
                  <Checkbox
                    id={value}
                    value={selected[value]}
                    onChange={checked => {
                      setSelected({ ...selected, [value]: checked });
                    }}
                  />
                  <SDownloadItemLabel htmlFor={value}>{label}</SDownloadItemLabel>
                </SDownloadItem>
              ))}
            </React.Fragment>
          )}
          <StyledDownloadButton variant="high-contrast" onClick={onDownloadClick}>
            Download
          </StyledDownloadButton>
        </SDownloadMenu>
      }
      trigger="click"
    >
      <BoxButton aria-label="download excel">
        <Icon name="file-excel" />
      </BoxButton>
    </Popover>
  );
};

export default DownloadDropdown;
