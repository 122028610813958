import { ISODate } from '../../../../../types/common';
import { TimingStates } from '../../model/timings.model';

export type Timing = {
  date: ISODate;
  status: TimingStates;
};

export const highlightStep = (timingStep: TimingStates) =>
  [
    TimingStates.WITHDRAWN_DATE,
    TimingStates.TERMINATION_DATE,
    TimingStates.POSTPONED_DATE,
  ].includes(timingStep);
