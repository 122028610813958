import { datalabApi } from '@cmg/api';
import { checkPermissions, getUserPermissions, permissionsByEntity } from '@cmg/auth';
import { duckPartFactory, Option } from '@cmg/common';
import { AnyAction, combineReducers } from 'redux';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import dlApi from '../../api/datalab-api';
import * as datalabGatewayApi from '../../api/dlgw/datalabGatewayApi';
import {
  createActionCreator,
  createActionType,
  createApiActionCreators,
  createReducer,
  REQUEST,
  SUCCESS,
} from '../../common/redux/reduxHelpers';
import {
  getDatalabUserPreferences,
  setDatalabUserPreferences,
} from '../../config/datalabUserPreferences';
import { UUID } from '../../types/common';
import { CalendarCategory } from '../../types/domain/calendar/constants';
import { precedentTransactionRequest } from '../shared/ducks';
import { type Calendar_OfferingFieldsFragment } from './graphql';
import { FilterValues, getAllOfferingOptions } from './model/calendar-filters';

export type FilterOptions = {
  offeringType: Option[];
};

/**
 * ACTION TYPES
 */
const SET_FOLLOW_OFFERING = 'calendar-graphql/SET_FOLLOW_OFFERING';
const FETCH_DATALAB_OPTIONS = 'calendar-graphql/FETCH_DATALAB_OPTIONS';
const PRECEDENT_REPORTS_FROM_CALENDAR = 'calendar-graphql/PRECEDENT_REPORTS_FROM_CALENDAR';
const FILTER_CHANGE = 'calendar-graphql/FILTER_CHANGE';
const UPDATE_FILTER = 'calendar-graphql/UPDATE_FILTER';
const UPDATE_OPTIONS = 'calendar-graphql/UPDATE_OPTIONS';
const INIT_FILTER = 'calendar-graphql/INIT_FILTER';

/**
 * ACTIONS
 */
export const fetchDatalabOptionsActions = createApiActionCreators(FETCH_DATALAB_OPTIONS);

export const precedentReportsFromCalendarAction = createActionCreator<{
  offering: datalabApi.CalendarOffering | Calendar_OfferingFieldsFragment;
  useCustomSectors: boolean;
}>(PRECEDENT_REPORTS_FROM_CALENDAR);
export const filterChange = createActionCreator<FilterValues>(FILTER_CHANGE);
export const filterUpdate = createActionCreator<FilterValues>(UPDATE_FILTER);
export const optionsUpdate = createActionCreator<FilterOptions>(UPDATE_OPTIONS);
export const initFilter = createActionCreator<never>(INIT_FILTER);

type SetFollowOfferingParams = {
  offeringId: UUID;
  isFollowing: boolean;
  category: CalendarCategory;
};

export const {
  actionCreators: {
    request: setFollowOfferingRequest,
    success: setFollowOfferingSucceeded,
    failure: setFollowOfferingFailed,
  },
  initialState: setFollowOfferingInitialState,
  reducer: setFollowOfferingReducer,
  actionTypes: { REQUEST: setFollowOfferingRequestType, SUCCESS: setFollowingOfferingSuccessType },
} = duckPartFactory.makeAPIDuckParts<SetFollowOfferingParams, SetFollowOfferingParams>({
  prefix: SET_FOLLOW_OFFERING,
});

export type SetFollowOfferingAction = ReturnType<typeof setFollowOfferingRequest>;
export type SetFollowOfferingSuccessAction = ReturnType<typeof setFollowOfferingSucceeded>;

/**
 * REDUCERS
 */
type CalendarReducer<T> = (state: T | undefined, action: AnyAction) => T;

export type State = {
  advisoryOptions: any[];
  fundOptions: any[];
  filterValues: FilterValues;
  filterOptions: FilterOptions;
};

export const initialState: State = {
  advisoryOptions: [],
  fundOptions: [],
  filterValues: {
    offeringType: [],
    sector: [],
    customSectorId: [],
    sizeInDollars: {},
    marketCap: {},
    leftLeadFirmId: [],
    managerFirmId: [],
    useCustomSectors: false,
    countries: [],
  },
  filterOptions: {
    offeringType: [],
  },
};

const filterValuesReducer: CalendarReducer<FilterValues> = createReducer(
  initialState.filterValues,
  {
    [UPDATE_FILTER]: (state: FilterValues, values: Partial<FilterValues>) => ({
      ...state,
      ...values,
    }),
  }
);

const filterOptionsReducer: CalendarReducer<FilterOptions> = createReducer(
  initialState.filterOptions,
  {
    [UPDATE_OPTIONS]: (state: FilterOptions, values: Partial<FilterOptions>) => ({
      ...state,
      ...values,
    }),
  }
);

const advisoryOptionsReducer: CalendarReducer<any[]> = createReducer(initialState.advisoryOptions, {
  [FETCH_DATALAB_OPTIONS]: {
    [SUCCESS]: (state, payload) => payload.advisoryOptions,
  },
});

const fundOptionsReducer: CalendarReducer<any[]> = createReducer(initialState.fundOptions, {
  [FETCH_DATALAB_OPTIONS]: {
    [SUCCESS]: (state, payload) => payload.fundOptions,
  },
});

const reducer: CalendarReducer<State> = combineReducers({
  advisoryOptions: advisoryOptionsReducer,
  fundOptions: fundOptionsReducer,
  filterValues: filterValuesReducer,
  filterOptions: filterOptionsReducer,
});

export default reducer;

/**
 * SELECTORS
 */
const selectState: (state) => State = state => state.calendarGraphql;
export const selectAdvisoryOptions = state => selectState(state).advisoryOptions;
export const selectFundOptions = state => selectState(state).fundOptions;
export const selectFilterOptions = state => selectState(state).filterOptions;
export const selectFilterValues = state => selectState(state).filterValues;

/**
 * SAGAS
 */
export function* setFollowOfferingSaga({ payload }: SetFollowOfferingAction) {
  const { offeringId, isFollowing } = payload;

  const resp: datalabGatewayApi.SetFollowOfferingResponse = yield call(
    datalabGatewayApi.setFollowOffering,
    { offeringId },
    { isFollowing }
  );

  if (resp.ok) {
    yield put(setFollowOfferingSucceeded(payload));
  } else {
    yield put(setFollowOfferingFailed(resp.data.error));
  }
}

export function* fetchDatalabOptionsSaga() {
  const canFetchFunds = checkPermissions(getUserPermissions(), [permissionsByEntity.FundIoi.READ]);

  const [advisory, fund] = yield [
    call(dlApi.fetchAdvisoryOptions),
    canFetchFunds ? call(dlApi.fetchFundOptions) : { ok: true, data: [] },
  ];

  if (advisory.ok && fund.ok) {
    const result = {
      advisoryOptions: advisory.data,
      fundOptions: fund.data,
    };
    yield put(fetchDatalabOptionsActions.success(result));
  }
}

export function* precedentReportsFromCalendarSaga({
  payload,
}: {
  payload: { offering: datalabApi.CalendarOffering; useCustomSectors: boolean };
}) {
  const { offering, useCustomSectors } = payload;
  const state = yield select();

  const marketCapMin = offering.marketCap
    ? Math.round((offering.marketCap / 1000000) * 0.5)
    : undefined;
  const marketCapMax = offering.marketCap
    ? Math.round((offering.marketCap / 1000000) * 1.5)
    : undefined;
  const sectors = offering.sectorCode ? [offering.sectorCode] : [];
  const customSectors = offering.customSectorId ? [offering.customSectorId] : [];

  const options = {
    offeringType: offering.type,
    marketCapMin,
    marketCapMax,
    sectors,
    customSectors,
    useCustomSectors,
    advisoryOptions: selectAdvisoryOptions(state),
    fundOptions: selectFundOptions(state),
  };

  yield put(precedentTransactionRequest(options));
}

export function* initFilterSaga() {
  const state = yield select();

  const filterOptions = selectFilterOptions(state);
  yield put(
    optionsUpdate({
      ...filterOptions,
      offeringType: getAllOfferingOptions(),
    })
  );

  const savedFilters = getDatalabUserPreferences<FilterValues>('calendarFiltersGraphql');

  if (savedFilters) {
    yield put(filterUpdate(savedFilters));
    return;
  } else {
    const filterValues = selectFilterValues(state);
    yield put(
      filterUpdate({
        ...filterValues,
        offeringType: getAllOfferingOptions().map(({ value }) => value),
      })
    );
  }
}

export function* updateFilterSaga(filterAction: ReturnType<typeof filterChange>) {
  if (!filterAction.payload) {
    return;
  }

  yield put(filterUpdate(filterAction.payload));

  setDatalabUserPreferences('calendarFiltersGraphql', filterAction.payload);
}

export function* calendarSaga() {
  yield takeEvery<SetFollowOfferingAction>(setFollowOfferingRequestType, setFollowOfferingSaga);
  // @ts-ignore
  yield takeEvery(createActionType(FETCH_DATALAB_OPTIONS, REQUEST), fetchDatalabOptionsSaga);
  yield takeEvery(
    // @ts-ignore
    createActionType(PRECEDENT_REPORTS_FROM_CALENDAR),
    precedentReportsFromCalendarSaga
  );
  yield takeEvery<ReturnType<typeof filterChange>>(FILTER_CHANGE, updateFilterSaga);
  yield takeLatest(INIT_FILTER, initFilterSaga);
}
