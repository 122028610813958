import { createTestId } from '../../../../common/selectors';

const create = (name: string) => createTestId(`xc-roadshows-configuration-management-team-${name}`);

export const roadshowDealTeamsConfigScreen = create('screen');
export const roadshowDealTeamsConfigAddTeamButton = create('add-team-button');
export const roadshowDealTeamsConfigAddTeamMemberButton = create('add-team-member-button');
export const roadshowDealTeamsConfigDeleteTeamButton = create('delete-team-button');
export const roadshowDealTeamsConfigEditTeamButton = create('edit-team-button');
export const roadshowDealTeamsConfigDealTeamsList = create('deal-teams-list');
export const roadshowDealTeamsComingSoonBanner = create('coming-soon-banner');
export const roadshowDealTeamsConfigCreateDealTeamButton = create('create-deal-team-button');
