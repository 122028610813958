import MenuIcon from '@mui/icons-material/Menu';
import React from 'react';

import type { AppBarMenuProps } from '../shared/AppBarMenu';
import { AppBarMenu } from '../shared/AppBarMenu';

export type AppBarNavigationMenuProps = Pick<AppBarMenuProps, 'renderItems' | 'label'>;

export const AppBarNavigationMenu: React.VFC<AppBarNavigationMenuProps> = ({
  ...restMenuProps
}) => {
  return <AppBarMenu id="application-main-menu" icon={<MenuIcon />} {...restMenuProps} />;
};
