import Icon from '@mui/material/Icon';
import { styled } from '@mui/material/styles';
import type { VFC } from 'react';
import React from 'react';

const StyledAppBarLogo = styled(Icon, {
  name: 'AppBarLogo',
  slot: 'Root',
  overridesResolver: (_props, styles) => [styles.root],
})({});

const StyledAppBarLogoImg = styled('img', {
  name: 'AppBarLogo',
  slot: 'Img',
  overridesResolver: (_props, styles) => [styles.img],
})({});

export type AppBarLogoProps = Readonly<{
  /**
   * Image to be shown inside the logo component
   */
  readonly imgSrc: string;
  /**
   * Alternate text describing the image
   */
  readonly imgAlt: string;
}>;

export const AppBarLogo: VFC<AppBarLogoProps> = ({ imgSrc, imgAlt }) => {
  return (
    <StyledAppBarLogo>
      <StyledAppBarLogoImg src={imgSrc} alt={imgAlt} />
    </StyledAppBarLogo>
  );
};
