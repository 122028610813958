import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { Box } from '@cmg/common';
import { isValid } from 'date-fns';
import React from 'react';

import { getFeatureToggles } from '../../../../config/appSettings';
import { OfferingType } from '../../../../graphql/__generated__/index';
import { isAtmOn, isInternationalOfferingsOn } from '../../../datalab/model/utils';
import { CompanyProfile_Offering_FieldsFragment } from '../../graphql/__generated__/CompanyProfile';
import AtmOfferingsTable from './table-model/AtmOfferingsTable';
import OfferingsTable from './table-model/OfferingsTable';
import { TableColumnProps } from './table-model/types';

export type Props = {
  offerings: readonly CompanyProfile_Offering_FieldsFragment[];
  hasSplits?: boolean | null;
};

const CompanyOfferingsSummary: React.FC<Props> = ({ offerings, hasSplits }) => {
  const hasIoiPermission = useCheckPermissions([permissionsByEntity.Ioi.READ]);
  const isAtmEnabled = isAtmOn();
  const isInternationalEnabled = isInternationalOfferingsOn();
  const canReadAtmSelldown = useCheckPermissions([permissionsByEntity.ATMSelldown.READ]);
  const { isAtmSelldownOn } = getFeatureToggles();

  const sortedOfferings = React.useMemo(() => {
    // slice used since query results are read only, use toSorted in the future (node v20)
    return (offerings ?? []).slice().sort((offeringA, offeringB) => {
      const pricingDateA = offeringA.statusRelevantDate && new Date(offeringA.statusRelevantDate);
      const pricingDateB = offeringB.statusRelevantDate && new Date(offeringB.statusRelevantDate);
      const filingDateA = offeringA.publicFilingDate && new Date(offeringA.publicFilingDate);
      const filingDateB = offeringB.publicFilingDate && new Date(offeringB.publicFilingDate);
      const dateA = pricingDateA && isValid(pricingDateA) ? pricingDateA : filingDateA;
      const dateB = pricingDateB && isValid(pricingDateB) ? pricingDateB : filingDateB;
      if (dateA && dateB && isValid(dateA) && isValid(dateB)) {
        return dateB.getTime() - dateA.getTime();
      }
      return -1;
    });
  }, [offerings]);

  const permissions: TableColumnProps = {
    hasIoiPermission,
    hasSplits,
    showInternational: isInternationalEnabled,
    canReadAtmSelldown,
    isAtmSelldownOn,
  };

  return (
    <Box className="widget-summary">
      <React.Fragment>
        <Box.Header title="Summary" />
        <Box.Content>
          <OfferingsTable
            offerings={sortedOfferings.filter(offering => offering.type !== OfferingType.Atm)}
            permissions={permissions}
          />
        </Box.Content>
      </React.Fragment>
      {isAtmEnabled && (
        <React.Fragment>
          <Box.Header title="Atm Summary" />
          <Box.Content>
            <AtmOfferingsTable
              offerings={sortedOfferings.filter(offering => offering.type === OfferingType.Atm)}
              permissions={permissions}
            />
          </Box.Content>
        </React.Fragment>
      )}
    </Box>
  );
};

export default CompanyOfferingsSummary;
