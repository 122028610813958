import {
  Button,
  Highlights,
  HighlightsChipItem,
  HighlightsTextItem,
  IconButton,
  Link,
  Stack,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from '@cmg/design-system';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import React from 'react';

import routeFactory from '../../../../../common/util/routeFactory';
import { OfferingType } from '../../../../../graphql/__generated__/index';
import { isInternationalOfferingsOn } from '../../../../datalab/model/utils';
import { getCurrencyFormatInBillions } from '../../../../shared/model/utils';
import { OfferingProfile_ListQuery } from '../../graphql/__generated__/OfferingProfile';
import { SectorHeader } from './components/SectorHeader';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  isFollowing?: boolean;
  onToggleFollowOffering?: () => void;
};
const typographyStyle: SxProps<Theme> = { color: 'text.secondary' };

const OfferingProfileSubHeader: React.FC<Props> = ({
  offeringProfile,
  isFollowing,
  onToggleFollowOffering,
}) => {
  const { pricingCurrency, attributes, issuer, symbol, type, typeDisplayName, status } =
    offeringProfile.offeringById ?? {};
  const offeringSize = React.useMemo(() => {
    const showInternational = isInternationalOfferingsOn();
    const { sizeUsd = null } = attributes ?? {};
    return getCurrencyFormatInBillions({
      value: sizeUsd,
      pricingCurrencyCode: pricingCurrency as string,
      showInternational,
    });
  }, [pricingCurrency, attributes]);

  const title = issuer?.id ? (
    <Link variant="h2" href={routeFactory.companies.getUrlPath({ id: issuer?.id })}>
      {issuer?.name}
    </Link>
  ) : (
    issuer?.name
  );

  const actions = (
    <React.Fragment>
      {issuer?.id && (
        <Button href={routeFactory.companies.getUrlPath({ id: issuer?.id })}>
          View Company Profile
        </Button>
      )}
      <Button
        onClick={onToggleFollowOffering}
        startIcon={
          isFollowing ? (
            <PushPinIcon color="primary" fontSize="small" />
          ) : (
            <PushPinOutlinedIcon color="primary" fontSize="small" />
          )
        }
      >
        {isFollowing ? 'Unfollow' : 'Follow'}
      </Button>
    </React.Fragment>
  );

  if (type === OfferingType.Atm) {
    return (
      <Highlights
        divider={false}
        title={title}
        subtitle={issuer?.primarySymbol?.toUpperCase()}
        titleItems={
          <Stack columnGap={2} direction="row" flexWrap="wrap" alignSelf="flex-end">
            <SectorHeader {...issuer} />
            {issuer?.entityTypes && issuer?.entityTypes.length > 0 && (
              <HighlightsTextItem
                name="Entity Type"
                text={(issuer?.entityTypes ?? []).join(', ')}
              />
            )}
          </Stack>
        }
        actions={
          <React.Fragment>
            {issuer?.id && (
              <Button href={routeFactory.companies.getUrlPath({ id: issuer?.id })}>
                View Company Profile
              </Button>
            )}
          </React.Fragment>
        }
      />
    );
  }
  if (type === OfferingType.Convertible) {
    return (
      <Highlights
        title={title}
        titleItems={
          <Typography variant="h2" sx={typographyStyle} alignSelf="flex-end">
            {symbol && symbol.length > 0
              ? symbol.toUpperCase()
              : issuer?.primarySymbol?.toUpperCase()}
          </Typography>
        }
        items={
          <React.Fragment>
            {typeDisplayName && <HighlightsTextItem name="Offering Type" text={typeDisplayName} />}
            {status && <HighlightsChipItem name="Offering Status" label={status} color="primary" />}
            {issuer?.sectorDisplayName && (
              <HighlightsTextItem name="Sectors" text={issuer?.sectorDisplayName} />
            )}
            {offeringSize && <HighlightsTextItem name="Offering Size" text={offeringSize} />}
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <IconButton aria-label="Follow" onClick={onToggleFollowOffering}>
              <Tooltip title={isFollowing ? 'Unfollow' : 'Follow'}>
                {isFollowing ? (
                  <PushPinIcon color="primary" fontSize="small" />
                ) : (
                  <PushPinOutlinedIcon color="primary" fontSize="small" />
                )}
              </Tooltip>
            </IconButton>
          </React.Fragment>
        }
      />
    );
  }
  return (
    <Highlights
      divider={false}
      title={title}
      subtitle={issuer?.primarySymbol?.toUpperCase()}
      titleItems={
        <Stack columnGap={2} direction="row" flexWrap="wrap" alignSelf="flex-end">
          {issuer?.sectorDisplayName && (
            <HighlightsTextItem
              name="Sector/Sub Sector"
              text={`${issuer?.sectorDisplayName}${
                issuer?.subSectorDisplayName ? `/${issuer?.subSectorDisplayName}` : ''
              }`}
            />
          )}
          {issuer?.entityTypes && issuer?.entityTypes.length > 0 && (
            <HighlightsTextItem name="Entity Type" text={issuer?.entityTypes.join(', ')} />
          )}
        </Stack>
      }
      actions={actions}
    />
  );
};

export default OfferingProfileSubHeader;
