import { Popover } from '@cmg/common';
import React from 'react';

import ValuesGrid from '../values-grid/ValuesGrid';

export type Props = {
  value: React.ReactNode;
  valueTooltip: React.ReactNode;
  label: string;
  labelTooltip: string;
};

const ValueWithTooltip: React.FC<Props> = ({ value, valueTooltip, label, labelTooltip }) => (
  <Popover
    trigger="hover"
    variant="TOOLTIP"
    content={
      <span>
        {labelTooltip}: {valueTooltip}
      </span>
    }
    placement="top"
  >
    <div>
      <ValuesGrid.Value>{value}</ValuesGrid.Value>
      <ValuesGrid.Label>{label}</ValuesGrid.Label>
    </div>
  </Popover>
);

export default ValueWithTooltip;
