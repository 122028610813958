export type ListCompareOptions<T> = {
  /** A key selector used to identify unique value instances to detect added/removed. */
  key: KeySelectorFn<T>;
  /** An equality predicate used to detect mutations. */
  equals: EqualityPredicateFn<T>;
};

export type ListCompareProps<T> = {
  a: readonly T[];
  b: readonly T[];
  options: ListCompareOptions<T>;
};

export type ListCompareResults<T> = {
  addedItems: T[];
  deletedItems: T[];
  mutatedItems: [T, T][];
};

export type KeySelectorFn<T> = (item: T) => any;
export type EqualityPredicateFn<T> = (a: T, b: T) => boolean;

export const listCompare = <T>({
  a,
  b,
  options: { key, equals },
}: ListCompareProps<T>): ListCompareResults<T> => {
  const aMap = a.reduce<Map<any, T>>((map, item) => map.set(key(item), item), new Map<any, T>());
  const bMap = b.reduce<Map<any, T>>((map, item) => map.set(key(item), item), new Map<any, T>());

  const aKeySet = new Set(aMap.keys());
  const bKeySet = new Set(bMap.keys());

  let addedItems: T[] = [];
  let deletedItems: T[] = [];
  let mutatedItems: [T, T][] = [];

  aMap.forEach((aValue, aKey) => {
    if (bKeySet.has(aKey)) {
      // This entry has a key in common with both a and b
      const bValue = bMap.get(aKey)!;
      if (!equals(aValue, bValue)) {
        mutatedItems.push([aValue, bValue]);
      }
    } else {
      deletedItems.push(aValue);
    }
  });

  bMap.forEach((bValue, bKey) => {
    if (!aKeySet.has(bKey)) {
      addedItems.push(bValue);
    }
  });

  return { addedItems, deletedItems, mutatedItems };
};
