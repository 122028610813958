import { numericUtil } from '@cmg/common';
import isNil from 'lodash/isNil';
import React from 'react';

import DashboardValueBase, { Size } from '../dashboard-value-base/DashboardValueBase';

export type Props = {
  label?: string;
  value?: number | null;
  size?: Size;
  performance?: number | null;
};

/**
 * CurrencyValue displays a neutral formatted currency value
 * with optional label, size and performance
 */
const CurrencyValue: React.FC<Props> = ({ label, value, size, performance }) => {
  const formattedValue = isNil(value) ? '-' : numericUtil.formatCurrencyInBillions(value);

  return (
    <DashboardValueBase
      renderValue={() => formattedValue}
      label={label}
      size={size}
      performance={performance}
    />
  );
};

export default CurrencyValue;
