import * as accountDetailSelectors from './account-detail';
import * as rolodexSelectors from './rolodex';

export * from './global-management';
export * from './identity-management';
export * from './layout';
export * from './login';
export * from './logout';
export * from './offplatform-data-admin';

export const rolodex = rolodexSelectors;
export const accountDetail = accountDetailSelectors;
