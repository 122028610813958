import range from 'lodash/range';
import React from 'react';

import Icon from '../../graphics/icon/Icon';
import PaginationButton from './PaginationButton';

const PADDING_PAGE = 1; // lodash.range does not include the end number so we add 1
const LAST_PAGE_RANGE = 2;
const FIRST_PAGES_START = 1;
const FIRST_PAGES_END = 1;
const ELLIPSIS_PAGE_SIZE = 1;
const SIBLING_PAGES_RANGE = 1;
const BATCH_PAGES = 5;
const MIN_PAGES_TO_ACTIVATE_BATCH = 20;

export type Props = {
  activePage?: number; // One-based indexing (that's how our APIs operate)
  totalPages?: number;
  onChangePage: (page: number) => void;
};

export const isActiveLastPageRange = (
  activePage: number,
  totalPages: number,
  lastPageRange: number
): boolean => activePage >= totalPages - lastPageRange;

export const getLastPageStart = (
  activePage: number,
  totalPages: number,
  lastPageRange: number
): number =>
  isActiveLastPageRange(activePage, totalPages, lastPageRange)
    ? totalPages - lastPageRange
    : totalPages;

export const getMainPageStart = (activePage: number, lastPageStart: number): number =>
  Math.min(
    Math.max(activePage - SIBLING_PAGES_RANGE, FIRST_PAGES_END + ELLIPSIS_PAGE_SIZE + 1),
    lastPageStart - ELLIPSIS_PAGE_SIZE - 2 * SIBLING_PAGES_RANGE
  );

const Pagination: React.FC<Props> = ({ activePage, totalPages, onChangePage }) => {
  if (activePage === undefined || totalPages === undefined) {
    return null;
  }

  const createPage = (page: number, isEllipsis?: boolean): React.ReactNode => {
    if (isEllipsis) {
      return (
        <PaginationButton key={`${page}-ellipsis`} disabled>
          ...
        </PaginationButton>
      );
    }

    return (
      <PaginationButton key={page} onClick={() => onChangePage(page)} active={page === activePage}>
        {page}
      </PaginationButton>
    );
  };

  const renderPages = (): React.ReactNode[] => {
    // display all pages when there are <= 7
    if (totalPages <= 7) {
      return range(1, totalPages + PADDING_PAGE).map(page => createPage(page));
    }
    const lastPageStart: number = getLastPageStart(activePage, totalPages, LAST_PAGE_RANGE);
    const lastPageEnd: number = totalPages;

    // generate first and last pages
    const firstPages = range(FIRST_PAGES_START, FIRST_PAGES_END + PADDING_PAGE).map(page =>
      createPage(page)
    );
    const lastPages = range(lastPageStart, lastPageEnd + PADDING_PAGE).map(page =>
      createPage(page)
    );

    // generate main pages middle range
    const mainPagesStart = getMainPageStart(activePage, lastPageStart);
    const mainPagesEnd = mainPagesStart + 2;
    const mainPages = range(mainPagesStart, mainPagesEnd + PADDING_PAGE).map(page =>
      createPage(page)
    );

    // Calculate and add ellipsis before group of main pages
    const firstEllipsisPageNumber: number = mainPagesStart - 1;
    const showFirstEllipsis: boolean = firstEllipsisPageNumber !== FIRST_PAGES_END + 1;

    const firstEllipsis = createPage(firstEllipsisPageNumber, showFirstEllipsis);

    // Calculate and add ellipsis after group of main pages
    const secondEllipsisPageNumber: number = mainPagesEnd + 1;
    const showSecondEllipsis: boolean = secondEllipsisPageNumber !== lastPageStart;
    const secondEllipsis = showSecondEllipsis
      ? createPage(secondEllipsisPageNumber, showSecondEllipsis)
      : null;

    // return all groups
    return [...firstPages, firstEllipsis, ...mainPages, secondEllipsis, ...lastPages];
  };

  const hasPrevious = activePage > 1;
  const hasNext = activePage < totalPages;
  const previousPage = activePage - 1;
  const nextPage = activePage + 1;
  const showBatchButton = totalPages >= MIN_PAGES_TO_ACTIVATE_BATCH;
  const nextBatch = activePage + BATCH_PAGES;
  const prevBatch = activePage - BATCH_PAGES;
  const hasNextBatch = nextBatch <= totalPages;
  const hasPrevBatch = prevBatch > 0;

  return (
    <div>
      {showBatchButton && (
        <PaginationButton onClick={() => onChangePage(prevBatch)} disabled={!hasPrevBatch}>
          <Icon name="chevron-double-left" variant="regular" />
        </PaginationButton>
      )}
      <PaginationButton onClick={() => onChangePage(previousPage)} disabled={!hasPrevious}>
        <Icon name="chevron-left" variant="regular" />
      </PaginationButton>
      {renderPages()}
      <PaginationButton onClick={() => onChangePage(nextPage)} disabled={!hasNext}>
        <Icon name="chevron-right" variant="regular" />
      </PaginationButton>
      {showBatchButton && (
        <PaginationButton onClick={() => onChangePage(nextBatch)} disabled={!hasNextBatch}>
          <Icon name="chevron-double-right" variant="regular" />
        </PaginationButton>
      )}
    </div>
  );
};

export default Pagination;
