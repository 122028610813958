import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

export const StyledNavLink = styled(NavLink)<{
  disabled?: boolean;
}>`
  display: inline-block;
  padding: 0 5px 5px;
  font-size: ${({ theme }) => theme.text.size.medium};
  color: ${({ theme }) => theme.text.color.light};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  text-transform: uppercase;

  &.activeLink {
    font-weight: ${({ theme }) => theme.text.weight.medium};
    border-bottom: ${({ theme, disabled }) =>
      `${theme.border.width.medium} solid ${
        !!disabled ? theme.text.color.disabled : theme.text.color.link
      }`};
    color: ${({ theme, disabled }) =>
      !!disabled ? theme.text.color.disabled : theme.text.color.link};
  }

  &:hover {
    color: ${({ theme, disabled }) =>
      !!disabled ? theme.text.color.disabled : theme.text.color.link};
    text-decoration: none;
  }
`;
