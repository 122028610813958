import { createPopoverTestId, createTestId } from '../common/selectors';

export const navbarSelector = createTestId('nav-bar');
export const navBarUserMenuPopoverSelector = createTestId('nav-bar-popover-button');
export const navbarAccountsSelector = createTestId('nav-bar-accounts');
export const navbarAccountSettingsSelector = createTestId('nav-bar-account-settings');
export const navbarProfileSelector = createTestId('nav-bar-profile');
export const navbarTraitsSelector = createTestId('nav-bar-traits');
export const navbarRolodexSelector = createTestId('nav-bar-rolodex');
export const navbarOffPlatformDataAdmin = createTestId('nav-bar-off-platform-data-admin');
export const mobileNavbarSelector = createTestId('mobile-nav-bar');
export const mobileNavBarCollapseMenuSelector = createTestId('mobile-nav-bar-collapse-menu');
export const logoutButtonSelector = createPopoverTestId('nav-bar-logout');
