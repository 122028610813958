import { createTestId } from '../../common/selectors';

export * from './set-api-key';

/** General static-content */
export const gettingStartedContainerSelector = createTestId('getting-started-container');
export const authenticationContainerSelector = createTestId('authentication-container');
export const paginationContainerSelector = createTestId('pagination-container');
export const dateFormatsContainerSelector = createTestId('date-formats-container');

/** Dlgw guides static-content */
export const customFiltersContainerSelector = createTestId('custom-filters-container');

export const dlgwSpecContainerSelector = createTestId('dlgw-spec-container');
export const dlgwSpecSetApiKeyButton = createTestId('dlgw-spec-set-api-key-button');
