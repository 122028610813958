import { styled } from '@mui/material/styles';
import type { FC } from 'react';
import React from 'react';

import type { TableRowProps } from './TableRow';
import { TableRow } from './TableRow';

const StyledTableRow = styled(TableRow, {
  name: 'TableGroupHeader',
  slot: 'Root',
  overridesResolver: (_props, styles) => [styles.root],
})({});

export const TableGroupHeader: FC<TableRowProps> = ({ children, ...props }) => {
  return <StyledTableRow {...props}>{children}</StyledTableRow>;
};
