import { Icon, Popover } from '@cmg/common';
import { companyScreenSubHeaderTestId } from '@cmg/e2e-selectors';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Pill } from '../../../../obsolete/components/ui';
import { CompanyLegacy } from '../../../../types/domain/company/company';
import ScreenSubHeader from '../../../shared/layout/ScreenSubHeader';
import { getCurrencyFormatInBillions } from '../../../shared/model/utils';
import SectorProvider from '../../../shared/sectors/SectorProvider';
import { updateSectorCompanyActionCreators } from '../../ducks';
import { InfoIconWrapper, PopoverContent } from './CompanySubHeader.styles';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updateSectorCompany: updateSectorCompanyActionCreators.request,
    },
    dispatch
  ),
});

type OwnProps = {
  company: CompanyLegacy;
  showInternational: boolean;
  pricingCurrencyCode: string;
  showAtm: boolean;
};
export type Props = OwnProps & ReturnType<typeof mapDispatchToProps>;

export const CompanySubHeader: React.FC<Props> = ({
  company,
  showInternational,
  pricingCurrencyCode,
  actions,
  showAtm,
}) => {
  const handleSubmit = (sectorId: string) => {
    actions.updateSectorCompany({ companyId: company.id, sectorId });
  };

  return (
    <SectorProvider onSubmit={handleSubmit}>
      {({ SectorToggle, SectorPicker }) => (
        <ScreenSubHeader
          title={
            <div data-test-id={companyScreenSubHeaderTestId.testId}>
              {company.issuer} <span className="ticker">({company.companyProfile.ticker})</span>
              {company.delisted && (
                <Pill type="danger" uppercase>
                  Delisted
                </Pill>
              )}
            </div>
          }
          bottomData={[
            {
              label: <SectorToggle />,
              value: (
                <SectorPicker
                  sector={company.companyProfile.sector}
                  customSector={company.customSector?.sectorName}
                />
              ),
            },
            {
              label: 'Sub Sector',
              value: (
                <SectorPicker
                  sector={company.companyProfile.subSector}
                  customSector={company.customSubsector?.sectorName}
                />
              ),
            },
          ]}
          rightData={[
            {
              label: 'Offerings',
              value: company.offeringsCount,
            },
            {
              label: (
                <React.Fragment>
                  Capital Raised
                  {showAtm && (
                    <Popover
                      variant="DARK"
                      placement="topLeft"
                      hideArrow
                      content={<PopoverContent>Excludes ATM Offerings</PopoverContent>}
                    >
                      <InfoIconWrapper>
                        <Icon name="info-circle" size="lg" className="icon" />
                      </InfoIconWrapper>
                    </Popover>
                  )}
                </React.Fragment>
              ),
              value: getCurrencyFormatInBillions({
                value:
                  showInternational && company.capitalRaisedLocalCurr !== null
                    ? company.capitalRaisedLocalCurr
                    : company.capitalRaised,
                pricingCurrencyCode,
                showInternational,
              }),
            },
          ]}
        />
      )}
    </SectorProvider>
  );
};

export default connect(undefined, mapDispatchToProps)(CompanySubHeader);
