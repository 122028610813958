// Default retry interval strategy
export const defaultIntervalStrategy: CreateReconnectStategyParams = {
  maxBase: 60 * 15 * 1000, // 15 minutes
  decayRate: 0.003, // High decay
  steps: 10,
};

type CreateReconnectStategyParams = {
  /**
   * Number of steps (array length) where each position is the number of the next retry interval
   */
  steps: number;
  /**
   * Max invertal value
   */
  maxBase: number;
  /**
   * Rate parameter of the exponential distribution, meaning how quick is decay of the exponential funtion.
   * When rate is 1 there is no decay, values close to 1 indicate a slow decay, values close to 0 indicate a quick decay. By default the rate is 0.001
   * to increase the decay and drive number values up.
   */
  decayRate?: number;
};
/**
 *
 * @param param {createReconnectStategyParams}
 * @returns
 */
export const createReconnectStategy = ({
  steps,
  maxBase = defaultIntervalStrategy.maxBase,
  decayRate = defaultIntervalStrategy.decayRate,
}: CreateReconnectStategyParams): number[] => {
  return Array(steps)
    .fill('')
    .map(() => {
      const expRandom = randomExponential(decayRate);
      const expRandomMilis = expRandom * 1000;

      return expRandomMilis < maxBase ? expRandomMilis : maxBase - Math.random() * 1000;
    })
    .sort((a, b) => a - b);
};

/**
 * randomExponential - generate exponential random
 * @param decayRate Rate parameter of the exponential distribution, meaning how quick is decay of the exponential funtion.
 * When rate is 1 there is no decay, values close to 1 indicate a slow decay, values close to 0 indicate a quick decay. By default the rate is 0.001
 * to increase the decay and drive number values up.
 * @returns exponential random
 */
export const randomExponential = (decayRate: number = 1) => {
  return -Math.log(1 - Math.random()) / decayRate;
};
