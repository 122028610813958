import { datalabApi } from '@cmg/api';
import { reduxUtil } from '@cmg/common';
import saveAs from 'file-saver';
import { combineReducers } from 'redux';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import api from '../../../api/datalab-api';
import { RootState } from '../../../common/redux/rootReducer';
import { cloneObjectCamelToSnake } from '../../../obsolete/utils/helpers';
import { PaginationQueryParams } from '../../../types/api/pagination';
import { CompanyResponse } from '../../../types/domain/company/company';
import { FilterValues } from './companies-grid-legacy/CompaniesFilters';

const { createReducer } = reduxUtil;

enum ActionTypes {
  FETCH_COMPANIES_REQUEST = 'settings/companies/FETCH_COMPANIES_REQUEST',
  FETCH_COMPANIES_SUCCESS = 'settings/companies/FETCH_COMPANIES_SUCCESS',
  DOWNLOAD_COMPANIES_REQUEST = 'settings/companies/DOWNLOAD_COMPANIES_REQUEST',
  UPDATE_SECTOR_COMPANY_REQUEST = 'settings/companies/UPDATE_SECTOR_COMPANY',
  UPDATE_CUSTOM_SECTORS = 'settings/companies/UPDATE_CUSTOM_SECTOR',
}

export const fetchCompanies = (payload: Partial<PaginationQueryParams> & FilterValues) => ({
  type: ActionTypes.FETCH_COMPANIES_REQUEST,
  payload,
});
export const fetchCompaniesSuccess = (payload: CompanyResponse) => ({
  type: ActionTypes.FETCH_COMPANIES_SUCCESS,
  payload,
});

export const downloadCompanies = (
  payload: {
    query?: string;
    bySectorsValues?: string;
    byCustomSectorsValues?: string;
  } & Omit<PaginationQueryParams, 'page' | 'perPage'>
) => ({
  type: ActionTypes.DOWNLOAD_COMPANIES_REQUEST,
  payload,
});

export const updateSectorCompany = (payload: {
  companyIds: string[];
  sectorId: string;
  callback?: (result: any) => void;
}) => ({
  type: ActionTypes.UPDATE_SECTOR_COMPANY_REQUEST,
  payload,
});

type UpdatedSector = {
  companyId: string;
  customSector: datalabApi.LiteCustomSectorDto;
  customSubsector?: datalabApi.LiteCustomSectorDto;
};
export const updateCustomSectors = (payload: UpdatedSector[]) => ({
  type: ActionTypes.UPDATE_CUSTOM_SECTORS,
  payload,
});

type Actions = {
  [ActionTypes.FETCH_COMPANIES_REQUEST]: ReturnType<typeof fetchCompanies>;
  [ActionTypes.FETCH_COMPANIES_SUCCESS]: ReturnType<typeof fetchCompaniesSuccess>;
  [ActionTypes.DOWNLOAD_COMPANIES_REQUEST]: ReturnType<typeof downloadCompanies>;
  [ActionTypes.UPDATE_SECTOR_COMPANY_REQUEST]: ReturnType<typeof updateSectorCompany>;
  [ActionTypes.UPDATE_CUSTOM_SECTORS]: ReturnType<typeof updateCustomSectors>;
};

export type SettingsCompaniesState = {
  companies: CompanyResponse | null;
};

const initialState: SettingsCompaniesState = { companies: null };

const companiesReducer = createReducer<SettingsCompaniesState['companies'], Actions>(
  initialState.companies,
  {
    [ActionTypes.FETCH_COMPANIES_SUCCESS]: (state, { payload }) => payload,
    [ActionTypes.UPDATE_CUSTOM_SECTORS]: (state, { payload }) => {
      if (!state) {
        return state;
      }
      return {
        ...state,
        data: state.data.map(c => {
          const row = payload.find(p => p.companyId === c.id);
          return row
            ? { ...c, customSector: row.customSector, customSubsector: row.customSubsector }
            : c;
        }),
      };
    },
  }
);

export default combineReducers<SettingsCompaniesState>({
  companies: companiesReducer,
});

export const selectCompanies = (state: RootState) => state.settingsCompanies.companies;

/**
 * SAGAS
 */

function* fetchCompaniesSaga({ payload }: Actions[ActionTypes.FETCH_COMPANIES_REQUEST]) {
  const resp = yield call(api.company.fetchCompanies, cloneObjectCamelToSnake(payload));
  if (resp.ok) {
    yield put(fetchCompaniesSuccess(resp.data));
  }
}

export function* downloadCompaniesSaga({
  payload,
}: Actions[ActionTypes.DOWNLOAD_COMPANIES_REQUEST]) {
  const resp = yield call(api.company.downloadCompanies, payload);

  if (resp.ok) {
    const disposition = resp.headers['content-disposition'];
    const filename = decodeURI(disposition.match(/filename="(.*)"/)[1]);

    saveAs(resp.data, filename);
  }
}

function* updateSectorCompanySaga({
  payload: { companyIds, sectorId, callback },
}: Actions[ActionTypes.UPDATE_SECTOR_COMPANY_REQUEST]) {
  const resp = yield call(api.customSectorCompany.update, companyIds, sectorId);
  if (resp.ok) {
    callback && callback(resp.data.data);
    yield put(updateCustomSectors(resp.data.data));
  }
}

export function* settingsCompaniesSaga() {
  yield takeLatest<Actions[ActionTypes.FETCH_COMPANIES_REQUEST]>(
    ActionTypes.FETCH_COMPANIES_REQUEST,
    fetchCompaniesSaga
  );
  yield takeLatest<Actions[ActionTypes.DOWNLOAD_COMPANIES_REQUEST]>(
    ActionTypes.DOWNLOAD_COMPANIES_REQUEST,
    downloadCompaniesSaga
  );
  yield takeEvery<Actions[ActionTypes.UPDATE_SECTOR_COMPANY_REQUEST]>(
    ActionTypes.UPDATE_SECTOR_COMPANY_REQUEST,
    updateSectorCompanySaga
  );
}
