import { DocumentNode, useLazyQuery } from '@apollo/client';
import { IGetRowsParams } from 'ag-grid-community';
import { useCallback, useEffect, useRef } from 'react';

import { loggerUtil } from '../../util';
import GridDataSourceManager, {
  FilterModel,
  QueryDocumentTypeContract,
  QueryVariablesContract,
  SortModel,
} from './GridDataSourceManager';

export type UseGridDataSourceOptions<
  ColumnsKeys extends string,
  QueryVariables extends QueryVariablesContract,
  QueryName extends string
> = {
  queryName: QueryName;
  clientName?: string;
  rowsPerPage?: number;
  filtersMode?: 'uncontrolled' | 'controlled';
  sortingMode?: 'uncontrolled' | 'controlled';
  mapFiltersModelFn?: (filterModel: FilterModel<ColumnsKeys>) => QueryVariables['where'];
  mapSortingModelFn?: (sortModel: SortModel<ColumnsKeys>[]) => QueryVariables['order'];
  queryParams?: Omit<QueryVariables, 'where' | 'order' | 'take' | 'skip'>;
  queryDocument: DocumentNode;
  handleFetchError?: (error: any) => void;
  filters?: QueryVariables['where'];
  order?: QueryVariables['order'];
};

export const useGridDatasource = <
  ColumnsKeys extends string,
  QueryDocumentType extends QueryDocumentTypeContract<unknown, QueryName>,
  QueryVariables extends QueryVariablesContract,
  QueryName extends string
>(
  options: UseGridDataSourceOptions<ColumnsKeys, QueryVariables, QueryName>
) => {
  const { clientName, queryDocument, ...rest } = options;
  const [fetchQueryFn] = useLazyQuery<QueryDocumentType, QueryVariables>(queryDocument, {
    fetchPolicy: 'network-only',
    ...(clientName ? { context: { clientName } } : {}),
  });

  const managerRef = useRef<
    GridDataSourceManager<ColumnsKeys, QueryDocumentType, QueryVariables, QueryName>
  >(
    new GridDataSourceManager<ColumnsKeys, QueryDocumentType, QueryVariables, QueryName>({
      ...rest,
      fetchData: fetchQueryFn,
    })
  );

  useEffect(() => {
    const { filtersMode } = managerRef.current ?? {};

    if (!options.filters) {
      return;
    }

    if (filtersMode === 'uncontrolled') {
      loggerUtil.warning(
        'You are passing "filters" to an uncontrolled grid datasource. This will not have any effect.'
      );
      return;
    }

    managerRef.current.setFiltersQueryParams(options.filters);
  }, [options.filters]);

  useEffect(() => {
    const { sortingMode } = managerRef.current ?? {};

    if (!options.order) {
      return;
    }

    if (sortingMode === 'uncontrolled') {
      loggerUtil.warning(
        'You are passing "order" to an uncontrolled grid datasource. This will not have any effect.'
      );
      return;
    }

    managerRef.current.setSortingQueryParams(options.order);
  }, [options.order]);

  useEffect(() => {
    if (!options.queryParams) {
      return;
    }

    managerRef.current.setQueryParams(options.queryParams);
  }, [options.queryParams]);

  const getRows = useCallback((params: IGetRowsParams) => managerRef.current.getRows(params), []);
  const setGridRef = useCallback((ref: any) => managerRef.current.setGridRef(ref), []);

  return {
    getRows,
    setGridRef,
  };
};
