import {
  DLGWCornerstoneInvestment,
  DLGWCornerstoneInvestmentType,
  DLGWOffering,
  ShareholderType,
} from '@capital-markets-gateway/api-client-datalab-gateway';
import React from 'react';

import { getFormattedCurrencyInMillion } from '../../../model/utils';
import { HeaderLabel } from './CornerstoneTooltip.styles';

export const CornerstoneShareholderTypeLabels = {
  [ShareholderType.CORPORATE]: 'Corporate',
  [ShareholderType.INSIDER]: 'Insider',
  [ShareholderType.NON_INSIDER]: 'Non Insider',
  [ShareholderType.PRIVATE_EQUITY]: 'PE',
  [ShareholderType.VENTURE_CAPITAL]: 'VC',
  [ShareholderType.OTHER_INSTITUTIONAL]: 'Other Inst.',
};

export const CornerstoneInvestorTypeLabels = {
  [DLGWCornerstoneInvestmentType.CORNERSTONE_INVESTMENT_TYPE_OFFERING_PARTICIPANT]:
    'Offering Participants',
  [DLGWCornerstoneInvestmentType.CORNERSTONE_INVESTMENT_TYPE_PRIVATE_PLACEMENT]:
    'Private Placement',
};

type Currency = DLGWOffering['pricingCurrencyCode'];
export type Props = {
  cornerstoneInvestments: DLGWCornerstoneInvestment[];
  cornerstoneInvestorsCount?: number | null;
  cornerstoneOfferingAmount?: number | null;
  investmentType: DLGWCornerstoneInvestmentType;
  pricingCurrencyCode: Currency;
};

const renderIndividual = (
  investment: DLGWCornerstoneInvestment,
  idx: number,
  pricingCurrencyCode: Currency
) => {
  const investor = investment.investors?.[0];
  if (!investor) {
    return null;
  }
  return (
    <tr className="individual-investment" key={idx}>
      {investor && (
        <React.Fragment>
          <td>{investor.shareholder?.firmName}</td>
          <td>
            {investor.shareholder?.sponsorType
              ? CornerstoneShareholderTypeLabels[investor.shareholder?.sponsorType]
              : ''}
          </td>
          <td>{investor.isExistingCornerstoneInvestor ? 'No' : 'Yes'}</td>
          <td>{getFormattedCurrencyInMillion(pricingCurrencyCode, investment.individualAmount)}</td>
        </React.Fragment>
      )}
    </tr>
  );
};

const renderCombined = (
  combinedInvestments: DLGWCornerstoneInvestment,
  idx: number,
  pricingCurrencyCode: Currency
) => {
  const investors = combinedInvestments.investors ?? [];

  return (
    <React.Fragment key={idx}>
      <tr className="combined-amount-row">
        <td colSpan={3}>Combined Amount</td>
        <td>
          {getFormattedCurrencyInMillion(pricingCurrencyCode, combinedInvestments.individualAmount)}
        </td>
      </tr>
      {investors.map((investor, idx) => (
        <tr className="combined-row" key={idx}>
          <td>{investor.shareholder?.firmName}</td>
          <td>
            {investor.shareholder?.sponsorType
              ? CornerstoneShareholderTypeLabels[investor.shareholder?.sponsorType]
              : ''}
          </td>
          <td>{investor.isExistingCornerstoneInvestor ? 'No' : 'Yes'}</td>
          <td />
        </tr>
      ))}
    </React.Fragment>
  );
};

const CornerstoneTooltipTableContent: React.FC<Props> = ({
  cornerstoneInvestorsCount,
  cornerstoneOfferingAmount,
  cornerstoneInvestments,
  investmentType,
  pricingCurrencyCode,
}) => {
  const individualInvestments = cornerstoneInvestments.filter(
    c => (c.investors?.length ?? 0) === 1
  );
  const combinedInvestments = cornerstoneInvestments.filter(c => (c.investors?.length ?? 0) > 1);

  return cornerstoneInvestments.length >= 1 ? (
    <React.Fragment>
      <thead>
        <tr>
          <HeaderLabel className="cornerstone-type">{`Cornerstone\u2013${CornerstoneInvestorTypeLabels[investmentType]}`}</HeaderLabel>
        </tr>
        <tr>
          <th style={{ width: '50%' }}>Investor</th>
          <th style={{ width: '15%' }}>Type</th>
          <th style={{ width: '19%' }}>New Investor</th>
          <th style={{ width: '16%' }}>Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr className="total-row">
          <td colSpan={3}>{`Total ${cornerstoneInvestorsCount}`}</td>
          <td>{getFormattedCurrencyInMillion(pricingCurrencyCode, cornerstoneOfferingAmount)}</td>
        </tr>
        {individualInvestments.length > 0 &&
          individualInvestments.map((investment, idx) =>
            renderIndividual(investment, idx, pricingCurrencyCode)
          )}

        {combinedInvestments.length > 0 &&
          combinedInvestments.map((investment, idx) =>
            renderCombined(investment, idx, pricingCurrencyCode)
          )}
      </tbody>
    </React.Fragment>
  ) : null;
};

export default CornerstoneTooltipTableContent;
