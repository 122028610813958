import { useFormikContext } from 'formik';
import React from 'react';

import { NumericInputField } from '../../../../../form/numeric-input/NumericInput';
import Icon from '../../../../../graphics/icon/Icon';
import Popover from '../../../../../overlays/popover/Popover';
import { Values } from './NumericInputForm.model';
import {
  SDirtyIndicator,
  SPrefixWrapper,
  SRoot,
  StyledWarningIcon,
} from './NumericTextInput.styles';

type OnChangeFn = (value: number | null) => void;

type Props = {
  readonly name: string;
  /**
   * Initial allocation share quantity.
   */
  readonly value: number | null;

  readonly errorMsg?: string | null;

  /**
   * Whether or not the form field is enabled.
   */
  readonly disabled?: boolean;
  /**
   * Whether or not the input is being saved
   */
  readonly busy?: boolean;
  /**
   * Warning to be displayed as a tooltip over the warning icon
   */
  readonly warning?: string | null;
  /**
   * used to show alternative ui state
   */
  readonly editing?: boolean;
  /**
   * Debounced callback triggered when the field changes.
   */
  readonly onChange?: OnChangeFn;
};

/**
 * Ag Grid Framework Renderer for a Numeric Text Input.
 * It is intended to be used along the Numeric Form.
 *
 */
export const NumericTextInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { name, editing, disabled, busy, warning, onChange } = props;
  const { dirty, resetForm } = useFormikContext<Values>();

  React.useEffect(() => {
    resetForm();
  }, [resetForm]);

  return (
    <SRoot>
      {editing && dirty && <SDirtyIndicator />}
      <NumericInputField
        ref={ref}
        disabled={disabled}
        name={name}
        fullWidth={true}
        onChange={onChange}
        prefix={
          <React.Fragment>
            {!busy && warning && (
              <Popover content={warning} variant="TOOLTIP">
                <SPrefixWrapper>
                  <StyledWarningIcon />
                </SPrefixWrapper>
              </Popover>
            )}
            {busy && (
              <SPrefixWrapper>
                <Icon name="spinner-third" spin={true} />
              </SPrefixWrapper>
            )}
          </React.Fragment>
        }
      />
    </SRoot>
  );
});

export default NumericTextInput;
