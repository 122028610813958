import React from 'react';
import styled, { css } from 'styled-components/macro';

import BoxButton from '../../layout/box/BoxButton';

export const StyledPaginationButton = styled(BoxButton)<{ active?: boolean }>`
  ${({ active }) =>
    active &&
    css`
      &,
      &:hover {
        background-color: ${({ theme }) => theme.interactive.primary};
        color: ${({ theme }) => theme.interactive.primaryContrast};
      }
    `}
`;

const PaginationButton: React.FC<{
  disabled?: boolean;
  active?: boolean;
  onClick?: () => void;
}> = ({ children, disabled, active, onClick }) => (
  <StyledPaginationButton disabled={disabled} onClick={onClick} active={active}>
    {children}
  </StyledPaginationButton>
);

export default PaginationButton;
