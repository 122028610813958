/* eslint-disable */
// prettier-ignore
import * as Types from '../../../graphql/__generated__/index';

import { gql } from '@apollo/client';
export type Atm_Selldown_StructureFieldsFragment = {
  __typename?: 'AtmStructure';
  selldownId?: string | null;
  programRemainingInSecurities?: number | null;
  type: Types.AtmStructureType;
  typeDisplayName?: string | null;
  announcementDate?: string | null;
  asOfDate?: string | null;
  sales?: number | null;
  salesInSecurities?: number | null;
  averagePrice?: number | null;
  programSize?: number | null;
  programSizeInSecurities?: number | null;
  programRemaining?: number | null;
};

export type Atm_Attributes_FieldsFragment = {
  __typename?: 'AtmAttributes';
  pctGrossSpread?: number | null;
  lastTradeBeforeFilingSplitAdjusted?: number | null;
  latestAsOfDate?: string | null;
  latestProgramSize?: number | null;
  latestProgramSizeInSecurities?: number | null;
  announcedProgramSize?: number | null;
  announcedProgramSizeInSecurities?: number | null;
};

export type Atm_Selldown_FieldsFragment = {
  __typename?: 'AtmAttributes';
  totalSales?: number | null;
  totalSalesInSecurities?: number | null;
  totalProgramRemaining?: number | null;
  totalProgramRemainingInSecurities?: number | null;
  structure: Array<{
    __typename?: 'AtmStructure';
    selldownId?: string | null;
    programRemainingInSecurities?: number | null;
    type: Types.AtmStructureType;
    typeDisplayName?: string | null;
    announcementDate?: string | null;
    asOfDate?: string | null;
    sales?: number | null;
    salesInSecurities?: number | null;
    averagePrice?: number | null;
    programSize?: number | null;
    programSizeInSecurities?: number | null;
    programRemaining?: number | null;
  }>;
};

export type AtmOfferings_Selldown_FieldsFragment = {
  __typename?: 'AtmAttributes';
  totalSales?: number | null;
  totalSalesInSecurities?: number | null;
  totalProgramRemaining?: number | null;
  totalProgramRemainingInSecurities?: number | null;
};

export type Atm_Selldown_Term_FieldsFragment = {
  __typename?: 'Term';
  type?: Types.TermType | null;
  revisedDate?: string | null;
  atmProgramSize?: number | null;
  atmProgramSizeInSecurities?: number | null;
};

export const Atm_Attributes_FieldsFragmentDoc = gql`
  fragment Atm_Attributes_Fields on AtmAttributes {
    pctGrossSpread
    lastTradeBeforeFilingSplitAdjusted
    latestAsOfDate
    latestProgramSize
    latestProgramSizeInSecurities
    announcedProgramSize
    announcedProgramSizeInSecurities
  }
`;
export const Atm_Selldown_StructureFieldsFragmentDoc = gql`
  fragment Atm_Selldown_StructureFields on AtmStructure {
    selldownId
    programRemainingInSecurities
    type
    typeDisplayName
    announcementDate
    asOfDate
    sales
    salesInSecurities
    averagePrice
    programSize
    programSizeInSecurities
    programRemaining
  }
`;
export const AtmOfferings_Selldown_FieldsFragmentDoc = gql`
  fragment AtmOfferings_Selldown_Fields on AtmAttributes {
    totalSales
    totalSalesInSecurities
    totalProgramRemaining
    totalProgramRemainingInSecurities
  }
`;
export const Atm_Selldown_FieldsFragmentDoc = gql`
  fragment Atm_Selldown_Fields on AtmAttributes {
    structure {
      ...Atm_Selldown_StructureFields
    }
    ...AtmOfferings_Selldown_Fields
  }
  ${Atm_Selldown_StructureFieldsFragmentDoc}
  ${AtmOfferings_Selldown_FieldsFragmentDoc}
`;
export const Atm_Selldown_Term_FieldsFragmentDoc = gql`
  fragment Atm_Selldown_Term_Fields on Term {
    type
    revisedDate
    atmProgramSize
    atmProgramSizeInSecurities
  }
`;
