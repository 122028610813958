export enum OrderDirection {
  ASC = 1,
  DESC = -1,
}

export type OrderInfo<TColumn> =
  | 'unordered'
  | {
      column: TColumn;
      direction: OrderDirection;
    };

export type OrderedRow<TRow> = TRow & { originalRowIndex: number };

export type Comparator<T = any> = (direction: OrderDirection) => (A: T, B: T) => number;

export const dateComparator: Comparator<number | string | Date | undefined | null> =
  direction => (A, B) => {
    const aTime = (A instanceof Date ? A : new Date(A ?? 0)).getTime();
    const bTime = (B instanceof Date ? B : new Date(B ?? 0)).getTime();

    const aTimeForComparison = Number.isNaN(aTime) ? -1 : aTime;
    const bTimeForComparison = Number.isNaN(bTime) ? -1 : bTime;

    return (aTimeForComparison - bTimeForComparison) * direction;
  };

export const numberComparator: Comparator<number | undefined | null> = direction => (A, B) => {
  return ((A ?? Number.MIN_SAFE_INTEGER) - (B ?? Number.MIN_SAFE_INTEGER)) * direction;
};

export const stringComparator: Comparator<string | undefined | null> = direction => (A, B) => {
  return (A ?? '').localeCompare(B ?? '') * direction;
};

export const comparatorWithAccessor: <TValue, TRow>(
  comparator: Comparator<TValue>,
  accessor: (row: TRow) => TValue
) => Comparator<TRow> = (comparator, accessor) => {
  return direction => (A, B) => comparator(direction)(accessor(A), accessor(B));
};
