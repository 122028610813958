import styled from 'styled-components/macro';

export const SSmallViewPortHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
`;

export const SCollapse = styled.div<{ expanded: boolean }>`
  background: ${({ theme }) => theme.background.color.white};
  width: 100%;
  height: 100%;
  position: absolute;
  flex-direction: column;
  z-index: ${({ theme }) => theme.zIndex.responsiveDrawer};
  display: ${({ expanded }) => (expanded ? 'flex' : 'none')};
`;

export const SSmallViewPort = styled.div`
  flex: 1;
`;

export const SLargeViewPort = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
