import { Box, numericUtil, Popover, Table, timeUtil } from '@cmg/common';
import { isValid } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';

import PerformancePercents from '../../../../common/components/format/performance-percents/PerformancePercents';
import { NoDataAvailable } from '../../../../common/components/table/table';
import { CompanyOffering } from '../../../../types/domain/company/companyOffering';
import PopoverContentTable from '../../../shared/components/PopoverContentTable';
import { getCurrencyFormat, getCurrencyFormatInMillions } from '../../../shared/model/utils';
import FundAllocationsCell from './cells/FundAllocationsCell';
import OfferingTypeCell from './cells/OfferingTypeCell';
import { STableCell, STableHeader } from './CompanyOfferingsSummary.styles';

export type Props = {
  offerings: CompanyOffering[];
  hasSplits: boolean;
  showIoIInfo: boolean;
  showInternational: boolean;
  showAtm: boolean;
};
const CompanyOfferingsSummary: React.FC<Props> = ({
  offerings,
  hasSplits,
  showIoIInfo,
  showInternational,
  showAtm,
}) => {
  const sortedOfferings = React.useMemo(() => {
    return offerings.sort((offeringA, offeringB) => {
      const pricingDateA = offeringA.statusRelevantDate && new Date(offeringA.statusRelevantDate);
      const pricingDateB = offeringB.statusRelevantDate && new Date(offeringB.statusRelevantDate);
      const filingDateA = offeringA.publicFilingDate && new Date(offeringA.publicFilingDate);
      const filingDateB = offeringB.publicFilingDate && new Date(offeringB.publicFilingDate);
      const dateA = isValid(pricingDateA) ? pricingDateA : filingDateA;
      const dateB = isValid(pricingDateB) ? pricingDateB : filingDateB;
      if (dateA && dateB && isValid(dateA) && isValid(dateB)) {
        return dateB.getTime() - dateA.getTime();
      }
      return -1;
    });
  }, [offerings]);

  return (
    <Box className="widget-summary">
      <div>
        <Box.Header title="Summary" />
        <Box.Content>
          <Table striped>
            <thead>
              <tr>
                <th>Type</th>
                <STableHeader>Status</STableHeader>
                {showAtm && <STableHeader>Public Filing Date</STableHeader>}
                <STableHeader>Pricing Date</STableHeader>
                <STableHeader>Size</STableHeader>
                <STableHeader>Size (Shares)</STableHeader>
                <STableHeader>Market Cap</STableHeader>
                <STableHeader>% of Market Cap</STableHeader>
                <STableHeader>Offer Price</STableHeader>
                <STableHeader>Net Price</STableHeader>
                {hasSplits && <STableHeader>Split Adjusted Offer Price</STableHeader>}
                {showIoIInfo && <STableHeader>Allocation</STableHeader>}
                {showIoIInfo && <STableHeader>Fill Rate</STableHeader>}
                {showIoIInfo && <STableHeader>% of Deal</STableHeader>}
                <STableHeader>Discount to Last Trade</STableHeader>
                <STableHeader>Offer To Open</STableHeader>
                <STableHeader>Offer To 1 Day</STableHeader>
                <STableHeader>Offer To Current</STableHeader>
                <th>Left Lead</th>
              </tr>
            </thead>
            <tbody>
              {sortedOfferings.map(row => {
                const hasShares = row.underwriters?.some(u => u.nrSharesPct !== null);
                const { pricingCurrencyCode } = row;
                return (
                  <tr key={row.offeringId}>
                    <OfferingTypeCell offering={row} />
                    <STableCell>{row.status}</STableCell>
                    {showAtm && (
                      <STableCell>{timeUtil.formatAsDisplayDate(row.publicFilingDate)}</STableCell>
                    )}
                    <STableCell>{timeUtil.formatAsDisplayDate(row.statusRelevantDate)}</STableCell>
                    <STableCell>
                      {showInternational
                        ? getCurrencyFormatInMillions({
                            value: row.sizeLocalCurr,
                            pricingCurrencyCode,
                            showInternational,
                          })
                        : getCurrencyFormatInMillions({
                            value: row.sizeDollars,
                            pricingCurrencyCode,
                            showInternational,
                          })}
                    </STableCell>
                    <STableCell>{numericUtil.formatInteger(row.sizeShares)}</STableCell>
                    <STableCell>
                      {showInternational
                        ? getCurrencyFormatInMillions({
                            value: row.marketLocalCurr,
                            pricingCurrencyCode,
                            showInternational,
                          })
                        : getCurrencyFormatInMillions({
                            value: row.market,
                            pricingCurrencyCode,
                            showInternational,
                          })}
                    </STableCell>
                    <STableCell>{numericUtil.formatPercents(row.pctOfMarketCap)}</STableCell>
                    <STableCell>
                      {showInternational
                        ? getCurrencyFormat({
                            value: row.offerPriceLocalCurr,
                            pricingCurrencyCode,
                            showInternational,
                          })
                        : getCurrencyFormat({
                            value: row.offerPrice,
                            pricingCurrencyCode,
                            showInternational,
                          })}
                    </STableCell>
                    <STableCell>
                      {getCurrencyFormat({
                        value: row.netPrice,
                        pricingCurrencyCode,
                        showInternational,
                      })}
                    </STableCell>
                    {hasSplits && (
                      <STableCell>
                        {getCurrencyFormat({
                          value: row.splitAdjustedOfferPrice,
                          pricingCurrencyCode,
                          showInternational,
                        })}
                      </STableCell>
                    )}
                    {showIoIInfo &&
                      (row.ioiInfo ? (
                        <FundAllocationsCell ioiInfo={row.ioiInfo} />
                      ) : (
                        <STableCell></STableCell>
                      ))}
                    {showIoIInfo && (
                      <STableCell>
                        {row.ioiInfo ? numericUtil.formatPercents(row.ioiInfo.fillRate) : ''}
                      </STableCell>
                    )}
                    {showIoIInfo && (
                      <STableCell>
                        {row.ioiInfo
                          ? numericUtil.formatPercents(row.ioiInfo.allocationPercent)
                          : ''}
                      </STableCell>
                    )}
                    <STableCell>
                      <Popover
                        trigger="hover"
                        variant="TOOLTIP"
                        content={
                          showInternational
                            ? getCurrencyFormat({
                                value: row.discountToLastTradePriceLocalCurr,
                                pricingCurrencyCode,
                                showInternational,
                              })
                            : getCurrencyFormat({
                                value: row.discountToLastTradePrice,
                                pricingCurrencyCode,
                                showInternational,
                              })
                        }
                        placement="left"
                        transparentBackground
                      >
                        <span>
                          <PerformancePercents value={row.discountToLastTrade} />
                        </span>
                      </Popover>
                    </STableCell>
                    <STableCell>
                      <Popover
                        trigger="hover"
                        variant="TOOLTIP"
                        content={getCurrencyFormat({
                          value: row.offerToOpenPrice,
                          pricingCurrencyCode,
                          showInternational,
                        })}
                        placement="left"
                        transparentBackground
                      >
                        <span>
                          <PerformancePercents value={row.offerToOpen} />
                        </span>
                      </Popover>
                    </STableCell>
                    <STableCell>
                      <Popover
                        trigger="hover"
                        variant="TOOLTIP"
                        content={getCurrencyFormat({
                          value: row.offerTo1DayPrice,
                          pricingCurrencyCode,
                          showInternational,
                        })}
                        placement="left"
                        transparentBackground
                      >
                        <span>
                          <PerformancePercents value={row.offerTo1Day} />
                        </span>
                      </Popover>
                    </STableCell>
                    <STableCell>
                      <Popover
                        trigger="hover"
                        variant="TOOLTIP"
                        content={getCurrencyFormat({
                          value: row.offerToCurrentPrice,
                          pricingCurrencyCode,
                          showInternational,
                        })}
                        placement="left"
                        transparentBackground
                      >
                        <span>
                          <PerformancePercents value={row.offerToCurrent} />
                        </span>
                      </Popover>
                    </STableCell>
                    <td>
                      <Popover
                        trigger="hover"
                        variant="TOOLTIP"
                        placement="left"
                        transparentBackground
                        content={
                          <PopoverContentTable alignLastColumn="right">
                            <thead>
                              <tr>
                                <th>Underwriter</th>
                                <th>Role</th>
                                <th>{hasShares ? 'Shares' : ''}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {row.underwriters?.map((u, idx) => (
                                <tr key={idx}>
                                  <td>{u.underwriter}</td>
                                  <td>{u.role}</td>
                                  <td>
                                    {hasShares ? numericUtil.formatPercents(u.nrSharesPct, 1) : ''}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </PopoverContentTable>
                        }
                      >
                        <Link to={`/datalab/underwriter_offerings/?underwriter=${row.leftLeadId}`}>
                          {row.leftLead}
                        </Link>
                      </Popover>
                    </td>
                  </tr>
                );
              })}
              {!offerings.length && <NoDataAvailable />}
            </tbody>
          </Table>
        </Box.Content>
      </div>
    </Box>
  );
};

export default CompanyOfferingsSummary;
