import { createTestId } from '../../../common/selectors';

const create = (name: string) => createTestId(`xc-offering-setup-terms-${name}`);

// Base Offering fields
export const offeringSetupTermsFilingType = create('filing-type');
export const offeringSetupTermsInitialRegistrationValue = create('initial-registration-value');
export const offeringSetupTermsPrimaryShares = create('primary-shares');
export const offeringSetupTermsSecondaryShares = create('secondary-shares');
export const offeringSetupTermsOfferPrice = create('offer-price');
export const offeringSetupTermsIPORangeLow = create('ipo-range-low');
export const offeringSetupTermsIPORangeHigh = create('ipo-range-high');
export const offeringSetupTermsPostOfferingShares = create('post-offering-shares');
export const offeringSetupTermsLockUpPeriod = create('lock-up-period');
export const offeringSetupTermsUseOfProceeds = create('use-of-proceeds');

// Over-allotment Authorized
export const offeringSetupTermsOverAllotmentAuthorized = create('over-allotment-authorized');
export const offeringSetupTermsOverAllotmentAuthorizedPrimaryShares = create(
  'over-allotment-authorized-primary-shares'
);
export const offeringSetupTermsOverAllotmentAuthorizedSecondaryShares = create(
  'over-allotment-authorized-secondary-shares'
);

// Over-allotment Exercised
export const offeringSetupTermsOverAllotmentExercised = create('over-allotment-exercised');
export const offeringSetupTermsOverAllotmentExercisedPrimaryShares = create(
  'over-allotment-exercised-primary-shares'
);
export const offeringSetupTermsOverAllotmentExercisedSecondaryShares = create(
  'over-allotment-exercised-secondary-shares'
);

export const offeringSetupTermsAddNewFiling = create('add-new-filing');
