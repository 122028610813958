import {
  Banner,
  Icon,
  reduxUtil,
  ResponsiveDrawer,
  ServerErrors,
  useInfiniteScroll,
} from '@cmg/common';
import { myDashboard } from '@cmg/e2e-selectors';
import React, { RefObject } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { RootState } from '../../../common/redux/rootReducer';
import RecentDealsList from './components/recent-deals-list/RecentDealsList';
import {
  fetchRecentlyPricedDealsRequest,
  selectRecentDealsError,
  selectRecentDealsGroupedByFirstTradeDate,
  selectRecentDealsLoading,
  selectRecentDealsPagination,
} from './ducks';
import {
  SInfiniteList,
  SListInitialLoading,
  SListScrollLoading,
  SRecentDeals,
  STitle,
  StyledCalendarIcon,
} from './RecentDeals.styles';

/**
 * RecentDeals connected component responsible for fetching and displaying
 * recently priced deals
 */
const RecentDeals: React.FC = () => {
  const { fetchRecentDeals } = reduxUtil.useActions({
    fetchRecentDeals: fetchRecentlyPricedDealsRequest,
  });

  const { recentDeals, loading, pagination, error } = useSelector(
    (state: RootState) => ({
      recentDeals: selectRecentDealsGroupedByFirstTradeDate(state),
      pagination: selectRecentDealsPagination(state),
      loading: selectRecentDealsLoading(state),
      error: selectRecentDealsError(state),
    }),
    shallowEqual
  );
  React.useEffect(() => {
    fetchRecentDeals();
  }, [fetchRecentDeals]);

  const hasNext = Boolean(!loading && pagination?.hasNext);
  const showInfiniteScrollLoading = Boolean(loading && pagination?.hasNext);
  const initialLoading = loading && !pagination;

  const onNext = () => {
    fetchRecentDeals({ page: pagination!.activePage + 1 });
  };

  const scrollRef: RefObject<HTMLDivElement> = useInfiniteScroll({
    hasNext: hasNext, // loading and pagination combined to allow onNext
    onNext,
  });

  return (
    <ResponsiveDrawer
      testId={myDashboard.showRecentDeals.testId}
      breakpoint="xxlarge"
      header={aboveBreakpoint => {
        return (
          !aboveBreakpoint && (
            <STitle>
              <StyledCalendarIcon name="calendar-alt" size="lg" />
              <h3>Recently Priced Deals</h3>
            </STitle>
          )
        );
      }}
    >
      <SRecentDeals data-test-id={myDashboard.recentDeals.testId}>
        {/* Initial loading indicator before pagination is returned */}
        {initialLoading && (
          <SListInitialLoading>
            {/** @todo replace with loading skeleton */}
            <Icon name="spinner-third" size="2x" fixedWidth spin />
          </SListInitialLoading>
        )}
        {error && (
          <Banner showIcon={false} variant="error">
            <ServerErrors error={error} />
          </Banner>
        )}
        {!initialLoading && (
          <SInfiniteList ref={scrollRef} data-test-id={myDashboard.recentDealsList.testId}>
            <RecentDealsList recentDeals={recentDeals} />
          </SInfiniteList>
        )}
        {showInfiniteScrollLoading && (
          <SListScrollLoading>
            <Icon name="spinner-third" size="lg" fixedWidth spin />
          </SListScrollLoading>
        )}
      </SRecentDeals>
    </ResponsiveDrawer>
  );
};

export default RecentDeals;
