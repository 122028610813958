import { createTestId } from '../../common/selectors';

const create = (name: string) => createTestId(`xc-calendar-${name}`);

export const calendarScreen = create('calendar-screen');

// navbar
export const calendarNavBar = create('navbar');
export const calendarNavBarLive = create('navbar-live');
export const calendarNavBarPriced = create('navbar-priced');
export const calendarNavBarFiled = create('navbar-filed');
export const calendarNavBarPostponed = create('navbar-postponed');

// offering side panel in offering grid
export const calendarOfferingSidePanelLink = create('offering-side-panel-link');
