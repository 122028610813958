import { createTestId } from '../../common/selectors';

export * from './ds';
export * from './noi';
export * from './rpn';
export * from './tn';
export * from './usdtn';

const create = (name: string) => createTestId(`xc-regulatory-filings-${name}`);

export const regulatoryFilingsScreen = create('screen');

// common
export const regulatoryFilingsSubmitButton = create('submit');
export const regulatoryFilingsCancelButton = create('cancel');
export const regulatoryFilingsAmendButton = create('amend');

export const regulatoryFilingsSubmitModalConfirmButton = create('submit-modal-confirm');
export const regulatoryFilingsSubmitModalCancelButton = create('submit-modal-cancel');
export const regulatoryFilingsCancelModalConfirmButton = create('cancel-modal-confirm');
export const regulatoryFilingsCancelModalCancelButton = create('cancel-modal-cancel');

export const regulatoryFilingsCommonForm = create('common-form');
export const regulatoryFilingsCommonDetail = create('common-detail');

export const regulatoryFilingsFilingDetailsForm = create('filing-details-form');
export const regulatoryFilingsFilingDetail = create('filing-detail');

// symbol of covered securities
export const regulatoryFilingsAddSymbol = create('add-symbol');

// navbar
export const regulatoryFilingsNavbar = create('navbar');
export const regulatoryFilingsNavbarRPN = create('navbar-rpn');
export const regulatoryFilingsNavbarNOI = create('navbar-noi');
export const regulatoryFilingsNavbarDS = create('navbar-ds');
export const regulatoryFilingsNavbarTN = create('navbar-tn');
export const regulatoryFilingsNavbarUSDTN = create('navbar-usdtn');
