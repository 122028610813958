import { datalabApi } from '@cmg/api';
import { checkPermissions, getUserPermissions, permissionsByEntity } from '@cmg/auth';
import { countriesList, Option } from '@cmg/common';
import flatten from 'lodash/flatten';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import uniqWith from 'lodash/uniqWith';
import memoize from 'memoize-one';

import { getFeatureToggles } from '../../../config/appSettings';
import {
  ManagerRole,
  OfferingType,
  SpacFilterOptions,
} from '../../../types/domain/offering/constants';
import {
  formatSectorName,
  sectorNameDisplay,
} from '../../../types/domain/sector/cmgSectorConstants';
import {
  isInternationalOfferingsOn,
  isInternationalOfferingTypesOn,
} from '../../datalab/model/utils';

export enum filterByEnum {
  IS_EQUAL = 'IS_EQUAL',
  IS_EQUAL_BOOLEAN = 'IS_EQUAL_BOOLEAN',
  IS_IN = 'IS_IN',
  IS_IN_BETWEEN_MILLION = 'IS_IN_BETWEEN_MILLION',
  HAS_MEMBER_IN = 'HAS_MEMBER_IN',
  IS_LIKE = 'IS_LIKE',
}

export enum InternalOfferingType {
  IPO_SPACS = 'IPO_SPACS',
  ABB_ABO = 'ABB_ABO',
  BLOCK = 'BLOCK',
  FULLY_MARKETED_OFFERING = 'FULLY_MARKETED_OFFERING',
}

export const CalendarOptions: {
  [key in OfferingType | InternalOfferingType]: Option;
} = {
  [OfferingType.IPO]: { value: OfferingType.IPO, label: 'IPO' },
  [OfferingType.RIGHTS]: { value: OfferingType.RIGHTS, label: 'Rights' },
  [OfferingType.OVERNIGHT_FO]: { value: OfferingType.OVERNIGHT_FO, label: 'Overnight FO' },
  [OfferingType.MARKETED_FO]: { value: OfferingType.MARKETED_FO, label: 'Marketed FO' },
  [InternalOfferingType.ABB_ABO]: { value: 'ABB_ABO', label: 'ABB/ABO' },
  [InternalOfferingType.BLOCK]: { value: 'BLOCK', label: 'Block' },
  [OfferingType.REGISTERED_BLOCK]: {
    value: OfferingType.REGISTERED_BLOCK,
    label: 'Registered Block',
  },
  [OfferingType.UNREGISTERED_BLOCK]: {
    value: OfferingType.UNREGISTERED_BLOCK,
    label: 'Unregistered Block',
  },
  [InternalOfferingType.IPO_SPACS]: {
    value: InternalOfferingType.IPO_SPACS,
    label: 'IPO (SPACs)',
  },
  [InternalOfferingType.FULLY_MARKETED_OFFERING]: {
    value: InternalOfferingType.FULLY_MARKETED_OFFERING,
    label: 'Fully Marketed Offering',
  },
  [OfferingType.FO]: {
    value: OfferingType.FO,
    label: 'Follow On',
  },
  [OfferingType.CONVERTIBLE]: {
    value: OfferingType.CONVERTIBLE,
    label: 'Convertible',
  },
  [OfferingType.ATM]: {
    value: OfferingType.ATM,
    label: 'Atm',
  },
  [OfferingType.REGISTERED_DIRECT]: {
    value: OfferingType.REGISTERED_DIRECT,
    label: 'Registered direct',
  },
  [OfferingType.DIRECT_LISTING]: {
    value: OfferingType.DIRECT_LISTING,
    label: 'Direct listing',
  },
};

export type FilterConfig = {
  getValue: (row) => any;
  filterBy: filterByEnum;
  value: any;
};

export type FilterValues = {
  offeringType: string[];
  sector: string[];
  customSectorId: string[];
  spac: string;
  sizeInDollars: {
    max?: number;
    min?: number;
  };
  marketCap: {
    max?: number;
    min?: number;
  };
  leftLeadFirmId: string[];
  managerFirmId: string[];
  useCustomSectors: boolean;
  countries: string[];
};
export type Country = {
  title: string;
  value: string;
};
export type CountryByRegion = {
  [key: string]: Country[];
};

const uniqueArrByProp = (myArr, prop) => {
  return myArr.filter(
    (obj, pos, arr) => arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
  );
};

const isFilterEqual = (row: Object, option: string, getValue: Function) => {
  if (!option) {
    // not filtering
    return true;
  }

  return getValue(row) === option;
};

const isEqualBoolean = (row: Object, option: boolean, getValue: Function) => {
  return getValue(row) === option;
};

const isIn = (row: Object, options: string[], getValue: Function) => {
  if (options.length === 0) {
    // not filtering
    return true;
  }
  const value = getValue(row);
  if (!value) {
    return false;
  }
  return isArray(value) ? options.some(_value => value.includes(_value)) : options.includes(value);
};

const isInBetweenMillion = (
  row: Object,
  range: {
    max?: number | null;
    min?: number | null;
  },
  getValue: Function
) => {
  const value = getValue(row);
  const maxMillions = range.max ? range.max * 1000000 : undefined;
  const minMillions = range.min ? range.min * 1000000 : undefined;
  if (maxMillions && minMillions) {
    return (value >= minMillions && value <= maxMillions) || value === null;
  }
  if (maxMillions) {
    return value <= maxMillions || value === null;
  }
  if (minMillions) {
    return value >= minMillions || value === null;
  }
  return true;
};

const hasMemberIn = (row: Object, options: string[], getValue: Function) => {
  if (options.length === 0) {
    // not filtering
    return true;
  }

  const values = getValue(row);
  return values && values.some(item => options.includes(item));
};

export const applyFilters = <TRowEntity extends Object>(
  rows: TRowEntity[] = [],
  filters: FilterConfig[] = []
): TRowEntity[] => {
  if (!filters || !filters.length) {
    return rows;
  }

  return rows.filter(row =>
    filters.every(f => {
      switch (f.filterBy) {
        case filterByEnum.IS_EQUAL:
          return isFilterEqual(row, f.value, f.getValue);
        case filterByEnum.IS_EQUAL_BOOLEAN:
          return isEqualBoolean(row, f.value, f.getValue);
        case filterByEnum.IS_IN:
          return isIn(row, f.value, f.getValue);
        case filterByEnum.HAS_MEMBER_IN:
          return hasMemberIn(row, f.value, f.getValue);
        case filterByEnum.IS_IN_BETWEEN_MILLION:
          return isInBetweenMillion(row, f.value, f.getValue);
        default:
          return true;
      }
    })
  );
};

export function getAllowedOfferingTypes(offeringTypes: string[]) {
  const { isNewDirectOfferingFieldsOn: isViewDirectsOn } = getFeatureToggles();
  const canViewIntlOfferingTypes = isInternationalOfferingTypesOn();
  return offeringTypes.filter(
    o =>
      (isViewDirectsOn || o !== OfferingType.REGISTERED_DIRECT) &&
      (isViewDirectsOn || o !== OfferingType.DIRECT_LISTING) &&
      (canViewIntlOfferingTypes || o !== InternalOfferingType.FULLY_MARKETED_OFFERING) &&
      (canViewIntlOfferingTypes || o !== InternalOfferingType.BLOCK) &&
      (canViewIntlOfferingTypes || o !== InternalOfferingType.ABB_ABO)
  );
}

const getOfferingType = (row: datalabApi.CalendarOffering) => {
  const canViewIntlOfferingTypes = isInternationalOfferingTypesOn();
  if (row.isSpac) {
    return InternalOfferingType.IPO_SPACS.valueOf();
  }
  if (
    canViewIntlOfferingTypes &&
    row.pricingCurrencyCode !== 'USD' &&
    row.type === OfferingType.OVERNIGHT_FO
  ) {
    return InternalOfferingType.ABB_ABO.valueOf();
  }
  if (
    canViewIntlOfferingTypes &&
    row.pricingCurrencyCode !== 'USD' &&
    row.type === OfferingType.MARKETED_FO
  ) {
    return InternalOfferingType.FULLY_MARKETED_OFFERING.valueOf();
  }
  if (
    canViewIntlOfferingTypes &&
    row.pricingCurrencyCode !== 'USD' &&
    (row.type === OfferingType.REGISTERED_BLOCK || row.type === OfferingType.UNREGISTERED_BLOCK)
  ) {
    return InternalOfferingType.BLOCK.valueOf();
  }

  return row.type;
};
export function getFilterConfig(values: FilterValues): FilterConfig[] {
  const canReadCustomSectors = checkPermissions(getUserPermissions(), [
    permissionsByEntity.CustomSectors.READ,
  ]);
  let sector = values.sector;
  if (canReadCustomSectors && values.useCustomSectors) {
    sector = values.customSectorId;
  }
  const offeringTypes = getAllowedOfferingTypes(values.offeringType);
  const showInternational = isInternationalOfferingsOn();

  return [
    {
      getValue: (row: datalabApi.CalendarOffering) => getOfferingType(row),
      filterBy: filterByEnum.IS_IN,
      value: offeringTypes,
    },
    {
      getValue: (row: datalabApi.CalendarOffering) => {
        if (!canReadCustomSectors) {
          return row.sector;
        }
        return values.useCustomSectors ? row.customSectorId : row.sector;
      },
      filterBy: filterByEnum.IS_IN,
      value: sector,
    },
    {
      getValue: (row: datalabApi.CalendarOffering) => row.leftLeadFirmId,
      filterBy: filterByEnum.IS_IN,
      value: values.leftLeadFirmId,
    },
    {
      getValue: (row: datalabApi.CalendarOffering) =>
        row.managers && row.managers.map(item => item.firmId),
      filterBy: filterByEnum.HAS_MEMBER_IN,
      value: values.managerFirmId,
    },
    {
      getValue: (row: datalabApi.CalendarOffering) => row.marketCap,
      filterBy: filterByEnum.IS_IN_BETWEEN_MILLION,
      value: values.marketCap,
    },
    {
      getValue: (row: datalabApi.CalendarOffering) => row.sizeInDollars,
      filterBy: filterByEnum.IS_IN_BETWEEN_MILLION,
      value: values.sizeInDollars,
    },
    ...(showInternational
      ? [
          {
            getValue: (row: datalabApi.CalendarOffering) => row.countryCode,
            filterBy: filterByEnum.IS_IN,
            value: values.countries,
          },
        ]
      : []),
  ];
}

/*
SPAC fields are filtered differently than other fields
Rather than dynamically comparing strings to strings,
SPAC has three hard-coded filtering options
 */
export const spacFilterMap = {
  /* To "Include" means to show both SPAC and non-SPAC offerings */
  [SpacFilterOptions.INCLUDE]: {
    filterBy: filterByEnum.IS_IN,
    value: [],
  },
  [SpacFilterOptions.EXCLUDE]: {
    filterBy: filterByEnum.IS_EQUAL_BOOLEAN,
    value: false,
  },
  [SpacFilterOptions.ONLY]: {
    filterBy: filterByEnum.IS_EQUAL_BOOLEAN,
    value: true,
  },
};

export function getAllUnderwriters(offerings: datalabApi.CalendarOffering[]): Option[] {
  const managers: datalabApi.CalendarOfferingManager[] = flatten(
    offerings.map(offering => offering.managers || [])
  );

  const underwriters = managers.filter(
    manager =>
      manager.role === ManagerRole.CO_MANAGER ||
      manager.role === ManagerRole.CO_LEAD ||
      manager.role === ManagerRole.ACTIVE_BOOKRUNNER ||
      manager.role === ManagerRole.BOOKRUNNER
  );

  const options = uniqueArrByProp(underwriters, 'firmId').map(underwriter => ({
    label: underwriter.firmName,
    value: underwriter.firmId,
  }));

  return sortBy(options, option => option.label.toLowerCase());
}

export function getAllSectors(): Option[] {
  const sectors = Object.keys(sectorNameDisplay).map(sector => ({
    label: formatSectorName(sector),
    value: sector,
  }));

  return sortBy(sectors, sector => sector.label);
}

const offeringTypeSortOrder: { [key in OfferingType | InternalOfferingType]: number } = {
  [OfferingType.IPO]: 1,
  [InternalOfferingType.IPO_SPACS]: 2,
  [OfferingType.FO]: 3,
  [InternalOfferingType.FULLY_MARKETED_OFFERING]: 4,
  [OfferingType.MARKETED_FO]: 5,
  [InternalOfferingType.ABB_ABO]: 6,
  [OfferingType.OVERNIGHT_FO]: 7,
  [OfferingType.RIGHTS]: 8,
  [InternalOfferingType.BLOCK]: 9,
  [OfferingType.REGISTERED_BLOCK]: 10,
  [OfferingType.UNREGISTERED_BLOCK]: 11,
  [OfferingType.CONVERTIBLE]: 12,
  [OfferingType.ATM]: 13,
  [OfferingType.REGISTERED_DIRECT]: 14,
  [OfferingType.DIRECT_LISTING]: 15,
};

export function useGetAllOfferingValues(): string[] {
  return getAllOfferingOptions().map(option => option.value);
}

const excludedOfferingTypes = [OfferingType.ATM.valueOf()];

export function getAllOfferingOptions(): Option[] {
  const userPermissions = getUserPermissions();
  const canViewConverts = checkPermissions(userPermissions, [
    permissionsByEntity.ConvertsOffering.READ,
  ]);
  const canViewRights = checkPermissions(userPermissions, [
    permissionsByEntity.InternationalOffering.READ,
  ]);
  const canViewIntlOfferingTypes = isInternationalOfferingTypesOn();
  const { isNewDirectOfferingFieldsOn: canViewDirects } = getFeatureToggles();

  return sortBy(CalendarOptions, option => offeringTypeSortOrder[option.value]).filter(
    option =>
      !excludedOfferingTypes.includes(option.value) &&
      (canViewConverts || option.value !== OfferingType.CONVERTIBLE) &&
      (canViewRights || option.value !== OfferingType.RIGHTS) &&
      (canViewDirects || option.value !== OfferingType.DIRECT_LISTING) &&
      (canViewDirects || option.value !== OfferingType.REGISTERED_DIRECT) &&
      (canViewIntlOfferingTypes || option.value !== InternalOfferingType.FULLY_MARKETED_OFFERING) &&
      (canViewIntlOfferingTypes || option.value !== InternalOfferingType.BLOCK) &&
      (canViewIntlOfferingTypes || option.value !== InternalOfferingType.ABB_ABO)
  );
}

export function getAllLeftLeads(offerings: datalabApi.CalendarOffering[]): Option[] {
  const leftLeads = uniqueArrByProp(offerings, 'leftLeadFirmId').map(offering => ({
    label: offering.leftLeadFirmName,
    value: offering.leftLeadFirmId,
  }));

  return sortBy(leftLeads, option => option.label);
}

export function getAllCustomSectors(offerings: datalabApi.CalendarOffering[]): Option[] {
  return uniqueArrByProp(offerings, 'customSectorId')
    .map(offering => ({
      label: offering.customSectorName,
      value: offering.customSectorId,
    }))
    .filter(option => option.value);
}

export const getRegionToCountry = (): CountryByRegion => {
  return Object.values(countriesList).reduce((acc, countries) => {
    const { region, countryCode, countryDisplayName } = countries;

    if (!region || !countryDisplayName || !countryCode) {
      return acc || [];
    }
    const upperCaseRegion = region.toUpperCase();
    const prevCountries = acc[upperCaseRegion] || [];
    const nextCountries = uniqWith(
      [...prevCountries, { title: countryDisplayName, value: countryCode }],
      isEqual
    );

    return { ...acc, [upperCaseRegion]: nextCountries };
  }, {});
};

export const getRegionAndCountryOptions = memoize(() => {
  const values = getRegionToCountry();
  return Object.keys(values).map(region => {
    return {
      title: region,
      value: (values[region] ?? []).map(country => country.value).toString(),
      children: (values[region] ?? []).map(({ value, title }) => {
        return {
          title: title,
          value: values[region].length > 1 ? value : title,
        };
      }),
    };
  });
});

/*
Unlike other filter options which are compiled from the existing offerings,
SPAC options are hard-coded.
 */
export function getAllSpacOptions(): Option[] {
  return [
    { label: 'Include SPACs', value: SpacFilterOptions.INCLUDE },
    { label: 'Exclude SPACs', value: SpacFilterOptions.EXCLUDE },
    { label: 'SPACs Only', value: SpacFilterOptions.ONLY },
  ];
}
