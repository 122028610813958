import { datalabApi } from '@cmg/api';
import { ToastManager } from '@cmg/common';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import { UUID } from '../../../types/common';
import { toastMessages } from '../../shared/constants/messages';
import { fetchSectors } from '../../shared/sectors/ducks';

export enum ActionTypes {
  SUBMIT_SECTOR_REQUEST = 'sectors/SUBMIT_SECTOR_REQUEST',
  SUBMIT_SUB_SECTOR_REQUEST = 'sectors/SUBMIT_SUB_SECTOR_REQUEST',
  DELETE_SECTOR_REQUEST = 'sectors/DELETE_SECTOR_REQUEST',
  DELETE_SUB_SECTOR_REQUEST = 'sectors/DELETE_SUB_SECTOR_REQUEST',
}

export const submitSector = (payload: {
  sectorId?: UUID;
  data: datalabApi.CustomSectorBaseDto;
}) => ({
  type: ActionTypes.SUBMIT_SECTOR_REQUEST,
  payload,
});

export const submitSubSector = (payload: {
  sectorId?: UUID;
  parentId: UUID;
  data: datalabApi.CustomSectorBaseDto;
}) => ({
  type: ActionTypes.SUBMIT_SUB_SECTOR_REQUEST,
  payload,
});

export const deleteSector = (payload: { sectorId: UUID }) => ({
  type: ActionTypes.DELETE_SECTOR_REQUEST,
  payload,
});

export const deleteSubSector = (payload: { sectorId: UUID; parentId: UUID }) => ({
  type: ActionTypes.DELETE_SUB_SECTOR_REQUEST,
  payload,
});

type Actions = {
  [ActionTypes.SUBMIT_SECTOR_REQUEST]: ReturnType<typeof submitSector>;
  [ActionTypes.SUBMIT_SUB_SECTOR_REQUEST]: ReturnType<typeof submitSubSector>;
  [ActionTypes.DELETE_SECTOR_REQUEST]: ReturnType<typeof deleteSector>;
  [ActionTypes.DELETE_SUB_SECTOR_REQUEST]: ReturnType<typeof deleteSubSector>;
};

/**
 * there is no reducer but only sagas which eventually refresh sectors-state from /shared/
 * as soon as there will be need for more specific list of sectors (filtered, ordered...) we can create it there on demand
 */

/**
 * SAGAS
 */

export function* submitSectorSaga({
  payload: { sectorId, data },
}: Actions[ActionTypes.SUBMIT_SECTOR_REQUEST]): SagaIterator {
  let resp: datalabApi.CreateCustomSectorResponse | datalabApi.UpdateCustomSectorResponse;

  if (sectorId) {
    resp = yield call(datalabApi.updateCustomSector, data, { id: sectorId });
  } else {
    resp = yield call(datalabApi.createCustomSector, data, {});
  }

  if (resp.ok) {
    ToastManager.success(toastMessages.success.saveCustomSector);
    yield put(fetchSectors());
  } else {
    ToastManager.error(toastMessages.error.errorSaving);
  }
}

export function* submitSubSectorSaga({
  payload: { sectorId, parentId, data },
}: Actions[ActionTypes.SUBMIT_SUB_SECTOR_REQUEST]): SagaIterator {
  let resp: datalabApi.CreateCustomSubSectorResponse | datalabApi.UpdateCustomSubSectorResponse;

  if (sectorId) {
    resp = yield call(datalabApi.updateCustomSubSector, data, { id: sectorId, parentId });
  } else {
    resp = yield call(datalabApi.createCustomSubSector, data, { parentId });
  }

  if (resp.ok) {
    ToastManager.success(toastMessages.success.saveCustomSubSector);
    yield put(fetchSectors());
  } else {
    ToastManager.error(toastMessages.error.errorSaving);
  }
}

export function* deleteCustomSectorSaga({
  payload: { sectorId },
}: Actions[ActionTypes.DELETE_SECTOR_REQUEST]): SagaIterator {
  const resp: datalabApi.DeleteCustomSectorResponse = yield call(datalabApi.deleteCustomSector, {
    id: sectorId,
  });

  if (resp.ok) {
    ToastManager.success(toastMessages.success.deleteCustomSector);
    yield put(fetchSectors());
  } else {
    ToastManager.error(toastMessages.error.errorDeleting);
  }
}

export function* deleteCustomSubSectorSaga({
  payload: { sectorId, parentId },
}: Actions[ActionTypes.DELETE_SUB_SECTOR_REQUEST]): SagaIterator {
  const resp: datalabApi.DeleteCustomSubSectorResponse = yield call(
    datalabApi.deleteCustomSubSector,
    {
      id: sectorId,
      parentId,
    }
  );

  if (resp.ok) {
    ToastManager.success(toastMessages.success.deleteCustomSubSector);
    yield put(fetchSectors());
  } else {
    ToastManager.error(toastMessages.error.errorDeleting);
  }
}

export function* settingsSectorsSaga() {
  yield takeEvery<Actions[ActionTypes.SUBMIT_SECTOR_REQUEST]>(
    ActionTypes.SUBMIT_SECTOR_REQUEST,
    submitSectorSaga
  );
  yield takeEvery<Actions[ActionTypes.SUBMIT_SUB_SECTOR_REQUEST]>(
    ActionTypes.SUBMIT_SUB_SECTOR_REQUEST,
    submitSubSectorSaga
  );
  yield takeEvery<Actions[ActionTypes.DELETE_SECTOR_REQUEST]>(
    ActionTypes.DELETE_SECTOR_REQUEST,
    deleteCustomSectorSaga
  );
  yield takeEvery<Actions[ActionTypes.DELETE_SUB_SECTOR_REQUEST]>(
    ActionTypes.DELETE_SUB_SECTOR_REQUEST,
    deleteCustomSubSectorSaga
  );
}
