import { createTestId } from '../../../common/selectors';

const create = (name: string) => createTestId(`xc-offering-side-panel-offering-notes-${name}`);

export const offeringNotesScreen = create('screen');

export const offeringNotesList = create('list');

export const offeringNotesForm = create('form');

export const offeringNotesCreateNoteButton = create('create-note-button');

export const offeringNotesSaveNoteChangesButton = create('save-note-changes-button');

export const offeringNotesDiscardNoteChangesButton = create('discard-note-changes-button');

export const offeringNotesEditNoteButton = create('edit-note-button');

export const offeringNotesDeleteNoteButton = create('delete-note-button');

export const offeringNotesConfirmDeleteNoteButton = create('confirm-delete-note-button');

export const offeringNotesCancelDeleteNoteButton = create('cancel-delete-note-button');
