import React from 'react';

import { Option } from '../../form/select/types';
import { StyledCheckbox } from './CheckboxTreeGroup.styles';
import { SLeafWrapper } from './CheckboxTreeLeaf.styles';

export type Props<TOptionValue> = {
  /**
   * Allow style tweaks
   */
  className?: string;
  /**
   * Set checkbox to be disabled
   */
  isDisabled?: boolean;
  /**
   * Set checkbox to be checked if selected
   */
  isSelected?: boolean;
  /**
   * Option to render
   */
  option: Option<TOptionValue>;
  /**
   * Gets called when selection changes
   */
  onChange: (option: Option<TOptionValue>, selected: boolean) => void;
};

/**
 * Tree leaf node component.
 */
const CheckboxTreeLeaf = <TOptionValue,>({
  isDisabled,
  isSelected,
  option,
  onChange,
  className,
}: Props<TOptionValue>) => {
  const handleOnChange = (checked: boolean) => {
    onChange(option, checked);
  };

  return (
    <SLeafWrapper className={className}>
      <StyledCheckbox value={isSelected} disabled={isDisabled} onChange={handleOnChange} />
      {option.label}
    </SLeafWrapper>
  );
};

export default CheckboxTreeLeaf;
