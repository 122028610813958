import { createTestId } from '../../../common/selectors';

const create = (name: string) => createTestId(`xc-wires-reg-m-${name}`);

export const wiresRegMEditButton = create('edit-button');
export const wiresRegMDeleteButton = create('delete-button');
export const wiresRegMSendButton = create('send-button');

export const wiresRegMModalCancelButton = create('modal-cancel-button');
export const wiresRegMModalSaveButton = create('modal-save-button');

export const wiresRegMFormOfferingResultInput = create('form-offering-result-input');
export const wiresRegMFormNotificationPeriodInput = create('form-notification-period-input');
