import { ICellEditorParams } from 'ag-grid-community';
import React from 'react';
import styled from 'styled-components/macro';

import Popover from '../../../overlays/popover/Popover';

export const SAgEditor = styled.div`
  position: absolute;
  input {
    border-radius: 0;
    height: ${({ theme }) => theme.table.cell.height};
    width: 100%;
    border-color: transparent;
  }
`;
export const SError = styled.div`
  color: ${({ theme }) => theme.text.color.error};
`;

const defaultErrorCheck = value => (value < 0 ? 'Value must be a positive number' : undefined);

type CellEditorParams = { [key: string]: any };
type Options = {
  processCharPress?: (char: string) => string;
  errorCheck?: (value: number, params: CellEditorParams) => string | undefined;
};
export function createAgEditor(WrappedComponent, options: Options = {}) {
  type OwnValues = {
    params: CellEditorParams;
    onChange?: (value: number) => void;
    onFocus?: () => void;
  };
  type Props = ICellEditorParams & OwnValues;
  type State = {
    value: number;
    error?: string;
    isFocused: boolean;
  };

  return class extends React.Component<Props, State> {
    state = {
      value: this.props.value,
      error: undefined,
      isFocused: false,
    };

    ref = React.createRef<HTMLInputElement>();

    // used directly by agGrid - similar to componentDidMount but few ticks later
    // docs: https://www.ag-grid.com/javascript-grid-cell-editor/
    afterGuiAttached() {
      const { charPress, value } = this.props;
      const { processCharPress } = options;

      if (this.ref.current) {
        this.ref.current.focus();
        this.ref.current.select();
      }

      if (charPress) {
        const charValue = processCharPress ? processCharPress(charPress) : charPress;
        const value = parseFloat(charValue);
        this.setState({ value });
        const size = isNaN(value) ? 0 : value.toString().length;
        this.ref.current && this.ref.current.setSelectionRange(size, size); // cursor fix for IE
      }

      const error = this.validateValue(value);

      if (error) {
        this.setState({ error });
      }
    }

    // used directly by agGrid - value getter triggered during mounting and unmounting
    getValue = () => {
      const errorOrNull = !!this.state.error || this.state.value === null;
      if (!errorOrNull && this.props.onChange && this.props.value !== this.state.value) {
        this.props.onChange(this.state.value);
      } else if (errorOrNull) {
        this.setState({ value: this.props.value });
      }
      return this.state.value;
    };

    validateValue = (value: number): string | undefined => {
      return options.errorCheck
        ? options.errorCheck(value, this.props.params)
        : defaultErrorCheck(value);
    };

    handleChange = (value: number) => {
      this.setState({ value, error: this.validateValue(value) });
    };

    handleFocus = () => {
      const { onFocus } = this.props;

      this.setState({ isFocused: true });
      onFocus && onFocus();
    };

    handleBlur = () => {
      const { stopEditing } = this.props;

      this.setState({ isFocused: false });
      stopEditing();
    };

    render() {
      const { error, isFocused } = this.state;

      return (
        <Popover visible={!!(error && isFocused)} content={<SError>{this.state.error}</SError>}>
          <SAgEditor>
            <WrappedComponent
              value={this.state.value}
              onChange={this.handleChange}
              hasError={!!this.state.error}
              ref={this.ref}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
            />
          </SAgEditor>
        </Popover>
      );
    }
  };
}
