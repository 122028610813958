import { datalabApi } from '@cmg/api';
import React from 'react';
import styled from 'styled-components/macro';

import { firmSubTypeLabels } from '../../constants/constants';
import { FirmSearchOption } from '../types';

const SWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SContent = styled.div`
  margin-left: 10px;
  color: ${({ theme }) => theme.color.gray7};
  text-align: right;
`;

function formatFirmSubType(firmSubType: datalabApi.FirmSubType | null) {
  if (!firmSubType || !firmSubTypeLabels[firmSubType]) {
    return null;
  }

  return firmSubTypeLabels[firmSubType];
}

type Props = {
  option: FirmSearchOption;
};

/**
 * Given a firm search option, provided by FirmSearchMultiSelect, it renders the
 * label (which is set by a datasource, but should be the legalName) on the left
 * and an abbreviated version of the firm's sub type.
 */
const FirmSearchOptionRenderer: React.FC<Props> = ({ option }) => (
  <SWrapper>
    <div>{option.label}</div>
    <SContent>{formatFirmSubType(option.firm.firmSubType)}</SContent>
  </SWrapper>
);

export default FirmSearchOptionRenderer;
