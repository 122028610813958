import { createTestId } from '../../../common/selectors';

const create = (name: string) => createTestId(`xc-roadshows-deal-teams-${name}`);

export const createDealTeamButton = create('create-deal-team-button');
export const cancelDealTeamButton = create('cancel-deal-team-button');

export const listDeleteDealTeamButton = create('deal-team-list-delete-button');

export const cancelDeleteDealTeamMemberButton = create('cancel-delete-deal-team-member-button');
export const confirmDeleteDealTeamMemberButton = create('confirm-delete-deal-team-member-button');

export const cancelDeleteDealTeamButton = create('cancel-delete-deal-team-button');
export const confirmDeleteDealTeamButton = create('confirm-delete-deal-team-button');

export const saveChangesDealTeamButton = create('deal-team-header-save-button');
export const discardChangesDealTeamButton = create('deal-team-header-discard-button');
export const deleteDealTeamButton = create('deal-team-header-delete-button');

export const cancelDeleteDealTeamDepartmentButton = create(
  'cancel-delete-deal-team-department-button'
);
export const confirmDeleteDealTeamDepartmentButton = create(
  'confirm-delete-deal-team-department-button'
);

export const addDealTeamMember = create('add-deal-team-member-button');
export const removeDealTeamMember = create('remove-deal-team-member-button');
export const importDealTeamMembers = create('import-deal-team-members-button');

export const addDealTeamDepartment = create('add-deal-team-department-button');
export const removeDealTeamDepartment = create('remove-deal-team-department-button');
