import {
  DLGWCustomFilters,
  DLGWDataLabTableAverageRequest,
  DLGWDataLabTableCreditRequest,
  DLGWDataLabTableRequest,
  DLGWDataLabTableRequestOrderField,
  DLGWOfferingType,
  DLGWSpacFilter,
  SortOrderDirection,
} from '@capital-markets-gateway/api-client-datalab-gateway';

import { DatalabRequestDto } from '../../../../features/datalab/types';
import { mapCustomFilter } from './customFilterMappers';
import { mapAverageMethodFilter, mapPlCreditFilter } from './enumMappers';
import { mapLegacyFieldToDLGWField } from './tableFieldNameMappers';
import { DataLabTableRowField } from './types';

// Ruby would accept values that the server could not order by, it would ignore them.
//  This occurs when the client paginates fields and sorting is done on the front end.
//  Filtering those non-server-sortable fields out here, otherwise DLGW .NET throws a validation error.
const DLGWOrderFieldNames: string[] = Object.values(DLGWDataLabTableRequestOrderField);
const mapOrderField = (legacyOrderField: DataLabTableRowField) => {
  const dlgwTableField = mapLegacyFieldToDLGWField(
    legacyOrderField
  ) as DLGWDataLabTableRequestOrderField;
  return DLGWOrderFieldNames.includes(dlgwTableField || '') ? dlgwTableField : undefined;
};

export const mapLegacyRequestBodyToDlgw = (
  legacyBody: DatalabRequestDto
): DLGWDataLabTableRequest & DLGWDataLabTableCreditRequest & DLGWDataLabTableAverageRequest => {
  const legacyFilters = legacyBody.data_lab;
  return {
    filters: {
      startDate: legacyFilters.start_date_value, // WORKS
      endDate: legacyFilters.end_date_value, // WORKS
      // verified mapping - filter.model.ts getOfferingTypeOptions
      offeringTypes: legacyFilters.offering_type_values as DLGWOfferingType[], // WORKS
      sectorCodes: legacyFilters.by_sectors_values, // WORKS
      customSectorIds: legacyFilters.by_custom_sectors_values, // WORKS
      underwritersIds: legacyFilters.underwriters_values, // WORKS
      fundsIds: legacyFilters.funds_values,
      sponsorsIds: legacyFilters.sponsors_values, // WORKS
      advisoriesIds: legacyFilters.advisories_values, // WORKS
      customFilters: mapCustomFilter(legacyFilters.advanced_query) as DLGWCustomFilters | null, // WORKS
      leftLeadOnly: legacyFilters.left_lead_only, // WORKS - exclusive to league tables
      // incorrectly marked as boolean on legacy type -- look at DatalabFilterForm.tsx "ioiTypeOptions"
      filterForAllocations: null,
      marketCapMin: legacyFilters.market_cap_value_min as number, // WORKS
      marketCapMax: legacyFilters.market_cap_value_max as number, // WORKS
      offeringSizeMin: legacyFilters.offering_size_value_min as number, // WORKS
      offeringSizeMax: legacyFilters.offering_size_value_max as number, // WORKS
      sellingShareholderPctMin: legacyFilters.selling_shareholder_pct_min as number, // WORKS - i think
      sellingShareholderPctMax: legacyFilters.selling_shareholder_pct_max as number, // WORKS - i think
      spac: legacyFilters.spac as DLGWSpacFilter, // WORKS
      averageMethod: mapAverageMethodFilter(legacyFilters.avg_method),
      plCredit: mapPlCreditFilter(legacyFilters.pl_credit),
      // TODO: countriesValues: legacyFilters.countries_values, // MISSING IN DLGW
      // TODO: legacyFilters.custom_filter // MISSING IN DLGW ('future_expiration_date', 'filed_or_live') https://github.com/capital-markets-gateway/js/blob/be18cc06df5d7b208b149d331421c0b06d28e16d/packages/ecm-client-web/src/features/datalab/model/datalab-api-mapping.ts#L64
    },
    useCustomSectors: legacyBody.use_custom_sectors, // WORKS
    // orderField is the same across all types of table requests but has multiple enum definitions, making it so it's not
    //  possible to satisfy all enum definitions with one mapping. thus, casting to any...
    orderField: mapOrderField(legacyBody.order_field as DataLabTableRowField) as any, // WORKS
    page: legacyBody.page, // WORKS
    perPage: legacyBody.per_page, // WORKS
    includeTotals: true,
    orderDirection: legacyBody.order_direction as SortOrderDirection, // WORKS
  };
};
