import styled, { css } from 'styled-components/macro';

import { Severity } from './Alert.model';

type ContainerProps = {
  readonly severity: Severity;
  readonly withMargin?: boolean;
};

export const SContainer = styled.div<ContainerProps>`
  ${({ severity, withMargin, theme }) => css`
    padding: 8px 16px;
    display: flex;
    align-items: baseline;

    ${withMargin &&
    css`
      margin-bottom: 16px;
    `}

    ${severity === 'error' &&
    css`
      background-color: ${theme.designSystem.colors.red['000']};
    `}
    ${severity === 'warning' &&
    css`
      background-color: ${theme.designSystem.colors.yellow['000']};
    `}
    ${severity === 'info' &&
    css`
      background-color: ${theme.designSystem.colors.turquoise['000']};
    `}
    ${severity === 'success' &&
    css`
      background-color: ${theme.designSystem.colors.green['000']};
    `}
  `};
`;

export const SIconContainer = styled.div`
  margin-right: 16px;
`;

export const SContentContainer = styled.div`
  flex: 1;
`;

export const SActionContainer = styled.div`
  flex: 0 0;
  font-size: ${({ theme }) => theme.text.size.medium};
  font-weight: ${({ theme }) => theme.text.weight.regular};
`;

export const SDetails = styled.div`
  font-size: ${({ theme }) => theme.text.size.medium};
`;

export const STitle = styled.p`
  font-size: ${({ theme }) => theme.text.size.medium};
  font-weight: ${({ theme }) => theme.text.weight.medium};
  color: ${({ theme }) => theme.text.color.darkGray};
  margin: 0;

  & ~ ${SDetails} {
    margin-top: 12px;
  }
`;
