import flatten from 'lodash/flatten';
import isEqual from 'lodash/isEqual';
import React from 'react';

import { Group, Option } from '../../form/select/types';
import TextInput from '../../form/text-input/TextInput';
import { getVisibleNodes } from './CheckboxTree.model';
import {
  SLeafNode,
  SMessage,
  SNodeList,
  SQuickSelectOptions,
  SSearchWrapper,
  StyledLinkButton,
} from './CheckboxTree.styles';
import CheckboxTreeGroup from './CheckboxTreeGroup';
import CheckboxTreeLeaf from './CheckboxTreeLeaf';

export type Props<TOptionValue = string> = {
  /**
   * Enables style tweaks
   */
  className?: string;
  /**
   * Enables search bar
   */
  searchable?: boolean;
  /**
   * If supplied, hides the 'all, none, recommended' links
   */
  hideBulkActions?: boolean;
  /**
   * Enables custom rendering logic for search input
   */
  renderSearchInput?: (props: {
    onChange: (text: string | null) => void;
    value: string | null;
  }) => React.ReactElement;
  /**
   *
   * Render custom header above checkboxes
   */
  renderHeader?: () => React.ReactElement | null;
  /**
   * Tree structure
   */
  options?: (Option<TOptionValue> | Group<TOptionValue>)[];
  /**
   * List of selected nodes
   */
  values?: TOptionValue[];
  /**
   * Default node selection, equals to initial list of values if not specified.
   */
  defaultValues?: TOptionValue[];
  /**
   *  List of disabled nodes
   */
  disabled?: TOptionValue[];
  /**
   * Called when selection changes
   */
  onChange?: (values: TOptionValue[]) => void;
  /**
   * Limit height of node list
   */
  maxHeight?: number;
  /**
   * Test ID for this component
   */
  testId?: string;
};

/**
 * Simple Tree view component. Supports one level nesting only.
 */
const CheckboxTree = <TOptionValue,>({
  className,
  searchable,
  hideBulkActions,
  renderSearchInput = props => (
    <TextInput {...props} value={props.value || ''} autoFocus placeholder="Filter..." />
  ),
  disabled = [],
  values = [],
  defaultValues,
  options = [],
  renderHeader,
  onChange,
  maxHeight,
  testId,
}: Props<TOptionValue>) => {
  const [expanded, setExpanded] = React.useState<Group<TOptionValue>[]>([]);
  const [searchText, setSearchText] = React.useState<string | null>(null);
  const [defaultSelection, setDefaultSelection] = React.useState(defaultValues || values);

  React.useEffect(() => {
    defaultValues && setDefaultSelection(() => defaultValues);
  }, [defaultValues, values]);

  const handleSearchChange = (text: string | null) => {
    setSearchText(text);
  };

  const handleGroupToggle = ({ expanded: isExpanded, ...toggleGroup }: Group<TOptionValue>) => {
    if (isExpanded) {
      const nextExpanded: Group<TOptionValue>[] = expanded.filter(
        group => !isEqual(group, toggleGroup)
      );
      setExpanded(nextExpanded);
    } else {
      const nextExpanded: Group<TOptionValue>[] = [
        ...expanded,
        toggleGroup,
      ] as Group<TOptionValue>[];
      setExpanded(nextExpanded);
    }
  };

  const handleSelectAll = () => {
    const groupOptions = options.filter(option => option.options).map(option => option.options);
    const allOptions = [
      ...options.filter(option => !option.options),
      ...flatten(groupOptions),
    ] as Option<TOptionValue>[];
    const nextSelected = allOptions.map(({ value }) => value);

    onChange && onChange(nextSelected);
  };

  const handleDeselectAll = () => {
    const nextSelected = values.filter(value => disabled.includes(value));

    onChange && onChange(nextSelected);
  };

  const handleResetToDefault = () => {
    onChange && onChange(defaultSelection);
  };

  const handleGroupSelectionChange = groupValues => {
    onChange && onChange(groupValues);
  };

  const handleLeafSelectionChange = (option: Option<TOptionValue>, isSelected: boolean) => {
    const nextSelected = isSelected
      ? [...values, option.value]
      : values.filter(value => value !== option.value);

    onChange && onChange(nextSelected);
  };

  const visibleNodes = getVisibleNodes(options, searchText);

  return (
    <div className={className}>
      {searchable && (
        <SSearchWrapper>
          {renderSearchInput({ onChange: handleSearchChange, value: searchText })}
        </SSearchWrapper>
      )}

      {!!visibleNodes.length && !hideBulkActions && (
        <SQuickSelectOptions>
          <StyledLinkButton onClick={handleSelectAll}>All</StyledLinkButton>
          <StyledLinkButton onClick={handleDeselectAll}>None</StyledLinkButton>
          <StyledLinkButton onClick={handleResetToDefault}>Default</StyledLinkButton>
        </SQuickSelectOptions>
      )}

      {renderHeader && renderHeader()}

      <SNodeList maxHeight={maxHeight} data-test-id={testId}>
        {visibleNodes.length ? (
          visibleNodes.map(node => {
            if (!node.options) {
              return (
                <SLeafNode key={`${node.value}`}>
                  <CheckboxTreeLeaf
                    isDisabled={disabled.includes(node.value)}
                    isSelected={values.includes(node.value)}
                    option={node}
                    onChange={handleLeafSelectionChange}
                  />
                </SLeafNode>
              );
            }

            return (
              <CheckboxTreeGroup
                key={node.options.map(({ value }) => value).join(',')}
                group={node}
                onChange={handleGroupSelectionChange}
                onToggle={handleGroupToggle}
                values={values}
                disabled={disabled}
                expanded={!!(searchText || expanded.find(group => isEqual(group, node)))}
              />
            );
          })
        ) : (
          <SMessage>No Data</SMessage>
        )}
      </SNodeList>
    </div>
  );
};

export default CheckboxTree;
