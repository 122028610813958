import type {
  SnackbarOrigin,
  SnackbarProviderProps as NotistackSnackbarProviderProps,
} from 'notistack';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { defaultAnchorOrigin, defaultComponents, defaultMaxSnack } from './SnackbarProvider.model';

export type SnackbarProviderProps = {
  readonly maxSnack?: number;
  readonly Components?: NotistackSnackbarProviderProps['Components'];
  readonly anchorOrigin?: SnackbarOrigin;
};

export const SnackbarProvider: FC<SnackbarProviderProps> = ({
  children,
  Components,
  maxSnack = defaultMaxSnack,
  anchorOrigin = defaultAnchorOrigin,
  ...props
}) => {
  const mergedComponents = useMemo(
    () => ({
      ...defaultComponents,
      ...Components,
    }),
    [Components]
  );

  return (
    <NotistackSnackbarProvider
      {...props}
      maxSnack={maxSnack}
      anchorOrigin={anchorOrigin}
      Components={mergedComponents}
    >
      {children}
    </NotistackSnackbarProvider>
  );
};
