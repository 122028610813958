import InputAdornment from '@mui/material/InputAdornment';
import React from 'react';

import type { NumberFieldProps } from '../number-field/NumberField';
import { NumberField } from '../number-field/NumberField';

export type CurrencyFieldProps = Omit<NumberFieldProps, 'InputProps'> &
  Readonly<{
    InputProps?: Omit<NumberFieldProps['InputProps'], 'startAdornment'>;
    // ISO 4217 currency code (https://en.wikipedia.org/wiki/ISO_4217#Active_codes_(list_one))
    currencyCode?: string;
  }>;

export const CurrencyField: React.FC<CurrencyFieldProps> = React.forwardRef<
  HTMLDivElement,
  CurrencyFieldProps
>(({ InputProps, currencyCode = 'USD', ...props }, ref) => {
  const currencySymbol = getCurrencySymbol(currencyCode);

  const inputProps = React.useMemo(
    () => ({
      ...InputProps,
      startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
    }),
    [InputProps, currencySymbol]
  );

  return <NumberField {...props} ref={ref} InputProps={inputProps} />;
});

/**
 * Retrieve a currency symbol (e.g. $, €, £) for a given currency code (e.g. USD, EUR, GBP).
 * TODO - This function should be moved to a shared utility library along with the rest of the currency formatting logic.
 *  CurrencyField and design system would then import this util library OR CurrencyField would be move to its own sub project of design system.
 * @param currencyCode
 * @returns currency symbol
 */
const getCurrencySymbol = (currencyCode: string) => {
  try {
    const format = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      notation: 'standard',
    });

    const parts = format.formatToParts(123);
    const currencyPart = parts.find(part => part.type === 'currency');
    return currencyPart ? currencyPart.value : '';
  } catch (error) {
    console.error(`Failed to get currency symbol for currency code: ${currencyCode}`, error);
    return '';
  }
};
