import { duckPartFactory, mixpanelUtil, reduxUtil, ToastManager } from '@cmg/common';
import { AnyAction, combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import api from '../../../api/datalab-api';
import { RootState } from '../../../common/redux/rootReducer';
import { UUID } from '../../../types/common';
import { UserNote } from '../../../types/domain/offering/user-note';
import { toastMessages } from '../../shared/constants/messages';

const { createMixPanelAction } = mixpanelUtil;

/**
 * DUCK PARTS DEFINITIONS
 */
export const {
  actionCreators: { success: requestUserNoteSucceeded, failure: requestUserNoteFailed },
  initialState: userNoteInitialState,
  makeSelectors,
  reducer: userNoteReducer,
} = duckPartFactory.makeAPIDuckParts<{ offeringId: UUID }, UserNote>({
  prefix: 'offering-profile-dl/USER_NOTE',
});

/**
 * ACTIONS
 */
export enum ActionTypes {
  RESET_STATE = 'offering-profile-dl/RESET_STATE',
  FETCH_USER_NOTE = 'offering-profile-dl/FETCH_USER_NOTE',
  SAVE_USER_NOTE = 'offering-profile-dl/SAVE_USER_NOTE',
}

export const resetState = () => ({ type: ActionTypes.RESET_STATE });
export const fetchUserNoteRequest = (payload: { offeringId: UUID }) => ({
  type: ActionTypes.FETCH_USER_NOTE,
  payload,
});
export const saveUserNoteRequest = (payload: { offeringId: UUID; userNote: UserNote }) => ({
  type: ActionTypes.SAVE_USER_NOTE,
  payload,
});

type Actions = {
  [ActionTypes.FETCH_USER_NOTE]: ReturnType<typeof fetchUserNoteRequest>;
  [ActionTypes.SAVE_USER_NOTE]: ReturnType<typeof saveUserNoteRequest>;
  [ActionTypes.RESET_STATE]: ReturnType<typeof resetState>;
};

/**
 * REDUCERS
 */
export type ReducerState = {
  userNote: typeof userNoteInitialState;
};

export const initialState: ReducerState = {
  userNote: userNoteInitialState,
};

const crossSliceReducer = reduxUtil.createReducer<ReducerState, Actions>(initialState, {
  [ActionTypes.RESET_STATE]: () => initialState,
});

const combinedReducers = combineReducers<ReducerState>({
  userNote: userNoteReducer,
});

export const reducer = function duckReducer(state: ReducerState = initialState, action: AnyAction) {
  const intermediateState = combinedReducers(state, action);
  return crossSliceReducer(intermediateState, action);
};

/**
 * SELECTORS
 */
export const { selectData: selectUserNote, selectError } = makeSelectors(
  (state: RootState) => state.offeringProfile.userNote
);

/**
 * SAGAS
 */
export function* fetchUserNoteSaga({
  payload,
}: Actions[ActionTypes.FETCH_USER_NOTE]): SagaIterator {
  yield put(createMixPanelAction(ActionTypes.FETCH_USER_NOTE, 'Offering Fetch User Note'));

  const resp = yield call(api.fetchUserNote, payload.offeringId);

  if (resp.ok) {
    yield put(requestUserNoteSucceeded(resp.data));
  } else {
    yield put(requestUserNoteFailed(resp.data.error));
  }
}

export function* saveUserNoteSaga({ payload }: Actions[ActionTypes.SAVE_USER_NOTE]): SagaIterator {
  yield put(createMixPanelAction(ActionTypes.SAVE_USER_NOTE, 'Offering Save User Note'));

  const resp = yield call(api.saveUserNote, payload.offeringId, payload.userNote);

  if (resp.ok) {
    yield put(requestUserNoteSucceeded(resp.data));
    yield call(ToastManager.success, toastMessages.success.saved);
  } else {
    yield put(requestUserNoteFailed(resp.data.error));
    yield call(ToastManager.error, toastMessages.error.errorSaving);
  }
}

export function* offeringProfileDlSaga() {
  yield takeLatest<Actions[ActionTypes.FETCH_USER_NOTE]>(
    ActionTypes.FETCH_USER_NOTE,
    fetchUserNoteSaga
  );
  yield takeLatest<Actions[ActionTypes.SAVE_USER_NOTE]>(
    ActionTypes.SAVE_USER_NOTE,
    saveUserNoteSaga
  );
}
