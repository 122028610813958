import { addHours, addMinutes, format, parse } from 'date-fns';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { Meridiem, MeridiemTimeTuple } from '../TimePicker.model';
import {
  SPopoverContent,
  STime,
  STimeCell,
  StyledArrowButton,
  StyledRadioGroup,
} from './TimePickerPopoverContent.styles';

export type Props = {
  time: string | null;
  onChange?: (value: MeridiemTimeTuple) => void;
  period: Meridiem | null;
  onFocus: (event: React.FocusEvent<Element>) => void;
  onBlur: (event: React.FocusEvent<Element>) => void;
};

const TimePickerPopoverContent: React.FC<Props> = ({ time, period, onChange, onFocus, onBlur }) => {
  const theme = useTheme();

  const [hours, minutes] = time?.split(':') ?? [];

  const changeHour = (amount: number, fallbackTime: string) => {
    if (time) {
      const newTime = addHours(parse(time, 'hh:mm', new Date()), amount);
      const formattedTime = format(newTime, 'hh:mm');

      onChange?.([formattedTime, period]);
    } else {
      onChange?.([fallbackTime, period || 'AM']);
    }
  };

  const handleAddHour = () => {
    changeHour(1, '01:00');
  };

  const handleSubtractHour = () => {
    changeHour(-1, '11:00');
  };

  const changeMinute = (amount: number, fallbackTime: string) => {
    if (time) {
      const newTime = addMinutes(parse(time, 'hh:mm', new Date()), amount);
      const formattedTime = format(newTime, 'hh:mm');

      onChange?.([formattedTime, period]);
    } else {
      onChange?.([fallbackTime, period || 'AM']);
    }
  };

  const handleAddQuarterHour = () => {
    changeMinute(15, '01:15');
  };

  const handleSubtractQuarterHour = () => {
    changeMinute(-15, '11:45');
  };

  const handlePeriodChange = (nextPeriod: string | null) => {
    onChange?.([time, nextPeriod as Meridiem]);
  };

  return (
    <SPopoverContent
      tabIndex={0}
      onFocus={onFocus}
      onBlur={onBlur}
      data-test-id="time picker popover"
    >
      <STimeCell>
        <StyledArrowButton
          testId="add-hour"
          fullWidth
          onClick={handleAddHour}
          icon={{
            name: 'chevron-up',
            variant: 'solid',
            color: theme.foundation.colors.primary[300],
          }}
        />
        <STime isEmpty={!minutes}>{hours || 'HH'}</STime>
        <StyledArrowButton
          testId="subtract-hour"
          fullWidth
          onClick={handleSubtractHour}
          icon={{
            name: 'chevron-down',
            variant: 'solid',
            color: theme.foundation.colors.primary[300],
          }}
        />
      </STimeCell>

      <div>:</div>

      <STimeCell>
        <StyledArrowButton
          testId="add-minutes"
          fullWidth
          onClick={handleAddQuarterHour}
          icon={{
            name: 'chevron-up',
            variant: 'solid',
            color: theme.foundation.colors.primary[300],
          }}
        />
        <STime isEmpty={!minutes}>{minutes ?? 'MM'}</STime>
        <StyledArrowButton
          testId="subtract-minutes"
          fullWidth
          onClick={handleSubtractQuarterHour}
          icon={{
            name: 'chevron-down',
            variant: 'solid',
            color: theme.foundation.colors.primary[300],
          }}
        />
      </STimeCell>
      <StyledRadioGroup
        value={period}
        variant="vertical"
        options={[
          { value: 'AM', label: 'AM' },
          { value: 'PM', label: 'PM' },
        ]}
        onChange={handlePeriodChange}
      />
    </SPopoverContent>
  );
};

export default TimePickerPopoverContent;
