import { RegularBreakpoints } from '@cmg/design-system';
import React from 'react';

import { isInternationalOfferingsOn } from '../../../../../datalab/model/utils';
import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import OfferingProfileCardItem from '../../shared/OfferingProfileCardItem';
import { useGetStructureData } from '../AtmProfile.model';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
};

const StructurePricingWidget: React.FC<Props> = ({ offeringProfile, cardSizes }) => {
  const showInternational = isInternationalOfferingsOn();
  const structure = useGetStructureData(offeringProfile, showInternational);

  return (
    <OfferingProfileCardItem title="Structure/Pricing" cardSizes={cardSizes} rowData={structure} />
  );
};

export default StructurePricingWidget;
