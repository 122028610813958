import { connect } from 'react-redux';

import { CalendarCategory } from '../../../types/domain/calendar/constants';
import { selectOfferings } from '../ducks';
import {
  Column,
  conditionalLockUpColumn,
  countryColumn,
  customSectorColumn,
  issuerFirmNameColumn,
  leftLeadColumn,
  lockUpExpirationDateColumn,
  marketCapAtPricingColumn,
  marketCapAtPricingLocalCurrColumn,
  multipleLockUpsColumn,
  offeringTypeColumn,
  offerPriceColumn,
  offerPriceLocalCurrColumn,
  offerToCurrentColumn,
  pricingDateColumn,
  regionColumn,
  secondarySharesColumn,
  sectorColumn,
  shareholderColumn,
  sizeDollarsColumn,
  sizeLocalCurrColumn,
  tickerColumn,
  totalBookRunnersColumn,
  totalNonBookRunnersColumn,
} from '../model/offeringListColumns';
import BaseDatalabCalendar, { mapDispatchToProps } from './BaseDatalabCalendar';

const calendarCategory = CalendarCategory.LOCK_UP_EXPIRATION;

export const columnsConfig: Column[] = [
  issuerFirmNameColumn,
  tickerColumn,
  regionColumn,
  countryColumn,
  sectorColumn,
  customSectorColumn,
  lockUpExpirationDateColumn,
  conditionalLockUpColumn,
  multipleLockUpsColumn,
  offeringTypeColumn,
  pricingDateColumn,
  offerPriceLocalCurrColumn,
  offerPriceColumn,
  sizeLocalCurrColumn,
  sizeDollarsColumn,
  marketCapAtPricingLocalCurrColumn,
  marketCapAtPricingColumn,
  offerToCurrentColumn,
  leftLeadColumn,
  shareholderColumn,
  secondarySharesColumn,
  totalBookRunnersColumn,
  totalNonBookRunnersColumn,
];

export const staticColumns: Column[] = [issuerFirmNameColumn, tickerColumn];

export const defaultVisibleColumns = columnsConfig
  .filter(
    c =>
      ![
        secondarySharesColumn,
        totalBookRunnersColumn,
        totalNonBookRunnersColumn,
        conditionalLockUpColumn,
        multipleLockUpsColumn,
      ].includes(c)
  )
  .map(c => c.field);

const mapStateToProps = state => ({
  offerings: selectOfferings(state, calendarCategory),
});

export class LockupExpirationsOfferingsCalendarComponent extends BaseDatalabCalendar {
  constructor(props) {
    super(props);

    this.calendarCategory = calendarCategory;
    this.defaultGroupBy = 'lockUpExpirationDate';
    this.defaultGroupByOrderType = 'asc';
    this.defaultOrderBy = 'issuerFirmName';
    this.defaultOrderByType = 'asc';
    this.columnsConfig = columnsConfig;
    this.staticColumns = staticColumns;
    this.state = {
      visibleColumns: defaultVisibleColumns,
    };
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LockupExpirationsOfferingsCalendarComponent);
