import { datalabApi } from '@cmg/api';
import { numericUtil, Popover, Tag } from '@cmg/common';
import React from 'react';
import styled, { ThemeConsumer } from 'styled-components/macro';

import { getFeatureToggles } from '../../../config/appSettings';
import { OfferingStatus } from '../../../types/domain/offering/constants';

const SSizeSharesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const STagWrapper = styled.div`
  margin-top: -3px;
  margin-right: 5px;
`;

export type Props = {
  offering: datalabApi.CalendarOffering;
};

const SizeSharesRenderer: React.FC<Props> = ({ offering }) => {
  const hideRevisedTag =
    getFeatureToggles().isRestrictFiledUSOfferingsInDLOn &&
    offering.countryCode === 'US' &&
    offering.status === OfferingStatus.FILED;

  const isOfferingSizeZero = offering.sizeInShares === 0;

  return (
    <SSizeSharesWrapper>
      {(offering.isIpoSizeSharesRevised || offering.isFoSizeSharesRevised) &&
        !hideRevisedTag &&
        !isOfferingSizeZero && (
          <Popover
            variant="TOOLTIP"
            placement="top"
            content={
              <span>Initial Size: {numericUtil.formatInteger(offering.initialSizeShares)}</span>
            }
          >
            <STagWrapper>
              <ThemeConsumer>{theme => <Tag color={theme.color.blue2}>R</Tag>}</ThemeConsumer>
            </STagWrapper>
          </Popover>
        )}
      <span>{!isOfferingSizeZero ? numericUtil.formatInteger(offering.sizeInShares) : ''}</span>
    </SSizeSharesWrapper>
  );
};

export default SizeSharesRenderer;
