import type { CustomContentProps, SharedProps } from 'notistack';
import { SnackbarContent } from 'notistack';
import React from 'react';

import { BaseAlert } from './BaseAlert';

export const InfoSnack = React.forwardRef<HTMLDivElement, CustomContentProps & SharedProps>(
  (props, ref) => {
    const { id, message, onClose } = props;

    return (
      <SnackbarContent ref={ref}>
        <BaseAlert id={id} severity="info" onClose={onClose}>
          {message}
        </BaseAlert>
      </SnackbarContent>
    );
  }
);
