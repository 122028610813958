import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators } from 'redux';

import { getFeatureToggles } from '../../../../../config/appSettings';
import { precedentTransactionRequest } from '../../../../shared/ducks';
import {
  fetchDatalabOptionsActions,
  fetchOfferingActions,
  selectAdvisoryOptions,
  selectError as selectOfferingError,
  selectFundOptions,
  selectOffering,
  setFollowOfferingRequest,
} from '../../../shared/ducks';
import OfferingProfileScreen from '../../components/OfferingProfileScreen';
import {
  fetchUserNoteRequest,
  resetState,
  saveUserNoteRequest,
  selectError,
  selectUserNote,
} from '../../ducks';
import DSOfferingProfileScreen from './OfferingProfileScreen';

const mapStateToProps = state => ({
  profileError: selectError(state),
  offeringError: selectOfferingError(state),
  offering: selectOffering(state),
  userNote: selectUserNote(state),
  advisoryOptions: selectAdvisoryOptions(state),
  fundOptions: selectFundOptions(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchOffering: fetchOfferingActions.request,
      fetchUserNote: fetchUserNoteRequest,
      saveUserNote: saveUserNoteRequest,
      setFollowOffering: setFollowOfferingRequest,
      fetchDatalabOptions: fetchDatalabOptionsActions.request,
      onPrecedentsFilter: precedentTransactionRequest,
      resetState,
    },
    dispatch
  ),
});

type RouterProps = RouteComponentProps<{ offeringId: string }>;

type OwnProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & RouterProps;

export class OfferingProfileContainerComponent extends React.Component<Props> {
  componentDidMount() {
    const { actions, match } = this.props;
    const { offeringId } = match.params;

    actions.fetchOffering({ offeringId });
    actions.fetchUserNote({ offeringId });
    actions.fetchDatalabOptions();
  }

  componentWillUnmount() {
    this.props.actions.resetState();
  }

  onSaveUserNote = userNote => {
    this.props.actions.saveUserNote({
      offeringId: this.props.match.params.offeringId,
      userNote,
    });
  };

  onToggleFollowOffering = isFollowing => {
    this.props.actions.setFollowOffering({
      offeringId: this.props.match.params.offeringId,
      isFollowing,
    });
  };

  handleOnPrecedentsFilter = ({
    offeringType,
    marketCapMin,
    marketCapMax,
    sectors,
    customSectors,
  }) => {
    const { actions, advisoryOptions, fundOptions } = this.props;

    actions.onPrecedentsFilter({
      offeringType,
      marketCapMin,
      marketCapMax,
      sectors,
      customSectors,
      advisoryOptions,
      fundOptions,
    });
  };

  render() {
    const { offering, userNote, profileError, offeringError } = this.props;
    const { isConvertsInDLOn, isATMInDLOn, isDSOfferingProfileTemplateOn } = getFeatureToggles();

    const error = offeringError || profileError;

    if (!offering && !error) {
      return null;
    }

    if (
      offering &&
      ((offering.type === 'CONVERTIBLE' && isConvertsInDLOn) ||
        (offering.type === 'ATM' && isATMInDLOn) ||
        isDSOfferingProfileTemplateOn)
    ) {
      return (
        <DSOfferingProfileScreen
          offeringId={offering.id}
          offeringType={offering.type}
          userNote={userNote}
          onSaveUserNote={this.onSaveUserNote}
          isFollowing={offering.userIsFollowing}
          onToggleFollowOffering={() => this.onToggleFollowOffering(!offering.userIsFollowing)}
        />
      );
    }
    return (
      <OfferingProfileScreen
        error={error}
        offering={offering}
        userNote={userNote}
        onSaveUserNote={this.onSaveUserNote}
        onPrecedentsFilter={this.handleOnPrecedentsFilter}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferingProfileContainerComponent);
