import { createTestId } from '../../common/selectors';

export const datalabScreenLoadingIndicator = createTestId('datalab-screen-loading-indicator');

export const ruleSelector = createTestId('datalab-rule-selector');
export const addFilterSelector = createTestId('datalab-add-new-rule-selector');
export const removeFilterSelector = createTestId('datalab-remove-rule-selector');

export const capitalRaisedMatrixSelector = createTestId('datalab-u-s-ecm-matrix');
export const capitalRaisedMatrixYearWidgetSelector = year =>
  createTestId(`datalab-u-s-ecm-matrix-year-${year}-widget`);

export const screenSwitch = createTestId('datalab-table-or-charts-toggler');
export const filterToolbar = createTestId('datalab-filter-toolbar');
export const widgetTotalOfferings = createTestId('datalab-widget-offering-total');
export const widgetTotalCapitalRaised = createTestId('datalab-widget-capital-raised-total');
export const widgetOfferingVolumeBySector = createTestId(
  'datalab-widget-offering-volume-by-sector'
);
export const widgetDailyVolume = createTestId('datalab-widget-daily-volume-of-a-deal');
export const widgetPerformanceHeatMapBySector = createTestId(
  'datalab-widget-performance-heatmap-by-sector'
);
export const widgetPerformanceHeatMapByOfferingType = createTestId(
  'datalab-widget-performance-heatmap-by-offering-type'
);

export const datalabFiltersSelector = createTestId(`datalab-filters`);
export const datalabYearFromFilterSelector = createTestId(`datalab-year-from-filter`);
export const datalabYearToFilterSelector = createTestId(`datalab-year-to-filter`);
export const datalabRegionCountryFilterSelector = createTestId(`datalab-region-country-filter`);
export const datalabSizeInDollarsSelector = createTestId(`datalab-size-in-dollars-filter`);
export const datalabMarketCapSelector = createTestId(`datalab-market-cap-filter`);
export const datalabTableSelector = section => createTestId(`datalab-table-${section}`);

export const showFiltersButtonSelector = createTestId(`datalab-toggle-filters`);
export const showFiltersCounterSelector = createTestId(`datalab-toggle-filters-counter`);
export const followOnPricingSelector = createTestId('datalab-follow-on-pricing');
export const followOnStructureSelector = createTestId('datalab-follow-no-structure');
export const participationSummarySelector = createTestId('datalab-participation-summary');
export const ipoPricingScreenSelector = createTestId('datalab-ipo-pricing');
export const ipoStructureScreenSelector = createTestId('datalab-ipo-structure');

export const sharePopoverButtonSelector = createTestId('datalab-all-reports-share-popover-button');
export const sharePopoverFormCancelButtonSelector = createTestId(
  'datalab-all-reports-share-popover-cancel-button'
);
export const sharePopoverFormConfirmButtonSelector = createTestId(
  'datalab-all-reports-share-popover-confirm-button'
);
export const favoriteButtonSelector = createTestId('datalab-all-reports-favorite-button');
export const deletePopoverButtonSelector = createTestId(
  'datalab-all-reports-delete-popover-button'
);
export const deletePopoverFormCancelButtonSelector = createTestId(
  'datalab-all-reports-delete-popover-cancel-button'
);
export const deletePopoverFormConfirmButtonSelector = createTestId(
  'datalab-all-reports-delete-popover-confirm-button'
);

export const usEcmSelector = createTestId('datalab-u-s-ecm');
export const underwriterProfileSellSideSelector = createTestId(
  'datalab-underwriter-profile-sell-side'
);
export const underwritingTermsScreenSelector = createTestId('datalab-underwriting-terms');
export const suggestedUserButtonSelector = createTestId(
  'datalab-user-select-suggested-user-button'
);

export const advancedFilterUnderwriterSelect = createTestId(
  'datalab-advanced-filter-underwriter-select'
);
export const customFilterUnderwriterSelect = createTestId(
  'datalab-custom-filter-underwriter-select'
);

export const advancedFilterRoleSelect = createTestId('datalab-advanced-filter-role-select');
export const customFilterRoleSelect = createTestId('datalab-custom-filter-role-select');

export const grossSpreadWidgetSelector = createTestId('datalab-gross-spread-widget');

export const allReportsModalSelector = createTestId('datalab-all-reports-modal');
export const saveReportToolbarButtonSelector = createTestId('datalab-save-report-toolbar-button');
export const saveReportModalSelector = createTestId('datalab-save-report-modal');
export const seeAllReportsButtonSelector = createTestId('datalab-see-all-reports-button');
export const reportReportRowSelector = createTestId('datalab-report-row');
export const clearAllReportToolbarButtonSelector = createTestId(
  'datalab-clearAll-report-toolbar-button'
);
