import { genericServerErrorDefault } from '../test/testUtil';

/**
 * Creates argType value for generic error, that can be used in stories.
 *
 * Usage:
 * argTypes: {
 *    errorProperty: createDefaultGenericErrorArgTypeValue()
 * }
 *
 * Property called 'errorProperty' in sample above will be rendered in addon controls table as radio group with two options:
 * - Empty -> for no error
 * - Generic Error -> for default 'mock' error
 *
 * @returns Default generic error arg type value
 */
export function createDefaultGenericErrorArgTypeValue() {
  return {
    options: ['Empty', 'GenericError'],
    mapping: {
      Empty: null,
      GenericError: genericServerErrorDefault,
    },
    control: {
      type: 'radio',
      labels: {
        GenericError: 'Generic Error',
      },
    },
  };
}
