import { RegularBreakpoints } from '@cmg/design-system';

import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import OfferingProfileCardItem from '../../shared/OfferingProfileCardItem';
import { useGetTimingData } from '../AtmProfile.model';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
};

const TimingWidget: React.FC<Props> = ({ offeringProfile, cardSizes }) => {
  const timing = useGetTimingData(offeringProfile);

  return (
    <OfferingProfileCardItem
      title="Timing"
      dataDescription="All dates are presented in exchange country's local time zone."
      cardSizes={cardSizes}
      rowData={timing}
    />
  );
};

export default TimingWidget;
