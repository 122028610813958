import { createTestId } from '../../../../common/selectors';

const create = (name: string) =>
  createTestId(`xc-offering-side-bar-order-book-indication-activity-${name}`);

export const offeringSideBarIndicationActivityCreateIndicationScreen = create(
  'create-indication-screen'
);

export const offeringSideBarIndicationActivityManageIndicationScreen = create(
  'manage-indication-screen'
);

export const offeringSideBarIndicationActivityDeliveryInstructionsScreen = create(
  'delivery-instructions-screen'
);

export const offeringSideBarIndicationActivityEditButton = create('edit-button');

export const offeringSideBarIndicationActivityDiscardChangesButton =
  create('discard-changes-button');

export const offeringSideBarIndicationActivitySaveButton = create('save-button');

export const offeringSideBarIndicationActivityTrancheSelect = create('tranche-select');

export const offeringSideBarIndicationActivityDemandInstrumentSelect = create(
  'demand-instrument-select'
);

export const offeringSideBarIndicationActivityInterestLevelOrderTypeSelect = create(
  'interest-level-order-type-select'
);

export const offeringSideBarIndicationActivityInterestLevelLimitTypeSelect = create(
  'interest-level-limit-type-select'
);

export const offeringSideBarIndicationActivityInterestLevelLimitPriceInput = create(
  'interest-level-limit-price-input'
);

export const offeringSideBarIndicationActivityInterestLevelLimitPercentInput = create(
  'interest-level-limit-percent-input'
);

export const offeringSideBarIndicationActivityInterestLevelInterestUnitSelect = create(
  'interest-level-interest-unit-select'
);

export const offeringSideBarIndicationActivityInterestLevelInterestQuantityInput = create(
  'interest-level-interest-quantity-input'
);

export const offeringSideBarIndicationActivityDemandCurrencySelect =
  create('demand-currency-select');

export const offeringSideBarIndicationActivityAddInterestLevelButton = create(
  'add-interest-level-button'
);

export const offeringSideBarIndicationActivityRemoveInterestLevelButton = create(
  'remove-interest-level-button'
);

export const offeringSideBarIndicationActivityStatusUpdateCancelButton = create(
  'status-update-cancel-button'
);

export const offeringSideBarIndicationActivityStatusUpdateReActivateButton = create(
  'status-update-reactivate-button'
);

export const offeringSideBarIndicationActivityStatusUpdateConfirmNoButton = create(
  'status-update-confirm-no-button'
);

export const offeringSideBarIndicationActivityStatusUpdateConfirmYesButton = create(
  'status-update-confirm-yes-button'
);

export const offeringSideBarIndicationActivityAcknowledgeAllocationButton = create(
  'acknowledge-allocation-button'
);

export const offeringSideBarIndicationActivityDeclineAllocationButton = create(
  'decline-allocation-button'
);

export const offeringSideBarIndicationActivityConfirmAllocationAcknowledgementButton = create(
  'confirm-allocation-acknowledgement-button'
);

export const offeringSideBarIndicationActivityCancelAllocationAcknowledgementButton = create(
  'cancel-allocation-acknowledgement-button'
);

export const offeringSideBarIndicationActivityPassButton = create('pass-button');

export const offeringSideBarIndicationActivityPassHeaderStatus = create('pass-header-status');

export const offeringSideBarIndicationActivityYesPromptButton = create('yes-prompt-button');

export const offeringSideBarIndicationActivityNoPromptButton = create('no-prompt-button');

export const offeringSideBarIndicationActivityInterestLevelRow = create('interest-level-row');

export const offeringSideBarIndicationActivityInterestLevelOrderTypeColumn = create(
  'interest-level-order-type-column'
);

export const offeringSideBarIndicationActivityInterestLeveLimitColumn = create(
  'interest-level-limit-column'
);

export const offeringSideBarIndicationActivityInterestLevelUnitColumn =
  create('interest-unit-column');

export const offeringSideBarIndicationActivityInterestLevelQuantityColumn = create(
  'interest-level-quantity-column'
);

export const offeringSideBarIndicationActivityStatus = create('status');

export const offeringSideBarIndicationActivityFinalAllocationStatus =
  create('final-allocation-status');
