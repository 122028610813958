import Box from '@mui/material/Box';
import type { ButtonProps } from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import type { ReactElement } from 'react';
import React from 'react';

import type { DotStatusProps } from '../../data-display/status';
import type { TestProps } from '../../types';

export type PageHeaderProps = Readonly<
  TestProps & {
    /**
     * Title of page header
     */
    readonly pageTitle: React.ReactNode;
    /**
     * Status of the page
     */
    readonly status?: ReactElement<DotStatusProps>;
    /**
     * Available actions
     */
    readonly actions?: ReactElement<ButtonProps>[];
  }
>;

export const PageHeader: React.FC<PageHeaderProps> = ({
  pageTitle,
  status,
  actions,
  children,
  ...restProps
}) => {
  const theme = useTheme();
  return (
    <Box {...restProps}>
      <Stack
        direction="row"
        height={theme.spacing(6)}
        px={2}
        py={1}
        columnGap={3}
        rowGap={1}
        flexWrap="wrap"
      >
        <Stack direction="row" flexGrow={0} gap={3} alignItems="center">
          <Typography variant="h2">{pageTitle}</Typography>
          {status}
        </Stack>

        <Stack flexGrow={1} direction="row" alignItems="center">
          {children}
        </Stack>

        {actions && (
          <Stack direction="row" flexGrow={0} gap={1}>
            {actions}
          </Stack>
        )}
      </Stack>
    </Box>
  );
};
