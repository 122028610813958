import React from 'react';

import { SBreadcrumb, StyledBreadcrumbIcon } from './Breadcrumb.styles';

/**
 * Breadcrumb component used as a building block of SubNavigation
 * */
const Breadcrumb: React.FC = ({ children, ...rest }) => (
  <SBreadcrumb {...rest}>
    <StyledBreadcrumbIcon name="chevron-right" />
    {children}
  </SBreadcrumb>
);

export default Breadcrumb;
