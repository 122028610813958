import React from 'react';
import styled from 'styled-components/macro';

import type { Maybe } from '../../../../../types/modifiers';
import type { CellRendererFieldValidation } from '../BaseAgGrid.model';

type Props = {
  readonly validation?: Maybe<CellRendererFieldValidation>;
};

const SPath = styled.path<Props>`
  fill: ${({ theme, validation }) => {
    if (validation === 'error') {
      return theme.designSystem.colors.red['300'];
    }
    if (validation === 'warning') {
      return theme.designSystem.colors.yellow['300'];
    }
    return 'unset';
  }};
`;

export const WarningIcon: React.FC<Props> = props => {
  const { validation } = props;

  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <SPath
        validation={validation}
        d="M15.8199 11.9953C16.3326 12.8588 15.6891 13.9382 14.665 13.9382H1.33484C0.308813 13.9382 -0.331798 12.8571 0.179925 11.9953L6.84509 0.767317C7.35806 -0.0965615 8.64287 -0.0949962 9.15492 0.767317L15.8199 11.9953V11.9953ZM8.00001 9.67397C7.29431 9.67397 6.72223 10.2298 6.72223 10.9154C6.72223 11.6011 7.29431 12.1569 8.00001 12.1569C8.7057 12.1569 9.27778 11.6011 9.27778 10.9154C9.27778 10.2298 8.7057 9.67397 8.00001 9.67397ZM6.78687 5.21151L6.99292 8.88196C7.00256 9.05372 7.14873 9.18817 7.32575 9.18817H8.67426C8.85128 9.18817 8.99745 9.05372 9.00709 8.88196L9.21314 5.21151C9.22356 5.02599 9.07153 4.86999 8.88031 4.86999H7.11967C6.92845 4.86999 6.77645 5.02599 6.78687 5.21151V5.21151Z"
      />
    </svg>
  );
};

export default WarningIcon;
