import type { IconButtonProps } from '@mui/material/IconButton';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

export type AppBarIconButtonProps = Omit<IconButtonProps, 'color' | 'aria-label'> & {
  /**
   * Tooltip text shown when the icon is hovered
   */
  readonly tooltipTitle: string;
  /**
   * Icon to be shown inside of the button
   */
  readonly children: React.ReactNode;
};

export const AppBarIconButton = React.forwardRef<HTMLButtonElement, AppBarIconButtonProps>(
  ({ tooltipTitle, ...iconButtonProps }, ref) => {
    return (
      <Tooltip title={tooltipTitle}>
        <span>
          <IconButton
            {...iconButtonProps}
            aria-label={tooltipTitle}
            color="secondaryDarkBackground"
            ref={ref}
          />
        </span>
      </Tooltip>
    );
  }
);
