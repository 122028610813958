import { datalabApi } from '@cmg/api';

import { FirmDataSource, FirmSearchOption } from './types';

/**
 * By default, the FirmSearchMultiSelect uses this dataSource which calls out to dlgw api
 * as its means to fetch options.
 */
const dlgwDataSource: FirmDataSource = {
  searchByText: async (searchText: string, firmType?: datalabApi.FirmType) => {
    const response = await datalabApi.fetchFirms(
      {},
      {
        searchText,
        firmType,
      }
    );
    if (response.ok) {
      const firms = response.data.data;
      const firmOptions: FirmSearchOption[] = firms.map((firm: datalabApi.Firm) => ({
        label: firm.legalName,
        value: firm.id,
        firm,
      }));
      return firmOptions;
    } else {
      return [];
    }
  },
  searchByFirmIds: async (ids: string[]) => {
    const response = await datalabApi.fetchFirmsByIds(ids, {});
    if (response.ok) {
      const firms = response.data;
      const firmOptions: FirmSearchOption[] = firms.map((firm: datalabApi.Firm) => ({
        label: firm.legalName,
        value: firm.id,
        firm,
      }));
      return firmOptions;
    } else {
      return [];
    }
  },
};

export default dlgwDataSource;
