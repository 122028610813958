import MUIAppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { Box } from '@mui/system';
import type { FC } from 'react';
import React from 'react';

export type AppBarProps = Readonly<{
  /**
   * Section of the app bar that contains domain logo
   */
  readonly logoSection: React.ReactNode;
  /**
   * Section of the app bar that contains navigation links
   * Links are shown only on medium sized screens or bigger
   */
  readonly navigationSectionLinks?: React.ReactNode;
  /**
   * Section of the app bar that contains navigation menu
   * Menu is shown only on small sized screens or smaller
   */
  readonly navigationSectionMenu?: React.ReactNode;
  /**
   * Primary section (may contain quick access links, promo links, search, etc.)
   */
  readonly primarySection?: React.ReactNode;
  /**
   * Section of the app bar that contanis secondary items (profile dropdown, help, notifications, etc.)
   * Secondary section is shown only on medium sized screens or bigger
   */
  readonly secondarySection?: React.ReactNode;
  /**
   * Section of the app bar that contanis secondary items (profile dropdown, help, notifications, etc.)
   * Secondary section mobile is shown only on small sized screens or smaller
   */
  readonly secondarySectionMobile?: React.ReactNode;
}>;

export const AppBar: FC<AppBarProps> = ({
  logoSection,
  navigationSectionLinks,
  navigationSectionMenu,
  primarySection,
  secondarySection,
  secondarySectionMobile,
}) => {
  return (
    <MUIAppBar position="sticky">
      <Toolbar
        style={{ minHeight: '48px' }}
        sx={{
          columnGap: 3,
          display: { xs: 'none', md: 'flex' },
        }}
      >
        <Box display="flex" alignItems="center">
          {logoSection}
        </Box>
        {navigationSectionLinks && (
          <Stack direction="row" columnGap={1}>
            {navigationSectionLinks}
          </Stack>
        )}
        {primarySection && <Box>{primarySection}</Box>}
        <Stack direction="row" columnGap={2} flexGrow={1} justifyContent="end">
          {secondarySection}
        </Stack>
      </Toolbar>
      <Toolbar
        style={{ minHeight: '48px' }}
        sx={{ columnGap: 3, display: { xs: 'flex', md: 'none' } }}
      >
        {navigationSectionMenu && <Box>{navigationSectionMenu}</Box>}
        <Box>{logoSection}</Box>
        {primarySection && <Box>{primarySection}</Box>}
        <Stack direction="row" columnGap={2} flexGrow={1} justifyContent="end">
          {secondarySectionMobile ?? secondarySection}
        </Stack>
      </Toolbar>
    </MUIAppBar>
  );
};
