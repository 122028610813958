import { connect } from 'react-redux';

import { CalendarCategory } from '../../../types/domain/calendar/constants';
import { selectOfferings } from '../ducks';
import {
  Column,
  countryColumn,
  customSectorColumn,
  issuerFirmNameColumn,
  leftLeadColumn,
  offeringTypeColumn,
  priceRangeLowColumn,
  publicFilingDateColumn,
  regionColumn,
  secondarySharesColumn,
  sectorColumn,
  sizeLocalCurrColumn,
  sizeSharesColumn,
  tickerColumn,
  totalBookRunnersColumn,
  totalNonBookRunnersColumn,
} from '../model/offeringListColumns';
import BaseDatalabCalendar, { mapDispatchToProps } from './BaseDatalabCalendar';

const calendarCategory = CalendarCategory.FILED;

export const columnsConfig: Column[] = [
  issuerFirmNameColumn,
  tickerColumn,
  regionColumn,
  countryColumn,
  sectorColumn,
  customSectorColumn,
  offeringTypeColumn,
  publicFilingDateColumn,
  sizeLocalCurrColumn,
  sizeSharesColumn,
  priceRangeLowColumn,
  leftLeadColumn,
  secondarySharesColumn,
  totalBookRunnersColumn,
  totalNonBookRunnersColumn,
];

export const staticColumns: Column[] = [issuerFirmNameColumn, tickerColumn];

const mapStateToProps = state => ({
  offerings: selectOfferings(state, calendarCategory),
});

export const defaultVisibleColumns = columnsConfig
  .filter(
    c => ![secondarySharesColumn, totalBookRunnersColumn, totalNonBookRunnersColumn].includes(c)
  )
  .map(c => c.field);

export class FiledOfferingsCalendarComponent extends BaseDatalabCalendar {
  constructor(props) {
    super(props);

    this.calendarCategory = calendarCategory;
    this.defaultGroupBy = 'publicFilingDate';
    this.defaultGroupByOrderType = 'descWithNullFirst';
    this.defaultOrderBy = 'issuerFirmName';
    this.defaultOrderByType = 'asc';
    this.columnsConfig = columnsConfig;
    this.staticColumns = staticColumns;
    this.state = {
      visibleColumns: defaultVisibleColumns,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FiledOfferingsCalendarComponent);
