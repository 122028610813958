import { createTestId } from '../../common/selectors';

export const loginFormSelector = createTestId('login-form');
export const loginUsernameSelector = createTestId('login-username');
export const loginPasswordSelector = createTestId('login-password');
export const loginSubmitSelector = createTestId('login-submit');
export const loginScreenSelector = createTestId('login-screen');
export const passwordExpiredScreenSelector = createTestId('password-expired-screen');
export const passwordExpiredFormSelector = createTestId('password-expired-form');
export const passwordExpiredPasswordSelector = createTestId('password-expired-password');
export const passwordExpiredConfirmPasswordSelector = createTestId(
  'password-expired-confirm-password'
);
export const passwordExpiredSubmitSelector = createTestId('password-expired-submit');
