import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import type { ButtonProps } from '@mui/material/Button';
import Button from '@mui/material/Button';
import * as React from 'react';

export type DropdownButtonProps = Omit<
  ButtonProps,
  'aria-haspopup' | 'aria-expanded' | 'endIcon'
> & {
  isExpanded: Readonly<boolean>;
  expandedIconTitle?: Readonly<string>;
  collapsedIconTitle?: Readonly<string>;
};

export const DropdownButton: React.FC<DropdownButtonProps> = React.forwardRef<
  HTMLButtonElement,
  DropdownButtonProps
>(({ isExpanded, expandedIconTitle, collapsedIconTitle, children, ...restProps }, ref) => {
  return (
    <Button
      {...restProps}
      ref={ref}
      aria-haspopup={true}
      aria-expanded={isExpanded}
      endIcon={
        isExpanded ? (
          <ExpandLess titleAccess={expandedIconTitle} />
        ) : (
          <ExpandMore titleAccess={collapsedIconTitle} />
        )
      }
    >
      {children}
    </Button>
  );
});
