import {
  CellFocusedEvent,
  CellPosition,
  CellValueChangedEvent,
  ColDef,
  Column,
  ColumnApi,
  ColumnResizedEvent,
  ColumnState,
  ColumnVisibleEvent,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IDatasource,
  IsRowSelectable,
  ModelUpdatedEvent,
  SortChangedEvent,
  TabToNextCellParams,
  ViewportChangedEvent,
} from 'ag-grid-community';
import isEqual from 'lodash/isEqual';
import React from 'react';

import Icon from '../../graphics/icon/Icon';
import Box, { Tab } from '../../layout/box/Box';
import BoxButton from '../../layout/box/BoxButton';
import Collapse from '../../layout/collapse/Collapse';
import Pagination from '../pagination/Pagination';
import AgGridWrapper from './aggrid/AgGridWrapper';
import { SAgGridWrapper, SPanel, SText, StyledBox, StyledBoxFooter } from './DataGrid.styles';
import ColumnResizeDropdown from './dropdowns/ColumnResizeDropdown';
import ColumnSelectorDropdown, { CategoriesConfig } from './dropdowns/ColumnSelectorDropdown';
import DownloadDropdown from './dropdowns/DownloadDropdown';
import PaginationDropdown from './dropdowns/PaginationDropdown';

const defaultProps = {
  pagination: {
    page: 1,
    perPage: 25,
  },
};

// this type is also in ecm-app and we should keep those synchronized
export type PaginationQueryParams = {
  page: number;
  perPage: number;
  orderField?: string | null;
  orderDirection?: 'asc' | 'desc';
};

// https://www.ag-grid.com/react-data-grid/component-cell-renderer/#reference-CustomCellRendererProps
// This type is mostly optional to facilitate testing
export type CustomCellRendererProps<TData = any, TValue = any, TContext = any> = {
  value: TValue | null | undefined;
  valueFormatted?: string | null;
  fullWidth?: boolean;
  pinned?: 'left' | 'right' | null;
  data?: TData;
  node?: any; // should be IRowNode
  colDef?: ColDef;
  column?: Column;
  eGridCell?: HTMLElement;
  eParentOfValue?: HTMLElement;
  getValue?: () => TValue | null | undefined;
  setValue?: (value: TValue | null | undefined) => void;
  formatValue?: (value: TValue | null | undefined) => string;
  refreshCell?: () => void;
  registerRowDragger?: (
    rowDraggerElement: HTMLElement,
    dragStartPixels?: number,
    value?: TValue,
    suppressVisibilityChange?: boolean
  ) => void;
  api?: GridApi;
  context?: TContext;
};

export type Props<TRow> = {
  columns: ColDef[];
  categorizedColumns?: CategoriesConfig;
  rows?: TRow[];
  skipSetTimeOutLoading?: boolean;
  pagination: PaginationQueryParams | typeof defaultProps.pagination;
  className?: string;
  getRowsPinnedToTop?: (rows: TRow[]) => any[];
  getRowsPinnedToBottom?: (rows: TRow[]) => any[] | undefined;
  renderFilters?: () => React.ReactNode;
  renderPanel?: (options: { selectedRows: any[] }) => React.ReactNode;
  resizeBy?: 'text' | 'grid';
  frameworkComponents?:
    | {
        [p: string]: {
          new (): any;
        };
      }
    | any;
  /**
   * resizeStrategy - Grid consumers will be able to decide wether they want a resize to fit screen or a resize to fit cell content strategy. Default is resize to fit screen
   */
  resizeStrategy?: 'fit-screen' | 'fit-content';
  domLayout?: 'autoHeight' | 'normal';
  overlayNoRowsTemplate?: string;
  useCollapseForFilters?: boolean;
  totalPages?: number;
  tabToNextCell?: (params: TabToNextCellParams) => CellPosition;
  onPaginationChange?: (params: PaginationQueryParams) => void;
  onColumnVisible?: (event: ColumnVisibleEvent) => void;
  onColumnResized?: (event: ColumnResizedEvent) => void;
  onCellFocused?(event: CellFocusedEvent): void;
  onFirstDataRendered?: (event: FirstDataRenderedEvent) => void;
  onViewportChanged?: (event: ViewportChangedEvent) => void;
  onModelUpdated?: (event: ModelUpdatedEvent) => void;
  onSortChanged?: (event: SortChangedEvent) => void;
  getRowNodeId?: (data: TRow) => string | number;
  onGridReady?: (grid: GridReadyEvent) => void;
  onCellValueChange?: (e: CellValueChangedEvent) => void;
  onSelectionChange?: (selectedRows: any[], activeRow?: any) => void;
  onColumnModelChange?: (columnsModel: ColumnState[]) => void;
  gridOptions?: GridOptions;
  loading?: boolean;
  // if extended is not undefined => renders box with handlers around the DataGrid table
  extended?: {
    onChangeTab?: (tab: Tab) => void;
    onDownload?: (selectedOptions: string[]) => void;
    downloadOptions?: {
      value: string;
      label: string;
    }[];
    downloadTitle?: string;
    tabs?: Tab[];
    activeTab?: Tab;
    title?: React.ReactNode;
    fillViewport?: boolean;
    withMargin?: boolean;
    hideColumnResize?: boolean;
    hideColumnSelector?: boolean;
    hidePagination?: boolean;
    hideHeader?: boolean;
    debounceVerticalScrollbar?: boolean;
  };
  infiniteScroll?: boolean;
  datasource?: IDatasource;
  gridHeight?: string;
  readonly context?: unknown;
  readonly rowSelection?: 'single' | 'multiple';
  isRowSelectable?: IsRowSelectable;
  numberOfRowsFetched?: number | null;
  totalRows?: number | null;
  testId?: string;
};

type State = {
  isFilterCollapsed: boolean;
  columnsModel: ColumnState[];
  resizeBy?: 'text' | 'grid';
  selectedRows: any[];
  gridApi: GridApi | null;
  gridColumnApi: ColumnApi | null;
};

export default class DataGrid<TRow> extends React.Component<Props<TRow>, State> {
  state: State = {
    isFilterCollapsed: true,
    columnsModel: [],
    resizeBy: undefined,
    selectedRows: [],
    gridApi: null,
    gridColumnApi: null,
  };

  static defaultProps = defaultProps;

  static DEFAULT_PER_PAGE: number = 25;

  componentDidUpdate = (prevProps: Props<TRow>) => {
    if (prevProps.rows !== this.props.rows || prevProps.loading !== this.props.loading) {
      this.handleLoadingOverlay();
    }
  };

  componentWillUnmount = () => {
    this.setState({ gridApi: null, gridColumnApi: null });
  };

  getResizeBy = () => this.state.resizeBy || this.props.resizeBy || 'text';

  /**
   *  page & perPage - are set by params directly by our components
   *  orderField & OrderDirection - are coming from internal gridApi (so we can't set it manually)
   */
  handlePaginationOrOrderChange = ({ page, perPage }: { page?: number; perPage?: number }) => {
    if (this.props.onPaginationChange && this.state.gridColumnApi) {
      const columnsState = this.state.gridColumnApi.getColumnState();
      const sortModel = columnsState.filter(column => column.sort);
      const order = sortModel.length > 0 ? sortModel[0] : { colId: undefined, sort: undefined };

      const newPerPage = perPage ? perPage : this.props.pagination.perPage;
      let newPage = page ? page : this.props.pagination.page;
      if (perPage) {
        // If perPage has changed, set the current page to 1.
        // Otherwise, set page to the provided value.
        newPage = newPerPage === this.props.pagination.perPage ? newPage : 1;
      }

      this.props.onPaginationChange({
        page: newPage,
        perPage: newPerPage,
        orderField: order.colId,
        orderDirection: order.sort as 'asc' | 'desc' | undefined,
      });
    }
  };

  handleToggleFilterCollapse = () =>
    this.setState({ isFilterCollapsed: !this.state.isFilterCollapsed });

  handleToggleColumnVisibility = (ids: string[]) => {
    this.state.gridColumnApi &&
      this.state.gridColumnApi.applyColumnState({
        state: this.state.columnsModel.map(c => ({
          ...c,
          hide: Boolean(c.colId && !ids.includes(c.colId)),
        })),
      });
  };

  handleGridReady = (grid: GridReadyEvent) => {
    this.setState({ gridApi: grid.api, gridColumnApi: grid.columnApi }, () => {
      this.handleColumnModelChange();
      this.handleLoadingOverlay();
      this.props.onGridReady && this.props.onGridReady(grid);
    });
  };

  handleOnResizeStrategyChange = strategy => this.setState({ resizeBy: strategy });

  handleColumnModelChange = () => {
    const { onColumnModelChange } = this.props;

    if (this.state.gridColumnApi) {
      const columnsModel = this.state.gridColumnApi.getColumnState();

      if (!isEqual(columnsModel, this.state.columnsModel)) {
        this.setState({ columnsModel });
        onColumnModelChange && onColumnModelChange(columnsModel);
      }
    }
  };

  handleSelectionChange = (selectedRows, activeRow) => {
    this.setState({ selectedRows });
    this.props.onSelectionChange && this.props.onSelectionChange(selectedRows, activeRow);
  };

  handleLoadingOverlay = () => {
    if (this.state.gridApi) {
      const { loading, rows } = this.props;
      if (loading) {
        const gridApi = this.state.gridApi;
        // loading issue workaround https://github.com/ag-grid/ag-grid/issues/3849
        if (this.props.skipSetTimeOutLoading) {
          gridApi.showLoadingOverlay();
        } else {
          setTimeout(() => {
            gridApi.showLoadingOverlay();
          });
        }
      } else if (rows && rows.length === 0) {
        this.state.gridApi.showNoRowsOverlay();
      } else {
        this.state.gridApi.hideOverlay();
      }
    }
  };

  handleColumnVisibility = (event: ColumnVisibleEvent) => {
    this.props.onColumnVisible && this.props.onColumnVisible(event);
  };

  handleColumnResized = (event: ColumnResizedEvent) => {
    this.props.onColumnResized && this.props.onColumnResized(event);
  };

  handleCellFocused = (event: CellFocusedEvent) => {
    this.props.onCellFocused && this.props.onCellFocused(event);
  };

  handleFirstDataRendered = (event: FirstDataRenderedEvent) => {
    this.props.onFirstDataRendered && this.props.onFirstDataRendered(event);
  };

  handleViewportChanged = (event: ViewportChangedEvent) => {
    this.props.onViewportChanged && this.props.onViewportChanged(event);
  };

  handleModelUpdated = (event: ModelUpdatedEvent) => {
    this.props.onModelUpdated && this.props.onModelUpdated(event);
  };

  handleSortChanged = this.props.onSortChanged
    ? (event: SortChangedEvent) => {
        this.props.onSortChanged && this.props.onSortChanged(event);
      }
    : undefined;

  renderGrid() {
    const {
      rows,
      columns,
      getRowsPinnedToTop,
      getRowsPinnedToBottom,
      gridOptions,
      renderPanel,
      onCellValueChange,
      getRowNodeId,
      frameworkComponents,
      renderFilters,
      useCollapseForFilters,
      extended,
      domLayout,
      overlayNoRowsTemplate,
      resizeStrategy,
      infiniteScroll,
      datasource,
      gridHeight,
      context,
      rowSelection,
      isRowSelectable,
      testId,
    } = this.props;
    const { isFilterCollapsed, selectedRows } = this.state;

    return (
      <React.Fragment>
        {renderFilters && (
          <div>
            <Collapse isExpanded={!useCollapseForFilters ? true : !isFilterCollapsed}>
              <div>{renderFilters()}</div>
            </Collapse>
          </div>
        )}
        {renderPanel && <SPanel>{renderPanel({ selectedRows })}</SPanel>}
        <SAgGridWrapper
          data-test-id={testId}
          withMargin={!!extended && extended.withMargin}
          fullHeight={infiniteScroll}
        >
          <AgGridWrapper
            domLayout={domLayout}
            overlayNoRowsTemplate={overlayNoRowsTemplate}
            columns={columns}
            gridOptions={gridOptions}
            pinnedTopRows={getRowsPinnedToTop && rows ? getRowsPinnedToTop(rows) : undefined}
            pinnedBottomRows={
              getRowsPinnedToBottom && rows ? getRowsPinnedToBottom(rows) : undefined
            }
            rows={rows}
            resizeBy={this.getResizeBy()}
            onOrderByChange={() => this.handlePaginationOrOrderChange({})}
            onGridReady={this.handleGridReady}
            onCellValueChange={onCellValueChange}
            onSelectionChange={this.handleSelectionChange}
            onDisplayedColumnsChanged={this.handleColumnModelChange}
            getRowNodeId={getRowNodeId}
            resizeStrategy={resizeStrategy}
            infiniteScroll={infiniteScroll}
            datasource={datasource}
            gridHeight={gridHeight}
            context={context}
            frameworkComponents={frameworkComponents}
            onColumnVisible={this.handleColumnVisibility}
            onColumnResized={this.handleColumnResized}
            onCellFocused={this.handleCellFocused}
            onFirstDataRendered={this.handleFirstDataRendered}
            onViewportChanged={this.handleViewportChanged}
            onModelUpdated={this.handleModelUpdated}
            onSortChanged={this.handleSortChanged}
            tabToNextCell={this.props.tabToNextCell}
            rowSelection={rowSelection}
            isRowSelectable={isRowSelectable}
          />
        </SAgGridWrapper>
      </React.Fragment>
    );
  }

  renderNumberOfRowsFetched() {
    const { numberOfRowsFetched, totalRows, infiniteScroll } = this.props;

    if (infiniteScroll && numberOfRowsFetched !== undefined && totalRows !== undefined) {
      return (
        <SText>
          Showing {numberOfRowsFetched} rows of {totalRows}
        </SText>
      );
    }

    return null;
  }

  render() {
    const {
      columns,
      categorizedColumns,
      className,
      useCollapseForFilters,
      pagination,
      totalPages,
      extended,
      domLayout,
    } = this.props;
    const { columnsModel } = this.state;

    if (extended) {
      const {
        fillViewport,
        onChangeTab,
        tabs,
        title,
        withMargin,
        activeTab,
        hideColumnResize,
        hideColumnSelector,
        hidePagination,
        onDownload,
        downloadTitle,
        downloadOptions,
        hideHeader = false,
      } = extended;
      return (
        <StyledBox
          className={className}
          fillViewport={fillViewport}
          withMargin={withMargin}
          domLayout={domLayout}
        >
          {!hideHeader && (
            <Box.Header activeTab={activeTab} tabs={tabs} onChangeTab={onChangeTab} title={title}>
              {this.renderNumberOfRowsFetched()}
              {!hideColumnResize && (
                <ColumnResizeDropdown
                  onChange={this.handleOnResizeStrategyChange}
                  strategy={this.getResizeBy()}
                />
              )}
              {!hideColumnSelector && (
                <ColumnSelectorDropdown
                  columns={columns}
                  categorizedColumns={categorizedColumns}
                  columnsModel={columnsModel}
                  onChange={this.handleToggleColumnVisibility}
                />
              )}
              {onDownload && (
                <DownloadDropdown
                  onDownload={onDownload}
                  downloadOptions={downloadOptions}
                  downloadTitle={downloadTitle}
                />
              )}
              {!hidePagination && (
                <React.Fragment>
                  <PaginationDropdown
                    itemsPerPage={pagination.perPage}
                    onChange={perPage => this.handlePaginationOrOrderChange({ perPage })}
                  />
                  <Pagination
                    activePage={pagination.page}
                    totalPages={totalPages}
                    onChangePage={page => this.handlePaginationOrOrderChange({ page })}
                  />
                </React.Fragment>
              )}
              {useCollapseForFilters && (
                <BoxButton aria-label="toggle filters" onClick={this.handleToggleFilterCollapse}>
                  <Icon name="sliders-h" />
                </BoxButton>
              )}
            </Box.Header>
          )}

          {this.renderGrid()}

          {!hidePagination && (
            <StyledBoxFooter>
              <Pagination
                activePage={pagination.page}
                totalPages={totalPages}
                onChangePage={page => this.handlePaginationOrOrderChange({ page })}
              />
            </StyledBoxFooter>
          )}
        </StyledBox>
      );
    } else {
      return <div className={className}>{this.renderGrid()}</div>;
    }
  }
}
