import { useEffect, useMemo, useRef, useState } from 'react';

const useIntervalProgress = (intervalTime: number, refreshTime: number) => {
  const [progress, setProgress] = useState(0);
  const intervalRef = useRef<number | null>(null);
  const completedTimestampRef = useRef<number | null>(null);

  const stopInterval = useMemo(
    () => () => {
      if (intervalRef.current !== null) {
        const currentTimer = intervalRef.current;

        window.clearInterval(currentTimer);

        intervalRef.current = null;
        completedTimestampRef.current = null;
      }
    },
    []
  );

  const startInterval = useMemo(
    () => () => {
      if (intervalRef.current === null) {
        if (completedTimestampRef.current === null) {
          // sets completed timestamp
          completedTimestampRef.current = Date.now() + intervalTime;
          setProgress(0);
        }

        intervalRef.current = window.setInterval(() => {
          const currentTimestamp = Date.now();
          const completedTimestamp = completedTimestampRef.current ?? 0;

          if (currentTimestamp < completedTimestamp) {
            const timePassed = intervalTime - (completedTimestamp - currentTimestamp);
            const progressPercentage = (timePassed / intervalTime) * 100;

            setProgress(progressPercentage);
          } else {
            // completed
            setProgress(100);
            stopInterval();
          }
        }, refreshTime);
      }
    },
    [intervalTime, refreshTime, stopInterval]
  );

  useEffect(() => {
    stopInterval(); // if the values passed to the hook change clear the interval
    setProgress(0);

    return () => {
      stopInterval(); // always clear the interval when unmounting the component
    };
  }, [intervalTime, refreshTime, stopInterval]);

  return {
    progress,
    startInterval,
    stopInterval,
  };
};

export default useIntervalProgress;
