import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import type { TextFieldProps } from '@mui/material/TextField';
import TextField from '@mui/material/TextField';

const StyledTextField = styled(TextField, {
  name: 'AppBarSearchTextField',
  slot: 'Root',
  overridesResolver: (_props, styles) => [styles.root],
})({});

const StyledInputAdornment = styled(InputAdornment, {
  name: 'AppBarSearchTextField',
  slot: 'Adornment',
  overridesResolver: (_props, styles) => [styles.adornment],
})({});

export type AppBarSearchTextFieldProps = Omit<TextFieldProps, 'InputProps' | 'color'>;

export const AppBarSearchTextField: React.FC<AppBarSearchTextFieldProps> = props => {
  return (
    <StyledTextField
      {...props}
      InputProps={{
        endAdornment: (
          <StyledInputAdornment position="end">
            <SearchIcon />
          </StyledInputAdornment>
        ),
      }}
    />
  );
};
