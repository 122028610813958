import styled, { css } from 'styled-components/macro';

import Box from '../../layout/box/Box';

export const SAgGridWrapper = styled.div<{ withMargin?: boolean; fullHeight?: boolean }>`
  padding: ${({ withMargin }) => (withMargin ? '10px 20px' : 0)};
  height: ${({ fullHeight }) => (fullHeight ? '100%' : 'auto')};
`;

type BoxProps = {
  fillViewport?: boolean;
  domLayout?: 'normal' | 'autoHeight';
};

// ES Lint crashes when using multiline definition of props here
export const StyledBox = styled(Box)<BoxProps>`
  margin-bottom: 0;

  ${({ fillViewport, domLayout }) =>
    fillViewport &&
    css`
      height: 100%;
      min-height: 400px;

      ${SAgGridWrapper} {
        padding: 0 20px;
        flex: 1 1 auto;
        position: relative;

        > div {
          position: absolute;
          top: 0;
          bottom: 0;
          left: ${domLayout === 'normal' ? 0 : '20px'};
          right: ${domLayout === 'normal' ? 0 : '20px'};
          height: auto;
        }
      }
    `}
`;

export const SPanel = styled.div`
  align-items: flex-start;

  &:not(:empty) {
    padding: 10px 20px;

    button {
      margin-right: 10px;
    }
  }
`;

export const StyledBoxFooter = styled(Box.Footer)`
  border-top: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.light}`};
`;

export const SText = styled.span`
  align-self: center;
  color: ${({ theme }) => theme.text.color.dark};
  font-size: ${({ theme }) => theme.text.size.small};
  padding: 0px 12px;
`;
