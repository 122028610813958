import { Location } from 'history';
import React from 'react';
import { matchPath, useHistory } from 'react-router';
import { Prompt } from 'react-router-dom';

import UnsavedChangesModal from '../unsaved-changes-modal/UnsavedChangesModal';

export type Props = {
  /**
   * Block navigation to different url
   */
  when: boolean;
  /**
   * Called when user clicks on leave page button
   */
  onLeave?: () => void;
  /**
   * Ignore the unsaved changes guard when the next route is one of ignoreRoutes
   */
  ignoreRoutes?: string[];
  /**
   * Modal Component to render
   */
  renderModal?: (renderProps: {
    show: boolean;
    onLeave?: () => void;
    onStay?: () => void;
    onHide: () => void;
  }) => JSX.Element;
};

/**
 * Wrapper around Prompt and UnsavedChangesModal components.
 * Watches for react router navigation changes and shows modal window if when property is set to true.
 */
const UnsavedChangesGuard: React.FC<Props> = ({
  when,
  onLeave,
  children,
  ignoreRoutes = [],
  renderModal = props => <UnsavedChangesModal {...props} />,
}) => {
  const { push } = useHistory();
  const [nextLocation, setNextLocation] = React.useState<string>();
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = React.useState<boolean>(false);

  const handleBlockNavigation = (nextLocation: Location) => {
    if (!!matchPath(nextLocation.pathname, ignoreRoutes)) {
      return true;
    }

    setNextLocation(nextLocation.pathname);
    setShowUnsavedChangesModal(true);

    return false;
  };

  React.useEffect(() => {
    // Proceed to next location when there has been a navigation attempt and client no longer blocks it
    if (!when && nextLocation) {
      push(nextLocation);
    }
  }, [when, nextLocation, push]);

  const onLeaveCallback = React.useCallback(() => {
    onLeave && onLeave();
  }, [onLeave]);

  const onStayCallback = React.useCallback(() => {
    setNextLocation(undefined);
  }, []);

  const onHideCallback = React.useCallback(() => {
    setShowUnsavedChangesModal(false);
  }, []);

  return (
    <React.Fragment>
      <Prompt when={when} message={handleBlockNavigation} />
      {renderModal({
        show: showUnsavedChangesModal,
        onLeave: onLeaveCallback,
        onStay: onStayCallback,
        onHide: onHideCallback,
      })}
      {children}
    </React.Fragment>
  );
};

export default UnsavedChangesGuard;
