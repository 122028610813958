import { ConfirmModal, Option } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { connectModal, hide, InjectedProps, show } from 'redux-modal';

import { OfferingType } from '../../../../types/domain/offering/constants';
import { precedentTransactionCancelled, precedentTransactionSuccess } from '../../ducks';
import { fetchSectors, selectSectors } from '../../sectors/ducks';
import { mapSectorsToOptions } from '../../sectors/sectors.model';
import { mapEntityToFormValues, mapFormValuesToEntity } from './precedent-report.model';
import PrecedentReportForm from './PrecedentReportForm';

export const PRECEDENT_TRANSACTIONS_MODAL_ID = 'PRECEDENT_TRANSACTIONS_MODAL_ID';

const mapStateToProps = state => ({
  customSectorsList: selectSectors(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      confirm: precedentTransactionSuccess,
      cancel: precedentTransactionCancelled,
      fetchSectors,
    },
    dispatch
  ),
});

type OwnProps = {
  advisoryOptions: Option[];
  fundOptions: Option[];
  offeringType: OfferingType;
  marketCapMin: number;
  marketCapMax: number;
  sectors: string[];
  customSectors: string[];
  useCustomSectors?: boolean;
};
type Props = OwnProps &
  InjectedProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export class PrecedentReportModalComponent extends React.Component<Props> {
  state = {
    values: mapFormValuesToEntity(
      mapEntityToFormValues(
        this.props.marketCapMin,
        this.props.marketCapMax,
        this.props.offeringType,
        this.props.sectors,
        this.props.customSectors,
        this.props.useCustomSectors
      )
    ),
  };

  componentDidMount() {
    !this.props.customSectorsList && this.props.actions.fetchSectors();
  }

  handleConfirm = () => {
    this.props.actions.confirm({
      filterValues: this.state.values,
      offeringType: this.props.offeringType,
    });
    this.props.handleHide();
  };

  handleHide = () => {
    this.props.actions.cancel();
    this.props.handleHide();
  };

  handleOnFormValuesChange = values => {
    this.setState({ values });
  };

  render() {
    const {
      advisoryOptions,
      offeringType,
      marketCapMax,
      marketCapMin,
      sectors,
      customSectorsList,
      customSectors,
      useCustomSectors,
    } = this.props;
    return (
      <ConfirmModal
        show={this.props.show}
        cancelButtonCaption="Cancel"
        confirmButtonCaption="Generate"
        size="large"
        title="Precedent Transactions"
        onHide={this.handleHide}
        onConfirm={this.handleConfirm}
      >
        <PrecedentReportForm
          advisoryOptions={advisoryOptions}
          offeringType={offeringType}
          marketCapMax={marketCapMax}
          marketCapMin={marketCapMin}
          sectors={sectors}
          customSectorIds={customSectors}
          customSectorOptions={mapSectorsToOptions(customSectorsList)}
          useCustomSectors={useCustomSectors}
          onChange={this.handleOnFormValuesChange}
        />
      </ConfirmModal>
    );
  }
}

export const showPrecedentReportModal = (props: OwnProps) =>
  show(PRECEDENT_TRANSACTIONS_MODAL_ID, props);

export const hidePrecedentReportModal = () => hide(PRECEDENT_TRANSACTIONS_MODAL_ID);

const container = connect(mapStateToProps, mapDispatchToProps)(PrecedentReportModalComponent);

export default connectModal({ name: PRECEDENT_TRANSACTIONS_MODAL_ID, destroyOnHide: false })(
  container
);
