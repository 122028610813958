import Stack from '@mui/material/Stack';
import React from 'react';

export type PageLayoutProps = Readonly<{
  /**
   * Header of the page
   */
  readonly header?: React.ReactNode;
}>;

export const PageLayout: React.FC<PageLayoutProps> = ({ header, children }) => {
  return (
    <Stack component="main">
      {header}
      {children}
    </Stack>
  );
};
