import { createTestId } from '../../../../common/selectors';

const create = (name: string) => createTestId(`account-user-detail-${name}`);

export const accountUserDetailScreenSelector = create('screen');
export const accountUserDetailDisableUserButtonSelector = create('disable-user-button');
export const accountUserDetailEnableUserButtonSelector = create('enable-user-button');
export const accountUserDetailUnlockUserButtonSelector = create('unlock-user-button');
export const accountUserDetailResendInviteButtonSelector = create('resend-invite-button');
export const accountUserDetailResetPasswordButtonSelector = create('reset-password-button');
export const accountUserDetailSaveButtonSelector = create('save-button');
export const accountUserDetailEditButtonSelector = create('edit-button');
export const accountUserDetailCancelButtonSelector = create('cancel-button');
export const accountUserDetailHeaderSelector = create('header');
export const accountUserDetailFormSelector = create('form');
export const accountUserDetailRoleName = create('role-name');
export const accountUserDetailRoleRemoveButtonSelector = create('role-remove-button');
export const accountUserDetailRoleRowSelector = create('role-row');
export const accountUserChangeEmailButtonSelector = create('change-email-button');
export const accountUserDetailActiveStatusTagSelector = create('active-status-tag');
export const accountUserDetailResetPasswordBannerSelector = create('reset-password-banner');
