import { AsyncSelect, Option } from '@cmg/common';
import { companySearchTestId } from '@cmg/e2e-selectors';
import debounce from 'lodash/debounce';
import React from 'react';

import { SSearchWrapper, StyledSearchIcon } from './CompanySearch.styles';
import CompanySearchOption from './CompanySearchOption';
import type { Company, CompanyOption } from './types';

type Props = {
  onItemClick: (companyId: Company['id']) => void;
  onSearchOptions: (searchTerm: string) => Promise<CompanyOption[]>;
  autoFocus?: boolean;
  maxMenuHeight?: number;
};

/**
 * Company search input handles fetching and displaying search results
 */
export const CompanySearch: React.FC<Props> = ({
  onItemClick,
  onSearchOptions,
  autoFocus,
  maxMenuHeight,
}): JSX.Element => {
  const handleLoadOptions = debounce(
    async (inputValue: string, callback: (options: Option<Company['id']>[]) => void) => {
      const companyOptions = await onSearchOptions(inputValue);
      callback(companyOptions);
    },
    350
  );

  const searchStyles = () => ({
    control: () => ({
      height: '44px',
      cursor: 'pointer',
    }),
  });

  const handleOnChange = (value: string | null) => {
    if (value) {
      onItemClick(value);
    }
  };

  const renderOption = (option: Option<Company['id']>, { inputValue }) => {
    return <CompanySearchOption option={option as CompanyOption} searchInput={inputValue} />;
  };

  return (
    <SSearchWrapper
      data-test-id={companySearchTestId.testId}
      data-testid={companySearchTestId.testId}
    >
      <AsyncSelect<Company['id']>
        autoFocus={autoFocus}
        isClearable={false}
        loadOptions={handleLoadOptions}
        styledConfig={searchStyles()}
        noOptionsMessage={() => 'Please start typing a company name or ticker to see results'}
        openMenuOnClick={false}
        renderDropdownIndicator={() => <StyledSearchIcon name="search" size="lg" />}
        placeholder="Search for companies…"
        onChange={handleOnChange}
        renderOption={renderOption}
        value={null} // we don't display selected value
        maxMenuHeight={maxMenuHeight}
      />
    </SSearchWrapper>
  );
};

export default CompanySearch;
