import { countriesList, ToastManager } from '@cmg/common';
import React from 'react';

import { Country, Region } from '../../../../../graphql/__generated__/index';
import { useOfferingsFilterForm_CountriesQuery } from '../graphql/__generated__/OfferingsFilterForm_Countries';
import { useCreateRegionCountryOptions } from '../OfferingsFilterForm.model';

// add relevant Region prop to Country entities since this field is currently
// not implemented in graphql (returns expected future graphql operation result)
// TBD: remove once the Country.region is available via graphql
const attachRegions = (data: ReturnType<typeof useOfferingsFilterForm_CountriesQuery>['data']) => {
  if (!data?.countries?.items) {
    return data;
  }

  const cmgCountries = Object.values(countriesList);

  return {
    countries: {
      items: data.countries.items.map(item => ({
        ...item,
        region: cmgCountries.find(({ countryCode }) => countryCode === `${item.id}`)
          ?.region as unknown as Region,
      })),
      totalCount: data.countries?.totalCount,
    },
  };
};

export const useFetchRegionsAndCountries = ({ skip }: { skip?: boolean } = {}) => {
  const { data: rawData, error, loading } = useOfferingsFilterForm_CountriesQuery({ skip });
  const data = React.useMemo(() => attachRegions(rawData), [rawData]);
  const regionCountries = useCreateRegionCountryOptions(data);
  const regionCountryOptions = React.useMemo(() => {
    if (error) {
      ToastManager.error('Error occurred while fetching countries');
    }
    if (!loading && data) {
      return regionCountries;
    }
    return [];
  }, [data, error, loading, regionCountries]);

  const getRegionCountries = React.useCallback(
    (values: (Region | Country)[] | null) =>
      values
        ? (values
            .map(value => {
              const regionOption = regionCountryOptions.find(region => region.value === `${value}`);
              return regionOption ? regionOption.children.map(country => country.value) : value;
            })
            .flat() as Country[])
        : null,
    [regionCountryOptions]
  );

  return { regionCountryOptions, getRegionCountries };
};
