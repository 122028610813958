import { Grid } from '@cmg/design-system';
import React from 'react';

import { UserNote } from '../../../../../types/domain/offering/user-note';
import { OfferingProfile_ListQuery } from '../../graphql/__generated__/OfferingProfile';
import {
  spacing,
  x4_l4_m6_s12,
  x4_l12_m12_s12,
  x6_l6_m12_s12,
  x8_l8_m6_s12,
  x8_l12_m12_s12,
  x12_l6_m12_s12,
  x12_l12_m12_s12,
} from '../shared/OfferingProfile.styles';
import NewsWidget from '../shared/widgets/NewsWidget';
import UserNotesWidget from '../shared/widgets/UserNotesWidget';
import AdvisersWidget from './widgets/AdvisersWidget';
import CompanyWidget from './widgets/CompanyWidget';
import ManagersWidget from './widgets/ManagersWidget';
import PerformanceWidget from './widgets/PerformanceWidget';
import StructurePricingWidget from './widgets/StructurePricingWidget';
import TimingWidget from './widgets/TimingWidget';
import UnderwritingTermsWidget from './widgets/UnderwritingTermsWidget';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  userNote?: UserNote | null;
  onSaveUserNote?: (userNote: Partial<UserNote>) => void;
};

export const AtmProfile: React.FC<Props> = ({ offeringProfile, userNote, onSaveUserNote }) => {
  return (
    <Grid container {...spacing} alignItems="stretch" direction="row">
      <Grid container item {...x12_l12_m12_s12} {...spacing}>
        <CompanyWidget cardSizes={x4_l4_m6_s12} offeringProfile={offeringProfile} />
        <Grid container item {...x8_l8_m6_s12} {...spacing} alignItems="stretch" direction="row">
          <TimingWidget cardSizes={x6_l6_m12_s12} offeringProfile={offeringProfile} />
          <NewsWidget cardSizes={x6_l6_m12_s12} offeringProfile={offeringProfile} />
        </Grid>
      </Grid>
      <Grid container item {...x8_l12_m12_s12} {...spacing}>
        <StructurePricingWidget cardSizes={x6_l6_m12_s12} offeringProfile={offeringProfile} />
        <PerformanceWidget cardSizes={x6_l6_m12_s12} offeringProfile={offeringProfile} />
        <ManagersWidget cardSizes={x6_l6_m12_s12} offeringProfile={offeringProfile} />
        <UnderwritingTermsWidget cardSizes={x6_l6_m12_s12} offeringProfile={offeringProfile} />
      </Grid>
      <Grid container item {...x4_l12_m12_s12} {...spacing}>
        <AdvisersWidget cardSizes={x12_l6_m12_s12} offeringProfile={offeringProfile} />
        <UserNotesWidget
          cardSizes={x12_l6_m12_s12}
          onSaveUserNote={onSaveUserNote}
          userNote={userNote}
        />
      </Grid>
    </Grid>
  );
};
