import _invert from 'lodash/invert';

import { DataLabTableRowField, DLGWTableRowField } from './types';

/**
 * Map of legacy ruby field name to DLGW field name
 * `Record` guarantees that all keys of DataLabTableRow have an entry
 */
const mapping: Record<DataLabTableRowField, DLGWTableRowField | null> = {
  allocation: 'allocation',
  allocation_investment: 'allocationInvestment',
  allocation_pct: 'allocationPct',
  sales_concession: 'salesConcession',
  color_code: 'colorCode',
  companies_cik: 'companiesCik',
  companies_cusip: 'companiesCusip',
  companies_issuer: 'companiesIssuer',
  companies_sub_sector: 'companiesSubSector',
  companies_ticker: 'companiesTicker',
  estimated_fee: 'estimatedFee',
  fifty_two_wk_high: 'fiftyTwoWkHigh',
  filing_details_deal_pct_at_pricing_market_cap: 'filingDetailsDealPctAtPricingMarketCap',
  filing_details_deal_pct_market_cap_pre_offering: 'filingDetailsDealPctMarketCapPreOffering',
  filing_details_gross_proceeds: 'filingDetailsGrossProceeds',
  filing_details_gross_proceeds_with_over_allotment: 'filingDetailsGrossProceedsWithOverAllotment',
  filing_details_market_cap_at_pricing: 'filingDetailsMarketCapAtPricing',
  filing_details_net_price: 'filingDetailsNetPrice',
  filing_details_offering_price: 'filingDetailsOfferingPrice',
  filing_details_pct_secondary_shares: 'filingDetailsPctSecondaryShares',
  filing_details_price_vs_range: 'filingDetailsPriceVsRange',
  filing_details_size_in_dollars: 'filingDetailsSizeInDollars',
  fill_rate: 'fillRate',
  ioi_shares: 'ioiShares',
  last_trade_before_filing: 'lastTradeBeforeFiling',
  last_trade_before_offer: 'lastTradeBeforeOffer',
  left_lead_firm_id: 'leftLeadFirmId',
  left_lead_firm_name: 'leftLeadFirmName',
  lock_up_early_release_date: 'lockUpEarlyReleaseDate',
  lock_up_expiration: 'lockUpExpiration',
  lock_up_period: 'lockUpPeriod',
  offering_confidential_filing_date: 'offeringConfidentialFilingDate',
  offering_day1_vwap_price: 'offeringDay1VwapPrice',
  offering_discount_to_vwap: 'offeringDiscountToVwap',
  offering_entity_structure: 'offeringEntityStructure',
  offering_first_day_turnover: 'offeringFirstDayTurnover',
  offering_first_day_volume: 'offeringFirstDayVolume',
  offering_first_trade_date: 'offeringFirstTradeDate',
  offering_gross_spread: 'offeringGrossSpread',
  offering_gross_spread_pct: 'offeringGrossSpreadPct',
  offering_id: 'offeringId',
  offering_initial_ipo_range_high: 'offeringInitialIpoRangeHigh',
  offering_initial_ipo_range_low: 'offeringInitialIpoRangeLow',
  offering_initial_registration_value: 'offeringInitialRegistrationValue',
  offering_offer_to_1day: 'offeringOfferTo1Day',
  offering_offer_to_3day: 'offeringOfferTo3Day',
  offering_offer_to_7day: 'offeringOfferTo7Day',
  offering_offer_to_14day: 'offeringOfferTo14Day',
  offering_offer_to_30day: 'offeringOfferTo30Day',
  offering_offer_to_90day: 'offeringOfferTo90Day',
  offering_offer_to_180day: 'offeringOfferTo180Day',
  offering_offer_to_current: 'offeringOfferToCurrent',
  offering_offer_to_one_year: 'offeringOfferToOneYear',
  offering_offer_to_open: 'offeringOfferToOpen',
  offering_offer_to_prior_quarter: 'offeringOfferToPriorQuarter',
  offering_offer_to_vwap_1day: 'offeringOfferToVwap1Day',
  offering_over_allotment_authorized: 'offeringOverAllotmentAuthorized',
  offering_over_allotment_exercised: 'offeringOverAllotmentExercised',
  offering_pct_change_in_size: 'offeringPctChangeInSize',
  offering_post_offering_shares: 'offeringPostOfferingShares',
  offering_preliminary_prospectus_link: 'offeringPreliminaryProspectusLink',
  offering_price_vs_midpoint: 'offeringPriceVsMidpoint',
  offering_primary_shares_base_offering: 'offeringPrimarySharesBaseOffering',
  offering_public_filing_date: 'offeringPublicFilingDate',
  offering_revised_ipo_range_high: 'offeringRevisedIpoRangeHigh',
  offering_revised_ipo_range_low: 'offeringRevisedIpoRangeLow',
  offering_secondary_shares_base_offering: 'offeringSecondarySharesBaseOffering',
  offering_security_type: 'offeringSecurityType',
  offering_settlement_date: 'offeringSettlementDate',
  offering_size: 'offeringSize',
  offering_total_bookrunners: 'offeringTotalBookrunners',
  offering_total_co_managers: 'offeringTotalCoManagers',
  offering_total_managers: 'offeringTotalManagers',
  offering_total_non_bookrunners: 'offeringTotalNonBookrunners',
  offering_total_pct_to_bookrunners: 'offeringTotalPctToBookrunners',
  offering_total_pct_to_co_managers: 'offeringTotalPctToCoManagers',
  offering_total_pct_to_left_lead: 'offeringTotalPctToLeftLead',
  offering_total_pct_to_non_bookrunners: 'offeringTotalPctToNonBookrunners',
  offering_vwap_price: 'offeringVwapPrice',
  offerings_all_in_cost: 'offeringsAllInCost',
  offerings_discount_to_52wk_high: 'offeringsDiscountTo52WkHigh',
  offerings_discount_to_last_trade: 'offeringsDiscountToLastTrade',
  offerings_file_to_offer_discount: 'offeringsFileToOfferDiscount',
  offerings_pre_offering_adtv: 'offeringsPreOfferingAdtv',
  offerings_pricing_date: 'offeringsPricingDate',
  offerings_sector: 'offeringsSector',
  offerings_size_as_multiple_of_adtv: 'offeringsSizeAsMultipleOfAdtv',
  offerings_type: 'offeringsType',
  primary_shareholder_firm_id: 'primaryShareholderFirmId',
  primary_shareholder_name: 'primaryShareholderName',
  re_offer_discount_high: 'reOfferDiscountHigh',
  re_offer_discount_low: 'reOfferDiscountLow',
  re_offer_high: 'reOfferHigh',
  re_offer_low: 'reOfferLow',
  return_open: 'returnOpen',
  return_1day: 'return1Day',
  return_3day: 'return3Day',
  return_7day: 'return7Day',
  shareholders: 'shareholders',
  underwriters: 'underwriters',
  filing_details_market_cap_pre_offering: 'filingDetailsMarketCapPreOffering',
  offering_use_of_proceeds: 'offeringUseOfProceeds',
  offering_forward_agreement: 'offeringForwardAgreement',
  offering_pre_offering_ownership: 'offeringPreOfferingOwnership',
  offering_post_offering_ownership: 'offeringPostOfferingOwnership',
  offering_pct_pre_offering_ownership: 'offeringPctPreOfferingOwnership',
  offering_pct_post_offering_ownership: 'offeringPctPostOfferingOwnership',
  offering_pre_offering_shares: 'offeringPreOfferingShares',
  total_shares_filed_excl_shoe: 'totalSharesFiledExclShoe',
  offering_launch_date: 'offeringLaunchDate',
  timing_of_launch: 'timingOfLaunch',
  first_follow_on: 'firstFollowOn',
  offering_status: null,
  offering_split_adjusted_offering_price: 'offeringSplitAdjustedOfferingPrice',
  issuer_isin: 'issuerIsin',
  conditional_lock_up: 'conditionalLockUp',
  multiple_lock_ups: 'multipleLockUps',
  mean: 'meanFee',
  median: 'medianFee',
  offerings: 'offerings',
  ipos: 'ipos',
  fos: 'fos',
  underwriting_table_shares: 'underwritingTableShares',
  left_lead: 'leftLead',
  underwriter_name: 'underwriterName',
  book_offerings: 'bookOfferings',
  non_book_offerings: 'nonBookOfferings',
  offerings_volume: 'offeringsVolume',
  underwritten_volume: 'underwrittenVolume',
  book_fees: 'bookFees',
  non_book_fees: 'nonBookFees',
  min: 'minFee',
  max: 'maxFee',
  fees: 'totalFees',
  wallet_share: 'walletShare',
  follow_on_discount_from_filing: 'followOnDiscountFromFiling',
  follow_on_discount_to_last_trade: 'followOnDiscountToLastTrade',
  gross_spread: 'offeringGrossSpread',
  pct_of_market_cap: 'pctOfMarketCap',
  first_day_turnover: 'offeringFirstDayTurnover',
  offer_to_open: 'offeringOfferToOpen',
  offer_to_1day: 'offeringOfferTo1Day',
  offer_to_3day: 'offeringOfferTo3Day',
  offer_to_7day: 'offeringOfferTo7Day',
  offer_to_14day: 'offeringOfferTo14Day',
  offer_to_30day: 'offeringOfferTo30Day',
  offer_to_current: 'offeringOfferToCurrent',
  underwriter_role: 'underwriterRole',
  economics_pct: 'economicsPct',
  return_open_volume: 'returnOpenVolume',
  return_vwap_1day_volume: 'returnVwap1DayVolume',
  return_vwap_1day: 'returnVwap1Day',
  return_1day_volume: 'return1DayVolume',
  return_7day_volume: 'return7DayVolume',
  return_prior_quarter_volume: 'returnPriorQuarterVolume',
  return_prior_quarter: 'returnPriorQuarter',
  return_current_volume: 'returnCurrentVolume',
  return_current: 'returnCurrent',
  estimated_commission: 'estimatedCommission',
  sponsor_name: 'sponsorName',
  total_fees: 'totalFees',
  fund_name: 'fundName',
  pct_of_firm_offerings: 'pctOfFirmOfferings',
  pct_of_firm_total: 'pctOfFirmTotal',
  pct_of_firm_allocation: 'pctOfFirmAllocation',
  pct_of_deal: 'pctOfDeal',
  return_day7: 'return7Day',
  return_day3: 'return3Day',
  return_day1: 'return1Day',
  is_dual_listed: 'isDualListed',
  is_concurrent_offering: 'isConcurrentOffering',
  use_of_proceeds_note: 'useOfProceedsNote',
  is_synthetic_secondary: 'isSyntheticSecondary',
  is_up_listing: 'isUpListing',
  is_carve_out: 'isCarveOut',
  is_clean_up_trade: 'isCleanUpTrade',
  has_cornerstone_investors: 'hasCornerstoneInvestors',
  is_company_repurchase_included: 'isCompanyRepurchaseIncluded',
  is_company_repurchase_additional: 'isCompanyRepurchaseAdditional',
  egc: 'egc',
  naics: 'naics',
  headquarters: 'headquarters',
  initial_gross_proceeds_base: 'initialGrossProceedsBase',
  initial_gross_proceeds_base_local_curr: 'initialGrossProceedsBaseLocalCurr',
  revised_gross_proceeds_base: 'revisedGrossProceedsBase',
  revised_gross_proceeds_base_local_curr: 'revisedGrossProceedsBaseLocalCurr',
  // Following types are not in the BE contract for dotnet its not be a blocker for ruby
  cornerstone_offering_participants_amount: null,
  cornerstone_investors_private_placement_count: null,
  cornerstone_private_placement_amount: null,
  cornerstone_investors_offering_participants_count: null,
};

const { offering_status, ...inverseMappableFields } = mapping;
const inverseMapping = _invert(inverseMappableFields) as Record<
  DLGWTableRowField,
  DataLabTableRowField
>;

export const mapDLGWFieldToLegacyField = (
  dlgwFieldName: DLGWTableRowField | string
): DataLabTableRowField => {
  return inverseMapping[dlgwFieldName];
};

export const mapLegacyFieldToDLGWField = (
  legacyFieldName: DataLabTableRowField
): DLGWTableRowField | null => {
  return mapping[legacyFieldName];
};
