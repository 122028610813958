import { format, isDate, isValid, parseISO } from 'date-fns';

import { ISODate, ISODateTime } from '../../types/common';

/* Available date and time formats */
const TimeFormat = 'h:mm a';
const ShortDateFormat = 'MMM dd, yyyy';

type DateTime = ISODate | ISODateTime | Date;

export const getDate = (dt: DateTime): Date => {
  if (isDate(dt)) {
    return dt as Date;
  }
  return parseISO(dt as ISODate | ISODateTime);
};
export const formatDate = (dt: Date, formatString: string): string | null => {
  return isValid(dt) ? format(dt, formatString) : null;
};
export const getDisplayValue = (formatFunc: () => string | null) => {
  return formatFunc() ?? '-';
};

/**
 * @example '2023-11-29T06:00:00Z' returns 'Nov 29, 2023 at 6:00 AM'
 */
export const formatAsDateAndTime = (dt: DateTime) => {
  const date = getDate(dt);
  return formatDate(date, `${ShortDateFormat} 'at' ${TimeFormat}`);
};
/**
 * @example '2023-11-29T06:00:00Z' returns 'Nov 29, 2023 at 6:00 AM'
 */
export const getDisplayValueForDateAndTime = (dt: DateTime) => {
  return getDisplayValue(() => formatAsDateAndTime(dt));
};

/**
 * @example '2023-11-29T06:00:00Z' returns 'Nov 29, 2023'
 */
export const formatAsDate = (dt: DateTime) => {
  const date = getDate(dt);
  return formatDate(date, ShortDateFormat);
};
/**
 * @example '2023-11-29T06:00:00Z' returns 'Nov 29, 2023 at 6:00 AM'
 */
export const getDisplayValueForDate = (dt: DateTime) => {
  return getDisplayValue(() => formatAsDate(dt));
};
