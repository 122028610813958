import { styled } from '@mui/material/styles';
import type { TableRowProps as MUITableRowProps } from '@mui/material/TableRow';
import MUITableRow from '@mui/material/TableRow';
import React from 'react';

export type TableRowProps = MUITableRowProps &
  Readonly<{
    disabled?: boolean;
  }>;

const StyledTableRow = styled(MUITableRow, {
  name: 'TableRow',
  slot: 'Root',
  overridesResolver: (props: TableRowProps, styles) => {
    return [props.disabled && styles.disabled];
  },
})({}) as typeof MUITableRow;

export const TableRow: React.FC<TableRowProps> = props => {
  return <StyledTableRow {...props} />;
};
