import { Box } from '@cmg/common';
import Steps, { Step } from 'rc-steps';
import React from 'react';
import styled, { css } from 'styled-components/macro';

export const StyledSteps = styled(Steps)`
  &.rc-steps-dot {
    .rc-steps-item-tail {
      top: 4px;

      &:after {
        top: 1px;

        height: 1px;
        background-color: ${({ theme }) => theme.background.color.dark};
      }
    }

    .rc-steps-item-icon {
      height: 9px;
      width: 9px;
      background: transparent;

      .rc-steps-icon-dot {
        background: ${({ theme }) => theme.background.color.dark} !important;
        border-radius: 50%;
      }
    }

    .rc-steps-item-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100% - 4px);
      margin-top: 0;
      padding-top: 24px;
    }

    .rc-steps-item-title,
    .rc-steps-item-description {
      color: ${({ theme }) => theme.brand.color.dark};
      font-weight: 500;
    }

    .rc-steps-item-title {
      font-size: ${({ theme }) => theme.text.size.mediumSmall};
      text-transform: uppercase;
      margin-bottom: 12px;
    }

    .rc-steps-item-description {
      font-size: ${({ theme }) => theme.text.size.medium};
      text-align: center;
    }
  }
`;

export const StyledStep = styled(({ withWarning, ...rest }) => <Step {...rest} />)<{
  withWarning?: boolean;
}>`
  ${({ theme, withWarning }) =>
    withWarning &&
    css`
      .rc-steps-item-title,
      .rc-steps-item-description {
        color: ${theme.text.color.error};
      }
    `}
`;

export const SDay = styled.div`
  line-height: 1.1;
  font-size: ${({ theme }) => theme.text.size.billboard};
  font-weight: 300;
  color: ${({ theme }) => theme.brand.color.dark};

  ${({ theme }) => theme.mediaQuery.mediumDown} {
    font-size: ${({ theme }) => theme.text.size.xlarge};
  }
`;

export const StyledBox = styled(Box)`
  > div {
    padding-top: 15px;
  }
`;
