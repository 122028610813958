import { Icon } from '@cmg/common';
import styled from 'styled-components/macro';

export const SItemIconWrapper = styled.span`
  width: 20px;
  text-align: center;
  margin-left: 5px;
  margin-right: 15px;
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  top: 7px;
  cursor: pointer;
  font-size: 12px;
`;

export const STooltipContent = styled.div`
  max-width: 350px;
`;
