import { Box, Icon } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledBox = styled(Box)`
  position: absolute;
  overflow: hidden;
  height: calc(100% - 14px);
  width: calc(100% - 14px);
`;

export const StyledBoxContent = styled(Box.Content)`
  padding: 0;
  height: calc(100% - 41px);
`;

export const SSearch = styled.div`
  padding: 10px;

  input {
    padding-left: 35px !important; /* to beat InputWrapper */
  }
`;

export const SMessageText = styled.p`
  flex: 1 0 90%;
  margin: 0;
`;

export const SIconLink = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const SMessageWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.text.color.light};
`;

export const SIconWrapper = styled.div`
  display: flex;
  width: 40px;
  justify-content: center;
  align-items: center;
`;

export const STitle = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.medium};
  padding-bottom: 10px;
  font-size: ${({ theme }) => theme.text.size.medium};
`;

export const SNewsList = styled.ul`
  height: calc(100% - 58px);
  overflow-y: auto;
  padding: 10px;
  margin: 0;
  list-style: none;
`;

export const SNews = styled.li`
  border-bottom: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.light}`};
  padding-bottom: 15px;
  margin-bottom: 15px;

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }
`;

export const StyledIcon = styled(Icon)`
  padding-left: 9px;
`;
