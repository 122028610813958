import isNil from 'lodash/isNil';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { assertIsError } from '../../util/error';

export type StorageType = 'Local' | 'Session';

const Storage = {
  Local: window.localStorage,
  Session: window.sessionStorage,
};

const parseStorageObject = <T>(objectFromStorage: string | null): T | null => {
  return objectFromStorage ? (JSON.parse(objectFromStorage) as T) : null;
};

const getValueFromStorage = <T>(key: string, storage: Storage): T | null => {
  try {
    return parseStorageObject(storage.getItem(key));
  } catch (error) {
    return null;
  }
};

export type BrowserStorageProps<T> = Readonly<{
  key: string;
  initialValue: T;
  storageType?: StorageType;
}>;

export const useBrowserStorage = <T>({
  key,
  initialValue,
  storageType = 'Local',
}: BrowserStorageProps<T>): [T, (value: T) => void, boolean] => {
  const storage = Storage[storageType];
  const valueInStorage: T | null = useMemo(
    () => getValueFromStorage<T>(key, storage),
    [key, storage]
  );

  const [internalValue, setInternalValue] = useState<T>(valueInStorage ?? initialValue);
  const [isPreferenceSet, setIsPreferenceSet] = useState(!!valueInStorage);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key !== key || event.storageArea !== storage) {
        return;
      }

      const newValue = parseStorageObject<T>(event.newValue);

      if (internalValue === newValue) {
        return;
      }

      setInternalValue(newValue ?? initialValue);
      setIsPreferenceSet(!!newValue);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [initialValue, internalValue, key, storage]);

  const dispatchStorageEvent = useCallback(
    (value: T | undefined) => {
      const newValue = !isNil(value) ? JSON.stringify(value) : value;
      const oldValue = !isNil(valueInStorage) ? JSON.stringify(valueInStorage) : valueInStorage;

      const storageEvent = new StorageEvent('storage', {
        key,
        oldValue,
        newValue,
        storageArea: storage,
      });

      window.dispatchEvent(storageEvent);
    },
    [key, storage, valueInStorage]
  );

  const setValue = useCallback(
    (value: T) => {
      try {
        storage.setItem(key, JSON.stringify(value));
        setInternalValue(value);
        setIsPreferenceSet(true);
        dispatchStorageEvent(value);
      } catch (error) {
        assertIsError(error);
        const { message } = error;
        if (
          message.includes('exceeded') ||
          message.includes('maximum') ||
          message.includes('QUOTA_EXCEEDED_ERR')
        ) {
          storage.clear();
          dispatchStorageEvent(undefined);
        }
      }
    },
    [dispatchStorageEvent, key, storage]
  );

  return [internalValue, setValue, isPreferenceSet];
};

export const useRemoveFromBrowserStorage = (key: string, storageType: StorageType): void => {
  try {
    const item = Storage[storageType].getItem(key);
    if (item) {
      Storage[storageType].removeItem(key);
    }
  } catch (error) {
    console.error(error);
  }
};
