import { createTestId } from '../../../../common/selectors';

const create = (name: string) => createTestId(`xc-roadshows-configuration-days-${name}`);

export const roadshowDaysConfigScreen = create('screen');
export const roadshowDaysConfigCancelButton = create('cancel-button');
export const roadshowDaysConfigCreateButton = create('create-button');
export const roadshowDaysConfigSaveButton = create('save-button');
export const roadshowDaysConfigViewCalendarButton = create('view-calendar-button');
export const roadshowDaysConfigNextButton = create('next-button');
