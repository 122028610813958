import { createTestId } from '../../common/selectors';

const createSectionTestId = name => createTestId(`my-dashboard-${name}-section`);

// common
export const usEcmSection = createSectionTestId('us-ecm');
export const ipoPricingSummarySection = createSectionTestId('ipo-pricing-summary');
export const followOnSummarySection = createSectionTestId('follow-on-summary');

// buy-side
export const myParticipationSummarySection = createSectionTestId('my-participation-summary');
export const passedSection = createSectionTestId('passed');
export const myDatalabSubscriptionNotice = createTestId('my-dashboard-my-dl-subscription-notice');

// sell-side
export const leagueTableSection = createSectionTestId('league-table');
export const leagueTableUnderwriterNameLink = createTestId(
  'my-dashboard-league-table-underwriter-name-link'
);
