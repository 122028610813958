import { CSSProperties } from 'react';
import styled from 'styled-components/macro';

import type { CellRendererProps, FieldValidationProps } from '../../BaseAgGrid.model';

export type SLayoutProps = {
  readonly justifyContent?: CSSProperties['justifyContent'];
};

export const SLayout = styled.div<SLayoutProps & CellRendererProps & FieldValidationProps>`
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 11px;
  align-items: center;
  justify-content: ${({ justifyContent }) => {
    return justifyContent ?? 'space-between';
  }};
  background-color: ${({ theme, validation }) => {
    if (validation === 'error') {
      return theme.designSystem.colors.red['000'];
    }

    if (validation === 'warning') {
      return theme.designSystem.colors.yellow['000'];
    }

    return 'unset';
  }};
`;
