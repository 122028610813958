import styled from 'styled-components/macro';

export const STableHeader = styled.th`
  // overriding table > thead > th from common/Table
  text-align: right !important;
`;

export const STableCell = styled.td`
  text-align: right;
`;
