import { Column, Row } from '@cmg/common';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

import {
  getCurrencyFormat,
  getCurrencyFormatInMillions,
  getCurrencyRangeFormat,
} from '../../../shared/model/utils';
import WidgetNews from '../../shared/components/widget-news/WidgetNews';
import WidgetPriced from '../../shared/components/widget-priced/WidgetPriced';
import WidgetPricingAndPerformanceFollowOn from '../../shared/components/widget-pricing-and-performance-fo/WidgetPricingAndPerformanceFollowOn';
import WidgetPricingAndPerformanceIPO from '../../shared/components/widget-pricing-and-performance-ipo/WidgetPricingAndPerformanceIPO';
import WidgetShareholdersAdvisors from '../../shared/components/widget-shareholders-advisors/WidgetShareholdersAdvisors';
import WidgetTiming from '../../shared/components/widget-timing/WidgetTiming';
import WidgetUnderwriters from '../../shared/components/widget-underwriters/WidgetUnderwriters';
import WidgetUnderwritingTerms from '../../shared/components/widget-underwriting-terms/WidgetUnderwritingTerms';
import WidgetUserNote from '../../shared/components/WidgetUserNote';
import { getDatalabTimingsArray, isFollowOnType } from '../model/offering-dl-profile.model';

const SContainer = styled.div`
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  height: calc((100vh - 88px) - 86px);
`;

export default class OfferingProfile extends React.Component {
  static propTypes = {
    offering: PropTypes.object.isRequired,
    showInternational: PropTypes.bool.isRequired,
    userNote: PropTypes.object,
    onSaveUserNote: PropTypes.func,
  };

  render() {
    const { offering: offeringData, userNote, onSaveUserNote, showInternational } = this.props;
    const {
      hasPriceDiscovery,
      timing,
      performance,
      terms,
      pricing,
      filings,
      advisors,
      sponsors,
      managers,
      news,
      type,
      status,
      pricingCurrencyCode,
    } = offeringData;

    const isFollowOn = isFollowOnType(type);
    const timingArray = getDatalabTimingsArray(timing, status, type);

    const getCurrencyFormatFn = value => {
      return getCurrencyFormat({ value, pricingCurrencyCode, showInternational }) ?? 'N/A';
    };

    const getCurrencyFormatInMillionsFn = value => {
      return (
        getCurrencyFormatInMillions({ value, pricingCurrencyCode, showInternational }) ?? 'N/A'
      );
    };

    const getCurrencyRangeFormatFn = (valueLow, valueHigh) => {
      return (
        getCurrencyRangeFormat({ valueLow, valueHigh, pricingCurrencyCode, showInternational }) ??
        'N/A'
      );
    };
    return (
      <SContainer>
        <Row type="flex">
          <Column xs={24} sm={16}>
            <WidgetTiming timing={timingArray} />
          </Column>
          <Column xs={0} sm={8}>
            <WidgetUserNote userNote={userNote} handleSaveUserNote={onSaveUserNote} />
          </Column>
        </Row>
        <Row type="flex">
          <Column xs={24} sm={16} className="nested-col">
            <WidgetPriced
              isFollowOn={isFollowOn}
              pricing={pricing}
              filings={filings}
              pricingCurrencyCode={pricingCurrencyCode}
              showInternational={showInternational}
            />
            {isFollowOn && (
              <WidgetPricingAndPerformanceFollowOn
                pricing={pricing}
                performance={performance}
                hasPriceDiscovery={hasPriceDiscovery}
                showInternational={showInternational}
                getCurrencyFormat={getCurrencyFormatFn}
                getCurrencyFormatInMillions={getCurrencyFormatInMillionsFn}
                getCurrencyRangeFormat={getCurrencyRangeFormatFn}
              />
            )}
            {!isFollowOn && (
              <WidgetPricingAndPerformanceIPO
                pricing={pricing}
                performance={performance}
                showInternational={showInternational}
                getCurrencyFormat={getCurrencyFormatFn}
                getCurrencyFormatInMillions={getCurrencyFormatInMillionsFn}
              />
            )}
          </Column>
          <Column xs={0} sm={8}>
            <WidgetNews news={news} />
          </Column>
        </Row>
        <Row type="flex">
          <Column xs={24} sm={8}>
            <WidgetUnderwriters managers={managers} />
          </Column>
          <Column xs={24} sm={8}>
            <WidgetUnderwritingTerms terms={terms} />
          </Column>
          <Column xs={24} sm={8}>
            <WidgetShareholdersAdvisors advisors={advisors} sponsors={sponsors} />
          </Column>
        </Row>
        <Row className="print-hide">
          <Column xs={24} sm={0}>
            <WidgetUserNote userNote={userNote} handleSaveUserNote={onSaveUserNote} />
          </Column>
        </Row>
        <Row>
          <Column xs={24} sm={0}>
            <WidgetNews news={news} />
          </Column>
        </Row>
      </SContainer>
    );
  }
}
