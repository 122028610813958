import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import routeFactory from '../../../../common/util/routeFactory';
import { getAppSettings } from '../../../../config/appSettings';
// todo refactor
import { Pill } from '../../../../obsolete/components/ui';
import {
  InternationalOfferingTypeLabels,
  offeringTypeLabels,
} from '../../../../types/domain/offering/constants';
import { Offering } from '../../../../types/domain/offering/offering';
import { Pricing } from '../../../../types/domain/offering/pricing';
import {
  isInternationalOfferingsOn,
  isInternationalOfferingTypesOn,
} from '../../../datalab/model/utils';
import ScreenSubHeader from '../../../shared/layout/ScreenSubHeader';
import { getCurrencyFormatInBillions } from '../../../shared/model/utils';
import SectorProvider from '../../../shared/sectors/SectorProvider';
import {
  selectOffering,
  setFollowOfferingRequest,
  updateSectorCompanyActionCreators,
} from '../ducks';
import TopButton from './TopButton';

export const getFormattedOfferingSize = (
  pricing: Pricing,
  pricingCurrencyCode: string
): string | null => {
  const showInternational = isInternationalOfferingsOn();
  const value = showInternational ? pricing.sizeInLocalCurr : pricing.sizeInDollars;
  return getCurrencyFormatInBillions({ value, pricingCurrencyCode, showInternational });
};

const mapStateToProps = state => ({
  offering: selectOffering(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setFollowOffering: setFollowOfferingRequest,
      updateSectorCompany: updateSectorCompanyActionCreators.request,
    },
    dispatch
  ),
});

type OwnProps = {
  onPrecedentsFilter: (offering: Offering) => void;
};
type Props = OwnProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export const TickerTitle: React.FC<Offering> = offering => {
  const {
    symbol,
    issuer: { ticker },
  } = offering;

  return <span className="ticker">&nbsp;({symbol && symbol.length > 0 ? symbol : ticker})</span>;
};

export const OfferingSubHeader: React.FC<Props> = ({ offering, onPrecedentsFilter, actions }) => {
  const appSettings = getAppSettings();
  const handleOnToggleFollow = () => {
    actions.setFollowOffering({ offeringId: offering.id, isFollowing: !offering.userIsFollowing });
  };

  const handleSubmit = (sectorId: string) => {
    actions.updateSectorCompany({
      companyId: offering.issuer.firmId,
      sectorId,
    });
  };

  return (
    <SectorProvider onSubmit={handleSubmit}>
      {({ SectorToggle, SectorPicker }) => (
        <ScreenSubHeader
          title={
            <span>
              <Link to={routeFactory.companies.getUrlPath({ id: offering.issuer.firmId })}>
                {offering.issuer.firmName}
              </Link>
              <TickerTitle {...offering} />
              <Pill type="primary" uppercase>
                {offering.status}
              </Pill>
            </span>
          }
          bottomData={[
            {
              label: 'Offering Type',
              value:
                offering.pricingCurrencyCode !== 'USD' && isInternationalOfferingTypesOn()
                  ? InternationalOfferingTypeLabels[offering.type]
                  : offeringTypeLabels[offering.type],
            },
            {
              label: <SectorToggle />,
              value: (
                <SectorPicker
                  sector={offering.issuer.sector}
                  customSector={
                    offering.issuer.customSector && offering.issuer.customSector.sectorName
                  }
                />
              ),
            },
          ]}
          rightData={[
            {
              label: <div>Offering Size</div>,
              value: getFormattedOfferingSize(offering.pricing, offering.pricingCurrencyCode),
            },
          ]}
          rightButton={
            <div className="subheader-buttons">
              <TopButton
                primary={offering.userIsFollowing}
                hint={offering.userIsFollowing ? 'Unfollow' : 'Follow'}
                icon="thumbtack"
                onClick={handleOnToggleFollow}
              />
              {appSettings.features.isPrecedentTransactionsOn && (
                <TopButton
                  icon="search"
                  hint="Precedent Report"
                  onClick={() => onPrecedentsFilter(offering)}
                />
              )}
            </div>
          }
        />
      )}
    </SectorProvider>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferingSubHeader);
