import {
  DLGWDataLabTableRequest,
  DLGWTable,
} from '@capital-markets-gateway/api-client-datalab-gateway';
import { ApiResponse } from '@cmg/api';

import { DatalabScreens } from '../../../../features/datalab/constants';
import { DatalabRequestDto } from '../../../../features/datalab/types';
import { dlgwApiClient } from '../../dlgwApiClient';
import { mapLegacyRequestBodyToDlgw } from './requestMappers';
import { mapDlgwResponseBodyToLegacy } from './responseMappers';

const mapScreenToApiPath = (screen: DatalabScreens) => {
  const mapping: Record<DatalabScreens, string> = {
    [DatalabScreens.MARKET_PULSE]: '/dataLabs/marketPulse/table',
    [DatalabScreens.IPO_STRUCTURE]: '/dataLabs/ipoStructure/table',
    [DatalabScreens.IPO_PRICING]: '/dataLabs/ipoPricing/table',
    [DatalabScreens.FOLLOW_ON_STRUCTURE]: '/dataLabs/followOnStructure/table',
    [DatalabScreens.FOLLOW_ON_PRICING]: '/dataLabs/followOnPricing/table',
    [DatalabScreens.UNDERWRITING_TERMS]: '/dataLabs/underwritingTerms/table',
    [DatalabScreens.UNDERWRITER_PROFILE_SELL]: '/dataLabs/underwriterProfile/table',
    [DatalabScreens.UNDERWRITER_PROFILE_BUY]: '', // what is this?
    [DatalabScreens.IPO_BACKLOG]: '/dataLabs/ipoBacklog/table',
    [DatalabScreens.LOCK_UP_EXPIRATION]: '/dataLabs/lockUpExpiration/table',
    [DatalabScreens.LEAGUE_TABLE]: '/dataLabs/ecmLeagueTable/table',
    [DatalabScreens.AFTERMARKET_MATRIX]: '/dataLabs/afterMarketMatrix/table',
    [DatalabScreens.SPONSOR_LEAGUE_TABLE]: '/dataLabs/sponsorLeagueTable/table',
    [DatalabScreens.UNDERWRITER_OFFERINGS]: '/dataLabs/underwriterOfferings/table',
    [DatalabScreens.AFTERMARKET_UNDERWRITER_OFFERINGS]:
      '/dataLabs/afterMarketUnderwriterOfferings/table',
    [DatalabScreens.IOI_PARTICIPATION]: '/dataLabs/ioiParticipation/table',
    [DatalabScreens.IOI_LEAGUE_TABLE]: '/dataLabs/ioiLeagueTable/table',
    [DatalabScreens.PL_LEAGUE_TABLE]: '/dataLabs/pnlLeagueTable/table',
    [DatalabScreens.BROKER_PL_OFFERINGS]: '/dataLabs/brokerPnlOfferings/table',
    [DatalabScreens.FUND_IOI_LEAGUE_TABLE]: '/dataLabs/fundIoiLeagueTable/table',
    [DatalabScreens.FUND_IOI_PARTICIPATION]: '/dataLabs/fundIoiParticipation/table',
    [DatalabScreens.CAPITAL_RAISED_MARKET]: '', // no table endpoint
    [DatalabScreens.CONVERTS]: '/dataLabs/converts/table',
    [DatalabScreens.ATM]: '/dataLabs/atm/table',
    [DatalabScreens.GLOBAL_ECM]: '/dataLabs/marketPulse/table',
    [DatalabScreens.GLOBAL_LEAGUE]: '/dataLabs/globalLeague/table',
  };

  return mapping[screen];
};

export const getReportTable = async (
  screen: DatalabScreens,
  legacyRequestBody: DatalabRequestDto
) => {
  const requestBody = mapLegacyRequestBodyToDlgw(legacyRequestBody);
  const response = await dlgwApiClient
    .post<DLGWDataLabTableRequest, ApiResponse<DLGWTable>>(
      mapScreenToApiPath(screen),
      requestBody,
      {
        // @ts-ignore
        apiCallId: 'DATALAB_API_CALL_ID',
      }
    )
    .then(resp => ({
      ...resp,
      data: resp.ok ? mapDlgwResponseBodyToLegacy(resp.data) : resp.data,
    }));

  return response;
};
