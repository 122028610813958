import React from 'react';
import { useTheme } from 'styled-components/macro';

import Icon from '../../../../components/graphics/icon/Icon';
import { Severity } from './Alert.model';
import {
  SActionContainer,
  SContainer,
  SContentContainer,
  SDetails,
  SIconContainer,
  STitle,
} from './Alert.styles';

export type Props = {
  id?: string;
  testId?: string;
  action?: React.ReactNode;
  severity: Severity;
  withMargin?: boolean;
};

const Alert: React.FC<Props> = ({ id, testId, severity, children, action, withMargin = false }) => {
  const theme = useTheme();

  return (
    <SContainer
      id={id}
      data-test-id={testId}
      severity={severity}
      withMargin={withMargin}
      role="alert"
    >
      <SIconContainer>
        {severity === 'success' && (
          <Icon
            variant="solid"
            name="check-circle"
            color={theme.foundation.colors.success['300']}
          />
        )}
        {severity === 'error' && (
          <Icon variant="solid" name="times-circle" color={theme.foundation.colors.error['300']} />
        )}
        {severity === 'warning' && (
          <Icon
            variant="solid"
            name="exclamation-circle"
            color={theme.foundation.colors.warning['200']}
          />
        )}
        {severity === 'info' && (
          <Icon variant="solid" name="info-circle" color={theme.foundation.colors.info['300']} />
        )}
      </SIconContainer>
      <SContentContainer>{children}</SContentContainer>
      {action && (
        <SActionContainer>{typeof action === 'function' ? action() : action}</SActionContainer>
      )}
    </SContainer>
  );
};

export default Object.assign(Alert, { Title: STitle, Details: SDetails });
