import React from 'react';

import { OfferingManagerRole } from '../../../../../../graphql/__generated__/index';
import { formatManagerRowFn, managerSortFn } from '../../../../../shared/model/utils';
import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import { ManagerSectionData } from '../types';

export const useGetManagersSectionData = (
  offeringProfile: OfferingProfile_ListQuery
): ManagerSectionData =>
  React.useMemo(() => {
    const { managers = [], attributes, pricingCurrency } = offeringProfile.offeringById ?? {};
    const { totalManagers, totalBookrunners, totalNonBookrunners } = attributes ?? {};
    const bookrunners = managers
      .filter(({ role }) => {
        return (
          role === OfferingManagerRole.Bookrunner || role === OfferingManagerRole.ActiveBookrunner
        );
      })
      .sort(managerSortFn)
      .map(manager => formatManagerRowFn(manager, pricingCurrency));

    const nonBookrunners = managers
      .filter(({ role }) => {
        return (
          role !== OfferingManagerRole.Bookrunner && role !== OfferingManagerRole.ActiveBookrunner
        );
      })
      .sort(managerSortFn)
      .map(manager => formatManagerRowFn(manager, pricingCurrency));

    return {
      bookrunners,
      nonBookrunners,
      totalManagers,
      totalBookrunners,
      totalNonBookrunners,
    };
  }, [offeringProfile.offeringById]);
