import type { SnackbarOrigin } from 'notistack';

import { ErrorSnack } from './variants/ErrorSnack';
import { InfoSnack } from './variants/InfoSnack';
import { SuccessSnack } from './variants/SuccessSnack';
import { WarningSnack } from './variants/WarningSnack';

export const defaultComponents = {
  default: InfoSnack,
  info: InfoSnack,
  success: SuccessSnack,
  error: ErrorSnack,
  warning: WarningSnack,
} as const;

export const defaultMaxSnack = 3;
export const defaultAnchorOrigin: SnackbarOrigin = { vertical: 'top', horizontal: 'right' };

export type SnackbarVariants = Exclude<keyof typeof defaultComponents, 'default'>;
