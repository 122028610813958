import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  RegularBreakpoints,
  Typography,
} from '@cmg/design-system';

import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import OfferingDetailRowItem from '../../shared/OfferingDetailRowItem';
import {
  cardContentStyle,
  cardHeaderStyle,
  cardHeight,
  cardTitleStyle,
  dividerStyle,
} from '../../shared/OfferingProfileCardItem';
import { useGetPerformanceData } from '../AtmProfile.model';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
};

const listSectionSizes: RegularBreakpoints = { xl: 12, lg: 12, md: 6, sm: 12 };

const PerformanceWidget: React.FC<Props> = ({ offeringProfile, cardSizes }) => {
  const performance = useGetPerformanceData(offeringProfile);
  const title = 'Performance';
  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight}>
        <Divider sx={dividerStyle} />
        <CardHeader
          title={
            <Typography variant="h3" sx={cardTitleStyle}>
              {title}
            </Typography>
          }
          aria-label={title}
          sx={cardHeaderStyle}
        />
        <CardContent sx={cardContentStyle}>
          <Grid container alignItems="baseline">
            <Grid container item {...listSectionSizes}>
              {performance.slice(0, 5).map(({ name, value }) => {
                return <OfferingDetailRowItem key={name} label={name} value={value} />;
              })}
            </Grid>
            <Grid container item {...listSectionSizes}>
              {performance.slice(5).map(({ name, value }) => {
                return <OfferingDetailRowItem key={name} label={name} value={value} />;
              })}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default PerformanceWidget;
