import { RowNode } from 'ag-grid-community';

import { dateTimeUtil, numericUtil } from '../../../../../util';
import { ValueFormatterParams } from '../BaseAgGrid.model';

const { getDisplayValueForInteger, getDisplayValueForCurrency, getDisplayValueForPercents } =
  numericUtil;
const { getDisplayValueForDate, getDisplayValueForDateAndTime } = dateTimeUtil;

type CommonFormatterParams = {
  shouldFormatPinnedRow?: boolean;
};
type DecimalFormatterParams = CommonFormatterParams & { precision?: number };

const valueFormatter = (
  { shouldFormatPinnedRow }: CommonFormatterParams,
  node: RowNode | null,
  formatterFunc: () => string
) => {
  if (shouldFormatPinnedRow || !node?.isRowPinned()) {
    return formatterFunc();
  }
  return '';
};

/**
 * Gets ag-grid formatter function for formatting integers
 * @param params Includes option `shouldFormatPinnedRow` that indicates whether the pinned row should be formatted
 * @returns formatter function that returns integer string or dash (-) when value is null
 */
export const getIntegerValueFormatter =
  (params: CommonFormatterParams = { shouldFormatPinnedRow: false }) =>
  <TData,>({ value, node }: ValueFormatterParams<TData, number>) =>
    valueFormatter(params, node, () => getDisplayValueForInteger(value));

/**
 * Gets ag-grid formatter function for formatting percents
 * @param params Includes option `shouldFormatPinnedRow` that indicates whether the pinned row should be formatted and `precision` according which should the number be formatted
 * @returns formatter function that returns percentage string or dash (-) when value is null
 */
export const getPercentsValueFormatter =
  ({ shouldFormatPinnedRow = false, precision = 4 }: DecimalFormatterParams = {}) =>
  <TData,>({ value, node }: ValueFormatterParams<TData, number>) =>
    valueFormatter({ shouldFormatPinnedRow }, node, () =>
      getDisplayValueForPercents(value, precision)
    );

/**
 * Gets ag-grid formatter function for formatting currency
 * @param params Includes option `shouldFormatPinnedRow` that indicates whether the pinned row should be formatted and `precision` according which should the number be formatted
 * @returns formatter function that returns currency string or dash (-) when value is null
 */
export const getCurrencyValueFormatter =
  ({ shouldFormatPinnedRow = false, precision = 2 }: DecimalFormatterParams = {}) =>
  <TData,>({ value, node }: ValueFormatterParams<TData, number>) =>
    valueFormatter({ shouldFormatPinnedRow }, node, () =>
      getDisplayValueForCurrency(value, precision)
    );

/**
 * Gets ag-grid formatter function for formatting date
 * @param params Includes option `shouldFormatPinnedRow` that indicates whether the pinned row should be formatted
 * @returns formatter function that returns date string or dash (-) when value is null
 */
export const getDateValueFormatter =
  (params: CommonFormatterParams = { shouldFormatPinnedRow: false }) =>
  <TData,>({ value, node }: ValueFormatterParams<TData, string>) =>
    valueFormatter(params, node, () => getDisplayValueForDate(value));

/**
 * Gets ag-grid formatter function for formatting date and time
 * @param params Includes option `shouldFormatPinnedRow` that indicates whether the pinned row should be formatted
 * @returns formatter function that returns datetime string or dash (-) when value is null
 */
export const getDateTimeValueFormatter =
  (params: CommonFormatterParams = { shouldFormatPinnedRow: false }) =>
  <TData,>({ value, node }: ValueFormatterParams<TData, string>) =>
    valueFormatter(params, node, () => getDisplayValueForDateAndTime(value));

/**
 * Gets ag-grid formatter function for formatting string
 * @param params Includes option `shouldFormatPinnedRow` that indicates whether the pinned row should be formatted
 * @returns formatter function that returns string or dash (-) when value is null
 */
export const getStringValueFormatter =
  (params: CommonFormatterParams = { shouldFormatPinnedRow: false }) =>
  <TData,>({ value, node }: ValueFormatterParams<TData, string>) =>
    valueFormatter(params, node, () => value ?? '-');
