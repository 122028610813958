import styled, { css } from 'styled-components/macro';

export type Props = {
  direction: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  gap?: number;
  justifyContent?: CSSStyleDeclaration['justifyContent'];
  alignItems?: CSSStyleDeclaration['alignItems'];
  fullWidth?: boolean;
  fullHeight?: boolean;
};

export const SFlexContainer = styled.div<Props>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  gap: ${({ gap }) => gap !== undefined && `${gap}px`};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `}
`;

export type DividerProps = { direction?: Props['direction'] };

export const SDivider = styled.div<DividerProps>`
  background: ${({ theme }) => theme.designSystem.colors.gray['100']};

  ${({ direction }) =>
    direction === 'row'
      ? css`
          width: 1px;
          align-self: stretch;
          justify-self: normal;
        `
      : css`
          height: 1px;
          align-self: normal;
          justify-self: stretch;
        `}
`;

export type SectionProps = { flexGrow?: CSSStyleDeclaration['flexGrow'] };

export const SSection = styled.div<SectionProps>`
  flex-grow: ${({ flexGrow }) => flexGrow};
`;

export default Object.assign({}, SFlexContainer, { Divider: SDivider }, { Section: SSection });
