import { numericUtil } from '@cmg/common';
import React from 'react';

import PerformancePercentsWithTooltip from '../../../../../common/components/format/performance-percents-with-tooltip/PerformancePercentsWithTooltip';
import { AdviserRole, SecurityType } from '../../../../../graphql/__generated__/index';
import {
  getFormattedBooleanValue,
  getFormattedCurrencyInMillion,
  getFormattedCurrencyValue,
  getFormattedDateValue,
  getFormattedPercentageValue,
} from '../../../../shared/model/utils';
import { OfferingProfile_ListQuery } from '../../graphql/__generated__/OfferingProfile';
import {
  CardRowItem,
  emptyValue,
  OfferingPerformanceFieldName,
  OfferingProfileFieldName,
  OfferingProfileStructureFieldName,
  OfferingUnderwritingTermsFieldName,
} from '../shared/types';

export const useGetStructureData = (
  offeringProfile: OfferingProfile_ListQuery,
  showInternational: boolean
): CardRowItem[] =>
  React.useMemo(() => {
    const { hasForwardAgreement, attributes, pricingCurrency } = offeringProfile.offeringById ?? {};

    return [
      {
        name: OfferingProfileStructureFieldName.LatestSizeInSecuritiesTotal,
        value: attributes?.latestSizeInSecuritiesTotal
          ? numericUtil.getDisplayValueForNumber(attributes?.latestSizeInSecuritiesTotal, 0)
          : emptyValue,
      },
      {
        name: OfferingProfileStructureFieldName.LatestGrossProceedsTotal,
        value: attributes?.sizeUsd
          ? getFormattedCurrencyInMillion(pricingCurrency, attributes.sizeUsd)
          : emptyValue,
      },
      {
        name: OfferingProfileStructureFieldName.PreOfferingMktCap,
        value: attributes?.marketCapPreOffering
          ? getFormattedCurrencyInMillion(pricingCurrency, attributes.marketCapPreOffering)
          : emptyValue,
      },
      {
        name: OfferingProfileStructureFieldName.PctPreOfferingMarketCap,
        value: attributes?.pctMarketCapPreOffering
          ? getFormattedPercentageValue(attributes.pctMarketCapPreOffering)
          : emptyValue,
      },
      {
        name: OfferingProfileStructureFieldName.DayAdtv30,
        value: attributes?.preOfferingAdtv
          ? numericUtil.getDisplayValueForNumber(attributes.preOfferingAdtv, 0)
          : emptyValue,
      },
      {
        name: OfferingProfileStructureFieldName.LastTradeBeforeFiling,
        value: attributes?.lastTradeBeforeFiling
          ? getFormattedCurrencyValue({
              value: attributes.lastTradeBeforeFiling,
              pricingCurrencyCode: pricingCurrency as string,
              showInternational,
            })
          : emptyValue,
      },
      {
        name: OfferingProfileStructureFieldName.ForwardSale,
        value: getFormattedBooleanValue(hasForwardAgreement),
      },
    ];
  }, [offeringProfile.offeringById, showInternational]);

export const useGetTimingData = (offeringProfile: OfferingProfile_ListQuery): CardRowItem[] =>
  React.useMemo(() => {
    const { publicFilingDate, marketTimingDisplayName, terminatedDate } =
      offeringProfile.offeringById ?? {};
    return [
      {
        name: OfferingProfileFieldName.PublicFilingDate,
        value: getFormattedDateValue(publicFilingDate),
      },
      {
        name: OfferingProfileFieldName.PublicFilingTime,
        value: marketTimingDisplayName ?? emptyValue,
      },
      {
        name: OfferingProfileFieldName.TerminationDate,
        value: getFormattedDateValue(terminatedDate),
      },
    ];
  }, [offeringProfile.offeringById]);

export const useGetCompanySecurityData = (
  offeringProfile: OfferingProfile_ListQuery
): CardRowItem[] =>
  React.useMemo(() => {
    const {
      securityType,
      securityTypeDisplayName,
      issuer,
      typeDisplayName,
      exchangeMic,
      exchangeRegion,
      exchangeCountry,
      pricingCurrency,
    } = offeringProfile.offeringById ?? {};

    const securityTypeDisplay =
      securityType === SecurityType.ConvertibleNote ? 'Note' : securityTypeDisplayName;

    return [
      {
        name: OfferingProfileFieldName.Issuer,
        value: issuer?.name ?? emptyValue,
      },
      {
        name: OfferingProfileFieldName.Type,
        value: typeDisplayName ?? emptyValue,
      },
      {
        name: OfferingProfileFieldName.SecurityType,
        value: securityTypeDisplay ?? emptyValue,
      },
      {
        name: OfferingProfileFieldName.Sector,
        value: issuer?.sectorDisplayName ?? emptyValue,
      },
      {
        name: OfferingProfileFieldName.Symbol,
        value: issuer?.primarySymbol ?? emptyValue,
      },
      {
        name: OfferingProfileFieldName.Exchange,
        value: exchangeMic ?? emptyValue,
      },
      {
        name: OfferingProfileFieldName.ExchangeRegionCountry,
        value: `${exchangeRegion ?? emptyValue}/${exchangeCountry ?? emptyValue}`,
      },
      {
        name: OfferingProfileFieldName.Currency,
        value: pricingCurrency ?? emptyValue,
      },
    ];
  }, [offeringProfile]);

export const useGetAdvisorsData = (offeringProfile: OfferingProfile_ListQuery): CardRowItem[] =>
  React.useMemo(() => {
    const { advisers = [] } = offeringProfile.offeringById ?? {};
    const getAdviserNameByRole = (adviserRole: AdviserRole) => {
      const advisersByRole =
        advisers?.filter(({ role }) => {
          return role === adviserRole;
        }) ?? [];
      return advisersByRole.length > 0
        ? advisersByRole.map(({ name }) => name).join(', ')
        : emptyValue;
    };
    return [
      {
        name: OfferingProfileFieldName.UnderwriterCounsel,
        value: getAdviserNameByRole(AdviserRole.UnderwriterCounsel),
      },
      {
        name: OfferingProfileFieldName.IssuerCounsel,
        value: getAdviserNameByRole(AdviserRole.IssuerCounsel),
      },
      {
        name: OfferingProfileFieldName.IssuerAuditor,
        value: getAdviserNameByRole(AdviserRole.IssuerAuditor),
      },
    ];
  }, [offeringProfile.offeringById]);

export const useUnderwritingTerms = (offeringProfile: OfferingProfile_ListQuery): CardRowItem[] =>
  React.useMemo(() => {
    const { atmAttributes } = offeringProfile.offeringById ?? {};

    return [
      {
        name: OfferingUnderwritingTermsFieldName.GrossSpreadPct,
        value: atmAttributes?.pctGrossSpread
          ? getFormattedPercentageValue(atmAttributes?.pctGrossSpread)
          : emptyValue,
      },
    ];
  }, [offeringProfile.offeringById]);

export const useGetPerformanceData = (offeringProfile: OfferingProfile_ListQuery): CardRowItem[] =>
  React.useMemo(() => {
    const {
      pctOfferToOpen,
      pctOfferTo1Day,
      pctOfferTo3Day,
      pctOfferTo7Day,
      pctOfferTo14Day,
      pctOfferTo30Day,
      pctOfferTo90Day,
      pctOfferTo180Day,
      pctOfferToCurrent,
      openingSharePrice,
      day1SharePrice,
      day3SharePrice,
      day7SharePrice,
      day14SharePrice,
      day30SharePrice,
      day90SharePrice,
      day180SharePrice,
      currentSharePrice,
    } = offeringProfile.offeringById?.attributes ?? {};
    const pricingCurrencyCode = 'USD';
    return [
      {
        name: OfferingPerformanceFieldName.pctOfferToOpen,
        value: (
          <PerformancePercentsWithTooltip
            value={pctOfferToOpen}
            nullValue={emptyValue}
            precision={2}
            tooltip={`Opening Price: ${getFormattedCurrencyValue({
              value: openingSharePrice,
              pricingCurrencyCode,
            })}`}
          />
        ),
      },
      {
        name: OfferingPerformanceFieldName.pctOfferTo1Day,
        value: (
          <PerformancePercentsWithTooltip
            value={pctOfferTo1Day}
            nullValue={emptyValue}
            precision={2}
            tooltip={`1 Day Close Price: ${getFormattedCurrencyValue({
              value: day1SharePrice,
              pricingCurrencyCode,
            })}`}
          />
        ),
      },
      {
        name: OfferingPerformanceFieldName.pctOfferTo3Day,
        value: (
          <PerformancePercentsWithTooltip
            value={pctOfferTo3Day}
            nullValue={emptyValue}
            precision={2}
            tooltip={`3 Day Close Price: ${getFormattedCurrencyValue({
              value: day3SharePrice,
              pricingCurrencyCode,
            })}`}
          />
        ),
      },
      {
        name: OfferingPerformanceFieldName.pctOfferTo7Day,
        value: (
          <PerformancePercentsWithTooltip
            value={pctOfferTo7Day}
            nullValue={emptyValue}
            precision={2}
            tooltip={`7 Day Close Price: ${getFormattedCurrencyValue({
              value: day7SharePrice,
              pricingCurrencyCode,
            })}`}
          />
        ),
      },
      {
        name: OfferingPerformanceFieldName.pctOfferTo14Day,
        value: (
          <PerformancePercentsWithTooltip
            value={pctOfferTo14Day}
            nullValue={emptyValue}
            precision={2}
            tooltip={`14 Day Close Price: ${getFormattedCurrencyValue({
              value: day14SharePrice,
              pricingCurrencyCode,
            })}`}
          />
        ),
      },
      {
        name: OfferingPerformanceFieldName.pctOfferTo30Day,
        value: (
          <PerformancePercentsWithTooltip
            value={pctOfferTo30Day}
            nullValue={emptyValue}
            precision={2}
            tooltip={`30 Day Close Price: ${getFormattedCurrencyValue({
              value: day30SharePrice,
              pricingCurrencyCode,
            })}`}
          />
        ),
      },
      {
        name: OfferingPerformanceFieldName.pctOfferTo90Day,
        value: (
          <PerformancePercentsWithTooltip
            value={pctOfferTo90Day}
            nullValue={emptyValue}
            precision={2}
            tooltip={`90 Day Close Price: ${getFormattedCurrencyValue({
              value: day90SharePrice,
              pricingCurrencyCode,
            })}`}
          />
        ),
      },
      {
        name: OfferingPerformanceFieldName.pctOfferTo180Day,
        value: (
          <PerformancePercentsWithTooltip
            value={pctOfferTo180Day}
            nullValue={emptyValue}
            precision={2}
            tooltip={`180 Day Close Price: ${getFormattedCurrencyValue({
              value: day180SharePrice,
              pricingCurrencyCode,
            })}`}
          />
        ),
      },
      {
        name: OfferingPerformanceFieldName.pctOfferToCurrent,
        value: (
          <PerformancePercentsWithTooltip
            value={pctOfferToCurrent}
            nullValue={emptyValue}
            precision={2}
            tooltip={`Last Close Price: ${getFormattedCurrencyValue({
              value: currentSharePrice,
              pricingCurrencyCode,
            })}`}
          />
        ),
      },
    ];
  }, [offeringProfile.offeringById?.attributes]);
