/**
 * Defines all variables and mappings that are common to many project components.
 *
 * Usage: The theme is provided through styled-components theme and can also be
 * imported directly where needed.
 */
import { ColorPalette } from '../foundation/color';

const color = {
  brown1: '#9c640c',
  pink1: '#c39bd3',
  violet1: '#9b59b6',
  gray1: '#e2e2e2',
  gray2: '#eff1f5',
  gray3: '#88939a',
  gray4: '#bfbfbf',
  gray5: '#3e4d64',
  gray6: '#bac0c4',
  gray7: '#cccccc',
  gray8: '#999999',
  gray9: '#333333',
  gray10: '#cedce3',
  gray11: '#4c5053',
  gray12: '#a9aeb8',
  black: '#000000',
  white: '#ffffff',
  red1: '#f44336',
  red2: '#fa8080',
  red3: '#f22516',
  red4: '#ff8f83',
  red5: '#e5534c',
  blue1: '#5eb8e6',
  blue2: '#38aae5',
  blue3: '#233249',
  blue4: '#1e2b3e',
  blue5: '#6495ed',
  blue6: '#dfecff',
  blue7: '#32a5df',
  blue8: '#13879a',
  blue9: '#0073ee',
  blue11: '#17a2b8',
  blue12: '#5a687e',
  blue13: '#03a9f4',
  blue14: '#4085f7',
  blue15: '#edf5ff',
  blue16: '#91daff',
  blue17: '#3ab1ef',
  blue18: '#1978a9',
  blue19: '#0c5074',
  green1: '#69d18e',
  green2: '#53e2af',
  green3: '#4fc97a',
  green4: '#4caf50',
  green5: '#91dcac',
  green6: '#4ce687',
  green7: '#1bbb58',
  green8: '#48c9b0',
  yellow1: '#f5b800',
  yellow2: '#ffc107',
  yellow3: '#f4b278',
  yellow4: '#f0efb7',
  yellow5: '#f5b041',
};

const designSystemColors: { [key: string]: ColorPalette } = {
  darkBlue: {
    '000': '#F0F6FF',
    '100': '#DFECFF',
    '200': '#3E4D64',
    '300': '#233249',
    '400': '#1D293C',
    '500': '#151D29',
  },
  blue: {
    '000': '#CDEAF8',
    '100': '#9CD5F2',
    '200': '#6ABFEC',
    '300': '#38AAE5',
    '400': '#1A8ECA',
    '500': '#146A97',
  },
  red: {
    '000': '#FEEDEB',
    '100': '#FAA19B',
    '200': '#F77268',
    '300': '#F44336',
    '400': '#E21B0C',
    '500': '#AA1409',
  },
  green: {
    '000': '#EEFAF2',
    '100': '#A7E4BD',
    '200': '#7BD69B',
    '300': '#4FC97A',
    '400': '#35AB5E',
    '500': '#278147',
  },
  yellow: {
    '000': '#FFF9E7',
    '100': '#FFE083',
    '200': '#FFD145',
    '300': '#FFC107',
    '400': '#DAA400',
    '500': '#AF8300',
  },
  turquoise: {
    '000': '#E8F3F5',
    '100': '#9BE6F3',
    '200': '#19B3CC',
    '300': '#13879A',
    '400': '#117687',
    '500': '#0C5460',
  },
  gray: {
    '000': '#EFF1F5',
    '100': '#DFDFDF',
    '200': '#CFCFCF',
    '300': '#BFBFBF',
    '400': '#9F9F9F',
    '500': '#7F7F7F',
  },
};

// CMGs brand colors, used for multiple purposes from text to borders to backgrounds
const brand = {
  color: {
    light: '#007AC2',
    lightAlternate: '#00629D',
    dark: color.blue3,
    darkAlternate: color.blue4,
    success: color.green1,
    successAlternate: color.green3,
    danger: color.red1,
    dangerAlternate: color.red3,
    warning: color.yellow2,
    warningAlternate: color.yellow1,
    info: color.blue11,
    infoAlternate: color.blue8,
    error: color.red3,
    infoBanner: 'rgba(19, 135, 154, 0.1)',
  },
};

// A bridge to get the legacy design system interactive elements looking similar to MUI design system interactive elements
const interactive = {
  primary: '#007AC2',
  primaryComplement: '#00629D',
  primaryContrast: '#FFFFFF',
  primaryTranslucent1: 'rgba(0, 122, 194, 0.65)',
  primaryTranslucent2: 'rgba(0, 122, 194, 0.04)',

  // TODO rename from light to high-contrast and move to high-contrast nested object below
  primaryLight: '#48B2FA',
  primaryLightComplement: '#1E97E8',
  primaryLightContrast: '#FFFFFF',

  danger: '#DD3730',
  dangerComplement: '#BA1B1B',
  dangerContrast: '#FFFFFF',

  success: '#008940',
  successComplement: '#006D31',
  successContrast: '#FFFFFF',

  disabled: 'rgba(35,50,73,0.09)',
  disabledContrast: 'rgba(35,50,73,0.4)',

  // for use in components that support high-contrast variants
  highContrast: {
    disabled: 'rgba(167, 173, 182, 0.6)',
    disabledContrast: 'rgba(235, 236, 238, 0.8)',
  },
};

const chart = {
  bar: {
    background: {
      color: {
        lighter: color.blue16,
        light: color.blue17,
        dark: color.blue18,
        darker: color.blue19,
        lightAlpha50: 'rgba(58, 177, 239, 0.5)',
        green: color.green5,
        greenAlpha50: 'rgba(145, 220, 172, 0.5)',
      },
    },
  },
  radar: {
    background: {
      color: {
        white: color.white,
      },
    },
    text: {
      color: {
        dark: color.black,
      },
    },
    border: {
      color: {
        green: color.green5,
      },
    },
  },
};

// todo refer brand
const text = {
  color: {
    placeholder: color.gray4,
    dark: color.black,
    darkGray: color.gray5,
    disabled: color.gray1,
    light: color.gray3,
    white: color.white,
    error: color.red1,
    warning: color.yellow2,
    positive: color.green1,
    negative: color.red2,
    link: interactive.primary,
    linkLight: interactive.primaryLight,
  },

  // TODO: unite font sizes according to new design system
  size: {
    xxs: '10px',
    xs: '12px',
    s: '14px',
    m: '16px',
    l: '18px',
    xl: '24px',
    xxl: '32px',
    //
    tiny: '10px',
    small: '11.5px',
    mediumSmall: '13px',
    medium: '14px',
    mediumLarge: '17px',
    large: '22px',
    xlarge: '30px',
    billboard: '52px',
  },

  weight: {
    thin: 100,
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
  },

  family: {
    regular: "'Roboto', sans-serif, 'Helvetica Neue', Helvetica, Arial;",
    slab: "'Roboto Slab', sans-serif, 'Helvetica Neue', Helvetica, Arial;",
    code: 'Menlo, Consolas, monospace',
  },
};

const background = {
  color: {
    dark: designSystemColors.darkBlue[300],
    light: color.gray2,
    highlighted: designSystemColors.darkBlue[100],
    white: color.white,
    positive: color.green1,
    negative: designSystemColors.red[300],
    info: color.blue11,
    warning: designSystemColors.yellow[300],
    menu: designSystemColors.darkBlue[200],
    processing: designSystemColors.turquoise[300],
  },
};

const border = {
  color: {
    light: color.gray2,
    dark: color.gray1,
    error: color.red1,
    white: color.white,
  },
  radius: {
    small: '3px',
    medium: '5px',
    large: '8px',
    xxl: '15px',
  },
  width: {
    small: '1px',
    medium: '3px',
  },
  smallSolidLight: `1px solid ${color.gray2}`,
  smallSolidDark: `1px solid ${color.gray1}`,
};

/**
 * Synching with [MUI](https://mui.com/material-ui/customization/z-index/) to avoid any bugs with feature-toggles.
 * It's not a 1:1, but its intent its to close the gap between MUI & common while keeping backwards compatibility.
 */
const zIndex = {
  selectMenu: 1500, // old virtualized table header in ECM app has z-index: 10
  header: 1100,
  modal: 1300,
  notificationsSlideout: 1304, // notifications slideout is below .cmg-popover at 1055
  popover: 1500, // match MUI tooltip.
  screenOverlay: 1200,
  contentOverlay: 1201,
  responsiveDrawer: 1210,
  toast: 1400, // defaults to 1000, match MUI snackbar at 1400
};

// Adjust styles based on browser viewport width
// Note: These breakpoints are not final and can be adjusted. They were taken from bootstrap.
const breakpoint = {
  small: 576,
  medium: 768,
  large: 992,
  xlarge: 1200,
  xxlarge: 1440,
};

// Conveniences to use directly interpolate in a styled-component
const mediaQuery = {
  smallUp: `@media (min-width: ${breakpoint.small}px)`,
  mediumUp: `@media (min-width: ${breakpoint.medium}px)`,
  largeUp: `@media (min-width: ${breakpoint.large}px)`,
  xlargeUp: `@media (min-width: ${breakpoint.xlarge}px)`,
  xxlargeUp: `@media (min-width: ${breakpoint.xxlarge}px)`,
  smallDown: `@media (max-width: ${breakpoint.small - 1}px)`,
  mediumDown: `@media (max-width: ${breakpoint.medium - 1}px)`,
  largeDown: `@media (max-width: ${breakpoint.large - 1}px)`,
  xlargeDown: `@media (max-width: ${breakpoint.xlarge - 1}px)`,
  xxlargeDown: `@media (max-width: ${breakpoint.xxlarge - 1}px)`,
};

const table = {
  cell: {
    height: '26px',
  },
};

const designSystem = {
  colors: designSystemColors,
};

export const commonTheme = {
  designSystem,
  breakpoint,
  brand,
  color,
  text,
  background,
  border,
  zIndex,
  mediaQuery,
  table,
  chart,

  /**
   * Below are concrete composite styles. Any composite style should be reusable across multiple
   * components. Otherwise, use the variables above directly in your components.
   */

  form: {
    field: {
      // UX is still WIP
      radio: {
        baseColor: interactive.primary,
        hoverColor: interactive.primary,
        mouseDownColor: interactive.primary,
        backgroundColor: interactive.primaryContrast,
      },
      pill: {
        baseColor: color.blue2,
      },
    },
    control: {
      errorColor: color.red1,
      height: '34px',
      borderRadius: '4px',
      borderColor: color.gray1,
      placeholderColor: color.gray4,
    },
  },

  // A bridge to get the legacy design system interactive elements looking similar to MUI design system interactive elements
  interactive,
};
