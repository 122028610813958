import {
  DLGWCalendarOffering,
  DLGWCornerstoneInvestmentType,
} from '@capital-markets-gateway/api-client-datalab-gateway';
import React from 'react';

import { formatNullableBoolean } from '../../../../../common/helpers/helpers';
import { getFormattedCurrencyInMillion } from '../../../model/utils';
import { HeaderLabel, STooltipContent, StyledPopover } from './CornerstoneTooltip.styles';
import CornerstoneTooltipTableContent from './CornerstoneTooltipTableContent';

export const CornerstoneTooltip: React.FC<DLGWCalendarOffering> = ({
  cornerstoneTotalAmount,
  cornerstoneInvestorsTotalCount,
  cornerstoneInvestorsPrivatePlacementCount,
  cornerstonePrivatePlacementAmount,
  cornerstoneInvestments,
  cornerstoneOfferingParticipantsAmount,
  cornerstoneInvestorsOfferingParticipantsCount,
  hasCornerstoneInvestors,
  pricingCurrencyCode,
  ...props
}) => {
  const offeringParticipants =
    cornerstoneInvestments?.filter(
      c => c.type === DLGWCornerstoneInvestmentType.CORNERSTONE_INVESTMENT_TYPE_OFFERING_PARTICIPANT
    ) ?? [];

  const privatePlacement =
    cornerstoneInvestments?.filter(
      c => c.type === DLGWCornerstoneInvestmentType.CORNERSTONE_INVESTMENT_TYPE_PRIVATE_PLACEMENT
    ) ?? [];

  if (!hasCornerstoneInvestors) {
    return <span>{formatNullableBoolean(hasCornerstoneInvestors)}</span>;
  }

  return (
    <StyledPopover
      content={
        <STooltipContent>
          <thead>
            <tr>
              <HeaderLabel className="cornerstone-header">
                {`Cornerstone Investors (${cornerstoneInvestorsTotalCount})`}
                <div className="subtitle">
                  Cornerstone Investment Total
                  <span>
                    {getFormattedCurrencyInMillion(pricingCurrencyCode, cornerstoneTotalAmount)}
                  </span>
                </div>
              </HeaderLabel>
            </tr>
          </thead>
          <CornerstoneTooltipTableContent
            {...props}
            cornerstoneInvestments={offeringParticipants}
            cornerstoneInvestorsCount={cornerstoneInvestorsOfferingParticipantsCount}
            cornerstoneOfferingAmount={cornerstoneOfferingParticipantsAmount}
            investmentType={
              DLGWCornerstoneInvestmentType.CORNERSTONE_INVESTMENT_TYPE_OFFERING_PARTICIPANT
            }
            pricingCurrencyCode={pricingCurrencyCode}
          />
          <CornerstoneTooltipTableContent
            {...props}
            cornerstoneInvestments={privatePlacement}
            cornerstoneInvestorsCount={cornerstoneInvestorsPrivatePlacementCount}
            cornerstoneOfferingAmount={cornerstonePrivatePlacementAmount}
            investmentType={
              DLGWCornerstoneInvestmentType.CORNERSTONE_INVESTMENT_TYPE_PRIVATE_PLACEMENT
            }
            pricingCurrencyCode={pricingCurrencyCode}
          />
        </STooltipContent>
      }
    >
      {formatNullableBoolean(hasCornerstoneInvestors)}
    </StyledPopover>
  );
};
