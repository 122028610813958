import 'rc-steps/assets/iconfont.css';
import 'rc-steps/assets/index.css';

import { Box, timeUtil } from '@cmg/common';
import React from 'react';

import { timingStateLabels } from '../../model/timings.model';
import { highlightStep, Timing } from './WidgetTiming.model';
import { SDay, StyledBox, StyledStep, StyledSteps } from './WidgetTiming.styles';

export type Props = {
  timing: Timing[];
};

const WidgetTiming: React.FC<Props> = ({ timing }) => (
  <StyledBox>
    <Box.Header title="Timing" />
    <Box.Content>
      <StyledSteps labelPlacement="vertical" progressDot>
        {timing.map((t, idx) => {
          return (
            <StyledStep
              withWarning={highlightStep(t.status)}
              title={timingStateLabels[t.status]}
              description={
                <div>
                  <SDay>{timeUtil.formatAsDay(t.date) ?? 'N/A'}</SDay>
                  {timeUtil.formatAsMonthAbbreviationAndYear(t.date)}
                </div>
              }
              key={idx}
            />
          );
        })}
      </StyledSteps>
    </Box.Content>
  </StyledBox>
);

export default WidgetTiming;
