import type { AutocompleteProps as MUIAutocompleteProps } from '@mui/material/Autocomplete';
import MUIAutocomplete from '@mui/material/Autocomplete';
import type { ChipTypeMap } from '@mui/material/Chip';
import type { InputProps } from '@mui/material/Input';
import type { TextFieldProps as MUITextFieldProps } from '@mui/material/TextField';
import TextField from '@mui/material/TextField';
import React from 'react';

export type AutocompleteTextFieldProps = Pick<
  MUITextFieldProps,
  'required' | 'name' | 'label' | 'placeholder' | 'error' | 'helperText'
> & { InputProps?: Pick<InputProps, 'startAdornment' | 'autoFocus'> };

export type AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
> = Omit<
  MUIAutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>,
  'renderInput'
> & {
  TextFieldProps: AutocompleteTextFieldProps;
};

export const Autocomplete = React.forwardRef(({ TextFieldProps, ...restProps }, ref) => {
  return (
    <MUIAutocomplete
      {...restProps}
      ref={ref}
      renderInput={params => (
        <TextField
          {...params}
          {...TextFieldProps}
          InputLabelProps={{ disableAnimation: true, shrink: true }}
          InputProps={{ ...params.InputProps, ...(TextFieldProps.InputProps ?? {}) }}
        />
      )}
    />
  );
}) as <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(
  props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> & {
    ref?: React.ForwardedRef<HTMLUListElement>;
  }
) => JSX.Element;
