import styled from 'styled-components/macro';

export const SRow = styled.div`
  display: flex;
  margin-top: 10px;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const SColumn = styled.div`
  flex-basis: 20%;
`;
