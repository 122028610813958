import styled, { css } from 'styled-components/macro';

import LinkButton from '../../buttons/button/LinkButton';

export const SLeafNode = styled.div`
  margin-left: 23px;
`;

export const SSearchWrapper = styled.div`
  margin-bottom: 10px;
`;

export const SMessage = styled.div`
  color: ${({ theme }) => theme.text.color.light};
  text-align: center;
`;

export const SNodeList = styled.div<{ maxHeight?: number }>`
  position: relative;

  ${({ maxHeight, theme }) =>
    maxHeight &&
    css`
      overflow: auto;
      padding-right: 5px;
      max-height: ${maxHeight}px;

      &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
        border-radius: ${theme.border.radius.small};
      }

      &::-webkit-scrollbar-thumb {
        border-radius: ${theme.border.radius.small};
        background-color: ${theme.interactive.primaryLight};
      }
    `}
`;

export const StyledLinkButton = styled(LinkButton).attrs({ variant: 'high-contrast' })`
  padding: 0 8px;
  border-right: 1px solid ${({ theme }) => theme.border.color.light};
  border-radius: 0;

  &:hover {
    // don't let link button make the border transparent
    border-right-color: ${({ theme }) => theme.border.color.light};
  }

  &:last-child {
    border: none;
  }
`;

export const SQuickSelectOptions = styled.div`
  text-align: center;
`;
