import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

/**
 * Hook for reading from and writing to the window locations query parameters
 * @returns
 *  searchParams: memoized URLSearchParams which auto handles decoding
 *  setSearchParam: function that can set a specific query param to a value, triggering a navigation event
 */
export const useSearchParams = () => {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const setSearchParams = useCallback(
    (newSearchParams: URLSearchParams) => {
      history.replace({ search: newSearchParams.toString() });
    },
    [history]
  );

  const setSearchParam = useCallback(
    (paramKey: string, paramValue: string) => {
      const newSearchParams = new URLSearchParams(searchParams.toString());

      newSearchParams.set(paramKey, paramValue);
      setSearchParams(newSearchParams);
    },
    [setSearchParams, searchParams]
  );

  return {
    // Current search params
    searchParams,
    // Update one search param, which will cause a navigation event.
    // warning: If setting multiple search params in the same event tick, use setSearchParams.
    //  This is because the navigation must have had time to complete in order for the hook to
    //  have the latest search params.
    setSearchParam,
    // Update all search params, which will cause a navigation event.
    // todo - consider an option for history.push instead of history.replace if the need arises
    setSearchParams,
  };
};
