import { createTestId } from '../../../common/selectors';

const create = (name: string) => createTestId(`xc-offering-setup-${name}`);

export const offeringSetupSummary = create('summary');

const createOfferingSummarySelector = (name: string) => create(`offering-summary-${name}`);

export const offeringSetupSummaryScreen = createOfferingSummarySelector('screen');

export const offeringSetupSummaryPublishButton = createOfferingSummarySelector('publish-button');
export const offeringSetupSummaryMoreDropdownButton =
  createOfferingSummarySelector('more-dropdown-button');
export const offeringSetupSummaryDiscardButton = createOfferingSummarySelector('discard-button');

export const offeringSetupSummaryShareDraftModal = createOfferingSummarySelector(
  'share-offering-draft-modal'
);

export const offeringSetupSummaryStatusSelect = createOfferingSummarySelector('status-select');

export const offeringSetupSummaryShareDraftButton = createOfferingSummarySelector(
  'share-offering-draft-button'
);

const createOfferingShareDraftModalSelector = (name: string) =>
  create(`offering-share-draft-modal-${name}`);

export const offeringShareDraftManagerGroupCheckbox =
  createOfferingShareDraftModalSelector('manager-group-checkbox');
export const offeringShareDraftManagerItemCheckbox =
  createOfferingShareDraftModalSelector('manager-checkbox');
export const offeringShareDraftShareButton = createOfferingShareDraftModalSelector('share-button');
