// the main import isn't properly declared in TS. we import the main file at its absolute path.
import configure from 'redux-idle-monitor/lib/index';

// These are the default events that will trigger user active status but can be
// customized if provided.
const activeEvents = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mouseWheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
];

// CMG defined idle statuses.
enum IdleStatus {
  AWAY = 'AWAY',
  EXPIRED = 'EXPIRED',
}

// The order in which idle statuses are to be triggered
const idleStatusOrder = [IdleStatus.AWAY, IdleStatus.EXPIRED];

// redux-idle-monitor does not provide types. This is a shim for that.
type IdleReducerState = {
  idleStatus: 'ACTIVE' | IdleStatus; // ACTIVE is built into redux-idle-monitor, while IdleStatus is our own status definitions
  isRunning: boolean;
  isDetectionRunning: boolean;
  isIdle: boolean;
  lastActive: number;
  lastEvent: Object | null;
};
type IdleReducer = (...reducerParams: any) => IdleReducerState;

/**
 * Factory function that creates the necessary redux integration parts to enable user idle monitoring in a SPA.
 *
 * It does this by providing an interface over redux-idle-monitor functionality.
 */
const createIdleMonitor = (params: {
  appName: string; // name that redux-idle-monitor uses to uniquely identify an instance of itself so it can be used across browser tabs
  awayStatusTimeout: number; // number of minutes until user is considered away
  expiredStatusTimeout: number; // number of minutes until user is considered expired, starting from the end of the away timeout
  onTransitionToAway?: (dispatch: Function) => void;
  onTransitionToExpired: (dispatch: Function) => void;
  onTransitionToActive?: (dispatch: Function) => void;
}) => {
  /**
   * Maps one of our defined idle statuses to a number of milliseconds.
   * @param  idleStatus constant that represents an increment of idle time.
   * @return function that does the mapping. redux-idle-monitor uses this internally.
   */
  const idleStatusDelay = (idleStatus: IdleStatus) => () => {
    if (idleStatus === IdleStatus.AWAY) {
      return params.awayStatusTimeout * 1000 * 60; // convert minutes to milliseconds
    }

    if (idleStatus === IdleStatus.EXPIRED) {
      return params.expiredStatusTimeout * 1000 * 60; // convert minutes to milliseconds
    }

    return 0;
  };

  /**
   * Maps idle statuses to redux actions.
   * It will prompt the user if they have been idle long enough that they are away.
   * It will log the user out if they've ignored the modal.
   * @param  idleStatus constant that represents an increment of idle time.
   * @return function that does the mapping. redux-idle-monitor uses this internally.
   */
  const idleStatusAction = (idleStatus: IdleStatus) => (dispatch: Function) => {
    if (idleStatus === IdleStatus.AWAY) {
      // e.g. dispatch show expiration modal
      params.onTransitionToAway && params.onTransitionToAway(dispatch);
    }

    if (idleStatus === IdleStatus.EXPIRED) {
      // e.g. log the user out
      params.onTransitionToExpired(dispatch);
    }
  };

  const reduxIdleMonitorOptions = {
    appName: params.appName,
    IDLE_STATUSES: idleStatusOrder,
    idleStatusDelay,
    activeStatusAction: params.onTransitionToActive ? params.onTransitionToActive : () => null,
    idleStatusAction,
    activeEvents,
  };

  const { middleware: idleMiddleware, reducer, actions } = configure(reduxIdleMonitorOptions);
  const { start: startIdleMonitorAction, stop: stopIdleMonitorAction } = actions;
  const idleReducer = reducer as IdleReducer;

  return { idleMiddleware, idleReducer, startIdleMonitorAction, stopIdleMonitorAction };
};

export default createIdleMonitor;
