const MIXPANEL_ACTION_TYPE = 'MIXPANEL';

/**
 * Returns a Redux action that will trigger a mix panel event
 * when dispatched.
 *
 * @param type - the action type
 * @param eventName - the event name
 * @param props - additional data
 */
const createMixPanelAction = (type: string, eventName: string, props: Object = {}) => ({
  type: MIXPANEL_ACTION_TYPE,
  meta: {
    mixpanel: {
      type,
      eventName,
      props,
    },
  },
});

const mixpanelConfig = {
  cross_subdomain_cookie: false,
  upgrade: true,
  secure_cookie: true,
  property_blacklist: ['$current_url', '$referrer', '$initial_referrer'],
};

const uniqueIdSelector: (getUserId: () => string | null) => string | null = (
  getUserId: () => string | null
) => {
  const userId = getUserId();

  if (userId) {
    const subdomain = window.location.host.split('.')[0];
    return `CMG-${subdomain}-${userId}`;
  }

  return null;
};

const personSelector: (
  getUserId: () => string | null,
  getUserGivenName: () => string | null,
  getUserFamilyName: () => string | null
) => {
  ID?: string | null;
  $first_name?: string | null;
  $last_name?: string | null;
} = (getUserId, getUserGivenName, getUserFamilyName) => {
  const userId = getUserId();
  const userGivenName = getUserGivenName();
  const userFamilyName = getUserFamilyName();

  if (userId) {
    try {
      const subdomain = window.location.host.split('.')[0];
      return {
        ID: `CMG-${subdomain}-${userId}`,
        $first_name: userGivenName,
        $last_name: userFamilyName,
      };
    } catch (err) {
      return {};
    }
  }
  return {};
};

/**
 * Returns an options configuration object used by the
 * remimi mixpanel redux middleware library.
 *
 * A series of selectors are provided as arguments to
 * derive current user information from Redux state.
 *
 * @param getUserId - a getter that returns the user's ID from the auth redux store
 * @param getUserGivenName - a getter that returns the user's given name from the auth redux store
 * @param getUserFamilyName - a getter that returns the user's family name from the auth redux store
 */
const getMixPanelOptions = (
  getUserId: () => string | null,
  getUserGivenName: () => string | null,
  getUserFamilyName: () => string | null
) => ({
  personSelector: (state: Object) => personSelector(getUserId, getUserGivenName, getUserFamilyName),
  uniqueIdSelector: (state: Object) => uniqueIdSelector(getUserId),
  config: mixpanelConfig,
});

const mixpanelUtils = {
  createMixPanelAction,
  getMixPanelOptions,
};

export default mixpanelUtils;
