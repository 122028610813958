import { createTestId } from '../../../../common/selectors';

const create = (name: string) => createTestId(`xc-wires-per-manager-wire-${name}`);

export const wiresSelectedPerManagerScreen = create('selected-screen');
export const wiresEmptyPerManagerScreen = create('empty-screen');
export const wiresSelectManagerScreen = create('select-manager-screen');

export const wiresSelectedPerManagerBanner = create('selected-screen-banner');
export const wiresEmptyPerManagerBanner = create('empty-screen-banner');

export const wiresPerManagerWireEmptyWireEditButton = create('empty-wire-edit-button');

export const wiresPerManagerWireSelectedWireDeleteButton = create('selected-wire-delete-button');
export const wiresPerManagerWireSelectedWireCreateNewButton = create(
  'selected-wire-create-new-button'
);
export const wiresPerManagerWireSelectedWireEditButton = create('selected-wire-edit-button');
export const wiresPerManagerWireSelectedWireSendButton = create('selected-wire-send-button');
export const wiresPerManagerWireSelectedWireTemplate = create('selected-wire-template');

export const wiresPerManagerWireManagerList = create('manager-list');
