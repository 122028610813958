import { checkPermissions, permissionsByEntity, useAuth } from '@cmg/auth';
import { DataGrid, Icon, SecondaryButton, selectColumn } from '@cmg/common';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components/macro';

import { PaginationQueryParams } from '../../../../types/api/pagination';
import { CompanyLegacy } from '../../../../types/domain/company/company';
import { fetchSectors, selectSectors } from '../../../shared/sectors/ducks';
import { downloadCompanies, fetchCompanies, selectCompanies, updateSectorCompany } from '../ducks';
import CompaniesFilters, { FilterValues } from './CompaniesFilters';
import SectorsModal from './SectorsModal';

const mapStateToProps = state => ({
  companies: selectCompanies(state),
  sectors: selectSectors(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchCompanies,
      fetchSectors,
      updateSectorCompany,
      downloadCompanies,
    },
    dispatch
  ),
});

export type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const ActionCell = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.text.color.link};
`;

export const CompaniesGrid: React.FC<Props> = ({ companies, sectors, actions }) => {
  const { userPermissions } = useAuth();
  const [fetchParams, setFetchParams] = useState<Partial<PaginationQueryParams> & FilterValues>({
    orderDirection: 'asc',
    orderField: 'issuer',
    page: 1,
    perPage: DataGrid.DEFAULT_PER_PAGE,
    query: undefined,
    bySectorsValues: undefined,
    byCustomSectorsValues: undefined,
  });

  const [selected, setSelected] = useState<CompanyLegacy[]>([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    actions.fetchCompanies(fetchParams);
  }, [actions, fetchParams]);

  useEffect(() => {
    !sectors && actions.fetchSectors();
  }, [actions, sectors]);

  const handleUpdateIds = companies => {
    setSelected(companies);
    setShowModal(true);
  };

  const canWriteCustomSectors = checkPermissions(userPermissions, [
    permissionsByEntity.CustomSectors.FULL,
  ]);

  const columns = [
    ...(canWriteCustomSectors ? [selectColumn] : []),
    { field: 'issuer', headerName: 'Issuer', sort: 'asc' },
    { field: 'ticker', headerName: 'Ticker' },
    {
      field: 'companyProfile.sector',
      headerName: 'Sector',
      colId: 'sector',
    },
    {
      field: 'companyProfile.subSector',
      headerName: 'Sub Sector',
      colId: 'subsector',
    },
    {
      field: 'customSector.sectorName',
      headerName: 'My Sector',
      colId: 'custom_sector',
      cellRendererFramework: canWriteCustomSectors
        ? ({ data: company }) => (
            <ActionCell onClick={() => handleUpdateIds([company])}>
              {company.customSector ? (
                company.customSector.sectorName
              ) : (
                <Icon name="plus" size="sm" />
              )}
            </ActionCell>
          )
        : undefined,
    },
    {
      field: 'customSubsector.sectorName',
      headerName: 'My Sub Sector',
      colId: 'custom_subsector',
      cellRendererFramework: canWriteCustomSectors
        ? ({ data: company }) => (
            <ActionCell onClick={() => handleUpdateIds([company])}>
              {company.customSubsector ? (
                company.customSubsector.sectorName
              ) : (
                <Icon name="plus" size="sm" />
              )}
            </ActionCell>
          )
        : undefined,
    },
  ];

  const handleBulkSubmit = (sectorId: string) => {
    actions.updateSectorCompany({ companyIds: selected.map(c => c.id), sectorId });
  };

  const handleParamsChange = (params: Partial<PaginationQueryParams> & FilterValues) => {
    const newParams = { ...fetchParams, ...params };
    setFetchParams(newParams);
  };

  const handleSelectionChange = rows => {
    setSelected(rows);
  };

  const onDownload = () => {
    const { query, bySectorsValues, byCustomSectorsValues, orderField, orderDirection } =
      fetchParams;
    actions.downloadCompanies({
      query,
      bySectorsValues,
      byCustomSectorsValues,
      orderField,
      orderDirection,
    });
  };

  return companies ? (
    <React.Fragment>
      <DataGrid<CompanyLegacy>
        extended={{
          title: 'Manage Companies',
          withMargin: true,
          onDownload,
          downloadTitle: 'Download Companies',
        }}
        columns={columns}
        rows={companies.data}
        resizeBy="grid"
        renderFilters={() => (
          <CompaniesFilters sectors={sectors} onChange={params => handleParamsChange(params)} />
        )}
        renderPanel={
          canWriteCustomSectors
            ? ({ selectedRows }) => (
                <SecondaryButton
                  disabled={selectedRows.length < 2}
                  onClick={() => setShowModal(true)}
                >
                  Bulk update
                </SecondaryButton>
              )
            : undefined
        }
        totalPages={companies.pagination.totalPages}
        pagination={{
          page: companies.pagination.activePage,
          perPage: companies.pagination.perPage,
        }}
        onPaginationChange={handleParamsChange}
        onSelectionChange={handleSelectionChange}
      />
      <SectorsModal
        onSubmit={handleBulkSubmit}
        companyCount={selected.length}
        sectors={sectors || undefined}
        onHide={() => setShowModal(false)}
        show={showModal}
      />
    </React.Fragment>
  ) : null;
};

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesGrid);
