import styled from 'styled-components/macro';

import Icon from '../../../../../graphics/icon/Icon';

// the wrapper is needed to make the Popover appear at all when over an Icon (svg)
export const SPrefixWrapper = styled.span`
  margin-left: 10px;
`;

export const StyledWarningIcon = styled(Icon).attrs(() => ({
  name: 'exclamation-circle',
  variant: 'solid',
}))`
  color: ${({ theme }) => theme.brand.color.warningAlternate};
`;

export const SDirtyIndicator = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.brand.color.warningAlternate};
  border-left: 2px solid rgba(0, 0, 0, 0.15);
`;

export const SRoot = styled.div`
  padding: 5px;
`;
