import { datalabApi } from '@cmg/api';
import { checkPermissions, getUserPermissions, permissionsByEntity } from '@cmg/auth';
import { reduxUtil } from '@cmg/common';
import { combineReducers } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';

import { RootState } from '../../../common/redux/rootReducer';

const { createReducer } = reduxUtil;

enum ActionTypes {
  FETCH_SECTORS_REQUEST = 'shared/sectors/FETCH_SECTORS_REQUEST',
  FETCH_SECTORS_SUCCESS = 'shared/sectors/FETCH_SECTORS_SUCCESS',
}

export const fetchSectors = () => ({ type: ActionTypes.FETCH_SECTORS_REQUEST });

export const fetchSectorsSuccess = (payload: datalabApi.CustomSectorDto[]) => ({
  type: ActionTypes.FETCH_SECTORS_SUCCESS,
  payload,
});

type Actions = {
  [ActionTypes.FETCH_SECTORS_REQUEST]: ReturnType<typeof fetchSectors>;
  [ActionTypes.FETCH_SECTORS_SUCCESS]: ReturnType<typeof fetchSectorsSuccess>;
};

export type SharedSectorsState = {
  sectors: datalabApi.CustomSectorDto[] | null;
};

export const initialState: SharedSectorsState = { sectors: null };

const sectorsReducer = createReducer<SharedSectorsState['sectors'], Actions>(initialState.sectors, {
  [ActionTypes.FETCH_SECTORS_SUCCESS]: (state, { payload }) => payload,
});

export default combineReducers<SharedSectorsState>({
  sectors: sectorsReducer,
});

export const selectSectors = (state: RootState) => state.sharedSectors.sectors;

/**
 * SAGAS
 */

function* fetchSectorsSaga() {
  const userPermissions = getUserPermissions();
  if (checkPermissions(userPermissions, [permissionsByEntity.CustomSectors.READ])) {
    const resp: datalabApi.FetchCustomSectorsResponse = yield call(
      datalabApi.fetchCustomSectors,
      {},
      {}
    );
    if (resp.ok) {
      yield put(fetchSectorsSuccess(resp.data));
    }
  }
}

export function* sharedSectorsSaga() {
  yield takeLatest<Actions[ActionTypes.FETCH_SECTORS_REQUEST]>(
    ActionTypes.FETCH_SECTORS_REQUEST,
    fetchSectorsSaga
  );
}
