import { Theme } from '../types';
import { commonTheme } from './common-theme';

const colors: Theme['foundation']['colors'] = {
  primary: {
    '000': '#CDEAF8',
    '100': '#9CD5F2',
    '200': '#6ABFEC',
    '300': '#38AAE5',
    '400': '#1A8ECA',
    '500': '#146A97',
  },
  secondary: {
    '000': '#EFF1F5',
    '100': '#DFDFDF',
    '200': '#CFCFCF',
    '300': '#BFBFBF',
    '400': '#9F9F9F',
    '500': '#7F7F7F',
  },
  success: {
    '000': '#EEFAF2',
    '100': '#A7E4BD',
    '200': '#7BD69B',
    '300': '#4FC97A',
    '400': '#35AB5E',
    '500': '#278147',
  },
  error: {
    '000': '#FEEDEB',
    '100': '#FAA19B',
    '200': '#F77268',
    '300': '#F44336',
    '400': '#E21B0C',
    '500': '#AA1409',
  },
  warning: {
    '000': '#FFF9E7',
    '100': '#FFE083',
    '200': '#FFD145',
    '300': '#FFC107',
    '400': '#DAA400',
    '500': '#AF8300',
  },
  info: {
    '000': '#E8F3F5',
    '100': '#9BE6F3',
    '200': '#19B3CC',
    '300': '#13879A',
    '400': '#117687',
    '500': '#0C5460',
  },
};

export const defaultTheme: Theme = {
  ...commonTheme,
  foundation: {
    colors,
  },
};
