import type { ListItemButtonProps } from '@mui/material/ListItemButton';
import ListItemButton from '@mui/material/ListItemButton';
import { styled } from '@mui/material/styles';

export type ListStatusItemButtonProps = ListItemButtonProps;

const ListStatusItemButton = styled(ListItemButton, {
  name: 'ListStatusItemButton',
  slot: 'ListItemButton',
  overridesResolver: (_props, styles) => [styles.root],
})(({ theme }) => ({
  alignItems: 'flex-start',
}));

export default ListStatusItemButton;
