import { createTestId } from '../../../common/selectors';

const create = name => createTestId(`rolodex-firm-list-${name}`);

export const firmListScreen = create('screen');
export const firmListHeader = create('header');
export const firmListCreateFirm = create('create-firm');
export const firmListSearchInput = create('search-input');
export const firmListIdInput = create('id-input');
export const firmListStatusTypeSelect = create('status-select');
export const firmListEntityTypeSelect = create('entity-select');
export const firmListIndustryTypeSelect = create('industry-select');
export const firmListRoleTypeSelect = create('role-select');
export const firmListRecordStatusTypeSelect = create('record-status-select');
export const firmListFirmTypeSelect = create('firm-type-select');
