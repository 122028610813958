import { createTestId } from '../../../common/selectors';

const create = (name: string) => createTestId(`xc-wires-selling-group-invitation-${name}`);

export const wiresSellingGroupInvitationEditButton = create('edit-button');
export const wiresSellingGroupInvitationDeleteButton = create('delete-button');
export const wiresSellingGroupInvitationSendButton = create('send-button');

export const wiresSellingGroupInvitationModalCancelButton = create('modal-cancel-button');
export const wiresSellingGroupInvitationModalSaveButton = create('modal-save-button');

export const wiresSellingGroupInvitationFormMasterSdaDateInput = create('form-sda-date-input');
export const wiresSellingGroupInvitationFormDisclaimerInput = create('form-disclaimer-input');
