import { createTestId } from '../../../common/selectors';

const create = name => createTestId(`account-security-${name}`);

export const accountSecurityScreen = create('screen');
export const accountSecurityLoginSettingsPanel = create('login-settings-panel');
export const accountSecurityIpWhitelistPanel = create('ip-whitelist-panel');
export const accountSecurityLocalLoginProviderButton = create('local-login-provider-button');
export const accountSecurityAzureProviderScreen = create('azure-provider-screen');
export const accountSecuritySamlProviderScreen = create('saml-provider-screen');
export const accountSecurityOpenIdProviderScreen = create('openid-provider-screen');
export const accountSecurityIdentityProviderScreen = create('identity-provider-screen');
export const accountSecurityIdentityProviderHeader = create('identity-provider-header');
export const accountSecurityLocalLoginProviderScreen = create('local-login-provider-screen');
export const accountSecurityPasswordPolicyEditButton = create('password-policy-edit-button');
