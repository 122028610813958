import styled, { css } from 'styled-components/macro';

import IconButton from '../../buttons/button/IconButton';

export const CLOSE_TIMEOUT_MS = 200;

export const SBody = styled.div`
  padding: 15px;
  position: relative;
`;

export const STitle = styled.div`
  padding: 15px;
  font-size: 18px;
`;

export const SFooter = styled.div`
  padding: 15px;
  text-align: right;
  border-top: 1px solid ${({ theme }) => theme.border.color.dark};
`;

export const StyledIconButton = styled(IconButton)`
  float: right;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.interactive.primary};
  border-radius: 0;
  font-size: 18px;
  &,
  &:hover {
    color: ${({ theme }) => theme.text.color.white};
  }
`;

export const contentAndOverlayCss = css<{ size?: 'small' | 'large' }>`
  width: 95%;
  margin: auto;
  background: ${({ theme }) => theme.background.color.white};
  align-self: flex-start;

  &__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex !important;
    height: 100vh;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: ${({ theme }) => theme.zIndex.modal};
    opacity: 0;
    transition: opacity ${CLOSE_TIMEOUT_MS}ms ease-in-out;
    overflow: auto;
    padding: 30px 0;

    &.ReactModal__Overlay--after-open {
      opacity: 1;
    }
    &.ReactModal__Overlay--before-close {
      opacity: 0;
    }
  }

  max-width: ${({ size }) => (size === 'large' ? '900px' : '650px')};
`;

export const SLoadingWrapper = styled.div`
  height: 200px;
  text-align: center;
  padding-top: 90px;
`;
