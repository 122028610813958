import { Option, ToastManager } from '@cmg/common';
import React from 'react';

import { OfferingType } from '../../../../../graphql/__generated__/index';
import { SearchFilterHookResult } from '../../../../../graphql/types';
import { useGetLeftleadQuery } from '../graphql/__generated__/GetLeftLead';
import { useCreateLeftleadOptions } from '../OfferingsFilterForm.model';

export const useSearchLeftleads = (offeringType?: OfferingType): SearchFilterHookResult => {
  const [searchText, setSearchText] = React.useState<string>();
  const { data, error, loading } = useGetLeftleadQuery({
    variables: {
      filter: {
        offering: offeringType ? { type: { eq: offeringType } } : undefined,
        leftLeadName: searchText ? { like: searchText } : undefined,
      },
    },
  });
  const resolveRef = React.useRef<(value: Option<string>[]) => void>();
  const leftleadOptions = useCreateLeftleadOptions(data?.offeringAttributes?.items ?? []);

  React.useEffect(() => {
    if (error) {
      ToastManager.error('Error occurred while fetching leftleads');
    }
    if (!loading && data && resolveRef.current) {
      resolveRef.current(leftleadOptions);
    }
  }, [loading, data, error, leftleadOptions]);

  const loadOptions = React.useCallback((newSearchText: string) => {
    setSearchText(newSearchText);
    return new Promise<Option<string>[]>(resolve => {
      resolveRef.current = resolve;
    });
  }, []);

  return {
    defaultOptions: leftleadOptions,
    loadOptions,
  };
};
