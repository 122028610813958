import merge from 'lodash/merge';

import {
  ApiClientFailure,
  GenericResponseDataFailure,
  GenericServerError,
} from '../../api/types/ApiClientResponses';

export const makeGenericServerError = (
  overrides?: Partial<GenericServerError>
): GenericServerError => {
  const defaults: GenericServerError = {
    code: '',
    message: '',
    target: '',
    details: [],
  };

  return merge(defaults, overrides);
};

export const genericServerErrorDefault = makeGenericServerError({
  code: 'mock-code',
  details: [
    {
      code: 'mock-detail-code',
      message: 'mock-detail-message',
      target: 'mock-detail-target',
    },
  ],
  message: 'mock-top-level-message',
  target: 'mock-target',
});

export const makeSuccessApiResponse = (data?: any) => ({
  ok: true,
  data,
});

export const genericSuccessApiResponseDefault = makeSuccessApiResponse();

export const makeErrorApiResponse = (
  response?: Partial<GenericServerError>
): ApiClientFailure<GenericResponseDataFailure> => ({
  ok: false,
  data: {
    error: makeGenericServerError(response),
  },
});

export const genericErrorApiResponseDefault = makeErrorApiResponse();
