import styled from 'styled-components/macro';

export const SDiscountWrapper = styled.div`
  display: flex;
`;

export const SDiscountSeparator = styled.div`
  margin-left: 2px;
  margin-right: 2px;
`;
