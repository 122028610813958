import { CheckboxSelectField, MultiSelectField, NumericRangeInputField } from '@cmg/common';
import {
  datalabMarketCapSelector,
  datalabRegionCountryFilterSelector,
  datalabSizeInDollarsSelector,
} from '@cmg/e2e-selectors';
import { Form } from 'formik';
import React from 'react';
import styled from 'styled-components/macro';

import { TreeSelectField } from '../../../common/components/form/selects/TreeSelect';
import { CalendarCategory } from '../../../types/domain/calendar/constants';
import SectorToggleLabel from '../../shared/sectors/SectorToggleLabel';
import { FilterOptions } from '../ducks';

const SFormRow = styled.div`
  padding: 10px 20px 0px 20px;
  > * {
    width: 250px;
    margin-right: 15px;
  }
`;

export type Props = {
  showInternational: boolean;
  filterOptions: FilterOptions;
  useCustomSectors: boolean;
  handleSubmit: () => void;
  category: CalendarCategory;
};

const CalendarFilterForm: React.FC<Props> = ({
  showInternational,
  filterOptions,
  useCustomSectors,
  handleSubmit,
}) => {
  return (
    <Form>
      <SFormRow>
        <CheckboxSelectField
          testId="offeringType-select"
          name="offeringType"
          label="Offering Type"
          options={filterOptions.offeringType}
          resetValues={filterOptions.offeringType}
          withMargin
          width={250}
          onChange={() => handleSubmit()}
        />
        <NumericRangeInputField
          data-test-id={datalabSizeInDollarsSelector}
          name="sizeInDollars"
          label="Size ($M)"
          inputsProps={{ precision: 2 }}
          onChange={() => handleSubmit()}
        />
        <NumericRangeInputField
          data-test-id={datalabMarketCapSelector}
          name="marketCap"
          label="Market Cap ($M)"
          inputsProps={{ precision: 2 }}
          onChange={() => handleSubmit()}
        />
        <MultiSelectField
          testId="sector-select"
          name={useCustomSectors ? 'customSectorId' : 'sector'}
          label={<SectorToggleLabel onChange={handleSubmit} />}
          options={useCustomSectors ? filterOptions.customSector : filterOptions.sector}
          withMargin
          onChange={() => handleSubmit()}
        />
        <MultiSelectField
          testId="leftLead-select"
          name="leftLeadFirmId"
          label="Left Lead"
          options={filterOptions.leftLead}
          withMargin
          onChange={() => handleSubmit()}
        />
        <MultiSelectField
          testId="managerFirmId-select"
          name="managerFirmId"
          label="Underwriter"
          options={filterOptions.underwriter}
          withMargin
          onChange={() => handleSubmit()}
        />
        {showInternational && (
          <TreeSelectField
            data-test-id={datalabRegionCountryFilterSelector}
            testId="countries-select"
            name="countries"
            treeData={filterOptions.country || []}
            label="Region/Country"
            withMargin
            onChange={() => handleSubmit()}
          />
        )}
      </SFormRow>
    </Form>
  );
};

export default CalendarFilterForm;
