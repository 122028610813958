import { LazyQueryExecFunction } from '@apollo/client';
import { GridReadyEvent, IGetRowsParams } from 'ag-grid-community';
import isEqual from 'lodash/isEqual';

export type SortModel<T> = {
  colId: T;
  sort: 'asc' | 'desc';
};

export type FilterModel<T extends string> = Partial<Record<T, string | null>>;

export type QueryDocumentTypeContract<T, QueryName extends string> = Readonly<
  Partial<{
    [K in QueryName]: Partial<
      Readonly<{
        items: readonly T[] | null;
        totalCount: number | null;
        pageInfo: Partial<
          Readonly<{
            hasNextPage: boolean | null;
          }>
        > | null;
      }>
    > | null;
  }>
>;

export type QueryVariablesContract = {
  take?: number | null;
  skip?: number | null;
  where?: Partial<Record<string, unknown>> | null;
  order?: unknown | unknown[] | null;
};

export type GridDataSourceManagerOptions<
  ColumnsKeys extends string,
  Entity extends QueryDocumentTypeContract<unknown, QueryName>,
  QueryVariables extends QueryVariablesContract,
  QueryName extends string
> = {
  rowsPerPage?: number;
  filtersMode?: 'uncontrolled' | 'controlled';
  sortingMode?: 'uncontrolled' | 'controlled';
  mapFiltersModelFn?: (filterModel: FilterModel<ColumnsKeys>) => QueryVariables['where'];
  mapSortingModelFn?: (sortModel: SortModel<ColumnsKeys>[]) => QueryVariables['order'];
  handleFetchError?: (error: any) => void;
  queryName: QueryName;
  fetchData: LazyQueryExecFunction<Entity, QueryVariables>;
};

export default class GridDataSourceManager<
  ColumnsKeys extends string,
  Entity extends QueryDocumentTypeContract<unknown, QueryName>,
  QueryVariables extends QueryVariablesContract,
  QueryName extends string
> {
  private gridRef: GridReadyEvent | null;
  private filterModel: FilterModel<ColumnsKeys>;
  private sortModel: SortModel<ColumnsKeys>[];
  private mapFiltersModelFn:
    | ((filterModel: FilterModel<ColumnsKeys>) => QueryVariables['where'])
    | null;
  private mapSortingModelFn:
    | ((sortModel: SortModel<ColumnsKeys>[]) => QueryVariables['order'])
    | null;
  private queryName: QueryName;
  private filterQueryParams: QueryVariables['where'];
  private sortingQueryParams: QueryVariables['order'];
  private fetchData: LazyQueryExecFunction<Entity, QueryVariables> | null;
  private rowsPerPage: number;
  private hasNextPage: boolean;
  private handleFetchError: (error: any) => void;
  private totalCount: number;
  private queryParams: Omit<QueryVariables, 'where' | 'order' | 'take' | 'skip'> | {};

  public filtersMode: 'uncontrolled' | 'controlled';
  public sortingMode: 'uncontrolled' | 'controlled';

  constructor({
    queryName,
    rowsPerPage = 100,
    filtersMode = 'uncontrolled',
    sortingMode = 'uncontrolled',
    mapFiltersModelFn,
    mapSortingModelFn,
    handleFetchError,
    fetchData,
  }: GridDataSourceManagerOptions<ColumnsKeys, Entity, QueryVariables, QueryName>) {
    this.rowsPerPage = rowsPerPage ?? 100;
    this.filtersMode = filtersMode ?? 'uncontrolled';
    this.sortingMode = sortingMode ?? 'uncontrolled';
    this.handleFetchError = handleFetchError ?? (() => {});
    this.queryName = queryName;
    this.mapFiltersModelFn = null;
    this.mapSortingModelFn = null;
    this.filterModel = {};
    this.sortModel = [];
    this.filterQueryParams = {};
    this.sortingQueryParams = [];
    this.fetchData = fetchData;
    this.gridRef = null;
    this.totalCount = 0;
    this.queryParams = {};

    if (this.filtersMode === 'uncontrolled') {
      this.mapFiltersModelFn = mapFiltersModelFn ?? null;
    } else if (mapFiltersModelFn) {
      console.warn('Filters mode is controlled, mapFiltersModelFn will not have any effect');
    }

    if (this.sortingMode === 'uncontrolled') {
      this.mapSortingModelFn = mapSortingModelFn ?? null;
    } else if (mapSortingModelFn) {
      console.warn('Sorting mode is controlled, mapSortingModelFn will not have any effect');
    }
    this.hasNextPage = true;
  }

  setGridRef(ref: any) {
    this.gridRef = ref;
  }

  async getRows(params: IGetRowsParams) {
    const { startRow, sortModel } = params;

    if (!this.gridRef) {
      console.warn('Grid reference is not set');
      return;
    }

    if (this.filtersMode === 'uncontrolled') {
      // TODO: Implement this when we are using agGrid filters
      console.warn(
        'Filters mode is uncontrolled is not implemented yet no filters will be applied'
      );
    }

    const uncontrolledSortModeChanged =
      this.sortingMode === 'uncontrolled' && this.hasSortModelChanged(sortModel);

    if (uncontrolledSortModeChanged) {
      this.setSorting(sortModel);
      this.resetPagination();
    }

    if (!this.hasNextPage) {
      params.successCallback([], this.totalCount);
      return;
    }

    const { skip, take } = this.getPaginationQueryParams(startRow);

    if (skip === 0) {
      this.showLoadingOverlay();
    }

    const response = await this.fetchData?.({
      variables: {
        ...this.queryParams,
        skip,
        take,
        where: this.filterQueryParams,
        order: this.sortingQueryParams,
      } as QueryVariables,
    });

    if (!response) {
      params.failCallback();
      return;
    }

    if (response.error) {
      params.failCallback();
      this.handleFetchError(response.error);
      this.hideAllOverlays();
      return;
    }

    const data = response.data?.[this.queryName];

    if (data?.items) {
      const { items, totalCount } = data;

      params.successCallback((items ?? []) as any[], totalCount ?? undefined);

      this.hideAllOverlays();

      this.totalCount = totalCount ?? 0;

      if (totalCount === 0) {
        this.showNoRowsOverlay();
      }

      this.hasNextPage = !!data.pageInfo?.hasNextPage;
    } else {
      params.failCallback();
      this.hideAllOverlays();
    }
  }

  private setFilters(filters: FilterModel<ColumnsKeys>) {
    this.filterModel = filters;
    this.filterQueryParams = this.mapFiltersModelFn?.(filters) ?? {};
  }

  private setSorting(sort: SortModel<ColumnsKeys>[]) {
    this.sortModel = sort;
    this.sortingQueryParams = this.mapSortingModelFn?.(sort) ?? [];
  }

  setQueryParams(params: Omit<QueryVariables, 'where' | 'order' | 'take' | 'skip'>) {
    if (!this.hasQueryParamsChanged(params)) {
      return;
    }

    this.queryParams = params;
    this.resetPagination();
  }

  setFiltersQueryParams(filters: QueryVariables['where']) {
    if (this.filtersMode === 'uncontrolled') {
      console.warn('Filters mode is uncontrolled, setFiltersQueryParams will not have any effect');
      return;
    }

    if (!this.hasFiltersModelChanged(filters)) {
      return;
    }

    this.filterQueryParams = filters;
    this.resetPagination();
  }

  setSortingQueryParams(sorting: QueryVariables['order']) {
    if (this.sortingMode === 'uncontrolled') {
      console.warn('Sorting mode is uncontrolled, setSortingQueryParams will not have any effect');
      return;
    }

    if (!this.hasSortModelChanged(sorting)) {
      return;
    }

    this.sortingQueryParams = sorting;
    this.resetPagination();
  }

  private getPaginationQueryParams(skip: number) {
    return {
      skip,
      take: this.rowsPerPage,
    };
  }

  resetPagination() {
    this.gridRef?.api.setRowCount(0);
    this.gridRef?.api.purgeInfiniteCache();

    this.hasNextPage = true;
    this.totalCount = 0;
  }

  private showNoRowsOverlay() {
    this.gridRef?.api.showNoRowsOverlay();
  }

  private showLoadingOverlay() {
    this.gridRef?.api.showLoadingOverlay();
  }

  private hideAllOverlays() {
    this.gridRef?.api.hideOverlay();
  }

  hasSortModelChanged(sortModel: QueryVariables['order'] | SortModel<ColumnsKeys>[]) {
    if (this.sortingMode === 'uncontrolled') {
      return !isEqual(sortModel, this.sortModel);
    }

    return !isEqual(sortModel, this.sortingQueryParams);
  }

  hasFiltersModelChanged(filters: QueryVariables['where'] | FilterModel<ColumnsKeys>) {
    if (this.filtersMode === 'uncontrolled') {
      return !isEqual(filters, this.filterModel);
    }

    return !isEqual(filters, this.filterQueryParams);
  }

  hasQueryParamsChanged(params: Omit<QueryVariables, 'where' | 'order' | 'take' | 'skip'>) {
    return !isEqual(params, this.queryParams);
  }
}
