// Convert file to base64 string
export const fileToBase64 = (file: File) => {
  return new Promise(resolve => {
    const reader = new FileReader();
    // Read file content on file loaded event
    reader.onload = (event: any) => {
      resolve(event.target.result.split(',').pop());
    };

    reader.onerror = () => {
      reader.abort();
    };

    // Convert data to base64
    reader.readAsDataURL(file);
  });
};
