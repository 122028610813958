import { checkPermissions, getUserPermissions, permissionsByEntity } from '@cmg/auth';
import { apiTypes, duckPartFactory, mixpanelUtil } from '@cmg/common';
import { combineReducers } from 'redux';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

// eslint-disable-next-line import/no-duplicates
import sharedApi from '../../../api/datalab-api';
// eslint-disable-next-line import/no-duplicates
import datalabApi from '../../../api/datalab-api';
import * as dlgwApi from '../../../api/dlgw/datalabGatewayApi';
import {
  createActionCreator,
  createActionType,
  createApiActionCreators,
  createReducer,
  FAILURE,
  REQUEST,
  SUCCESS,
} from '../../../common/redux/reduxHelpers';
import { UUID } from '../../../types/common';

const { createMixPanelAction } = mixpanelUtil;

/**
 * ACTION TYPES
 */
const FETCH_OFFERING = 'offering-dl/FETCH_OFFERING';
export const FETCH_DATALAB_OPTIONS = 'offering-profile-dl/FETCH_DATALAB_OPTIONS';
const UPDATE_SECTOR_COMPANY = 'offering-dl/UPDATE_SECTOR_COMPANY';
export const UPDATE_CUSTOM_SECTOR = 'offering-dl/UPDATE_CUSTOM_SECTOR';
const SET_FOLLOW_OFFERING = 'offering-dl/SET_FOLLOW_OFFERING';

/**
 * ACTIONS
 */
export const fetchOfferingActions = createApiActionCreators(FETCH_OFFERING);
export const fetchDatalabOptionsActions = createApiActionCreators(FETCH_DATALAB_OPTIONS);
export const updateSectorCompanyActionCreators = createApiActionCreators(UPDATE_SECTOR_COMPANY);
export const updateCustomSector = createActionCreator(UPDATE_CUSTOM_SECTOR);

export type SetFollowOfferingAction = ReturnType<typeof setFollowOfferingRequest>;

type SetFollowOfferingParams = {
  offeringId: UUID;
  isFollowing: boolean;
};

export const {
  actionCreators: {
    request: setFollowOfferingRequest,
    success: setFollowOfferingSucceeded,
    failure: setFollowOfferingFailed,
  },
  initialState: setFollowOfferingInitialState,
  reducer: setFollowOfferingReducer,
  actionTypes: { REQUEST: setFollowOfferingRequestType, SUCCESS: setFollowingOfferingSuccessType },
} = duckPartFactory.makeAPIDuckParts<SetFollowOfferingParams, SetFollowOfferingParams>({
  prefix: SET_FOLLOW_OFFERING,
});

/**
 * REDUCERS
 */
export const initialState = {
  error: null,
  offering: null,
  advisoryOptions: [],
  fundOptions: [],
};

const offering = createReducer(initialState.offering, {
  [FETCH_OFFERING]: {
    [SUCCESS]: (state, payload) => payload,
  },
  [setFollowingOfferingSuccessType]: (state, payload) => ({
    ...state,
    userIsFollowing: payload.isFollowing,
  }),
  [UPDATE_CUSTOM_SECTOR]: (state, payload) => {
    return {
      ...state,
      issuer: {
        ...state.issuer,
        customSector: payload.customSector,
        customSubsector: payload.customSubsector,
      },
    };
  },
});
const advisoryOptions = createReducer(initialState.advisoryOptions, {
  [FETCH_DATALAB_OPTIONS]: {
    [SUCCESS]: (state, payload) => payload.advisoryOptions,
  },
});
const fundOptions = createReducer(initialState.fundOptions, {
  [FETCH_DATALAB_OPTIONS]: {
    [SUCCESS]: (state, payload) => payload.fundOptions,
  },
});

const error = createReducer(initialState.error, {
  [FETCH_OFFERING]: {
    [SUCCESS]: () => null,
    [FAILURE]: (state, { error }) => error,
  },
});

export default combineReducers({
  offering,
  advisoryOptions,
  fundOptions,
  error,
});

/**
 * SELECTORS
 */
export const selectOfferingDl = state => state.offeringDl;

export const selectOffering = state => selectOfferingDl(state).offering;
export const selectAdvisoryOptions = state => selectOfferingDl(state).advisoryOptions;
export const selectFundOptions = state => selectOfferingDl(state).fundOptions;
export const selectError = (state): apiTypes.GenericServerError | null =>
  selectOfferingDl(state).error;

/**
 * SAGAS
 */
function* fetchOffering({ payload }: any) {
  yield put(createMixPanelAction(FETCH_OFFERING, 'Offering Page'));

  const resp = yield call(datalabApi.fetchOffering, payload.offeringId);

  if (resp.ok) {
    yield put(fetchOfferingActions.success(resp.data.data));
  } else {
    yield put(fetchOfferingActions.failure(resp.data));
  }
}

export function* setFollowOfferingSaga({ payload }: SetFollowOfferingAction) {
  const { offeringId, isFollowing } = payload;

  const resp: dlgwApi.SetFollowOfferingResponse = yield call(
    dlgwApi.setFollowOffering,
    { offeringId },
    { isFollowing }
  );

  if (resp.ok) {
    yield put(setFollowOfferingSucceeded(payload));
  } else {
    yield put(setFollowOfferingFailed(resp.data.error));
  }
}

export function* fetchDatalabOptions() {
  yield put(createMixPanelAction(FETCH_DATALAB_OPTIONS, 'Fetch Datalab filter options'));

  const userPermissions = getUserPermissions();
  const canFetchFunds = checkPermissions(userPermissions, [permissionsByEntity.FundIoi.READ]);

  const [advisory, fund] = yield [
    call(sharedApi.fetchAdvisoryOptions),
    canFetchFunds ? call(sharedApi.fetchFundOptions) : { ok: true, data: [] },
  ];

  if (advisory.ok && fund.ok) {
    const result = {
      advisoryOptions: advisory.data,
      fundOptions: fund.data,
    };
    yield put(fetchDatalabOptionsActions.success(result));
  }
}

function* updateSectorCompany({ payload: { companyId, sectorId } }: any) {
  const resp = yield call(datalabApi.customSectorCompany.update, companyId, sectorId);
  if (resp.ok) {
    yield put(updateCustomSector(resp.data.data[0]));
  }
}

export function* offeringDlSaga() {
  yield takeLatest(createActionType(FETCH_OFFERING, REQUEST), fetchOffering);
  yield takeLatest<SetFollowOfferingAction>(setFollowOfferingRequestType, setFollowOfferingSaga);
  yield takeEvery(createActionType(FETCH_DATALAB_OPTIONS, REQUEST), fetchDatalabOptions);
  yield takeEvery(createActionType(UPDATE_SECTOR_COMPANY, REQUEST), updateSectorCompany);
}
