import { reduxUtil } from '@cmg/common';
import { AnyAction, combineReducers } from 'redux';
import { all, takeEvery } from 'redux-saga/effects';

import { RootState } from '../../common/redux/rootReducer';
import * as BuySideDashboard from './dashboard/buy-side/ducks';
import * as DashboardControls from './dashboard/controls/ducks';
import * as SellSideDashboard from './dashboard/sell-side/ducks';
import * as RecentlyPricedDeals from './recent-deals/ducks';

/**
 * ACTION TYPES
 */
export enum ActionTypes {
  RESET_MY_DASHBOARD = 'MY_DASHBOARD/RESET_MY_DASHBOARD',
}

/**
 * ACTIONS
 */

export const resetMyDashboard = () => ({ type: ActionTypes.RESET_MY_DASHBOARD });
export type ResetMyDashboardAction = ReturnType<typeof resetMyDashboard>;

type Actions = {
  [ActionTypes.RESET_MY_DASHBOARD]: ResetMyDashboardAction;
};

/**
 * REDUCERS
 */
const { createReducer } = reduxUtil;

export type ReducerState = {
  /** buy side dashboard offerings */
  buySideDashboard: BuySideDashboard.ReducerState;

  /** sell side dashboard offerings */
  sellSideDashboard: SellSideDashboard.ReducerState;

  /** recently priced deals */
  recentDeals: RecentlyPricedDeals.ReducerState;

  controls: DashboardControls.ReducerState;
};

export const initialState: ReducerState = {
  buySideDashboard: BuySideDashboard.initialState,
  sellSideDashboard: SellSideDashboard.initialState,
  recentDeals: RecentlyPricedDeals.initialState,
  controls: DashboardControls.initialState,
};

/**
 * @todo replace with resettable reducer once moved to cmg/common
 */
const crossSliceReducer = createReducer<ReducerState, Actions>(initialState, {
  [ActionTypes.RESET_MY_DASHBOARD]: () => initialState,
});

export const combinedReducers = combineReducers<ReducerState>({
  buySideDashboard: BuySideDashboard.reducer,
  sellSideDashboard: SellSideDashboard.reducer,
  recentDeals: RecentlyPricedDeals.reducer,
  controls: DashboardControls.reducer,
});

const reducer = function duckReducer(state: ReducerState = initialState, action: AnyAction) {
  const intermediateState = combinedReducers(state, action);
  return crossSliceReducer(intermediateState, action);
};

export default reducer;

/**
 * Selectors
 */

export const selectMyDashboard = (state: RootState) => state.myDashboard;

/**
 * SAGAS
 */

export function* myDashboardSaga() {
  /**
   * To avoid fetching custom sectors twice for each registered dashboard we register
   * the customSector options saga here
   */
  yield takeEvery<DashboardControls.FetchCustomSectorOptionsAction>(
    DashboardControls.fetchCustomSectorOptionsRequestType,
    DashboardControls.fetchCustomSectorOptionsSaga
  );
  yield all([
    RecentlyPricedDeals.recentlyPricedDealsSaga(),
    SellSideDashboard.sellSideDashboardSaga(),
    BuySideDashboard.buySideDashboardSaga(),
  ]);
}
