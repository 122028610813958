import styled, { css } from 'styled-components/macro';

import type { Maybe } from '../../../../types';

type SAgGridProps = { readonly height?: Maybe<string>; readonly leftPinned?: Maybe<boolean> };
type SaGrid = SAgGridProps &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const SAgGrid = styled.div<SaGrid>`
  &.ag-theme-balham {
    font-family: ${({ theme }) => theme.text.family.regular};
    height: 100%;
    max-width: 100%;
    overflow: auto;

    ${({ leftPinned }) =>
      leftPinned === true
        ? css`
            .ag-pinned-left-cols-container {
              z-index: 1;
            }
          `
        : undefined}
    .ag-row-selected {
      background-color: ${({ theme }) => theme.background.color.highlighted};
    }

    .ag-root-wrapper {
      background-color: ${({ theme }) => theme.background.color.white};
    }

    .ag-root {
      border-width: 0;
      margin-bottom: 4px;
    }

    .ag-row {
      border-bottom-width: 0;
    }

    .ag-header-container .ag-header-cell-text {
      text-transform: capitalize;
      color: ${({ theme }) => theme.text.color.light};
    }

    .ag-header-cell-label {
      color: ${({ theme }) => theme.text.color.light};
    }

    .ag-header-group-text {
      color: ${({ theme }) => theme.text.color.light};
    }

    .ag-cell {
      font-size: ${({ theme }) => theme.text.size.mediumSmall};

      &.table-cell-editable {
        color: ${({ theme }) => theme.text.color.link};
      }

      &.td-pill {
        padding: 1px 3px !important;
        font-size: 11px !important;

        .pill {
          padding: 2px 8px;
          border-radius: 8px;
        }
      }

      &.ag-cell-inline-editing {
        height: ${({ theme }) => theme.table.cell.height};
      }
    }

    .ag-floating-bottom-container {
      font-weight: ${({ theme }) => theme.text.weight.bold};

      .ag-row:first-child {
        border-top: 2px solid black;
      }
    }

    .ag-row,
    .ag-cell {
      &.bg-success {
        background-color: ${({ theme }) => theme.color.green4};
      }

      &.bg-warning {
        background-color: ${({ theme }) => theme.color.yellow2};
      }

      &.bg-danger {
        background-color: ${({ theme }) => theme.color.red2};
      }

      &.bg-info {
        background-color: ${({ theme }) => theme.color.blue13};
      }
    }

    .ag-floating-top-viewport,
    .ag-pinned-right-floating-top,
    .ag-pinned-left-floating-top {
      .ag-cell {
        background-color: ${({ theme }) => theme.background.color.dark};
        color: ${({ theme }) => theme.text.color.white};
        font-size: 11px;
        text-align: left;

        .summary-cell > * {
          line-height: 11px;
        }
      }
    }

    .ag-floating-bottom {
      background-color: ${({ theme }) => theme.background.color.white};

      .ag-cell {
        background-color: ${({ theme }) => theme.background.color.white};
      }
    }

    /* todo workaround for invalid auto-column-size (these styles removed ellipsis dots from header) */

    .ag-header-cell,
    .ag-header-group-cell {
      padding-left: 0;
      padding-right: 0;
    }

    .ag-header-group-cell,
    .ag-cell-label-container,
    .ag-header-select-all {
      padding-left: 12px;
      padding-right: 12px;
    }

    .ag-header-cell-label .ag-header-cell-text {
      overflow: hidden;
      text-overflow: unset;
    }

    .ag-header-cell-label {
      display: flex;
      float: left;
      height: 100%;
      width: 100%;
    }

    // workaround ended

    .ag-ltr .ag-cell-focus.select-column,
    .ag-ltr .ag-cell-focus.select-column:focus,
    .ag-ltr .ag-cell-focus.td-actions,
    .ag-ltr .ag-cell-focus.td-actions:focus {
      border: 1px solid transparent;
    }

    .select-column {
      text-overflow: clip;
    }

    /*
      ag-icon is no longer used for checkboxes but it may be used for other icons
      @todo we need to verify that its not used for anything before removing it here. 
    */

    .ag-icon,
    .ag-checkbox-input-wrapper {
      background-color: ${({ theme }) => theme.background.color.white};
      font-size: ${({ theme }) => theme.text.size.medium};
      height: ${({ theme }) => theme.text.size.medium};
      width: ${({ theme }) => theme.text.size.medium};
      box-shadow: none;
    }
  }

  .ag-header-center {
    .ag-header-group-cell-label,
    .ag-header-cell-label {
      justify-content: center;
    }
  }

  .ag-header-right {
    .ag-header-group-cell-label,
    .ag-header-cell-label {
      justify-content: right;
    }
  }

  .ag-bl-overlay {
    height: auto;
    width: auto;
    position: unset;
  }
`;
