import { Box, numericUtil, Table } from '@cmg/common';
import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../../../common/util/routeFactory';
import { UUID } from '../../../../../types/common';
import { ManagerRole, managerRoleLabels } from '../../../../../types/domain/offering/constants';
import { DatalabScreens } from '../../../../datalab/constants';
import { STableCell, STableHeader } from './WidgetUnderwriters.styles';

type LiteManager = {
  firmId: UUID;
  firmName: string;
  role: ManagerRole;
  economicsShares: number | null;
  economicsPercent: number | null;
};

export type Props = {
  managers: LiteManager[];
};

const WidgetUnderwriters: React.FC<Props> = ({ managers }) => {
  return (
    <Box>
      <div>
        <Box.Header title="Underwriters" />
        <Box.Content>
          <Table striped>
            <thead>
              <tr>
                <th>Underwriter</th>
                <th>Role</th>
                <STableHeader>Number of shares</STableHeader>
                <STableHeader>Economics</STableHeader>
              </tr>
            </thead>
            <tbody>
              {managers.map(manager => (
                <tr key={manager.firmId}>
                  <td>
                    <Link
                      to={routeFactory.datalab.getUrlPath({
                        screen: DatalabScreens.UNDERWRITER_OFFERINGS,
                        type: 'table',
                        query: {
                          underwriter: manager.firmId,
                        },
                      })}
                    >
                      {manager.firmName}
                    </Link>
                  </td>
                  <td>{managerRoleLabels[manager.role]}</td>
                  <STableCell>
                    <span>{numericUtil.formatInteger(manager.economicsShares)}</span>
                  </STableCell>
                  <STableCell>{numericUtil.formatPercents(manager.economicsPercent, 1)}</STableCell>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box.Content>
      </div>
    </Box>
  );
};

export default WidgetUnderwriters;
