import { Link } from 'react-router-dom';

import routeFactory from '../../../../../../common/util/routeFactory';
import { getFeatureToggles } from '../../../../../../config/appSettings';
import {
  Country,
  OfferingStatus,
  OfferingType,
} from '../../../../../../graphql/__generated__/index';
import { InternationalOfferingTypeLabels } from '../../../../../../types/domain/offering/constants';
import { isInternationalOfferingTypesOn } from '../../../../../datalab/model/utils';
import { CompanyProfile_Offering_FieldsFragment } from '../../../../graphql/__generated__/CompanyProfile';

type Props = {
  offering: CompanyProfile_Offering_FieldsFragment;
};

const OfferingTypeCell: React.FC<Props> = ({ offering }) => {
  const url = routeFactory.offerings.getUrlPath({ id: offering.id });

  const hideLink =
    getFeatureToggles().isRestrictFiledUSOfferingsInDLOn &&
    offering.attributes?.exchangeCountry === Country.Us &&
    offering.status === OfferingStatus.Filed &&
    offering.type !== OfferingType.Convertible &&
    offering.type !== OfferingType.Atm;

  const offeringTypeLabel =
    offering.type &&
    offering.attributes?.pricingCurrency !== 'USD' &&
    isInternationalOfferingTypesOn()
      ? InternationalOfferingTypeLabels[offering.type]
      : offering.typeDisplayName;

  return hideLink ? (
    <span>{offering.typeDisplayName}</span>
  ) : (
    <Link to={url}>{offeringTypeLabel}</Link>
  );
};

export default OfferingTypeCell;
