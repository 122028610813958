import { createTestId } from '../../common/selectors';

const create = (name: string) => createTestId(`xc-offering-setup-${name}`);

export const offeringSetupTopLevelSaveButton = create('top-level-save-button');
export const offeringSetupSaveButton = create('save-button');
export const offeringSetupCancelButton = create('cancel-button');
export const offeringSetupEditButton = create('edit-button');
export const offeringSetupDeleteButton = create('delete-button');

export * from './basic-info';
export * from './instruments';
export * from './managers';
export * from './marketing';
export * from './publish-offering';
export * from './side-nav';
export * from './summary';
export * from './terms';
