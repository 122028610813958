import { createTestId } from '../common/selectors';

export const supportButtonSelector = createTestId('support-button');
export const accountMenuTriggerSelector = createTestId('account-menu-trigger');
export const accountMenuLogoutSelector = createTestId('account-menu-logout');
export const accountMenuApiSelector = createTestId('account-menu-api');
export const accountMenuSectorsSelector = createTestId('account-menu-sectors');
export const accountMenuPrivacySelector = createTestId('account-menu-privacy');
export const sidebarSelector = createTestId('sidebar');
export const menuItemSelector = menu => createTestId(`sidebar-menu-${menu}`);
export const submenuItemSelector = (menu, submenu) =>
  createTestId(`sidebar-menu-${menu}-${submenu}`);
export const companySearchTestId = createTestId('company-search');
