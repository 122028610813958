import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ActionCreatorsMapObject, AnyAction, bindActionCreators } from 'redux';

/**
 * inspired by:
 * https://react-redux.js.org/next/api/hooks#recipe-useactions
 */
export function useActions(actions: ActionCreatorsMapObject<AnyAction>) {
  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => bindActionCreators(actions, dispatch), [dispatch]);
}
