import type { ButtonProps } from '@mui/material/Button';
import Button from '@mui/material/Button';
import type { LinkProps } from '@mui/material/Link';
import React from 'react';

export type AppBarNavigationLinkProps = Omit<ButtonProps, 'color'> &
  Omit<LinkProps, 'color'> &
  Readonly<{
    readonly isActive?: boolean;
  }>;

export const AppBarNavigationLink = React.forwardRef<HTMLButtonElement, AppBarNavigationLinkProps>(
  ({ isActive = false, ...restProps }, ref) => {
    return (
      <Button
        {...restProps}
        ref={ref}
        color={isActive ? 'primaryDarkBackground' : 'secondaryDarkBackground'}
      />
    );
  }
);
