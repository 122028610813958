import InputAdornment from '@mui/material/InputAdornment';
import React from 'react';

import type { NumberFieldProps } from '../number-field/NumberField';
import { NumberField } from '../number-field/NumberField';

export type PercentageFieldProps = Omit<NumberFieldProps, 'InputProps'> & {
  InputProps?: Omit<NumberFieldProps['InputProps'], 'endAdornment'>;
};

export const PercentageField = React.forwardRef<HTMLDivElement, NumberFieldProps>(
  ({ InputProps, ...props }, ref) => {
    const inputProps = React.useMemo(
      () => ({
        ...InputProps,
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }),
      [InputProps]
    );

    return <NumberField {...props} ref={ref} InputProps={inputProps} />;
  }
);
