import {
  Alert,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@cmg/design-system';
import { Collapse } from '@mui/material';
import orderBy from 'lodash/orderBy';
import React, { useMemo, useState } from 'react';

import { NoDataAvailable } from '../../../../../common/components/table/table';
import {
  StyledGrid,
  StyledTableCell,
  StyledTableHeader,
  StyledTableRow,
  tableContainerStyle,
} from '../../../../../common/components/table/table.styles';
import { AtmBlurTable } from '../../../../shared/components/atm/blur-table/AtmBlurTable';
import {
  AtmSellDownReport,
  isAtmProgramSize,
} from '../../../../shared/components/atm/sell-down/AtmSellDownReport';
import { CompanyProfile_Offering_FieldsFragment } from '../../../graphql/__generated__/CompanyProfile';
import { useGetAtmOfferingsTableModel } from '../table-model/OfferingsTable.model';
import { TableColumnProps } from '../table-model/types';
import {
  SContainer,
  StyledKeyboardArrowDown,
  StyledKeyboardArrowUp,
  StyledNoSpaceRow,
} from './AtmOfferingsTable.styles';

type Props = {
  offerings: CompanyProfile_Offering_FieldsFragment[];
  permissions: TableColumnProps;
};

const AtmOfferingsTable: React.FC<Props> = ({ offerings, permissions }) => {
  const atmTableModel = useGetAtmOfferingsTableModel(permissions);
  const [openRows, setOpenRows] = useState<string[]>([]);
  const handleToggleRow = (offeringId: string) => {
    setOpenRows(prevOpenRows =>
      prevOpenRows.includes(offeringId)
        ? prevOpenRows.filter(id => id !== offeringId)
        : [...prevOpenRows, offeringId]
    );
  };
  const sortedOfferings = useMemo(
    () => orderBy(offerings, 'publicFilingDate', 'desc'),
    [offerings]
  );

  return (
    <React.Fragment>
      {!permissions.canReadAtmSelldown && permissions.isAtmSelldownOn && (
        <Alert severity="info" color="info" sx={{ marginBottom: 2 }}>
          Please contact sales@cmgx.io to get access to ATM Selldown Data.
        </Alert>
      )}

      <StyledGrid>
        <TableContainer sx={tableContainerStyle}>
          <Table size="medium" data-testid="atm-offerings-table">
            <TableHead>
              {(offerings?.length ?? 0) > 0 && (
                <TableRow color="text.secondary">
                  {permissions.isAtmSelldownOn && <StyledTableHeader />}
                  {atmTableModel.map((column, index) => (
                    <StyledTableHeader key={index} {...column.headerStyles}>
                      {column.label}
                    </StyledTableHeader>
                  ))}
                </TableRow>
              )}
            </TableHead>

            <TableBody>
              {sortedOfferings.map(offering => {
                if (!offering.attributes) {
                  return null;
                }
                return (
                  <React.Fragment key={offering.id}>
                    <StyledTableRow
                      showCursor={permissions.isAtmSelldownOn}
                      onClick={() => handleToggleRow(offering.id)}
                      topBorder
                    >
                      {permissions.isAtmSelldownOn && (
                        <StyledTableCell noBorderBottom noPadding>
                          {openRows.includes(offering.id) ? (
                            <StyledKeyboardArrowUp />
                          ) : (
                            <StyledKeyboardArrowDown />
                          )}
                        </StyledTableCell>
                      )}
                      {atmTableModel.map((column, index) => (
                        <StyledTableCell
                          key={`${offering.id}_${index}`}
                          noBorderBottom
                          {...column.contentStyles}
                        >
                          {column.render(offering, permissions)}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                    {permissions.isAtmSelldownOn && (
                      <StyledNoSpaceRow colSpan={atmTableModel.length + 1}>
                        <Collapse in={openRows.includes(offering.id)} timeout="auto" unmountOnExit>
                          <SContainer>
                            <Typography variant="highlight1">Selldown data</Typography>
                            {offering.atmAttributes && permissions.canReadAtmSelldown ? (
                              <AtmSellDownReport
                                {...offering.atmAttributes}
                                pricingCurrency={offering.attributes.pricingCurrency}
                                isAtmProgramSize={isAtmProgramSize(offering.terms)}
                                structure={offering.atmAttributes?.structure ?? []}
                              />
                            ) : (
                              <AtmBlurTable
                                {...offering.atmAttributes}
                                pricingCurrency={offering.attributes.pricingCurrency}
                                isAtmProgramSize={isAtmProgramSize(offering.terms)}
                                terms={offering.terms ?? []}
                              />
                            )}
                          </SContainer>
                        </Collapse>
                      </StyledNoSpaceRow>
                    )}
                  </React.Fragment>
                );
              })}
              {!offerings?.length && <NoDataAvailable />}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledGrid>
    </React.Fragment>
  );
};

export default AtmOfferingsTable;
