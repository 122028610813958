import MoreVert from '@mui/icons-material/MoreVert';
import React from 'react';

import type { AppBarMenuProps } from '../shared/AppBarMenu';
import { AppBarMenu } from '../shared/AppBarMenu';

export type AppBarSecondaryMenuProps = Pick<AppBarMenuProps, 'renderItems' | 'label'>;

export const AppBarSecondaryMenu: React.VFC<AppBarSecondaryMenuProps> = ({ ...restMenuProps }) => {
  return <AppBarMenu id="application-secondary-menu" icon={<MoreVert />} {...restMenuProps} />;
};
