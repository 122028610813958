import { Box, Column, numericUtil, timeUtil } from '@cmg/common';
import isNil from 'lodash/isNil';
import React from 'react';

import PerformancePercents from '../../../../../common/components/format/performance-percents/PerformancePercents';
import { Performance, Pricing } from '../../model/offering';
import ValueWithMultilineTooltip from '../value-with-multiline-tooltip/ValueWithMultilineTooltip';
import ValueWithTooltip from '../value-with-tooltip/ValueWithTooltip';
import ValuesGrid from '../values-grid/ValuesGrid';
import ReOfferDiscount from './columns/ReOfferDiscount';

const { Label, Value } = ValuesGrid;

export type Props = {
  pricing: Pricing;
  performance: Performance;
  hasPriceDiscovery?: boolean;
  showInternational: boolean;
  getCurrencyFormat: (value: number | null) => JSX.Element | string;
  getCurrencyFormatInMillions: (value: number | null) => string;
  getCurrencyRangeFormat: (
    valueLow: number | null,
    valueHigh: number | null
  ) => JSX.Element | string;
};

const WidgetPricingAndPerformanceFollowOn: React.FC<Props> = ({
  pricing,
  performance,
  hasPriceDiscovery,
  showInternational,
  getCurrencyFormat,
  getCurrencyFormatInMillions,
  getCurrencyRangeFormat,
}) => (
  <Box>
    <div>
      <Box.Header title="Pricing &amp; Performance" />
      <Box.Content>
        <ValuesGrid>
          <ValuesGrid.Row>
            <Column xs={6}>
              <ValueWithTooltip
                value={
                  showInternational
                    ? getCurrencyFormatInMillions(pricing.marketCapLocalCurr)
                    : getCurrencyFormatInMillions(pricing.marketCap)
                }
                valueTooltip={numericUtil.formatInteger(pricing.postOfferingShares)}
                label="Market Cap at Pricing"
                labelTooltip="Shares Outstanding"
              />
            </Column>

            <Column xs={6}>
              <Value>
                {!isNil(pricing.pctPreOfferingMarketCap)
                  ? numericUtil.formatPercents(pricing.pctPreOfferingMarketCap, 1)
                  : 'N/A'}
                {' | '}
                {!isNil(pricing.pctPostOfferingMarketCap)
                  ? numericUtil.formatPercents(pricing.pctPostOfferingMarketCap, 1)
                  : 'N/A'}
              </Value>
              <Label>% Pre & Post Market Cap</Label>
            </Column>

            <Column xs={6}>
              <ValueWithMultilineTooltip
                value={
                  !isNil(pricing.sizeAsMultipleOfAdtv)
                    ? numericUtil.formatMultipleFactor(pricing.sizeAsMultipleOfAdtv)
                    : 'N/A'
                }
                valuesTooltip={[
                  numericUtil.formatInteger(pricing.preOfferingAdtv),
                  <span>
                    {pricing.adtvRangeLowDate &&
                      timeUtil.formatAsDisplayDate(pricing.adtvRangeLowDate)}
                    -
                    {pricing.adtvRangeHighDate &&
                      timeUtil.formatAsDisplayDate(pricing.adtvRangeHighDate)}
                  </span>,
                ]}
                label="Mult. of ADTV"
                labelsTooltip={['Pre-Offering ADTV', 'ADTV Range']}
              />
            </Column>

            <Column xs={6}>
              <ValueWithMultilineTooltip
                value={<PerformancePercents value={pricing.discountTo52wkHigh} nullValue="N/A" />}
                valuesTooltip={[
                  <span>{getCurrencyFormat(pricing.fiftyTwoWkHigh)}</span>,
                  <span>
                    {pricing.fiftyTwoWkHighDate &&
                      timeUtil.formatAsDisplayDate(pricing.fiftyTwoWkHighDate)}
                  </span>,
                ]}
                label="52-WK High Discount"
                labelsTooltip={['52 Week High Price', '52 Week High Date']}
              />
            </Column>
          </ValuesGrid.Row>

          <ValuesGrid.Row>
            {hasPriceDiscovery && (
              <Column xs={6}>
                <ValueWithTooltip
                  value={<PerformancePercents value={pricing.fileToOffer} nullValue="N/A" />}
                  valueTooltip={
                    showInternational
                      ? getCurrencyFormat(pricing.lastTradeBeforeFilingLocalCurr)
                      : getCurrencyFormat(pricing.lastTradeBeforeFiling)
                  }
                  label="File to Offer"
                  labelTooltip="Last Trade Before Filing"
                />
              </Column>
            )}

            {!hasPriceDiscovery && (
              <Column xs={6}>
                <ReOfferDiscount
                  pricing={pricing}
                  showInternational={showInternational}
                  getCurrencyRangeFormat={getCurrencyRangeFormat}
                />
              </Column>
            )}

            {pricing.discountToVwap ? (
              <Column xs={6}>
                <ValueWithMultilineTooltip
                  value={`${
                    !isNil(pricing.discountToLast)
                      ? numericUtil.formatPercents(pricing.discountToLast, 1)
                      : 'N/A'
                  } | ${numericUtil.formatPercents(pricing.discountToVwap, 1)}`}
                  valuesTooltip={[
                    <span>
                      {showInternational
                        ? getCurrencyFormat(pricing.lastTradeBeforeOfferLocalCurr)
                        : getCurrencyFormat(pricing.lastTradeBeforeOffer)}
                    </span>,
                    <span>{getCurrencyFormat(pricing.vwapPrice)}</span>,
                  ]}
                  label="To Last Trade & VWAP"
                  labelsTooltip={['Last Trade Before Pricing', 'VWAP Price']}
                />
              </Column>
            ) : (
              <Column xs={6}>
                <ValueWithTooltip
                  value={<PerformancePercents value={pricing.discountToLast} nullValue="N/A" />}
                  valueTooltip={
                    <span>
                      {showInternational
                        ? getCurrencyFormat(pricing.lastTradeBeforeOfferLocalCurr)
                        : getCurrencyFormat(pricing.lastTradeBeforeOffer)}
                    </span>
                  }
                  label="To Last Trade"
                  labelTooltip="Last Trade Before Pricing"
                />
              </Column>
            )}

            <Column xs={6}>
              <Value>
                {!isNil(pricing.allIn) ? numericUtil.formatPercents(pricing.allIn) : 'N/A'}
              </Value>
              <Label>All-in Cost</Label>
            </Column>

            <Column xs={6}>
              <ValueWithTooltip
                value={
                  !isNil(performance.firstDayTurnover)
                    ? numericUtil.formatMultipleFactor(performance.firstDayTurnover)
                    : 'N/A'
                }
                valueTooltip={numericUtil.formatInteger(pricing.firstDayVolume)}
                label="First Day Turnover"
                labelTooltip="First Day Volume"
              />
            </Column>
          </ValuesGrid.Row>

          <ValuesGrid.Row>
            <Column xs={6}>
              <ValueWithTooltip
                value={<PerformancePercents value={performance.openingPrice} nullValue="N/A" />}
                valueTooltip={getCurrencyFormat(performance.openingSharePrice)}
                label="Opening Price"
                labelTooltip="Opening Price"
              />
            </Column>

            <Column xs={6}>
              <ValueWithTooltip
                value={<PerformancePercents value={performance.day1} nullValue="N/A" />}
                valueTooltip={getCurrencyFormat(performance.day1SharePrice)}
                label="1 Day"
                labelTooltip="1 Day Close Price"
              />
            </Column>

            <Column xs={6}>
              <ValueWithTooltip
                value={<PerformancePercents value={performance.day3} nullValue="N/A" />}
                valueTooltip={getCurrencyFormat(performance.day3SharePrice)}
                label="3 Day"
                labelTooltip="3 Day Close Price"
              />
            </Column>

            <Column xs={6}>
              <ValueWithTooltip
                value={<PerformancePercents value={performance.day7} nullValue="N/A" />}
                valueTooltip={getCurrencyFormat(performance.day7SharePrice)}
                label="7 Day"
                labelTooltip="7 Day Close Price"
              />
            </Column>
          </ValuesGrid.Row>

          <ValuesGrid.Row>
            <Column xs={6}>
              <ValueWithTooltip
                value={<PerformancePercents value={performance.day14} nullValue="N/A" />}
                valueTooltip={getCurrencyFormat(performance.day14SharePrice)}
                label="14 Day"
                labelTooltip="14 Day Close Price"
              />
            </Column>

            <Column xs={6}>
              <ValueWithTooltip
                value={<PerformancePercents value={performance.day30} nullValue="N/A" />}
                valueTooltip={getCurrencyFormat(performance.day30SharePrice)}
                label="30 Day"
                labelTooltip="30 Day Close Price"
              />
            </Column>

            <Column xs={6}>
              <ValueWithTooltip
                value={<PerformancePercents value={performance.day90} nullValue="N/A" />}
                valueTooltip={getCurrencyFormat(performance.day90SharePrice)}
                label="90 Day"
                labelTooltip="90 Day Close Price"
              />
            </Column>

            <Column xs={6}>
              <ValueWithTooltip
                value={<PerformancePercents value={performance.current} nullValue="N/A" />}
                valueTooltip={getCurrencyFormat(performance.currentSharePrice)}
                label="Current"
                labelTooltip="Last Close Price"
              />
            </Column>
          </ValuesGrid.Row>
        </ValuesGrid>
      </Box.Content>
    </div>
  </Box>
);

export default WidgetPricingAndPerformanceFollowOn;
