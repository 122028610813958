import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  RegularBreakpoints,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@cmg/design-system';
import React from 'react';

import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import { useGetManagersData } from '../../shared/model/Shared.model';
import {
  cardContentStyle,
  cardHeaderStyle,
  cardHeight,
  cardTitleStyle,
  dividerStyle,
} from '../../shared/OfferingProfileCardItem';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
};
const tableContainerStyle: SxProps<Theme> = { maxHeight: 440 };
const tableRowStyle: SxProps<Theme> = {
  '& th': {
    color: 'text.secondary',
  },
};

const ManagersWidget: React.FC<Props> = ({ offeringProfile, cardSizes }) => {
  const managersData = useGetManagersData(offeringProfile);
  const title = 'Sales Agents';

  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight}>
        <Divider sx={dividerStyle} />
        <CardHeader
          title={
            <Typography variant="h3" sx={cardTitleStyle}>
              {title}
            </Typography>
          }
          aria-label={title}
          sx={cardHeaderStyle}
        />
        <CardContent sx={cardContentStyle}>
          <Grid container item alignItems="baseline">
            <Grid item xs={12}>
              <TableContainer sx={tableContainerStyle}>
                <Table stickyHeader>
                  <colgroup>
                    <col width="100%" />
                  </colgroup>
                  <TableHead>
                    <TableRow sx={tableRowStyle}>
                      <TableCell>Sales Agents</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {managersData.map(({ name }) => {
                      return (
                        <TableRow key={name}>
                          <TableCell>{name}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ManagersWidget;
