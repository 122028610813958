import { datalabApi } from '@cmg/api';
import { myDashboard } from '@cmg/e2e-selectors';
import isEmpty from 'lodash/isEmpty';
import React from 'react';

import NoRecentDeals from '../no-recent-deals/NoRecentDeals';
import RecentDealItem from './recent-deal-item/RecentDealItem';
import { formatDateWithDay } from './RecentDealsList.model';
import { SFirstTradedDateGroupHeader } from './RecentDealsList.styles';

type RecentDealsGroupedByFirstTradeDate = {
  [key: string]: datalabApi.CalendarOffering[];
};

export type Props = {
  recentDeals: RecentDealsGroupedByFirstTradeDate | null;
};

const RecentDealsList: React.FC<Props> = ({ recentDeals }) => {
  if (!recentDeals || isEmpty(recentDeals)) {
    return <NoRecentDeals />;
  }

  return (
    <React.Fragment>
      {Object.keys(recentDeals).map(firstTradeDate => {
        const deals = recentDeals[firstTradeDate];

        return (
          <div key={firstTradeDate} data-test-id={myDashboard.recentDealsGroup.testId}>
            <SFirstTradedDateGroupHeader>
              <div>First trade date:</div>
              <div>{formatDateWithDay(firstTradeDate)}</div>
            </SFirstTradedDateGroupHeader>
            {deals.map(deal => (
              <RecentDealItem key={deal.id} recentDeal={deal} />
            ))}
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default RecentDealsList;
