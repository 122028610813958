import {
  DLGWAverageField,
  DLGWTable,
  DLGWTableRow,
} from '@capital-markets-gateway/api-client-datalab-gateway';
import { loggerUtil } from '@cmg/common';

import { DataLabTable, DataLabTableRow } from '../../../../features/datalab/types';
import { mapOfferingType, mapSector, mapSecurityType } from './enumMappers';
import { mapDLGWFieldToLegacyField } from './tableFieldNameMappers';

// exported for testing
export const mapTableSummaries = (
  dlgwSummaries: DLGWTable['tableSummaries']
): DataLabTable['table_summaries'] => {
  if (!dlgwSummaries) {
    return {};
  }

  return dlgwSummaries.reduce((accumulator, currentSummary: DLGWAverageField) => {
    if (!currentSummary.field) {
      return accumulator;
    }

    const legacyFieldName = mapDLGWFieldToLegacyField(currentSummary.field);

    if (!legacyFieldName) {
      loggerUtil.info(
        `report-table responseMappers - could not map table summary field ${currentSummary.field}`
      );
      return accumulator;
    }

    return {
      ...accumulator,
      [legacyFieldName]: currentSummary.values,
    };
  }, {});
};

// exported for testing
export const mapPagination = (
  dlgwResponsePagination: DLGWTable['pagination']
): DataLabTable['pagination'] => {
  return {
    activePage: dlgwResponsePagination?.activePage!,
    perPage: dlgwResponsePagination?.perPage!,
    totalPages: dlgwResponsePagination?.totalPages!,
    hasNext: dlgwResponsePagination?.hasNext ?? undefined,
    hasPrevious: dlgwResponsePagination?.hasPrevious ?? undefined,
    totalCount: dlgwResponsePagination?.totalCount ?? undefined,
  };
};

// exported for testing
export const mapTableRow = (row: DLGWTableRow): DataLabTableRow => {
  return {
    allocation: row.allocation,
    allocation_investment: row.allocationInvestment,
    allocation_pct: row.allocationPct,
    sales_concession: row.salesConcession,
    color_code: row.colorCode,
    companies_cik: row.companiesCik,
    companies_cusip: row.companiesCusip,
    companies_issuer: row.companiesIssuer,
    companies_sub_sector: row.companiesSubSector,
    companies_ticker: row.companiesTicker,
    estimated_fee: row.estimatedFee,
    fifty_two_wk_high: row.fiftyTwoWkHigh,
    filing_details_deal_pct_at_pricing_market_cap: row.filingDetailsDealPctAtPricingMarketCap,
    filing_details_deal_pct_market_cap_pre_offering: row.filingDetailsDealPctMarketCapPreOffering,
    filing_details_gross_proceeds: row.filingDetailsGrossProceeds,
    filing_details_gross_proceeds_with_over_allotment:
      row.filingDetailsGrossProceedsWithOverAllotment,
    filing_details_market_cap_at_pricing: row.filingDetailsMarketCapAtPricing,
    filing_details_net_price: row.filingDetailsNetPrice,
    filing_details_offering_price: row.filingDetailsOfferingPrice,
    filing_details_pct_secondary_shares: row.filingDetailsPctSecondaryShares,
    filing_details_price_vs_range: row.filingDetailsPriceVsRange,
    filing_details_size_in_dollars: row.filingDetailsSizeInDollars,
    fill_rate: row.fillRate,
    ioi_shares: row.ioiShares,
    last_trade_before_filing: row.lastTradeBeforeFiling,
    last_trade_before_offer: row.lastTradeBeforeOffer,
    left_lead_firm_id: row.leftLeadFirmId,
    left_lead_firm_name: row.leftLeadFirmName,
    lock_up_early_release_date: row.lockUpEarlyReleaseDate,
    lock_up_expiration: row.lockUpExpiration,
    lock_up_period: row.lockUpPeriod,
    offering_confidential_filing_date: row.offeringConfidentialFilingDate,
    offering_day1_vwap_price: row.offeringDay1VwapPrice,
    offering_discount_to_vwap: row.offeringDiscountToVwap,
    offering_entity_structure: row.offeringEntityStructure?.join(', '),
    offering_first_day_turnover: row.offeringFirstDayTurnover,
    offering_first_day_volume: row.offeringFirstDayVolume,
    offering_first_trade_date: row.offeringFirstTradeDate,
    offering_gross_spread: row.offeringGrossSpread,
    offering_gross_spread_pct: row.offeringGrossSpreadPct,
    offering_id: row.offeringId,
    offering_initial_ipo_range_high: row.offeringInitialIpoRangeHigh,
    offering_initial_ipo_range_low: row.offeringInitialIpoRangeLow,
    offering_initial_registration_value: row.offeringInitialRegistrationValue,
    offering_offer_to_1day: row.offeringOfferTo1Day,
    offering_offer_to_3day: row.offeringOfferTo3Day,
    offering_offer_to_7day: row.offeringOfferTo7Day,
    offering_offer_to_14day: row.offeringOfferTo14Day,
    offering_offer_to_30day: row.offeringOfferTo30Day,
    offering_offer_to_90day: row.offeringOfferTo90Day,
    offering_offer_to_180day: row.offeringOfferTo180Day,
    offering_offer_to_current: row.offeringOfferToCurrent,
    offering_offer_to_one_year: row.offeringOfferToOneYear,
    offering_offer_to_open: row.offeringOfferToOpen,
    offering_offer_to_prior_quarter: row.offeringOfferToPriorQuarter,
    offering_offer_to_vwap_1day: row.offeringOfferToVwap1Day,
    offering_over_allotment_authorized: row.offeringOverAllotmentAuthorized,
    offering_over_allotment_exercised: row.offeringOverAllotmentExercised,
    offering_pct_change_in_size: row.offeringPctChangeInSize,
    offering_post_offering_shares: row.offeringPostOfferingShares,
    offering_preliminary_prospectus_link: row.offeringPreliminaryProspectusLink,
    offering_price_vs_midpoint: row.offeringPriceVsMidpoint,
    offering_primary_shares_base_offering: row.offeringPrimarySharesBaseOffering,
    offering_public_filing_date: row.offeringPublicFilingDate,
    offering_revised_ipo_range_high: row.offeringRevisedIpoRangeHigh,
    offering_revised_ipo_range_low: row.offeringRevisedIpoRangeLow,
    offering_secondary_shares_base_offering: row.offeringSecondarySharesBaseOffering,
    offering_security_type: mapSecurityType(row.offeringSecurityType),
    offering_settlement_date: row.offeringSettlementDate,
    offering_size: row.offeringSize,
    offering_total_bookrunners: row.offeringTotalBookrunners,
    offering_total_co_managers: row.offeringTotalCoManagers,
    offering_total_managers: row.offeringTotalManagers,
    offering_total_non_bookrunners: row.offeringTotalNonBookrunners,
    offering_total_pct_to_bookrunners: row.offeringTotalPctToBookrunners,
    offering_total_pct_to_co_managers: row.offeringTotalPctToCoManagers,
    offering_total_pct_to_left_lead: row.offeringTotalPctToLeftLead,
    offering_total_pct_to_non_bookrunners: row.offeringTotalPctToNonBookrunners,
    offering_vwap_price: row.offeringVwapPrice,
    offerings_all_in_cost: row.offeringsAllInCost,
    offerings_discount_to_52wk_high: row.offeringsDiscountTo52WkHigh,
    offerings_discount_to_last_trade: row.offeringsDiscountToLastTrade,
    offerings_file_to_offer_discount: row.offeringsFileToOfferDiscount,
    offerings_pre_offering_adtv: row.offeringsPreOfferingAdtv,
    offerings_pricing_date: row.offeringsPricingDate,
    offerings_sector: mapSector(row.offeringsSector),
    offerings_size_as_multiple_of_adtv: row.offeringsSizeAsMultipleOfAdtv,
    offerings_type: mapOfferingType(row.offeringsType),
    primary_shareholder_firm_id: row.primaryShareholderFirmId,
    primary_shareholder_name: row.primaryShareholderName,
    re_offer_discount_high: row.reOfferDiscountHigh,
    re_offer_discount_low: row.reOfferDiscountLow,
    re_offer_high: row.reOfferHigh,
    re_offer_low: row.reOfferLow,
    return_open: row.returnOpen,
    return_1day: row.return1Day,
    return_3day: row.return3Day,
    return_7day: row.return7Day,
    shareholders: row.shareholders?.map(shareholder => ({
      firm_id: shareholder.firmId,
      firm_name: shareholder.firmName,
      sponsor_type: null, // TODO missing in dlgw
      pre_offering_ownership: shareholder.preOfferingOwnership,
      pct_pre_offering_ownership: shareholder.pctPreOfferingOwnership,
      post_offering_ownership: shareholder.postOfferingOwnership,
      pct_post_offering_ownership: shareholder.pctPostOfferingOwnership,
    })),
    underwriters: row.underwriters?.map(underwriter => ({
      firm_name: underwriter.firmName,
      firm_id: underwriter.firmId,
      role: underwriter.role,
      economics_percent: underwriter.economicsPercent,
    })),
    filing_details_market_cap_pre_offering: row.filingDetailsMarketCapPreOffering,
    offering_use_of_proceeds: row.offeringUseOfProceeds?.join(', '), // TODO - need display text here like ruby returns. DLGW returns screaming snake enum.
    offering_forward_agreement: row.offeringForwardAgreement,
    offering_pre_offering_ownership: row.offeringPreOfferingOwnership,
    offering_post_offering_ownership: row.offeringPostOfferingOwnership,
    offering_pct_pre_offering_ownership: row.offeringPctPreOfferingOwnership,
    offering_pct_post_offering_ownership: row.offeringPctPostOfferingOwnership,
    offering_pre_offering_shares: row.offeringPreOfferingShares,
    total_shares_filed_excl_shoe: row.totalSharesFiledExclShoe,
    offering_launch_date: row.offeringLaunchDate,
    // timing_of_launch: row.timingOfLaunch, // row.timingOfLaunch, // dlgw MarketTiming is a malformed type (int enum instead of string enum)
    first_follow_on: row.firstFollowOn,
    offering_status: 'priced', // does not exist on dlgw, setting to what ruby always returns
    offering_split_adjusted_offering_price: row.offeringSplitAdjustedOfferingPrice,
    issuer_isin: row.issuerIsin,
    conditional_lock_up: row.conditionalLockUp,
    multiple_lock_ups: row.multipleLockUps,

    // TODO - missing newly added local currency fields. https://capitalmarketsgateway.atlassian.net/browse/ECM-28397

    // not in market pulse
    underwriting_table_shares: row.underwritingTableShares,
    left_lead: row.leftLead,
    underwriter_name: row.underwriterName,
    book_offerings: row.bookOfferings,
    non_book_offerings: row.nonBookOfferings,
    offerings: row.offerings,
    offerings_volume: row.offeringsVolume,
    underwritten_volume: row.underwrittenVolume,
    book_fees: row.bookFees,
    non_book_fees: row.nonBookFees,
    min: row.minFee,
    mean: row.meanFee,
    median: row.medianFee,
    max: row.maxFee,
    fees: row.totalFees,
    wallet_share: row.walletShare,
    follow_on_discount_from_filing: row.followOnDiscountFromFiling,
    follow_on_discount_to_last_trade: row.followOnDiscountToLastTrade,
    gross_spread: null, // row.grossSpread, // MISSING IN DLGW - should be a percentage field
    pct_of_market_cap: row.pctOfMarketCap,
    first_day_turnover: row.offeringFirstDayTurnover,
    offer_to_open: row.offeringOfferToOpen,
    offer_to_1day: row.offeringOfferTo1Day,
    offer_to_3day: row.offeringOfferTo3Day,
    offer_to_7day: row.offeringOfferTo7Day,
    offer_to_14day: row.offeringOfferTo14Day,
    offer_to_30day: row.offeringOfferTo30Day,
    offer_to_current: row.offeringOfferToCurrent,
    underwriter_role: row.underwriterRole,
    economics_pct: row.economicsPct,
    return_open_volume: row.returnOpenVolume,
    return_vwap_1day_volume: row.returnVwap1DayVolume,
    return_vwap_1day: row.returnVwap1Day,
    return_1day_volume: row.return1DayVolume,
    return_7day_volume: row.return7DayVolume,
    return_prior_quarter_volume: row.returnPriorQuarterVolume,
    return_prior_quarter: row.returnPriorQuarter,
    return_current_volume: row.returnCurrentVolume,
    return_current: row.returnCurrent,
    estimated_commission: row.estimatedCommission,
    sponsor_name: row.sponsorName,
    total_fees: row.totalFees,
    fund_name: row.fundName,
    ipos: row.ipos,
    fos: row.fos,
    pct_of_firm_offerings: row.pctOfFirmOfferings,
    pct_of_firm_total: row.pctOfFirmTotal,
    pct_of_firm_allocation: row.pctOfFirmAllocation,
    pct_of_deal: row.pctOfDeal,
    return_day7: row.return1Day,
    return_day3: row.return1Day,
    return_day1: row.return1Day,
    is_dual_listed: row.isDualListed,
    is_concurrent_offering: row.isConcurrentOffering,
    use_of_proceeds_note: row.useOfProceedsNote,
    is_synthetic_secondary: row.isSyntheticSecondary,
    is_up_listing: row.isUpListing,
    is_carve_out: row.isCarveOut,
    is_clean_up_trade: row.isCleanUpTrade,
    has_cornerstone_investors: row.hasCornerstoneInvestors,
    is_company_repurchase_included: row.isCompanyRepurchaseIncluded,
    is_company_repurchase_additional: row.isCompanyRepurchaseAdditional,
    egc: row.egc,
    naics: row.naics,
    headquarters: row.headquarters,
    initial_gross_proceeds_base: row.initialGrossProceedsBase,
    initial_gross_proceeds_base_local_curr: row.initialGrossProceedsBaseLocalCurr,
    revised_gross_proceeds_base: row.revisedGrossProceedsBase,
    revised_gross_proceeds_base_local_curr: row.revisedGrossProceedsBaseLocalCurr,
  };
};

/**
 * Map any dlgw report table response body (market pulse, participation summary, etc...) to
 *  the ruby legacy model.
 * @param responseBody - dlgw shape of report response
 * @returns - legacy ruby shape of report response
 */
export const mapDlgwResponseBodyToLegacy = (responseBody: DLGWTable): DataLabTable => {
  return {
    table_rows: responseBody.tableRows?.map(row => mapTableRow(row)) || [],
    table_summaries: mapTableSummaries(responseBody.tableSummaries),
    pagination: mapPagination(responseBody.pagination),
  };
};
