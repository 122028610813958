import trim from 'lodash/trim';
import React, { useEffect, useState } from 'react';

import { KEYS } from '../../../../shared/constants/constants';
import {
  SectorsGrid_CustomSectorFieldsFragment,
  SectorsGrid_CustomSectorPartsFragment,
  useSectorsGrid_CustomSectorListQuery,
} from '../graphql/__generated__/SectorsGrid_CustomSectors';
import {
  SErrorNote,
  SSectorInputWrapper,
  StyledSectorInput,
  StyledSectorPrimaryButton,
} from './SectorsGridComponents.styles';

// Gets names of sectors on the same level (all parents or parent's children)
export const getSectorNames = (
  sectors: SectorsGrid_CustomSectorPartsFragment[],
  parentId?: string,
  sector?: SectorsGrid_CustomSectorFieldsFragment
): string[] => {
  const sectorList = parentId ? sectors.find(s => s.id === parentId)?.children || [] : sectors;

  return sectorList.filter(s => (!sector || s.id !== sector.id) && s.name).map(s => s.name!);
};

export const checkIsDuplicate = (
  sectorName: string | null | undefined,
  sectorNames: string[] = []
): boolean => sectorNames.some(s => s.toLowerCase() === sectorName?.toLowerCase());

export type Props = {
  parentId?: string;
  sector?: SectorsGrid_CustomSectorFieldsFragment | SectorsGrid_CustomSectorPartsFragment;
  onEdit: (name: string) => void;
  loading?: boolean;
};

export const SectorEdit = ({ sector, parentId, onEdit, loading }: Props) => {
  const { data } = useSectorsGrid_CustomSectorListQuery({ fetchPolicy: 'cache-only' });
  const sectors = data?.customSectors?.items ?? [];

  const [sectorName, setSectorName] = useState<string | null>(null);

  useEffect(() => {
    setSectorName(sector?.name ?? null);
  }, [sector]);

  const handleEdit = () => {
    if (sectorName && trim(sectorName)) {
      onEdit(sectorName);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.key === KEYS.ENTER && handleEdit();
  };

  const isDuplicate = checkIsDuplicate(sectorName, getSectorNames(sectors, parentId, sector));

  return (
    <React.Fragment>
      <SSectorInputWrapper>
        <StyledSectorInput
          aria-label="Sector edit input"
          value={sectorName}
          onChange={setSectorName}
          onKeyDown={handleKeyDown}
          hasError={isDuplicate}
          disabled={loading}
        />
        {isDuplicate && <SErrorNote>Cannot add/edit a duplicate sector.</SErrorNote>}
      </SSectorInputWrapper>
      <StyledSectorPrimaryButton
        disabled={!sectorName || !trim(sectorName) || isDuplicate}
        onClick={handleEdit}
        loading={loading}
        loadingLabel="Saving..."
      >
        Save
      </StyledSectorPrimaryButton>
    </React.Fragment>
  );
};

export default SectorEdit;
