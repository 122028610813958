import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { Formik } from 'formik';
import flatten from 'lodash/flatten';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { CalendarCategory } from '../../../types/domain/calendar/constants';
import { getSpacByOfferingType } from '../../datalab/model/filters.model';
import { isInternationalOfferingsOn } from '../../datalab/model/utils';
import { filterChange, initFilter, selectFilterOptions, selectFilterValues } from '../ducks';
import { FilterValues } from '../model/calendar-filters';
import CalendarFilterForm from './CalendarFilterForm';

const mapStateToProps = state => ({
  filterValues: selectFilterValues(state),
  filterOptions: selectFilterOptions(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      filterChange,
      initFilter,
    },
    dispatch
  ),
});

export type OwnProps = {
  category: CalendarCategory;
};
export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

export function mapSubmittedValues(values: FilterValues): FilterValues {
  const { offeringType, spac: isSpac, countries, ...restValues } = values;
  const spac = getSpacByOfferingType(offeringType);
  const countriesFilterVal = countries.map(val => val.split(','));
  return {
    ...restValues,
    offeringType,
    spac: spac,
    countries: flatten(countriesFilterVal),
  };
}

export const CalendarFilterComponent: React.FC<Props> = ({
  actions,
  filterOptions,
  filterValues,
  category,
}) => {
  useEffect(() => {
    actions.initFilter();
  }, [actions]);

  const showInternational = isInternationalOfferingsOn();
  const canReadCustomSectors = useCheckPermissions([permissionsByEntity.CustomSectors.READ]);

  return (
    <Formik
      initialValues={filterValues}
      enableReinitialize
      onSubmit={values => {
        actions.filterChange(mapSubmittedValues(values));
      }}
      render={({ values: { useCustomSectors }, handleSubmit }) => (
        <CalendarFilterForm
          category={category}
          showInternational={showInternational}
          filterOptions={filterOptions}
          useCustomSectors={canReadCustomSectors ? useCustomSectors : false}
          handleSubmit={handleSubmit}
        />
      )}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarFilterComponent);
