import { Box, FormField, PrimaryButton, TextArea } from '@cmg/common';
import { Form } from 'antd';
import React from 'react';

import { UserNote } from '../../../../types/domain/offering/user-note';
import { StyledBox, StyledForm } from './WidgetUserNote.styles';

export type Props = {
  handleSaveUserNote: (values) => void;
  userNote?: UserNote | null;
};

const WidgetUserNoteComponent: React.FC<Props> = ({ handleSaveUserNote, userNote }) => {
  const [form] = Form.useForm();

  // Sometimes userNote comes in as null at first, which breaks Form.Item's initialValue mechanism.
  React.useEffect(() => {
    if (userNote?.note && form.getFieldValue('note') !== userNote.note) {
      form.setFieldsValue({ note: userNote.note });
    }
  }, [userNote?.note, form]);

  return (
    <StyledBox>
      <Box.Content>
        <StyledForm form={form} onFinish={handleSaveUserNote}>
          <FormField>
            <Form.Item name="note" initialValue={userNote?.note}>
              <TextArea placeholder="Your Notes" rows={6} value={form.getFieldValue('note')} />
            </Form.Item>
          </FormField>
          <PrimaryButton type="submit">Save Notes</PrimaryButton>
        </StyledForm>
      </Box.Content>
    </StyledBox>
  );
};

export default WidgetUserNoteComponent;
