import { DateRangePresetTypes } from '@cmg/common';

import { followOnOfferingTypeOptions, ipoOfferingTypeOptions } from '../../constants/constants';

export function getOfferingTypeOptions(isIPO) {
  if (isIPO) {
    return ipoOfferingTypeOptions;
  } else {
    return followOnOfferingTypeOptions;
  }
}

export type FormValues = {
  underwriters: any[];
  sponsors: any[];
  advisory: any[];
  date: { start?: string; end?: string; type?: string };
  marketCap: { min: any; max: any };
  offeringSize: { min: any; max: any };
  sellingShareholderPct: { min: any; max: any };
  leftLeadOnly: any;
  offeringType: any[];
  sector: any[];
  customSector: any[];
  useCustomSectors: boolean;
};

export function mapFormValuesToEntity(formValues: FormValues) {
  return {
    advisory: formValues.advisory,
    sponsor: formValues.sponsors,
    underwriter: formValues.underwriters,
    dateRangeStart: formValues.date.start,
    dateRangeEnd: formValues.date.end,
    dateRangeType: formValues.date.type,
    leftLeadOnly: formValues.leftLeadOnly,
    marketCapMin: formValues.marketCap.min,
    marketCapMax: formValues.marketCap.max,
    offeringSizeMin: formValues.offeringSize.min,
    offeringSizeMax: formValues.offeringSize.max,
    sellingShareholderPctMin: formValues.sellingShareholderPct.min,
    sellingShareholderPctMax: formValues.sellingShareholderPct.max,
    offeringType: formValues.offeringType,
    sector: formValues.sector,
    customSector: formValues.customSector,
    useCustomSectors: formValues.useCustomSectors,
  };
}

export function mapEntityToFormValues(
  marketCapMin,
  marketCapMax,
  offeringType,
  sectors,
  customSectorIds,
  useCustomSectors
): FormValues {
  return {
    underwriters: [],
    sponsors: [],
    advisory: [],
    date: { type: DateRangePresetTypes.L90D },
    marketCap: {
      min: marketCapMin,
      max: marketCapMax,
    },
    offeringSize: {
      min: undefined,
      max: undefined,
    },
    sellingShareholderPct: {
      min: undefined,
      max: undefined,
    },
    leftLeadOnly: undefined,
    offeringType: [offeringType],
    sector: sectors,
    customSector: customSectorIds,
    useCustomSectors: useCustomSectors,
  };
}
