import type { TextFieldProps } from '@mui/material/TextField';
import MUITextField from '@mui/material/TextField';
import React from 'react';
import type { NumberFormatValues, NumericFormatProps } from 'react-number-format';
import { NumericFormat } from 'react-number-format';

import type { TestProps } from '../../../types';

type NumericInputFormatProps = Pick<
  NumericFormatProps,
  'decimalScale' | 'fixedDecimalScale' | 'allowNegative'
> & {
  onInputChange?: (value: bigint | number | null) => void;
  value?: bigint | number | null;
};

export type NumberFieldProps = TestProps &
  Omit<TextFieldProps, 'onChange'> &
  NumericInputFormatProps;

const InputNumericFormat = React.forwardRef<HTMLElement, NumericInputFormatProps>(
  ({ onInputChange, ...props }, ref) => {
    const handleInputChange = React.useCallback(
      (value: NumberFormatValues) => {
        onInputChange?.(value.floatValue ?? null);
      },
      [onInputChange]
    );

    return (
      <NumericFormat
        {...props}
        value={props.value?.toString() ?? ''}
        getInputRef={ref}
        onValueChange={handleInputChange}
        thousandSeparator
        valueIsNumericString
      />
    );
  }
);

export const NumberField = React.forwardRef<HTMLDivElement, NumberFieldProps>(
  (
    { decimalScale, fixedDecimalScale, allowNegative, onInputChange, tabIndex, ...props },
    ref
  ): JSX.Element => {
    const inputProps = React.useMemo<TextFieldProps['InputProps']>(
      () => ({
        ...props.InputProps,
        // any is also used in demo for integration of react-number-format on mui documentation
        // https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
        inputComponent: InputNumericFormat as any,
        inputProps: {
          decimalScale,
          fixedDecimalScale,
          allowNegative,
          onInputChange,
          style: { textAlign: 'right' },
          tabIndex,
        },
      }),
      [decimalScale, fixedDecimalScale, allowNegative, onInputChange, tabIndex, props.InputProps]
    );

    return <MUITextField {...props} ref={ref} InputProps={inputProps} />;
  }
);
