import Stack from '@mui/material/Stack';
import React from 'react';

export const HighlightsItemList: React.FC = ({ children }) => {
  return (
    <Stack
      alignItems="center"
      direction="row"
      flexWrap="wrap"
      columnGap={2}
      rowGap={1}
      role="table"
    >
      {children}
    </Stack>
  );
};
