import { datalabApi } from '@cmg/api';

export const filterSectors = (
  sectors: datalabApi.CustomSectorDto[] | null,
  filter: string
): datalabApi.CustomSectorDto[] => {
  const filterLower = filter.toLowerCase();

  if (sectors) {
    if (filter) {
      return sectors
        .map(sec => ({
          ...sec,
          subSectors: (sec.subSectors || []).filter(sub =>
            sub.sectorName.toLowerCase().includes(filterLower)
          ),
        }))
        .filter(sec => sec.sectorName.toLowerCase().includes(filterLower) || sec.subSectors.length);
    }

    return sectors;
  }

  return [];
};
