import { Alert, IconButton, TextInput } from '@cmg/common';
import orderBy from 'lodash/orderBy';
import xor from 'lodash/xor';
import React, { useMemo, useState } from 'react';

import SectorAdd from './components/SectorAdd';
import SectorRow from './components/SectorRow';
import { useSectorsGrid_CustomSectorListQuery } from './graphql/__generated__/SectorsGrid_CustomSectors';
import { filterSectors } from './SectorsGrid.model';
import {
  SSectorFilter,
  SSectorParent,
  SSectorRowWrapper,
  SSectorsUl,
  StyledIcon,
  StyledSpinner,
} from './SectorsGrid.styles';

export type Props = {
  canUpdate: boolean;
};

const SectorsGrid = ({ canUpdate }: Props) => {
  const { data, loading, error } = useSectorsGrid_CustomSectorListQuery();

  const [filter, setFilter] = useState('');
  const filteredSectors = useMemo(
    () => filterSectors(data?.customSectors?.items, filter),
    [data, filter]
  );

  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const toggleCollapsedId = (id: string) => setExpandedIds(xor(expandedIds, [id]));

  return (
    <StyledSpinner show={loading} testId="Sectors-SectorsGrid-Loading">
      {error && (
        <Alert severity="error" withMargin>
          {error.message}
        </Alert>
      )}
      <SSectorFilter>
        <TextInput
          prefix={<StyledIcon name="filter" />}
          suffix={
            filter && (
              <div role="button" onClick={() => setFilter('')}>
                <StyledIcon name="times-circle" />
              </div>
            )
          }
          value={filter}
          onChange={value => setFilter(value || '')}
          placeholder="Filter"
        />
      </SSectorFilter>
      <SSectorsUl>
        {filteredSectors.map(sector => (
          <li key={sector.id}>
            <SSectorParent>
              {!sector.children.length ? (
                <SSectorRowWrapper>
                  <SectorRow sector={sector} canUpdate={canUpdate} />
                </SSectorRowWrapper>
              ) : (
                <React.Fragment>
                  <IconButton
                    icon={{
                      name: !expandedIds.includes(sector.id) ? 'caret-right' : 'caret-down',
                    }}
                    onClick={() => toggleCollapsedId(sector.id)}
                    title="Expand sector"
                  />

                  <SectorRow sector={sector} canUpdate={canUpdate} />
                </React.Fragment>
              )}
            </SSectorParent>
            <ul>
              {expandedIds.includes(sector.id) &&
                orderBy(sector.children || [], 'name', 'asc').map(sub => (
                  <li key={sub.id}>
                    <SectorRow sector={sub} parentId={sector.id} canUpdate={canUpdate} />
                  </li>
                ))}
              {canUpdate && (
                <li>
                  <SectorAdd parentId={sector.id} />
                </li>
              )}
            </ul>
          </li>
        ))}
        {canUpdate && (
          <li>
            <SectorAdd />
          </li>
        )}
      </SSectorsUl>
    </StyledSpinner>
  );
};

export default SectorsGrid;
