import { datalabApi } from '@cmg/api';
import React from 'react';

import CalendarIconButton from './CalendarIconButton';

type Props = {
  offering: datalabApi.CalendarOffering;
  onClick: (offering: datalabApi.CalendarOffering) => void;
};

const PrecedentReportButton: React.FC<Props> = ({ offering, onClick }) => (
  <CalendarIconButton
    onClick={() => onClick(offering)}
    iconName="search"
    popoverContent={<span>Precedent Transactions</span>}
  />
);

export default PrecedentReportButton;
