import type { InputProps } from '@mui/material/Input';
import { useTheme } from '@mui/material/styles';
import { unstable_useId as useId } from '@mui/material/utils';
import React, { useMemo, useState } from 'react';

import type { TooltipProps } from '../../components/feedback/tooltip/Tooltip';
import { Tooltip } from '../../components/feedback/tooltip/Tooltip';

export type ControlWithHelperTextRenderControlProps = Readonly<{
  /**
   * Helper text to be passed to rendered control
   */
  readonly helperText?: React.ReactNode;
  /**
   * Properties to be passed to input described by the tooltip
   */
  readonly inputProps: Pick<InputProps, 'aria-describedby'>;
}>;

export type ControlWithHelperTextProps = Readonly<{
  /**
   * True when helper text should be rendered inside tooltip, false otherwise
   */
  readonly showHelperTextInTooltip?: boolean;
  readonly tooltipVariant?: TooltipProps['variant'];
  /**
   * Helper text
   */
  readonly helperText?: React.ReactNode;
  /**
   * Method triggered on place where control should be rendered
   * @param props Properties
   * @returns Rendered control
   */
  readonly renderControl: (props: ControlWithHelperTextRenderControlProps) => JSX.Element;
  /**
   * For manual control of tooltip open state
   */
  readonly open?: boolean;
}>;

export function ControlWithHelperText({
  helperText,
  showHelperTextInTooltip,
  tooltipVariant,
  open,
  renderControl,
}: ControlWithHelperTextProps): JSX.Element {
  const tooltipId = useId();
  const { transitions } = useTheme();
  const [isTooltipOpened, setIsTooltipOpened] = useState(false);

  const renderControlProps = useMemo(
    (): ControlWithHelperTextRenderControlProps => ({
      inputProps: isTooltipOpened ? { 'aria-describedby': tooltipId } : {},
      helperText: showHelperTextInTooltip ? undefined : helperText,
    }),
    [helperText, isTooltipOpened, showHelperTextInTooltip, tooltipId]
  );

  return showHelperTextInTooltip ? (
    <Tooltip
      id={tooltipId}
      disableHoverListener
      arrow
      placement="top"
      variant={tooltipVariant}
      title={helperText}
      open={open}
      TransitionProps={{ timeout: { exit: 0, enter: transitions.duration.enteringScreen } }}
      onOpen={() => setIsTooltipOpened(true)}
      onClose={() => setIsTooltipOpened(false)}
    >
      {renderControl(renderControlProps)}
    </Tooltip>
  ) : (
    renderControl(renderControlProps)
  );
}
