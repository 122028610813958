import styled, { css } from 'styled-components/macro';

import { Props } from './Table';

/**
 * The styling of this table is meant to make all the tables across our repository look consistent
 */
export const STable = styled.table<Props>`
  width: 100%;
  border-spacing: 0;

  > thead {
    > tr {
      > th {
        text-align: left;
        padding: 7px;
        margin-bottom: 2px;
        color: ${({ theme }) => theme.text.color.light};
        text-transform: uppercase;
        font-size: 11px; // till we resolve font.size.small is either 11px or 12px
        font-weight: ${({ theme }) => theme.text.weight.medium};
        vertical-align: bottom;

        button {
          font-weight: ${({ theme }) => theme.text.weight.medium};
          font-size: 11px; // till we resolve font.size.small is either 11px or 12px
          color: ${({ theme }) => theme.text.color.light};
        }

        ${({ headerSeparator, theme }) =>
          headerSeparator &&
          css`
            &:not(:first-child) {
              border-left: ${theme.border.width.small} solid ${theme.border.color.dark};
            }
          `}
      }
    }
  }

  > tbody {
    font-size: 13px; /* matching ag-grid */

    > tr {
      background-color: ${({ theme }) => theme.background.color.white};

      > td,
      > th {
        vertical-align: top;
      }

      td {
        padding: ${({ withCellMargin = true }) => (withCellMargin ? css`6px 8px` : css`5px`)};
      }

      ${({ striped, theme }) =>
        striped &&
        css`
          &:nth-child(odd) {
            background-color: ${theme.background.color.light};
          }

          &:nth-child(2n) {
            background-color: ${theme.background.color.white};
          }
        `}

      ${({ reversed, striped, theme }) =>
        striped &&
        reversed &&
        css`
          &:nth-child(odd) {
            background-color: ${theme.background.color.white};
          }

          &:nth-child(2n) {
            background-color: ${theme.background.color.light};
          }
        `}
    }
  }
`;

export const SResponsiveWrapper = styled.div<{ responsive?: boolean }>`
  ${({ responsive, theme }) =>
    responsive &&
    css`
      overflow-x: auto;

      ${theme.mediaQuery.mediumDown} {
        width: 100%;
        margin-bottom: 15px;

        ${STable} {
          th,
          td {
            white-space: nowrap;
          }
        }
      }
    `};
`;

export const SRightHeader = styled.th`
  /* Override parent table style */
  &&& {
    text-align: right;
  }
`;

export const SRightCell = styled.td`
  text-align: right;
`;

export const SSummaryRow = styled.tr`
  td {
    font-weight: ${({ theme }) => theme.text.weight.medium};
  }
`;
