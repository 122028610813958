import type H from 'history';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

type ToType<S = unknown> =
  | H.LocationDescriptor<S>
  | ((location: H.Location<S>) => H.LocationDescriptor<S>);

type Props<S> = {
  readonly to: ToType<S>;
  readonly replace?: boolean;
};

export const LinkRenderer = <S extends unknown = unknown>(props: PropsWithChildren<Props<S>>) => {
  const { children, to, replace } = props;
  return (
    <Link to={to} replace={replace}>
      {children}
    </Link>
  );
};

export default LinkRenderer;
