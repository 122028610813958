import React from 'react';

import type { HighlightsItemProps } from './HighlightsItem';
import { HighlightsItem } from './HighlightsItem';

export type HighlightsTextItemProps = Readonly<
  Pick<HighlightsItemProps, 'name' | 'data-test-id'> & {
    text: string;
  }
>;

export function HighlightsTextItem({ text, ...itemProps }: HighlightsTextItemProps): JSX.Element {
  return <HighlightsItem {...itemProps} value={text} />;
}
