import { loggerUtil } from '..';

export const parseCsvStringArray = (
  value: string | undefined,
  defaultValue: [] = [],
  separator: string = ','
) => {
  return value ? value.split(separator) : defaultValue;
};

/**
 * Given a string equalling "true", "false", or undefined and a default
 * value, attempts to cast the string to a boolean value - or returns
 * the provided default value.
 *
 * @param boolString either the strings "true" or "false"
 * @param boolDefault a default boolean value to return if boolString is undefined
 */
export const parseBoolOrThrow = (boolString?: string, boolDefault: boolean = false) => {
  if (boolString && boolString !== 'true' && boolString !== 'false') {
    throw new Error('Parseable boolean must be "true" or "false"');
  }

  if (boolString === 'true') {
    return true;
  }

  if (boolString === 'false') {
    return false;
  }

  return boolDefault;
};

/**
 * Of the two string parameters provided, returns the value that is truthy -
 * giving value precedence, then defaultValue.
 *
 * If both are falsy, an error is thrown.
 *
 * @param value - a string value
 * @param defaultValue - a string value to return if value is falsy
 */
export const getOrThrow = (value?: string, defaultValue?: string) => {
  const val = value || defaultValue;

  if (!val) {
    throw new Error('Must supply a value for environment variable');
  }

  return val;
};

/**
 * Given a string containing a float value (or undefined) and optionally a default
 * float value, attempts to cast the string to a float - or returns
 * the provided default value.
 *
 * If both arguments are falsy, an error is thrown.
 *
 * @param stringNumber - a string containing a float value
 * @param numberDefault - a default float value to return if stringNumber is falsy
 */
export const parseFloatOrThrow = (stringNumber?: string, numberDefault?: number) => {
  if (stringNumber && Number.isNaN(Number.parseFloat(stringNumber))) {
    throw new Error('Parseable number must be a valid float');
  }

  const parsedNumber = stringNumber ? Number.parseFloat(stringNumber) : numberDefault;

  if (parsedNumber === undefined) {
    throw new Error('Must supply a number for environment variable');
  }

  return parsedNumber;
};

export const parseEnumOrThrow = <TEnum extends { [key: string]: string }>(
  enumType: TEnum,
  envValue?: keyof TEnum | string,
  defaultValue?: keyof TEnum | string
) => {
  const value = envValue || defaultValue;
  if (!value) {
    throw new Error('Must supply a value for environment variable');
  }

  const enumValues = Object.values(enumType);

  if (!enumValues.includes(value as string)) {
    throw new Error(`Must supply value from ${enumValues.toString()}`);
  }

  return value as keyof TEnum;
};

/**
 * A shim to retrieve env variables provided as overrides by a web app user at runtime.
 * @returns any overrides stored in 'env' property of session storage
 */
export const getSessionEnvOverrides = () => {
  const raw = sessionStorage?.getItem('env');

  if (!raw) {
    return undefined;
  }

  try {
    return JSON.parse(raw);
  } catch (err) {
    loggerUtil.error(
      new Error('getSessionEnvOverrides: Could not parse session env var overrides')
    );
    return undefined;
  }
};

/**
 * A shim to retrieve env variables provided as overrides by a web app user at runtime.
 * A temporary solution for OIDC testing only, use getSessionEnvOverrides instead!
 * @returns any overrides stored in 'env' property of local storage
 */
export const getLocalStorageEnvOverrides = () => {
  const raw = localStorage?.getItem('env');

  if (!raw) {
    return undefined;
  }

  try {
    return JSON.parse(raw);
  } catch (err) {
    loggerUtil.error(
      new Error('getLocalStorageEnvOverrides: Could not parse local storage env var overrides')
    );
    return undefined;
  }
};
