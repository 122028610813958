import { createTestId } from '../../common/selectors';

export * from './offering-details';
export * from './offering-header';

const create = (name: string) => createTestId(`xc-my-offering-${name}`);

export const offeringScreen = create('screen');

// navbar
export const offeringNavbar = create('navbar');
export const offeringNavBarOfferingDetails = create('navbar-offering-details');
export const offeringNavBarRegulatoryFilings = create('navbar-regulatory-filings');
export const offeringNavBarRoadshow = create('navbar-roadshow');
export const offeringNavBarOrderBook = create('navbar-order-book');
export const offeringNavBarSyndicateWires = create('navbar-syndicate-wires');

// order book sub nav item
export const offeringNavBarOrderBookStatus = create('navbar-order-book-status');
