import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React from 'react';

export type AlertListContentProps = Readonly<{
  messages: string | string[];
}>;

const AlertListContent: FC<AlertListContentProps> = ({ messages }) => {
  if (!messages.length) {
    return null;
  }

  if (typeof messages === 'string') {
    return <Typography variant="body2">{messages}</Typography>;
  }

  if (messages.length === 1) {
    return <Typography variant="body2">{messages[0]}</Typography>;
  }

  return (
    <List sx={{ listStyleType: 'disc', pl: 2 }} dense disablePadding>
      {messages.map((message, index) => (
        <ListItem
          aria-label={message}
          key={`${message}_${index}`}
          disablePadding
          sx={{ display: 'list-item' }}
        >
          <Typography variant="body2">{message}</Typography>
        </ListItem>
      ))}
    </List>
  );
};

export default AlertListContent;
