import { createTestId } from '../../../common/selectors';

const create = (name: string) => createTestId(`xc-wires-designation-${name}`);

export const wiresDesignationScreen = create('screen');

export const wiresDesignationModalCancelButton = create('modal-cancel-button');
export const wiresDesignationModalSaveButton = create('modal-save-button');

export const wiresDesignationEditFormDesignationsInput = create('edit-form-designations-input');
export const wiresDesignationEditFormHeaderNoteInput = create('edit-form-header-note-input');

export const wiresDesignationCreateNewVersionButton = create('create-new-version-button');
export const wiresDesignationEditWireButton = create('edit-wire-button');
export const wiresDesignationDeleteWireButton = create('delete-wire-button');
export const wiresDesignationSendWireButton = create('send-wire-button');

export const wiresDesignationSendWireModalNoButton = create('send-wire-modal-no-button');
export const wiresDesignationSendWireModalYesButton = create('send-wire-modal-yes-button');

export const wiresDesignationDeleteWireModalNoButton = create('delete-wire-modal-no-button');
export const wiresDesignationDeleteWireModalYesButton = create('delete-wire-modal-yes-button');
