import { createTestId } from '../../../../common/selectors';

const create = (name: string) => createTestId(`xc-roadshows-configuration-management-team-${name}`);

export const roadshowManagementTeamConfigScreen = create('screen');
export const roadshowManagementTeamConfigViewCalendarButton = create('view-calendar-button');
export const roadshowManagementTeamConfigPrevButton = create('prev-button');
export const roadshowManagementTeamConfigNextButton = create('next-button');
export const roadshowManagementTeamConfigSearchModalNewCompanyButton = create(
  'search-modal-new-company-button'
);
export const roadshowManagementTeamConfigSearchModalResultsNewCompanyButton = create(
  'search-modal-results-new-company-button'
);
export const roadshowManagementTeamConfigSearchModalCancelButton = create(
  'search-modal-cancel-button'
);
export const roadshowManagementTeamConfigSearchModalAddButton = create('search-modal-add-button');
export const roadshowManagementTeamConfigAddCompanyButton = create('add-company-button');
export const roadshowManagementTeamConfigAddCompanyLinkButton = create('add-company-link-button');
