import TextField from '@mui/material/TextField';
import { TimePicker as MUITimePickerField } from '@mui/x-date-pickers/TimePicker';
import React from 'react';

import type { TestProps } from '../../../types';

export type TimePickerFieldProps = TestProps & {
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  label?: React.ReactNode;
  size?: 'small' | 'medium';
  value: Date | null;
  onChange: (value: number | null, keyboardInputValue?: string) => void;
};

export const TimePickerField: React.FC<TimePickerFieldProps> = ({
  'data-test-id': testID,
  label,
  required,
  error,
  helperText,
  size,
  ...props
}) => {
  return (
    <MUITimePickerField
      {...props}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          required={required}
          error={error}
          helperText={helperText}
          size={size}
          data-test-id={testID}
        />
      )}
    />
  );
};
