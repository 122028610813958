import { LogLevel } from '@microsoft/signalr';
import React from 'react';

import { SignalRConnectionStore, SignalRContextInterface } from './SignalRContext.model';

export const SignalRContext = React.createContext<SignalRContextInterface>({
  connectionsStore: null,
});

export const SignalRContextProvider: React.FC<{ token?: string | null; logLevel?: LogLevel }> = ({
  children,
  token,
  logLevel,
}) => {
  const [connectionsStore, setConnectionsStore] = React.useState<SignalRConnectionStore | null>(
    null
  );

  React.useEffect(() => {
    const connections = token ? new SignalRConnectionStore(token, logLevel) : null;
    setConnectionsStore(connections);

    if (!connections) {
      return;
    }

    return () => {
      connections.removeAllListeners();
    };
  }, [logLevel, token]);

  return <SignalRContext.Provider value={{ connectionsStore }}>{children}</SignalRContext.Provider>;
};
