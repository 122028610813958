import { cmgSectorScheme, TextInput } from '@cmg/common';
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { TreeSelect } from '../../../../../common/components/form';
import { mapGraphqlSectorsToOptions } from '../../../../shared/sectors/sectors.model';
import { SectorsGrid_CustomSectorPartsFragment } from '../../../sectors/sectors-grid/graphql/__generated__/SectorsGrid_CustomSectors';
import { SCompaniesFilters, StyledFormField } from './CompaniesFilters.styles';

export type FilterValues = {
  searchTerm?: string;
  sectorValue?: string;
  subSectorValue?: string;
  customSectorId?: string;
  customSubSectorId?: string;
};

const overrideFields = {
  searchTerm: {},
  sectorValue: { sectorValue: undefined, subSectorValue: undefined },
  subSectorValue: { sectorValue: undefined, subSectorValue: undefined },
  customSectorId: { customSectorId: undefined, customSubSectorId: undefined },
  customSubSectorId: { customSectorId: undefined, customSubSectorId: undefined },
};

export type Props = {
  sectors: SectorsGrid_CustomSectorPartsFragment[] | null | undefined;
  onChange: (values: FilterValues) => void;
};

const CompaniesFilters = ({ onChange, sectors }: Props) => {
  const [values, setValues] = useState<FilterValues>({});

  const debouncedOnChange = useDebouncedCallback(onChange, 300);

  const handleChange = (name: keyof FilterValues, value: string | undefined) => {
    const newValues = { ...values, ...overrideFields[name], [name]: value };
    setValues(newValues);
    if (name === 'searchTerm') {
      debouncedOnChange(newValues);
    } else {
      onChange(newValues);
    }
  };

  return (
    <SCompaniesFilters>
      <StyledFormField label="Search Companies">
        <TextInput
          testId="CompaniesFilters-SearchCompanies"
          value={values.searchTerm || ''}
          onChange={value => handleChange('searchTerm', value)}
          placeholder="Search..."
        />
      </StyledFormField>
      <StyledFormField label="Filter by Sectors">
        <TreeSelect
          testId="CompaniesFilters-FilterBySectors"
          value={values.sectorValue || values.subSectorValue}
          treeData={cmgSectorScheme}
          onChange={(value: string | undefined) =>
            handleChange(
              cmgSectorScheme.some(sector => sector.value === value)
                ? 'sectorValue'
                : 'subSectorValue',
              value
            )
          }
          treeCheckable={false}
        />
      </StyledFormField>
      <StyledFormField label="Filter by My Sectors">
        <TreeSelect
          testId="CompaniesFilters-FilterByCustomSectors"
          value={values.customSectorId || values.customSubSectorId}
          treeData={mapGraphqlSectorsToOptions(sectors)}
          onChange={(value: string | undefined) =>
            handleChange(
              sectors?.some(sector => sector.id === value) ? 'customSectorId' : 'customSubSectorId',
              value
            )
          }
          treeCheckable={false}
        />
      </StyledFormField>
    </SCompaniesFilters>
  );
};

export default CompaniesFilters;
