import styled from 'styled-components/macro';

export const SNavigationWrapper = styled.nav`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: ${({ theme }) => theme.background.color.white};
`;

export const SNavGroup = styled.div`
  /* on smaller screens make the sub header group horizontal scroll enabled */
  overflow-x: auto;
  /* IE displays a vertical scroll that has no purpose */
  overflow-y: hidden;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;

  /* desktop screens will wrap additional content that exceeds the row */
  ${({ theme }) => theme.mediaQuery.mediumUp} {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const SNavItem = styled.div`
  margin: 8px 20px 0;
  display: inline-block;
`;
