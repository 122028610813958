import styled, { css } from 'styled-components/macro';

import IconButton from '../../../../../components/buttons/button/IconButton';
import RadioGroup from '../../../../../components/form/radio/RadioGroup';

type Focusable = {
  onFocus?: (event: React.FocusEvent<Element>) => void;
  onBlur?: (event: React.FocusEvent<Element>) => void;
};

export const SPopoverContent = styled.div<Focusable>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 4px;
`;

export const StyledArrowButton = styled(IconButton)`
  height: 16px;
`;

export const StyledRadioGroup = styled(RadioGroup)`
  margin: 4px 16px;
`;

export const STimeCell = styled.div`
  display: flex;
  flex-direction: column;
  border: ${({ theme }) =>
    `${theme.border.width.small} solid ${theme.foundation.colors.secondary[100]}`};
  border-radius: 2px;
`;

export const STime = styled.div<{ isEmpty: boolean }>`
  width: 64px;
  height: 28px;
  text-align: right;
  padding: 2px 8px;
  color: ${({ theme }) => theme.designSystem.colors.darkBlue[200]};
  border-top: ${({ theme }) =>
    `${theme.border.width.small} solid ${theme.foundation.colors.secondary[100]}`};
  border-bottom: ${({ theme }) =>
    `${theme.border.width.small} solid ${theme.foundation.colors.secondary[100]}`};

  ${({ isEmpty }) =>
    isEmpty &&
    css`
      color: ${({ theme }) => theme.foundation.colors.secondary[300]};
    `}
`;
