/* istanbul ignore file */
/* eslint-disable react-hooks/exhaustive-deps */
/** Partially ported from https://github.com/kotarella1110/use-custom-compare */
import isEqual from 'lodash/isEqual';
import { DependencyList, EffectCallback, useCallback, useEffect, useMemo, useRef } from 'react';

export type DepsAreEqual = (prevDeps: DependencyList, nextDeps: DependencyList) => boolean;

function useCompare(deps: DependencyList, depsAreEqual: DepsAreEqual) {
  const ref = useRef<DependencyList>([]);
  if (!ref.current || !depsAreEqual(deps, ref.current)) {
    ref.current = deps;
  }
  return ref.current;
}

function useDebug(deps: DependencyList) {
  const ref = useRef<DependencyList>([]);
  const depsCompare = deps.map((d, i) => d === ref.current[i]);

  // eslint-disable-next-line no-console
  console.log('useDebug', {
    eql: deps === ref.current,
    deps,
    refCurrent: ref.current,
    depsCompare,
    depsThatChanged: depsCompare.reduce<number[]>((acc, value, index) => {
      return value === false ? [...acc, index] : acc;
    }, []),
  });

  if (!ref.current || deps !== ref.current) {
    ref.current = deps;
  }
  return ref.current;
}

export function useMemoCompare<T>(
  factory: () => T,
  deps: DependencyList,
  depsAreEqual: DepsAreEqual
): T {
  return useMemo(factory, useCompare(deps, depsAreEqual));
}

export function useMemoDebug<T>(factory: () => T, deps: DependencyList): T {
  return useMemo(factory, useDebug(deps));
}

export function useMemoShallow<T>(factory: () => T, deps: DependencyList): T {
  return useMemoCompare(factory, deps, (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));
}

export function useEffectCompare(
  effect: EffectCallback,
  deps: DependencyList,
  depsAreEqual: DepsAreEqual
) {
  useEffect(effect, useCompare(deps, depsAreEqual));
}

export function useEffectDebug(effect: EffectCallback, deps: DependencyList) {
  useEffect(effect, useDebug(deps));
}

export function useCallbackCompare<T extends (...args: any[]) => any>(
  callback: T,
  deps: DependencyList,
  depsAreEqual: DepsAreEqual
): T {
  return useCallback(callback, useCompare(deps, depsAreEqual));
}

export function useCallbackShallow<T extends (...args: any[]) => any>(
  callback: T,
  deps: DependencyList
): T {
  return useMemoCompare(callback, deps, (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));
}

export function useCallbackDebug<T extends (...args: any[]) => any>(
  callback: T,
  deps: DependencyList
): T {
  return useCallback(callback, useDebug(deps));
}
