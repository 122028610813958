import { createPopoverTestId, createTestId } from '../common/selectors';

/** Top level navigation selectors */
export const navbarSelector = createTestId('nav-bar');
export const navBarUserMenuPopoverSelector = createTestId('nav-bar-popover-button');
export const navbarApiReferenceSelector = createTestId('nav-bar-api-reference');
export const navbarSupportSelector = createTestId('nav-bar-support');
export const mobileNavbarSelector = createTestId('mobile-nav-bar');
export const mobileNavBarCollapseMenuSelector = createTestId('mobile-nav-bar-collapse-menu');
export const logoutButtonSelector = createPopoverTestId('nav-bar-logout');

/** Sidebar navigation selectors */
export const sidebarSelector = createTestId('sidebar');
/** General static-content pages */
export const sidebarGettingStartedSelector = createTestId('sidebar-getting-started');
export const sidebarAuthenticationSelector = createTestId('sidebar-authentication');
export const sidebarSetApiKeyButtonSelector = createTestId('sidebar-set-api-key-button');
export const sidebarPaginationSelector = createTestId('sidebar-pagination');
export const sidebarDateFormatsSelector = createTestId('sidebar-date-formats');
/** Dlgw guides static-content pages */
export const sidebarDlgwGuideCustomFiltersSelector = createTestId(
  'sidebar-dlgw-guide-custom-filters'
);

/** Dlgw spec endpoint list */
export const sideBarDlgwEndpointsSelector = createTestId('sidebar-dlgw-endpoints');
export const sideBarDlgwEndpointDescription = createTestId('sidebar-dlgw-endpoint-description');
