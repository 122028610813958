import { createTestId } from '../../common/selectors';

export const filingStepFragmentExpandButtonSelector = createTestId(`filing-step-expand-button`);
export const filingStepFragmentNewFilingToolbarSelector = createTestId('filing-step-new-filing');
export const filingStepFragmentFilingRowSelector = createTestId('filing-step-filing-row');

export const formHeaderPreviewButtonSelector = createTestId('form-header-preview-button');
export const formHeaderEditButtonSelector = createTestId('form-header-edit-button');
export const formHeaderDraftButtonSelector = createTestId('form-header-draft-button');

export const sponsorsStepFragmentSponsorsSelector = createTestId('sponsors-step-sponsors');
