import { SectorsGrid_CustomSectorPartsFragment } from './graphql/__generated__/SectorsGrid_CustomSectors';

export const filterSectors = (
  sectors: SectorsGrid_CustomSectorPartsFragment[] | null | undefined,
  filter: string
) => {
  const filterLower = filter.toLowerCase();

  if (sectors) {
    if (filter) {
      return sectors
        .map(sec => ({
          ...sec,
          children: (sec.children || []).filter(sub =>
            sub.name?.toLowerCase().includes(filterLower)
          ),
        }))
        .filter(sec => sec.name?.toLowerCase().includes(filterLower) || sec.children.length);
    }

    return sectors;
  }

  return [];
};
