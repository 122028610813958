import { datalabApi } from '@cmg/api';
import { DateRangePresetTypes, duckPartFactory, reduxUtil } from '@cmg/common';
import { combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { RootState } from '../../../../common/redux/rootReducer';
import { AverageMethod } from '../../../../types/domain/my-dashboard/averageMethod';
import {
  DashboardParametersBase,
  DateRangeControl,
  MyDashboardTopControls,
  PerformanceMetric,
} from '../../../../types/domain/my-dashboard/myDashboardParameters';

export const initialTopControlValues: MyDashboardTopControls = {
  customSectorIds: [],
  offeringSizeMax: undefined,
  offeringSizeMin: undefined,
  sectorCodes: [],
  performanceMetric: PerformanceMetric.OFFER_TO_CURRENT,
  averageMethod: AverageMethod.WEIGHTED,
};

export const initialDateRangeControlValues: Pick<
  DashboardParametersBase,
  'timePeriod' | 'comparisonPeriod'
> = {
  timePeriod: {
    dateType: DateRangePresetTypes.P2W,
    endDate: undefined,
    startDate: undefined,
  },
  comparisonPeriod: {
    dateType: DateRangePresetTypes.PREV_2W,
    endDate: undefined,
    startDate: undefined,
  },
};

/**
 * ACTION TYPES
 */
export enum ActionTypes {
  SET_USE_CUSTOM_SECTORS = 'MY_DASHBOARD/SET_USE_CUSTOM_SECTORS',
  TOP_CONTROLS_UPDATED = 'MY_DASHBOARD/TOP_CONTROLS_UPDATED',
  TIME_PERIOD_CONTROL_UPDATED = 'MY_DASHBOARD/TIME_PERIOD_CONTROL_UPDATED',
  COMPARISON_PERIOD_CONTROL_UPDATED = 'MY_DASHBOARD/COMPARISON_PERIOD_CONTROL_UPDATED',
}

/**
 * DUCK PARTS DEFINITIONS
 */
export const {
  actionCreators: {
    request: fetchCustomSectorOptionsRequest,
    success: fetchCustomSectorOptionsSucceeded,
    failure: fetchCustomSectorOptionsFailed,
  },
  initialState: customSectorOptionsInitialState,
  reducer: customSectorOptionsReducer,
  actionTypes: { REQUEST: fetchCustomSectorOptionsRequestType },
} = duckPartFactory.makeAPIDuckParts<{}, datalabApi.CustomSectorDto[]>({
  prefix: 'MY_DASHBOARD/FETCH_CUSTOM_SECTOR_OPTIONS',
});

/**
 * ACTIONS
 */
export type FetchCustomSectorOptionsAction = ReturnType<typeof fetchCustomSectorOptionsRequest>;

/**
 * This action is called when the user updates control values
 */
export const topControlsUpdated = (payload: { controls: MyDashboardTopControls }) => ({
  type: ActionTypes.TOP_CONTROLS_UPDATED,
  payload,
});
export type TopControlsUpdatedAction = ReturnType<typeof topControlsUpdated>;

export const timePeriodControlUpdated = (payload: { timePeriod: DateRangeControl }) => ({
  type: ActionTypes.TIME_PERIOD_CONTROL_UPDATED,
  payload,
});
export type TimePeriodControlUpdatedAction = ReturnType<typeof timePeriodControlUpdated>;

export const comparisonPeriodControlUpdated = (payload: {
  comparisonPeriod: DateRangeControl;
}) => ({
  type: ActionTypes.COMPARISON_PERIOD_CONTROL_UPDATED,
  payload,
});
export type ComparisonPeriodControlUpdatedAction = ReturnType<
  typeof comparisonPeriodControlUpdated
>;

/**
 * set filter useCustomSectors flag
 */
export const setUseCustomSectors = (payload: boolean) => ({
  type: ActionTypes.SET_USE_CUSTOM_SECTORS,
  payload,
});
export type SetUseCustomSectorsAction = ReturnType<typeof setUseCustomSectors>;

type Actions = {
  [ActionTypes.SET_USE_CUSTOM_SECTORS]: SetUseCustomSectorsAction;
  [ActionTypes.TOP_CONTROLS_UPDATED]: TopControlsUpdatedAction;
  [ActionTypes.TIME_PERIOD_CONTROL_UPDATED]: TimePeriodControlUpdatedAction;
  [ActionTypes.COMPARISON_PERIOD_CONTROL_UPDATED]: ComparisonPeriodControlUpdatedAction;
};

/**
 * REDUCERS
 */
const { createReducer } = reduxUtil;

export type ReducerState = {
  /* custom sectors options */
  customSectorOptions: typeof customSectorOptionsInitialState;

  /* top dashboard controls. does not include time period and comparison period */
  topControls: MyDashboardTopControls;

  /** current time period */
  timePeriod: DateRangeControl;

  /** comparison time period */
  comparisonPeriod: DateRangeControl;

  /** local custom sectors override */
  useCustomSectors: boolean;
};

export const initialState: ReducerState = {
  customSectorOptions: customSectorOptionsInitialState,
  topControls: initialTopControlValues,
  timePeriod: initialDateRangeControlValues.timePeriod,
  comparisonPeriod: initialDateRangeControlValues.comparisonPeriod,
  useCustomSectors: false,
};

export const topControlsReducer = createReducer<ReducerState['topControls'], Actions>(
  initialState.topControls,
  {
    [ActionTypes.TOP_CONTROLS_UPDATED]: (curState, { payload }) => payload.controls,
  }
);

export const timePeriodReducer = createReducer<ReducerState['timePeriod'], Actions>(
  initialState.timePeriod,
  {
    [ActionTypes.TIME_PERIOD_CONTROL_UPDATED]: (curState, { payload }) => payload.timePeriod,
  }
);
export const comparisonPeriodReducer = createReducer<ReducerState['comparisonPeriod'], Actions>(
  initialState.comparisonPeriod,
  {
    [ActionTypes.COMPARISON_PERIOD_CONTROL_UPDATED]: (curState, { payload }) =>
      payload.comparisonPeriod,
  }
);

const useCustomSectorsReducer = createReducer<ReducerState['useCustomSectors'], Actions>(
  initialState.useCustomSectors,
  {
    [ActionTypes.SET_USE_CUSTOM_SECTORS]: (curState, { payload }) => payload,
  }
);

export const reducer = combineReducers<ReducerState>({
  customSectorOptions: customSectorOptionsReducer,
  topControls: topControlsReducer,
  timePeriod: timePeriodReducer,
  comparisonPeriod: comparisonPeriodReducer,
  useCustomSectors: useCustomSectorsReducer,
});

export default reducer;

/**
 * Selectors
 */

export const selectDashboardControls = (state: RootState) => state.myDashboard.controls;

// TODO ECM-5014 resolve non-nullable type and default value in duckPartsFactory
const emptyOptions = [];
export const selectCustomSectors = (state: RootState) =>
  selectDashboardControls(state).customSectorOptions.data || emptyOptions;

export const selectCustomSectorOptions = createSelector(
  selectCustomSectors,
  (sectors: datalabApi.CustomSectorDto[]) =>
    sectors.map(s => ({
      title: s.sectorName,
      value: s.sectorId,
      children: (s.subSectors || []).map(sub => ({
        title: sub.sectorName,
        value: sub.sectorId,
      })),
    }))
);

export const selectTopControls = (state: RootState) => selectDashboardControls(state).topControls;
export const selectTimePeriodControl = (state: RootState) =>
  selectDashboardControls(state).timePeriod;
export const selectComparisonPeriodControl = (state: RootState) =>
  selectDashboardControls(state).comparisonPeriod;

export const selectUseCustomSectors = state => selectDashboardControls(state).useCustomSectors;

/**
 * SAGAS
 */

/**
 * Fetch custom sector options saga
 */
export function* fetchCustomSectorOptionsSaga(): SagaIterator {
  const resp: datalabApi.FetchCustomSectorsResponse = yield call(datalabApi.fetchCustomSectors, {});

  if (resp.ok) {
    yield put(fetchCustomSectorOptionsSucceeded(resp.data));
  } else {
    yield put(fetchCustomSectorOptionsFailed(resp.data.error));
  }
}
