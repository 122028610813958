import { datalabApi } from '@cmg/api';
import React from 'react';
import styled, { css } from 'styled-components/macro';

import CalendarIconButton from './CalendarIconButton';

const StyledCalendarIconButton = styled(CalendarIconButton)`
  ${({ solid }) =>
    solid &&
    css`
      transform: rotate(30deg);
    `}
  &:hover {
    ${({ solid }) =>
      !solid &&
      css`
        transform: rotate(30deg);
      `}
  }
`;

type Props = {
  offering: datalabApi.CalendarOffering;
  onClick: (offering: datalabApi.CalendarOffering) => void;
};

const FollowOfferingButton: React.FC<Props> = ({ onClick, offering }) => {
  const popoverContent = offering.userIsFollowing ? 'Unfollow' : 'Follow';

  return (
    <StyledCalendarIconButton
      onClick={() => onClick(offering)}
      iconName="thumbtack"
      solid={!!offering.userIsFollowing}
      popoverContent={popoverContent}
    />
  );
};

export default FollowOfferingButton;
