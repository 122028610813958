import { IconButton, TextInput } from '@cmg/common';
import xor from 'lodash/xor';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchSectors, selectSectors } from '../../../shared/sectors/ducks';
import SectorAdd from './components/SectorAdd';
import SectorRow from './components/SectorRow';
import { filterSectors } from './SectorsGrid.model';
import {
  SectorFilter,
  SectorsUl,
  SSectorParent,
  SSectorRowWrapper,
  StyledIcon,
} from './SectorsGrid.styles';

const mapStateToProps = state => ({
  sectors: selectSectors(state),
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchSectors }, dispatch),
});

type OwnProps = {
  canUpdate: boolean;
};

export type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const SectorsGrid = ({ actions, sectors, canUpdate }: Props) => {
  const [filter, setFilter] = useState('');
  const [expandedIds, setExpandedIds] = useState<string[]>([]);

  useEffect(() => {
    actions.fetchSectors();
  }, [actions]);

  const toggleCollapsedId = (id: string) => setExpandedIds(xor(expandedIds, [id]));

  const filteredSectors = filterSectors(sectors, filter);
  return (
    <React.Fragment>
      <SectorFilter>
        <TextInput
          prefix={<StyledIcon name="filter" />}
          suffix={
            filter && (
              <div role="button" onClick={() => setFilter('')}>
                <StyledIcon name="times-circle" />
              </div>
            )
          }
          value={filter}
          onChange={value => setFilter(value || '')}
          placeholder="Filter"
        />
      </SectorFilter>
      <SectorsUl>
        {filteredSectors.map(sector => (
          <li key={sector.sectorId}>
            <SSectorParent>
              {!sector.subSectors.length ? (
                <SSectorRowWrapper>
                  <SectorRow sector={sector} canUpdate={canUpdate} />
                </SSectorRowWrapper>
              ) : (
                <React.Fragment>
                  <IconButton
                    icon={{
                      name: !expandedIds.includes(sector.sectorId) ? 'caret-right' : 'caret-down',
                    }}
                    onClick={() => toggleCollapsedId(sector.sectorId)}
                  />

                  <SectorRow sector={sector} canUpdate={canUpdate} />
                </React.Fragment>
              )}
            </SSectorParent>
            <ul>
              {expandedIds.includes(sector.sectorId) &&
                (sector.subSectors || []).map(sub => (
                  <li key={sub.sectorId}>
                    <SectorRow sector={sub} parentId={sector.sectorId} canUpdate={canUpdate} />
                  </li>
                ))}
              {canUpdate && (
                <li>
                  <SectorAdd parentId={sector.sectorId} />
                </li>
              )}
            </ul>
          </li>
        ))}
        {canUpdate && (
          <li>
            <SectorAdd />
          </li>
        )}
      </SectorsUl>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SectorsGrid);
