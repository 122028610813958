import { UnpackPromise } from '@cmg/common';

import { UUID } from '../../../types/common';
import { makeCreateEntityRequest } from '../../utils/request-factory';
import { dlgwApiClient } from '../dlgwApiClient';

export type SetFollowOfferingRequest = { isFollowing: boolean };

export const setFollowOffering = makeCreateEntityRequest<
  SetFollowOfferingRequest,
  void,
  { offeringId: UUID }
>(dlgwApiClient, '/offerings/:offeringId/follow');

export type SetFollowOfferingResponse = UnpackPromise<ReturnType<typeof setFollowOffering>>;
