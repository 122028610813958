import React from 'react';
import styled from 'styled-components/macro';

import PrimaryButton from '../../buttons/button/PrimaryButton';
import SecondaryButton from '../../buttons/button/SecondaryButton';
import Modal from '../modal/Modal';

const StyledModal = styled(Modal)`
  .control-label {
    padding-left: 2px;
  }
`;
const SButtonWrapper = styled.div`
  display: inline-block;
  margin-left: 7px;
`;

/**
 * if onSubmitForm is set, Modal will be wrapped by <form> and confirm-handler should be provided in withFormik HOC's `handleSubmit` see IoiParametersModal
 * otherwise onConfirm is requred
 */

export type Props = {
  show: boolean;
  children: React.ReactNode;
  size?: 'small' | 'large';
  title?: React.ReactNode;
  renderFooter?: (params: { defaultButtons: React.ReactNode }) => React.ReactNode;
  cancelButtonCaption?: string;
  confirmButtonCaption?: string;
  confirmButtonEnabled?: boolean;
  closeButton?: boolean;
  isLoading?: boolean;
  testId?: string;
  onHide: () => void;
} & (
  | {
      onConfirm: () => void;
      onSubmitForm?: never;
    }
  | {
      onConfirm?: never;
      onSubmitForm: () => void; // function passed by withFormik()
    }
);

const ConfirmModal: React.FC<Props> = ({
  show,
  cancelButtonCaption,
  confirmButtonCaption,
  confirmButtonEnabled,
  onHide,
  onConfirm,
  isLoading,
  size,
  title,
  closeButton,
  renderFooter,
  children,
  testId,
  onSubmitForm,
}) => {
  const buttonProps = {
    disabled: confirmButtonEnabled === false,
    children: confirmButtonCaption || 'Confirm',
  };
  const primaryCallback = (onSubmitForm ? onSubmitForm : onConfirm) as () => void;
  const defaultButtons = (
    <React.Fragment>
      <SButtonWrapper>
        <SecondaryButton onClick={onHide}>{cancelButtonCaption || 'Close'}</SecondaryButton>
      </SButtonWrapper>
      <SButtonWrapper>
        <PrimaryButton onClick={primaryCallback} {...buttonProps} />
      </SButtonWrapper>
    </React.Fragment>
  );
  return (
    <StyledModal
      size={size}
      show={show}
      onHide={onHide}
      title={title}
      closeButton={closeButton}
      isForm={!!onSubmitForm}
      footer={renderFooter ? renderFooter({ defaultButtons }) : defaultButtons}
      isLoading={isLoading}
      testId={testId}
    >
      {children}
    </StyledModal>
  );
};

export default ConfirmModal;
