import { SignalRCallbackByEvent, SignalREvent } from '../signalr';
import {
  CalendarEventFilterFn,
  useWrapCalendarRefreshCallback,
} from './useDebouncedCalendarRefreshEvent.model';
import { useListenToServerEvent } from './useListenToServerEvent';

export const useDebouncedCalendarRefreshEvent = ({
  callback,
  filterFn,
}: {
  callback: SignalRCallbackByEvent[SignalREvent.CALENDAR_REFRESH];
  filterFn?: CalendarEventFilterFn;
}) => {
  const { wrappedCallback, lastUpdatedAt } = useWrapCalendarRefreshCallback({ callback, filterFn });

  useListenToServerEvent(SignalREvent.CALENDAR_REFRESH, wrappedCallback);

  return { lastUpdatedAt };
};
