import throttle from 'lodash/throttle';
import { useEffect, useRef } from 'react';

/**
 * A custom hook which tracks scroll position of a ref for infinite scrolling functionality
 * returns scrollRef - a Ref which should attach to scrollable div container
 */
export type Props = {
  /** (optional default=1.5) percent of container height ref which defines triggerable area */
  threshold?: number;
  /** enabling onNext (this should be a combo of loading state handled within duck/component + pagination params) */
  hasNext: boolean;
  /** function called when scroll position enters threshold */
  onNext: () => void;
  /** debugging handler forwarding scroll events from listeners on ref, caution - throttled */
  onScroll?: (e: Event) => void;
};

const useInfiniteScroll = ({ threshold = 1.5, hasNext, onNext, onScroll }: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // handler checks distance from bottom of scroll target
    const scrollHandler = (e: Event) => {
      // prevent onNext from being called if we are already loading (handled in component/duck)
      // or if we don't have anymore to load (ie end of pagination)
      if (hasNext) {
        // make sure ref is mounted
        if (scrollRef && scrollRef.current) {
          // get scroll values and height of ref
          const { scrollTop, scrollHeight, offsetHeight } = scrollRef.current;

          // how close we are to the bottom
          const bottomOffset = scrollHeight - scrollTop;

          // calc if within threshold area at the bottom of ref
          const withinThreshold = bottomOffset < offsetHeight * threshold;

          // call onNext if scroll pos is within threshold
          if (withinThreshold) {
            onNext && onNext();
          }
        }
      }

      // if onScroll debugging prop, let's call it
      onScroll && onScroll(e);
    };

    // throttle scroll events for performance
    const throttledScrollHandler = throttle(scrollHandler, 100);

    // attach listeners to current ref, this const stores ref for compliant useEffect rules
    const currentRef = scrollRef.current;
    currentRef && currentRef.addEventListener('scroll', throttledScrollHandler);
    return () => {
      currentRef && currentRef.removeEventListener('scroll', throttledScrollHandler);
    };
  }, [threshold, hasNext, onNext, onScroll]);

  // return ref for attaching to list itself
  return scrollRef;
};

export default useInfiniteScroll;
