import { encodeQuery } from '../../../common/helpers/url-helpers';
import { OfferingType } from '../../../types/domain/offering/constants';

export function createNewPrecedentTransactionUrl(offeringType, filterValues) {
  const isIPO = offeringType === OfferingType.IPO;
  const datalabUrl = isIPO ? 'datalab/ipo_pricing/table' : 'datalab/follow_on_pricing/table';
  return `/${datalabUrl}?${encodeQuery(filterValues, ',')}`;
}

export function openNewPrecedentTransactions(offeringType, filterValues) {
  const url = createNewPrecedentTransactionUrl(offeringType, filterValues);
  const win = window.open(url, '_blank');
  win.focus();
}
