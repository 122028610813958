import { ConfirmModal, Icon } from '@cmg/common';
import { useState } from 'react';

import { TreeSelect } from '../../../../../common/components/form';
import routeFactory from '../../../../../common/util/routeFactory';
import { mapGraphqlSectorsToOptions } from '../../../../shared/sectors/sectors.model';
import { SectorsGrid_CustomSectorPartsFragment } from '../../../sectors/sectors-grid/graphql/__generated__/SectorsGrid_CustomSectors';
import {
  SManagedSectorsLinkContainer,
  SSectorsEditModal,
  SSubtitle,
  STitle,
} from './SectorsModal.styles';

type Props = {
  onSubmit: (sectorId: string) => void;
  isSubmitting: boolean;
  sectors: SectorsGrid_CustomSectorPartsFragment[] | null | undefined;
  companyCount?: number;
  show: boolean;
  onHide: () => void;
};

/**
 * Display modal for updating custom-sectors of provided companies
 */
const SectorsModal = ({ onSubmit, isSubmitting, sectors, onHide, companyCount, show }: Props) => {
  const [sectorId, setSectorId] = useState<string>();

  const handleSubmit = () => {
    sectorId && onSubmit(sectorId);
    onHide();
  };

  return (
    <ConfirmModal
      title={
        <STitle>
          <span>Assign My Sectors and Sub Sectors</span>
          <SSubtitle>
            {!companyCount || companyCount === 1
              ? `You are updating the company record.`
              : `You are updating ${companyCount} companies.`}
          </SSubtitle>
        </STitle>
      }
      isLoading={!sectors || isSubmitting}
      confirmButtonEnabled={!!sectorId}
      onHide={onHide}
      onConfirm={handleSubmit}
      show={show}
    >
      <SSectorsEditModal>
        <TreeSelect
          testId="SectorsModal-SelectCustomSector"
          value={sectorId}
          treeData={mapGraphqlSectorsToOptions(sectors)}
          onChange={setSectorId}
          treeCheckable={false}
        />
        <SManagedSectorsLinkContainer>
          <a target="_blank" rel="noreferrer noopener" href={routeFactory.sectors.getUrlPath()}>
            Manage Sectors <Icon name="external-link-alt" fixedWidth />
          </a>
        </SManagedSectorsLinkContainer>
      </SSectorsEditModal>
    </ConfirmModal>
  );
};

export default SectorsModal;
