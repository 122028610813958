import { ValueFormatterParams } from 'ag-grid-community';
import React from 'react';
import styled from 'styled-components/macro';

export const SPinnedRow = styled.div`
  text-align: right;
  > * {
    line-height: 11px;
  }
`;

const PinnedRowRenderer: React.FC<ValueFormatterParams> = params => {
  const { colDef, data, value } = params;

  const givenValue = value || (colDef.field && data[colDef.field]);

  if (givenValue === undefined || givenValue === null) {
    return null;
  }

  let formattedValue: string | number = '';

  if (givenValue && typeof givenValue.value === 'string') {
    formattedValue = givenValue.value;
  } else {
    formattedValue =
      colDef.valueFormatter && typeof colDef.valueFormatter === 'function'
        ? colDef.valueFormatter({ ...params, value: givenValue })
        : Math.round(givenValue.value);
  }

  return (
    <SPinnedRow>
      <div>{givenValue.title}</div>
      <div>{formattedValue}</div>
    </SPinnedRow>
  );
};

export default PinnedRowRenderer;
