import { datalabApi } from '@cmg/api';
import { Option } from '@cmg/common';
import memoize from 'memoize-one';

import { CustomSector } from '../../../graphql/__generated__';

export const mapSectorsToOptions: (
  sectors?: datalabApi.CustomSectorDto[] | null
) => Omit<Option, 'label'>[] = memoize((sectors?: datalabApi.CustomSectorDto[] | null) =>
  !sectors
    ? []
    : sectors.map(s => ({
        title: s.sectorName,
        value: s.sectorId,
        children: (s.subSectors || []).map(sub => ({
          title: sub.sectorName,
          value: sub.sectorId,
        })),
      }))
);

type GraphqlCustomSectors = {
  id: string;
  name?: string | null;
  children: { id: string; name?: string | null }[];
}[];

type CustomSectorFields = Pick<CustomSector, 'id' | 'name'>;

export const mapGraphqlSectorsToOptions: (
  sectors: (CustomSectorFields & { children: CustomSectorFields[] })[] | null | undefined
) => Omit<Option, 'label'>[] = memoize((sectors: GraphqlCustomSectors) =>
  !sectors
    ? []
    : sectors
        .filter(s => !!s.name)
        .map(s => ({
          title: s.name!,
          value: s.id,
          children: (s.children || [])
            .filter(s => !!s.name)
            .map(sub => ({
              title: sub.name!,
              value: sub.id,
            })),
        }))
);
