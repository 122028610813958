import { createTestId } from '../../../common/selectors';

const create = (name: string) => createTestId(`xc-wires-pricing-terms-${name}`);

export const wiresPricingTermsModalCancelButton = create('modal-cancel-button');
export const wiresPricingTermsModalSaveButton = create('modal-save-button');

export const wiresPricingTermsEditFormReallowanceInput = create('edit-form-reallowance-input');
export const wiresPricingTermsEditFormManagementFeeInput = create('edit-form-management-fee-input');
export const wiresPricingTermsEditFormSellingConcessionInput = create(
  'edit-form-selling-concession-input'
);
export const wiresPricingTermsEditFormUnderwritingFeeInput = create(
  'edit-form-underwriting-fee-input'
);

export const wiresPricingTermsCreateNewVersionButton = create('create-new-version-button');
export const wiresPricingTermsEditWireButton = create('edit-wire-button');
export const wiresPricingTermsDeleteWireButton = create('delete-wire-button');
export const wiresPricingTermsSendWireButton = create('send-wire-button');
