import React from 'react';

import { TreeGridColDef, TreeGridRowData } from './TreeGrid';
import { STreeGridCellContent } from './TreeGridCell.styles';

type Props = {
  column: TreeGridColDef;
  row: TreeGridRowData;
};

export const TreeGridGenericCell: React.FC<Props> = ({ column, row }) => {
  return (
    <td>
      <STreeGridCellContent>
        {column.cellRendererFramework ? column.cellRendererFramework(row) : row[column.field]}
      </STreeGridCellContent>
    </td>
  );
};

export default TreeGridGenericCell;
