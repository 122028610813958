import { Option, ToastManager } from '@cmg/common';
import React from 'react';

import { OfferingType } from '../../../../../graphql/__generated__/index';
import { SearchFilterHookResult } from '../../../../../graphql/types';
import { useGetUnderwritersQuery } from '../graphql/__generated__/GetUnderwriters';
import { useCreateUnderwriterOptions } from '../OfferingsFilterForm.model';

export const useSearchUnderwriters = (offeringType?: OfferingType): SearchFilterHookResult => {
  const [searchText, setSearchText] = React.useState<string>();
  const { data, error, loading } = useGetUnderwritersQuery({
    variables: {
      filter: {
        type: offeringType ? { eq: offeringType } : undefined,
        managers: searchText ? { some: { manager: { name: { like: searchText } } } } : undefined,
      },
    },
  });
  const resolveRef = React.useRef<(value: Option<string>[]) => void>();
  const underwriterOptions = useCreateUnderwriterOptions(data?.offerings?.items ?? [], searchText);

  React.useEffect(() => {
    if (error) {
      ToastManager.error('Error occurred while fetching underwriters');
    }
    if (!loading && data && resolveRef.current) {
      resolveRef.current(underwriterOptions);
    }
  }, [loading, data, error, underwriterOptions]);

  const loadOptions = React.useCallback((newSearchText: string) => {
    setSearchText(newSearchText);
    return new Promise<Option<string>[]>(resolve => {
      resolveRef.current = resolve;
    });
  }, []);

  return {
    defaultOptions: underwriterOptions,
    loadOptions,
  };
};
