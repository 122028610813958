import { createTestId } from '../../../common/selectors';

const create = (name: string) => createTestId(`xc-order-book-institutional-demand-${name}`);

export const institutionalDemandScreen = create('screen');
export const institutionalDemandGrid = create('grid');
export const institutionalDemandInvestorSearchFilter = create('investor-search-filter');
export const institutionalDemandIndicationStatusFilter = create('indication-status-filter');
export const institutionalDemandIndicationTypeFilter = create('indication-type-filter');
export const institutionalDemandAllocationsDropdownMenuF = create(
  'indication-allocations-dropdown-menu'
);
export const institutionalDemandAllocationsDropdownMenuFinalAllocationsMenuItem = create(
  'indication-allocations-dropdown-menu-final-allocations-menu-item'
);
export const institutionalDemandAllocationsDropdownMenuDraftAllocationsMenuItem = create(
  'indication-allocations-dropdown-menu-draft-allocations-menu-item'
);
export const institutionalDemandAllocationsDropdownMenuFinalAllocationsMenuItemShareHover = create(
  'indication-allocations-dropdown-menu-final-allocations-menu-item-share-hover'
);
export const institutionalDemandCreateIndicationButton = create('create-indication');
export const institutionalDemandAllocationsDropdownMenuShareDraftAllocation = create(
  'indication-allocations-dropdown-menu-share-draft-allocation'
);
export const institutionalDemandAllocationsDropdownMenuDuplicateDraftAllocation = create(
  'indication-allocations-dropdown-menu-duplicate-draft-allocation'
);
export const institutionalDemandAllocationsDropdownMenuEditDraftAllocation = create(
  'indication-allocations-dropdown-menu-edit-draft-allocation'
);
export const institutionalDemandAllocationsDropdownMenuAddNewDraftAllocation = create(
  'indication-allocations-dropdown-menu-add-new-draft-allocation'
);
export const institutionalDemandAllocationsDropdownMenuPromoteToFinalAllocation = create(
  'indication-allocations-dropdown-menu-promote-to-final-allocation'
);

export const institutionalDemandAllocationSetTotal = create('allocation-set-total');

export const institutionalDemandGridColumnsDropdownButton = create(
  'demand-grid-columns-dropdown-button'
);

export const institutionalDemandGridColumnsDropdownMenu = create(
  'demand-grid-columns-dropdown-menu'
);

export const institutionalDemandEditAllocationsButton = create('edit-allocations-button');

export const institutionalDemandSaveAllocationsButton = create('save-allocations-button');

export const institutionalDemandOrdersAndAllocationsExportDropdownButton =
  create('export-dropdown-button');

export const institutionalDemandOrdersAndAllocationsExportOrderBookGridButton = create(
  'export-order-book-grid-button'
);

export const institutionalDemandOrdersAndAllocationsExportRevisionHistoryButton = create(
  'export-revision-history-button'
);

export const institutionalDemandGridCellBillingAndDeliveryAgentDropdownMenu = create(
  'demand-grid-cell-billing-and-delivery-agent-dropdown-menu'
);
