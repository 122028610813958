import { getFeatureToggles } from '../../../../config/appSettings';
import { DatalabTableColumnCategory, DatalabTableColumnGroup } from '../../constants';
import { ColumnCategoryConfig, ColumnGroupConfig } from '../advanced-filters-config';
import { dtc } from '../datalabTableColumns';
import { setColumnsCategory, setColumnsGroup } from '../utils';

export const categorizedColumns: ColumnCategoryConfig = {
  [DatalabTableColumnCategory.ISSUER]: setColumnsCategory(
    [
      dtc.companies_issuer,
      dtc.companies_ticker,
      dtc.companies_cik,
      dtc.companies_cusip,
      dtc.issuer_isin,
      dtc.offering_entity_structure,
      dtc.exchange_mic,
      dtc.pricing_instrument_region,
      dtc.pricing_currency_code,
      dtc.country_code,
      dtc.offerings_sector,
      dtc.companies_sub_sector,
      ...(getFeatureToggles().isNewOfferingFieldsOn
        ? [dtc.dual_listed, dtc.egc, dtc.headquarters, dtc.naics]
        : []),
    ],
    DatalabTableColumnCategory.ISSUER
  ),
  [DatalabTableColumnCategory.OFFERING_TERMS]: setColumnsCategory(
    [
      { ...dtc.offerings_type, label: 'Type' },
      dtc.offering_security_type,
      dtc.filing_details_offering_price_local_curr,
      dtc.filing_details_offering_price,
      dtc.filing_details_net_price,
      dtc.split_adjusted_offering_price,
      dtc.offering_initial_registration_value,
      dtc.offering_size,
      dtc.total_shares_filed_excl_shoe,
      dtc.offering_primary_shares_base_offering,
      dtc.offering_secondary_shares_base_offering,
      dtc.filing_details_pct_secondary_shares,
      dtc.offering_over_allotment_authorized,
      dtc.offering_over_allotment_exercised,
      dtc.filing_details_gross_proceeds_local_curr,
      dtc.filing_details_gross_proceeds,
      dtc.filing_details_gross_proceeds_with_over_allotment_local_curr,
      dtc.filing_details_gross_proceeds_with_over_allotment,
      ...(getFeatureToggles().isNewOfferingFieldsOn
        ? [
            dtc.initial_gross_proceeds_base,
            dtc.initial_gross_proceeds_base_local_curr,
            dtc.revised_gross_proceeds_base,
            dtc.revised_gross_proceeds_base_local_curr,
          ]
        : []),
      dtc.filing_details_market_cap_at_pricing_local_curr,
      dtc.filing_details_market_cap_at_pricing,
      dtc.filing_details_market_cap_pre_offering,
      dtc.offering_post_offering_shares,
      dtc.offering_pre_offering_shares,
      dtc.filing_details_deal_pct_at_pricing_market_cap,
      dtc.filing_details_deal_pct_market_cap_pre_offering,
      dtc.offerings_pre_offering_adtv,
      dtc.offerings_size_as_multiple_of_adtv,
      dtc.offering_initial_ipo_range_low_local_curr,
      dtc.offering_initial_ipo_range_low,
      dtc.offering_revised_ipo_range_low,
      dtc.filing_details_price_vs_range,
      dtc.offering_price_vs_midpoint,
      dtc.offering_pct_change_in_size,
      dtc.offering_use_of_proceeds,
      dtc.offering_forward_agreement,
      dtc.first_follow_on,
      ...(getFeatureToggles().isNewOfferingFieldsOn
        ? [
            dtc.concurrent_offering,
            dtc.synthetic_secondary,
            dtc.up_listing,
            dtc.use_of_proceeds_note,
            dtc.company_repurchase,
          ]
        : []),
    ],
    DatalabTableColumnCategory.OFFERING_TERMS
  ),
  [DatalabTableColumnCategory.OWNERSHIP]: setColumnsCategory(
    [
      dtc.primary_shareholder_name,
      dtc.offering_pre_offering_ownership,
      dtc.offering_pct_pre_offering_ownership,
      dtc.offering_post_offering_ownership,
      dtc.offering_pct_post_offering_ownership,
      ...(getFeatureToggles().isNewOfferingFieldsOn
        ? [dtc.carve_out, dtc.clean_up_trade, dtc.cornerstone_investors]
        : []),
      ...(getFeatureToggles().isCornerstoneInvestorInDLOn
        ? [
            dtc.cornerstone_investors_list,
            dtc.cornerstone_investment,
            dtc.cornerstone_investment_type,
            dtc.number_of_cornerstone_investors,
          ]
        : []),
    ],
    DatalabTableColumnCategory.OWNERSHIP
  ),
  [DatalabTableColumnCategory.PERFORMANCE]: setColumnsCategory(
    [
      dtc.offering_first_day_volume,
      dtc.offering_first_day_turnover,
      dtc.offering_offer_to_open,
      dtc.offering_offer_to_vwap_1day,
      dtc.offering_offer_to_1day,
      dtc.offering_offer_to_3day,
      dtc.offering_offer_to_7day,
      dtc.offering_offer_to_14day,
      dtc.offering_offer_to_30day,
      dtc.offering_offer_to_90day,
      dtc.offering_offer_to_180day,
      dtc.offering_offer_to_one_year,
      dtc.offering_offer_to_prior_quarter,
      dtc.offering_offer_to_current,
      dtc.fifty_two_wk_high,
    ],
    DatalabTableColumnCategory.PERFORMANCE
  ),
  [DatalabTableColumnCategory.PRICING_DISCOUNT]: setColumnsCategory(
    [
      dtc.offerings_file_to_offer_discount,
      dtc.offerings_discount_to_last_trade,
      dtc.offering_discount_to_vwap,
      dtc.offerings_discount_to_52wk_high,
      dtc.offerings_all_in_cost,
      dtc.last_trade_before_filing_local_curr,
      dtc.last_trade_before_filing,
      dtc.last_trade_before_offer_local_curr,
      dtc.last_trade_before_offer,
      dtc.re_offer_low_local_curr,
      dtc.re_offer_low,
      dtc.re_offer_discount_low,
      dtc.re_offer_high_local_curr,
      dtc.re_offer_high,
      dtc.re_offer_discount_high,
    ],
    DatalabTableColumnCategory.PRICING_DISCOUNT
  ),
  [DatalabTableColumnCategory.TIMING]: setColumnsCategory(
    [
      dtc.offering_confidential_filing_date,
      dtc.offering_public_filing_date,
      dtc.offering_launch_date,
      dtc.timing_of_launch,
      dtc.offerings_pricing_date,
      dtc.offering_first_trade_date,
      dtc.offering_settlement_date,
      dtc.lock_up_period,
      dtc.lock_up_early_release_date,
      dtc.lock_up_expiration,
      dtc.conditional_lock_up,
      dtc.multiple_lock_ups,
    ],
    DatalabTableColumnCategory.TIMING
  ),
  [DatalabTableColumnCategory.UNDERWRITING]: setColumnsCategory(
    [
      dtc.offering_gross_spread_pct,
      dtc.offering_gross_spread,
      dtc.estimated_fee,
      dtc.offering_total_managers,
      dtc.offering_total_bookrunners,
      dtc.offering_total_non_bookrunners,
      dtc.offering_total_pct_to_bookrunners,
      dtc.offering_total_pct_to_non_bookrunners,
      dtc.offering_total_pct_to_left_lead,
      dtc.left_lead_firm_name,
    ],
    DatalabTableColumnCategory.UNDERWRITING
  ),
};

export const groupedColumns: ColumnGroupConfig = {
  [DatalabTableColumnGroup.LOCAL_CURRENCY]: setColumnsGroup(
    [
      dtc.filing_details_offering_price_local_curr,
      dtc.filing_details_gross_proceeds_local_curr,
      dtc.filing_details_gross_proceeds_with_over_allotment_local_curr,
      dtc.filing_details_market_cap_at_pricing_local_curr,
      dtc.offering_initial_ipo_range_low_local_curr,
      dtc.last_trade_before_filing_local_curr,
      dtc.last_trade_before_offer_local_curr,
      dtc.re_offer_low_local_curr,
      dtc.re_offer_high_local_curr,
    ],
    DatalabTableColumnGroup.LOCAL_CURRENCY
  ),
  [DatalabTableColumnGroup.USD]: setColumnsGroup(
    [
      dtc.filing_details_offering_price,
      dtc.filing_details_gross_proceeds,
      dtc.filing_details_gross_proceeds_with_over_allotment,
      dtc.filing_details_market_cap_at_pricing,
      dtc.offering_initial_ipo_range_low,
      dtc.last_trade_before_filing,
      dtc.last_trade_before_offer,
      dtc.re_offer_low,
      dtc.re_offer_high,
    ],
    DatalabTableColumnGroup.USD
  ),
};
