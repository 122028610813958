import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import type { TextFieldProps as MUITextFieldProps } from '@mui/material/TextField';
import MUITextField from '@mui/material/TextField';
import React from 'react';

import type { TestProps } from '../../../types';

export type SearchTextFieldProps = TestProps & Omit<MUITextFieldProps, 'InputProps'>;

export const SearchTextField: React.FC<SearchTextFieldProps> = props => {
  return (
    <MUITextField
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};
