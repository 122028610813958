import { datalabApi } from '@cmg/api';
import { numericUtil, Popover } from '@cmg/common';
import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../common/util/routeFactory';
import { managerRoleLabels } from '../../../types/domain/offering/constants';
import { DatalabScreens } from '../../datalab/constants';
import PopoverContentTable from '../../shared/components/PopoverContentTable';

export type Props = {
  offering: datalabApi.CalendarOffering;
};

const LeftLeadFirmNameRenderer: React.FC<Props> = ({ offering }) => {
  const url = routeFactory.datalab.getUrlPath({
    screen: DatalabScreens.UNDERWRITER_OFFERINGS,
    type: 'table',
    query: {
      underwriter: offering.leftLeadFirmId,
    },
  });

  const hasEconomics = offering.managers && offering.managers.some(u => u.economicsPercent);

  return (
    <Popover
      variant="TOOLTIP"
      placement="left"
      content={
        <PopoverContentTable>
          <thead>
            <tr>
              <th>Underwriter</th>
              <th>Role</th>
              {hasEconomics ? <th>Economics</th> : null}
            </tr>
          </thead>
          <tbody>
            {offering.managers &&
              offering.managers.map((u, idx) => (
                <tr key={idx}>
                  <td>{u.firmName}</td>
                  <td>{managerRoleLabels[u.role]}</td>
                  {hasEconomics && <td>{numericUtil.formatPercents(u.economicsPercent, 1)}</td>}
                </tr>
              ))}
          </tbody>
        </PopoverContentTable>
      }
    >
      <Link to={url}>{offering.leftLeadFirmName}</Link>
    </Popover>
  );
};

export default LeftLeadFirmNameRenderer;
