import { createTestId } from '../../../common/selectors';

const create = (name: string) => createTestId(`xc-wires-underwriter-invitation-${name}`);

export const wiresUnderwriterInvitationEditButton = create('edit-button');
export const wiresUnderwriterInvitationDeleteButton = create('delete-button');
export const wiresUnderwriterInvitationSendButton = create('send-button');

export const wiresUnderwriterInvitationModalCancelButton = create('modal-cancel-button');
export const wiresUnderwriterInvitationModalSaveButton = create('modal-save-button');

export const wiresUnderwriterInvitationFormMasterAauDateInput = create('form-m-aau-date-input');
export const wiresUnderwriterInvitationFormDisclaimerInput = create('form-disclaimer-input');
