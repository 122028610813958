import 'react-virtualized/styles.css';

import { ecmSelectors } from '@cmg/e2e-selectors';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { AutoSizer, MultiGrid } from 'react-virtualized';

import CustomCellMeasurer from './CustomCellMeasurer';
import CustomCellMeasurerCache from './CustomCellMeasurerCache';
import {
  SColumn,
  SGroupLabel,
  SHeaderItemGroup,
  SNoContent,
  STableWrapper,
  StyledHeaderItem,
  StyledSummaryHeaderItem,
} from './VirtualizedTable.styles';

export default class VirtualizedTable extends Component {
  static propTypes = {
    activePage: PropTypes.number,
    activeTab: PropTypes.object,
    columns: PropTypes.array.isRequired,
    disableHeight: PropTypes.bool,
    groupBy: PropTypes.string.isRequired,
    handleGroupBy: PropTypes.func.isRequired,
    handleOrderBy: PropTypes.func.isRequired,
    itemsPerPage: PropTypes.number,
    metaData: PropTypes.object,
    noResultsMessage: PropTypes.string,
    numberOfHeaderRows: PropTypes.number.isRequired,
    orderBy: PropTypes.string.isRequired,
    orderByType: PropTypes.string.isRequired,
    showRanking: PropTypes.bool,
    striped: PropTypes.bool,
    summaries: PropTypes.object,
    visibleColumns: PropTypes.array.isRequired,
    visibleRows: PropTypes.array.isRequired,
    tableWrapperStyles: PropTypes.object,
  };

  static defaultProps = {
    striped: true,
    noResultsMessage: 'There were no results matching your criteria.',
  };

  cache = new CustomCellMeasurerCache({
    defaultWidth: 100,
    minWidth: 65,
    fixedHeight: true,
    defaultHeight: 30,
  });

  shouldComponentUpdate() {
    this.cache.clearAll();
    return true;
  }

  cellRenderer = ({ columnIndex, parent, rowIndex, style }) => {
    const {
      visibleRows,
      visibleColumns,
      numberOfHeaderRows,
      showRanking,
      activePage,
      itemsPerPage,
      metaData,
      striped,
    } = this.props;

    const isRankingColumn = showRanking && columnIndex === 0;
    const rankingColumn = {
      field: 'ranking',
      label: 'Ranking',
      textAlign: 'right',
      fixedMinWidth: 65,
      parentColumnField: undefined,
    };

    const column = isRankingColumn
      ? rankingColumn
      : visibleColumns[columnIndex - (showRanking ? 1 : 0)];

    const cKey = `${column.field}-${rowIndex}`;

    let styleProps = {
      style: {
        ...style,
        height: 30,
        padding: '.5em',
        whiteSpace: 'nowrap',
      },
    };

    const textAlign = column.textAlign || 'left';

    const getContent = () => {
      const row = visibleRows[rowIndex] || {};
      const isHeaderRow = row.isHeaderRow === true;
      const isFooterRow = row.isFooterRow === true;

      if (isHeaderRow || isFooterRow) {
        return this.renderHeader({
          row,
          column,
          styleProps,
          cKey,
          isVisible: !isFooterRow,
          textAlign,
        });
      }

      const isGroupHeaderRow = Boolean(row.isGroupHeaderRow);
      const isGroupHeaderColumn = columnIndex === (isRankingColumn ? 1 : 0);

      if (isGroupHeaderRow) {
        styleProps = {
          ...styleProps,
        };

        let content;

        if (isGroupHeaderColumn) {
          content = this.renderGroupHeader({
            row,
            styleProps,
            visibleColumns,
          });
        }

        return (
          <SHeaderItemGroup
            firstColumn={columnIndex === 0}
            {...styleProps}
            key={cKey}
            data-test-id={ecmSelectors.oldGrid.headerCell.testId}
          >
            {content}
          </SHeaderItemGroup>
        );
      }

      const isEmptyRow = row.isEmptyRow === true;
      if (isEmptyRow) {
        return <div {...styleProps} className={column.class} />;
      }

      const isOddRow = striped && (rowIndex + numberOfHeaderRows) % 2 !== 0;

      if (isRankingColumn) {
        const rankNumber = rowIndex + 1 - numberOfHeaderRows + itemsPerPage * (activePage - 1);

        return (
          <SColumn
            textAlign={textAlign}
            className={column.class}
            isOddRow={isOddRow}
            {...styleProps}
            key={cKey}
            data-test-id={ecmSelectors.oldGrid.rankingCell.testId}
          >
            {rankNumber}
          </SColumn>
        );
      }

      const value = column.field.includes('.')
        ? column.field
            .split('.')
            .reduce((value, key) => (value && value[key] ? value[key] : value), row)
        : row[column.field];

      return (
        <SColumn
          textAlign={textAlign}
          {...styleProps}
          className={cn(column.class, { 'old-grid-cell--first': columnIndex === 0 })}
          isOddRow={isOddRow}
          key={cKey}
          data-test-id={ecmSelectors.oldGrid.cell.testId}
        >
          {column.renderer(value, row, column, visibleRows, metaData)}
        </SColumn>
      );
    };

    return (
      <CustomCellMeasurer
        cache={this.cache}
        columnIndex={columnIndex}
        key={cKey}
        parent={parent}
        rowIndex={rowIndex}
        column={column}
      >
        {getContent()}
      </CustomCellMeasurer>
    );
  };

  renderHeader = ({ textAlign, row, column, styleProps, key, isVisible = true }) => {
    const { columns, summaries, orderBy, orderByType, handleOrderBy, groupBy, handleGroupBy } =
      this.props;

    let columnConfig = column;
    let isParentColField = false;

    if (row.isCompositeHeaderRow) {
      if (column.parentColumnField === undefined) {
        return <div style={{ ...styleProps.style, border: 0 }} />;
      }

      isParentColField = true;
      columnConfig = columns.find(col => col.field === column.parentColumnField);
    }

    if (row.isSummaryHeaderRow) {
      return (
        <StyledSummaryHeaderItem
          {...styleProps}
          textAlign={textAlign}
          isVisible={isVisible}
          isParentColField={isParentColField}
          columnConfig={columnConfig}
          key={key}
          summaryValues={summaries ? summaries[column.field] : null}
        />
      );
    }

    return (
      <StyledHeaderItem
        {...styleProps}
        textAlign={textAlign}
        isVisible={isVisible}
        isParentColField={isParentColField}
        column={column}
        orderBy={orderBy}
        orderByType={orderByType}
        handleOrderBy={handleOrderBy}
        groupBy={groupBy}
        handleGroupBy={handleGroupBy}
        key={key}
      />
    );
  };

  renderGroupHeader = ({ row, visibleColumns }) => {
    const { visibleRows, metaData } = this.props;
    const { groupBy } = row;
    const column = visibleColumns.find(col => col.field === groupBy);

    if (!column) {
      return null;
    }

    const columnValue = () => {
      const value = groupBy.includes('.')
        ? groupBy.split('.').reduce((value, key) => (value && value[key] ? value[key] : value), row)
        : row[groupBy];

      if (column.groupHeaderRenderer !== undefined) {
        return column.groupHeaderRenderer(value, row, column);
      }

      if (column.renderer !== undefined) {
        return column.renderer(value, row, column, visibleRows, metaData);
      }

      return value;
    };

    return (
      <React.Fragment>
        <SGroupLabel type="name">{column.label}: </SGroupLabel>
        <SGroupLabel type="value">{columnValue()}</SGroupLabel>
      </React.Fragment>
    );
  };

  render() {
    const {
      visibleRows,
      visibleColumns,
      numberOfHeaderRows,
      showRanking,
      noResultsMessage,
      disableHeight,
      tableWrapperStyles,
    } = this.props;

    const columnCount = visibleColumns.length + (showRanking ? 1 : 0);
    const rowCount = visibleRows.length;
    const rowHeight = this.cache.defaultHeight;
    const minTableHeight = Math.max(
      rowHeight * 1.5,
      rowCount * rowHeight + 20 // 20px for horizontal scrollbar
    );

    return (
      <STableWrapper
        style={{
          ...(tableWrapperStyles ?? {}),
          /*
            The flex basis of 1px here is very important.
            If the flex basis is 0, AutoSizer won't properly
            determine the height of the container.  See ECM-3486.
          */
          flex: '1 1 1px',
          display: 'flex',
        }}
      >
        <AutoSizer
          disableHeight={disableHeight}
          onResize={debounce(({ width, height }) => {
            // VuDo: React throws warning on setState on an unmounted component
            if (width !== 0 && height !== 0) {
              this.setState({ width, height });
            }
          }, 150)}
        >
          {({ width, height }) => {
            this.cache.containerWidth = disableHeight ? width : width - 15;
            this.cache.fullColumnCount = columnCount;

            return (
              <MultiGrid
                ref={child => {
                  this.multiGrid = child;
                }}
                columnWidth={this.cache.columnWidth}
                deferredMeasurementCache={this.cache}
                cellRenderer={this.cellRenderer}
                noContentRenderer={() => <SNoContent>{noResultsMessage}</SNoContent>}
                columnCount={columnCount}
                rowCount={rowCount}
                fixedRowCount={numberOfHeaderRows}
                rowHeight={rowHeight}
                overscanRowCount={10}
                overscanColumnCount={5}
                height={disableHeight ? minTableHeight : height}
                width={width}
              />
            );
          }}
        </AutoSizer>
      </STableWrapper>
    );
  }
}
