import 'antd/lib/popover/style/index.css';

import { css } from 'styled-components/macro';

import { PopoverVariant, Props } from './Popover';

export type SPopoverProps = {
  hideArrow?: boolean;
  variant?: keyof typeof PopoverVariant;
  transparentBackground?: boolean;
  arrowPointAtCenter?: boolean;
  title?: string;
};

export const SPopover = ({
  hideArrow = false,
  transparentBackground = false,
  variant = PopoverVariant.LIGHT,
  title,
}: Props) => css`
  /* Override default z-index of antd Popover */
  z-index: ${({ theme }) => theme.zIndex.popover} !important;

  /* Bandaid fix for issue (ECM-15239) introduced when we upgraded to antd v4.x  */
  pointer-events: auto !important;

  ${transparentBackground &&
  css`
    .ant-popover-inner {
      background: transparent;
    }
  `};

  .ant-popover-inner-content {
    border-radius: ${({ theme }) =>
      title
        ? `0 0 ${theme.border.radius.medium} ${theme.border.radius.medium}`
        : theme.border.radius.medium};
  }

  &.ant-zoom-big-leave {
    display: none;
  }

  ${variant !== PopoverVariant.LIGHT &&
  css`
    .ant-popover-title,
    .ant-popover-inner-content {
      color: ${({ theme }) => theme.text.color.white};
    }
  `};

  ${variant === PopoverVariant.LIGHT_ERROR &&
  css`
    .ant-popover-arrow-content {
      color: ${({ theme }) => theme.text.color.error};
      background-color: ${({ theme }) => theme.background.color.white};
    }

    .ant-popover-title,
    .ant-popover-inner-content {
      color: ${({ theme }) => theme.text.color.error};
      background-color: ${({ theme }) => theme.background.color.white};
    }
  `};

  ${variant === PopoverVariant.SUCCESS &&
  css`
    .ant-popover-arrow-content {
      background-color: ${({ theme }) => theme.brand.color.success};
    }

    .ant-popover-title,
    .ant-popover-inner-content {
      background-color: ${({ theme }) => theme.brand.color.success};
    }
  `};

  ${variant === PopoverVariant.ERROR &&
  css`
    .ant-popover-arrow-content {
      background-color: ${({ theme }) => theme.brand.color.error};
    }

    .ant-popover-inner-content,
    .ant-popover-title {
      background-color: ${({ theme }) => theme.brand.color.error};
    }
  `}

  ${variant === PopoverVariant.DARK &&
  css`
    .ant-popover-arrow-content {
      background-color: ${({ theme }) => theme.brand.color.dark};
    }

    .ant-popover-inner-content,
    .ant-popover-title {
      background-color: ${({ theme }) => theme.brand.color.dark};
    }
  `}

  ${variant === PopoverVariant.TOOLTIP &&
  css`
    .ant-popover-arrow-content {
      background-color: ${({ theme }) => theme.color.gray3};
    }

    .ant-popover-inner-content,
    .ant-popover-title {
      background-color: ${({ theme }) => theme.color.gray3};
    }
  `}

  ${hideArrow === true &&
  css`
    .ant-popover-arrow {
      display: none;
    }

    [class^='ant-popover-placement-bottom'] {
      .ant-popover-content {
        margin-top: -10px;
      }
    }

    [class^='ant-popover-placement-top'] {
      .ant-popover-content {
        margin-bottom: -10px;
      }
    }

    [class^='ant-popover-placement-left'] {
      .ant-popover-content {
        margin-right: -10px;
      }
    }

    [class^='ant-popover-placement-right'] {
      .ant-popover-content {
        margin-left: -10px;
      }
    }
  `}
`;
