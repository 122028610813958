import { createTestId } from '../../../common/selectors';

export const accountListScreenSelector = createTestId('account-list-screen');
export const accountHeaderSelector = createTestId('account-list-header');
export const accountListCreateAccountSelector = createTestId('account-list-create-account');
export const accountListNameSelector = createTestId('account-list-name');
export const accountListNameLinkSelector = createTestId('account-list-name-link');
export const accountListSearchInputSelector = createTestId('account-list-search-input');
export const accountListAccountTypeSelectSelector = createTestId(
  'account-list-account-type-select'
);
export const accountListLicenseTypeSelectSelector = createTestId(
  'account-list-license-type-select'
);
export const accountListTraitsSelectSelector = createTestId('account-list-trait-select');
export const accountListStatusTypeSelectSelector = createTestId('account-list-status-select');
export const accountListNewAccountFormSelector = createTestId(
  'account-list-new-account-form-select'
);
