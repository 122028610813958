import {
  cmgSectorScheme,
  DateRangeField,
  MultiSelectField,
  NumericRangeInputField,
  Option,
  YesNoSelectField,
} from '@cmg/common';
import { Form, FormikProps, withFormik } from 'formik';
import React from 'react';

import { TreeSelectField } from '../../../../common/components/form/selects/TreeSelect';
import { OfferingType } from '../../../../types/domain/offering/constants';
import { FirmSearchMultiSelectField } from '../../firm-search/firm-search-multi-select/FirmSearchMultiSelect';
import SectorToggleLabel from '../../sectors/SectorToggleLabel';
import {
  FormValues,
  getOfferingTypeOptions,
  mapEntityToFormValues,
  mapFormValuesToEntity,
} from './precedent-report.model';
import { SRow } from './PrecedentReportForm.styles';

export type OwnProps = {
  advisoryOptions: Option[];
  offeringType: OfferingType;
  marketCapMax?: number;
  marketCapMin?: number;
  sectors: string[];
  customSectorIds: string[];
  customSectorOptions: Omit<Option, 'label'>[];
  useCustomSectors?: boolean;
  onChange: (values: ReturnType<typeof mapFormValuesToEntity>) => void;
};

export type Props = OwnProps & FormikProps<FormValues>;

export class InnerForm extends React.Component<Props> {
  render() {
    const { advisoryOptions, offeringType, customSectorOptions, values, handleSubmit } = this.props;

    const isIPO = offeringType === OfferingType.IPO;
    const offeringTypeOptions = getOfferingTypeOptions(isIPO);

    return (
      <div>
        <Form>
          <SRow>
            <DateRangeField
              name="date"
              label="Date Range"
              className="range-filter"
              withMargin
              onChange={() => handleSubmit()}
            />

            <MultiSelectField
              name="offeringType"
              label="Offering Type"
              className="select-filter"
              options={offeringTypeOptions}
              disabled={isIPO}
              withMargin
              fullWidth
              onChange={() => handleSubmit()}
            />

            {values && values.useCustomSectors ? (
              <TreeSelectField
                name="customSector"
                className="select-filter"
                treeData={customSectorOptions}
                label={<SectorToggleLabel onChange={handleSubmit} />}
                withMargin
                onChange={handleSubmit}
              />
            ) : (
              <TreeSelectField
                name="sector"
                label={<SectorToggleLabel onChange={handleSubmit} />}
                className="select-filter"
                treeData={cmgSectorScheme}
                withMargin
                onChange={handleSubmit}
              />
            )}
          </SRow>

          <SRow>
            <NumericRangeInputField
              name="marketCap"
              label="Market Cap ($M)"
              className="range-filter"
              inputsProps={{ precision: 0 }}
              withMargin
              onChange={() => handleSubmit()}
            />
            <NumericRangeInputField
              name="offeringSize"
              label="Offering Size ($M)"
              className="range-filter"
              inputsProps={{ precision: 0 }}
              withMargin
              onChange={() => handleSubmit()}
            />
            <NumericRangeInputField
              name="sellingShareholderPct"
              label="Selling ShareHl %"
              className="range-filter"
              inputsProps={{ suffix: '%' }}
              withMargin
              onChange={() => handleSubmit()}
            />
          </SRow>

          <SRow>
            <FirmSearchMultiSelectField
              name="underwriters"
              label="Underwriter"
              firmType="UNDERWRITER"
              className="select-filter"
              withMargin
              fullWidth
              onChange={handleSubmit}
            />

            <YesNoSelectField
              name="leftLeadOnly"
              label="Left Lead Only"
              className="select-filter"
              withMargin
              fullWidth
              isClearable
              onChange={() => handleSubmit()}
            />

            <FirmSearchMultiSelectField
              name="sponsors"
              label="Sponsors"
              firmType="SPONSOR"
              className="select-filter"
              withMargin
              fullWidth
              onChange={() => handleSubmit()}
            />
          </SRow>

          <SRow>
            <MultiSelectField
              name="advisories"
              label="Advisory"
              className="select-filter"
              options={advisoryOptions}
              withMargin
              fullWidth
              onChange={() => handleSubmit()}
            />
          </SRow>
        </Form>
      </div>
    );
  }
}

export default withFormik<OwnProps, FormValues>({
  enableReinitialize: false,
  isInitialValid: false,
  validateOnBlur: true,
  validateOnChange: true,
  handleSubmit: (values, { props }) => {
    props.onChange(mapFormValuesToEntity(values));
  },
  mapPropsToValues: (props: OwnProps) =>
    mapEntityToFormValues(
      props.marketCapMin,
      props.marketCapMax,
      props.offeringType,
      props.sectors,
      props.customSectorIds,
      props.useCustomSectors
    ),
})(InnerForm);
