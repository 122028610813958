import { datalabApi, UUID } from '@cmg/api';
import { AsyncMultiSelect, createFormikField } from '@cmg/common';
import React from 'react';

import defaultDataSource from '../dataSource';
import FirmSearchOptionRenderer from '../firm-search-options/FirmSearchOptionRenderer';
import { FirmDataSource, FirmSearchOption } from '../types';
import { StyledSearchIcon } from './FirmSearchMultiSelect.styles';

// TODO export AsyncMultiSelectProps from @cmg/common
type AsyncMultiSelectProps = any;

export type Props = {
  /**
   * Limit the options retrieved to a specific firm type
   */
  firmType?: datalabApi.FirmType;
  /**
   * Swappable API interface. Defaults to our dlgw api, but can be changed for stories / tests.
   */
  dataSource: FirmDataSource;
} & AsyncMultiSelectProps;

/**
 * A searchable select component performs searches on firms. It is meant to be used
 * wherever we perform firm lookups. Props like firmStatus and firmType are passed
 * in to configure the remote search.
 */
class FirmSearchMultiSelect extends React.Component<Props> {
  static defaultProps = {
    dataSource: defaultDataSource,
  };

  handleLoadOptions = async (inputValue: string) => {
    if (inputValue) {
      return this.props.dataSource.searchByText(inputValue, this.props.firmType);
    }
    return [];
  };

  handleLoadMissingOptions = (missingIds: UUID[]) => {
    return this.props.dataSource.searchByFirmIds(missingIds);
  };

  render() {
    const { placeholder, firmType, dataSource, ...restProps } = this.props;

    return (
      <AsyncMultiSelect<UUID>
        isSearchable
        placeholder={placeholder || 'Search firms...'}
        loadOptions={this.handleLoadOptions}
        loadMissingOptions={this.handleLoadMissingOptions}
        renderOption={option => <FirmSearchOptionRenderer option={option as FirmSearchOption} />}
        renderDropdownIndicator={() => <StyledSearchIcon name="search" size="sm" />}
        noOptionsMessage={({ inputValue }: { inputValue: string }) => {
          if (inputValue) {
            return `No firms matching ${inputValue} found.`;
          }

          return 'Please start typing a firm name to see results';
        }}
        {...restProps}
      />
    );
  }
}

export default FirmSearchMultiSelect;

export const FirmSearchMultiSelectField = createFormikField<UUID, UUID, HTMLInputElement, Props>(
  FirmSearchMultiSelect
);
