import React, { ReactNode } from 'react';

import { SResponsiveWrapper, SRightCell, SRightHeader, SSummaryRow, STable } from './Table.styles';

export type Props = {
  withCellMargin?: boolean;
  striped?: boolean;
  reversed?: boolean;
  responsive?: boolean;
  headerSeparator?: boolean;
  testId?: string;
  children?: ReactNode;
};

/**
 * Use in place of a <table> element to create a styled html table.
 *
 * This is meant to stay simple and only be used for styling.
 * If more complex functionality is introduced like generating a table
 * structure from a data prop, that is the responsibility of a component that would wrap this.
 *
 * @param withCellMargin - Set to false to set smaller padding to reduce Table size
 * @param striped - Each second row has different background color
 * @param responsive - Table container can be scrolled horizontally on smaller devices
 * @param headerSeparator - Header cells will be separated with border
 */
class Table extends React.Component<Props> {
  static SummaryRow = SSummaryRow;
  static RightHeader = SRightHeader;
  static RightCell = SRightCell;

  render() {
    const { children, responsive = true, testId, ...rest } = this.props;
    return (
      <SResponsiveWrapper responsive={responsive}>
        <STable data-test-id={testId} {...rest}>
          {children}
        </STable>
      </SResponsiveWrapper>
    );
  }
}

export default Table;
