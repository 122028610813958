import React from 'react';

export type Options<TProps extends {}> = {
  /**
   * Includes only specified properties in controls
   */
  includeOnly?: (keyof TProps)[];
  /**
   * Excludes all the specified properties from controls
   */
  exclude?: (keyof TProps)[];
};

/**
 * Creates story with controls
 *
 * @param defaultProps Default story props
 * @param ReactElement Element to be shown in the story
 * @param options Controls options
 * @returns Story with controls
 */
export function createStoryWithControls<TProps extends {}>(
  defaultProps: TProps,
  ReactElement: React.JSXElementConstructor<TProps>,
  options?: Options<TProps>
) {
  const story = (args?: Partial<TProps>) => {
    return <ReactElement {...defaultProps} {...args} />;
  };

  story.args = { ...defaultProps };

  if (options) {
    story.parameters = {
      controls: {
        include: options.includeOnly,
        exclude: options.exclude,
      },
    };
  }

  return story;
}
