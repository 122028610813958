import { createTestId } from '../../common/selectors';

const create = (name: string) => createTestId(`xc-my-offerings-${name}`);

export const myOfferingsScreen = create('screen');

export const myOfferingsOfferingsTab = create('offerings-tab');
export const myOfferingsDraftsTab = create('drafts-tab');
export const myOfferingsSharedDraftsTab = create('shared-drafts-tab');

// navbar
export const myOfferingsNavbar = create('navbar');
export const myOfferingsNavbarOfferings = create('navbar-offerings');
export const myOfferingsNavbarDrafts = create('navbar-drafts');
export const myOfferingsNavbarSharedDrafts = create('navbar-shared-drafts');

export const myOfferingsCreateNewOfferingButton = create('create-new-offering-button');
export const myOfferingsOfferingLink = create('offering-link');

export const myOfferingsOfferingSearchTextInput = create('offering-search-text-input');

export const myOfferingsDraftOfferingShareHistoryIcon = create('draft-offering-share-history-icon');
export const myOfferingsDraftOfferingShareHistoryTooltip = create(
  'draft-offering-share-history-tooltip'
);

export const myOfferingsDraftOfferingType = create('draft-offering-type');
