import { createTestId } from '../../common/selectors';

export * from './deal-teams';
export * from './investors-form';
export * from './logistics-details-form';
export * from './meeting-details-form';
export * from './participants-form';
export * from './roadshow-calendar';
export * from './roadshow-configuration';
export * from './roadshows-list';

const create = (name: string) => createTestId(`xc-roadshows-${name}`);

export const roadshowsScreen = create('roadshows-screen');
