import { combineReducers } from 'redux';

import { GenericServerError } from '../../api/types/ApiClientResponses';
import { createReducer } from '../reduxUtil';

type ActionTypes = {
  REQUEST: string;
  SUCCESS: string;
  FAILURE: string;
  RESET: string;
  CLEAR_ERROR: string;
};

type CallStatus = 'NOT_STARTED' | 'FAILED' | 'SUCCEEDED';

export type APIDuckPartState<TSuccessValue, TErrorValue> = {
  loading: boolean;
  status: CallStatus;
  error: TErrorValue | null;
  data: TSuccessValue | null;
};

const makeLoadingReducer = (at: ActionTypes) =>
  createReducer<boolean, any>(false, {
    [at.REQUEST]: () => true,
    [at.SUCCESS]: () => false,
    [at.FAILURE]: () => false,
    [at.RESET]: () => false,
  });

const makeStatusReducer = (at: ActionTypes) =>
  createReducer<CallStatus, any>('NOT_STARTED', {
    [at.SUCCESS]: () => 'SUCCEEDED',
    [at.FAILURE]: () => 'FAILED',
    [at.RESET]: () => 'NOT_STARTED',
  });

const makeDataReducer = <TSuccessData>(at: ActionTypes) =>
  createReducer<TSuccessData | null, any>(null, {
    [at.SUCCESS]: (curState, successAction) =>
      successAction.payload ? successAction.payload : null,
    [at.RESET]: () => null,
  });

const makeErrorReducer = <TError>(at: ActionTypes) =>
  createReducer<TError | null, any>(null, {
    [at.REQUEST]: () => null,
    [at.SUCCESS]: () => null,
    [at.FAILURE]: (curState, failureAction) => failureAction.payload,
    [at.RESET]: () => null,
    [at.CLEAR_ERROR]: () => null,
  });

const makeActionCreator = <TPayload>(actionType: string) => {
  // TODO - Conditionally optional parameters are not supported by TS yet: https://github.com/Microsoft/TypeScript/issues/12400
  // It's more important that when TPayload is not of type undefined, that we require that value to be passed.
  return (payload: TPayload) => {
    return {
      type: actionType,
      payload,
    };
  };
};

/**
 * This factory keeps management of our api call state DRY by building typesafe action creators,
 * reducers, selectors, and state that can be plugged into any existing feature duck.
 * It makes no assumptions about where the data comes from. That is up to the duck that plugs
 * these duck parts in.
 * @returns common duck parts for use with a single api call
 */
const makeAPIDuckParts = <
  TRequestParams = undefined,
  TSuccessValue = undefined,
  TErrorValue = GenericServerError
>(params: {
  prefix: string;
}) => {
  /**
   * MAKE ACTIONS
   */

  const actionTypes = {
    REQUEST: `${params.prefix}/REQUEST`,
    SUCCESS: `${params.prefix}/SUCCESS`,
    FAILURE: `${params.prefix}/FAILURE`,
    RESET: `${params.prefix}/RESET`,
    CLEAR_ERROR: `${params.prefix}/CLEAR_ERROR`,
  };

  const actionCreators = {
    request: makeActionCreator<TRequestParams>(actionTypes.REQUEST),
    success: makeActionCreator<TSuccessValue>(actionTypes.SUCCESS),
    failure: makeActionCreator<TErrorValue>(actionTypes.FAILURE),
    reset: () => ({ type: actionTypes.RESET }), // makeActionCreator does not allow "no payload"
    clearError: () => ({ type: actionTypes.CLEAR_ERROR }),
  };

  type RequestAction = ReturnType<typeof actionCreators.request>;
  type SuccessAction = ReturnType<typeof actionCreators.success>;
  type FailureAction = ReturnType<typeof actionCreators.failure>;
  type ResetAction = ReturnType<typeof actionCreators.reset>;
  type ClearErrorAction = ReturnType<typeof actionCreators.clearError>;

  type ActionsUnion =
    | RequestAction
    | SuccessAction
    | FailureAction
    | ResetAction
    | ClearErrorAction;

  /**
   * MAKE STATE
   */

  type LocalState = APIDuckPartState<TSuccessValue, TErrorValue>;
  const initialState: LocalState = {
    loading: false,
    status: 'NOT_STARTED',
    error: null,
    data: null,
  };

  /**
   * MAKE REDUCERS
   */

  const reducer = combineReducers<LocalState, ActionsUnion>({
    loading: makeLoadingReducer(actionTypes),
    status: makeStatusReducer(actionTypes),
    data: makeDataReducer<LocalState['data']>(actionTypes),
    error: makeErrorReducer<LocalState['error']>(actionTypes),
  });

  /**
   * MAKE SAGAS
   */

  // TODO - Need to think about this one, but we might be able to provide a convenience saga that
  // covers a majority of logic-less api calls we make. We could take a custom 'requestFn' as a param
  // to makeAPIDuckParts that resolves or rejects, and makeSaga would dispatch the success or failure
  // action accordingly.
  // const saga = makeSaga(...);

  /**
   * MAKE SELECTORS
   */

  const localSelectors = {
    selectAll: (localState: LocalState) => localState,
    selectLoading: (localState: LocalState) => localState.loading,
    selectData: (localState: LocalState) => localState.data,
    selectSucceeded: (localState: LocalState) => localState.status === 'SUCCEEDED',
    selectError: (localState: LocalState) => localState.error,
    selectFailed: (localState: LocalState) => localState.status === 'FAILED',
  };

  /**
   * In order to make selectors that accept the full store state as a parameter, this is exposed
   * as a factory function so a getter for the state of the associated duck parts can be supplied.
   * @param getLocalState Function that when given redux store state, returns the state corresponding to LocalState
   */
  const makeSelectors = (selectLocalState: (storeState: any) => LocalState) => {
    return {
      selectAll: (storeState: any) => localSelectors.selectAll(selectLocalState(storeState)),
      selectLoading: (storeState: any) =>
        localSelectors.selectLoading(selectLocalState(storeState)),
      selectData: (storeState: any) => localSelectors.selectData(selectLocalState(storeState)),
      selectSucceeded: (storeState: any) =>
        localSelectors.selectSucceeded(selectLocalState(storeState)),
      selectError: (storeState: any) => localSelectors.selectError(selectLocalState(storeState)),
      selectFailed: (storeState: any) => localSelectors.selectFailed(selectLocalState(storeState)),
    };
  };

  return {
    actionTypes,
    actionCreators,
    initialState,
    reducer,
    makeSelectors,
  };
};

export { makeAPIDuckParts };
