import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components/macro';

import Popover from '../../../../overlays/popover/Popover';
import { InfoIcon } from './InfoIcon';
import WarningIcon from './WarningIcon';

type Props = {
  readonly iconPosition?: 'left' | 'right';
  readonly icon?: ReactNode;
  readonly value?: ReactNode;
  readonly tooltip?: ReactNode;
};

const SLayout = styled.div<Props>`
  display: flex;
  align-items: center;

  ${({ iconPosition }) =>
    iconPosition === 'right'
      ? css`
          flex-direction: row-reverse;
          > :first-child {
            margin-left: 6px;
          }
        `
      : css`
          > :last-child {
            margin-left: 6px;
          }
        `}
`;

export const IconLabelRenderer: React.FC<Props> = props => {
  const { value, iconPosition, icon, tooltip } = props;

  return (
    <SLayout iconPosition={iconPosition}>
      <Popover
        variant="DARK"
        placement="bottomRight"
        trigger="hover"
        content={tooltip}
        disabled={!tooltip}
        arrowPointAtCenter
      >
        <div>{icon === 'warning' ? <WarningIcon /> : <InfoIcon />}</div>
      </Popover>
      <span>{value}</span>
    </SLayout>
  );
};

export default IconLabelRenderer;
