import { createTestId } from '../../common/selectors';

export * from './controls';
export * from './dashboard-sections';
export * from './recent-deals';

export const myDashboardScreen = createTestId('my-dashboard-screen');
export const sellSideDashboard = createTestId('my-dashboard-sell-side');
export const buySideDashboard = createTestId('my-dashboard-buy-side');
export const currentPeriod = createTestId('my-dashboard-current-period-column');
export const comparisonPeriod = createTestId('my-dashboard-comparison-period-column');
