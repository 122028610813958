import React, { ReactNode } from 'react';

import Breadcrumb from './Breadcrumb';
import Link from './Link';
import { SNavGroup, SNavigationWrapper, SNavItem } from './SubNavigation.styles';

type OwnNavigationProps = {
  testId?: string;
  children?: ReactNode;
};

/**
 * SubNavigation is used for sub navigation
 * it serves as a container for sub navigation groups and items.
 *
 * SubNavigation.Group will display a list of items in a row
 * SubNavigation.Item will add spacing around SubNavigation.Link
 */
class SubNavigation extends React.Component<OwnNavigationProps> {
  render() {
    const { children, testId, ...rest } = this.props;
    return (
      <SNavigationWrapper data-test-id={testId} {...rest}>
        {children}
      </SNavigationWrapper>
    );
  }

  /**
   * Group is used as a container for Item or Link components
   * it will display its children in a row and align them in the center
   */
  static Group = SNavGroup;
  /**
   * Item is a wrapper around Link that gives it some additional spacing
   */
  static Item = SNavItem;
  static Link = Link;
  static Breadcrumb = Breadcrumb;
}

export default SubNavigation;
