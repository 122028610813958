import merge from 'lodash/merge';

import { Pagination } from '../../api/types/Pagination';

export const makePagination = (overrides?: Partial<Pagination>): Pagination => {
  const defaults: Pagination = {
    activePage: 3,
    perPage: 10,
    totalPages: 30,
    totalCount: 297,
    hasPrevious: true,
    hasNext: true,
  };

  return merge(defaults, overrides);
};

export const paginationDefault = makePagination();
