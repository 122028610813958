import { createTestId } from '../../../common/selectors';

const create = (name: string) => createTestId(`xc-offering-side-panel-navbar-${name}`);

export const offeringSidePanelOfferingNotesLink = create('offering-notes-link');

export const offeringSidePanelBuySideOrderLink = create('buy-side-order');

export const offeringSidePanelCommunicatedDemandLink = create('communicated-demand-link');

export const offeringSidePanelInternalDemandLink = create('internal-demand-link');

export const offeringSidePanelUpdateHistoryLink = create('update-history-link');

export const offeringSidePanelSellSideOrderBookLink = create('sell-side-order-book-link');
