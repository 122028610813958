/**
 * This util module contains functions for dealing with dates and time.
 * Formatting
 */
import { format, isDate, isSameDay, isValid, parseISO } from 'date-fns';
import { formatInTimeZone, getTimezoneOffset, utcToZonedTime } from 'date-fns-tz';
import { EqualityFn } from 'memoize-one';

import { ISODate, ISODateTime } from '../../types/common';

/**
 * DISPLAY formats. We display dates in these formats.
 * Do not export these. All date formatting should be done by functions exported from this file.
 */
const TimeFormat = 'h:mm a';
const LiteralShortDateFormat = 'EEE, MMM d, yyyy';
const ISODateFormat = 'yyyy-MM-dd';
const USDateFormat = 'MM/dd/yyyy';
const USDateTimeFormat = 'M/d/yyyy h:mm a';
const DayOfWeekFormat = 'EEEE';
const MonthAndDateFormat = 'MMMM d';
const MonthDateTimeFormat = 'MMM d | h:mm a';
const MonthDateYearFormat = 'MMM d, yyyy';
const DayOfWeekAndDateFormat = `${DayOfWeekFormat} ${USDateFormat}`;
const TimezoneAbbreviationFormat = 'zzz';
const DayFormat = 'dd';
const MonthAbbreviationYearFormat = 'MMM yyyy';

export const getDate = (dt: ISODate | ISODateTime | Date): Date => {
  if (isDate(dt)) {
    return dt as Date;
  }
  return parseISO(dt as ISODate | ISODateTime);
};

const isTimezoneInvalid = (timezone: string): boolean => {
  return !timezone || isNaN(getTimezoneOffset(timezone));
};

/**
 * Gets a random number of milliseconds given a number of seconds
 * @param maxSeconds upper bound of random output
 */
export const getRandomMilliseconds = (maxSeconds: number) => {
  return Math.floor(Math.random() * Math.floor(1000 * maxSeconds));
};

/**
 * Given any server side date format, return a MM/DD/YYYY (U.S. Date) string.
 * Use this anywhere that we display a date returned by our API.
 * @returns if date is valid, formatted date string. otherwise, null.
 */
export const formatAsDisplayDate = (dt: ISODate | ISODateTime | Date): string | null => {
  const date = getDate(dt);
  return isValid(date) ? format(date, USDateFormat) : null;
};

/**
 * Given any server side date format, return the full name of the day of the week.
 * @returns if date is valid, the full name of the day of the week. otherwise, null.
 */
export const formatAsDisplayDay = (dt: ISODate | ISODateTime | Date): string | null => {
  const date = getDate(dt);
  return isValid(date) ? format(date, DayOfWeekFormat) : null;
};

/**
 * Given an ISODateTime, return a 'h:mm A' string
 * Use this anywhere that we display a time.
 * @param timezone *optional* the specific timezone that should be used
 * @returns if date is valid, formatted datetime string. otherwise, null;
 */
export const formatAsDisplayTime = (dt: ISODateTime | Date, timezone?: string): string | null => {
  if (timezone && isTimezoneInvalid(timezone)) {
    return null;
  }

  const date = timezone ? utcToZonedTime(getDate(dt), timezone) : getDate(dt);
  return isValid(date) ? format(date, TimeFormat) : null;
};

/**
 * Given an ISODateTime, return a 'M/D/YYYY h:mm A' string
 * Use this anywhere that we display a datetime returned by our API.
 * @param timezone *optional* the specific timezone that should be used
 * @returns if date is valid, formatted datetime string. otherwise, null;
 */
export const formatAsDisplayDateTime = (
  dt: ISODateTime | Date,
  timezone?: string
): string | null => {
  if (timezone && isTimezoneInvalid(timezone)) {
    return null;
  }

  const date = timezone ? utcToZonedTime(getDate(dt), timezone) : getDate(dt);
  return isValid(date) ? format(date, USDateTimeFormat) : null;
};

/**
 * Given an ISO Date Time, return an ISO date string.
 * @returns if date is valid, ISO date string. otherwise, null.
 */
export const formatAsISODate = (dt: string | Date): string | null => {
  const date = getDate(dt);
  return isValid(date) ? format(date, ISODateFormat) : null;
};

/**
 * Given an ISODate return a 'Weekday MM/DD/YYYY' string
 * @returns if date is valid, formatted weekday and date string. otherwise, null;
 */
export const formatAsDisplayDayAndDate = (dt: ISODate | ISODateTime | Date) => {
  const date = getDate(dt);
  return isValid(date) ? format(date, DayOfWeekAndDateFormat) : null;
};

/**
 * Given an ISODateTime, return a 'ddd, MMM D, YYYY' string
 * Use this anywhere that we display a time.
 * @param timezone *optional* the specific timezone that should be used
 * @returns if date is valid, formatted datetime string. otherwise, null;
 */
export const formatAsLiteralShortDate = (dt: ISODateTime | Date): string | null => {
  const date = getDate(dt);
  return isValid(date) ? format(date, LiteralShortDateFormat) : null;
};

/**
 * Given an ISODate return a 'MMMM D' string
 * @returns if date is valid, formatted month and date string. otherwise, null;
 */
export const formatAsMonthDate = (dt: ISODate | ISODateTime | Date) => {
  const date = getDate(dt);
  return isValid(date) ? format(date, MonthAndDateFormat) : null;
};

/**
 * Given an ISODate return a 'MMM DD, YYYY' string
 * @returns if date is valid, formatted month, date, and year string. otherwise, null;
 */
export const formatAsMonthDateYear = (dt: ISODate | ISODateTime | Date) => {
  const date = getDate(dt);
  return isValid(date) ? format(date, MonthDateYearFormat) : null;
};

/**
 * Given an ISODateTime return a 'MMM D | hh:mm A' string
 * @returns if date is valid, formatted month, date and time string. otherwise, null;
 */
export const formatAsMonthDateTime = (dt: ISODateTime | Date) => {
  const date = getDate(dt);
  return isValid(date) ? format(date, MonthDateTimeFormat) : null;
};

/**
 * Given an ISODate and timezone region return timezone abbreviation string
 * @returns if date is valid, timezone abbreviation string
 */
export const formatAsTimeZoneAbbreviation = (
  dt: ISODate | ISODateTime | Date,
  timezone: string
) => {
  if (isTimezoneInvalid(timezone)) {
    return null;
  }
  const date = getDate(dt);
  return isValid(date) ? formatInTimeZone(date, timezone, TimezoneAbbreviationFormat) : null;
};

// NOTE: If we need to display dates for a particular timezone, new functions that
// account for timezone should be created. Keep the 90% case without timezones simple.

/**
 * Given 2 ISODateTimes and time zone (IANA), return a 'h:mm A - h:mm A z' string (i.e. 11:00 AM - 2:00 PM CST)
 * Use this anywhere that we display a datetime returned by our API.
 * @returns if dates and timezone are all valid, formatted datetime string. otherwise, null;
 */
export const formatAsDisplayTimeRange = (
  fromDt: ISODateTime | Date,
  toDt: ISODateTime | Date,
  timeZone?: string
): string | null => {
  if (timeZone && isTimezoneInvalid(timeZone)) {
    return null;
  }

  const dayFrom = timeZone ? utcToZonedTime(getDate(fromDt), timeZone) : getDate(fromDt);
  const dayTo = timeZone ? utcToZonedTime(getDate(toDt), timeZone) : getDate(toDt);

  if (!isValid(dayFrom) || !isValid(dayTo)) {
    return null;
  }

  const from = formatAsDisplayTime(dayFrom);
  const to = formatAsDisplayTime(dayTo);

  if (timeZone) {
    const timeZoneAbbr = formatAsTimeZoneAbbreviation(fromDt, timeZone);
    return `${from} - ${to} ${timeZoneAbbr}`;
  }

  return `${from} - ${to}`;
};

/**
 * Given ISODateTime or Date, return 'dd' string
 * @returns If date is valid returns day, otherwise null
 */
export const formatAsDay = (dt: ISODateTime | Date): string | null => {
  const date = getDate(dt);
  return isValid(date) ? format(date, DayFormat) : null;
};

/**
 * Given ISODateTime or Date, return 'MMM yyyy' string
 * @returns If date is valid returns month abbreviation and year, otherwise null
 */
export const formatAsMonthAbbreviationAndYear = (dt: ISODateTime | Date): string | null => {
  const date = getDate(dt);
  return isValid(date) ? format(date, MonthAbbreviationYearFormat) : null;
};

export const isSameDayEqualityFn: EqualityFn<<TResult>(date: Date) => TResult> = (
  newArgs,
  lastArgs
) => {
  return isSameDay(newArgs[0], lastArgs[0]);
};
