import { createTestId } from '../common/selectors';

const create = name => createTestId(`old-grid-${name}`);

export const firstCell = create('cell-first');
export const cell = create('cell');
export const headerCell = create('header-cell');
export const rankingCell = create('ranking-cell');
export const toolbarDownloadButton = create('toolbar-download-button');
export const popoverDownloadButton = create('popover-download-button');
export const headerItem = create('header-item');
export const toolbarColumnsTogglerButton = create('toolbar-columns-toggler-button');
export const toolbarDropdownCheckboxTree = create('toolbar-dropdown-checkbox-tree');
