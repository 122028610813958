import type { TooltipProps as MUITooltipProps } from '@mui/material/Tooltip';
import MUITooltip from '@mui/material/Tooltip';
import React from 'react';

export type TooltipProps = Omit<MUITooltipProps, 'slotProps' | 'slots'> &
  Readonly<{
    variant?: 'success' | 'warning' | 'error' | 'info';
  }>;

export const Tooltip = React.forwardRef<HTMLDivElement, TooltipProps>((props, ref) => {
  const { children, variant, arrow, ...muiTooltipProps } = props;

  return (
    <MUITooltip
      {...muiTooltipProps}
      ref={ref}
      arrow={!variant && arrow}
      slotProps={{
        tooltip: { variant },
      }}
    >
      {children}
    </MUITooltip>
  );
});
