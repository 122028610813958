import './AgGridWrapper.scss';

import type {
  ColDef,
  ColGroupDef,
  GetRowNodeIdFunc,
  GridApi,
  GridReadyEvent,
  ModelUpdatedEvent,
  RowNode,
} from 'ag-grid-community';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import deepMerge from 'deepmerge';
import React, { CSSProperties } from 'react';

import type { Maybe } from '../../../../types';
import { SAgGrid } from './AgGridWrapper.styles';
import {
  defaultColDef,
  defaultFrameworkComponents,
  defaultGridOptions,
  defaultRowHeight,
  defaultRowStyle,
  OnGridReadyEventFn,
  resizeGrid,
  ResizeProps,
  SelectionChangedEventFn,
} from './BaseAgGrid.model';
import { GridApiContext } from './Context';

type ColumnDefinition = ColDef | ColGroupDef;
type GetRowHeightFn = (params: unknown) => number;
type GetRowStyleFn = (params: unknown) => CSSProperties;

type Props<TRow = Record<string, unknown>, TContext = unknown> = Readonly<
  Pick<
    AgGridReactProps,
    | 'onCellValueChanged'
    | 'gridOptions'
    | 'frameworkComponents'
    | 'noRowsOverlayComponentFramework'
    | 'isRowSelectable'
  >
> &
  ResizeProps & {
    readonly context?: Maybe<TContext>;
    readonly columns?: readonly ColumnDefinition[];
    readonly rows?: readonly TRow[];
    readonly pinnedTopRows?: readonly unknown[];
    readonly pinnedBottomRows?: readonly unknown[];

    readonly loading?: Maybe<boolean>;
    readonly rowHeight?: Maybe<number | GetRowHeightFn>;
    readonly rowStyle?: Maybe<CSSProperties | GetRowStyleFn>;
    readonly rowNodeId?: Maybe<GetRowNodeIdFunc | keyof TRow>;
    readonly defaultColDef?: Maybe<ColDef>;
    readonly rowSelection?: 'single' | 'multiple';
    readonly onSelectionChanged?: SelectionChangedEventFn;
    readonly onGridReady?: OnGridReadyEventFn;
    readonly onModelUpdated?: (event: ModelUpdatedEvent) => void;
    isFullWidthCell?: (rowNode: RowNode) => boolean;
    fullWidthCellRendererFramework?: any;
  };

/**
 * Instance of BaseAgGrid Component.
 * Wraps Ag Grid with good defaults.
 * @param props
 * @returns
 */
export const BaseAgGrid = <TRow extends unknown>(props: React.PropsWithChildren<Props<TRow>>) => {
  const {
    children,
    context,
    columns,
    rows,
    loading,
    pinnedTopRows,
    pinnedBottomRows,
    rowHeight = defaultRowHeight,
    rowStyle: inputRowStyle,
    rowNodeId = 'id',
    defaultColDef: inputColDef,
    gridOptions,
    frameworkComponents,
    onGridReady,
    resize,
    isFullWidthCell,
    fullWidthCellRendererFramework,
    ...rest
  } = props;

  const [gridApi, setGridApi] = React.useState<Maybe<GridApi>>();
  const onGridReadyHandler = React.useCallback(
    (event: GridReadyEvent) => {
      resizeGrid(event, { resize });
      setGridApi(event.api);
      if (onGridReady != null) {
        onGridReady(event);
      }
    },
    [onGridReady, resize]
  );

  const getRowNodeId = React.useCallback(
    data => {
      if (typeof rowNodeId === 'string') {
        return data[rowNodeId];
      }

      if (typeof rowNodeId === 'function') {
        return rowNodeId(data);
      }

      return undefined;
    },
    [rowNodeId]
  );

  React.useEffect(() => {
    if (loading) {
      gridApi?.showLoadingOverlay();
    } else if (rows && rows.length === 0) {
      gridApi?.showNoRowsOverlay();
    } else {
      gridApi?.hideOverlay(); // hideOverlay hides both loading and noRows overlays https://github.com/ag-grid/ag-grid/issues/3849
    }
  }, [gridApi, loading, rows]);

  const mergedDefaultColDef = React.useMemo(
    () => deepMerge(defaultColDef, inputColDef ?? {}),
    [inputColDef]
  );

  const mergedRowStyle = React.useMemo(
    () =>
      typeof inputRowStyle === 'function'
        ? params => deepMerge(defaultRowStyle, inputRowStyle(params))
        : deepMerge(defaultRowStyle, inputRowStyle ?? {}),
    [inputRowStyle]
  );

  const mergedGridOptions = React.useMemo(
    () => deepMerge(defaultGridOptions, gridOptions ?? {}),
    [gridOptions]
  );

  /* 
    Seems onGridSizeChanged was deprecated in ag-grid and then reintroduced; however, the TS definitions are still flagged as deprecated
    https://github.com/ag-grid/ag-grid/issues/2440 
  */
  const onGridSizeChanged = React.useCallback((event: any) => {
    const api: Maybe<GridApi> = event?.api;
    if (api == null) {
      return;
    }

    api.sizeColumnsToFit();
  }, []);

  const mergedFrameworkComponents = React.useMemo(
    () =>
      frameworkComponents != null
        ? deepMerge(defaultFrameworkComponents, frameworkComponents)
        : defaultFrameworkComponents,
    [frameworkComponents]
  );

  const handleOnModelUpdatedChange = (args: ModelUpdatedEvent) =>
    props.onModelUpdated && props.onModelUpdated(args);

  return (
    <GridApiContext.Provider value={gridApi}>
      <SAgGrid className="cmg-legacy-data-grid ag-theme-balham" height="100%" leftPinned>
        <AgGridReact
          context={context}
          columnDefs={columns as ColumnDefinition[]}
          rowData={rows as TRow[]}
          pinnedTopRowData={pinnedTopRows as unknown[]}
          pinnedBottomRowData={pinnedBottomRows as unknown[]}
          rowHeight={typeof rowHeight === 'number' ? rowHeight : undefined}
          getRowHeight={typeof rowHeight === 'function' ? rowHeight : undefined}
          rowStyle={typeof mergedRowStyle === 'object' ? mergedRowStyle : undefined}
          getRowStyle={typeof mergedRowStyle === 'function' ? mergedRowStyle : undefined}
          onGridReady={onGridReadyHandler}
          getRowNodeId={getRowNodeId}
          immutableData={true}
          defaultColDef={mergedDefaultColDef}
          gridOptions={mergedGridOptions}
          onGridSizeChanged={onGridSizeChanged}
          frameworkComponents={mergedFrameworkComponents}
          rowDeselection
          isFullWidthCell={isFullWidthCell}
          onModelUpdated={handleOnModelUpdatedChange}
          fullWidthCellRendererFramework={fullWidthCellRendererFramework}
          {...rest}
        >
          {children}
        </AgGridReact>
      </SAgGrid>
    </GridApiContext.Provider>
  );
};

export default BaseAgGrid;
