import { datalabApi, PaginatedResponse } from '@cmg/api';
import { apiTypes, duckPartFactory, mixpanelUtil, reduxUtil } from '@cmg/common';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { AnyAction, combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { RootState } from '../../../common/redux/rootReducer';
import { CalendarCategory } from '../../../types/domain/calendar/constants';

const { createMixPanelAction } = mixpanelUtil;

/**
 * ACTION TYPES
 */
export enum ActionTypes {
  RESET_MY_DASHBOARD = 'MY_DASHBOARD/RESET_MY_DASHBOARD',
}

/**
 * DUCK PARTS DEFINITIONS
 */
export const {
  actionCreators: {
    request: fetchRecentlyPricedDealsRequest,
    success: fetchRecentlyPricedDealsSucceeded,
    failure: fetchRecentlyPricedDealsFailed,
  },
  initialState: recentlyPricedDealsInitialState,
  reducer: recentlyPricedDealsReducer,
  actionTypes: {
    REQUEST: fetchRecentlyPricedDealsRequestType,
    SUCCESS: fetchRecentlyPricedDealsSuccessType,
  },
  makeSelectors: makeRecentlyPricedDealsSelectors,
} = duckPartFactory.makeAPIDuckParts<
  Partial<apiTypes.ListParams>,
  PaginatedResponse<datalabApi.CalendarOffering[]>
>({
  prefix: 'MY_DASHBOARD/FETCH_RECENTLY_PRICED_DEALS',
});
/**
 * ACTIONS
 */
export type FetchRecentlyPricedDealsAction = ReturnType<typeof fetchRecentlyPricedDealsRequest>;

export const resetMyDashboard = () => ({ type: ActionTypes.RESET_MY_DASHBOARD });
export type ResetMyDashboardAction = ReturnType<typeof resetMyDashboard>;

type Actions = {
  [ActionTypes.RESET_MY_DASHBOARD]: ResetMyDashboardAction;
};

/**
 * REDUCERS
 */
const { createReducer } = reduxUtil;

export type ReducerState = {
  /** recently priced deals */
  recentDeals: typeof recentlyPricedDealsInitialState;

  /** combined recent deals includes all the paginated values from the infinite scroll */
  combinedRecentDeals: datalabApi.CalendarOffering[];
};

export const initialState: ReducerState = {
  recentDeals: recentlyPricedDealsInitialState,
  combinedRecentDeals: [],
};

/**
 * combinedRecentDealsReducer - combines all recently priced deals that were requested and paginated over
 * using infinite scroll.
 *
 * Note: state.recentDeals is the current/last record requested whereas combined includes all records
 */
export const combinedRecentDealsReducer = createReducer<ReducerState['combinedRecentDeals'], any>(
  initialState.combinedRecentDeals,
  {
    [fetchRecentlyPricedDealsSuccessType]: (
      curState,
      { payload }: ReturnType<typeof fetchRecentlyPricedDealsSucceeded>
    ) => [...curState, ...payload.data],
  }
);

/**
 * @todo replace with resettable reducer once moved to cmg/common
 */
const crossSliceReducer = createReducer<ReducerState, Actions>(initialState, {
  [ActionTypes.RESET_MY_DASHBOARD]: () => initialState,
});

export const combinedReducers = combineReducers<ReducerState>({
  recentDeals: recentlyPricedDealsReducer,
  combinedRecentDeals: combinedRecentDealsReducer,
});

export const reducer = function duckReducer(state: ReducerState = initialState, action: AnyAction) {
  const intermediateState = combinedReducers(state, action);
  return crossSliceReducer(intermediateState, action);
};

/**
 * Selectors
 */
export const selectRecentDealsState = (state: RootState) => state.myDashboard.recentDeals;

/** Recently Priced Deals */
export const selectRecentDeals = state => selectRecentDealsState(state).recentDeals;
const recentDealsSelectors = makeRecentlyPricedDealsSelectors(selectRecentDeals);

export const selectRecentDealsData = state => selectRecentDealsState(state).combinedRecentDeals;

/**
 * selectRecentDealsGroupedByFirstTradeDate sorts and groups recently priced deals
 * by the first trade date
 */
export const selectRecentDealsGroupedByFirstTradeDate = createSelector(
  (state: RootState) => selectRecentDealsData(state),
  (recentDeals: datalabApi.CalendarOffering[]) => {
    if (recentDeals.length) {
      const sortedByFirstTradeDateDesc = orderBy(recentDeals, ['firstTradeDate'], ['desc']);
      return groupBy(sortedByFirstTradeDateDesc, 'firstTradeDate');
    }
    return null;
  }
);

export const selectRecentDealsPagination = state => selectRecentDeals(state).data?.pagination;
export const selectRecentDealsLoading = recentDealsSelectors.selectLoading;
export const selectRecentDealsError = recentDealsSelectors.selectError;

/**
 * SAGAS
 */

/** Fetch My Dashboard Recently Priced Deals saga */
export function* fetchRecentlyPricedDealsSaga({
  payload,
}: FetchRecentlyPricedDealsAction): SagaIterator {
  const params = {
    category: CalendarCategory.PRICED,
    perPage: 15,
    includeTotals: true,
    ...payload,
  };

  yield put(
    createMixPanelAction(fetchRecentlyPricedDealsRequestType, 'Fetch Recently Priced Deals', params)
  );

  const resp: datalabApi.GetCalendarOfferingsResponse = yield call(
    datalabApi.getCalendarOfferings,
    {},
    params
  );

  if (resp.ok) {
    yield put(fetchRecentlyPricedDealsSucceeded(resp.data));
  } else {
    yield put(fetchRecentlyPricedDealsFailed(resp.data.error));
  }
}

export function* recentlyPricedDealsSaga() {
  yield takeEvery<FetchRecentlyPricedDealsAction>(
    fetchRecentlyPricedDealsRequestType,
    fetchRecentlyPricedDealsSaga
  );
}
