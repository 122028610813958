import { Popover as AntPopover } from 'antd';
import { TooltipProps } from 'antd/lib/tooltip';
import React from 'react';
import styled from 'styled-components/macro';

import { SPopover } from './Popover.styles';

export enum PopoverVariant {
  LIGHT = 'LIGHT',
  LIGHT_ERROR = 'LIGHT_ERROR',
  TOOLTIP = 'TOOLTIP',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  DARK = 'DARK',
}

type PrivateProps = {
  /** For testing purposes only! */
  mouseEnterDelay?: number;
  /** For testing purposes only! */
  mouseLeaveDelay?: number;
};

export type Props = {
  /** Card title that will be rendered in overlay */
  title?: React.ReactNode;
  /** Card content that will be rendered in overlay */
  content?: React.ReactNode;
  /* The position of the popover relative to the target */
  placement?: TooltipProps['placement'];
  /** Popover trigger mode */
  trigger?: TooltipProps['trigger'];
  /** Placement of popover will be dynamically adjusted based on viewport*/
  autoAdjustOverflow?: boolean;
  /**
   * Used to explicitly control whether or not the tooltip is visible.
   * Popover triggers will be ignored, and the visibility of the component will be determined specifically from the value of this prop.
   */
  visible?: boolean;
  /** Whether or not the popover is visible when the trigger is made */
  disabled?: boolean;
  /** Callback executed when visibility of the popover card is changed */
  onVisibleChange?: (visible: boolean) => void;
  /**
   * The style variant to use
   * TODO do not use keyof typeof, instead create a union of strings
   */
  variant?: keyof typeof PopoverVariant;
  /** Set to true for the arrow to point to the center of the wrapper content. False will align to the edge. */
  arrowPointAtCenter?: boolean;
  /** Set to true to hide the popover overlay arrow */
  hideArrow?: boolean;
  /** Displays a transparent background */
  transparentBackground?: boolean;
  /** For internal styling purposes only! */
  className?: string;
};

/**
 * A floating card popped by clicking or hovering.
 */
const PopoverComponent: React.FC<Props & PrivateProps> = ({
  hideArrow,
  variant,
  disabled,
  className,
  mouseEnterDelay = 0.5,
  mouseLeaveDelay = 0.5,
  ...restProps
}) => {
  // Override the natural trigger (focus|hover) by setting visible=false when the popover is disabled
  const visibleProp = disabled
    ? {
        visible: false,
      }
    : undefined;

  return (
    <AntPopover
      {...restProps}
      {...visibleProp}
      mouseEnterDelay={mouseEnterDelay}
      mouseLeaveDelay={mouseLeaveDelay}
      overlayClassName={className}
    />
  );
};

/**
 * Popover has to be styled this way because its content is internally rendered outside of component DOM tree.
 * Generated class name is passed down as className property which is then passed to overlayClassName prop.
 *
 * Styles are defined in yet another styled component so it can be tested properly
 *
 * Exported for testing purposes only!
 *
 * @see https://bitbucket.org/capitalmarketsgateway/ecm_client_web/src/dev/docs/Styling.md#markdown-header-styling-react-portal
 */
export const StyledPopover = styled(PopoverComponent)<Props>`
  ${({ theme, ...rest }) => SPopover(rest as Props)};
`;

/**
 * Popover wrapper that exports public API props only
 */
const Popover: React.FC<Props> = props => {
  return <StyledPopover {...props} />;
};

export default Popover;
