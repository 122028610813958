import type { Theme } from '@mui/material';
import type { LinkProps } from '@mui/material/Link';
import type { ListItemButtonProps } from '@mui/material/ListItemButton';

export function extendTheme(theme: Theme, LinkComponent?: React.ElementType): Theme {
  if (!LinkComponent) {
    return theme;
  }

  return {
    ...theme,
    components: {
      ...theme.components,
      MuiLink: {
        ...theme.components?.MuiLink,
        defaultProps: {
          ...theme.components?.MuiLink?.defaultProps,
          component: LinkComponent,
        } as LinkProps,
      },
      MuiButtonBase: {
        ...theme.components?.MuiButtonBase,
        defaultProps: {
          ...theme.components?.MuiButtonBase?.defaultProps,
          LinkComponent: LinkComponent,
        },
      },
      MuiListItemButton: {
        ...theme.components?.MuiListItemButton,
        defaultProps: {
          ...theme.components?.MuiListItemButton?.defaultProps,
          component: LinkComponent,
        } as ListItemButtonProps,
      },
    },
  };
}
