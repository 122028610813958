import React, { FocusEventHandler } from 'react';

import { SRow } from './Table.styles';

export type Props = {
  onClick?: () => void;
  onBlur?: FocusEventHandler<HTMLDivElement>;
  isFocused?: boolean; // We can override default focus behaviour
  className?: string;
  suppressHighlightingOnHover?: boolean;
  title?: string;
};

export const Row: React.FC<Props> = ({ onClick, isFocused, onBlur, ...props }) => {
  const [isFocusedLocal, setIsFocusedLocal] = React.useState(false);
  const element = React.useRef<HTMLDivElement>(null);

  // We need to remove focus when the user clicks elsewhere
  React.useEffect(() => {
    if (isFocused !== undefined || !isFocusedLocal) {
      return;
    }

    const listener = e => {
      if (element.current && !element.current.contains(e.target)) {
        setIsFocusedLocal(false);
        onBlur?.(e);
      }
    };

    window.addEventListener('click', listener, { capture: true });

    return () => {
      window.removeEventListener('click', listener, { capture: true });
    };
  }, [isFocused, isFocusedLocal, onBlur]);

  const handleOnClick = () => {
    setIsFocusedLocal(true);
    onClick?.();
  };

  return (
    <SRow
      isFocused={isFocused === undefined ? isFocusedLocal : isFocused}
      onClick={handleOnClick}
      {...props}
      ref={element}
    />
  );
};
