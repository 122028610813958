import {
  DLGWCustomFiltersCriteria,
  DLGWOperatorType,
  DLGWRule,
} from '@capital-markets-gateway/api-client-datalab-gateway';

import {
  AdvancedFilterRule,
  AdvancedFilterValue,
  operatorEnum,
} from '../../../../common/components/form/advanced-filter/advanced-filter.model';
import { DataLabTableRowField } from './types';

type DataLabCustomFilterFields =
  | DataLabTableRowField
  | 'underwriter_and_role'
  | 'managers_underwriter_id'
  | 'ipo_range_revision_type'
  | 'sponsors_sponsor_type';

/**
 * Maps the name of a custom filter field defined in the ruby api to a filter 'criteria' (aka name)
 *  in DLGW api.
 *  There are a lot of null mappings because custom filters is largely a subset of table fields, and
 *  the table fields is the best type we have to represent custom filter fields for the ruby api.
 * @param dlFilterFieldName - field name of the custom filter field from ruby
 * @returns field name of the custom filter field in DLGW
 */
const mapCustomFilterCriteria = (dlFilterFieldName?: DataLabCustomFilterFields) => {
  if (!dlFilterFieldName) {
    return undefined;
  }

  const mapping: Record<DataLabCustomFilterFields, DLGWCustomFiltersCriteria | null> = {
    // extra custom filter fields
    underwriter_and_role: DLGWCustomFiltersCriteria.UNDERWRITER_AND_ROLE,
    managers_underwriter_id: DLGWCustomFiltersCriteria.MANAGERS_UNDERWRITER_ID,
    ipo_range_revision_type: DLGWCustomFiltersCriteria.IPO_RANGE_REVISION_TYPE,
    sponsors_sponsor_type: DLGWCustomFiltersCriteria.SPONSORS_SPONSOR_TYPE,

    // table column fields
    allocation: null,
    allocation_investment: null,
    allocation_pct: null,
    book_fees: null,
    book_offerings: null,
    color_code: null,
    companies_cik: DLGWCustomFiltersCriteria.COMPANIES_CIK,
    companies_cusip: DLGWCustomFiltersCriteria.COMPANIES_CUSIP,
    companies_issuer: DLGWCustomFiltersCriteria.COMPANIES_ISSUER,
    companies_sub_sector: null,
    companies_ticker: DLGWCustomFiltersCriteria.COMPANIES_TICKER,
    conditional_lock_up: DLGWCustomFiltersCriteria.CONDITIONAL_LOCK_UP,
    economics_pct: null,
    egc: DLGWCustomFiltersCriteria.EGC,
    estimated_commission: null,
    estimated_fee: DLGWCustomFiltersCriteria.ESTIMATED_FEE,
    fees: null,
    fifty_two_wk_high: DLGWCustomFiltersCriteria.FIFTY_TWO_WK_HIGH,
    filing_details_deal_pct_at_pricing_market_cap:
      DLGWCustomFiltersCriteria.FILING_DETAILS_DEAL_PCT_AT_PRICING_MARKET_CAP,
    filing_details_deal_pct_market_cap_pre_offering:
      DLGWCustomFiltersCriteria.FILING_DETAILS_DEAL_PCT_MARKET_CAP_PRE_OFFERING,
    filing_details_gross_proceeds: DLGWCustomFiltersCriteria.FILING_DETAILS_GROSS_PROCEEDS,
    filing_details_gross_proceeds_with_over_allotment:
      DLGWCustomFiltersCriteria.FILING_DETAILS_GROSS_PROCEEDS_WITH_OVER_ALLOTMENT,
    filing_details_market_cap_at_pricing:
      DLGWCustomFiltersCriteria.FILING_DETAILS_MARKET_CAP_AT_PRICING,
    filing_details_market_cap_pre_offering:
      DLGWCustomFiltersCriteria.FILING_DETAILS_MARKET_CAP_PRE_OFFERING,
    filing_details_net_price: DLGWCustomFiltersCriteria.FILING_DETAILS_NET_PRICE,
    filing_details_offering_price: DLGWCustomFiltersCriteria.FILING_DETAILS_OFFERING_PRICE,
    filing_details_pct_secondary_shares:
      DLGWCustomFiltersCriteria.FILING_DETAILS_PCT_SECONDARY_SHARES,
    filing_details_price_vs_range: DLGWCustomFiltersCriteria.FILING_DETAILS_PRICE_VS_RANGE,
    filing_details_size_in_dollars: DLGWCustomFiltersCriteria.FILING_DETAILS_SIZE_IN_DOLLARS,
    fill_rate: null,
    first_day_turnover: null,
    first_follow_on: DLGWCustomFiltersCriteria.FIRST_FOLLOW_ON,
    follow_on_discount_from_filing: null,
    follow_on_discount_to_last_trade: null,
    fos: null,
    fund_name: null,
    gross_spread: null,
    has_cornerstone_investors: DLGWCustomFiltersCriteria.HAS_CORNERSTONE_INVESTORS,
    cornerstone_investors_offering_participants_count:
      DLGWCustomFiltersCriteria.CORNERSTONE_INVESTORS_OFFERING_PARTICIPANTS_COUNT,
    cornerstone_offering_participants_amount:
      DLGWCustomFiltersCriteria.CORNERSTONE_OFFERING_PARTICIPANTS_AMOUNT,
    cornerstone_investors_private_placement_count:
      DLGWCustomFiltersCriteria.CORNERSTONE_INVESTORS_PRIVATE_PLACEMENT_COUNT,
    cornerstone_private_placement_amount:
      DLGWCustomFiltersCriteria.CORNERSTONE_PRIVATE_PLACEMENT_AMOUNT,
    headquarters: DLGWCustomFiltersCriteria.HEADQUARTERS,
    initial_gross_proceeds_base: DLGWCustomFiltersCriteria.INITIAL_GROSS_PROCEEDS_BASE,
    initial_gross_proceeds_base_local_curr:
      DLGWCustomFiltersCriteria.INITIAL_GROSS_PROCEEDS_BASE_LOCAL_CURR,
    ioi_shares: null,
    ipos: null,
    is_carve_out: DLGWCustomFiltersCriteria.IS_CARVE_OUT,
    is_clean_up_trade: DLGWCustomFiltersCriteria.IS_CLEAN_UP_TRADE,
    is_company_repurchase_additional: DLGWCustomFiltersCriteria.IS_COMPANY_REPURCHASE_ADDITIONAL,
    is_company_repurchase_included: DLGWCustomFiltersCriteria.IS_COMPANY_REPURCHASE_INCLUDED,
    is_concurrent_offering: DLGWCustomFiltersCriteria.IS_CONCURRENT_OFFERING,
    is_dual_listed: DLGWCustomFiltersCriteria.IS_DUAL_LISTED,
    is_synthetic_secondary: DLGWCustomFiltersCriteria.IS_SYNTHETIC_SECONDARY,
    is_up_listing: DLGWCustomFiltersCriteria.IS_UP_LISTING,
    issuer_isin: DLGWCustomFiltersCriteria.ISSUER_ISIN,
    last_trade_before_filing: DLGWCustomFiltersCriteria.LAST_TRADE_BEFORE_FILING,
    last_trade_before_offer: DLGWCustomFiltersCriteria.LAST_TRADE_BEFORE_OFFER,
    left_lead: null,
    left_lead_firm_id: null,
    left_lead_firm_name: null,
    lock_up_early_release_date: DLGWCustomFiltersCriteria.LOCK_UP_EARLY_RELEASE_DATE,
    lock_up_expiration: DLGWCustomFiltersCriteria.LOCK_UP_EXPIRATION,
    lock_up_period: DLGWCustomFiltersCriteria.LOCK_UP_PERIOD,
    max: null,
    mean: null,
    median: null,
    min: null,
    multiple_lock_ups: DLGWCustomFiltersCriteria.MULTIPLE_LOCK_UPS,
    naics: DLGWCustomFiltersCriteria.NAICS,
    non_book_fees: null,
    non_book_offerings: null,
    offer_to_1day: null,
    offer_to_3day: null,
    offer_to_7day: null,
    offer_to_14day: null,
    offer_to_30day: null,
    offer_to_current: null,
    offer_to_open: null,
    offering_confidential_filing_date: DLGWCustomFiltersCriteria.OFFERING_CONFIDENTIAL_FILING_DATE,
    offering_day1_vwap_price: DLGWCustomFiltersCriteria.OFFERING_OFFER_TO_VWAP_1DAY,
    offering_discount_to_vwap: DLGWCustomFiltersCriteria.OFFERING_DISCOUNT_TO_VWAP,
    offering_entity_structure: DLGWCustomFiltersCriteria.OFFERING_ENTITY_STRUCTURE,
    offering_first_day_turnover: DLGWCustomFiltersCriteria.OFFERING_FIRST_DAY_TURNOVER,
    offering_first_day_volume: DLGWCustomFiltersCriteria.OFFERING_FIRST_DAY_VOLUME,
    offering_first_trade_date: DLGWCustomFiltersCriteria.OFFERING_FIRST_TRADE_DATE,
    offering_forward_agreement: DLGWCustomFiltersCriteria.OFFERING_FORWARD_AGREEMENT,
    offering_gross_spread: DLGWCustomFiltersCriteria.OFFERING_GROSS_SPREAD,
    offering_gross_spread_pct: DLGWCustomFiltersCriteria.OFFERING_GROSS_SPREAD_PCT,
    offering_id: DLGWCustomFiltersCriteria.OFFERING_ID,
    offering_initial_ipo_range_high: null,
    offering_initial_ipo_range_low: DLGWCustomFiltersCriteria.OFFERING_INITIAL_IPO_RANGE_LOW,
    offering_initial_registration_value:
      DLGWCustomFiltersCriteria.OFFERING_INITIAL_REGISTRATION_VALUE,
    offering_launch_date: DLGWCustomFiltersCriteria.OFFERING_LAUNCH_DATE,
    offering_offer_to_1day: DLGWCustomFiltersCriteria.OFFERING_OFFER_TO_1DAY,
    offering_offer_to_3day: DLGWCustomFiltersCriteria.OFFERING_OFFER_TO_3DAY,
    offering_offer_to_7day: DLGWCustomFiltersCriteria.OFFERING_OFFER_TO_7DAY,
    offering_offer_to_14day: DLGWCustomFiltersCriteria.OFFERING_OFFER_TO_14DAY,
    offering_offer_to_30day: DLGWCustomFiltersCriteria.OFFERING_OFFER_TO_30DAY,
    offering_offer_to_90day: DLGWCustomFiltersCriteria.OFFERING_OFFER_TO_90DAY,
    offering_offer_to_180day: DLGWCustomFiltersCriteria.OFFERING_OFFER_TO_180DAY,
    offering_offer_to_current: DLGWCustomFiltersCriteria.OFFERING_OFFER_TO_CURRENT,
    offering_offer_to_one_year: DLGWCustomFiltersCriteria.OFFERING_OFFER_TO_ONE_YEAR,
    offering_offer_to_open: DLGWCustomFiltersCriteria.OFFERING_OFFER_TO_OPEN,
    offering_offer_to_prior_quarter: DLGWCustomFiltersCriteria.OFFERING_OFFER_TO_PRIOR_QUARTER,
    offering_offer_to_vwap_1day: DLGWCustomFiltersCriteria.OFFERING_OFFER_TO_VWAP_1DAY,
    offering_over_allotment_authorized:
      DLGWCustomFiltersCriteria.OFFERING_OVER_ALLOTMENT_AUTHORIZED,
    offering_over_allotment_exercised: DLGWCustomFiltersCriteria.OFFERING_OVER_ALLOTMENT_EXERCISED,
    offering_pct_change_in_size: DLGWCustomFiltersCriteria.OFFERING_PCT_CHANGE_IN_SIZE,
    offering_pct_post_offering_ownership:
      DLGWCustomFiltersCriteria.OFFERING_PCT_POST_OFFERING_OWNERSHIP,
    offering_pct_pre_offering_ownership:
      DLGWCustomFiltersCriteria.OFFERING_PCT_PRE_OFFERING_OWNERSHIP,
    offering_post_offering_ownership: DLGWCustomFiltersCriteria.OFFERING_POST_OFFERING_OWNERSHIP,
    offering_post_offering_shares: DLGWCustomFiltersCriteria.OFFERING_POST_OFFERING_SHARES,
    offering_pre_offering_ownership: DLGWCustomFiltersCriteria.OFFERING_PRE_OFFERING_OWNERSHIP,
    offering_pre_offering_shares: DLGWCustomFiltersCriteria.OFFERING_PRE_OFFERING_SHARES,
    offering_preliminary_prospectus_link: null,
    offering_price_vs_midpoint: DLGWCustomFiltersCriteria.OFFERING_PRICE_VS_MIDPOINT,
    offering_primary_shares_base_offering:
      DLGWCustomFiltersCriteria.OFFERING_PRIMARY_SHARES_BASE_OFFERING,
    offering_public_filing_date: DLGWCustomFiltersCriteria.OFFERING_PUBLIC_FILING_DATE,
    offering_revised_ipo_range_high: null,
    offering_revised_ipo_range_low: DLGWCustomFiltersCriteria.OFFERING_REVISED_IPO_RANGE_LOW,
    offering_secondary_shares_base_offering:
      DLGWCustomFiltersCriteria.OFFERING_SECONDARY_SHARES_BASE_OFFERING,
    offering_security_type: DLGWCustomFiltersCriteria.OFFERING_SECURITY_TYPE,
    offering_settlement_date: DLGWCustomFiltersCriteria.OFFERING_SETTLEMENT_DATE,
    offering_size: DLGWCustomFiltersCriteria.OFFERING_SIZE,
    offering_split_adjusted_offering_price:
      DLGWCustomFiltersCriteria.OFFERING_SPLIT_ADJUSTED_OFFERING_PRICE,
    offering_status: null,
    offering_total_bookrunners: DLGWCustomFiltersCriteria.OFFERING_TOTAL_BOOKRUNNERS,
    offering_total_co_managers: DLGWCustomFiltersCriteria.OFFERING_TOTAL_MANAGERS,
    offering_total_managers: DLGWCustomFiltersCriteria.OFFERING_TOTAL_MANAGERS,
    offering_total_non_bookrunners: DLGWCustomFiltersCriteria.OFFERING_TOTAL_NON_BOOKRUNNERS,
    offering_total_pct_to_bookrunners: DLGWCustomFiltersCriteria.OFFERING_TOTAL_PCT_TO_BOOKRUNNERS,
    offering_total_pct_to_co_managers: DLGWCustomFiltersCriteria.OFFERING_TOTAL_MANAGERS,
    offering_total_pct_to_left_lead: DLGWCustomFiltersCriteria.OFFERING_TOTAL_PCT_TO_LEFT_LEAD,
    offering_total_pct_to_non_bookrunners:
      DLGWCustomFiltersCriteria.OFFERING_TOTAL_PCT_TO_NON_BOOKRUNNERS,
    offering_use_of_proceeds: null,
    offering_vwap_price: null,
    offerings: null,
    offerings_all_in_cost: DLGWCustomFiltersCriteria.OFFERINGS_ALL_IN_COST,
    offerings_discount_to_52wk_high: DLGWCustomFiltersCriteria.OFFERINGS_DISCOUNT_TO_52WK_HIGH,
    offerings_discount_to_last_trade: DLGWCustomFiltersCriteria.OFFERINGS_DISCOUNT_TO_LAST_TRADE,
    offerings_file_to_offer_discount: DLGWCustomFiltersCriteria.OFFERINGS_FILE_TO_OFFER_DISCOUNT,
    offerings_pre_offering_adtv: DLGWCustomFiltersCriteria.OFFERINGS_PRE_OFFERING_ADTV,
    offerings_pricing_date: DLGWCustomFiltersCriteria.OFFERINGS_PRICING_DATE,
    offerings_sector: DLGWCustomFiltersCriteria.OFFERINGS_SECTOR,
    offerings_size_as_multiple_of_adtv:
      DLGWCustomFiltersCriteria.OFFERINGS_SIZE_AS_MULTIPLE_OF_ADTV,
    offerings_type: DLGWCustomFiltersCriteria.OFFERINGS_TYPE,
    offerings_volume: null,
    pct_of_deal: null,
    pct_of_firm_allocation: null,
    pct_of_firm_offerings: null,
    pct_of_firm_total: null,
    pct_of_market_cap: null,
    primary_shareholder_firm_id: null,
    primary_shareholder_name: DLGWCustomFiltersCriteria.PRIMARY_SHAREHOLDER_NAME,
    re_offer_discount_high: DLGWCustomFiltersCriteria.RE_OFFER_DISCOUNT_HIGH,
    re_offer_discount_low: DLGWCustomFiltersCriteria.RE_OFFER_DISCOUNT_LOW,
    re_offer_high: DLGWCustomFiltersCriteria.RE_OFFER_HIGH,
    re_offer_low: DLGWCustomFiltersCriteria.RE_OFFER_LOW,
    return_1day: null,
    return_1day_volume: null,
    return_3day: null,
    return_7day: null,
    return_7day_volume: null,
    return_current: null,
    return_current_volume: null,
    return_day1: null,
    return_day3: null,
    return_day7: null,
    return_open: null,
    return_open_volume: null,
    return_prior_quarter: null,
    return_prior_quarter_volume: null,
    return_vwap_1day: null,
    return_vwap_1day_volume: null,
    revised_gross_proceeds_base: DLGWCustomFiltersCriteria.REVISED_GROSS_PROCEEDS_BASE,
    revised_gross_proceeds_base_local_curr:
      DLGWCustomFiltersCriteria.REVISED_GROSS_PROCEEDS_BASE_LOCAL_CURR,
    sales_concession: null,
    shareholders: DLGWCustomFiltersCriteria.PRIMARY_SHAREHOLDER_NAME,
    sponsor_name: null,
    timing_of_launch: null,
    total_fees: null,
    total_shares_filed_excl_shoe: DLGWCustomFiltersCriteria.TOTAL_SHARES_FILED_EXCL_SHOE,
    underwriter_name: null,
    underwriter_role: null,
    underwriters: null,
    underwriting_table_shares: null,
    underwritten_volume: null,
    use_of_proceeds_note: DLGWCustomFiltersCriteria.USE_OF_PROCEEDS_NOTE,
    wallet_share: null,
  };

  return mapping[dlFilterFieldName];
};

const mapRuleValue = (dlRuleValue: AdvancedFilterRule['value']) => {
  if (!dlRuleValue) {
    return undefined;
  }

  if (typeof dlRuleValue === 'string' || typeof dlRuleValue === 'number') {
    return [dlRuleValue];
  }

  if (Array.isArray(dlRuleValue)) {
    return dlRuleValue;
  }

  if ('role' in dlRuleValue && 'underwriter_id' in dlRuleValue) {
    return [dlRuleValue.underwriter_id, dlRuleValue.role];
  }

  if ('lteq' in dlRuleValue && 'gteq' in dlRuleValue) {
    return [dlRuleValue.lteq, dlRuleValue.gteq];
  }

  return undefined;
};

const mapOperatorType = (dlOperatorType?: operatorEnum): DLGWOperatorType | undefined => {
  if (!dlOperatorType) {
    return undefined;
  }

  const mapping: Record<operatorEnum, DLGWOperatorType> = {
    [operatorEnum.NULL]: DLGWOperatorType.IS_NULL,
    [operatorEnum.NOT_NULL]: DLGWOperatorType.IS_NOT_NULL,
    [operatorEnum.IN]: DLGWOperatorType.IN,
    [operatorEnum.NOT_IN]: DLGWOperatorType.NOT_IN,
    [operatorEnum.EQUAL]: DLGWOperatorType.EQUAL_TO,
    [operatorEnum.NOT_EQUAL]: DLGWOperatorType.NOT_EQUAL_TO,
    [operatorEnum.LIKE]: DLGWOperatorType.LIKE,
    [operatorEnum.LESS_THAN]: DLGWOperatorType.LESS_THAN,
    [operatorEnum.GREATER_THAN]: DLGWOperatorType.GREATER_THAN,
    [operatorEnum.BETWEEN]: DLGWOperatorType.BETWEEN,
  };

  return mapping[dlOperatorType];
};

// The generated type is incorrect for rules.values. Generated type is a number[].
// It's supposed to be an array that can contain
type CorrectedDLGWRule = Omit<DLGWRule, 'values'> & {
  values: (string | number)[] | undefined;
};

type CorrectedDLGWCustomFilters = {
  rules: CorrectedDLGWRule[];
};

export const mapCustomFilter = (
  dlCustomFilter?: AdvancedFilterValue
): CorrectedDLGWCustomFilters | null => {
  if (!dlCustomFilter) {
    return null;
  }

  return {
    rules: dlCustomFilter.rules.map(rule => ({
      criteria: mapCustomFilterCriteria(rule.field as any) || undefined,
      values: mapRuleValue(rule.value),
      operatorType: mapOperatorType(rule.operator),
    })),
  };
};
