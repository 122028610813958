import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import React from 'react';

import { extendTheme } from './ThemeProvider.model';
import { lightTheme } from './themes';

export type ThemeProviderProps = {
  LinkComponent?: React.ElementType;
};

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ LinkComponent, children }) => {
  const theme = React.useMemo(() => {
    return extendTheme(lightTheme, LinkComponent);
  }, [LinkComponent]);

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
};
