import { createTestId } from '../../common/selectors';

export const recentDeals = createTestId('my-dashboard-recent-deals');
export const recentDealsList = createTestId('my-dashboard-recent-deals-list');
export const noRecentDeals = createTestId('my-dashboard-no-recent-deals');
export const recentDealsGroup = createTestId('my-dashboard-recent-deals-group');
export const recentDealsItem = createTestId('my-dashboard-recent-deals-item');

// mobile
export const showRecentDeals = createTestId('my-dashboard-mobile-show-recent-deals');
