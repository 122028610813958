import { Box, Icon, TextInput } from '@cmg/common';
import React, { useState } from 'react';

import { formatUrl } from '../../../../../common/helpers/helpers';
import { News } from '../../../../../types/domain/offering/news';
import {
  SIconLink,
  SIconWrapper,
  SMessageText,
  SMessageWrapper,
  SNews,
  SNewsList,
  SSearch,
  STitle,
  StyledBox,
  StyledBoxContent,
  StyledIcon,
} from './WidgetNews.styles';

type Props = {
  news: News[];
};

const WidgetNews: React.FC<Props> = ({ news }) => {
  const [query, setQuery] = useState<string | null>('');

  const visibleNews = news.filter(({ text, title }) =>
    title
      .concat(text)
      .toLowerCase()
      .includes((query || '').toLowerCase())
  );

  return (
    <StyledBox>
      <Box.Header title="SEC News" />
      <StyledBoxContent>
        <SSearch>
          <TextInput
            placeholder="Search keywords"
            value={query || ''}
            onChange={setQuery}
            prefix={<StyledIcon name="search" size="lg" />}
          />
        </SSearch>
        {visibleNews.length > 0 && (
          <SNewsList>
            {visibleNews.map(item => (
              <SNews key={item.url}>
                <STitle>{item.title}</STitle>
                <SMessageWrapper>
                  <SMessageText>{item.text}</SMessageText>
                  <SIconWrapper>
                    <SIconLink href={formatUrl(item.url)} target="_blank" rel="noreferrer noopener">
                      <Icon name="external-link-alt" />
                    </SIconLink>
                  </SIconWrapper>
                </SMessageWrapper>
              </SNews>
            ))}
          </SNewsList>
        )}
      </StyledBoxContent>
    </StyledBox>
  );
};

export default WidgetNews;
