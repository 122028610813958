import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import type { FC } from 'react';
import React, { Fragment } from 'react';

import type { DotStatusProps } from '../status';
import { DotStatus } from '../status';

export type ListStatusItemContentProps = Readonly<{
  readonly title: React.ReactNode;
  readonly subTitle?: React.ReactNode;
  readonly status?: DotStatusProps['color'];
}>;

const ListStatusItemIcon = styled(ListItemIcon, {
  name: 'ListItemStatusIcon',
  slot: 'ListItemIcon',
  overridesResolver: (_props, styles) => [styles.root],
})(() => ({
  minWidth: '22px',
  marginTop: '8px',
}));

const ListStatusItemContent: FC<ListStatusItemContentProps> = ({ title, subTitle, status }) => {
  return (
    <Fragment>
      {status && (
        <ListStatusItemIcon>
          <DotStatus color={status} titleAccess={status} />
        </ListStatusItemIcon>
      )}
      <ListItemText
        primary={title}
        primaryTypographyProps={{ variant: 'body1' }}
        secondary={subTitle}
        secondaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
      />
    </Fragment>
  );
};

export default ListStatusItemContent;
