import { createTestId } from '../../../common/selectors';

const create = (name: string) => createTestId(`xc-offering-setup-basic-info-${name}`);

export const offeringSetupBasicInfo = create('basic-info');

const createBasicInfoSelector = (name: string) => create(`basic-info-${name}`);

export const offeringSetupBasicInfoScreen = createBasicInfoSelector('screen');

export const offeringSetupBasicInfoCreateDraft = createBasicInfoSelector('create-draft');

export const offeringSetupBasicInfoSave = createBasicInfoSelector('save');

export const offeringSetupBasicInfoOfferingType = createBasicInfoSelector('offering-type');

export const offeringSetupBasicInfoIssuerName = createBasicInfoSelector('issuer-name');

export const offeringSetupBasicInfoIssuerCIK = createBasicInfoSelector('issuer-cik');

export const offeringSetupBasicInfoSector = createBasicInfoSelector('sector');

export const offeringSetupBasicInfoBusinessDescription =
  createBasicInfoSelector('business-description');

export const offeringSetupBasicInfoSecurityType = createBasicInfoSelector('security-type');

export const offeringSetupBasicInfoShareClass = createBasicInfoSelector('share-class');

export const offeringSetupBasicInfoPricingCurrency = createBasicInfoSelector('pricing-currency');
