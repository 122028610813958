import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';

export type HighlightsTitleProps = Readonly<{
  value: Readonly<string> | Readonly<React.ReactNode>;
  items?: Readonly<React.ReactNode>;
  subtitle?: Readonly<React.ReactNode>;
}>;

export const HighlightsTitle: React.VFC<HighlightsTitleProps> = ({ value, items, subtitle }) => {
  return (
    <Stack direction="row" columnGap={3} rowGap={1} flexWrap="wrap">
      <Stack direction="row" gap={2} alignItems="center" flexWrap="wrap">
        <Typography variant="h2">{value}</Typography>
        {subtitle && (
          <Typography variant="h3" color="text.secondary" display="inline">
            {subtitle}
          </Typography>
        )}
      </Stack>
      <Stack direction="row" gap={1} alignItems="baseline" flexWrap="wrap">
        {items}
      </Stack>
    </Stack>
  );
};
