import { createTestId } from '../../../common/selectors';

const create = (name: string) => createTestId(`xc-offering-setup-sidenav-${name}`);

export const offeringSetupSideNavBasicInfo = create('basic-info');
export const offeringSetupSideNavCurrencies = create('currencies');
export const offeringSetupSideNavInstruments = create('instruments');
export const offeringSetupSideNavTranches = create('tranches');
export const offeringSetupSideNavManagers = create('managers');
export const offeringSetupSideNavManagerEconomics = create('manager-economics');
export const offeringSetupSideNavTiming = create('timing');
export const offeringSetupSideNavMarketing = create('marketing');
export const offeringSetupSideNavTerms = create('terms');
export const offeringSetupSideNavDisclaimers = create('disclaimers');
export const offeringSetupSideNavSummary = create('summary');
