import React from 'react';

import PerformancePercents from '../../../../../../common/components/format/performance-percents/PerformancePercents';
import { Pricing } from '../../../model/offering';
import ValueWithTooltip from '../../value-with-tooltip/ValueWithTooltip';
import {
  SDiscountSeparator,
  SDiscountWrapper,
} from '../WidgetPricingAndPerformanceFollowOn.styles';

export type Props = {
  pricing: Pricing;
  showInternational: boolean;
  getCurrencyRangeFormat: (
    valueLow: number | null,
    valueHigh: number | null
  ) => JSX.Element | string;
};

const ReOfferDiscount: React.FC<Props> = ({
  pricing,
  showInternational,
  getCurrencyRangeFormat,
}) => {
  const reOfferDiscountLow = showInternational
    ? pricing.reOfferDiscountLowLocalCurr
    : pricing.reOfferDiscountLow;
  const reOfferDiscountHigh = showInternational
    ? pricing.reOfferDiscountHighLocalCurr
    : pricing.reOfferDiscountHigh;

  return (
    <ValueWithTooltip
      value={
        <SDiscountWrapper>
          <PerformancePercents value={reOfferDiscountLow} nullValue="N/A" />
          <SDiscountSeparator>-</SDiscountSeparator>
          <PerformancePercents value={reOfferDiscountHigh} nullValue="N/A" />
        </SDiscountWrapper>
      }
      valueTooltip={
        showInternational
          ? getCurrencyRangeFormat(pricing.reOfferLowLocalCurr, pricing.reOfferHighLocalCurr)
          : getCurrencyRangeFormat(pricing.reOfferLow, pricing.reOfferHigh)
      }
      label="Re-Offer Discount"
      labelTooltip="Re-Offer Price"
    />
  );
};

export default ReOfferDiscount;
