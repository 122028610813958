import { createTestId } from '../../../common/selectors';

const create = (name: string) => createTestId(`xc-regulatory-filings-tn-${name}`);

export const tnFilingFormScreen = create('form-screen');
export const tnFilingDetailScreen = create('detail-screen');

export const tnRemoveSymbol = create('remove-symbol');
export const tnAddActivity = create('add-activity');
export const tnRemoveActivity = create('remove-activity');
export const tnAddTradeInfo = create('add-trade-info');
export const tnRemoveTradeInfo = create('remove-trade-info');
export const tnFilingFirmCRDInput = create('fim-crd-input');
