import { getCurrencySymbol } from '../../data/currencies';
import numeral from './locale-config';

const ONE_MILLION = 1000000;
const ONE_BILLION = 1000000000;

// using solely number as a value type breaks a lot of things
// non-typescript files also use these and could be passing non-number values
// TODO: undefined/optional props in other components will need fixing when this type becomes only number
// linking to typescript conversion epic: https://capitalmarketsgateway.atlassian.net/browse/ECM-2061
type Value = number | null | undefined | '';
type NumberOrNull = number | null;

/* helpers */
const round = (value: number, precision: number = 2) => {
  const multiplier = 10 ** precision;
  return Math.round(value * multiplier) / multiplier;
};

const isBillion = (value: number) => {
  return Math.abs(value) >= ONE_BILLION;
};

// custom type-guard in place for null checking through function
const isEmpty = (value: any): value is Value => {
  // returns value | null instead of boolean for typescript strict null checking
  return value === undefined || value === null || value === '';
};

function getDisplayValue(value: Value, formatFunc: () => string): string {
  if (typeof value === 'number') {
    return formatFunc();
  }

  return '-';
}

/**
 * Note: exported for testing only! Not used anywhere outside of this file
 * helper using 'numeral' to format a number value with options
 * @param value a number value to format
 * options:
 * @param prefix prepend string
 * @param suffix append string
 * @param precision number of decimal places to format to
 * @param scale change magnitude of number ultimately shifting decimal point
 */
export function formatNumericValue(
  value: Value,
  options: { prefix?: string; suffix?: string; precision?: number; scale?: number }
) {
  const defaultOptions = {
    prefix: '',
    suffix: '',
    precision: 2,
    scale: 0,
  };
  const { prefix, suffix, precision, scale } = { ...defaultOptions, ...options };

  // TODO: remove "value===''" and test all usages if it's compatible with our usage
  if (isEmpty(value)) {
    return '';
  }

  // avoiding float point issues, eg 1550000 * 0.000001 = 1.54999999...
  let scaledValue: number = value;
  let tempScale = scale;
  while (tempScale > 0) {
    scaledValue *= 10;
    tempScale -= 1;
  }
  while (tempScale < 0) {
    scaledValue /= 10;
    tempScale += 1;
  }

  const isNegative = value < 0;
  const roundedValue = round(isNegative ? -scaledValue : scaledValue, precision);
  const formattedValue = numeral(roundedValue).format(`0,0.${'0'.repeat(precision)}`);
  const affixedValue = `${prefix}${formattedValue}${suffix}`;
  return isNegative ? `(${affixedValue})` : affixedValue;
}

/* formatting functions */

/**
 * format a number with a given precision (# of decimal places)
 * great for adding thousands separators
 * @param value a number to format
 * @param precision number of decimal places (default=2)
 * @param scale number to scale value by shifting decimal point
 */
export const formatNumber = (value: Value, precision: number = 2, scale: number = 0) =>
  formatNumericValue(value, { precision, scale });

/**
 * if passed value is number, then formats a number with a given precision (# of decimal places),
 * otherwise returns default display value for number: '-'
 *
 * great for adding thousands separators
 * @param value a number to format
 * @param precision number of decimal places (default=2)
 * @param scale number to scale value by shifting decimal point
 * @param suffix append string
 */
export function getDisplayValueForNumber(
  value: Value,
  precision: number = 2,
  scale: number = 0,
  suffix: string = ''
): string {
  return getDisplayValue(value, () => formatNumericValue(value, { precision, scale, suffix }));
}

/**
 * format an integer
 * 123.123 -> 123
 * @param value a number to format as int
 */
export const formatInteger = (value: Value) => formatNumericValue(value, { precision: 0 });

/**
 * if passed value is number, then formats a number as an integer,
 * otherwise returns default display value for integer: '-'
 *
 * 123.123 -> 123
 * @param value a number to format as int
 */
export function getDisplayValueForInteger(value: Value): string {
  return getDisplayValue(value, () => formatInteger(value));
}

/**
 * format a percentage (adds %, set to 2 decimal places)
 * ex: 0.123 -> 12.3%
 * @param value number (0 to 1 = 0-100%) to format as a percent
 * @param precision number of decimal places
 * @param scale number to scale value by shifting decimal point
 */
export const formatPercents = (value: Value, precision: number = 2, scale: number = 2) =>
  formatNumericValue(value, { precision, suffix: '%', scale });

/**
 * If passed value is number, then formats a number a percentage
 * otherwise returns default display value for percentage: '-'
 * ex: 0.123 -> 12.3%
 *
 * @param value number (0 to 1 = 0-100%) to format as a percent
 * @param precision number of decimal places
 * @param scale number to scale value by shifting decimal point
 */
export const getDisplayValueForPercents = (
  value: Value,
  precision: number = 2,
  scale: number = 2
): string => {
  return getDisplayValue(value, () => formatPercents(value, precision, scale));
};

/**
 * format a number to currency ($ prefix, 2 decimal places)
 * ex: 425.125 -> $425.13
 * @param value a number to format as currency
 * @param precision number of decimal places
 * @param prefix prepend currency symbol
 */

export const formatCurrency = (value: Value, precision: number = 2, prefix = '$') =>
  formatNumericValue(value, { precision, prefix });

/**
 * if passed value is number, then it formats it to currency ($ prefix, 2 decimal places),
 * otherwise returns default display value for currency: '-'
 *
 * ex: 425.125  -> $425.13
 * ex: null     -> '-'
 *
 * @param value a number to format as currency
 * @param precision number of decimal places
 */
export function getDisplayValueForCurrency(
  value: Value,
  precision: number = 2,
  currencyCode: string = 'USD'
): string {
  const prefix = getCurrencySymbol(currencyCode);
  return getDisplayValue(value, () => formatCurrency(value, precision, prefix));
}

/**
 * format a millions-size number to currency, but scaled down + M suffix
 * ex: 2400123 -> $2.4M
 * @param value a number to format in millions
 * @param keepSmallValues when true reverts to using the regular formatCurrency()
 * @param prefix prepend currency symbol
 */

export const formatCurrencyInMillions = (
  value: Value,
  keepSmallValues: boolean = false,
  prefix = '$'
) => {
  if (isEmpty(value)) {
    return '';
  }

  if (keepSmallValues && round(value) < ONE_MILLION && round(value) > -ONE_MILLION) {
    return formatCurrency(value, undefined, prefix);
  }

  return formatNumericValue(value, { scale: -6, prefix, suffix: 'M', precision: 1 });
};

/**
 * format a billions-size number to currency, but scaled down + B suffix (fall back to millions)
 * ex:  1300000000 -> $1.3B
 * @param value a number to format in billions
 */

export const formatCurrencyInBillions = (value: Value, prefix = '$') => {
  if (isEmpty(value)) {
    return '';
  }

  if (round(value) < ONE_BILLION && round(value) > -ONE_BILLION) {
    return formatCurrencyInMillions(value, undefined, prefix);
  }
  return formatNumericValue(value, { scale: -9, prefix, suffix: 'B', precision: 1 });
};

/**
 * format a billions or millions-size number, but scaled down & B/M suffix
 * ex:  1300000000 -> 1.3B
 * @param value a number to format in billions or millions
 */
export function formatValueInBillions(value: Value) {
  if (isEmpty(value)) {
    return '';
  }

  const scale = isBillion(value) ? -9 : -6;
  const suffix = isBillion(value) ? 'B' : 'M';
  return formatNumericValue(value, { precision: 1, scale: scale, suffix: suffix });
}

/**
 * format a number with a single decimal place and an 'x'
 * 32.41 -> 32.4x
 * @param value a number to format in billions
 */
export const formatMultipleFactor = (value: Value) =>
  formatNumericValue(value, { precision: 1, suffix: 'x' });

/**
 * parse string or number to a valid number
 * useful for sanitizing from input element
 * removes duplicate decimals points, first occurance takes precedence
 * 32.413.1. -> 32.4131
 * @param value a string | number to parse
 */
export const parseNumber = (value: Value | string) => {
  if (isEmpty(value)) {
    return null;
  }

  const str = value.toString();

  // Index of first occurance of (.)
  const firstOccuranceIndex = str.search(/\./) + 1;

  // Splitting into two string and replacing all the dots (.'s) in the second string
  const resultStr =
    str.substring(0, firstOccuranceIndex) + str.slice(firstOccuranceIndex).replace(/\./g, '');

  return numeral(resultStr).value();
};

/**
 * Sums values passed as parameters.
 *
 * Values that are not representing 'numbers' are ignored. If all values,
 * passed in the list of parameters, are not 'numbers' than 'null' is returned.
 *
 * @param addends Values to be summed
 * @returns Sum of all the values passed in list of parameters.
 */
export const sum = (...addends: Value[]): NumberOrNull => {
  return addends.reduce<NumberOrNull>((currentResult, nextAddend) => {
    if (typeof nextAddend === 'number') {
      return currentResult === null ? nextAddend : nextAddend + currentResult;
    }

    return currentResult;
  }, null);
};

/**
 * Multiplies values passed as parameters.
 *
 * If there is any multiplicand that is not a number the method returns 'null'.
 *
 * @param multiplicands Values to be multiplied
 * @returns Product of all values passed in list of parameters.
 */
export const multiply = (...multiplicands: Value[]): NumberOrNull => {
  if (multiplicands.length === 0) {
    return null;
  }
  if (multiplicands.some(isEmpty)) {
    return null;
  }

  return multiplicands.reduce<number>((currentResult, nextMultiplicand) => {
    return currentResult * (nextMultiplicand as number);
  }, 1);
};

/**
 * Divides value by different value
 *
 * If either dividend or divisor is null returns 'null'. If divisor is 0, returns 'null'
 *
 * @param dividend Value to be divided
 * @param divisor Value by which we divide
 * @returns Value of division
 */
export const divide = (dividend: Value, divisor: Value): NumberOrNull => {
  if (isEmpty(dividend) || isEmpty(divisor)) {
    return null;
  }

  if (divisor === 0) {
    return null;
  }

  return dividend / divisor;
};

/**
 * Negates value passed as parameter
 *
 * Parameter that does not represent 'number' or zero is returned without any change otherwise
 * negative value of the number is returned.
 *
 * @param value Value to be negated
 * @returns Negative value of passed parameter
 */
export function negate<TValue extends Value>(value: TValue): TValue;
export function negate(value: Value): Value {
  if (typeof value === 'number' && value !== 0) {
    return -1 * value;
  }

  return value;
}
