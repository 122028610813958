import { styled } from '@mui/material/styles';
import type { TableCellProps } from '@mui/material/TableCell';
import TableCell from '@mui/material/TableCell';
import type { FC } from 'react';
import React from 'react';

const StyledTableCell = styled(TableCell, {
  name: 'TableGroupHeaderCell',
  slot: 'Root',
  overridesResolver: (_props, styles) => [styles.root],
})({});

export type TableGroupHeaderCellProps = TableCellProps;

export const TableGroupHeaderCell: FC<TableGroupHeaderCellProps> = ({ children, ...restProps }) => {
  return <StyledTableCell {...restProps}>{children}</StyledTableCell>;
};
