import 'react-s-alert/dist/s-alert-default.css';

import React from 'react';
import ReactSAlert from 'react-s-alert';
import styled from 'styled-components/macro';

import DefaultToastContentTemplate from './templates/DefaultToastContentTemplate';
import { ToastMessage } from './types';

type ToastConfigurationOptions = { timeout: number | 'none' };
type ToastContent = ToastMessage | Array<ToastMessage>;

enum defaultOptions {
  timeout = 3000,
}

/**
 * Cannot wrap ReactSAlert directly because it does not take a style prop. Instead, wrap and target a class.
 */
const SAlertWrapper = styled.div`
  line-height: 0;
  .s-alert-box {
    z-index: ${({ theme }) => `${theme.zIndex.toast}`};
  }
`;

/**
 * A toast component and notification triggering interface.
 * @deprecated use design-system [SnackbarManager](../../../../../design-system/src/utils/SnackbarManager.ts)
 */
class ToastManager extends React.Component {
  /**
   * Triggers the display of an error alert.
   */
  static error(content: ToastContent, options: ToastConfigurationOptions = defaultOptions): void {
    ReactSAlert.error(
      <DefaultToastContentTemplate content={Array.isArray(content) ? content : [content]} />,
      options
    );
  }

  /**
   * Triggers the display of an info alert.
   */
  static info(content: ToastContent, options: ToastConfigurationOptions = defaultOptions): void {
    ReactSAlert.info(
      <DefaultToastContentTemplate content={Array.isArray(content) ? content : [content]} />,
      options
    );
  }

  /**
   * Triggers the display of a success alert.
   */
  static success(content: ToastContent, options: ToastConfigurationOptions = defaultOptions): void {
    ReactSAlert.success(
      <DefaultToastContentTemplate content={Array.isArray(content) ? content : [content]} />,
      options
    );
  }

  /**
   * Closes all alerts
   */
  static closeAll(): void {
    ReactSAlert.closeAll();
  }

  render() {
    return (
      <SAlertWrapper>
        <ReactSAlert position="top-right" stack={{ spacing: 10 }} />
      </SAlertWrapper>
    );
  }
}

export default ToastManager;
