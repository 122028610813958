import { datalabApi } from '@cmg/api';
import { numericUtil } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import { PricingType, UnitType } from '../../../types/domain/offering/constants';

function formatIoiValue(ioi: datalabApi.Ioi) {
  switch (ioi.unitType) {
    case UnitType.SHARES:
      return numericUtil.formatInteger(ioi.shares);
    case UnitType.DOLLARS:
      return numericUtil.formatCurrencyInMillions(ioi.dollars, true);
    case UnitType.PERCENTAGE:
      return numericUtil.formatPercents(ioi.percentage);
    default:
      return null;
  }
}

function getPricing(ioi: datalabApi.Ioi) {
  if (ioi.pricingType === PricingType.LIMIT) {
    return numericUtil.formatCurrency(ioi.limitPrice);
  }

  return ioi.pricingType;
}

export const SIoiSummary = styled.table`
  text-align: justify;
  text-transform: capitalize;
`;

export type Props = {
  iois?: datalabApi.Ioi[];
};

const IoiSummary: React.FC<Props> = ({ iois }) => (
  <SIoiSummary>
    <tbody>
      {iois &&
        iois.map((ioi, index) => (
          <tr key={index}>
            <td style={{ textAlign: 'right' }}>{formatIoiValue(ioi)}</td>
            <td>&nbsp;@ {getPricing(ioi)}</td>
          </tr>
        ))}
    </tbody>
  </SIoiSummary>
);

export default IoiSummary;
