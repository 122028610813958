import queryString, { ParsedQuery, ParseOptions, StringifyOptions } from 'query-string';

export function queryParse<TQuery extends ParsedQuery = ParsedQuery>(
  query: string,
  options: ParseOptions = {}
): TQuery {
  return queryString.parse(query, { arrayFormat: 'comma', ...options }) as TQuery;
}

export const queryStringify = (
  obj: { [key: string]: any },
  options: StringifyOptions = {}
): string => {
  // The dotnet REST api doesn't support `comma` for arrayFormat. It needs `none`. Is it set to this because of the ruby API?
  const queryStringified = queryString.stringify(obj, {
    arrayFormat: 'comma',
    ...options,
  });
  return queryStringified ? `?${queryStringified}` : '';
};

/**
 * Extracts the account subdomain of the current url the user is on.
 * e.g. jpmorgan would return for both jpmorgan.id.alpha.oncmg.io and jpmorgan.local.oncmg.io:5001
 */
export const getAccountSubdomain = (location: Location | URL) => {
  const [accountSubdomain] = location.hostname.split('.');
  return accountSubdomain;
};

/**
 * Given a valid url string and a subdomain, the subdomain will be prepended to the url and
 * returned as a string.
 * @param urlString a valid url string
 * @param subdomain a single subdomain
 * @returns The url string with the subdomain prepended
 */
export const addSubdomainToUrl = (urlString: string, subdomain: string): string => {
  const url = new URL(urlString);
  url.host = `${subdomain}.${url.host}`;
  return url.toString();
};

/**
 * Returns true when the given hostname ends with local.oncmg.io
 * @param hostname
 */
export const isLocalhost = (hostname: string) => {
  return hostname.endsWith('local.oncmg.io');
};
