import { Box, numericUtil, Table, timeUtil } from '@cmg/common';
import React from 'react';

import { formatNullableBoolean } from '../../../../../common/helpers/helpers';
import { ISODate, UUID } from '../../../../../types/common';

export type Props = {
  terms: {
    sellingConcessionPercentage?: number | null;
    grossSpreadPercentage?: number | null;
    grossSpread?: number | null;
    sellingConcession?: number | null;
    managementFeePercentage?: number | null;
    managementFee?: number | null;
    underwritingFeePercentage?: number | null;
    underwritingFee?: number | null;
    // TODO: create/use dedicated type
    stabilizationAgent?: {
      firmName: string;
      firmId: UUID;
    };
    // TODO: create/use dedicated type
    settlementAgent?: {
      firmName: string;
      firmId: UUID;
    };
    // TODO: create/use dedicated type
    leftLead?: {
      firmName: string;
      firmId: UUID;
    };
    lockUpPeriod?: number | null;
    lockUpPeriodExpiration?: ISODate | null;
    lockUpEarlyRelease?: ISODate | null;
    conditionalLockUp?: boolean | null;
    multipleLockUps?: boolean | null;
  };
};

const WidgetUnderwritingTerms: React.FC<Props> = ({ terms }) => {
  return (
    <Box>
      <div>
        <Box.Header title="Underwriting terms" />
        <Box.Content>
          <Table striped reversed>
            <tbody>
              <tr>
                <td style={{ paddingLeft: 40 }}>Gross Spread</td>
                <td>{numericUtil.formatPercents(terms.grossSpreadPercentage)}</td>
                <td>
                  {numericUtil.formatCurrency(terms.grossSpread)}
                  /share
                </td>
              </tr>

              <tr>
                <td style={{ paddingLeft: 40 }}>Selling Concession</td>
                <td>{numericUtil.formatPercents(terms.sellingConcessionPercentage)}</td>
                <td>
                  {numericUtil.formatCurrency(terms.sellingConcession)}
                  /share
                </td>
              </tr>
              <tr>
                <td style={{ paddingLeft: 40 }}>Management Fee</td>
                <td>{numericUtil.formatPercents(terms.managementFeePercentage)}</td>
                <td>
                  {numericUtil.formatCurrency(terms.managementFee)}
                  /share
                </td>
              </tr>
              <tr>
                <td style={{ paddingLeft: 40 }}>Underwriting Fee</td>
                <td>{numericUtil.formatPercents(terms.underwritingFeePercentage)}</td>
                <td>
                  {numericUtil.formatCurrency(terms.underwritingFee)}
                  /share
                </td>
              </tr>

              <tr>
                <td style={{ color: 'transparent' }}>&nbsp;</td>
                <td style={{ color: 'transparent' }}>&nbsp;</td>
                <td style={{ color: 'transparent' }}>&nbsp;</td>
              </tr>

              <tr>
                <td>Stabilization Agent</td>
                <td />
                <td>{terms.stabilizationAgent && terms.stabilizationAgent.firmName}</td>
              </tr>
              <tr>
                <td>Settlement Agent</td>
                <td />
                <td>{terms.settlementAgent && terms.settlementAgent.firmName}</td>
              </tr>
              <tr>
                <td>Left Lead</td>
                <td />
                <td>{terms.leftLead && terms.leftLead.firmName}</td>
              </tr>

              <tr>
                <td>Lock-up Period</td>
                <td />
                <td>{terms.lockUpPeriod && `${terms.lockUpPeriod} Days`}</td>
              </tr>
              <tr>
                <td>Lock-up Expiration</td>
                <td />
                <td>
                  {terms.lockUpPeriodExpiration &&
                    timeUtil.formatAsDisplayDate(terms.lockUpPeriodExpiration)}
                </td>
              </tr>
              <tr>
                <td>Conditional Lock-up</td>
                <td />
                <td>{formatNullableBoolean(terms.conditionalLockUp)}</td>
              </tr>
              <tr>
                <td>Multiple Lock-ups</td>
                <td />
                <td>{formatNullableBoolean(terms.multipleLockUps)}</td>
              </tr>
              <tr>
                <td>Lock-up Early Release</td>
                <td />
                <td>
                  {terms.lockUpEarlyRelease &&
                    timeUtil.formatAsDisplayDate(terms.lockUpEarlyRelease)}
                </td>
              </tr>
            </tbody>
          </Table>
        </Box.Content>
      </div>
    </Box>
  );
};

export default WidgetUnderwritingTerms;
