export enum TimingStates {
  CONFIDENTIAL_FILING_DATE = 'CONFIDENTIAL_FILING_DATE',
  PUBLIC_FILING_DATE = 'PUBLIC_FILING_DATE',
  PRICING_DATE = 'PRICING_DATE',
  EXPECTED_PRICING_DATE = 'EXPECTED_PRICING_DATE',
  PROSPECTUS_DATE = 'PROSPECTUS_DATE',
  POSTPONED_DATE = 'POSTPONED_DATE',
  WITHDRAWN_DATE = 'WITHDRAWN_DATE',
  TERMINATION_DATE = 'TERMINATION_DATE',
  FIRST_TRADE_DATE = 'FIRST_TRADE_DATE',
  EXPECTED_FIRST_TRADE_DATE = 'EXPECTED_FIRST_TRADE_DATE',
  SETTLEMENT_DATE = 'SETTLEMENT_DATE',
  EXPECTED_SETTLEMENT_DATE = 'EXPECTED_SETTLEMENT_DATE',
  POSTPONE_DATE = 'POSTPONE_DATE',
  WITHDRAWAL_DATE = 'WITHDRAWAL_DATE',
  LAUNCH_DATE = 'LAUNCH_DATE',
}

export const timingStateLabels = {
  [TimingStates.CONFIDENTIAL_FILING_DATE]: 'Confidential Filing',
  [TimingStates.PUBLIC_FILING_DATE]: 'Public Filing',
  [TimingStates.PRICING_DATE]: 'Pricing',
  [TimingStates.EXPECTED_PRICING_DATE]: 'Expected Pricing',
  [TimingStates.PROSPECTUS_DATE]: 'Prospectus',
  [TimingStates.POSTPONED_DATE]: 'Postponed',
  [TimingStates.WITHDRAWN_DATE]: 'Withdrawn',
  [TimingStates.TERMINATION_DATE]: 'Termination',
  [TimingStates.FIRST_TRADE_DATE]: 'First Trade',
  [TimingStates.EXPECTED_FIRST_TRADE_DATE]: 'Expected First Trade',
  [TimingStates.SETTLEMENT_DATE]: 'Settlement',
  [TimingStates.EXPECTED_SETTLEMENT_DATE]: 'Expected Settlement',
  [TimingStates.POSTPONE_DATE]: 'Postpone',
  [TimingStates.WITHDRAWAL_DATE]: 'Withdrawal',
  [TimingStates.LAUNCH_DATE]: 'Launch',
};
