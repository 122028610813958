import flatten from 'lodash/flatten';
import memoize from 'memoize-one';

import {
  AdvancedFilterFieldConfig,
  booleanOperatorsConfig,
  currencyOperatorsConfig,
  dateOperatorsConfig,
  enumOperatorsConfig,
  integerOperatorsConfig,
  nullableBooleanOperatorsConfig,
  numericOperatorsConfig,
  operatorEnum,
  percentOperatorsConfig,
  stringOperatorsConfig,
} from '../../../common/components/form/advanced-filter/advanced-filter.model';
import { getFeatureToggles } from '../../../config/appSettings';
import { ipoRangeRevisionTypeLabels } from '../../../types/domain/offering/constants';
import { sponsorTypeLabels } from '../../shared/constants/constants';
import { createOptions } from '../../shared/model/utils';
import UnderwriterControl from '../components/advanced-filter/UnderwriterControl';
import UnderwriterRoleControl from '../components/advanced-filter/UnderwriterRoleControl';
import { DatalabTableColumnCategory, DatalabTableColumnGroup } from '../constants';
import { DatalabTableColumn, dtc, FilterTypes, masterColumns } from './datalabTableColumns';
import { localCurrencyColumnsFilter, overrideIntlProps } from './table.model';
import { setColumnsCategory } from './utils';

export type CategorizedDatalabTableColumn = DatalabTableColumn & { category: string };

export type ColumnCategoryConfig = {
  [key in DatalabTableColumnCategory]?: CategorizedDatalabTableColumn[];
};

export type GroupedDatalabTableColumn = DatalabTableColumn & { group: string };

export type ColumnGroupConfig = {
  [key in DatalabTableColumnGroup]?: GroupedDatalabTableColumn[];
};
/**
 * Get operators config based on column filter type.
 * Default values is numeric operators config
 *
 * @param column - table column config
 */
export const getOperatorConfigByTypeInField = (column: DatalabTableColumn) => {
  switch (column.filterType) {
    case FilterTypes.STRING:
      return stringOperatorsConfig;
    case FilterTypes.DATE:
      return dateOperatorsConfig;
    case FilterTypes.PERCENT:
      return percentOperatorsConfig;
    case FilterTypes.CURRENCY:
      return currencyOperatorsConfig;
    case FilterTypes.INTEGER:
      return integerOperatorsConfig;
    case FilterTypes.ENUM:
      return enumOperatorsConfig(column.filterEnum);
    case FilterTypes.BOOLEAN:
      return booleanOperatorsConfig;
    case FilterTypes.NULLABLE_BOOLEAN:
      return nullableBooleanOperatorsConfig;
    default:
      return numericOperatorsConfig;
  }
};

/**
 * Convert table column config to advanced filter field config
 *
 * @param column - table column configuration
 */
export const getFilterFieldConfig = (
  column: CategorizedDatalabTableColumn
): AdvancedFilterFieldConfig => {
  return {
    label: column.label,
    name: column.field,
    category: column.category,
    operatorsConfig: column.operatorsConfig
      ? column.operatorsConfig
      : getOperatorConfigByTypeInField(column),
  };
};

/**
 * List of fields for advanced filters
 */
export const underwriterDatalabTableColumns: { [key: string]: DatalabTableColumn } = {
  underwriter_and_role: {
    field: 'underwriter_and_role',
    label: 'Underwriter & Role',
    operatorsConfig: [{ name: operatorEnum.IN, renderer: UnderwriterRoleControl, label: 'Equals' }],
  },
  managers_underwriter_id: {
    field: 'managers_underwriter_id',
    label: 'Underwriter',
    operatorsConfig: [
      { name: operatorEnum.IN, renderer: UnderwriterControl, label: 'Is one of' },
      { name: operatorEnum.NOT_IN, renderer: UnderwriterControl, label: 'Is not one of' },
    ],
  },
};

/**
 * List of fields for advanced filters
 */
export const offeringTermsExtraFilters: { [key: string]: DatalabTableColumn } = {
  ipo_range_revision_type: {
    field: 'ipo_range_revision_type',
    label: 'IPO Range Revision Type',
    filterType: FilterTypes.ENUM,
    filterEnum: createOptions(ipoRangeRevisionTypeLabels),
  },
};

export const ownershipExtraFilters: { [key: string]: DatalabTableColumn } = {
  sponsors_sponsor_type: {
    field: 'sponsors_sponsor_type',
    label: 'Sponsor Type',
    filterType: FilterTypes.ENUM,
    filterEnum: createOptions(sponsorTypeLabels),
  },
};

/*
  List of fields for advanced filters
*/
const extraFilters: DatalabTableColumn[] = [
  dtc.filing_details_size_in_dollars,
  ...Object.values(underwriterDatalabTableColumns),
  ...Object.values(offeringTermsExtraFilters),
  ...Object.values(ownershipExtraFilters),
];

const excludedFieldNames: string[] = [
  dtc.left_lead,
  dtc.left_lead_firm_name,
  dtc.offerings_type,
  dtc.offerings_sector,
  dtc.companies_sub_sector,
  dtc.offering_use_of_proceeds,
].map(definition => definition.field);

/**
 * Fields that should be available in Advanced filters
 */
export const canFilterFields: DatalabTableColumn[] = [...masterColumns, ...extraFilters].filter(
  col => !excludedFieldNames.includes(col.field)
);

export const columnCategoryConfig: ColumnCategoryConfig = {
  [DatalabTableColumnCategory.ISSUER]: setColumnsCategory(
    [
      dtc.companies_cik,
      dtc.companies_cusip,
      dtc.issuer_isin,
      dtc.companies_issuer,
      dtc.offering_entity_structure,
      dtc.companies_ticker,
      dtc.exchange_mic,
      dtc.pricing_currency_code,
      ...(getFeatureToggles().isNewOfferingFieldsOn
        ? [dtc.dual_listed, dtc.egc, dtc.headquarters, dtc.naics]
        : []),
    ],
    DatalabTableColumnCategory.ISSUER
  ),
  [DatalabTableColumnCategory.OFFERING_TERMS]: setColumnsCategory(
    [
      dtc.offering_pct_change_in_size,
      dtc.filing_details_deal_pct_at_pricing_market_cap,
      dtc.filing_details_deal_pct_market_cap_pre_offering,
      dtc.filing_details_pct_secondary_shares,
      dtc.offering_price_vs_midpoint,
      dtc.offering_initial_ipo_range_low_local_curr,
      dtc.offering_initial_ipo_range_low,
      dtc.offering_initial_registration_value,
      dtc.filing_details_net_price,
      dtc.filing_details_offering_price_local_curr,
      dtc.filing_details_offering_price,
      dtc.filing_details_size_in_dollars,
      dtc.offering_over_allotment_authorized,
      dtc.offering_over_allotment_exercised,
      dtc.filing_details_market_cap_at_pricing_local_curr,
      dtc.filing_details_market_cap_at_pricing,
      dtc.offering_post_offering_shares,
      dtc.filing_details_market_cap_pre_offering,
      dtc.offering_pre_offering_shares,
      dtc.filing_details_price_vs_range,
      dtc.offering_primary_shares_base_offering,
      dtc.offering_secondary_shares_base_offering,
      dtc.offering_security_type,
      dtc.filing_details_gross_proceeds_local_curr,
      dtc.filing_details_gross_proceeds,
      dtc.filing_details_gross_proceeds_with_over_allotment_local_curr,
      dtc.filing_details_gross_proceeds_with_over_allotment,
      ...(getFeatureToggles().isNewOfferingFieldsOn
        ? [
            dtc.initial_gross_proceeds_base,
            dtc.initial_gross_proceeds_base_local_curr,
            dtc.revised_gross_proceeds_base,
            dtc.revised_gross_proceeds_base_local_curr,
          ]
        : []),
      dtc.offering_size,
      dtc.offerings_pre_offering_adtv,
      dtc.offerings_size_as_multiple_of_adtv,
      dtc.offering_forward_agreement,
      dtc.total_shares_filed_excl_shoe,
      dtc.first_follow_on,
      offeringTermsExtraFilters.ipo_range_revision_type,
      ...(getFeatureToggles().isNewOfferingFieldsOn
        ? [
            dtc.concurrent_offering,
            dtc.synthetic_secondary,
            dtc.up_listing,
            dtc.use_of_proceeds_note,
            dtc.company_repurchase_included,
            dtc.company_repurchase_additional,
          ]
        : []),
    ],
    DatalabTableColumnCategory.OFFERING_TERMS
  ),

  [DatalabTableColumnCategory.OWNERSHIP]: setColumnsCategory(
    [
      dtc.offering_pct_post_offering_ownership,
      dtc.offering_post_offering_ownership,
      dtc.offering_pct_pre_offering_ownership,
      dtc.offering_pre_offering_ownership,
      dtc.primary_shareholder_name,
      ownershipExtraFilters.sponsors_sponsor_type,
      ...(getFeatureToggles().isNewOfferingFieldsOn
        ? [dtc.carve_out, dtc.clean_up_trade, dtc.cornerstone_investors]
        : []),
      ...(getFeatureToggles().isCornerstoneInvestorInDLOn
        ? [dtc.number_of_cornerstone_investors, dtc.cornerstone_investment]
        : []),
    ],
    DatalabTableColumnCategory.OWNERSHIP
  ),
  [DatalabTableColumnCategory.PERFORMANCE]: setColumnsCategory(
    [
      dtc.offering_offer_to_1day,
      dtc.offering_offer_to_vwap_1day,
      dtc.offering_offer_to_one_year,
      dtc.offering_offer_to_14day,
      dtc.offering_offer_to_180day,
      dtc.offering_offer_to_3day,
      dtc.offering_offer_to_30day,
      dtc.offering_offer_to_7day,
      dtc.offering_offer_to_90day,
      dtc.offering_offer_to_current,
      dtc.offering_first_day_turnover,
      dtc.offering_first_day_volume,
      dtc.offering_offer_to_open,
      dtc.offering_offer_to_prior_quarter,
    ],
    DatalabTableColumnCategory.PERFORMANCE
  ),
  [DatalabTableColumnCategory.PRICING_DISCOUNT]: setColumnsCategory(
    [
      dtc.fifty_two_wk_high,
      dtc.offerings_file_to_offer_discount,
      dtc.last_trade_before_filing_local_curr,
      dtc.last_trade_before_filing,
      dtc.last_trade_before_offer_local_curr,
      dtc.last_trade_before_offer,
      dtc.re_offer_high_local_curr,
      dtc.re_offer_high,
      dtc.re_offer_discount_high,
      dtc.re_offer_low_local_curr,
      dtc.re_offer_low,
      dtc.re_offer_discount_low,
      dtc.offerings_discount_to_52wk_high,
      dtc.offerings_discount_to_last_trade,
      dtc.offering_discount_to_vwap,
      dtc.offerings_all_in_cost,
    ],
    DatalabTableColumnCategory.PRICING_DISCOUNT
  ),

  [DatalabTableColumnCategory.TIMING]: setColumnsCategory(
    [
      dtc.lock_up_expiration,
      dtc.lock_up_early_release_date,
      dtc.lock_up_period,
      dtc.conditional_lock_up,
      dtc.multiple_lock_ups,
      dtc.offering_confidential_filing_date,
      dtc.offering_public_filing_date,
      dtc.offering_launch_date,
      dtc.timing_of_launch,
      dtc.offerings_pricing_date,
      dtc.offering_first_trade_date,
      dtc.offering_settlement_date,
    ],
    DatalabTableColumnCategory.TIMING
  ),

  [DatalabTableColumnCategory.UNDERWRITING]: setColumnsCategory(
    [
      dtc.offering_total_bookrunners,
      dtc.offering_total_managers,
      dtc.offering_total_non_bookrunners,
      dtc.offering_total_pct_to_bookrunners,
      dtc.offering_total_pct_to_left_lead,
      dtc.offering_total_pct_to_non_bookrunners,
      dtc.estimated_fee,
      dtc.offering_gross_spread_pct,
      dtc.offering_gross_spread,
      underwriterDatalabTableColumns.managers_underwriter_id,
      underwriterDatalabTableColumns.underwriter_and_role,
    ],
    DatalabTableColumnCategory.UNDERWRITING
  ),
};
/**
 * Create configuration for advanced filter.
 *
 * @param tableColumns - list of available screen table columns
 */
export const getAdvancedFiltersConfig: (
  showInternational?: boolean
) => AdvancedFilterFieldConfig[] = memoize(
  (showInternational = false): AdvancedFilterFieldConfig[] => {
    const filterFields = showInternational
      ? canFilterFields
      : canFilterFields.filter(localCurrencyColumnsFilter);
    const tableColumnFields = filterFields.map(({ field }) => field);
    const flattenColumnCatConfig = flatten(
      Object.values(columnCategoryConfig).map(columns => columns)
    );
    const categorizedColumns = showInternational
      ? (flattenColumnCatConfig.map(overrideIntlProps) as CategorizedDatalabTableColumn[])
      : flattenColumnCatConfig;
    return categorizedColumns
      .filter(column => column && tableColumnFields.includes(column.field))
      .map(getFilterFieldConfig);
  }
);
