import { DangerButton, PrimaryButton, SecondaryButton } from '@cmg/common';
import styled, { css } from 'styled-components/macro';

export const primaryStyles = css`
  color: ${({ theme }) => `${theme.color.white} !important`};
  padding: 5px 10px;
  margin: 0 5px;
  border: none;
`;

export const SButton = styled(SecondaryButton)`
  border: none;
  cursor: pointer;
  padding: 2px;
  font-size: 12px;
  &:not(:hover):not(:active) {
    color: ${({ theme }) => theme.text.color.light};
  }
  &:hover {
    background: none;
    color: ${({ theme }) => theme.text.color.link};
  }
`;

export const SectorTrashIconButton = styled(SButton)`
  &:hover {
    color: ${({ theme }) => theme.color.red1};
  }
`;

export const SectorDeleteButton = styled(DangerButton)`
  ${primaryStyles}
`;
export const SectorPrimaryButton = styled(PrimaryButton)`
  ${primaryStyles}
`;
