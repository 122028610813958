import React from 'react';

import Checkbox from '../../../../form/checkbox/Checkbox';
import Popover, { Props as PopoverProps } from '../../../../overlays/popover/Popover';
import { CellRendererProps } from '../BaseAgGrid.model';

export type Props = CellRendererProps<boolean> & {
  disabled?: boolean;
  popoverProps?: PopoverProps;
};

/**
 * Ag Grid Framework Renderer for a checkbox row selector.
 * It has the behavior to handle row selection and supports multiple and single selection.
 */
export const RowSelectorRenderer = React.forwardRef<unknown, Props>((props, ref) => {
  const { api, node, column, disabled, popoverProps = { disabled: true } } = props;
  const [checked, setChecked] = React.useState<boolean | undefined>(node.isSelected());

  /* Component Editor Lifecycle methods */
  React.useImperativeHandle<unknown, {}>(
    ref,
    () => {
      return {
        refresh() {
          if (node.isRowPinned()) {
            setChecked(api.getSelectedNodes().length === api.getRenderedNodes().length);
          } else {
            setChecked(node.isSelected());
          }
        },
      };
    },
    [api, node]
  );

  const onChange = React.useCallback(
    (value: boolean | undefined) => {
      if (node.isRowPinned()) {
        if (value === true) {
          api.selectAll();
        } else {
          api.deselectAll();
        }
        api.refreshCells({ force: true, columns: [column] });
      } else {
        node.setSelected(value ?? false);
        api.refreshCells({ force: true, columns: [column] });
      }
      setChecked(value);
    },
    [api, column, node]
  );

  return (
    <Popover {...popoverProps}>
      <span>
        <Checkbox onChange={onChange} value={checked} disabled={disabled} showDisabledIcon />
      </span>
    </Popover>
  );
});

export default RowSelectorRenderer;
