import { Box, numericUtil, Table } from '@cmg/common';
import React from 'react';

import { filingTypeLabels } from '../../../../../types/domain/offering/constants';
import { Filing, Pricing } from '../../model/offering';
import { getFormattedPriceColumnValue, getFormattedSizeColumnValue } from './WidgetPriced.model';

export type Props = {
  filings: Filing[];
  pricing?: Pricing;
  isFollowOn?: boolean;
  pricingCurrencyCode?: string;
  showInternational: boolean;
};

const WidgetPriced: React.FC<Props> = ({
  filings,
  isFollowOn = false,
  pricing,
  pricingCurrencyCode,
  showInternational,
}) => {
  return (
    <Box>
      <div>
        <Box.Header title="Priced" />
        <Box.Content>
          <Table responsive striped>
            <thead>
              <tr>
                <th>Status</th>
                <Table.RightHeader>Size</Table.RightHeader>
                <Table.RightHeader>Price</Table.RightHeader>
                <Table.RightHeader>Size (Shares)</Table.RightHeader>
                <Table.RightHeader>Primary</Table.RightHeader>
                <Table.RightHeader>Secondary</Table.RightHeader>
                <Table.RightHeader>Over-Allotment Auth</Table.RightHeader>
                <Table.RightHeader>Over-Allotment Exercised</Table.RightHeader>
              </tr>
            </thead>
            <tbody>
              {filings.map((row, idx) => (
                <tr key={idx}>
                  <td>{filingTypeLabels[row.filingType]}</td>
                  <Table.RightCell>
                    {getFormattedSizeColumnValue({ row, pricingCurrencyCode, showInternational })}
                  </Table.RightCell>
                  <Table.RightCell>
                    {getFormattedPriceColumnValue({
                      isFollowOn,
                      filing: row,
                      pricing,
                      pricingCurrencyCode,
                      showInternational,
                    })}
                  </Table.RightCell>
                  <Table.RightCell>{numericUtil.formatInteger(row.sizeInShares)}</Table.RightCell>
                  <Table.RightCell>
                    {numericUtil.formatInteger(row.primarySharesBaseOffering)}
                  </Table.RightCell>
                  <Table.RightCell>
                    {numericUtil.formatInteger(row.secondarySharesBaseOffering)}
                  </Table.RightCell>
                  <Table.RightCell>
                    {numericUtil.formatInteger(row.totalSharesOverAllotmentAuthorized)}
                  </Table.RightCell>
                  <Table.RightCell>
                    {numericUtil.formatInteger(row.totalSharesOverAllotmentExercised)}
                  </Table.RightCell>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box.Content>
      </div>
    </Box>
  );
};

export default WidgetPriced;
