import { createTestId } from '../../../../common/selectors';

export const accountRoleDetailScreenSelector = createTestId('account-role-detail-screen');
export const accountRoleDetailNavbarSelector = createTestId('account-role-detail-navbar');
export const accountRoleDetailPermissionsLinkSelector = createTestId(
  'account-role-detail-permissions-nav-link'
);
export const accountRoleDetailUsersLinkSelector = createTestId(
  'account-role-detail-users-nav-link'
);
