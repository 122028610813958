import { Option } from '../../components/form/select/types';
import { ISODate } from '../../types/common';
import { formatAsTimeZoneAbbreviation } from './utils';

export const timeZones = [
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',
  'America/Anchorage',
  'Pacific/Honolulu',
];

/**
 * Maps list of supported time zones to select options using abbreviation format for corresponding date as label.
 */
export const getTimeZoneOptions = (date: ISODate) => {
  return timeZones.map<Option>(timeZone => ({
    label: formatAsTimeZoneAbbreviation(date, timeZone) || timeZone,
    value: timeZone,
  }));
};

const timeZonesMap = {
  'America/New_York': 'EST/EDT',
  'America/Chicago': 'CST/CDT',
  'America/Denver': 'MST/MDT',
  'America/Los_Angeles': 'PST/PDT',
  'America/Anchorage': 'AKST/AKDT',
  'Pacific/Honolulu': 'HST/HDT',
};

/**
 * Return date-independent list of time zones options
 */
export const getDefaultTimeZoneOptions = () => {
  return timeZones.map<Option>(timeZone => ({
    label: timeZonesMap[timeZone],
    value: timeZone,
  }));
};

export const timeZoneLabels = {
  EST: 'Eastern time zone (UTC-5:00)',
  EDT: 'Eastern time zone (UTC-4:00)',
  CST: 'Central time zone (UTC-6:00)',
  CDT: 'Central time zone (UTC-5:00)',
  MST: 'Mountain time zone (UTC-7:00)',
  MDT: 'Mountain time zone (UTC-6:00)',
  PST: 'Pacific time zone (UTC-8:00)',
  PDT: 'Pacific time zone (UTC-7:00)',
  AKST: 'Alaska time zone (UTC-9:00)',
  AKDT: 'Alaska time zone (UTC-8:00)',
  HST: 'Hawaii time zone (UTC-10:00)',
  HDT: 'Hawaii time zone (UTC-9:00)',
};
