import { RowNode } from 'ag-grid-community';
import React from 'react';
import styled from 'styled-components/macro';

import Disclaimer from '../../../indicators/disclaimer/Disclaimer';
import { GroupRow } from '../hooks/useGrouping';

export const SFullWidthRow = styled.div`
  margin-left: 20px;
`;

export type Props = {
  node: RowNode;
  context?: {
    footerStyles?: React.CSSProperties;
    disclaimerStyles?: React.CSSProperties;
  };
};

/**
 * FullWidthCellRenderer renders a full width row.
 * used to render group header rows in combination with the useDataGridGrouping hook
 *
 * FullWidthCellRenderer only renders when isFullWidthCell in gridOptions is true
 */
class FullWidthCellRenderer extends React.Component<Props> {
  getReactContainerStyle() {
    const isFooter = this.props.node.footer;
    const baseStyles = {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    };

    if (isFooter) {
      return {
        ...baseStyles,
        ...(this.props.context?.footerStyles ?? {}),
      };
    }

    if (this.props.node.data) {
      return {
        ...baseStyles,
        ...this.props.node.data.groupHeaderStyles,
      };
    }
    return baseStyles;
  }

  render() {
    const groupHeaderRow: GroupRow = this.props.node.data;
    const isFooter = this.props.node.footer;
    if (isFooter) {
      return <Disclaimer style={this.props.context?.disclaimerStyles ?? {}} />;
    } else {
      return <SFullWidthRow>{groupHeaderRow.renderGroupHeaderRow()}</SFullWidthRow>;
    }
  }
}

export default FullWidthCellRenderer;
