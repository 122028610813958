import { Collapse, Option } from '@cmg/common';
import { datalabFiltersSelector } from '@cmg/e2e-selectors';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { SponsorOption } from '../../../../types/domain/firm/sponsorOptions';
import { selectUserSettings } from '../../../shared/ducks';
import { fetchSectors, selectSectors } from '../../../shared/sectors/ducks';
import { mapSectorsToOptions } from '../../../shared/sectors/sectors.model';
import { selectFilters } from '../../ducks';
import { DatalabFilterValues, FilterSettings } from '../../types';
import DatalabFilterForm from './DatalabFilterForm';
import { SFiltersWrapper } from './DatalabFilters.styles';

const mapStateToProps = state => ({
  customSectors: selectSectors(state),
  filters: selectFilters(state),
  userSettings: selectUserSettings(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchSectors,
    },
    dispatch
  ),
});

type OwnProps = {
  filterCount: number;
  advisoryOptions: Option[];
  fundsOptions: Option[];
  sponsorOptions: Option[];
  handleSponsorSearch: (input: string) => Promise<SponsorOption[]>;
  filterSettings: FilterSettings;
  filterDefaultValues: DatalabFilterValues;
  underwriterOptions: Option[];
  spacOptions: Option[];
  onFilterChange: (values: DatalabFilterValues) => void;
  isFilterPanelExpanded: boolean;
  offeringTypesDefaultValues?: {
    value: string;
    label: string;
  }[];
};
export type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export class DatalabFiltersComponent extends React.Component<Props> {
  componentDidMount() {
    !this.props.customSectors && this.props.actions.fetchSectors();
  }

  render() {
    const {
      filters,
      filterCount,
      advisoryOptions,
      fundsOptions,
      sponsorOptions,
      handleSponsorSearch,
      underwriterOptions,
      spacOptions,
      filterSettings,
      filterDefaultValues,
      onFilterChange,
      customSectors,
      isFilterPanelExpanded,
      offeringTypesDefaultValues,
    } = this.props;

    return (
      <SFiltersWrapper data-test-id={datalabFiltersSelector.testId}>
        <Collapse isExpanded={isFilterPanelExpanded}>
          <DatalabFilterForm
            filters={filters}
            filterCount={filterCount}
            defaultValues={filterDefaultValues}
            advisoryOptions={advisoryOptions}
            filterSettings={filterSettings}
            fundsOptions={fundsOptions}
            sponsorOptions={sponsorOptions}
            handleSponsorSearch={handleSponsorSearch}
            underwriterOptions={underwriterOptions}
            customSectorOptions={mapSectorsToOptions(customSectors)}
            spacOptions={spacOptions}
            onChange={onFilterChange}
            offeringTypesDefaultValues={offeringTypesDefaultValues}
          />
        </Collapse>
      </SFiltersWrapper>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DatalabFiltersComponent);
