import { RegularBreakpoints } from '@cmg/design-system';

import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import OfferingProfileCardItem from '../../shared/OfferingProfileCardItem';
import { useGetCompanySecurityData } from '../AtmProfile.model';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
};

const CompanyWidget: React.FC<Props> = ({ offeringProfile, cardSizes }) => {
  const companySecurity = useGetCompanySecurityData(offeringProfile);

  return (
    <OfferingProfileCardItem
      title="Company/Security"
      cardSizes={cardSizes}
      rowData={companySecurity}
    />
  );
};

export default CompanyWidget;
