import { datalabApi } from '@cmg/api';
import { numericUtil } from '@cmg/common';
import { myDashboard } from '@cmg/e2e-selectors';
import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../../../../common/util/routeFactory';
import { offeringTypeLabels } from '../../../../../../types/domain/offering/constants';
import { sectorNameDisplay } from '../../../../../../types/domain/sector/cmgSectorConstants';
import ValueWithMultilineTooltip from '../../../../../offering-dl/shared/components/value-with-multiline-tooltip/ValueWithMultilineTooltip';
import CurrencyValue from '../../../../common/dashboard-value/currency-value/CurrencyValue';
import PerformancePercentsValue from '../../../../common/dashboard-value/performance-percents-value/PerformancePercentsValue';
import HorizontalLine from '../../../../common/horizontal-line/HorizontalLine';
import {
  SDealDetail,
  SIssuerName,
  SIssuerRow,
  SLeftLead,
  SListItem,
  SOfferingType,
  SParticipationTagWrapper,
  SRecentDeal,
  SRow,
  STicker,
  StyledParticipationTag,
} from './RecentDealItem.styles';

export type Props = {
  recentDeal: datalabApi.CalendarOffering;
};

const RecentDealItem: React.FC<Props> = ({ recentDeal }) => {
  return (
    <SListItem>
      <SRecentDeal data-test-id={myDashboard.recentDealsItem.testId}>
        <SIssuerRow>
          <Link to={routeFactory.offerings.getUrlPath({ id: recentDeal.id })}>
            <STicker>{recentDeal.ticker}</STicker>
          </Link>
          <SIssuerName title={recentDeal.issuerFirmName || ''}>
            <Link to={routeFactory.offerings.getUrlPath({ id: recentDeal.id })}>
              {recentDeal.issuerFirmName}
            </Link>
          </SIssuerName>
          <SParticipationTagWrapper>
            <StyledParticipationTag
              ioiType={recentDeal.ioiType}
              allocation={recentDeal.allocation}
            />
          </SParticipationTagWrapper>
        </SIssuerRow>
        <SRow>
          <SDealDetail>
            {recentDeal.sector ? sectorNameDisplay[recentDeal.sector] : '-'}
          </SDealDetail>
          <SOfferingType>{offeringTypeLabels[recentDeal.type]}</SOfferingType>
          <CurrencyValue value={recentDeal.sizeInDollars} size="mediumLarge" />
        </SRow>
        <SRow>
          <SLeftLead>{recentDeal.leftLeadFirmName}</SLeftLead>
          <ValueWithMultilineTooltip
            value={<PerformancePercentsValue value={recentDeal.offerToCurrent} />}
            valuesTooltip={[
              numericUtil.formatCurrency(recentDeal.offerPrice),
              numericUtil.formatCurrency(recentDeal.currentSharePrice),
            ]}
            labelsTooltip={['Offer Price ($)', 'Current Price ($)']}
            label=""
          />
        </SRow>
      </SRecentDeal>
      <HorizontalLine />
    </SListItem>
  );
};

export default RecentDealItem;
