import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import React from 'react';

const StyledStack = styled(Stack, {
  name: 'DomainObject',
  slot: 'Root',
  overridesResolver: (_props, styles) => [styles.root],
})({});

export type DomainObjectProps = Readonly<{
  /**
   * Domain Highlights
   */
  readonly domainHighlights: React.ReactNode;
  /**
   * Domain Navigation
   */
  readonly domainNav?: React.ReactNode;
}>;

export const DomainObject: React.FC<DomainObjectProps> = ({ domainHighlights, domainNav }) => {
  return (
    <StyledStack>
      {domainHighlights}
      {domainNav}
    </StyledStack>
  );
};
