import { createTestId } from '../../common/selectors';

const createControlTestId = name => createTestId(`my-dashboard-${name}-control`);

export const controlsForm = createTestId('my-dashboard-controls-form');

export const currentTimePeriod = createControlTestId('current-time-period');
export const comparisonTimePeriod = createControlTestId('comparison-time-period');
export const useCustomSectors = createControlTestId('use-custom-sectors');
export const underwriterSearch = createControlTestId('underwriter-search');
