import React from 'react';

import { type Calendar_OfferingFieldsFragment } from '../graphql';
import CalendarIconButton from './CalendarIconButton';

type Props = {
  offering: Calendar_OfferingFieldsFragment;
  onClick: (offering: Calendar_OfferingFieldsFragment) => void;
};

const PrecedentReportButton: React.FC<Props> = ({ offering, onClick }) => (
  <CalendarIconButton
    onClick={() => onClick(offering)}
    iconName="search"
    popoverContent={<span>Precedent Transactions</span>}
  />
);

export default PrecedentReportButton;
