import {
  DLGWAverageMethod,
  DLGWOfferingType,
  DLGWPlCredit,
  DLGWSector,
  DLGWSecurityType,
} from '@capital-markets-gateway/api-client-datalab-gateway';

import {
  OfferingSecurityType,
  offeringTypeLabels,
} from '../../../../types/domain/offering/constants';
import { formatSectorName } from '../../../../types/domain/sector/cmgSectorConstants';

export const mapSecurityType = (dlgwVal?: DLGWSecurityType): OfferingSecurityType | null => {
  if (!dlgwVal) {
    return null;
  }

  const mapping: Record<DLGWSecurityType, OfferingSecurityType | null> = {
    [DLGWSecurityType.COMMON_ORDINARY]: OfferingSecurityType.COMMON_ORDINARY,
    [DLGWSecurityType.GDR_ADR_ADS]: OfferingSecurityType.GDR_ADR_ADS,
    [DLGWSecurityType.UNIT]: OfferingSecurityType.UNIT,
    [DLGWSecurityType.COMMON_WARRANTS]: OfferingSecurityType.COMMON_WARRANTS,
    [DLGWSecurityType.CONVERTIBLE_BOND]: dlgwVal.toString() as OfferingSecurityType, // not handled in current DL UI
    [DLGWSecurityType.PREFERRED_SHARE]: dlgwVal.toString() as OfferingSecurityType, // not handled in current DL UI
  };
  return mapping[dlgwVal] || (dlgwVal.toString() as OfferingSecurityType);
};

export const mapOfferingType = (dlgwVal?: DLGWOfferingType): string | null => {
  if (!dlgwVal) {
    return null;
  }

  // already completed for usage of dlgw on dl calendar
  const mapping: Record<DLGWOfferingType, string> = offeringTypeLabels;
  return mapping[dlgwVal] || dlgwVal;
};

export const mapSector = (dlgwVal?: DLGWSector | string | null) => {
  // already completed for usage of dlgw on dl calendar
  return formatSectorName(dlgwVal);
};

export const mapAverageMethodFilter = (
  legacyVal?: 'mean' | 'median' | 'weighted' | null
): DLGWAverageMethod | undefined => {
  if (!legacyVal) {
    return undefined;
  }

  const mapping: Record<string, DLGWAverageMethod> = {
    mean: DLGWAverageMethod.MEAN,
    median: DLGWAverageMethod.MEDIAN,
    weighted: DLGWAverageMethod.WEIGHTED,
  };

  return mapping[legacyVal];
};

export const mapPlCreditFilter = (
  legacyVal?: 'pl_credit' | 'economics' | null
): DLGWPlCredit | undefined => {
  if (!legacyVal) {
    return undefined;
  }

  const mapping: Record<string, DLGWPlCredit> = {
    pl_credit: DLGWPlCredit.PL_CREDIT,
    economics: DLGWPlCredit.ECONOMICS,
  };

  return mapping[legacyVal];
};
