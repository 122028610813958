export type TestId = {
  testId: string;
  selector: string;
};

export const createTestId = testId => ({
  testId,
  selector: `[data-test-id="${testId}"]`,
});

/** Creates a test id object for popover elements */
export const createPopoverTestId = testId => ({
  testId,
  selector: `.ant-popover [data-test-id="${testId}"]`,
});
