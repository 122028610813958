import styled from 'styled-components/macro';

export const SStatusPopoverContent = styled.div`
  max-width: 220px;
  word-wrap: break-word;
`;

export const SStatusText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
