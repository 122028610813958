export function sanitizeWhiteSpaces(text: string) {
  return text.trim().replace(/ +(?= )/g, '');
}

export function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

type TruncateOptions = {
  maxLength: number;
};

export function truncateAtMaxLength(text: string, { maxLength }: TruncateOptions) {
  const shouldTruncate = text.length > maxLength;
  const truncatedText = shouldTruncate ? text.slice(0, maxLength).trimEnd() : text;
  const truncatedTextSuffix = shouldTruncate ? '...' : '';

  return `${truncatedText}${truncatedTextSuffix}`;
}
