import { datalabApi } from '@cmg/api';
import { cmgSectorScheme, FormField, TextInput } from '@cmg/common';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useDebouncedCallback } from 'use-debounce';

import { TreeSelect } from '../../../../common/components/form';
import { mapSectorsToOptions } from '../../../shared/sectors/sectors.model';

const SCompaniesFilters = styled.div`
  display: flex;
  margin: 15px 15px 5px;
`;
const StyledFormField = styled(FormField)`
  flex: 1 1 auto;
  margin: 0 5px;
  min-width: 200px;
  max-width: 300px;
`;

export type FilterValues = {
  query?: string;
  bySectorsValues?: string;
  byCustomSectorsValues?: string;
};

export type Props = {
  sectors: datalabApi.CustomSectorDto[] | null;
  onChange: (values: FilterValues) => void;
};

const CompaniesFilters = ({ onChange, sectors }: Props) => {
  const [values, setValues] = useState<FilterValues>({});

  const debouncedOnChange = useDebouncedCallback(onChange, 300);

  const handleChange = (name, value) => {
    const newValues = { ...values, [name]: value };
    setValues(newValues);
    if (name === 'query') {
      debouncedOnChange(newValues);
    } else {
      onChange(newValues);
    }
  };

  return (
    <SCompaniesFilters>
      <StyledFormField label="Search Companies">
        <TextInput
          value={values.query || ''}
          onChange={value => handleChange('query', value)}
          placeholder="Search..."
        />
      </StyledFormField>
      <StyledFormField label="Filter by Sectors">
        <TreeSelect
          value={values.bySectorsValues}
          treeData={cmgSectorScheme}
          onChange={value => handleChange('bySectorsValues', value)}
          treeCheckable={false}
        />
      </StyledFormField>
      <StyledFormField label="Filter by My Sectors">
        <TreeSelect
          value={values.byCustomSectorsValues}
          treeData={mapSectorsToOptions(sectors)}
          onChange={value => handleChange('byCustomSectorsValues', value)}
          treeCheckable={false}
        />
      </StyledFormField>
    </SCompaniesFilters>
  );
};

export default CompaniesFilters;
