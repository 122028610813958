import React from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { SignalRCallbackByEvent, SignalREvent } from '../signalr';
import { timeUtil } from '../util';

export type CalendarEventFilterFn =
  | ((...args: Parameters<SignalRCallbackByEvent[SignalREvent.CALENDAR_REFRESH]>) => boolean)
  | undefined;

export function filterSidePanelEvent(offeringId: string): NonNullable<CalendarEventFilterFn> {
  return function (messageOfferingId) {
    return offeringId === messageOfferingId;
  };
}

export const useWrapCalendarRefreshCallback = ({
  callback,
  filterFn,
}: {
  callback: SignalRCallbackByEvent[SignalREvent.CALENDAR_REFRESH];
  filterFn?: CalendarEventFilterFn;
}) => {
  const [lastUpdatedAt, setLastUpdatedAt] = React.useState(new Date().toISOString());

  const callbackWithLastUpdatedAt: typeof callback = React.useCallback(
    (...args) => {
      setLastUpdatedAt(new Date().toISOString());
      return callback(...args);
    },
    [callback]
  );

  const debouncedCallback = useDebouncedCallback(
    callbackWithLastUpdatedAt,
    timeUtil.getRandomMilliseconds(10)
  );

  const filteredCallback: typeof callback = React.useCallback(
    (...args) => {
      if (filterFn && !filterFn?.(...args)) {
        return;
      }

      return debouncedCallback(...args);
    },
    [debouncedCallback, filterFn]
  );

  return { lastUpdatedAt, wrappedCallback: filteredCallback };
};
