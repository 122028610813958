import { createTestId } from '../../../common/selectors';

const create = (name: string) => createTestId(`xc-roadshows-configuration-${name}`);

export * from './config-days';
export * from './config-deal-teams';
export * from './config-management-team';
export * from './roadshow-details';

export const roadshowConfigViewCalendarButton = create('view-calendar-button');
export const roadshowConfigCreateRoadshowButton = create('create-roadshow-button');
export const roadshowConfigPublishRoadshowButton = create('publish-roadshow-button');
