import {
  ColumnApi,
  GridApi,
  ProcessCellForExportParams,
  ProcessHeaderForExportParams,
} from 'ag-grid-community';

// api/apiUtil.ts deeply re-exports types, which somehow workarounds known "feature" of type being
// compiled to nothing when isolatedModules=true
import * as apiTypes from './api/types/apiTypes';
import * as apiUtil from './api/utils/apiUtil';
/**
 * Main export for this package. Anything defined here is accessible from other packages importing this
 * one. e.g. `import { CMGLogo } from '@cmg/common';`
 */
import * as assets from './assets';
import DangerButton from './components/buttons/button/DangerButton';
import IconButton from './components/buttons/button/IconButton';
import InfoButton from './components/buttons/button/InfoButton';
import LinkButton from './components/buttons/button/LinkButton';
import PrimaryButton from './components/buttons/button/PrimaryButton';
import SecondaryButton from './components/buttons/button/SecondaryButton';
import SuccessButton from './components/buttons/button/SuccessButton';
import WarningButton from './components/buttons/button/WarningButton';
import ButtonGroup from './components/buttons/button-group/ButtonGroup';
import ButtonsContainer from './components/buttons/buttons-container/ButtonsContainer';
import DropdownButton from './components/dropdowns/dropdown-button/DropdownButton';
import DropdownContainer from './components/dropdowns/dropdown-container/DropdownContainer';
import { Props as DropdownOptionType } from './components/dropdowns/dropdown-item/DropdownItem';
import DropdownSelect from './components/dropdowns/dropdown-select/DropdownSelect';
import TreeSelect, { TreeSelectField } from './components/dropdowns/tree-select/TreeSelect';
import Checkbox, { CheckboxField } from './components/form/checkbox/Checkbox';
import DatePicker, { DatePickerField } from './components/form/date-picker/DatePicker';
import DateTimePicker, { DateTimePickerField } from './components/form/date-picker/DateTimePicker';
import DateTimeZonePicker, {
  DateTimeZonePickerField,
} from './components/form/date-picker/DateTimeZonePicker';
import {
  DateRange,
  DateRangeField,
  DateRangeValue as DateRangeValueType,
} from './components/form/date-range/DateRange';
import {
  dateRangeUtil,
  Preset as DateRangePresetType,
  PresetOptions as DateRangePresetOptionsType,
  PresetTypes as DateRangePresetTypes,
} from './components/form/date-range/DateRange.model';
import FormField from './components/form/field/FormField';
import FileUploadInput, {
  FileUploadInputField,
} from './components/form/file-upload-input/FileUploadInput';
import {
  createFormikField,
  FieldControlProps as FieldControlPropsType,
} from './components/form/formik/createFormikField';
import FormLabel from './components/form/label/FormLabel';
import CurrencyInput, {
  CurrencyAgEditor,
  CurrencyInputField,
} from './components/form/numeric-currency-input/CurrencyInput';
import NumericInput, {
  NumericAgEditor,
  NumericInputField,
} from './components/form/numeric-input/NumericInput';
import PercentInput, {
  PercentAgEditor,
  PercentInputField,
} from './components/form/numeric-percent-input/PercentInput';
import CurrencyRangeInput, {
  CurrencyRangeInputField,
} from './components/form/range-inputs/currency-range-input/CurrencyRangeInput';
import NumericRangeInput, {
  NumericRangeInputField,
} from './components/form/range-inputs/numeric-range-input/NumericRangeInput';
import PercentRangeInput, {
  PercentRangeInputField,
} from './components/form/range-inputs/percent-range-input/PercentRangeInput';
import StackedInputGroup from './components/form/stacked-input-group/StackedInputGroup';
import Switch, { SwitchField } from './components/form/switch/Switch';
import TextArea, { TextAreaField } from './components/form/text-area/TextArea';
import TextInput, { TextAgEditor, TextInputField } from './components/form/text-input/TextInput';
import TimeZonePicker, {
  TimeZonePickerField,
} from './components/form/time-zone-picker/TimeZonePicker';
import { FormControlEvents as FormControlEventsType } from './components/form/types';
import GlobalStyle from './components/global/global-style/GlobalStyle';
import ThemeProvider from './components/global/theme-provider/ThemeProvider';
import CMGLogo from './components/graphics/cmg-logo/CMGLogo';
import { FlagComponent } from './components/graphics/flag-icons/FlagIcons';
import Icon, { IconNameParts as IconNamePartsType } from './components/graphics/icon/Icon';
import { LightIconName as LightIconNameType } from './components/graphics/icon/icon-provider/lightIcons';
import { RegularIconName as RegularIconNameType } from './components/graphics/icon/icon-provider/regularIcons';
import { SolidIconName as SolidIconNameType } from './components/graphics/icon/icon-provider/solidIcons';
import Banner from './components/indicators/banner/Banner';
import Disclaimer, { disclaimerAssets } from './components/indicators/disclaimer/Disclaimer';
import Progressbar from './components/indicators/progressbar/Progressbar';
import Ribbon from './components/indicators/ribbon/Ribbon';
import ServiceErrorBanner from './components/indicators/service-errors/ServiceErrorsBanner';
import Status from './components/indicators/status/Status';
import Tag from './components/indicators/tag/Tag';
import BoxButton from './components/layout/box/BoxButton';
import CenteredPage from './components/layout/centered-page/CenteredPage';
import Collapse from './components/layout/collapse/Collapse';
import ErrorBoundary from './components/layout/error-boundary/ErrorBoundary';
import InterstitialPage from './components/layout/interstitial-page/InterstitialPage';
import MediaQuery from './components/layout/media/MediaQuery';
import MobileNavigation from './components/layout/navigation/MobileNavigation';
import Navigation from './components/layout/navigation/Navigation';
import { selectColumn } from './components/lists/data-grid/aggrid/AgGridWrapper';
import { createAgEditor } from './components/lists/data-grid/aggrid/createAgEditor';
import DataGrid, {
  PaginationQueryParams as PaginationQueryParamsType,
} from './components/lists/data-grid/DataGrid';
import DataGridClient from './components/lists/data-grid/DataGridClient';
import DataGridGroupingClient from './components/lists/data-grid/DataGridGroupingClient';
import PaginationDropdown from './components/lists/data-grid/dropdowns/PaginationDropdown';
import { GroupRow } from './components/lists/data-grid/hooks/useGrouping';
import Pagination from './components/lists/pagination/Pagination';
import TreeGrid from './components/lists/tree-grid/TreeGrid';
import ConfirmModal from './components/overlays/confirm-modal/ConfirmModal';
import Popover from './components/overlays/popover/Popover';
import ToastManager from './components/overlays/toast-manager/ToastManager';
import UnsavedChangesGuard from './components/overlays/unsaved-changes-guard/UnsavedChangesGuard';
import cmgSectorScheme from './data/cmgSectorScheme';
import {
  countriesList,
  countryNamesGroupedByRegion,
  regionAndCountryTreeSelectSchema,
} from './data/countriesList';
import { currencyCodes } from './data/currencyCodes';
import { exchangeMicLabels, regions } from './data/exchangeMicLabels';
import { testStockSymbols } from './data/testingStockSymbols';
import useInfiniteScroll from './hooks/infinite-scroll/useInfiniteScroll';
// eslint-disable-next-line no-restricted-syntax
import { useDebugDependencyWatch } from './hooks/useDebugDependencyWatch/useDebugDependencyWatch';
import { createUseDocumentTitleHook } from './hooks/useDocumentTitle/useDocumentTitle';
import { useHubspotForm } from './hooks/useHubspotForm/useHubspotForm';
import { useScript } from './hooks/useScript/useScript';
import { useSearchParams } from './hooks/useSearchParams/useSearchParams';
import { useUrlFilterStorage } from './hooks/useUrlFilterStorage/useUrlFilterStorage';
import { useValidateFormOnSubmit } from './hooks/validate-form-on-submit/useValidateFormOnSubmit';
import { createDefaultGenericErrorArgTypeValue } from './stories/createDefaultGenericErrorArgTypeValue';
import { createStoryWithControls } from './stories/createStoryWithControls';
import theme from './styles/theme';
import * as testUtil from './test/testUtil';
import { UnpackPromise as UnpackPromiseType } from './types/language/UnpackPromise';

export * from './auto-save';
export type { IconType } from './components/buttons/button/IconButton';
export * from './components/errors';
export * from './components/form';
export type { DateTimeValue } from './components/form/date-picker/DateTimePicker.model';
export * from './components/form/select/types';
export * from './components/layout';
export * from './components/lists';
export type { CustomCellRendererProps } from './components/lists/data-grid/DataGrid';
export * from './components/overlays';
export * from './data/currencies';
export type { BrowserStorageProps } from './hooks/useBrowserStorage';
export { useBrowserStorage, useRemoveFromBrowserStorage } from './hooks/useBrowserStorage';
export * from './hooks/useDebug/useDebug';
export type { SortModel } from './hooks/useGridDatasource/GridDataSourceManager';
export { useGridDatasource } from './hooks/useGridDatasource/useGridDatasource';
export { useListCompare } from './hooks/useListCompare';
export { useWindowVisibility } from './hooks/useWindowVisibility';
export * from './redux';
export * from './server-events';
export * from './signalr';
export * from './types';
export * from './util';

// Form
export {
  Checkbox,
  CheckboxField,
  createFormikField,
  CurrencyAgEditor,
  CurrencyInput,
  CurrencyInputField,
  CurrencyRangeInput,
  CurrencyRangeInputField,
  DatePicker,
  DatePickerField,
  DateRange,
  DateRangeField,
  DateRangePresetTypes,
  dateRangeUtil,
  DateTimePicker,
  DateTimePickerField,
  DateTimeZonePicker,
  DateTimeZonePickerField,
  FileUploadInput,
  FileUploadInputField,
  FormField,
  FormLabel,
  NumericAgEditor,
  NumericInput,
  NumericInputField,
  NumericRangeInput,
  NumericRangeInputField,
  PercentAgEditor,
  PercentInput,
  PercentInputField,
  PercentRangeInput,
  PercentRangeInputField,
  StackedInputGroup,
  TextAgEditor,
  TextArea,
  TextAreaField,
  TextInput,
  TextInputField,
  TimeZonePicker,
  TimeZonePickerField,
};

export {
  apiTypes,
  apiUtil,
  assets,
  Banner,
  BoxButton,
  ButtonGroup,
  ButtonsContainer,
  CenteredPage,
  CMGLogo,
  cmgSectorScheme,
  Collapse,
  ConfirmModal,
  countriesList,
  countryNamesGroupedByRegion,
  createAgEditor,
  createDefaultGenericErrorArgTypeValue,
  createStoryWithControls,
  createUseDocumentTitleHook,
  currencyCodes,
  DangerButton,
  DataGrid,
  DataGridClient,
  DataGridGroupingClient,
  Disclaimer,
  disclaimerAssets,
  DropdownButton,
  DropdownContainer,
  DropdownSelect,
  ErrorBoundary,
  exchangeMicLabels,
  FlagComponent,
  GlobalStyle,
  Icon,
  IconButton,
  InfoButton,
  InterstitialPage,
  LinkButton,
  MediaQuery,
  MobileNavigation,
  Navigation,
  Pagination,
  PaginationDropdown, // todo: ECM-2616 - delete this export during removing deprecated VirtualTableWidget
  Popover,
  PrimaryButton,
  Progressbar,
  regionAndCountryTreeSelectSchema,
  regions,
  Ribbon,
  SecondaryButton,
  selectColumn,
  ServiceErrorBanner,
  Status,
  SuccessButton,
  Switch,
  SwitchField,
  Tag,
  testStockSymbols,
  testUtil,
  theme,
  ThemeProvider,
  ToastManager,
  TreeGrid,
  TreeSelect,
  TreeSelectField,
  UnsavedChangesGuard,
  // eslint-disable-next-line no-restricted-syntax
  useDebugDependencyWatch,
  useHubspotForm,
  useInfiniteScroll,
  useScript,
  useSearchParams,
  useUrlFilterStorage,
  useValidateFormOnSubmit,
  WarningButton,
};

export type UnpackPromise<T> = UnpackPromiseType<T>;
export type IconNameParts = IconNamePartsType;
export type SolidIconName = keyof typeof SolidIconNameType;
export type RegularIconName = keyof typeof RegularIconNameType;
export type LightIconName = keyof typeof LightIconNameType;
export type FieldControlProps<
  TFieldValue,
  TChangeValue,
  TElement = HTMLInputElement
> = FieldControlPropsType<TFieldValue, TChangeValue, TElement>;
export type FormControlEvents = FormControlEventsType;
export type DropdownOption = DropdownOptionType;
export type DateRangePreset = DateRangePresetType;
export type DateRangePresetOptions = DateRangePresetOptionsType;
export type DateRangeValue = DateRangeValueType;
export type PaginationQueryParams = PaginationQueryParamsType;
export type AgGridApi = GridApi;
export type AgGridColumnApi = ColumnApi;
export type AgGridProcessCellForExportParams = ProcessCellForExportParams;
export type AgGridProcessHeaderForExportParams = ProcessHeaderForExportParams;
export type GridGroupRow = GroupRow;
export type { FlagProps } from './components/graphics/flag-icons/FlagIcons';
export type { SizeProp as IconSize } from './components/graphics/icon/Icon';
export type {
  OrderComparator,
  OrderComparatorFn,
} from './components/lists/data-grid/hooks/useGrouping';
export type { Props as PopoverProps } from './components/overlays/popover/Popover';
export type { Props as UnsavedChangesGuardProps } from './components/overlays/unsaved-changes-guard/UnsavedChangesGuard';
export type { Options } from './stories/createStoryWithControls';
export type { Theme } from './styles/types';
export type { ISODate, ISODateTime, UUID } from './types/common';
export type { RouteConfig } from './util/route';
export type { IGetRowsParams } from 'ag-grid-community';

// old design system
export * from './old-design-system/components';
export type {
  Comparator,
  OrderInfo,
} from './old-design-system/components/layout/table/orderable/OrderableTable.model';
export { OrderDirection } from './old-design-system/components/layout/table/orderable/OrderableTable.model';
