/**
 * configures and exports numeral with default locale info
 */
import numeral from 'numeral';

const defaultLocale = {
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: number => {
    const b = number % 10;
    if ((number % 100) / 10 === 1) {
      return 'th';
    } else if (b === 1) {
      return 'st';
    } else if (b === 2) {
      return 'nd';
    } else if (b === 3) {
      return 'rd';
    }
    return 'th';
  },
};

// Avoid registering the same locale multiple times
if (!numeral.locales.hasOwnProperty('en-us')) {
  numeral.register('locale', 'en-us', {
    ...defaultLocale,
    currency: {
      symbol: '$',
    },
  });
}

numeral.locale('en-us'); // todo move localization into redux ?

export default numeral;
