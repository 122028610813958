import { createTestId } from '../../common/selectors';

export const ioiTrackerScreenSelector = createTestId('offering-ioi-tracker');
export const ioiTrackerFinalSharesSelector = createTestId('final-shares');
export const ioiTrackerAddIOISelector = createTestId('add-ioi');
export const ioiTrackerEditSelector = createTestId('edit');
export const ioiTrackerPassSelector = createTestId('pass');
export const ioiTrackerPassLabelSelector = createTestId('pass');
export const ioiTrackerRegMSelector = createTestId('reg-m');
export const ioiTrackerSaveSelector = createTestId('save');
export const ioiTrackerIndicatorsTableSelector = createTestId(
  'offering-ioi-tracker-indicators-table'
);
export const ioiTrackerLimitTypeWrapperSelector = createTestId('save');
