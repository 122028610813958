import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import type { CheckboxProps as MUICheckboxProps } from '@mui/material/Checkbox';
import MUICheckbox from '@mui/material/Checkbox';
import React from 'react';

export type CheckboxProps = Omit<MUICheckboxProps, 'icon'>;

export const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
  const iconColor = props.color === 'error' ? 'error' : undefined;

  return <MUICheckbox {...props} icon={<CheckBoxOutlineBlankOutlinedIcon color={iconColor} />} />;
};
