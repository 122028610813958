import { useEffect, useState } from 'react';

/**
 * Track window visibility via a hook.
 * Useful for only running logic when a user has the app in view, like animations or queries.
 *
 * Uses the Page Visibility API.
 * https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
 * @returns true for visible, false for not visible
 */
export const useWindowVisibility = () => {
  const [visible, setVisible] = useState<boolean>(document.visibilityState === 'visible');

  useEffect(() => {
    const onVisibilityChange = () => {
      setVisible(document.visibilityState === 'visible');
    };

    window.addEventListener('visibilitychange', onVisibilityChange);

    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, []);

  return visible;
};
