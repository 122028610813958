import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

/**
 * Initializes Datadog Real User Monitoring and
 * Logging.
 */
const init = ({
  datadogApplicationId,
  datadogClientToken,
  clusterEnvironment,
  serviceName,
  allowedTracingOrigin,
  isDevelopment = false,
  version,
}: {
  /**
   * Datadog Application ID
   */
  datadogApplicationId?: string;
  /**
   * Datadog Client Token
   */
  datadogClientToken?: string;
  /**
   * An identifier for the environment
   * the application is running in.
   */
  clusterEnvironment?: string;
  /**
   * Datadog 'Service' - unique per SPA
   */
  serviceName: string;
  /**
   * Datadog regex or string of origins that will be traced
   * e.g. /http:\/\/.*\.local\.oncmg\.io/
   */
  allowedTracingOrigin: RegExp;
  /**
   * Is SPA running in development mode
   */
  isDevelopment?: boolean;
  /**
   * SPA Version
   */
  version?: string;
}) => {
  if (isDevelopment) {
    console.warn('Initializing DataDog logger in development mode.');

    datadogLogs.init({
      clientToken: datadogClientToken ?? 'mock-datadogToken',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      env: clusterEnvironment ?? 'local',
      service: serviceName,
      version,
    });

    datadogLogs.logger.setHandler('console');
    datadogLogs.logger.setLevel('debug');
  }

  if (!isDevelopment && datadogApplicationId && datadogClientToken && clusterEnvironment) {
    datadogRum.init({
      applicationId: datadogApplicationId,
      clientToken: datadogClientToken,
      env: clusterEnvironment,
      service: serviceName,
      version,
      allowedTracingUrls: [allowedTracingOrigin],
    });

    datadogLogs.init({
      clientToken: datadogClientToken,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      env: clusterEnvironment,
      service: serviceName,
      version,
    });
  } else {
    console.error(
      'DataDog instrumentation not enabled, required configuration values are missing.'
    );
  }
};

/**
 * Track an error with stack trace in Datadog
 * Passthrough to https://docs.datadoghq.com/logs/log_collection/javascript/?tab=bundle#send-a-custom-log-entry
 */
const logError = ({ message, context }: { message: string; context: { [key: string]: any } }) => {
  datadogLogs.logger.error(message, context);
};

const logInfo = ({ message, context }: { message: string; context: { [key: string]: any } }) => {
  datadogLogs.logger.info(message, context);
};

const logWarning = ({ message, context }: { message: string; context: { [key: string]: any } }) => {
  datadogLogs.logger.warn(message, context);
};

const datalogLogger = {
  init,
  logError,
  logWarning,
  logInfo,
};

export default datalogLogger;
