import { createTestId } from '../../../../common/selectors';

export const accountUserListScreenSelector = createTestId('account-user-list-screen');
export const accountUserListHeaderSelector = createTestId('account-user-list-header');
export const accountUserListSelector = createTestId('account-user-list');
export const accountUserListCreateUserSelector = createTestId('account-user-list-create-user');

export const userListSearchInputSelector = createTestId('user-list-search-input');
export const userListStatusTypeSelectSelector = createTestId('user-list-status-select');
export const userListEmailColumnSelector = createTestId('user-list-email-column');
export const userListEmployeeKeyColumnSelector = createTestId('user-list-employee-key-column');
export const userListNameLinkSelector = createTestId('user-list-name-link');
export const userListJobFunctionTypeSelectSelector = createTestId('user-list-job-function-select');
export const userListAccountConfirmedTypeSelectSelector = createTestId(
  'user-list-account-confirmed-select'
);
