import { RowNode } from 'ag-grid-community';
import React from 'react';

import Popover from '../../../overlays/popover/Popover';
import { SStatusPopoverContent, SStatusText } from './LongStringCellRenderer.styles';

export type Props = Readonly<{
  value?: string | null;
  valueFormatted?: string | null;
  node: RowNode;
}>;

const LongStringCellRenderer: React.FC<Props> = ({ value, valueFormatted, node }) => {
  const isHeaderRow = node.isRowPinned();

  if (isHeaderRow) {
    return null;
  }

  return (
    <Popover
      variant="DARK"
      placement="topLeft"
      disabled={!value?.length}
      content={<SStatusPopoverContent>{valueFormatted || value}</SStatusPopoverContent>}
    >
      <SStatusText>{valueFormatted || value}</SStatusText>
    </Popover>
  );
};

export default LongStringCellRenderer;
