import { timeUtil } from '@cmg/common';
import { getUnixTime, isValid, parse, parseISO } from 'date-fns';

import { OfferingStatus, OfferingType } from '../../../../types/domain/offering/constants';
import { TimingStates } from '../../shared/model/timings.model';

export function isFollowOnType(offeringType) {
  return offeringType !== OfferingType.IPO;
}

export function isPendingStatus(offeringStatus) {
  return (
    offeringStatus === OfferingStatus.FILED ||
    offeringStatus === OfferingStatus.LIVE ||
    offeringStatus === OfferingStatus.POSTPONED
  );
}

export function isLiveOrFiledStatus(offeringStatus) {
  return offeringStatus === OfferingStatus.FILED || offeringStatus === OfferingStatus.LIVE;
}

export function getDatalabTimingsArray(timingObject, offeringStatus, offeringType) {
  return [
    {
      status: TimingStates.CONFIDENTIAL_FILING_DATE,
      date: timingObject.confidentialFilingDate,
      include: () => {
        if (!isFollowOnType(offeringType)) {
          return true;
        }
        return !!timingObject.confidentialFilingDate;
      },
    },
    {
      status: TimingStates.PUBLIC_FILING_DATE,
      date: timingObject.publicFilingDate,
      include: () => true,
    },
    {
      status: TimingStates.POSTPONED_DATE,
      date: timingObject.postponedDate,
      include: () => !!timingObject.postponedDate,
    },
    {
      status: TimingStates.WITHDRAWN_DATE,
      date: timingObject.withdrawnDate,
      include: () => !!timingObject.withdrawnDate,
    },
    {
      status: TimingStates.LAUNCH_DATE,
      date: timingObject.launchDate,
      include: () => !!timingObject.launchDate,
    },
    {
      status: TimingStates.PRICING_DATE,
      date: timingObject.pricingDate,
      include: () => {
        if (isPendingStatus(offeringStatus)) {
          return false;
        }
        return !!timingObject.pricingDate;
      },
    },
    {
      status: TimingStates.EXPECTED_PRICING_DATE,
      date: timingObject.pricingDate,
      include: () => {
        if (!isPendingStatus(offeringStatus)) {
          return false;
        }
        if (isLiveOrFiledStatus(offeringStatus)) {
          return true;
        }
        return !!timingObject.pricingDate;
      },
    },
    {
      status: TimingStates.FIRST_TRADE_DATE,
      date: timingObject.firstTradeDate,
      include: () => {
        if (isPendingStatus(offeringStatus)) {
          return false;
        }
        return !!timingObject.firstTradeDate;
      },
    },
    {
      status: TimingStates.EXPECTED_FIRST_TRADE_DATE,
      date: timingObject.firstTradeDate,
      include: () => {
        if (!isPendingStatus(offeringStatus)) {
          return false;
        }
        if (isLiveOrFiledStatus(offeringStatus)) {
          return true;
        }
        return !!timingObject.firstTradeDate;
      },
    },
    {
      status: TimingStates.TERMINATION_DATE,
      date: timingObject.terminationDate,
      include: () => !!timingObject.terminationDate,
    },
    {
      status: TimingStates.SETTLEMENT_DATE,
      date: timingObject.settlementDate,
      include: () => {
        if (isPendingStatus(offeringStatus) || offeringStatus === OfferingStatus.TERMINATED) {
          return false;
        }
        if (isLiveOrFiledStatus(offeringStatus)) {
          return true;
        }
        return !!timingObject.settlementDate;
      },
    },
    {
      status: TimingStates.EXPECTED_SETTLEMENT_DATE,
      date: timingObject.settlementDate,
      include: () => {
        if (!isPendingStatus(offeringStatus) && offeringStatus !== OfferingStatus.TERMINATED) {
          return false;
        }
        if (isLiveOrFiledStatus(offeringStatus)) {
          return true;
        }
        return !!timingObject.settlementDate;
      },
    },
  ]
    .filter(item => item.include())
    .map(({ status, date }) => {
      if (!date) {
        return {
          status,
          date,
          day: null,
          monthYear: null,
          timestamp: null,
        };
      }

      const usDate = parse(date, 'MM/dd/yyyy', new Date());
      const isoDate = parseISO(date);
      const dateTime = isValid(usDate) ? usDate : isoDate;

      return {
        status,
        date,
        day: timeUtil.formatAsDay(dateTime),
        monthYear: timeUtil.formatAsMonthAbbreviationAndYear(dateTime),
        timestamp: getUnixTime(dateTime),
      };
    });
}
