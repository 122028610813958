export enum regions {
  NAMR = 'NAMR',
  LAMR = 'LAMR',
  EMEA = 'EMEA',
  APAC = 'APAC',
}
export const exchangeMicLabels = {
  XNAS: {
    name: 'XNAS',
    displayName: 'Nasdaq Stock Market',
    region: regions.NAMR,
    currencyCode: 'USD',
    countryCode: 'US',
    countryDisplayName: 'USA',
    shortName: 'NASDAQ',
  },
  XNYS: {
    name: 'XNYS',
    displayName: 'New York Stock Exchange',
    region: regions.NAMR,
    currencyCode: 'USD',
    countryCode: 'US',
    countryDisplayName: 'USA',
    shortName: 'NYSE',
  },
  XBUE: {
    name: 'XBUE',
    displayName: 'Buenos Aires Stock Exchange',
    region: regions.LAMR,
    currencyCode: 'ARS',
    countryCode: 'AR',
    countryDisplayName: 'Argentina',
    shortName: 'BCBA',
  },
  XNEC: {
    name: 'XNEC',
    displayName: 'National Stock Exchange of Australia',
    region: regions.APAC,
    currencyCode: 'AUD',
    countryCode: 'AU',
    countryDisplayName: 'Australia',
    shortName: 'NSXA',
  },
  XASX: {
    name: 'XASX',
    displayName: 'Australian Stock Exchange',
    region: regions.APAC,
    currencyCode: 'AUD',
    countryCode: 'AU',
    countryDisplayName: 'Australia',
    shortName: 'ASX',
  },
  XWBO: {
    name: 'XWBO',
    displayName: 'Vienna Stock Exchange',
    region: regions.EMEA,
    currencyCode: 'EUR',
    countryCode: 'AT',
    countryDisplayName: 'Austria',
    shortName: 'VIE',
  },
  XBAH: {
    name: 'XBAH',
    displayName: 'Bahrain Bourse',
    region: regions.EMEA,
    currencyCode: 'BHD',
    countryCode: 'BH',
    countryDisplayName: 'Bahrain',
    shortName: 'Bahrain',
  },
  XDHA: {
    name: 'XDHA',
    displayName: 'Dhaka Stock Exchange',
    region: regions.APAC,
    currencyCode: 'BDT',
    countryCode: 'BD',
    countryDisplayName: 'Bangladesh',
    shortName: 'Dhaka',
  },
  XBRU: {
    name: 'XBRU',
    displayName: 'Euronext Brussels',
    region: regions.EMEA,
    currencyCode: 'EUR',
    countryCode: 'BE',
    countryDisplayName: 'Belgium',
    shortName: 'Brussels',
  },
  BVMF: {
    name: 'BVMF',
    displayName: 'Brazil Stock Exchange',
    region: regions.LAMR,
    currencyCode: 'BRL',
    countryCode: 'BR',
    countryDisplayName: 'Brazil',
    shortName: 'B3',
  },
  XCNQ: {
    name: 'XCNQ',
    displayName: 'Canadian Securities Exchange',
    region: regions.NAMR,
    currencyCode: 'CAD',
    countryCode: 'CA',
    countryDisplayName: 'Canada',
    shortName: 'CSE',
  },
  XTSE: {
    name: 'XTSE',
    displayName: 'Toronto Stock Exchange',
    region: regions.NAMR,
    currencyCode: 'CAD',
    countryCode: 'CA',
    countryDisplayName: 'Canada',
    shortName: 'TSX',
  },
  XTSX: {
    name: 'XTSX',
    displayName: 'TSX Venture Exchange',
    region: regions.NAMR,
    currencyCode: 'CAD',
    countryCode: 'CA',
    countryDisplayName: 'Canada',
    shortName: 'TSX-V',
  },
  NEOE: {
    name: 'NEOE',
    displayName: 'Aequitas NEO Exchange',
    region: regions.NAMR,
    currencyCode: 'CAD',
    countryCode: 'CA',
    countryDisplayName: 'Canada',
    shortName: 'NEO',
  },
  XSGO: {
    name: 'XSGO',
    displayName: 'Santiago Stock Exchange',
    region: regions.LAMR,
    currencyCode: 'CLP',
    countryCode: 'CL',
    countryDisplayName: 'Chile',
    shortName: 'Santiago',
  },
  XSHG: {
    name: 'XSHG',
    displayName: 'Shanghai Stock Exchange',
    region: regions.APAC,
    currencyCode: 'CNY',
    countryCode: 'CN',
    countryDisplayName: 'China',
    shortName: 'Shanghai',
  },
  XSHE: {
    name: 'XSHE',
    displayName: 'Shenzhen Stock Exchange',
    region: regions.APAC,
    currencyCode: 'CNY',
    countryCode: 'CN',
    countryDisplayName: 'China',
    shortName: 'Shenzhen',
  },
  XBOG: {
    name: 'XBOG',
    displayName: 'Colombia Securities Exchange',
    region: regions.LAMR,
    currencyCode: 'COP',
    countryCode: 'CO',
    countryDisplayName: 'Colombia',
    shortName: 'BVC',
  },
  XZAG: {
    name: 'XZAG',
    displayName: 'Zagreb Stock Exchange',
    region: regions.EMEA,
    currencyCode: 'HRK',
    countryCode: 'HR',
    countryDisplayName: 'Croatia',
    shortName: 'Zagreb',
  },
  XCYS: {
    name: 'XCYS',
    displayName: 'Cyprus Stock Exchange',
    region: regions.EMEA,
    currencyCode: 'EUR',
    countryCode: 'CY',
    countryDisplayName: 'Cyprus',
    shortName: 'Cyprus',
  },
  XPRA: {
    name: 'XPRA',
    displayName: 'Prague Stock Exchange',
    region: regions.EMEA,
    currencyCode: 'CZK',
    countryCode: 'CZ',
    countryDisplayName: 'Czech Republic',
    shortName: 'Prague',
  },
  XCSE: {
    name: 'XCSE',
    displayName: 'Nasdaq OMX Copenhagen',
    region: regions.EMEA,
    currencyCode: 'DKK',
    countryCode: 'DK',
    countryDisplayName: 'Denmark',
    shortName: 'XCSE',
  },
  XCAI: {
    name: 'XCAI',
    displayName: 'Egyptian Exchange',
    region: regions.EMEA,
    currencyCode: 'EGP',
    countryCode: 'EG',
    countryDisplayName: 'Egypt',
    shortName: 'Cairo',
  },
  XHEL: {
    name: 'XHEL',
    displayName: 'Nasdaq OMX Helsinki',
    region: regions.EMEA,
    currencyCode: 'EUR',
    countryCode: 'FI',
    countryDisplayName: 'Finland',
    shortName: 'Helsinki',
  },
  XPAR: {
    name: 'XPAR',
    displayName: 'Euronext Paris',
    region: regions.EMEA,
    currencyCode: 'EUR',
    countryCode: 'FR',
    countryDisplayName: 'France',
    shortName: 'Paris',
  },
  XFRA: {
    name: 'XFRA',
    displayName: 'Frankfurt Stock Exchange',
    region: regions.EMEA,
    currencyCode: 'EUR',
    countryCode: 'DE',
    countryDisplayName: 'Germany',
    shortName: 'Frankfurt',
  },
  XATH: {
    name: 'XATH',
    displayName: 'Athens Stock Exchange',
    region: regions.EMEA,
    currencyCode: 'EUR',
    countryCode: 'GR',
    countryDisplayName: 'Greece',
    shortName: 'Athens',
  },
  XHKG: {
    name: 'XHKG',
    displayName: 'Hong Kong Stock Exchange',
    region: regions.APAC,
    currencyCode: 'HKD',
    countryCode: 'HK',
    countryDisplayName: 'Hong Kong',
    shortName: 'HKEX',
  },
  XBUD: {
    name: 'XBUD',
    displayName: 'Budapest Stock Exchange',
    region: regions.EMEA,
    currencyCode: 'HUF',
    countryCode: 'HU',
    countryDisplayName: 'Hungary',
    shortName: 'Budapest',
  },
  XICE: {
    name: 'XICE',
    displayName: 'Iceland Stock Exchange',
    region: regions.EMEA,
    currencyCode: 'ISK',
    countryCode: 'IS',
    countryDisplayName: 'Iceland',
    shortName: 'Iceland',
  },
  XBOM: {
    name: 'XBOM',
    displayName: 'Bombay Stock Exchange',
    region: regions.APAC,
    currencyCode: 'INR',
    countryCode: 'IN',
    countryDisplayName: 'India',
    shortName: 'Bombay',
  },
  XNSE: {
    name: 'XNSE',
    displayName: 'National Stock Exchange of India',
    region: regions.APAC,
    currencyCode: 'INR',
    countryCode: 'IN',
    countryDisplayName: 'India',
    shortName: 'NSE',
  },
  XIDX: {
    name: 'XIDX',
    displayName: 'Indonesia Stock Exchange',
    region: regions.APAC,
    currencyCode: 'IDR',
    countryCode: 'ID',
    countryDisplayName: 'Indonesia',
    shortName: 'JAK',
  },
  XDUB: {
    name: 'XDUB',
    displayName: 'Irish Stock Exchange ',
    region: regions.EMEA,
    currencyCode: 'EUR',
    countryCode: 'IE',
    countryDisplayName: 'Ireland',
    shortName: 'Dublin',
  },
  XTAE: {
    name: 'XTAE',
    displayName: 'Tel Aviv Stock Exchange',
    region: regions.EMEA,
    currencyCode: 'ILS',
    countryCode: 'IL',
    countryDisplayName: 'Israel',
    shortName: 'TLV',
  },
  XMIL: {
    name: 'XMIL',
    displayName: 'Italian Stock Exchange',
    region: regions.EMEA,
    currencyCode: 'EUR',
    countryCode: 'IT',
    countryDisplayName: 'Italy',
    shortName: 'BIT',
  },
  XTKS: {
    name: 'XTKS',
    displayName: 'Tokyo Stock Exchange',
    region: regions.APAC,
    currencyCode: 'JPY',
    countryCode: 'JP',
    countryDisplayName: 'Japan',
    shortName: 'TSE',
  },
  XAMM: {
    name: 'XAMM',
    displayName: 'Amman Stock Exchange',
    region: regions.EMEA,
    currencyCode: 'JOD',
    countryCode: 'JO',
    countryDisplayName: 'Jordan',
    shortName: 'ASE',
  },
  XNAI: {
    name: 'XNAI',
    displayName: 'Nairobi Stock Exchange',
    region: regions.EMEA,
    currencyCode: 'KES',
    countryCode: 'KE',
    countryDisplayName: 'Kenya',
    shortName: 'Nairobi',
  },
  XKUW: {
    name: 'XKUW',
    displayName: 'Kuwait Stock Exchange',
    region: regions.EMEA,
    currencyCode: 'KWD',
    countryCode: 'KW',
    countryDisplayName: 'Kuwait',
    shortName: 'Kuwait',
  },
  XLUX: {
    name: 'XLUX',
    displayName: 'Luxembourg Stock Exchange',
    region: regions.EMEA,
    currencyCode: 'EUR',
    countryCode: 'LU',
    countryDisplayName: 'Luxembourg',
    shortName: 'XLUX',
  },
  XKLS: {
    name: 'XKLS',
    displayName: 'Bursa Malaysia',
    region: regions.APAC,
    currencyCode: 'MYR',
    countryCode: 'MY',
    countryDisplayName: 'Malaysia',
    shortName: 'KLSE',
  },
  XMEX: {
    name: 'XMEX',
    displayName: 'Mexican Stock Exchange',
    region: regions.LAMR,
    currencyCode: 'MXN',
    countryCode: 'MX',
    countryDisplayName: 'Mexico',
    shortName: 'BMV',
  },
  XCAS: {
    name: 'XCAS',
    displayName: 'Casablanca Stock Exchange',
    region: regions.EMEA,
    currencyCode: 'MAD',
    countryCode: 'MA',
    countryDisplayName: 'Morocco',
    shortName: 'BC',
  },
  XNZE: {
    name: 'XNZE',
    displayName: 'New Zealand Exchange',
    region: regions.APAC,
    currencyCode: 'NZD',
    countryCode: 'NZ',
    countryDisplayName: 'New Zealand',
    shortName: 'NZX',
  },
  XNSA: {
    name: 'XNSA',
    displayName: 'Nigerian Stock Exchange',
    region: regions.EMEA,
    currencyCode: 'NGN',
    countryCode: 'NG',
    countryDisplayName: 'Nigeria',
    shortName: 'Lagos',
  },
  XOSL: {
    name: 'XOSL',
    displayName: 'Oslo Stock Exchange',
    region: regions.EMEA,
    currencyCode: 'NOK',
    countryCode: 'NO',
    countryDisplayName: 'Norway',
    shortName: 'Oslo',
  },
  NOTC: {
    name: 'NOTC',
    displayName: 'Norwegian OTC Market',
    region: regions.EMEA,
    currencyCode: 'NOK',
    countryCode: 'NO',
    countryDisplayName: 'Norway',
    shortName: 'NOTC',
  },
  XMUS: {
    name: 'XMUS',
    displayName: 'Muscat Securities Market',
    region: regions.EMEA,
    currencyCode: 'OMR',
    countryCode: 'OM',
    countryDisplayName: 'Oman',
    shortName: 'Muscat',
  },
  XKAR: {
    name: 'XKAR',
    displayName: 'Karachi Stock Exchange',
    region: regions.APAC,
    currencyCode: 'PKR',
    countryCode: 'PK',
    countryDisplayName: 'Pakistan',
    shortName: 'Karachi',
  },
  XLIM: {
    name: 'XLIM',
    displayName: 'Lima Stock Exchange',
    region: regions.LAMR,
    currencyCode: 'PEN',
    countryCode: 'PE',
    countryDisplayName: 'Peru',
    shortName: 'BVL',
  },
  XPHS: {
    name: 'XPHS',
    displayName: 'Philippine Stock Exchange',
    region: regions.APAC,
    currencyCode: 'PHP',
    countryCode: 'PH',
    countryDisplayName: 'Philippines',
    shortName: 'PSE',
  },
  XWAR: {
    name: 'XWAR',
    displayName: 'Warsaw Stock Exchange',
    region: regions.EMEA,
    currencyCode: 'PLN',
    countryCode: 'PL',
    countryDisplayName: 'Poland',
    shortName: 'WSE',
  },
  XLIS: {
    name: 'XLIS',
    displayName: 'Euronext Lisbon',
    region: regions.EMEA,
    currencyCode: 'EUR',
    countryCode: 'PT',
    countryDisplayName: 'Portugal',
    shortName: 'Lisbon',
  },
  DSMD: {
    name: 'DSMD',
    displayName: 'Qatar Exchange',
    region: regions.EMEA,
    currencyCode: 'QAR',
    countryCode: 'QA',
    countryDisplayName: 'Qatar',
    shortName: 'Qatar',
  },
  XBSE: {
    name: 'XBSE',
    displayName: 'Bucharest Stock Exchange',
    region: regions.EMEA,
    currencyCode: 'RON',
    countryCode: 'RO',
    countryDisplayName: 'Romania',
    shortName: 'BVB',
  },
  MISX: {
    name: 'MISX',
    displayName: 'Moscow Exchange',
    region: regions.EMEA,
    currencyCode: 'RUB',
    countryCode: 'RU',
    countryDisplayName: 'Russia',
    shortName: 'MOEX',
  },
  XSAU: {
    name: 'XSAU',
    displayName: 'Saudi Stock Exchange',
    region: regions.EMEA,
    currencyCode: 'SAR',
    countryCode: 'SA',
    countryDisplayName: 'Saudi Arabia',
    shortName: 'Tadawul',
  },
  XBRV: {
    name: 'XBRV',
    displayName: 'BRVM - Regional Securities Exchange',
    region: regions.EMEA,
    currencyCode: 'XOF',
    countryCode: 'CI',
    countryDisplayName: 'Ivory Coast',
    shortName: 'BRVM',
  },
  XSES: {
    name: 'XSES',
    displayName: 'Singapore Exchange',
    region: regions.APAC,
    currencyCode: 'SGD',
    countryCode: 'SG',
    countryDisplayName: 'Singapore',
    shortName: 'SGX',
  },
  XLJU: {
    name: 'XLJU',
    displayName: 'Ljubljana Stock Exchange',
    region: regions.EMEA,
    currencyCode: 'EUR',
    countryCode: 'SI',
    countryDisplayName: 'Slovenia',
    shortName: 'LJSE',
  },
  XJSE: {
    name: 'XJSE',
    displayName: 'Johannesburg Stock Exchange',
    region: regions.EMEA,
    currencyCode: 'ZAR',
    countryCode: 'ZA',
    countryDisplayName: 'South Africa',
    shortName: 'JSE',
  },
  XKRX: {
    name: 'XKRX',
    displayName: 'Korea Exchange',
    region: regions.APAC,
    currencyCode: 'KRW',
    countryCode: 'KR',
    countryDisplayName: 'South Korea',
    shortName: 'KRX',
  },
  XMAD: {
    name: 'XMAD',
    displayName: 'Madrid Stock Exchange',
    region: regions.EMEA,
    currencyCode: 'EUR',
    countryCode: 'ES',
    countryDisplayName: 'Spain',
    shortName: 'BME',
  },
  XCOL: {
    name: 'XCOL',
    displayName: 'Colombo Stock Exchange',
    region: regions.APAC,
    currencyCode: 'LKR',
    countryCode: 'LK',
    countryDisplayName: 'Sri Lanka',
    shortName: 'Colombo',
  },
  XNGM: {
    name: 'XNGM',
    displayName: 'Nordic Growth Market',
    region: regions.EMEA,
    currencyCode: 'SEK',
    countryCode: 'SE',
    countryDisplayName: 'Sweden',
    shortName: 'NGM',
  },
  XSTO: {
    name: 'XSTO',
    displayName: 'Nasdaq OMX Stockholm',
    region: regions.EMEA,
    currencyCode: 'SEK',
    countryCode: 'SE',
    countryDisplayName: 'Sweden',
    shortName: 'STO',
  },
  XSWX: {
    name: 'XSWX',
    displayName: 'SIX Swiss Exchange',
    region: regions.EMEA,
    currencyCode: 'CHF',
    countryCode: 'CH',
    countryDisplayName: 'Switzerland',
    shortName: 'SIX',
  },
  XDSE: {
    name: 'XDSE',
    displayName: 'Damascus Securities Exchange',
    region: regions.EMEA,
    currencyCode: 'SYP',
    countryCode: 'SY',
    countryDisplayName: 'Syria',
    shortName: 'DSE',
  },
  XTAI: {
    name: 'XTAI',
    displayName: 'Taiwan Stock Exchange',
    region: regions.APAC,
    currencyCode: 'TWD',
    countryCode: 'TW',
    countryDisplayName: 'Taiwan',
    shortName: 'TWSE',
  },
  XBKK: {
    name: 'XBKK',
    displayName: 'Stock Exchange Of Thailand',
    region: regions.APAC,
    currencyCode: 'THB',
    countryCode: 'TH',
    countryDisplayName: 'Thailand',
    shortName: 'SET',
  },
  XAMS: {
    name: 'XAMS',
    displayName: 'Euronext Amsterdam',
    region: regions.EMEA,
    currencyCode: 'EUR',
    countryCode: 'NL',
    countryDisplayName: 'The Netherlands',
    shortName: 'AMS',
  },
  XIST: {
    name: 'XIST',
    displayName: 'Istanbul Stock Exchange',
    region: regions.EMEA,
    currencyCode: 'TRY',
    countryCode: 'TR',
    countryDisplayName: 'Turkey',
    shortName: 'BIST',
  },
  XDFM: {
    name: 'XDFM',
    displayName: 'Dubai Financial Market',
    region: regions.EMEA,
    currencyCode: 'AED',
    countryCode: 'AE',
    countryDisplayName: 'United Arab Emirates',
    shortName: 'DFM',
  },
  DIFX: {
    name: 'DIFX',
    displayName: 'Nasdaq Dubai',
    region: regions.EMEA,
    currencyCode: 'AED',
    countryCode: 'AE',
    countryDisplayName: 'United Arab Emirates',
    shortName: 'NDXB',
  },
  XADS: {
    name: 'XADS',
    displayName: 'Abu Dhabi Securities Exchange',
    region: regions.EMEA,
    currencyCode: 'AED',
    countryCode: 'AE',
    countryDisplayName: 'United Arab Emirates',
    shortName: 'ADSM',
  },
  XLON: {
    name: 'XLON',
    displayName: 'London Stock Exchange',
    region: regions.EMEA,
    currencyCode: 'GBP',
    countryCode: 'GB',
    countryDisplayName: 'United Kingdom',
    shortName: 'LSE',
  },
  BATS: {
    name: 'BATS',
    displayName: 'BATS Global Markets',
    region: regions.NAMR,
    currencyCode: 'USD',
    countryCode: 'US',
    countryDisplayName: 'USA',
    shortName: 'BATS',
  },
  XSTC: {
    name: 'XSTC',
    displayName: 'Hochiminh Stock Exchange',
    region: regions.APAC,
    currencyCode: 'VND',
    countryCode: 'VN',
    countryDisplayName: 'Vietnam',
    shortName: 'HOSE',
  },
  XTAL: {
    name: 'XTAL',
    displayName: 'Nasdaq OMX Tallinn',
    region: regions.EMEA,
    currencyCode: 'EUR',
    countryCode: 'EE',
    countryDisplayName: 'Estonia',
    shortName: 'Tallinn',
  },
  NEXX: {
    name: 'NEXX',
    displayName: 'Aquis Stock Exchange',
    region: regions.EMEA,
    currencyCode: 'GBP',
    countryCode: 'GB',
    countryDisplayName: 'United Kingdom',
    shortName: 'AQSE',
  },
  HSTC: {
    name: 'HSTC',
    displayName: 'Hanoi Stock Exchange',
    region: regions.APAC,
    currencyCode: 'VND',
    countryCode: 'VN',
    countryDisplayName: 'Vietnam',
    shortName: 'HNX',
  },
};
