import { connect } from 'react-redux';

import { getFeatureToggles } from '../../../config/appSettings';
import { CalendarCategory } from '../../../types/domain/calendar/constants';
import { selectOfferings } from '../ducks';
import {
  Column,
  conversionPriceColumn,
  countryColumn,
  couponColumn,
  customSectorColumn,
  discountToLastColumn,
  firstTradeDateColumn,
  hasCornerstoneColumn,
  issuerFirmNameColumn,
  leftLeadColumn,
  marketCapAtPricingColumn,
  marketCapAtPricingLocalCurrColumn,
  offeringTypeColumn,
  offerPriceColumn,
  offerPriceLocalCurrColumn,
  offerTo1dayColumn,
  offerToCurrentColumn,
  premiumColumn,
  priceRangeLivePricedColumn,
  regionColumn,
  secondarySharesColumn,
  sectorColumn,
  sizeDollarsColumn,
  sizeLocalCurrColumn,
  sizeSharesColumn,
  tickerColumn,
  totalBookRunnersColumn,
  totalNonBookRunnersColumn,
} from '../model/offeringListColumns';
import BaseDatalabCalendar, { mapDispatchToProps } from './BaseDatalabCalendar';

const calendarCategory = CalendarCategory.PRICED;

export const columnsConfig: Column[] = [
  issuerFirmNameColumn,
  tickerColumn,
  regionColumn,
  countryColumn,
  sectorColumn,
  customSectorColumn,
  offeringTypeColumn,
  firstTradeDateColumn,
  sizeLocalCurrColumn,
  sizeDollarsColumn,
  sizeSharesColumn,
  marketCapAtPricingLocalCurrColumn,
  marketCapAtPricingColumn,
  couponColumn,
  premiumColumn,
  conversionPriceColumn,
  priceRangeLivePricedColumn,
  offerPriceLocalCurrColumn,
  offerPriceColumn,
  discountToLastColumn,
  offerTo1dayColumn,
  offerToCurrentColumn,
  leftLeadColumn,
  ...(getFeatureToggles().isCornerstoneInvestorInDLOn ? [hasCornerstoneColumn] : []),
  secondarySharesColumn,
  totalBookRunnersColumn,
  totalNonBookRunnersColumn,
];

export const staticColumns: Column[] = [issuerFirmNameColumn, tickerColumn];

export const defaultVisibleColumns = columnsConfig
  .filter(
    c => ![secondarySharesColumn, totalBookRunnersColumn, totalNonBookRunnersColumn].includes(c)
  )
  .map(c => c.field);

const mapStateToProps = state => ({
  offerings: selectOfferings(state, calendarCategory),
});

export class PricedOfferingsCalendarComponent extends BaseDatalabCalendar {
  constructor(props) {
    super(props);

    this.calendarCategory = calendarCategory;
    this.defaultGroupBy = 'firstTradeDate';
    this.defaultGroupByOrderType = 'descWithNullFirst';
    this.defaultOrderBy = 'issuerFirmName';
    this.defaultOrderByType = 'asc';
    this.columnsConfig = columnsConfig;
    this.staticColumns = staticColumns;
    this.state = {
      visibleColumns: defaultVisibleColumns,
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PricedOfferingsCalendarComponent);
