import React from 'react';

import { SignalRContext } from './SignalRContext';
import { SignalRCallbackByEvent, SignalREvent } from './SignalRContext.model';

export const useSignalR = <TEventType extends SignalREvent>(
  eventType: TEventType,
  callback: SignalRCallbackByEvent[TEventType]
) => {
  const { connectionsStore } = React.useContext(SignalRContext);

  React.useEffect(() => {
    if (!connectionsStore) {
      return;
    }

    const connectionStartPromise = connectionsStore.addListener(eventType, callback);

    return () => {
      connectionStartPromise.then(() => {
        connectionsStore.removeListener(eventType, callback);
      });
    };
  }, [eventType, callback, connectionsStore]);
};
