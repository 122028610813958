import React from 'react';

import Icon from '../../graphics/icon/Icon';
import { TreeGridColDef, TreeGridRowData } from './TreeGrid';
import {
  SCollapseButtonWrapper,
  STreeGridCellContent,
  StyledCollapseIconButton,
  StyledCollapseIconWrapper,
} from './TreeGridCell.styles';

type Props = {
  column: TreeGridColDef;
  row: TreeGridRowData;
  isCollapsible: boolean;
  isExpanded: boolean;
  hasChildren: boolean;
  onToggleRowCollapse: (heirarchyTarget: string | number) => void;
  path: string | number;
  ancestors: (string | number)[];
};

export const TreeGridHierarchyCell: React.FC<Props> = ({
  column,
  row,
  onToggleRowCollapse,
  path,
  ancestors,
  isCollapsible,
  isExpanded,
  hasChildren,
}) => {
  return (
    <td>
      <STreeGridCellContent tabCount={ancestors.length}>
        <SCollapseButtonWrapper>
          {hasChildren && (
            <React.Fragment>
              {isCollapsible ? (
                <StyledCollapseIconButton
                  icon={{ name: isExpanded ? 'chevron-down' : 'chevron-right' }}
                  onClick={() => onToggleRowCollapse(path)}
                />
              ) : (
                <StyledCollapseIconWrapper>
                  <Icon name={isExpanded ? 'chevron-down' : 'chevron-right'} />
                </StyledCollapseIconWrapper>
              )}
            </React.Fragment>
          )}
        </SCollapseButtonWrapper>
        {column.cellRendererFramework ? column.cellRendererFramework(row) : row[column.field]}
      </STreeGridCellContent>
    </td>
  );
};

export default TreeGridHierarchyCell;
