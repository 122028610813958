import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  RegularBreakpoints,
  TextField,
  Typography,
} from '@cmg/design-system';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';

import { UserNote } from '../../../../../../types/domain/offering/user-note';
import {
  cardContentStyle,
  cardHeaderStyle,
  cardHeight,
  cardTitleStyle,
  dividerStyle,
} from '../OfferingProfileCardItem';

export type Props = {
  onSaveUserNote?: (userNote: Partial<UserNote>) => void;
  userNote?: UserNote | null;
  cardSizes?: RegularBreakpoints;
};

const UserNotesWidget: React.FC<Props> = ({ userNote, onSaveUserNote, cardSizes }) => {
  const title = 'Private Notes';

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      note: userNote?.note ?? '',
    },
    onSubmit: value => {
      onSaveUserNote && onSaveUserNote(value);
    },
  });
  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight}>
        <Divider sx={dividerStyle} />
        <CardHeader
          title={
            <Typography variant="h3" sx={cardTitleStyle}>
              {title}
            </Typography>
          }
          aria-label={title}
          sx={cardHeaderStyle}
        />
        <CardContent sx={cardContentStyle}>
          <FormikProvider value={formik}>
            <Form>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    label="Notes"
                    name="note"
                    multiline
                    rows={4}
                    value={formik.values.note}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      formik.submitForm();
                    }}
                  >
                    Save Notes
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default UserNotesWidget;
