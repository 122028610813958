import { detailedDiff } from 'deep-object-diff';
import isEmpty from 'lodash/isEmpty';
import * as React from 'react';

export const defaultLogIdentifier = '*useDebugDependencyWatch* ';

/* eslint-disable no-console, no-restricted-syntax */

/**
 * Tracks when dependencies change, along with diff.
 * Useful for debugging hooks calling behavior.
 */
export function useDebugDependencyWatch(
  deps: object,
  logIdentifier: string = defaultLogIdentifier
) {
  const depsRef = React.useRef(deps);
  const identifierRef = React.useRef(logIdentifier);
  const [changes, setChanges] = React.useState<{}>({});

  React.useEffect(() => {
    console.info(identifierRef.current, 'mounting');

    return () => console.info(identifierRef.current, 'unmounting');
  }, []);

  React.useEffect(() => {
    identifierRef.current = logIdentifier;
  }, [logIdentifier]);

  React.useEffect(() => {
    const changedDeps = Object.entries(deps).reduce((ret, [name, dep]) => {
      if (dep !== depsRef.current[name]) {
        const diff: { added?: object; deleted?: object; updated?: object } = detailedDiff(
          depsRef.current[name],
          dep
        );
        const isDiffEmpty =
          isEmpty(diff.added) &&
          isEmpty(diff.deleted) &&
          typeof diff.updated === 'object' &&
          isEmpty(diff.updated);

        return {
          ...ret,
          [name]: {
            oldValue: depsRef.current[name],
            newValue: dep,
            diff: isDiffEmpty ? 'Ref change' : diff,
          },
        };
      }
      return ret;
    }, {});

    if (isEmpty(changedDeps)) {
      return;
    }

    setChanges(changedDeps);

    console.info(
      identifierRef.current,
      'deps changed:',
      Object.keys(changedDeps),
      'changes:',
      changedDeps
    );

    depsRef.current = deps;
  }, [deps]);

  return changes;
}
