import { AccessControl, useAuth } from '@cmg/auth';
import { ToastManager } from '@cmg/common';
import React, { useEffect, useState } from 'react';

import { useDecisionShowLiveAndFiled } from '../../../common/hooks/useDecisionShowLiveAndFiled';
import { getAppSettings } from '../../../config/appSettings';
import Notifications from '../../shared/notifications/Notifications';
import Spinner from '../../shared/spinner/Spinner';
import { SidebarState, SScreenWrapper, SWrapper } from './MainWrapper.styles';
import OrientationOverlay from './OrientationOverlay';
import SessionAboutToExpireModal from './SessionAboutToExpireModal';
import SidebarContainer from './sidebar/SidebarContainer';
import SidebarContext from './sidebar/SidebarContext';

type Props = {
  defaultCollapsed: boolean;
  showOrientationWarning: boolean;
  showSpinner: boolean;
  onFetchUserSettings: () => void;
  userSettingsLoaded: boolean;
};

/**
 *
 */
const getSidebarState = (isLoggedIn: boolean, collapsed: boolean): SidebarState => {
  if (!isLoggedIn) {
    return 'hidden';
  }

  return collapsed ? 'collapsed' : 'expanded';
};

/**
 * Handles the highest level of layout in the application. Nested within is navigation
 * and root level routes (via children).
 * Similar to AppLayout.tsx in identity-client-web
 */
const MainWrapper: React.FC<Props> = ({
  /* children should be the root application routes */
  children,
  showSpinner,
  onFetchUserSettings,
  defaultCollapsed,
  showOrientationWarning,
  userSettingsLoaded,
}) => {
  const appSettings = getAppSettings();
  const { isLoggedIn, userPermissions } = useAuth();
  const showLiveAndFiled = useDecisionShowLiveAndFiled();

  const [collapsed, setCollapsed] = useState<boolean>(defaultCollapsed);

  const sidebarState = getSidebarState(isLoggedIn, collapsed);

  useEffect(() => {
    if (isLoggedIn) {
      onFetchUserSettings();
    }
  }, [onFetchUserSettings, isLoggedIn]);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Spinner show={showSpinner} priority={1} fullScreen>
      <SidebarContext.Provider value={{ collapsed }}>
        <SWrapper>
          {showOrientationWarning && <OrientationOverlay />}
          {/* Only displays our navigation when the user is logged in */}
          <AccessControl>
            <SidebarContainer
              userPermissions={userPermissions}
              onCollapseChange={toggleCollapse}
              showLiveAndFiled={showLiveAndFiled}
            />
          </AccessControl>

          {/* Children should be rendered either if user is logged in and user settings are downloaded
        or when user is not logged in at all */}
          <SScreenWrapper sidebarState={sidebarState}>
            {((isLoggedIn && userSettingsLoaded) || !isLoggedIn) && children}
          </SScreenWrapper>

          <ToastManager />

          <AccessControl>
            {appSettings.features.isNotificationsOn && <Notifications />}
          </AccessControl>

          <SessionAboutToExpireModal />
        </SWrapper>
      </SidebarContext.Provider>
    </Spinner>
  );
};

export default MainWrapper;
