import { numericUtil, Popover } from '@cmg/common';
import React from 'react';

import PerformancePercents from '../../../common/components/format/performance-percents/PerformancePercents';

export type Props = { tooltipValue: number | null | undefined; value: number | null | undefined };

const PerformancePercentsRenderer: React.FC<Props> = ({ tooltipValue, value }) => (
  <Popover
    variant="TOOLTIP"
    placement="left"
    content={<span>{numericUtil.formatCurrency(tooltipValue)}</span>}
  >
    <span>
      <PerformancePercents value={value} />
    </span>
  </Popover>
);

export default PerformancePercentsRenderer;
