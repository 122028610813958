/**
 * Given the type of a property of a state tree and a key/value mapping of action types -> actions
 * it represents the shape and type of the action handlers that can be supplied to the
 * particular reducer.
 */
type ActionHandlers<TStateSlice, TActions> = {
  [ActionKey in keyof TActions]?: (
    curState: TStateSlice,
    action: TActions[ActionKey]
  ) => TStateSlice;
};

/**
 * Given a property of a state tree and a set of action handlers, creates a reducer for
 * that state tree property.
 * @param initialState
 * @param handlers Mapping of action types to individual action handler methods that process an action and return state
 */
export const createReducer = <TStateSlice, TActions>(
  initialState: TStateSlice,
  handlers: ActionHandlers<TStateSlice, TActions>
) => {
  return function reducer(state: TStateSlice = initialState, action): TStateSlice {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };
};

/**
 * Wraps the array of reducers in one reducer function. Useful when state prop can be updated by multiple actions.
 * @param reducers array of reducer functions that will be called in given order
 */
export const createReducerPipeline = <TState>(reducers: ((state: TState, action) => TState)[]) => {
  return (initialState: TState, action) => {
    return reducers.reduce((state, reducer) => reducer(state, action), initialState);
  };
};

export { useActions } from './useActions';
