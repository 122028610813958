import { createTestId } from '../../../common/selectors';

const create = (name: string) => createTestId(`xc-offering-setup-instruments-${name}`);

export const offeringSetupInstruments = create('instruments');

const createInstrumentsSelector = (name: string) => create(`instruments-${name}`);

export const offeringSetupInstrumentsAddNewButton = createInstrumentsSelector(
  'add-new-instrument-button'
);

export const offeringSetupInstrumentsAddNewForm =
  createInstrumentsSelector('add-new-instrument-form');
