import { RegularBreakpoints } from '@cmg/design-system';

import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import OfferingProfileCardItem from '../../shared/OfferingProfileCardItem';
import { useGetAdvisorsData } from '../AtmProfile.model';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
};

const AdvisersWidget: React.FC<Props> = ({ offeringProfile, cardSizes }) => {
  const advisers = useGetAdvisorsData(offeringProfile);

  return <OfferingProfileCardItem title="Advisors" cardSizes={cardSizes} rowData={advisers} />;
};

export default AdvisersWidget;
