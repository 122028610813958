import React from 'react';
import { useTheme } from 'styled-components/macro';

import IconButton from '../../../../components/buttons/button/IconButton';
import {
  createFormikField,
  FieldControlProps,
} from '../../../../components/form/formik/createFormikField';
import { FormControlEvents } from '../../../../components/form/types';
import TimePickerPopoverContent from './popover-content/TimePickerPopoverContent';
import { MeridiemTimeTuple } from './TimePicker.model';
import {
  SDivider,
  SInputWrapper,
  SMeridiem,
  StyledPopover,
  StyledReactTimePicker,
} from './TimePicker.styles';

type OwnProps = {
  className?: string;
  innerRef?: React.Ref<HTMLDivElement>;
};

export type Props = FieldControlProps<MeridiemTimeTuple, MeridiemTimeTuple> &
  FormControlEvents &
  OwnProps;

export const TimePickerComponent: React.FC<Props> = ({
  innerRef,
  className,
  value,
  onFocus,
  onBlur,
  onChange,
  hasError,
}) => {
  const theme = useTheme();
  const [hasFocus, setHasFocus] = React.useState<boolean>(false);

  const [time, period] = value ?? [null, null];

  const handleFocus = event => {
    setHasFocus(true);
    onFocus?.(event);
  };

  const handleBlur = event => {
    setHasFocus(false);
    onBlur?.(event);
  };

  const handleTimeChange = (nextTime: string | null) => {
    onChange?.([nextTime, period || 'AM']);
  };

  const handleClearValue = () => {
    onChange?.([null, null]);
  };

  return (
    <StyledPopover
      hideArrow
      placement="bottomLeft"
      trigger="click"
      content={
        <TimePickerPopoverContent
          time={time}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={onChange}
          period={period}
        />
      }
    >
      <SInputWrapper
        tabIndex={0}
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={innerRef}
        className={className}
        hasFocus={hasFocus}
        hasError={hasError}
      >
        <StyledReactTimePicker
          disableClock
          value={time!}
          onChange={nextValue => handleTimeChange(nextValue as string | null)}
          format="hh:mm"
          maxDetail="minute"
          minutePlaceholder="MM"
          hourPlaceholder="HH"
        />

        <SMeridiem>{period}</SMeridiem>

        <IconButton
          testId="clear-values"
          icon={{ name: 'times', variant: 'solid', color: theme.foundation.colors.secondary[300] }}
          onClick={handleClearValue}
        />

        <SDivider />

        <IconButton
          icon={{
            name: 'clock',
            variant: 'light',
            color: theme.foundation.colors.secondary[300],
          }}
        />
      </SInputWrapper>
    </StyledPopover>
  );
};

const TimePicker = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  return <TimePickerComponent innerRef={ref} {...props} />;
});
TimePicker.displayName = 'TextPicker';

export default TimePicker;

export const TimePickerField = createFormikField<
  MeridiemTimeTuple,
  MeridiemTimeTuple,
  HTMLInputElement,
  Props
>(TimePickerComponent);
