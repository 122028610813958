import { apiTypes, Column, PageError, Row } from '@cmg/common';
import { DesignSystemProvider } from '@cmg/design-system';
import { companyScreenTestId } from '@cmg/e2e-selectors';
import React from 'react';

import { getFeatureToggles } from '../../../config/appSettings';
import { CompanyLegacy } from '../../../types/domain/company/company';
import { isAtmOn, isInternationalOfferingsOn } from '../../datalab/model/utils';
import ScreenContent from '../../shared/layout/ScreenContent';
import ScreenHeader from '../../shared/layout/ScreenHeader';
import { getCurrencyFormat, getCurrencyFormatInMillions } from '../../shared/model/utils';
import { CompanyProfile_Issuer_FieldsFragment } from '../graphql/__generated__/CompanyProfile';
import CompanyInfo from './company-info/CompanyInfo';
import CompanyInfoLegacy from './company-info-legacy/CompanyInfo';
import CompanyOfferingsSummary from './company-offerings-summary/CompanyOfferingsSummary';
import CompanyOfferingsSummaryLegacy from './company-offerings-summary-legacy/CompanyOfferingsSummary';
import CompanySubHeader from './company-sub-header/CompanySubHeader';
import CompanySubHeaderLegacy from './company-sub-header-legacy/CompanySubHeader';
import { SContainer } from './CompanyScreen.styles';

export type Props = {
  company?: CompanyProfile_Issuer_FieldsFragment | null;
  companyLegacy?: CompanyLegacy | null;
  error: apiTypes.GenericServerError | null;
};

const profileBottomPadding = { paddingBottom: '100px' };

const CompanyScreen: React.FC<Props> = ({ company, companyLegacy, error }) => {
  const showAtm = isAtmOn();
  const showInternational = isInternationalOfferingsOn();
  const { isCompanyProfileUsingGraphqlOn } = getFeatureToggles();

  if (isCompanyProfileUsingGraphqlOn) {
    return (
      <DesignSystemProvider>
        <div data-test-id={companyScreenTestId.testId}>
          <ScreenHeader />
          <ScreenContent
            renderSubHeader={() =>
              company && (
                <CompanySubHeader
                  showInternational={showInternational}
                  company={company}
                  showAtm={showAtm}
                />
              )
            }
          >
            <SContainer>
              <Row>
                <Column xs={24}>
                  <CompanyInfo company={company} />
                </Column>
              </Row>
              <Row>
                <Column xs={24}>
                  <div style={profileBottomPadding}>
                    <CompanyOfferingsSummary
                      offerings={company?.offerings ?? []}
                      hasSplits={company?.hasSplits}
                    />
                  </div>
                </Column>
              </Row>
            </SContainer>
          </ScreenContent>
        </div>
      </DesignSystemProvider>
    );
  }

  const pricingCurrencyCode = companyLegacy?.stockProfile?.pricingCurrencyCode ?? 'USD';
  return (
    <div data-test-id={companyScreenTestId.testId}>
      <ScreenHeader />
      <ScreenContent
        renderSubHeader={() =>
          companyLegacy &&
          !error && (
            <CompanySubHeaderLegacy
              showInternational={showInternational}
              company={companyLegacy}
              pricingCurrencyCode={pricingCurrencyCode}
              showAtm={showAtm}
            />
          )
        }
      >
        {error && <PageError error={error} />}
        {companyLegacy && !error && (
          <SContainer>
            <Row>
              <Column xs={24}>
                <CompanyInfoLegacy
                  companyDelisted={companyLegacy.delisted}
                  companyProfile={companyLegacy.companyProfile || {}}
                  stockProfile={companyLegacy.stockProfile || {}}
                  getCurrencyFormat={value =>
                    getCurrencyFormat({ value, pricingCurrencyCode, showInternational })
                  }
                  getCurrencyFormatInMillions={value =>
                    getCurrencyFormatInMillions({
                      value,
                      pricingCurrencyCode,
                      showInternational,
                    })
                  }
                />
              </Column>
            </Row>
            <Row>
              <Column xs={24}>
                <CompanyOfferingsSummaryLegacy
                  offerings={companyLegacy.offeringList}
                  hasSplits={companyLegacy.hasSplits}
                  showIoIInfo={companyLegacy.showFundIoiInfo}
                  showInternational={showInternational}
                  showAtm={showAtm}
                />
              </Column>
            </Row>
          </SContainer>
        )}
      </ScreenContent>
    </div>
  );
};

export default CompanyScreen;
