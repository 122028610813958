import { createTestId } from '../../../../common/selectors';

const create = (name: string) =>
  createTestId(`xc-offering-side-bar-order-book-delivery-instruments-${name}`);

export const offeringSideBarDeliveryInstrumentsManagerFirmSelect = create('manager-firm-select');
export const offeringSideBarDeliveryInstrumentsManagerFirmOption = create('manager-firm-option');
export const offeringSideBarDeliveryInstrumentsInstrumentSplitInstrumentSelect = create(
  'manager-split-instrument-split-instrument-select'
);
export const offeringSideBarDeliveryInstrumentsAddInstrumentSplitButton = create(
  'add-instrument-split-button'
);
export const offeringSideBarDeliveryInstrumentsRemoveInstrumentSplitButton = create(
  'remove-instrument-split-button'
);
export const offeringSideBarDeliveryInstrumentsSaveButton = create('save-button');
export const offeringSideBarDeliveryInstrumentsEditButton = create('edit-button');
