import React from 'react';

export const useScript = (src: string): boolean[] => {
  const cachedSource = React.useRef<string | null>(null);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);

  React.useEffect(() => {
    // if cachedSource is not empty that means that the script
    // was already loaded, so no need to load again
    if (cachedSource.current) {
      setLoaded(true);
      setError(false);
    } else {
      cachedSource.current = src;

      // create script
      const script = document.createElement('script');
      script.src = src;
      script.async = true;

      const onScriptLoad = () => {
        setLoaded(true);
        setError(false);
      };

      const onScriptError = () => {
        cachedSource.current = null;
        script.remove();

        setLoaded(true);
        setError(true);
      };

      script.addEventListener('load', onScriptLoad);
      script.addEventListener('error', onScriptError);

      // append script to document body
      document.body.appendChild(script);

      // cleanup listeners
      return () => {
        script.removeEventListener('load', onScriptLoad);
        script.removeEventListener('error', onScriptError);
        script.remove();
      };
    }
  }, [src]);

  return [loaded, error];
};
