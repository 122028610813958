import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import type { FC } from 'react';
import React from 'react';

import type { SnackbarProviderProps } from './components/feedback/snackbar/SnackbarProvider';
import { SnackbarProvider } from './components/feedback/snackbar/SnackbarProvider';
import type { ThemeProviderProps } from './ThemeProvider';
import { ThemeProvider } from './ThemeProvider';

export type DesignSystemProviderProps = {
  readonly themeConfig?: ThemeProviderProps;
  readonly snackbarConfig?: SnackbarProviderProps;
};

export const DesignSystemProvider: FC<DesignSystemProviderProps> = ({
  children,
  snackbarConfig,
  themeConfig,
}) => {
  return (
    <ThemeProvider {...themeConfig}>
      <SnackbarProvider {...snackbarConfig}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>{children}</LocalizationProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};
