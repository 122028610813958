import { createTestId } from '../../common/selectors';

const create = (name: string) => createTestId(`offplatform-data-admin-grid-${name}`);

export const offPlatformDataAdminCreateOfferingButton = create('create-offering-button');

// Filters
export const offPlatformDataAdminToggleFilters = create('toggle-filters');
export const offPlatformDataAdminFilterOfferingType = create('filter-offering-type');
export const offPlatformDataAdminFilterStatus = create('filter-status');
export const offPlatformDataAdminFilterWorkflowStatus = create('filter-workflow-status');
export const offPlatformDataAdminFilterRegion = create('filter-region');
export const offPlatformDataAdminFilterCountryCode = create('filter-country-code');
export const offPlatformDataAdminFilterManagerName = create('filter-manager-name');
export const offPlatformDataAdminFilterIssuerName = create('filter-issuer-name');
export const offPlatformDataAdminFilterPricingDate = create('filter-pricing-date');

// Export child folder(s)
export * from './side-panel';
