import Box from '@mui/material/Box';
import type { ButtonProps } from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import type { StackProps } from '@mui/material/Stack';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import type { ReactElement } from 'react';
import React from 'react';

import type { TestProps } from '../../../types';

export type ActionPanelSectionProps = Readonly<{
  title: string;
  titleItems?: React.ReactNode;
  actions?: ReactElement<ButtonProps>[];
}> &
  TestProps &
  StackProps;

export const ActionPanelSection: React.FC<ActionPanelSectionProps> = ({
  title,
  titleItems,
  actions,
  children,
  ...rest
}) => {
  return (
    <Stack {...rest}>
      <Divider />
      <Stack direction="row" alignItems="center" height={t => t.spacing(6)} p={1} gap={1}>
        <Typography variant="h3">{title}</Typography>
        <Box flexGrow={1}>{titleItems}</Box>
        {actions}
      </Stack>
      <Stack p={1}>{children}</Stack>
    </Stack>
  );
};

export const ActionPanelSectionSkeleton: React.FC<TestProps> = ({ children, ...rest }) => {
  const theme = useTheme();

  return (
    <Stack {...rest} role="progressbar">
      <Divider />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        height={t => t.spacing(6)}
        p={1}
      >
        <Skeleton width={theme.spacing(32)} />
        <Skeleton width={theme.spacing(8)} />
      </Stack>
      <Stack px={1}>{children}</Stack>
    </Stack>
  );
};

export const ActionPanelSectionContentSkeleton: React.FC<TestProps> = props => {
  return (
    <Stack {...props} gap={2} role="progressbar">
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Stack>
  );
};
