import { Form } from 'formik';
import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components/macro';

import Icon from '../../graphics/icon/Icon';
import {
  CLOSE_TIMEOUT_MS,
  contentAndOverlayCss,
  SBody,
  SFooter,
  SLoadingWrapper,
  STitle,
  StyledIconButton,
} from './Modal.styles';

ReactModal.setAppElement('body');

export type Props = {
  className?: string;
  show: boolean;
  children: React.ReactNode;
  title?: React.ReactNode;
  footer?: React.ReactNode;
  size?: 'small' | 'large';
  closeButton?: boolean;
  onHide?: () => void;
  isForm?: boolean; // wraps modal with <form>
  isLoading?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  testId?: string;
};

export const ModalAdapter: React.FC<Props> = ({
  show,
  children,
  onHide,
  className,
  title,
  footer,
  closeButton,
  isForm,
  isLoading,
  shouldCloseOnOverlayClick = true,
  testId,
}) => {
  const overlayClass = `${className}__overlay`;

  const content = isLoading ? (
    <SLoadingWrapper>
      <Icon name="spinner-third" spin />
    </SLoadingWrapper>
  ) : (
    <React.Fragment>
      {closeButton && <StyledIconButton icon={{ name: 'times' }} onClick={onHide} />}
      {title && <STitle>{title}</STitle>}
      <SBody>{children}</SBody>
      {footer && <SFooter>{footer}</SFooter>}
    </React.Fragment>
  );

  return (
    <ReactModal
      isOpen={show}
      onRequestClose={onHide}
      className={className}
      overlayClassName={overlayClass}
      closeTimeoutMS={CLOSE_TIMEOUT_MS}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      {isForm ? (
        <Form data-test-id={testId}>{content}</Form>
      ) : (
        <div data-test-id={testId}>{content}</div>
      )}
    </ReactModal>
  );
};

/**
 * styling using styled-components needs extra wrapper & two pseudo-classes
 * source: https://github.com/reactjs/react-modal/issues/603#issuecomment-378847885
 *
 * @see https://bitbucket.org/capitalmarketsgateway/ecm_client_web/src/dev/docs/Styling.md#markdown-header-styling-react-portal
 */
const Modal = styled(ModalAdapter)`
  ${contentAndOverlayCss}
`;

export default Modal;
