import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import type { DialogProps } from '@mui/material/Dialog';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import React, { Fragment, useCallback, useState } from 'react';

import { LoadingButton } from '../../../forms/buttons/LoadingButton';
import { Checkbox } from '../../../forms/checkbox/Checkbox';
import useIntervalProgress from './useIntervalProgress';

const noop = () => undefined;

const formBoxStyles = { marginTop: '10px' };
const progressBoxStyles = { flexGrow: 1, marginRight: '40px' };
const progressLabelBoxStyles = { marginBottom: '8px' };
const actionButtonStyles = { marginLeft: '8px' };

export type CriticalActionDialogProps = DialogProps & {
  checkboxLabelText?: React.ReactNode;
  actionButtonText?: string;
  alertText?: string;
  cancelButtonText?: string;
  progressBarLabelText?: string;
  confirmTimeAsSeconds?: number;
  handleClose?: () => void;
  onSubmit?: () => void;
  isSubmitting?: boolean;
};

export const CriticalActionDialog: React.FC<CriticalActionDialogProps> = ({
  progressBarLabelText = 'Please double check the information above',
  checkboxLabelText = 'I understand',
  actionButtonText = 'Done',
  title = 'Confirmation Modal',
  alertText = 'This action cannot be undone!',
  cancelButtonText = 'Cancel',
  handleClose = noop,
  onSubmit = noop,
  confirmTimeAsSeconds = 5,
  isSubmitting = false,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const { progress, startInterval, stopInterval } = useIntervalProgress(
    confirmTimeAsSeconds * 1000,
    200 // LinearProgress animation takes 200 ms
  );

  const showProgress = isChecked && progress !== 100;
  const isButtonDisabled = !isChecked || progress !== 100 || isSubmitting;

  const onCheck = useCallback(
    (checked: boolean) => {
      setIsChecked(checked);

      if (checked) {
        startInterval();
      } else {
        stopInterval();
      }
    },
    [startInterval, stopInterval]
  );

  const onClose = useCallback(() => {
    stopInterval();
    setIsChecked(false);
    handleClose();
  }, [handleClose, stopInterval]);

  return (
    <Dialog {...props} maxWidth="md" fullWidth onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <Alert severity="warning">{alertText}</Alert>
        <Box sx={formBoxStyles}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={isChecked} onChange={event => onCheck(event.target.checked)} />
              }
              label={checkboxLabelText}
            />
          </FormGroup>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={progressBoxStyles}>
          {showProgress && (
            <Fragment>
              <Box sx={progressLabelBoxStyles}>
                <Typography variant="body2">{progressBarLabelText}</Typography>
              </Box>
              <LinearProgress variant="determinate" value={progress} />
            </Fragment>
          )}
        </Box>
        <Button variant="outlined" onClick={onClose} disabled={isSubmitting}>
          {cancelButtonText}
        </Button>
        <LoadingButton
          sx={actionButtonStyles}
          variant="contained"
          onClick={onSubmit}
          disabled={isButtonDisabled}
          loading={isSubmitting}
        >
          {actionButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
