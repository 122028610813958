import { regions } from './exchangeMicLabels';

export const countriesList = {
  Usa: { countryCode: 'US', countryDisplayName: 'USA', region: regions.NAMR, displayOrder: 0 },
  Argentina: {
    countryCode: 'AR',
    countryDisplayName: 'Argentina',
    region: regions.LAMR,
    displayOrder: 1,
  },
  Australia: {
    countryCode: 'AU',
    countryDisplayName: 'Australia',
    region: regions.APAC,
    displayOrder: 2,
  },
  Austria: {
    countryCode: 'AT',
    countryDisplayName: 'Austria',
    region: regions.EMEA,
    displayOrder: 3,
  },
  Bahrain: {
    countryCode: 'BH',
    countryDisplayName: 'Bahrain',
    region: regions.EMEA,
    displayOrder: 4,
  },
  Bangladesh: {
    countryCode: 'BD',
    countryDisplayName: 'Bangladesh',
    region: regions.APAC,
    displayOrder: 5,
  },
  Belgium: {
    countryCode: 'BE',
    countryDisplayName: 'Belgium',
    region: regions.EMEA,
    displayOrder: 6,
  },
  Brazil: {
    countryCode: 'BR',
    countryDisplayName: 'Brazil',
    region: regions.LAMR,
    displayOrder: 7,
  },
  Canada: {
    countryCode: 'CA',
    countryDisplayName: 'Canada',
    region: regions.NAMR,
    displayOrder: 8,
  },
  Chile: { countryCode: 'CL', countryDisplayName: 'Chile', region: regions.LAMR, displayOrder: 9 },
  China: { countryCode: 'CN', countryDisplayName: 'China', region: regions.APAC, displayOrder: 10 },
  Colombia: {
    countryCode: 'CO',
    countryDisplayName: 'Colombia',
    region: regions.LAMR,
    displayOrder: 11,
  },
  Croatia: {
    countryCode: 'HR',
    countryDisplayName: 'Croatia',
    region: regions.EMEA,
    displayOrder: 12,
  },
  Cyprus: {
    countryCode: 'CY',
    countryDisplayName: 'Cyprus',
    region: regions.EMEA,
    displayOrder: 13,
  },
  CzechRepublic: {
    countryCode: 'CZ',
    countryDisplayName: 'Czech Republic',
    region: regions.EMEA,
    displayOrder: 14,
  },
  Denmark: {
    countryCode: 'DK',
    countryDisplayName: 'Denmark',
    region: regions.EMEA,
    displayOrder: 15,
  },
  Egypt: { countryCode: 'EG', countryDisplayName: 'Egypt', region: regions.EMEA, displayOrder: 16 },
  Finland: {
    countryCode: 'FI',
    countryDisplayName: 'Finland',
    region: regions.EMEA,
    displayOrder: 17,
  },
  France: {
    countryCode: 'FR',
    countryDisplayName: 'France',
    region: regions.EMEA,
    displayOrder: 18,
  },
  Germany: {
    countryCode: 'DE',
    countryDisplayName: 'Germany',
    region: regions.EMEA,
    displayOrder: 19,
  },
  Greece: {
    countryCode: 'GR',
    countryDisplayName: 'Greece',
    region: regions.EMEA,
    displayOrder: 20,
  },
  HongKong: {
    countryCode: 'HK',
    countryDisplayName: 'Hong Kong',
    region: regions.APAC,
    displayOrder: 21,
  },
  Hungary: {
    countryCode: 'HU',
    countryDisplayName: 'Hungary',
    region: regions.EMEA,
    displayOrder: 22,
  },
  Iceland: {
    countryCode: 'IS',
    countryDisplayName: 'Iceland',
    region: regions.EMEA,
    displayOrder: 23,
  },
  India: { countryCode: 'IN', countryDisplayName: 'India', region: regions.APAC, displayOrder: 24 },
  Indonesia: {
    countryCode: 'ID',
    countryDisplayName: 'Indonesia',
    region: regions.APAC,
    displayOrder: 25,
  },
  Ireland: {
    countryCode: 'IE',
    countryDisplayName: 'Ireland',
    region: regions.EMEA,
    displayOrder: 26,
  },
  Israel: {
    countryCode: 'IL',
    countryDisplayName: 'Israel',
    region: regions.EMEA,
    displayOrder: 27,
  },
  Italy: { countryCode: 'IT', countryDisplayName: 'Italy', region: regions.EMEA, displayOrder: 28 },
  Japan: { countryCode: 'JP', countryDisplayName: 'Japan', region: regions.APAC, displayOrder: 29 },
  Jordan: {
    countryCode: 'JO',
    countryDisplayName: 'Jordan',
    region: regions.EMEA,
    displayOrder: 30,
  },
  Kenya: { countryCode: 'KE', countryDisplayName: 'Kenya', region: regions.EMEA, displayOrder: 31 },
  Kuwait: {
    countryCode: 'KW',
    countryDisplayName: 'Kuwait',
    region: regions.EMEA,
    displayOrder: 32,
  },
  Luxembourg: {
    countryCode: 'LU',
    countryDisplayName: 'Luxembourg',
    region: regions.EMEA,
    displayOrder: 33,
  },
  Malaysia: {
    countryCode: 'MY',
    countryDisplayName: 'Malaysia',
    region: regions.APAC,
    displayOrder: 34,
  },
  Mexico: {
    countryCode: 'MX',
    countryDisplayName: 'Mexico',
    region: regions.LAMR,
    displayOrder: 35,
  },
  Morocco: {
    countryCode: 'MA',
    countryDisplayName: 'Morocco',
    region: regions.EMEA,
    displayOrder: 36,
  },
  NewZealand: {
    countryCode: 'NZ',
    countryDisplayName: 'New Zealand',
    region: regions.APAC,
    displayOrder: 37,
  },
  Nigeria: {
    countryCode: 'NG',
    countryDisplayName: 'Nigeria',
    region: regions.EMEA,
    displayOrder: 38,
  },
  Norway: {
    countryCode: 'NO',
    countryDisplayName: 'Norway',
    region: regions.EMEA,
    displayOrder: 39,
  },
  Oman: { countryCode: 'OM', countryDisplayName: 'Oman', region: regions.EMEA, displayOrder: 40 },
  Pakistan: {
    countryCode: 'PK',
    countryDisplayName: 'Pakistan',
    region: regions.APAC,
    displayOrder: 41,
  },
  Peru: { countryCode: 'PE', countryDisplayName: 'Peru', region: regions.LAMR, displayOrder: 42 },
  Philippines: {
    countryCode: 'PH',
    countryDisplayName: 'Philippines',
    region: regions.APAC,
    displayOrder: 43,
  },
  Poland: {
    countryCode: 'PL',
    countryDisplayName: 'Poland',
    region: regions.EMEA,
    displayOrder: 44,
  },
  Portugal: {
    countryCode: 'PT',
    countryDisplayName: 'Portugal',
    region: regions.EMEA,
    displayOrder: 45,
  },
  Qatar: { countryCode: 'QA', countryDisplayName: 'Qatar', region: regions.EMEA, displayOrder: 46 },
  Romania: {
    countryCode: 'RO',
    countryDisplayName: 'Romania',
    region: regions.EMEA,
    displayOrder: 47,
  },
  Russia: {
    countryCode: 'RU',
    countryDisplayName: 'Russia',
    region: regions.EMEA,
    displayOrder: 48,
  },
  SaudiArabia: {
    countryCode: 'SA',
    countryDisplayName: 'Saudi Arabia',
    region: regions.EMEA,
    displayOrder: 49,
  },
  IvoryCoast: {
    countryCode: 'CI',
    countryDisplayName: 'Ivory Coast',
    region: regions.EMEA,
    displayOrder: 50,
  },
  Singapore: {
    countryCode: 'SG',
    countryDisplayName: 'Singapore',
    region: regions.APAC,
    displayOrder: 51,
  },
  Slovenia: {
    countryCode: 'SI',
    countryDisplayName: 'Slovenia',
    region: regions.EMEA,
    displayOrder: 52,
  },
  SouthAfrica: {
    countryCode: 'ZA',
    countryDisplayName: 'South Africa',
    region: regions.EMEA,
    displayOrder: 53,
  },
  SouthKorea: {
    countryCode: 'KR',
    countryDisplayName: 'South Korea',
    region: regions.APAC,
    displayOrder: 54,
  },
  Spain: { countryCode: 'ES', countryDisplayName: 'Spain', region: regions.EMEA, displayOrder: 55 },
  SriLanka: {
    countryCode: 'LK',
    countryDisplayName: 'Sri Lanka',
    region: regions.APAC,
    displayOrder: 56,
  },
  Sweden: {
    countryCode: 'SE',
    countryDisplayName: 'Sweden',
    region: regions.EMEA,
    displayOrder: 57,
  },
  Switzerland: {
    countryCode: 'CH',
    countryDisplayName: 'Switzerland',
    region: regions.EMEA,
    displayOrder: 58,
  },
  Syria: { countryCode: 'SY', countryDisplayName: 'Syria', region: regions.EMEA, displayOrder: 59 },
  Taiwan: {
    countryCode: 'TW',
    countryDisplayName: 'Taiwan',
    region: regions.APAC,
    displayOrder: 60,
  },
  Thailand: {
    countryCode: 'TH',
    countryDisplayName: 'Thailand',
    region: regions.APAC,
    displayOrder: 61,
  },
  TheNetherlands: {
    countryCode: 'NL',
    countryDisplayName: 'The Netherlands',
    region: regions.EMEA,
    displayOrder: 62,
  },
  Turkey: {
    countryCode: 'TR',
    countryDisplayName: 'Turkey',
    region: regions.EMEA,
    displayOrder: 63,
  },
  UnitedArabEmirates: {
    countryCode: 'AE',
    countryDisplayName: 'United Arab Emirates',
    region: regions.EMEA,
    displayOrder: 64,
  },
  UnitedKingdom: {
    countryCode: 'GB',
    countryDisplayName: 'United Kingdom',
    region: regions.EMEA,
    displayOrder: 65,
  },
  Vietnam: {
    countryCode: 'VN',
    countryDisplayName: 'Vietnam',
    region: regions.APAC,
    displayOrder: 66,
  },
  Estonia: {
    countryCode: 'EE',
    countryDisplayName: 'Estonia',
    region: regions.EMEA,
    displayOrder: 67,
  },
};

export const countryNamesGroupedByRegion = Object.values(countriesList).reduce((acc, country) => {
  const { countryCode, countryDisplayName, region } = country;
  if (!acc[region]) {
    acc[region] = [];
  }
  acc[region].push({
    title: countryDisplayName,
    value: countryCode,
  });
  return acc;
}, {});

export const regionAndCountryTreeSelectSchema = Object.keys(countryNamesGroupedByRegion).map(
  region => {
    return {
      title: region,
      value: countryNamesGroupedByRegion[region]?.map(country => country.title).toString(),
      children: countryNamesGroupedByRegion[region]?.map(country => {
        return {
          title: country.title,
          value: country.title,
        };
      }),
    };
  }
);
