import { setNestedObjectValues, useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';

/**
 * Highlight potential validation errors in related Formik form.
 * Calls given onSubmit handler when no validation errors found.
 */
export const useValidateFormOnSubmit = (onSubmit: () => void) => {
  const { validateForm, setTouched } = useFormikContext();

  return async () => {
    const errors = await validateForm();

    if (isEmpty(errors)) {
      onSubmit();
    } else {
      setTouched(setNestedObjectValues(errors, true));
    }
  };
};
