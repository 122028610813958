import { Link } from 'react-router-dom';

import routeFactory from '../../../../../common/util/routeFactory';
import { getFeatureToggles } from '../../../../../config/appSettings';
import { CompanyOffering } from '../../../../../types/domain/company/companyOffering';
import {
  InternationalOfferingTypeLabels,
  OfferingType,
  offeringTypeLabels,
} from '../../../../../types/domain/offering/constants';
import { isInternationalOfferingTypesOn } from '../../../../datalab/model/utils';

type Props = {
  offering: CompanyOffering;
};

const OfferingTypeCell: React.FC<Props> = ({ offering }) => {
  const url = routeFactory.offerings.getUrlPath({ id: offering.offeringId });

  const hideLink =
    getFeatureToggles().isRestrictFiledUSOfferingsInDLOn &&
    offering.countryCode === 'US' &&
    offering.status === 'Filed' &&
    offering.type !== OfferingType.CONVERTIBLE &&
    offering.type !== OfferingType.ATM;

  const offeringTypeLabel =
    offering.pricingCurrencyCode !== 'USD' && isInternationalOfferingTypesOn()
      ? InternationalOfferingTypeLabels[offering.type]
      : offeringTypeLabels[offering.type];
  return (
    <td>
      {hideLink ? <span>{offeringTypeLabel}</span> : <Link to={url}>{offeringTypeLabel}</Link>}
    </td>
  );
};

export default OfferingTypeCell;
