import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';

import type { TestProps } from '../../../types';

const StyledName = styled(Typography, {
  name: 'HighlightsItem',
  slot: 'Name',
  overridesResolver: (_props, styles) => [styles.name],
})({});

const StyledValue = styled('div', {
  name: 'HighlightsItem',
  slot: 'Value',
  overridesResolver: (_props, styles) => [styles.value],
})({});

export type HighlightsItemProps = Readonly<
  TestProps & {
    /**
     * Name of the property
     */
    name: Readonly<string>;
    /**
     * Value of the property
     */
    value: Readonly<React.ReactNode>;
  }
>;

export function HighlightsItem({ name, value, ...restProps }: HighlightsItemProps): JSX.Element {
  return (
    <Stack {...restProps} alignItems="baseline" direction="row" gap={1} role="row">
      <StyledName role="rowheader">{name}</StyledName>
      <StyledValue role="cell">{value}</StyledValue>
    </Stack>
  );
}
