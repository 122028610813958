import { datalabApi } from '@cmg/api';
import { Icon } from '@cmg/common';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components/macro';

import { UUID } from '../../../../../types/common';
import { deleteSector, deleteSubSector } from '../../ducks';
import SectorEdit from './SectorEdit';
import { SButton, SectorDeleteButton, SectorTrashIconButton } from './styled';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ deleteSector, deleteSubSector }, dispatch),
});

export const SText = styled.span<{ crossed?: boolean }>`
  font-weight: ${({ theme }) => theme.text.weight.medium};
  text-decoration: ${({ crossed }) => (crossed ? `line-through` : `none`)};
`;
export const SScream = styled.span`
  color: ${({ theme }) => theme.text.color.error};
  margin: 0 10px;
`;

export const SSectorRowWrapper = styled.div`
  display: flex;
  align-items: center;
`;

type OwnProps = {
  parentId?: UUID;
  sector: datalabApi.CustomSectorDto | datalabApi.CustomSubSectorDto;
  canUpdate: boolean;
};

export type Props = OwnProps & ReturnType<typeof mapDispatchToProps>;

export const SectorRow = ({ sector, actions, canUpdate, parentId }: Props) => {
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleDelete = () => {
    if (parentId) {
      actions.deleteSubSector({ sectorId: sector.sectorId, parentId });
    } else {
      actions.deleteSector({ sectorId: sector.sectorId });
    }

    setDeleting(false);
  };

  if (editing) {
    return (
      <SSectorRowWrapper>
        <SectorEdit sector={sector} parentId={parentId} onSaved={() => setEditing(false)} />
        <SButton onClick={() => setEditing(false)}>Cancel</SButton>
      </SSectorRowWrapper>
    );
  }

  if (deleting) {
    let error: string | undefined;
    const { assignedCompanies, subSectors = [] } = sector as datalabApi.CustomSectorDto;

    if (subSectors.length) {
      error = 'Please delete sub sectors first';
    }
    if (assignedCompanies) {
      error = `Cannot delete a ${
        parentId ? 'sub ' : ''
      }sector with assigned companies (${assignedCompanies})`;
    }

    return (
      <SSectorRowWrapper>
        <SText crossed>{sector.sectorName}</SText>
        {error ? (
          <SScream>{error}</SScream>
        ) : (
          <SectorDeleteButton onClick={handleDelete}>Confirm Delete</SectorDeleteButton>
        )}
        <SButton onClick={() => setDeleting(false)}>Cancel</SButton>
      </SSectorRowWrapper>
    );
  }

  return (
    <SSectorRowWrapper>
      <SText>{sector.sectorName}</SText> &nbsp;
      {canUpdate && (
        <React.Fragment>
          <SButton onClick={() => setEditing(true)}>
            <Icon name="edit" />
          </SButton>
          <SectorTrashIconButton onClick={() => setDeleting(true)}>
            <Icon name="trash-alt" />
          </SectorTrashIconButton>
        </React.Fragment>
      )}
    </SSectorRowWrapper>
  );
};

export default connect(null, mapDispatchToProps)(SectorRow);
