import { useCallback, useMemo } from 'react';

import { loggerUtil } from '../../util';
import { useSearchParams } from '../useSearchParams/useSearchParams';

/**
 * Hook for reading and writing JSON to the url, primarily used for filter state deep linking management
 * @param filtersKey URL search param that the filter settings will be stored under
 * @returns
 *  urlFilters: JS object parsed from the url
 *  updateUrlFilters: function to update the url to store with a new JS object
 */
export const useUrlFilterStorage = <TURLFilters = any>(filtersKey: string) => {
  const { searchParams, setSearchParam } = useSearchParams();

  const filtersJsonString = searchParams.get(filtersKey) ?? '{}';

  const urlFilters = useMemo<Partial<TURLFilters>>(() => {
    try {
      return JSON.parse(filtersJsonString);
    } catch {
      // we want to swallow the error for the user, but still know within our monitoring tool that malformed json was in the url
      loggerUtil.info('useUrlFilterStorage could not process filters supplied via the url', {
        filtersKey,
        filtersJsonString,
      });
      return {};
    }
  }, [filtersJsonString, filtersKey]);

  const updateUrlFilters = useCallback(
    (newFilters: Partial<TURLFilters>) => {
      setSearchParam(filtersKey, JSON.stringify(newFilters));
    },
    [filtersKey, setSearchParam]
  );

  return {
    // urlFilters is optionally typed, but the best we can guarantee pulling from the browser
    //  url is a Partial of the type.
    urlFilters,
    updateUrlFilters,
  };
};
