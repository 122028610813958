import { HighlightsTextItem } from '@cmg/design-system';

export const SectorHeader: React.FC<{
  sectorDisplayName?: string | null;
  subSectorDisplayName?: string | null;
}> = ({ sectorDisplayName, subSectorDisplayName }) => {
  if (sectorDisplayName && subSectorDisplayName) {
    return (
      <HighlightsTextItem
        name="SECTOR/SUB SECTOR"
        text={`${sectorDisplayName ?? ''} / ${subSectorDisplayName ?? ''}`}
      />
    );
  }
  if (sectorDisplayName) {
    return <HighlightsTextItem name="SECTOR" text={sectorDisplayName ?? ''} />;
  }
  return null;
};
