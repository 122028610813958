import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import type { SxProps, Theme } from '@mui/material/styles';
import { tabClasses } from '@mui/material/Tab';
import type { TabsProps as MUITabsProps } from '@mui/material/Tabs';
import MUITabs, { tabsClasses } from '@mui/material/Tabs';
import { tabScrollButtonClasses } from '@mui/material/TabScrollButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';

import type { TestProps } from '../../types';

type OwnProps = {
  actions?: {
    xs: React.ReactNode;
    md: React.ReactNode;
  };
  content?: React.ReactNode;
};

export type DomainNavProps = OwnProps &
  TestProps &
  Pick<MUITabsProps, 'value' | 'onChange' | 'role' | 'aria-roledescription' | 'aria-label'>;
const tabsStyle: SxProps<Theme> = theme => ({
  ...theme.components?.DomainNav?.styleOverrides?.root,
  [`& .${tabClasses.root}`]: {
    height: theme.components?.DomainNav?.styleOverrides?.root?.height,
    minHeight: theme.components?.DomainNav?.styleOverrides?.root?.minHeight,
  },
  [`& .${tabsClasses.scrollButtons}.${tabScrollButtonClasses.disabled}`]: {
    opacity: 1, // Don't hide disabled buttons
    color: theme.palette.action.disabled,
  },
});

export const DomainNav: React.FC<DomainNavProps> = ({
  'data-test-id': testId,
  actions,
  content,
  children,
  ...tabsProps
}) => {
  const matchesMdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));

  return (
    <Box px={2} data-test-id={testId}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <MUITabs
          {...tabsProps}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile={true}
          sx={tabsStyle}
        >
          {children}
        </MUITabs>
        <Box>{content}</Box>
        {actions && <Box>{matchesMdUp ? actions.md : actions.xs}</Box>}
      </Stack>
      <Divider />
    </Box>
  );
};
