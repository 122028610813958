import { createTestId } from '../../../common/selectors';

const create = name => createTestId(`account-rolodex-${name}`);

export const accountRolodexScreen = create('screen');

const createAccountRemappingSelector = name => createTestId(`account-remapping-${name}`);
export const accountRemappingCloseButton = createAccountRemappingSelector('close-button');
export const accountRemappingBackButton = createAccountRemappingSelector('back-button');
export const accountRemappingConfirmButton = createAccountRemappingSelector('confirm-button');
