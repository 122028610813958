import FiberManualRecord from '@mui/icons-material/FiberManualRecord';
import { type SvgIconTypeMap } from '@mui/material';
import type { DefaultComponentProps } from '@mui/material/OverridableComponent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';

export type DotStatusProps = Pick<
  DefaultComponentProps<SvgIconTypeMap>,
  'color' | 'titleAccess'
> & {
  label?: Readonly<string>;
  secondaryLabel?: string;
};

export const DotStatus: React.FC<DotStatusProps> = ({
  color,
  label,
  secondaryLabel,
  titleAccess,
}) => {
  return (
    <Stack direction="column">
      <Stack direction="row" spacing={0.5} alignItems="center">
        <FiberManualRecord fontSize="inherit" color={color} titleAccess={titleAccess} />
        <Typography variant="body1" color={theme => theme.palette.text.secondary}>
          {label}
        </Typography>
      </Stack>
      {secondaryLabel && (
        <Typography
          color={theme => theme.palette.text.secondary}
          variant="caption"
          paddingLeft={theme => theme.spacing(2.25)}
        >
          {secondaryLabel}
        </Typography>
      )}
    </Stack>
  );
};
