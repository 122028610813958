import { loggerUtil, ToastManager, urlUtil } from '@cmg/common';
import { useCallback, useMemo } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import type { IIdleTimer } from 'react-idle-timer/dist/types/IIdleTimer';
import { setCookie } from 'react-use-cookie';

import type { IdleMonitorProps } from './types';
import {
  getIdleMessage,
  getIdleTimerEndStamp,
  getPromptBeforeIdle,
  isUserActiveOnOtherTab,
} from './utils';

export const useIdleMonitor = ({
  idleTimeout,
  rootDomain,
  secureCookies = true,
  onIdle,
  getUserContext,
}: IdleMonitorProps) => {
  const cookieName = `cmg_idle_${urlUtil.getAccountSubdomain(window.location)}`;

  const options = useMemo(
    () => ({
      timeout: idleTimeout * 60 * 1000,
      promptBeforeIdle: getPromptBeforeIdle(idleTimeout) * 60 * 1000,
    }),
    [idleTimeout]
  );

  const handleAction = useCallback(
    (_?: Event, idleTimer?: IIdleTimer) => {
      if (!idleTimer) {
        return;
      }

      if (idleTimer.isPrompted() || idleTimer.isIdle()) {
        idleTimer.activate();
      }

      const idleTimerEndStamp = getIdleTimerEndStamp(idleTimer);
      setCookie(cookieName, `${idleTimerEndStamp}`, {
        domain: `.${rootDomain}`,
        Secure: secureCookies,
      });
    },
    [cookieName, rootDomain, secureCookies]
  );

  const handlePrompt = useCallback(() => {
    loggerUtil.info('@cmg/auth: User Transitioned to Idle-Away', getUserContext?.());

    ToastManager.info(getIdleMessage(idleTimeout), {
      timeout: options.promptBeforeIdle, // keep toast up there until expiration
    });
  }, [getUserContext, idleTimeout, options.promptBeforeIdle]);

  const handleIdle = useCallback(
    (_?: Event, idleTimer?: IIdleTimer) => {
      if (idleTimer && isUserActiveOnOtherTab(idleTimer, cookieName)) {
        loggerUtil.info('@cmg/auth: User is active on another tab', getUserContext?.());
        idleTimer.activate();
      } else {
        loggerUtil.info('@cmg/auth: User Transitioned to Idle-Expired', getUserContext?.());
        onIdle();
      }
    },
    [cookieName, getUserContext, onIdle]
  );

  const handleActive = useCallback(() => {
    loggerUtil.info('@cmg/auth: Idle User Transitioned to Active', getUserContext?.());
  }, [getUserContext]);

  useIdleTimer({
    ...options,
    startOnMount: true,
    onActive: handleActive,
    onAction: handleAction,
    onPrompt: handlePrompt,
    onIdle: handleIdle,
    throttle: 5000,
    eventsThrottle: 1000,
  });
};
