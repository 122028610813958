import { SectorName } from './cmgSector';

/**
 * Mapping from a sector name (morningstar sectors) to display text.
 */
export const sectorNameDisplay: { [key in SectorName]: string } = {
  BASIC_MATERIALS: 'Basic Materials',
  CONSUMER_CYCLICAL: 'Consumer Cyclical',
  FINANCIAL_SERVICES: 'Financial Services',
  REAL_ESTATE: 'Real Estate',
  CONSUMER_DEFENSIVE: 'Consumer Defensive',
  HEALTHCARE: 'Healthcare',
  UTILITIES: 'Utilities',
  COMMUNICATION_SERVICES: 'Communication Services',
  ENERGY: 'Energy',
  INDUSTRIALS: 'Industrials',
  TECHNOLOGY: 'Technology',
  OTHER: 'Other',
};

/**
 * Formats a sector name based on the display mapping. If we don't have a matching
 * sector name, the display will be string passed in untouched.
 */
export const formatSectorName = (sectorName: string | null | undefined) => {
  if (!sectorName) {
    return '';
  }

  const displayName = sectorNameDisplay[sectorName];
  return displayName || sectorName;
};
