import { createTestId } from '../../../../common/selectors';

const create = (name: string) => createTestId(`xc-wires-multi-manager-wire-${name}`);

export const wiresSelectedMultiManagerWireStatusBanner = create('selected-wire-status-banner');

export const wiresMultiManagerWireEditModalCancelButton = create('edit-cancel-button');
export const wiresMultiManagerWireEditModalSaveButton = create('edit-save-button');

export const wiresMultiManagerWireEmptyWireEditButton = create('empty-wire-edit-button');

export const wiresMultiManagerWireSelectedWireDeleteButton = create('selected-wire-delete-button');
export const wiresMultiManagerWireSelectedWireCreateNewButton = create(
  'selected-wire-create-new-button'
);
export const wiresMultiManagerWireSelectedWireEditButton = create('selected-wire-edit-button');
export const wiresMultiManagerWireSelectedWireSendButton = create('selected-wire-send-button');
export const wiresMultiManagerWireSelectedWireTemplate = create('selected-wire-template');
