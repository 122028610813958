import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  RegularBreakpoints,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableGroupHeaderCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from '@cmg/design-system';
import React from 'react';

import { isFOOffering } from '../../../../../shared/model/utils';
import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import KeyValueItem from '../components/KeyValueItem';
import LabelWithTooltip from '../components/LabelWithTooltip';
import { useGetStructurePricingSectionData } from '../hooks/useGetStructurePricing';
import {
  cardContentStyle,
  cardHeaderStyle,
  cardHeight,
  cardTitleStyle,
  dividerStyle,
} from '../OfferingProfileCardItem';
import {
  StructurePricingRowLabels,
  StructurePricingTableHeader,
  StructurePricingTermName,
} from '../types';
import {
  bgColor,
  borderStyle,
  getBgColor,
  gridStyle,
  tableContainerStyle,
} from './StructurePricingWidget.styles';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
  id?: string;
};

const StructurePricingWidget: React.FC<Props> = ({ offeringProfile, cardSizes, id }) => {
  const isSmallScreen = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));
  const matchesMediumDown = useMediaQuery<Theme>(theme => theme.breakpoints.down(1770));
  const matchesSmallDown = useMediaQuery<Theme>(theme => theme.breakpoints.down(1200));
  const { structurePricingTable, structurePricingFOData, structurePricingRows } =
    useGetStructurePricingSectionData(offeringProfile, matchesMediumDown, matchesSmallDown);
  const { column1, column2, column3 } = structurePricingRows;

  const isFollowOn = React.useMemo(() => {
    return isFOOffering(offeringProfile?.offeringById?.type);
  }, [offeringProfile?.offeringById?.type]);

  const title = 'Structure/Pricing';

  // note the spacing need to cover item height including icons
  const keyValueItemHeight = name =>
    ![StructurePricingRowLabels.useOfProceeds, StructurePricingRowLabels.ADTVDateRange].includes(
      name
    )
      ? theme => theme.spacing(3.75)
      : undefined;

  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight} id={id}>
        <Divider sx={dividerStyle} />
        <CardHeader
          title={
            <Typography variant="h3" sx={cardTitleStyle}>
              {title}
            </Typography>
          }
          aria-label={title}
          sx={cardHeaderStyle}
        />
        <CardContent sx={cardContentStyle}>
          <Stack rowGap={2} display="flex">
            {isSmallScreen ? (
              <Stack divider={<Divider />}>
                {structurePricingTable.map(
                  ({
                    name,
                    grossProceeds,
                    offerPrice,
                    sizeInSecurities,
                    primary,
                    secondary,
                    ovltAuth,
                  }) => {
                    return (
                      <Stack paddingX={2} paddingY={1} sx={bgColor[name]} key={name}>
                        <Typography variant="highlight1" paddingBottom={0.5}>
                          {name}
                        </Typography>
                        <KeyValueItem
                          size="base"
                          label={
                            <LabelWithTooltip
                              label={StructurePricingTableHeader.grossProceeds}
                              tooltipText={
                                isFollowOn
                                  ? 'Gross Proceeds calculations all rely on final Offer Price upon pricing'
                                  : null
                              }
                            />
                          }
                          value={grossProceeds}
                        />
                        <KeyValueItem
                          size="base"
                          label={StructurePricingTableHeader.offerPriceRange}
                          value={offerPrice}
                        />
                        <KeyValueItem
                          size="base"
                          label={StructurePricingTableHeader.sizeInSecurities}
                          value={sizeInSecurities}
                        />
                        <KeyValueItem
                          size="base"
                          label={StructurePricingTableHeader.primary}
                          value={primary}
                        />
                        <KeyValueItem
                          size="base"
                          label={StructurePricingTableHeader.secondary}
                          value={secondary}
                        />
                        {name !== StructurePricingTermName.OvltExercised &&
                          name !== StructurePricingTermName.Total && (
                            <KeyValueItem
                              size="base"
                              label={StructurePricingTableHeader.ovltAuth}
                              value={ovltAuth}
                            />
                          )}
                      </Stack>
                    );
                  }
                )}
              </Stack>
            ) : (
              <Grid sx={gridStyle}>
                <TableContainer sx={tableContainerStyle}>
                  <Table size="medium">
                    <TableHead>
                      <TableRow color="text.secondary">
                        <TableGroupHeaderCell></TableGroupHeaderCell>
                        <TableCell align="right">
                          <LabelWithTooltip
                            label={StructurePricingTableHeader.grossProceeds}
                            tooltipText={
                              isFollowOn
                                ? 'Gross Proceeds calculations all rely on final Offer Price upon pricing'
                                : null
                            }
                            justifyContent="flex-end"
                          />
                        </TableCell>
                        <TableCell align="right">
                          {StructurePricingTableHeader.offerPriceRange}
                        </TableCell>
                        <TableCell align="right" sx={borderStyle}>
                          {StructurePricingTableHeader.sizeInSecurities}
                        </TableCell>
                        <TableCell align="right">{StructurePricingTableHeader.primary}</TableCell>
                        <TableCell align="right">{StructurePricingTableHeader.secondary}</TableCell>
                        <TableCell align="right">{StructurePricingTableHeader.ovltAuth}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {structurePricingTable.map(
                        ({
                          name,
                          grossProceeds,
                          offerPrice,
                          sizeInSecurities,
                          primary,
                          secondary,
                          ovltAuth,
                        }) => {
                          return (
                            <TableRow key={name} sx={getBgColor(name)}>
                              <TableCell width="13%">{name}</TableCell>
                              <TableCell align="right" width="14%">
                                {grossProceeds}
                              </TableCell>
                              <TableCell align="right" width="20%">
                                {offerPrice}
                              </TableCell>
                              <TableCell align="right" width="14%" sx={borderStyle}>
                                {sizeInSecurities}
                              </TableCell>
                              <TableCell align="right" width="13%">
                                {primary}
                              </TableCell>
                              <TableCell align="right" width="13%">
                                {secondary}
                              </TableCell>
                              <TableCell align="right" width="13%">
                                {ovltAuth}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
            {structurePricingFOData.length > 0 && (
              <React.Fragment>
                {isSmallScreen ? (
                  <Stack divider={<Divider />}>
                    {structurePricingFOData.map(({ name, value, value2 }) => {
                      return (
                        <Stack marginBottom={1} marginTop={1.5} key={name}>
                          <Typography variant="highlight1">{name}</Typography>
                          <KeyValueItem size="base" label="Price" value={value} />
                          <KeyValueItem size="base" label="Discount" value={value2} />
                        </Stack>
                      );
                    })}
                  </Stack>
                ) : (
                  <Grid item minWidth={theme => theme.spacing(46)} flex={1}>
                    <TableContainer>
                      <Table size="medium">
                        <TableHead>
                          <TableRow color="text.secondary">
                            <TableCell></TableCell>
                            <TableCell align="right">Price</TableCell>
                            <TableCell align="right">Discount</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {structurePricingFOData.map(({ name, value, value2 }) => {
                            return (
                              <TableRow key={name}>
                                <TableCell width="34%">{name}</TableCell>
                                <TableCell align="right" width="33%">
                                  {value}
                                </TableCell>
                                <TableCell align="right" width="33%">
                                  {value2}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}
              </React.Fragment>
            )}
            <Grid container columnSpacing={2} display="flex" justifyContent="space-between">
              <Grid item flex={1} key="col1">
                {column1.map(({ name, value }) => {
                  return (
                    <KeyValueItem
                      key={name}
                      size="large"
                      label={name}
                      value={value}
                      height={keyValueItemHeight(name)}
                    />
                  );
                })}
              </Grid>
              {column2.length > 0 && (
                <Grid item flex={1} key="col2">
                  {column2.map(({ name, value }) => {
                    return (
                      <KeyValueItem
                        key={name}
                        size="large"
                        label={name}
                        value={value}
                        height={keyValueItemHeight(name)}
                      />
                    );
                  })}
                </Grid>
              )}
              {column3.length > 0 && (
                <Grid item flex={1} key="col3">
                  {column3.map(({ name, value }) => {
                    return (
                      <KeyValueItem
                        key={name}
                        size="large"
                        label={name}
                        value={value}
                        height={keyValueItemHeight(name)}
                      />
                    );
                  })}
                </Grid>
              )}
            </Grid>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default StructurePricingWidget;
