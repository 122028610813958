import { createTestId } from '../../../../../common/selectors';

const create = (name: string) => createTestId(`xc-offering-side-panel-fund-level-demand-${name}`);

export const fundLevelDemandScreen = create('screen');

export const fundLevelDemandAddFundItemButton = create('fund-item-add-button');

export const fundLevelDemandEditFundItemDiscardButton = create('fund-item-edit-discard-button');

export const fundLevelDemandEditFundItemSaveButton = create('fund-item-edit-save-button');

export const fundLevelDemandDeleteFundItemConfirmNoButton = create(
  'fund-item-delete-confirm-no-button'
);

export const fundLevelDemandDeleteFundItemConfirmYesButton = create(
  'fund-item-delete-confirm-yes-button'
);

export const fundLevelDemandForm = create('form');

export const fundLevelDemandInterestLevel = create('interest-level');

export const fundLevelDemandInterestLevelAddButton = create('interest-level-add-button');

export const fundLevelDemandInterestLevelDeleteButton = create('interest-level-delete-button');

export const fundLevelDemandGrid = create('grid');

export const fundLevelDemandEditFundItemButton = create('fund-item-edit-button');

export const fundLevelDemandDeleteFundItemButton = create('fund-item-delete-button');
