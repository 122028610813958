import { createTestId } from '../../../../../common/selectors';

const create = (name: string) => createTestId(`xc-offering-side-panel-firm-level-demand-${name}`);

export const firmLevelDemandScreen = create('screen');

export const firmLevelDemandBarReActivateIndication = create('bar-re-activate-indication');

export const firmLevelDemandBarPassIndication = create('bar-pass-indication');

export const firmLevelDemandBarRegMIndication = create('bar-reg-m-indication');

export const firmLevelDemandBarEditIndication = create('bar-edit-indication');

export const firmLevelDemandBarDiscardChanges = create('bar-discard-changes');

export const firmLevelDemandBarSaveChanges = create('bar-save-changes');

export const firmLevelDemandForm = create('form');

export const firmLevelDemandInterestLevel = create('interest-level');

export const firmLevelDemandInterestLevelDelete = create('interest-level-delete');
