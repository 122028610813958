import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import React from 'react';

export type AppLayoutProps = Readonly<{
  /**
   * Top most component of the layout usually rendering AppBar
   */
  readonly header: React.ReactNode;
  /**
   * Component representing page of the application
   */
  readonly page: React.ReactNode;
}>;

const StyledStack = styled(Stack, {
  name: 'AppLayout',
  slot: 'Root',
  overridesResolver: (_props, styles) => [styles.root],
})({});

export const AppLayout: React.FC<AppLayoutProps> = ({ header, page, ...res }) => {
  return (
    <StyledStack>
      {header}
      {page}
    </StyledStack>
  );
};
