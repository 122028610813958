import { createTestId } from '../../../common/selectors';

const create = (name: string) => createTestId(`offplatform-admin-side-panel-${name}`);

export const offPlatformAdminSidePanelDealType = create('deal-type');
export const offPlatformAdminSidePanelIssuer = create('issuer');
export const offPlatformAdminSidePanelStatus = create('status');
export const offPlatformAdminSidePanelPublicFilingDate = create('public-filing-date');
export const offPlatformAdminSidePanelPricingDate = create('pricing-date');
export const offPlatformAdminSidePanelCurrency = create('currency');
export const offPlatformAdminSidePanelSecurityType = create('security-type');
export const offPlatformAdminSidePanelDepositaryReceipt = create('depositary-receipt');
export const offPlatformAdminSidePanelForwardAgreement = create('forward-agreement');
export const offPlatformAdminSidePanelSaveButton = create('save-button');
export const offPlatformAdminSidePanelCloseButton = create('close-button');
export const offPlatformAdminSidePanelApproveButton = create('approve-button');
export const offPlatformAdminSidePanelDeleteButton = create('delete-button');
