import React from 'react';

import { Comparator, OrderDirection, OrderedRow, OrderInfo } from './OrderableTable.model';

export const getOrderedRows = <TColumn extends string, TRow>({
  comparators,
  orderInfo,
  rows,
}: {
  comparators: { [column: string]: Comparator<TRow> };
  orderInfo: OrderInfo<TColumn>;
  rows: TRow[];
}): OrderedRow<TRow>[] => {
  const indexedRows = rows.map((row, originalRowIndex) => ({ ...row, originalRowIndex }));
  const comparator =
    orderInfo === 'unordered'
      ? undefined
      : comparators[orderInfo.column ?? '']?.(orderInfo.direction);

  if (!comparator) {
    return indexedRows;
  }

  return indexedRows.sort(comparator);
};

export type OrderableTableContextValue<TColumn, TRow> = {
  orderBy: OrderInfo<TColumn>;
  onOrder: (column: TColumn) => void;
  orderedRows: OrderedRow<TRow>[];
};

export function useOrderableTableState<TColumn extends string, TRow>({
  startingOrder,
  comparators,
  rows,
}: {
  startingOrder?: OrderInfo<TColumn>;
  comparators: {
    [column: string]: Comparator<TRow>;
  };
  rows: TRow[];
}): OrderableTableContextValue<TColumn, TRow> {
  const [orderBy, setOrderBy] = React.useState<OrderInfo<TColumn>>(startingOrder ?? 'unordered');

  const handleOrderBy = (columnToOrderBy: TColumn) => {
    if (!(columnToOrderBy in comparators)) {
      return;
    }

    setOrderBy(oldOrderInfo => {
      if (oldOrderInfo === 'unordered' || columnToOrderBy !== oldOrderInfo.column) {
        return { column: columnToOrderBy, direction: OrderDirection.ASC };
      }

      if (oldOrderInfo.direction === OrderDirection.DESC) {
        return 'unordered';
      }

      return { column: oldOrderInfo.column, direction: OrderDirection.DESC };
    });
  };

  const orderedRows = React.useMemo(
    () => getOrderedRows({ comparators, rows, orderInfo: orderBy }),
    [rows, orderBy, comparators]
  );

  return { orderBy, onOrder: handleOrderBy, orderedRows };
}
