import { CalendarCategory } from '../../../types/domain/calendar/constants';
import {
  Column,
  conditionalLockUpColumn,
  countryColumn,
  customSectorColumn,
  issuerFirmNameColumn,
  leftLeadColumn,
  lockUpExpirationDateColumn,
  marketCapAtPricingColumn,
  marketCapAtPricingLocalCurrColumn,
  multipleLockUpsColumn,
  offeringTypeColumn,
  offerPriceColumn,
  offerPriceLocalCurrColumn,
  offerToCurrentColumn,
  pricingDateColumn,
  regionColumn,
  secondarySharesColumn,
  sectorColumn,
  sellingRestrictionColumn,
  shareholderColumn,
  sizeDollarsColumn,
  sizeLocalCurrColumn,
  tickerColumn,
  totalBookRunnersColumn,
  totalNonBookRunnersColumn,
} from '../model/offeringListColumns';
import BaseDatalabCalendar, { TabProps } from './BaseDatalabCalendar';

const calendarCategory = CalendarCategory.LOCK_UP_EXPIRATION;

export const columnsConfig: Column[] = [
  issuerFirmNameColumn,
  tickerColumn,
  regionColumn,
  countryColumn,
  sellingRestrictionColumn,
  sectorColumn,
  customSectorColumn,
  offeringTypeColumn,
  lockUpExpirationDateColumn,
  conditionalLockUpColumn,
  multipleLockUpsColumn,
  pricingDateColumn,
  sizeLocalCurrColumn,
  sizeDollarsColumn,
  secondarySharesColumn,
  marketCapAtPricingLocalCurrColumn,
  marketCapAtPricingColumn,
  offerPriceLocalCurrColumn,
  offerPriceColumn,
  offerToCurrentColumn,
  leftLeadColumn,
  shareholderColumn,
  totalBookRunnersColumn,
  totalNonBookRunnersColumn,
];

export const staticColumns: Column[] = [issuerFirmNameColumn, tickerColumn];

export const defaultVisibleColumns = columnsConfig
  .filter(
    c =>
      ![
        secondarySharesColumn,
        totalBookRunnersColumn,
        totalNonBookRunnersColumn,
        conditionalLockUpColumn,
        multipleLockUpsColumn,
      ].includes(c)
  )
  .map(c => c.field);

export const tabConfig = {
  calendarCategory: calendarCategory,
  defaultGroupBy: 'attributes.lockUpExpirationDate',
  defaultGroupByOrderType: 'asc' as const,
  defaultOrderBy: 'issuer.name',
  defaultOrderByType: 'asc' as const,
  columnsConfig: columnsConfig,
  staticColumns: staticColumns,
  defaultVisibleColumns: defaultVisibleColumns,
};

const LockupExpirationsOfferingsCalendar = (props: TabProps) => {
  return <BaseDatalabCalendar {...props} {...tabConfig} />;
};

export default LockupExpirationsOfferingsCalendar;
