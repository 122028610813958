import type { GridApi } from 'ag-grid-community';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { useTheme } from 'styled-components/macro';

/**
 * Used when generating header rows, groupBy will create a key of 'null' string
 * when the value of the groupByField is null.
 *
 * This utility should be used to convert the string back into a null value or return the value if its not
 * a string of null
 */
const nullStringToValue = value => (value === 'null' ? null : value);

const defaultStringComparator: OrderComparatorFn<unknown, unknown> = (a, b, direction) => {
  const factor = direction === 'desc' ? -1 : 1;
  if (typeof a !== 'string') {
    return -1 * factor;
  }

  if (typeof b !== 'string') {
    return 1 * factor;
  }

  return (a?.localeCompare(b, undefined, { sensitivity: 'base' }) ?? -1) * factor;
};

export type OrderDirection = 'asc' | 'desc';
export type OrderComparatorFn<T, RowDataT = unknown> = (
  a: T,
  b: T,
  direction: OrderDirection | undefined,
  itemDataA?: RowDataT,
  itemDataB?: RowDataT
) => number;
export type OrderComparator<RowDataT = unknown> = Record<
  string,
  OrderComparatorFn<number> | OrderComparatorFn<unknown, RowDataT> | 'string'
>;

export type UseGroupingParams<TRow> = {
  /** rows data */
  sourceRows?: TRow[];
  /** column field to group by */
  groupByField: string;
  /** optional group header renderer function provided by the consumer and returns the group header element */
  groupHeaderRenderer?: (groupFieldValue: string, groupData: TRow[]) => React.ReactNode;
  /** group header order direction */
  groupHeaderOrderDirection?: 'asc' | 'desc';
  /**
   * group header comparator function that will be used as a custom array.sort
   * when provided it will be used instead of groupHeaderOrderDirection
   */
  groupHeaderComparator?: (lhs: string, rhs: string) => number;
  /** css style object to override/add styles to the group header */
  groupHeaderStyles?: Object;
  /** order field for grouped sets of data */
  orderField?: string | null;
  /** sort direction for grouped sets of data */
  orderDirection?: 'asc' | 'desc';
  /**
   * A custom map of fields with their respective sort function.
   * Defaults to lodash [orderBy](https://lodash.com/docs/#orderBy)
   */
  orderComparator?: OrderComparator<TRow>;
  gridApi?: GridApi;
};

export type GroupRow = {
  /** __groupHeader is required and used by isFullWidthCell in aggrid to determine when to render the group header row */
  __groupHeader: boolean;
  /** [groupByField]: groupByValue */
  [key: string]: any;
  /** required id for ag-grid to render the row */
  id: string;
  /** css style object to override/add styles to the group header */
  groupHeaderStyles: Object;
  /** renders the group header element provided by the consumer */
  renderGroupHeaderRow: () => React.ReactNode;
};

type GetSortedDataConfig<TRow> = {
  readonly colId?: string | null;
  readonly orderField?: string | null;
  readonly orderComparator?: OrderComparator<TRow>;
  readonly orderDirection?: 'asc' | 'desc';
  readonly gridApi?: GridApi;
};

const getRowValue = (
  gridApi: GridApi | undefined,
  item: unknown,
  columnId: string | undefined | null
) => {
  if (!gridApi || !item || !columnId) {
    return undefined;
  }

  const rowNode =
    gridApi != null && item['id'] != null ? gridApi?.getRowNode(item['id']) : undefined;
  const rowValue = rowNode && gridApi?.getValue(columnId, rowNode);
  return rowValue;
};

const getSortedData = <TRow extends unknown>(
  args: GetSortedDataConfig<TRow>,
  group: [TRow, ...TRow[]]
) => {
  const { colId, orderComparator, orderField, gridApi } = args;
  const comparator = orderField != null ? orderComparator?.[orderField] : undefined;
  if (!orderField) {
    return group;
  }

  return [...group].sort((itemA, itemB) => {
    const a = getRowValue(gridApi, itemA, colId) ?? itemA?.[orderField];
    const b = getRowValue(gridApi, itemB, colId) ?? itemB?.[orderField];
    if (typeof comparator === 'function') {
      return comparator(a, b, args.orderDirection, itemA, itemB);
    }

    return defaultStringComparator(a, b, args.orderDirection);
  });
};

const defaults = {
  sourceRows: [],
  groupHeaderStyles: {},
  groupHeaderRenderer: groupFieldValue => groupFieldValue,
};

/**
 * useGrouping takes source row data and grouping properties and returns a flat list of
 * the data with group headers.
 *
 * When useGrouping is used DataGridGroupingClient will leverage a fullWidthCellRenderer to render the group headers
 */
function useGrouping<TRow extends unknown>({
  sourceRows = defaults.sourceRows,
  groupByField,
  // when a renderer is not provided return the group field value
  groupHeaderRenderer = defaults.groupHeaderRenderer,
  groupHeaderOrderDirection = 'asc',
  groupHeaderComparator,
  groupHeaderStyles = defaults.groupHeaderStyles,
  orderField,
  orderDirection,
  orderComparator,
  gridApi,
}: UseGroupingParams<TRow>) {
  const theme = useTheme();

  return React.useMemo(() => {
    const groupedData = groupBy(sourceRows, groupByField);
    const groupKeys = Object.keys(groupedData);
    /**
     * sort the group headers
     * if groupHeaderComparator is defined we use it otherwise
     * groupHeaderOrderDirection will be used
     */
    const sortGroupHeaders = groupHeaderComparator
      ? groupKeys.sort(groupHeaderComparator)
      : orderBy(groupKeys, key => key, groupHeaderOrderDirection);

    // flatten all groups and add a group header row before each group
    // or a standard row
    return sortGroupHeaders.reduce<Array<GroupRow | TRow>>((flatList, currentGroupKey) => {
      // when order field exists we sort the grouped data by that field using the orderDirection
      const columnDef = gridApi?.getColumnDef(orderField ?? '');
      const sortedData = getSortedData(
        {
          colId: orderField,
          orderField: columnDef?.field ?? orderField,
          gridApi,
          orderComparator,
          orderDirection,
        },
        groupedData[currentGroupKey] as [TRow, ...TRow[]] // lodash requires a nonempty array
      );

      return [
        ...flatList,
        {
          // __groupHeader is required and used by isFullWidthCell in aggrid to determine when to render the group header row
          __groupHeader: true,
          [groupByField]: nullStringToValue(currentGroupKey),
          id: currentGroupKey,
          groupHeaderStyles: {
            backgroundColor: theme.background.color.light,
            ...groupHeaderStyles,
          },
          renderGroupHeaderRow: () =>
            groupHeaderRenderer(nullStringToValue(currentGroupKey), groupedData[currentGroupKey]),
        },
        ...sortedData,
      ];
    }, []);
  }, [
    sourceRows,
    groupByField,
    groupHeaderComparator,
    groupHeaderOrderDirection,
    gridApi,
    orderField,
    orderComparator,
    orderDirection,
    theme.background.color.light,
    groupHeaderStyles,
    groupHeaderRenderer,
  ]);
}

export default useGrouping;
