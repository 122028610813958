import { apiTypes, PageError } from '@cmg/common';
import { offeringProfileScreenSelector } from '@cmg/e2e-selectors';
import classNames from 'classnames';
import React from 'react';

import { OfferingType } from '../../../../types/domain/offering/constants';
import { Offering } from '../../../../types/domain/offering/offering';
import { UserNote } from '../../../../types/domain/offering/user-note';
import { isInternationalOfferingsOn } from '../../../datalab/model/utils';
import PrecedentTransactionsModal from '../../../shared/components/precedent-reports/PrecedentReportModal';
import ScreenContent from '../../../shared/layout/ScreenContent';
import ScreenHeader from '../../../shared/layout/ScreenHeader';
import OfferingSubHeader from '../../shared/components/OfferingSubHeader';
import OfferingProfile from './OfferingProfile';

type FilterValues = {
  offeringType: OfferingType;
  marketCapMin: number | undefined;
  marketCapMax: number | undefined;
  sectors: number[];
  customSectors: string[];
};

type Props = {
  error: apiTypes.GenericServerError | null;
  offering?: Offering;
  userNote?: UserNote | null;
  onSaveUserNote?: (userNote: string) => void;
  onPrecedentsFilter?: (values: FilterValues) => void;
};

export default class OfferingProfileScreen extends React.PureComponent<Props> {
  handleOnPrecedentReports = (offering: any) => {
    const marketCapMin = offering.pricing.marketCap
      ? Math.round((offering.pricing.marketCap / 1000000) * 0.5)
      : undefined;
    const marketCapMax = offering.pricing.marketCap
      ? Math.round((offering.pricing.marketCap / 1000000) * 1.5)
      : undefined;
    const sectors = offering.issuer.sectorId ? [offering.issuer.sectorId] : [];
    const customSectors = offering.issuer.customSector
      ? [offering.issuer.customSector.sectorId]
      : [];
    const { onPrecedentsFilter } = this.props;

    onPrecedentsFilter &&
      onPrecedentsFilter({
        offeringType: offering.type,
        marketCapMin,
        marketCapMax,
        sectors,
        customSectors,
      });
  };

  render() {
    const { offering, userNote, onSaveUserNote, error } = this.props;

    const showInternational = isInternationalOfferingsOn();
    return (
      <div
        className={classNames('offering-profile')}
        data-test-id={offeringProfileScreenSelector.testId}
      >
        <ScreenHeader />
        <ScreenContent
          renderSubHeader={() =>
            offering && <OfferingSubHeader onPrecedentsFilter={this.handleOnPrecedentReports} />
          }
        >
          {error && <PageError error={error} />}
          {offering && !error && (
            <OfferingProfile
              offering={offering}
              onSaveUserNote={onSaveUserNote}
              userNote={userNote}
              showInternational={showInternational}
            />
          )}
        </ScreenContent>

        <PrecedentTransactionsModal />
      </div>
    );
  }
}
