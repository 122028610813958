import { createTestId } from '../../../../common/selectors';

const create = name => createTestId(`account-subscription-${name}`);

export const accountSubscriptionScreenSelector = create('screen');
export const accountSubscriptionHeaderSelector = create('header');
export const accountSubscriptionEditButtonSelector = create('edit-button');
export const accountSubscriptionSaveButtonSelector = create('save-button');
export const accountSubscriptionCancelButtonSelector = create('cancel-button');
export const accountSubscriptionEnableAccountButtonSelector = create('enable-account-button');
export const accountSubscriptionDisableAccountButtonSelector = create('disable-account-button');
export const accountSubscriptionFormSelector = create('form');

export const accountSubscriptionTraitEditButtonSelector = create('trait-edit-button');
export const accountSubscriptionTraitSaveButtonSelector = create('trait-save-button');
