import './AgGridWrapper.scss';

import {
  CellFocusedEvent,
  CellPosition,
  CellValueChangedEvent,
  ColDef,
  ColumnApi,
  ColumnResizedEvent,
  ColumnState,
  ColumnVisibleEvent,
  DisplayedColumnsChangedEvent,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  GridSizeChangedEvent,
  IDatasource,
  IsRowSelectable,
  ModelUpdatedEvent,
  SelectionChangedEvent,
  SortChangedEvent,
  TabToNextCellParams,
  ViewportChangedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import deepMerge from 'deepmerge';
import React from 'react';

import PinnedRowRenderer from '../renderers/PinnedRowRenderer';
import { SAgGrid } from './AgGridWrapper.styles';
import { defaultFrameworkComponents, getAllColumnIds } from './BaseAgGrid.model';

/**
 * add this column to grid columns on the first position
 */
export const selectColumn = {
  field: 'checkbox',
  suppressColumnsToolPanel: true,
  headerName: '',
  headerCheckboxSelection: true,
  headerCheckboxSelectionFilteredOnly: true,
  checkboxSelection: true,
  sortable: false,
  maxWidth: 33,
  minWidth: 33,
  cellClass: 'select-column',
};

type Props = {
  /**
   * config for rendering row data by column
   */
  columns: ColDef[];
  /**
   * rows of data to be rendered
   */
  rows: any[];
  /**
   * floating rows of data at the top of the grid
   */
  pinnedTopRows?: any[];
  /**
   * floating rows of data at the bottom of the grid
   */
  frameworkComponents?:
    | {
        [p: string]: {
          new (): any;
        };
      }
    | any;
  pinnedBottomRows?: any[];
  /**
   * resize grid by cell content or resize so text fits
   */
  resizeBy: 'text' | 'grid';
  /**
   * options from AgGridReact
   */
  gridOptions?: GridOptions;
  /**
   * boolean allowing resizing
   */
  resizable: boolean;
  /**
   * function passed to AgGridReact for modifying row data
   */
  getRowNodeId?: (data: any) => string | number;
  /**
   * AgGridReact layout option
   * autoHeight: expands grid to fit all rows, no scrolling
   * normal: fits grid to parent container, scrolls
   */
  domLayout?: 'autoHeight' | 'normal';
  /**
   * empty rows messaging for AgGridReact
   */
  overlayNoRowsTemplate?: string;
  /**
   * renamed handler AgGridReact's onSortChanged - expects sortModel as a list of sorted columns
   */
  onOrderByChange: (sortModel: ColumnState[]) => void;
  /**
   * AgGridReact's onGridReady
   */
  onGridReady: (grid: GridReadyEvent) => void;
  /**
   * AgGridReact's onCellValueChange
   */
  onCellValueChange: (e: CellValueChangedEvent) => void;
  /**
   * AgGridReact's onDisplayedColumnsChanged
   */
  onDisplayedColumnsChanged?: (e: DisplayedColumnsChangedEvent) => void;
  /**
   * AgGridReact's onSelectionChange
   */
  onSelectionChange: (rows: any[], activeRow: any) => void;
  /**
   * resizeStrategy - Grid consumers will be able to decide wether they want a resize to fit screen or a resize to fit cell content strategy. Default is resize to fit screen
   */
  resizeStrategy: 'fit-screen' | 'fit-content';
  onFirstDataRendered?(event: FirstDataRenderedEvent): void;
  onViewportChanged?(event: ViewportChangedEvent): void;
  onModelUpdated?(event: ModelUpdatedEvent): void;
  onSortChanged?(event: SortChangedEvent): void;
  onGridSizeChanged?(event: GridSizeChangedEvent): void;
  onColumnVisible?(event: ColumnVisibleEvent): void;
  onColumnResized?: (event: ColumnResizedEvent) => void;
  onCellFocused?(event: CellFocusedEvent): void;
  tabToNextCell?: (params: TabToNextCellParams) => CellPosition;
  infiniteScroll?: boolean;
  datasource?: IDatasource;
  gridHeight?: string;
  readonly context?: unknown;
  readonly rowSelection?: 'single' | 'multiple';
  isRowSelectable?: IsRowSelectable;
};

type State = {
  gridApi: GridApi | null;
  gridColumnApi: ColumnApi | null;
};

/**
 * AgGridWrapper wraps AgGridReact with grid styling overrides and resize listeners
 * wires up necessary props and handlers - custom abstraction around AgGridReact
 */
export default class AgGridWrapper extends React.Component<Props, State> {
  state: State = {
    gridApi: null,
    gridColumnApi: null,
  };

  static defaultProps: Partial<Props> = {
    onOrderByChange: () => {},
    onGridReady: () => {},
    onCellValueChange: () => {},
    onSelectionChange: () => {},
    onFirstDataRendered: () => {},
    onViewportChanged: () => {},
    onModelUpdated: () => {},
    pinnedTopRows: [],
    pinnedBottomRows: [],
    resizeBy: 'text',
    resizable: true,
    resizeStrategy: 'fit-screen',
  };

  componentDidUpdate() {
    !this.props.infiniteScroll &&
      this.state.gridApi &&
      this.state.gridApi.setRowData(this.props.rows);
  }

  componentWillUnmount = () => {
    this.setState({ gridApi: null, gridColumnApi: null });
  };

  handleOnGridReady = (grid: GridReadyEvent) => {
    this.setState({ gridApi: grid.api, gridColumnApi: grid.columnApi }, () => {
      !this.props.infiniteScroll && grid.api.setRowData(this.props.rows);

      this.resizeGrid();
      this.props.onGridReady(grid);
    });
  };

  handleOrderByChange = (event?: SortChangedEvent) => {
    if (this.props.onSortChanged) {
      this.props.onSortChanged(event!);
    } else if (this.state.gridColumnApi) {
      const sortedColumns = this.state.gridColumnApi.getColumnState().filter(column => column.sort);
      this.props.onOrderByChange(sortedColumns);
    }
  };

  handleOnCellValueChange = (args: CellValueChangedEvent) => this.props.onCellValueChange(args);

  handleOnSelectionChanged = (event: SelectionChangedEvent) => {
    const rows = event.api.getSelectedNodes().map(node => node.data);
    const focusedIndex = event.api.getFocusedCell()?.rowIndex;

    let activeRow;

    if (rows.length) {
      if (focusedIndex !== undefined) {
        activeRow = event.api.getDisplayedRowAtIndex(focusedIndex)?.data;
      } else {
        activeRow = rows[0]; // getFocusedCell() selecting first item by checkbox ends up here (probably bug on aggrid side)
      }
    }

    this.props.onSelectionChange(rows, activeRow);
  };

  /**
   * resizeGrid - Execute a resize cycle for which ever resize strategy is applicable
   */
  resizeGrid = () => {
    this.tryResizeFitToScreen();
    this.tryResizeFitContent();
  };

  /**
   * handleGridSizeChanged - handler for window resize or grid resize
   */
  handleGridSizeChanged = (event: GridSizeChangedEvent) => {
    this.tryResizeFitToScreen();
    this.props.onGridSizeChanged && this.props.onGridSizeChanged(event);
  };

  /**
   * Handler run after data first render. NOTE: not all custom rendered fall into this first render
   */
  handleFirstDataRendered = (event: FirstDataRenderedEvent) => {
    this.props.onFirstDataRendered && this.props.onFirstDataRendered(event);
  };

  handleViewportChanged = (event: ViewportChangedEvent) => {
    this.props.onViewportChanged && this.props.onViewportChanged(event);
  };

  handleModelUpdated = (event: ModelUpdatedEvent) => {
    this.props.onModelUpdated && this.props.onModelUpdated(event);
  };

  handleSortChanged = (event: SortChangedEvent) => {
    this.props.onSortChanged && this.props.onSortChanged(event);
  };

  /**
   * Handler run after data first render. NOTE: not all custom rendered fall into this first render
   */
  handleColumnVisibility = (event: ColumnVisibleEvent) => {
    this.props.onColumnVisible && this.props.onColumnVisible(event);
  };

  /**
   * Triggered when a column is resized
   */
  handleColumnResized = (event: ColumnResizedEvent) => {
    this.props.onColumnResized && this.props.onColumnResized(event);
  };

  handleCellFocused = (event: CellFocusedEvent) => {
    this.props.onCellFocused && this.props.onCellFocused(event);
  };

  /**
   * tryResizeFitToScreen - try to resize to fit screen if aplicable according to grid configs
   */
  tryResizeFitToScreen = () => {
    if (this.props.resizable && this.state.gridApi && this.props.resizeStrategy === 'fit-screen') {
      this.state.gridApi.sizeColumnsToFit();
    }
  };

  /**
   * tryResizeFitContent - try to resize to fit cell content if applicable according to grid configs
   */
  tryResizeFitContent = () => {
    if (
      this.state.gridColumnApi &&
      (this.props.resizeBy === 'text' || this.props.resizeStrategy === 'fit-content')
    ) {
      this.state.gridColumnApi.autoSizeColumns(
        getAllColumnIds(this.state.gridColumnApi, { autoSizedOnly: true })
      );
    }
  };

  render() {
    const {
      columns,
      gridOptions,
      pinnedTopRows,
      pinnedBottomRows,
      resizable,
      getRowNodeId,
      frameworkComponents,
      onDisplayedColumnsChanged,
      domLayout = 'autoHeight',
      overlayNoRowsTemplate,
      infiniteScroll,
      datasource,
      context,
      rowSelection,
      isRowSelectable,
    } = this.props;

    const fullColumns = columns.map(c => ({
      ...c,
      // use field also as colId to avoid aggrid bug:
      // https://github.com/ag-grid/ag-grid/issues/2889 | https://github.com/ag-grid/ag-grid/issues/3206 | https://github.com/ag-grid/ag-grid/issues/3400
      colId: c.colId ? c.colId : c.field,
      // disable removing columns by dragging if they are not selectable in our ColumnSelectDropdown
      lockVisible: c.suppressColumnsToolPanel || c.lockVisible,
    }));

    const mergedFrameworkComponents =
      frameworkComponents != null
        ? deepMerge(defaultFrameworkComponents, frameworkComponents)
        : defaultFrameworkComponents;

    return (
      <SAgGrid className="cmg-legacy-data-grid ag-theme-balham">
        <AgGridReact
          domLayout={infiniteScroll ? 'normal' : domLayout}
          columnDefs={fullColumns}
          pagination={false}
          enterMovesDownAfterEdit={true}
          singleClickEdit
          onGridReady={this.handleOnGridReady}
          onCellValueChanged={this.handleOnCellValueChange}
          onDisplayedColumnsChanged={onDisplayedColumnsChanged}
          onSelectionChanged={this.handleOnSelectionChanged}
          pinnedTopRowData={pinnedTopRows}
          pinnedBottomRowData={pinnedBottomRows}
          overlayNoRowsTemplate={overlayNoRowsTemplate}
          immutableData={true}
          frameworkComponents={mergedFrameworkComponents}
          getRowNodeId={data => (getRowNodeId ? getRowNodeId(data) : data.id)}
          defaultColDef={{
            cellClassRules: {
              'table-cell-editable': params =>
                params.colDef.editable === true ||
                (typeof params.colDef.editable === 'function' &&
                  params.colDef.editable(params) === true),
            },
            comparator: () => 0,
            pinnedRowCellRendererFramework: PinnedRowRenderer,
            sortable: true,
            resizable,
          }}
          gridOptions={{
            ...gridOptions,
            ...(infiniteScroll && {
              rowModelType: 'infinite',
              datasource,
            }),
          }} // define any ag-grid prop which is not defined directly ☝
          onFirstDataRendered={this.handleFirstDataRendered}
          onViewportChanged={this.handleViewportChanged}
          onModelUpdated={this.handleModelUpdated}
          onSortChanged={this.handleOrderByChange}
          onColumnVisible={this.handleColumnVisibility}
          onColumnResized={this.handleColumnResized}
          onCellFocused={this.handleCellFocused}
          onGridSizeChanged={this.handleGridSizeChanged}
          tabToNextCell={this.props.tabToNextCell}
          context={context}
          rowSelection={rowSelection ?? 'multiple'}
          isRowSelectable={isRowSelectable}
        />
      </SAgGrid>
    );
  }
}
