import { apiTypes, mixpanelUtil } from '@cmg/common';
import { AnyAction, combineReducers } from 'redux';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import api from '../../api/datalab-api';
import {
  createActionCreator,
  createActionType,
  createApiActionCreators,
  createReducer,
  FAILURE,
  REQUEST,
  SUCCESS,
} from '../../common/redux/reduxHelpers';
import { RootState } from '../../common/redux/rootReducer';
import { CompanyLegacy } from '../../types/domain/company/company';

const { createMixPanelAction } = mixpanelUtil;

/**
 * ACTION TYPES
 */
const FETCH_COMPANY = 'company/FETCH_COMPANY';
const UPDATE_SECTOR_COMPANY = 'company/UPDATE_SECTOR_COMPANY';
const RESET_STATE = 'company/RESET_STATE';
export const UPDATE_CUSTOM_SECTOR = 'company/UPDATE_CUSTOM_SECTOR';

/**
 * ACTIONS
 */
export const fetchCompanyActions = createApiActionCreators(FETCH_COMPANY);
export const updateSectorCompanyActionCreators = createApiActionCreators(UPDATE_SECTOR_COMPANY);
export const updateCustomSector = createActionCreator<Partial<CompanyLegacy>>(UPDATE_CUSTOM_SECTOR);
export const resetState = createActionCreator(RESET_STATE);

type ResponseError = apiTypes.GenericServerError | null;

type ReducerState = {
  company: CompanyLegacy | null;
  error: ResponseError;
};

/**
 * REDUCERS
 */
export const initialState: ReducerState = {
  company: null,
  error: null,
};

const company = createReducer(initialState.company, {
  [RESET_STATE]: () => null,
  [FETCH_COMPANY]: {
    [SUCCESS]: (state, payload) => payload.company,
  },
  [UPDATE_CUSTOM_SECTOR]: (state, payload) => ({
    ...state,
    customSector: payload.customSector,
    customSubsector: payload.customSubsector,
  }),
});

const error = createReducer(null, {
  [RESET_STATE]: () => null,
  [FETCH_COMPANY]: {
    [SUCCESS]: () => null,
    [FAILURE]: (state, payload) => payload.error,
  },
});

export default combineReducers({
  company,
  error,
});

/**
 * SELECTORS
 */
export const selectCompanyProfile = (state: RootState) => state.companyProfile;

export const selectCompany = (state: RootState): CompanyLegacy | null =>
  selectCompanyProfile(state).company;

export const selectError = (state: RootState): ResponseError => selectCompanyProfile(state).error;

/**
 * SAGAS
 */
export function* fetchCompany({ payload: companyId }: AnyAction) {
  yield put(createMixPanelAction(FETCH_COMPANY, 'Company Page'));

  const resp = yield call(api.fetchCompany, companyId);

  if (resp.ok) {
    yield put(fetchCompanyActions.success(resp.data));
  } else {
    yield put(fetchCompanyActions.failure(resp.data));
  }
}

export function* updateSectorCompany({ payload: { companyId, sectorId, callback } }: AnyAction) {
  const resp = yield call(api.customSectorCompany.update, companyId, sectorId);

  if (resp.ok) {
    callback && callback(resp.data.data[0]);
    // this api method can update multiple companies and returns array even on updating only one
    yield put(updateCustomSector(resp.data.data[0]));
  }
}

export function* companyProfileSaga() {
  yield takeLatest(createActionType(FETCH_COMPANY, REQUEST), fetchCompany);
  yield takeEvery(createActionType(UPDATE_SECTOR_COMPANY, REQUEST), updateSectorCompany);
}
