import { datalabApi } from '@cmg/api';
import { ConfirmModal, Icon } from '@cmg/common';
import React, { useState } from 'react';

import { TreeSelect } from '../../../../common/components/form';
import routeFactory from '../../../../common/util/routeFactory';
import { mapSectorsToOptions } from '../../../shared/sectors/sectors.model';
import {
  SManagedSectorsLinkContainer,
  SSectorsEditModal,
  SSubtitle,
  STitle,
} from './SectorsModal.styles';

export type OnSubmit = (sectorId: string) => void;
type OwnProps = {
  onSubmit: OnSubmit;
  sectors?: datalabApi.CustomSectorDto[];
  companyCount?: number;
  show: boolean;
  onHide: () => void;
};
type Props = OwnProps;

/**
 * Display modal for updating custom-sectors of provided companies
 */
export const SectorsModal: React.FC<Props> = ({
  sectors,
  onHide,
  onSubmit,
  companyCount,
  show,
}) => {
  const [sectorId, setSectorId] = useState<string>();

  const handleSubmit = () => {
    sectorId && onSubmit(sectorId);
    onHide();
  };

  return (
    <ConfirmModal
      title={
        <STitle>
          <span>Assign My Sectors and Sub Sectors</span>
          <SSubtitle>
            {!companyCount || companyCount === 1
              ? `You are updating the company record.`
              : `You are updating ${companyCount} companies.`}
          </SSubtitle>
        </STitle>
      }
      isLoading={!sectors}
      confirmButtonEnabled={!!sectorId}
      onHide={onHide}
      onConfirm={handleSubmit}
      show={show}
    >
      <SSectorsEditModal>
        <TreeSelect
          value={sectorId}
          treeData={mapSectorsToOptions(sectors)}
          onChange={id => setSectorId(id)}
          treeCheckable={false}
        />
        <SManagedSectorsLinkContainer>
          <a target="_blank" rel="noreferrer noopener" href={routeFactory.sectors.getUrlPath()}>
            Manage Sectors <Icon name="external-link-alt" fixedWidth />
          </a>
        </SManagedSectorsLinkContainer>
      </SSectorsEditModal>
    </ConfirmModal>
  );
};

export default SectorsModal;
