import { createTestId } from '../../../common/selectors';

const create = (name: string) => createTestId(`xc-offering-setup-managers-${name}`);

export const offeringSetupManagers = create('managers');
export const offeringSetupEntitlements = create('entitlements');

const createEntitlementsSelector = (name: string) => create(`entitlements-${name}`);
const createManagerEconomicsSelector = (name: string) => create(`manager-economics-${name}`);

export const offeringSetupEntitlementsSubNavigation = createEntitlementsSelector('sub-navigation');
export const offeringSetupEntitlementsSubNavigationOfferingSetup = createEntitlementsSelector(
  'sub-navigation-offering-setup'
);
export const offeringSetupEntitlementsSubNavigationOrderBook = createEntitlementsSelector(
  'sub-navigation-order-book'
);
export const offeringSetupEntitlementsSubNavigationRoadshow =
  createEntitlementsSelector('sub-navigation-roadshow');
export const offeringSetupEntitlementsSubNavigationSyndicateWires = createEntitlementsSelector(
  'sub-navigation-syndicate-wires'
);
export const offeringSetupManagerEconomicsCalculateEconomicBreakdownButton =
  createManagerEconomicsSelector('Calculate-Economics-Breakdown-Button');
export const offeringSetupManagerEconomicsCalculateEcercisedOverallotmentSharesDistributionButton =
  createManagerEconomicsSelector('Calculate-Exercised-Overallotment-Shares-Distribution-Button');
export const offeringSetupManagerEconomicsSaveManagerEconomicsButton =
  createManagerEconomicsSelector('save-manager-economics-button');
