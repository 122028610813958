import { createTestId } from '../../../common/selectors';

const create = (name: string) => createTestId(`xc-roadshows-calendar-${name}`);

export const roadshowCalendarCreateButton = create('create-button');
export const roadshowCalendarCreateLogisticsButton = create('create-logistics-button');
export const roadshowCalendarCreateMeetingButton = create('create-meeting-button');
export const roadshowCalendarEditRoadshowConfigButton = create('edit-roadshow-config-button');
export const roadshowCalendarPublishRoadshowButton = create('publish-roadshow-button');
export const roadshowCalendarNextDateIcon = create('next-day-icon');
export const roadshowCalendarPrevDateIcon = create('prev-day-icon');
