import React from 'react';

import { useScript } from '../useScript/useScript';

declare global {
  interface Window {
    // these two types are required to integrate the hubspot form to the application
    hbspt?: any;
    hubspot?: any;
  }
}

const hubspotBaseUrl = 'https://js.hsforms.net/forms/v2.js';
// jQuery is a hard dependency for hubspot. The form submission fails if it not were to be injected
const jQueryBaseUrl = 'https://code.jquery.com/jquery-3.6.0.min.js';

type UseHubspotFormResponse = {
  loaded: boolean;
  error: boolean;
  formCreated: boolean;
};

// inputs based on Hubspot docs: https://legacydocs.hubspot.com/docs/methods/forms/advanced_form_options
type UseHubspotFormProps = {
  portalId: string;
  formId: string;
  target: string;
  onFormSubmit?: (form?: any) => any;
  onFormReady?: (form?: any) => any;
};

export const useHubspotForm = (props: Readonly<UseHubspotFormProps>): UseHubspotFormResponse => {
  const [isHubspotLoaded, errorHubspot] = useScript(hubspotBaseUrl);
  const [isJQueryLoaded, errorJquery] = useScript(jQueryBaseUrl);
  const [formCreated, setFormCreated] = React.useState(false);

  const areScriptsLoaded = isHubspotLoaded && isJQueryLoaded;
  const error = errorHubspot || errorJquery;

  React.useEffect(() => {
    if (areScriptsLoaded && window.hbspt && !formCreated) {
      window.hbspt.forms.create(props);
      setFormCreated(true);
    }
  }, [isHubspotLoaded, formCreated, setFormCreated, areScriptsLoaded, props]);

  return { loaded: areScriptsLoaded, formCreated, error };
};
