import { connect } from 'react-redux';

import { CalendarCategory } from '../../../types/domain/calendar/constants';
import { selectOfferings } from '../ducks';
import {
  allocationColumn,
  Column,
  countryColumn,
  customSectorColumn,
  expectedPricingDateColumn,
  ioiTypeColumn,
  issuerFirmNameForMyOfferingColumn,
  leftLeadColumn,
  marketCapAtPricingColumn,
  marketCapAtPricingLocalCurrColumn,
  offeringTypeColumn,
  offerPriceColumn,
  offerPriceLocalCurrColumn,
  priceRangeLowColumn,
  regionColumn,
  secondarySharesColumn,
  sectorColumn,
  sizeDollarsColumn,
  sizeLocalCurrColumn,
  sizeSharesColumn,
  tickerColumn,
  totalBookRunnersColumn,
  totalNonBookRunnersColumn,
} from '../model/offeringListColumns';
import BaseDatalabCalendar, { mapDispatchToProps } from './BaseDatalabCalendar';

const calendarCategory = CalendarCategory.MY_OFFERINGS_WITH_ALLOCATIONS;

export const columnsConfig: Column[] = [
  issuerFirmNameForMyOfferingColumn,
  tickerColumn,
  regionColumn,
  countryColumn,
  sectorColumn,
  customSectorColumn,
  offeringTypeColumn,
  expectedPricingDateColumn,
  sizeLocalCurrColumn,
  sizeDollarsColumn,
  sizeSharesColumn,
  marketCapAtPricingLocalCurrColumn,
  marketCapAtPricingColumn,
  priceRangeLowColumn,
  offerPriceLocalCurrColumn,
  offerPriceColumn,
  allocationColumn,
  ioiTypeColumn,
  leftLeadColumn,
  secondarySharesColumn,
  totalBookRunnersColumn,
  totalNonBookRunnersColumn,
];

export const staticColumns: Column[] = [issuerFirmNameForMyOfferingColumn, tickerColumn];

export const defaultVisibleColumns = columnsConfig
  .filter(
    c => ![secondarySharesColumn, totalBookRunnersColumn, totalNonBookRunnersColumn].includes(c)
  )
  .map(c => c.field);

const mapStateToProps = state => ({
  offerings: selectOfferings(state, calendarCategory),
});

export class MyOfferingsWithAllocationsCalendarComponent extends BaseDatalabCalendar {
  constructor(props) {
    super(props);

    this.calendarCategory = calendarCategory;
    this.defaultGroupBy = 'pricingDate';
    this.defaultGroupByOrderType = 'descWithNullFirst';
    this.defaultOrderBy = 'issuerFirmName';
    this.defaultOrderByType = 'asc';
    this.columnsConfig = columnsConfig;
    this.staticColumns = staticColumns;
    this.state = {
      visibleColumns: defaultVisibleColumns,
    };
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyOfferingsWithAllocationsCalendarComponent);
