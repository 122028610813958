import { Group, Option } from '../../form/select/types';

const getFilteredGroupLeafs = <TOptionValue>(
  node: Group<TOptionValue>,
  searchText: string | null
) => {
  if (!searchText) {
    return node;
  }

  const searchValue = searchText.toLowerCase();
  const nodeLabel = node.label.toLowerCase();

  if (!nodeLabel.includes(searchValue)) {
    const options = node.options.filter(({ label }) => label.toLowerCase().includes(searchValue));

    return {
      ...node,
      options,
    };
  }

  return node;
};

/**
 * Filter options based on search input value. Remove empty groups.
 */
export const getVisibleNodes = <TOptionValue>(
  options: (Option<TOptionValue> | Group<TOptionValue>)[],
  searchText: string | null
) => {
  return options
    .map(node => {
      if (!node.options) {
        return node;
      }

      return getFilteredGroupLeafs(node, searchText);
    })
    .filter(node => {
      if (!node.options) {
        return !searchText || node.label.toLowerCase().includes(searchText.toLowerCase());
      }

      return node.options.length > 0;
    });
};
