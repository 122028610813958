import { Property } from 'csstype';
import styled, { css } from 'styled-components/macro';

type AutoColumnTableProps = {
  numColumns: number;
  gridTemplateColumns?: never;
};

type TemplateColumnTableProps = {
  numColumns?: number;
  gridTemplateColumns: string;
};

export type TableProps = AutoColumnTableProps | TemplateColumnTableProps;

type CellProps = {
  align?: Property.TextAlign;
  colSpan?: number;
  rowSpan?: number;
  centered?: boolean;
  bold?: boolean;
};

type BodyCellProps = CellProps & {
  paddingSize?: 'XS' | 'S' | 'M' | 'L';
  withBottomBorder?: boolean;
  withRightBorder?: boolean;
};

type HeaderCellProps = CellProps & {
  withBottomBorder?: boolean;
  withRightBorder?: boolean;
  nonSticky?: boolean;
};

type RowProps = {
  isFocused?: boolean;
  suppressHighlightingOnHover?: boolean;
};

const generateCellCSS = ({
  align = 'left',
  colSpan = 1,
  rowSpan = 1,
  centered,
  bold,
}: CellProps) => {
  return css`
    text-align: ${align};
    justify-content: ${align};
    grid-column: span ${colSpan};
    grid-row: span ${rowSpan};
    position: relative;

    ${centered &&
    css`
      display: flex;
      align-items: center;
    `}

    ${bold &&
    css`
      font-weight: ${({ theme }) => theme.text.weight.bold};
    `}
  `;
};

export const SHeaderCell = styled.div.attrs({ role: 'columnheader' })<HeaderCellProps>`
  z-index: 1;
  top: 0;
  padding: 4px 8px;
  color: ${({ theme }) => theme.designSystem.colors.gray['500']};
  background: ${({ theme }) => theme.background.color.white};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  font-size: ${({ theme }) => theme.text.size.mediumSmall};
  position: ${({ nonSticky = false }) => (nonSticky ? 'relative' : 'sticky')};

  ${({ withBottomBorder = true }) =>
    withBottomBorder &&
    css`
      border-bottom: ${({ theme }) => theme.border.width.small} solid
        ${({ theme }) => theme.designSystem.colors.gray['100']};
    `};

  // The right border has to have a 4px margin on the vertical axis
  // We could solve this by nesting divs or with this little :after hack
  ${({ withRightBorder = true }) =>
    withRightBorder &&
    css`
      &:after {
        display: block;
        background: ${({ theme }) => theme.designSystem.colors.gray['300']};
        width: ${({ theme }) => theme.border.width.small};
        content: ' ';
        position: absolute;
        top: 4px;
        bottom: 4px;
        right: 0;
      }
    `};

  ${generateCellCSS}
`;

export const SBodyHeaderCell = styled.div.attrs({ role: 'cell' })<CellProps>`
  background-color: ${({ theme }) => theme.designSystem.colors.gray['000']};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  font-size: ${({ theme }) => theme.text.size.medium};
  padding: 4px 8px;

  ${generateCellCSS}
`;

export const SBodySubHeaderCell = styled.div.attrs({ role: 'cell' })<CellProps>`
  padding: 4px 8px;
  background: ${({ theme }) => theme.background.color.white};
  color: ${({ theme }) => theme.designSystem.colors.gray['500']};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  font-size: ${({ theme }) => theme.text.size.mediumSmall};
  border-bottom: ${({ theme }) => theme.border.width.small} solid
    ${({ theme }) => theme.designSystem.colors.gray['300']};

  ${generateCellCSS}
`;

export const SBodyCell = styled.div.attrs({ role: 'cell' })<BodyCellProps>`
  font-weight: ${({ theme }) => theme.text.weight.regular};
  box-shadow: inset ${({ withRightBorder = false }) => (withRightBorder ? '-0.5px' : 0)}
    ${({ withBottomBorder = true }) => (withBottomBorder ? '-0.5px' : 0)} 0
    ${({ theme }) => theme.designSystem.colors.gray['100']};
  padding: ${({ paddingSize }) => {
      switch (paddingSize) {
        case 'XS':
          return '2px';
        case 'S':
          return '8px';
        case 'M':
        default:
          return '16px';
      }
    }}
    8px;

  ${generateCellCSS}
`;

export const SRow = styled.div.attrs({ role: 'row' })<RowProps>`
  display: contents;

  ${({ isFocused, suppressHighlightingOnHover }) => {
    if (isFocused) {
      return css`
        & > ${SBodyCell} {
          background: ${({ theme }) => theme.designSystem.colors.darkBlue['100']};
        }
      `;
    }

    if (!suppressHighlightingOnHover) {
      return css`
        &:hover > ${SBodyCell} {
          background: ${({ theme }) => theme.designSystem.colors.darkBlue['000']};
        }
      `;
    }

    return undefined;
  }}
`;

/**
 * The `<Table />` component renders a table using a CSS grid.
 * You can either specify a number of columns using `numColumns={n}` and have them be automatically sized
 * or you can supply `gridTemplateColumns` prop with a grid-template-columns css value for manual sizing.
 *
 * - `<Table.TableHeaderCell />` renders a table header cell
 * - `<Table.GroupHeaderCell />` renders a group header cell inside the table's body
 * - `<Table.Row />` groups a row of `<Table.Cell />` components and provides different behaviors for focusing and hovering over a row
 * - `<Table.Cell />` is a basic cell for the table
 *
 * All cell components support alignment and span props.
 */
export const Table = styled.div.attrs({ role: 'table' })<TableProps>`
  display: grid;
  position: relative;
  ${({ numColumns, gridTemplateColumns }) =>
    gridTemplateColumns
      ? css`
          grid-template-columns: ${gridTemplateColumns};
        `
      : css`
          grid-template-columns: repeat(${numColumns}, auto);
        `}
  color: ${({ theme }) => theme.text.color.darkGray};
`;
