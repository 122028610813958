import { OrderableHeaderCell, OrderableTable } from './OrderableTable';
import {
  comparatorWithAccessor,
  dateComparator,
  numberComparator,
  stringComparator,
} from './OrderableTable.model';

// I've tried our default approach with exporting through Object.assign(), but for some reason the main component
// disappears and so simply using <Orderable /> fails. <Orderable.Table /> is not that bad though.
const module = {
  Table: OrderableTable,
  TableHeaderCell: OrderableHeaderCell,
  comparator: {
    date: dateComparator,
    number: numberComparator,
    string: stringComparator,
    withAccessor: comparatorWithAccessor,
  },
};

export default module;
