import { Icon } from '@cmg/common';
import React, { useState } from 'react';

import SectorEdit from './SectorEdit';
import { SButton } from './styled';

type Props = {
  parentId?: string;
};
export const SectorAdd = ({ parentId }: Props) => {
  const [editing, setEditing] = useState(false);

  if (editing) {
    return (
      <React.Fragment>
        <SectorEdit parentId={parentId} onSaved={() => setEditing(false)} />
        <SButton onClick={() => setEditing(false)}>Cancel</SButton>
      </React.Fragment>
    );
  } else {
    return (
      <SButton onClick={() => setEditing(true)}>
        <Icon name="plus" fixedWidth size="1x" />
        Add {parentId && 'Sub '}Sector
      </SButton>
    );
  }
};

export default SectorAdd;
