import { AnyAction } from 'redux';
import { runSaga, Saga } from 'redux-saga';

export async function simulateSaga<Fn extends Saga>({
  saga,
  args,
  state = {},
}: {
  saga: Fn;
  state?: any;
  args: Parameters<Fn>;
}) {
  const dispatchedActions: AnyAction[] = [];

  const dispatch = (action: AnyAction) => {
    dispatchedActions.push(action);
  };
  await runSaga({ dispatch, getState: () => state }, saga, ...args);
  return dispatchedActions;
}
