import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import React from 'react';

import type { TestProps } from '../../types';
import { HighlightsActionList } from './actions/HighlightsActionList';
import { HighlightsContent } from './HighlightsContent';
import { HighlightsItemList } from './items/HighlightsItemList';
import { HighlightsTitle } from './title/HighlightsTitle';

export type HighlightsProps = Readonly<
  TestProps & {
    /**
     * Highlight's title
     */
    title: Readonly<string> | Readonly<React.ReactNode>;
    /**
     * Higlight's title items
     */
    titleItems?: Readonly<React.ReactNode>;
    /**
     * Higlight's subtitle
     */
    subtitle?: Readonly<string> | Readonly<React.ReactNode>;
    /**
     * Highlight's items
     */
    items?: Readonly<React.ReactNode>;
    /**
     * Highlight's actions
     */
    actions?: Readonly<React.ReactNode>;
    /**
     * Highlight's bottom divider
     */
    divider?: Readonly<boolean>;
  }
>;

export const Highlights: React.VFC<HighlightsProps> = ({
  title,
  titleItems,
  subtitle,
  items,
  actions,
  divider = true,
  ...restProps
}) => {
  return (
    <Box {...restProps}>
      <Stack direction="row" flexWrap="wrap" px={4} py={0.75} gap={1} alignItems="start">
        <Box flexGrow={1}>
          <HighlightsContent direction={titleItems ? 'column' : 'row'}>
            <HighlightsTitle value={title} items={titleItems} subtitle={subtitle} />
            {items && <HighlightsItemList>{items}</HighlightsItemList>}
          </HighlightsContent>
        </Box>
        {actions && (
          <Box flexGrow={0}>
            <HighlightsActionList actions={actions} />
          </Box>
        )}
      </Stack>
      {divider && <Divider />}
    </Box>
  );
};
