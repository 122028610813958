import { testStockSymbols } from '../../data/testingStockSymbols';

/**
 * Checks whether or not a given offering is a test offering
 * @param pricingInstrumentStockSymbol the offering's stock symbol
 */
export function isTestOffering(pricingInstrumentStockSymbol: string | null | undefined): boolean {
  if (!pricingInstrumentStockSymbol) {
    return false;
  }
  return testStockSymbols.includes(pricingInstrumentStockSymbol);
}
