// TODO - This data should be provided by the backend.
// See https://capitalmarketsgateway.atlassian.net/browse/ECM-3613

const cmgSectorScheme = [
  {
    title: 'Basic Materials',
    value: '101',
    key: '101',
    children: [
      { title: 'Agriculture', value: '10110', key: '10110' },
      { title: 'Building Materials', value: '10120', key: '10120' },
      { title: 'Chemicals', value: '10130', key: '10130' },
      { title: 'Forest Products', value: '10140', key: '10140' },
      { title: 'Metals & Mining', value: '10150', key: '10150' },
      { title: 'Steel', value: '10160', key: '10160' },
    ],
  },
  {
    title: 'Communication Services',
    value: '308',
    key: '308',
    children: [
      { title: 'Electronic Gaming & Multimedia', value: '30830020', key: '30830020' },
      { title: 'Internet Content & Information', value: '30830010', key: '30830010' },
      { title: 'Media—Diversified', value: '30820', key: '30820' },
      { title: 'Telecom Services', value: '30810010', key: '30810010' },
    ],
  },
  {
    title: 'Consumer Cyclical',
    value: '102',
    key: '102',
    children: [
      { title: 'Furnishings, Fixtures & Appliances', value: '10220', key: '10220' },
      { title: 'Homebuilding & Construction', value: '10230', key: '10230' },
      { title: 'Internet Retail', value: '10280050', key: '10280050' },
      { title: 'Manufacturing—Apparel & Accessories', value: '10240', key: '10240' },
      { title: 'Packaging & Containers', value: '10250', key: '10250' },
      { title: 'Personal Services', value: '10260', key: '10260' },
      { title: 'Restaurants', value: '10270', key: '10270' },
      { title: 'Retail—Cyclical', value: '10280', key: '10280' },
      { title: 'Travel & Leisure', value: '10290', key: '10290' },
      { title: 'Vehicles & Parts', value: '10200', key: '10200' },
    ],
  },
  {
    title: 'Consumer Defensive',
    value: '205',
    key: '205',
    children: [
      { title: 'Beverages—Alcoholic', value: '20510', key: '20510' },
      { title: 'Beverages—Non-Alcoholic', value: '20520', key: '20520' },
      { title: 'Consumer Packaged Goods', value: '20525', key: '20525' },
      { title: 'Education', value: '20540', key: '20540' },
      { title: 'Retail—Defensive', value: '20550', key: '20550' },
      { title: 'Tobacco Products', value: '20560', key: '20560' },
    ],
  },
  {
    title: 'Energy',
    value: '309',
    key: '309',
    children: [
      { title: 'Oil & Gas Drilling', value: '30910010', key: '30910010' },
      { title: 'Oil & Gas E&P', value: '30910020', key: '30910020' },
      { title: 'Oil & Gas Integrated', value: '30910030', key: '30910030' },
      { title: 'Oil & Gas Midstream', value: '30910040', key: '30910040' },
      { title: 'Oil & Gas Refining & Marketing', value: '30910050', key: '30910050' },
      { title: 'Oil & Gas Equipment & Services', value: '30910060', key: '30910060' },
      { title: 'Other Energy Sources', value: '30920', key: '30920' },
    ],
  },
  {
    title: 'Financial Services',
    value: '103',
    key: '103',
    children: [
      { title: 'Asset Management', value: '10310', key: '10310' },
      { title: 'Banks', value: '10320', key: '10320' },
      { title: 'Capital Markets', value: '10330', key: '10330' },
      { title: 'Credit Services', value: '10360', key: '10360' },
      { title: 'Financial Conglomerates', value: '10350020', key: '10350020' },
      { title: 'Insurance', value: '10340', key: '10340' },
      { title: 'Shell Companies', value: '10350010', key: '10350010' },
    ],
  },
  {
    title: 'Healthcare',
    value: '206',
    key: '206',
    children: [
      { title: 'Biotechnology', value: '20610', key: '20610' },
      { title: 'Drug Manufacturers', value: '20620', key: '20620' },
      { title: 'Health Information Services', value: '20645030', key: '20645030' },
      { title: 'Healthcare Plans', value: '20630', key: '20630' },
      { title: 'Healthcare Providers & Services', value: '20645', key: '20645' },
      { title: 'Medical Devices & Instruments', value: '20650', key: '20650' },
      { title: 'Medical Diagnostics & Research', value: '20660', key: '20660' },
      { title: 'Medical Distribution', value: '20670', key: '20670' },
    ],
  },
  {
    title: 'Industrials',
    value: '310',
    key: '310',
    children: [
      { title: 'Aerospace & Defense', value: '31010', key: '31010' },
      { title: 'Conglomerates', value: '31030', key: '31030' },
      { title: 'Construction', value: '31040', key: '31040' },
      { title: 'Consulting Services', value: '31020020', key: '31020020' },
      { title: 'Farm & Heavy Construction Machinery', value: '31050', key: '31050' },
      { title: 'Industrial Distribution', value: '31060', key: '31060' },
      { title: 'Industrial Products', value: '31070', key: '31070' },
      { title: 'Rental & Leasing Services', value: '31020030', key: '31020030' },
      { title: 'Security & Protection Services', value: '31020040', key: '31020040' },
      { title: 'Specialty Business Services', value: '31020010', key: '31020010' },
      { title: 'Staffing & Employment Services', value: '31020050', key: '31020050' },
      { title: 'Transportation', value: '31080', key: '31080' },
      { title: 'Waste Management', value: '31090', key: '31090' },
    ],
  },
  {
    title: 'Real Estate',
    value: '104',
    key: '104',
    children: [
      { title: 'REIT—Diversified', value: '10420090', key: '10420090' },
      { title: 'REIT—Healthcare Facilities', value: '10420010', key: '10420010' },
      { title: 'REIT—Hotel & Motel', value: '10420020', key: '10420020' },
      { title: 'REIT—Industrial', value: '10420030', key: '10420030' },
      { title: 'REIT—Mortgage', value: '10420070', key: '10420070' },
      { title: 'REIT—Office', value: '10420040', key: '10420040' },
      { title: 'REIT—Residential', value: '10420050', key: '10420050' },
      { title: 'REIT—Retail', value: '10420060', key: '10420060' },
      { title: 'REIT—Specialty', value: '10420080', key: '10420080' },
      { title: 'Real Estate Services', value: '10410020', key: '10410020' },
      { title: 'Real Estate—Development', value: '10410010', key: '10410010' },
      { title: 'Real Estate—Diversified', value: '10410030', key: '10410030' },
    ],
  },
  {
    title: 'Technology',
    value: '311',
    key: '311',
    children: [
      { title: 'Hardware', value: '31120', key: '31120' },
      { title: 'Information Technology Services', value: '31110010', key: '31110010' },
      { title: 'Semiconductors', value: '31130', key: '31130' },
      { title: 'Software—Application', value: '31110020', key: '31110020' },
      { title: 'Software—Infrastructure', value: '31110030', key: '31110030' },
      { title: 'Solar', value: '31130030', key: '31130030' },
    ],
  },
  {
    title: 'Utilities',
    value: '207',
    key: '207',
    children: [
      { title: 'Utilities—Independent Power Producers', value: '20710', key: '20710' },
      { title: 'Utilities—Regulated', value: '20720', key: '20720' },
    ],
  },
  {
    title: 'Other',
    value: '999',
    key: '999',
    children: [],
  },
];

export default cmgSectorScheme;
