import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import type { DrawerProps } from '@mui/material/Drawer';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import type { PaperProps } from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import type { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import type { FC, MouseEventHandler, ReactNode } from 'react';
import React, { useCallback } from 'react';

import { ActionPanelMenuLayout } from './ActionPanel.styles';

export type ActionPanelProps = Pick<DrawerProps, 'open' | 'onClose'> & {
  readonly menu?: ReactNode;
  readonly header?: ReactNode;
  readonly dismissible?: boolean;
  readonly variant?: DrawerProps['variant'];
  readonly hideMenu?: boolean;
};
const containerStyle: SxProps<Theme> = {
  overflow: 'hidden',
  minHeight: '100%',
};
const paperProps: PaperProps = {
  sx: theme => ({
    boxShadow: 8,
    top: theme.spacing(6),
  }),
  role: 'dialog',
  'aria-roledescription': 'action panel',
};
const menuWidth = (theme: Theme) => theme.spacing(32);
const contentWidth = (theme: Theme) => theme.spacing(90);
const headerPadding = (theme: Theme) =>
  `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} 0`;
// using MUI base-8 spacing, so 8=64
// https://mui.com/material-ui/customization/spacing/
// https://m2.material.io/design/layout/understanding-layout.html#principles
const contentHeight = (theme: Theme) => `calc(100% - ${theme.spacing(8)})`;

export const ActionPanel: FC<ActionPanelProps> = props => {
  const { children, dismissible, header, hideMenu, open, menu, variant, onClose } = props;
  const onCloseByButton = useCallback<MouseEventHandler>(
    event => {
      if (onClose) {
        onClose(event, 'escapeKeyDown');
      }
    },
    [onClose]
  );

  return (
    <Drawer anchor="right" open={open} variant={variant} onClose={onClose} PaperProps={paperProps}>
      <Container sx={containerStyle} disableGutters>
        <Stack direction="row" spacing={2} alignItems="stretch" height="100%">
          <ActionPanelMenuLayout width={menuWidth} hidden={hideMenu} overflow="auto">
            {menu}
          </ActionPanelMenuLayout>
          <Stack alignItems="stretch" height={contentHeight} width={contentWidth}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              padding={headerPadding}
            >
              <Box padding={headerPadding}>
                {typeof header === 'string' ? (
                  <Typography
                    variant="highlight1"
                    component="h2"
                    color={theme => theme.palette.text.secondary}
                  >
                    {header}
                  </Typography>
                ) : (
                  header
                )}
              </Box>
              {dismissible && (
                <IconButton onClick={onCloseByButton} aria-label="Close">
                  <CloseIcon />
                </IconButton>
              )}
            </Stack>
            <Box overflow="auto" flexGrow="1">
              {children}
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Drawer>
  );
};
