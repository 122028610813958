import { useAccessToken } from '@cmg/auth';
import { LinkButton } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { openSupportRequestModal } from '../../../shared/layout/components/support-request-modal/SupportRequestModal';
import { SContent } from './ReportFieldRequest.styles';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openColumnRequestModal: openSupportRequestModal,
    },
    dispatch
  ),
});

type OwnProps = {
  onClick?: () => void;
};

export type Props = OwnProps & ReturnType<typeof mapDispatchToProps>;

/**
 * Allows user to send new data field request to CMG support.
 */
export const ReportFieldRequestComponent: React.FC<Props> = ({ actions, onClick }) => {
  const token = useAccessToken();

  const handleRequestNewField = () => {
    if (token) {
      onClick && onClick();
      actions.openColumnRequestModal({
        description: 'Please describe the field you would like to request',
        title: 'Request new field',
        submitButtonCaption: 'Request',
        token,
      });
    }
  };

  if (!token) {
    return null;
  }

  return (
    <SContent>
      <span>Don't see what you are looking for?</span>
      <LinkButton variant="high-contrast" onClick={handleRequestNewField}>
        Request new field
      </LinkButton>
    </SContent>
  );
};

export default connect(undefined, mapDispatchToProps)(ReportFieldRequestComponent);
