import React from 'react';

import PrimaryButton from '../../buttons/button/PrimaryButton';
import SecondaryButton from '../../buttons/button/SecondaryButton';
import Modal from '../modal/Modal';
import ModalContent from '../modal/ModalContent';
import ModalFooter from '../modal/ModalFooter';
import { SButtons } from './UnsavedChangesModal.styles';

type Props = {
  /**
   * Show modal
   */
  show: boolean;
  /**
   * Called when user clicks on leave page button
   */
  onLeave?: () => void;
  /**
   * Called when user clicks on stay on page button
   */
  onStay?: () => void;
  /**
   * Called when modal is being closed
   */
  onHide: () => void;
};

/**
 * Modal that notifies user about unsaved changes in edited form.
 */
const UnsavedChangesModal: React.FC<Props> = ({ onStay, onLeave, show, onHide }) => {
  const handleHide = () => {
    onLeave && onLeave();
    onHide();
  };

  const handleConfirm = () => {
    onStay && onStay();
    onHide();
  };

  return (
    <Modal
      show={show}
      title="Unsaved Changes"
      onHide={handleHide}
      shouldCloseOnOverlayClick={false}
    >
      <ModalContent>
        <p>
          You have unsaved changes that will be discarded if you navigate away. Are you sure you
          want to leave this page?
        </p>
      </ModalContent>
      <ModalFooter>
        <SButtons>
          <SecondaryButton onClick={handleHide}>Yes, Leave this Page</SecondaryButton>
          <PrimaryButton onClick={handleConfirm}>No, Stay on this Page</PrimaryButton>
        </SButtons>
      </ModalFooter>
    </Modal>
  );
};

export default UnsavedChangesModal;
