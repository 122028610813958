import { createTestId } from '../../../common/selectors';

const create = name => createTestId(`rolodex-firm-detail-${name}`);

export const firmDetailScreen = create('screen');
export const firmDetailHeader = create('header');
export const changeParentOrganizationSaveButton = create('change-parent-organization-save-button');
export const changeParentOrganizationUnlinkFromParentButton = create(
  'change-parent-organization-unlink-from-parent-button'
);
export const connectWithFactSetButton = create('disconnect-with-factset-button');
export const disconnectWithFactSetButton = create('disconnect-with-factset-button');
