import React from 'react';
import { NavLinkProps } from 'react-router-dom';

import { StyledNavLink } from './Link.styles';

type Props = {
  disabled?: boolean;
} & NavLinkProps;

/**
 * Link is a styled NavLink fom react-router-dom
 */
const Link: React.FC<Props> = ({ children, disabled = false, onClick, ...rest }) => {
  const handleOnClick = e => {
    if (disabled) {
      e.preventDefault();
      return;
    }
    onClick && onClick(e);
  };

  return (
    <StyledNavLink
      activeClassName="activeLink"
      disabled={disabled}
      onClick={handleOnClick}
      {...rest}
    >
      {children}
    </StyledNavLink>
  );
};

export default Link;
