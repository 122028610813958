import Stack from '@mui/material/Stack';
import React from 'react';

export type HighlightsContentProps = Readonly<{
  direction?: Readonly<'row' | 'column'>;
}>;

export const HighlightsContent: React.FC<HighlightsContentProps> = ({
  direction = 'row',
  children,
}) => {
  return (
    <Stack
      direction={direction}
      flexWrap="wrap"
      columnGap={direction === 'row' ? 8 : 1}
      rowGap={1}
      alignItems={direction === 'row' ? 'center' : 'start'}
    >
      {children}
    </Stack>
  );
};
