import React from 'react';

import { Comparator, OrderedRow, OrderInfo } from './OrderableTable.model';
import { OrderableTableContextValue, useOrderableTableState } from './OrderableTableContext.model';

const OrderableTableContext = React.createContext<OrderableTableContextValue<any, any> | undefined>(
  undefined
);

export const useOrderableTableContext = <TColumn, TRow extends {} = any>() => {
  const context = React.useContext(OrderableTableContext);

  if (context === undefined) {
    throw new Error('No Provider found for OrderableTableContext');
  }

  return context as OrderableTableContextValue<TColumn, TRow>;
};

export const OrderableTableContextProvider = <TColumn extends string, TRow>({
  startingOrder,
  comparators,
  rows,
  children,
}: {
  startingOrder?: OrderInfo<TColumn>;
  comparators: {
    [column: string]: Comparator<TRow>;
  };
  rows: TRow[];
  children?: ((orderedRows: OrderedRow<TRow>[]) => React.ReactNode) | React.ReactNode;
}) => {
  const value = useOrderableTableState({ startingOrder, comparators, rows });

  return (
    <OrderableTableContext.Provider value={value}>
      {typeof children === 'function' ? children(value.orderedRows) : children}
    </OrderableTableContext.Provider>
  );
};
