import styled from 'styled-components/macro';

import Checkbox from '../../form/checkbox/Checkbox';

export const SGroupLabelWrapper = styled.div`
  display: flex;
  margin: 5px 0 0 0;
  cursor: default;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin: 0 5px 0 0;
`;

export const SGroupLeafList = styled.div`
  margin-left: 30px;
  border-left: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.dark}`};
`;

export const SGroupLeafWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const STicker = styled.div`
  border-top: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.dark}`};
  width: 10px;
  margin-right: 5px;
`;
