import { createTestId } from '../../../common/selectors';

const create = (name: string) => createTestId(`draft-account-list-${name}`);

export const draftAccountListScreenSelector = create('screen');
export const draftAccountHeaderSelector = create('header');
export const draftAccountListInvestorNameSelector = create('investor-name');
export const draftAccountListAccountNameSelector = create('account-name');
export const draftAccountListInvestorSubdomainSelector = create('investor-subdomain');
export const draftAccountListStatusSelector = create('status');

export const draftAccountListInvestorNameSearchInputSelector = create('investor-name-search-input');
export const draftAccountListAccountNameSearchInputSelector = create('account-name-search-input');
export const draftAccountListAccountSubdomainSearchInputSelector = create(
  'account-subdomain-search-input'
);
export const draftAccountListCmgEntityKeySearchInputSelector = create(
  'cmg-entity-key-search-input'
);
export const draftAccountListInviteBySearchInputSelector = create('invited-by-search-input');
