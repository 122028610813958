import React from 'react';

import { Table } from '../Table.styles';
import { OrderDirection } from './OrderableTable.model';
import { StyledOrderableHeaderCell } from './OrderableTable.styles';
import { OrderableTableContextProvider, useOrderableTableContext } from './OrderableTableContext';

export type OrderableTableProps<TColumn extends string, TRow> = React.ComponentProps<typeof Table> &
  React.ComponentProps<typeof OrderableTableContextProvider<TColumn, TRow>>;

export function OrderableHeaderCell<T>({
  column,
  children,
  ...headerProps
}: {
  column: T;
  children?: React.ReactNode;
} & React.ComponentProps<typeof StyledOrderableHeaderCell>) {
  const { onOrder } = useOrderableTableContext<T>();

  return (
    <StyledOrderableHeaderCell onClick={() => onOrder(column)} {...headerProps}>
      {children} <OrderMark column={column} />
    </StyledOrderableHeaderCell>
  );
}

export function OrderMark<T>({ column }: { column: T }) {
  const { orderBy } = useOrderableTableContext<T>();

  if (orderBy === 'unordered' || orderBy.column !== column) {
    return null;
  }

  return <span>{orderBy.direction === OrderDirection.DESC ? '↓' : '↑'}</span>;
}

export const OrderableTable = <TColumn extends string, TRow>(
  props: OrderableTableProps<TColumn, TRow>
) => {
  const { startingOrder, comparators, rows, children, ...tableProps } = props;

  return (
    <OrderableTableContextProvider
      startingOrder={startingOrder}
      comparators={comparators}
      rows={rows}
    >
      {orderedRows => (
        <Table {...tableProps}>
          {typeof children === 'function' ? children(orderedRows) : children}
        </Table>
      )}
    </OrderableTableContextProvider>
  );
};
