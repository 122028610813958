import type { ColDef } from 'ag-grid-community';
import { Formik } from 'formik';
import isNumber from 'lodash/isNumber';
import React from 'react';
import * as Yup from 'yup';

import { ValidatorFn, ValueChangeParamsFn } from './NumericInputForm.model';
import NumberInput from './NumericTextInput';

type Props = {
  readonly colDef: ColDef;
  /**
   * Initial allocation share quantity.
   */
  readonly value: string | null;

  readonly errorMsg?: string | null;

  /**
   * Whether or not the form field is enabled.
   */
  readonly disabled?: boolean;
  /**
   * Whether or not the input is being saved
   */
  readonly busy?: boolean;
  /**
   * Warning to be displayed as a tooltip over the warning icon
   */
  readonly warning?: string | null;
  /**
   * used to show alternative ui state
   */
  readonly editing?: boolean;
  /**
   * Debounced callback triggered when the field changes.
   */
  readonly validator?: ValidatorFn | null;
  readonly onValueChange: ValueChangeParamsFn;
};

/**
 * Ag Grid Framework Renderer for a Numeric Input Form.
 *
 */
export const NumericInputForm = React.forwardRef<unknown, Props>((props, ref) => {
  const { colDef, value: defaultValue, errorMsg, validator, onValueChange } = props;

  const [currentValue, setValue] = React.useState<number | null>(
    Number.parseFloat(defaultValue ?? '0')
  );

  const name = colDef.field ?? 'value';
  const refInput = React.useRef<HTMLInputElement | null>(null);

  /* Component Editor Lifecycle methods */
  React.useImperativeHandle<unknown, {}>(
    ref,
    () => {
      return {
        afterGuiAttached() {
          refInput.current?.focus();
        },
        getValue() {
          return currentValue;
        },
      };
    },
    [currentValue]
  );

  const onSubmit = React.useCallback(
    (values: Record<string, unknown>) => {
      if (onValueChange != null) {
        onValueChange({
          [name]: Number.parseFloat(defaultValue ?? '0'),
        });
        setValue(values[name] as number);
      }
    },
    [defaultValue, name, onValueChange]
  );

  const initialValues = React.useMemo(
    () => ({
      [name]: Number.parseFloat(defaultValue ?? '0'),
    }),
    [defaultValue, name]
  );

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        value: Yup.mixed<number>()
          .nullable()
          .test(name, errorMsg ?? 'Invalid Numerical Value', value => {
            // Share quantity can be nil or a value greater than or equal to 0.
            if (typeof validator === 'function') {
              return validator(value);
            }

            return isNumber(value);
          }),
      }),
    [errorMsg, name, validator]
  );

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ values }) => (
        <NumberInput ref={refInput} name={name} value={values[name]} onChange={setValue} />
      )}
    </Formik>
  );
});

export default NumericInputForm;
