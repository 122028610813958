import { myDashboard } from '@cmg/e2e-selectors';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { resetMyDashboard } from '../../ducks';
import ControlsForm from '../controls/ControlsForm';
import { selectCustomSectorOptions } from '../controls/ducks';
import ComparisonPeriod from './components/ComparisonPeriod';
import CurrentPeriod from './components/CurrentPeriod';
import { SDashboard, SRow } from './Dashboard.styles';
import { initMyDashboard } from './ducks';

const mapStateToProps = state => ({
  customSectorOptions: selectCustomSectorOptions(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      onEnter: initMyDashboard,
      onLeave: resetMyDashboard,
    },
    dispatch
  ),
});

export type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

/**
 * Buy-Side Dashboard displays the building blocks for a comparison between two periods
 * of account performance and aggregated market data
 *
 * triggers the initMyDashboard action on load to fetch dashboard offerings and customSectors
 */
export const Dashboard: React.FC<Props> = ({ customSectorOptions, actions }) => {
  React.useEffect(() => {
    actions.onEnter();

    return () => {
      actions.onLeave();
    };
  }, [actions]);

  return (
    <SDashboard data-test-id={myDashboard.buySideDashboard.testId}>
      <ControlsForm customSectorOptions={customSectorOptions} />
      <SRow>
        <CurrentPeriod />
        <ComparisonPeriod />
      </SRow>
    </SDashboard>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
